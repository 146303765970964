import CustomDialog, { ContentContainer } from '../../components/dialogs/CustomDialog'
import { StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import { ButtonPrimary, ButtonSecondary } from '../../components/buttons/StyledButtons'
import React from 'react'

export function NoImageDialog({ visible, submit, dismiss, edit }) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
        <CustomText font="bold">
          {txt('christmasFriends.createEditPost.weakPostDialog.noImageTitle')}
        </CustomText>
        <CustomText font="small" style={{ paddingTop: 12 }}>
          {txt('christmasFriends.createEditPost.weakPostDialog.noImageText')}
        </CustomText>
      </ContentContainer>
      <View style={styles.buttonContainer}>
        <ButtonSecondary
          onPress={submit}
          style={styles.secondaryButton}
          title={edit ? txt('christmasFriends.createEditPost.weakPostDialog.save') : txt('christmasFriends.createEditPost.weakPostDialog.create')}
          titleStyle={{ paddingHorizontal: 12, color: Colors.blueAzure }}
        />
        <ButtonPrimary
          onPress={dismiss}
          style={styles.primaryButton}
          title={txt('christmasFriends.createEditPost.weakPostDialog.edit')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    paddingBottom: 24,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingHorizontal: 3,
    justifyContent: 'space-evenly'
  },
  secondaryButton: {
    borderColor: Colors.blueAzure,
    marginRight: 3,
    paddingHorizontal: 6,
    width: 150
  },
  primaryButton: {
    backgroundColor: Colors.blueAzure,
    marginLeft: 3,
    paddingHorizontal: 6,
    width: 150
  }
})