// libraries
import React, { useState } from 'react'
import { StyleSheet, Platform } from 'react-native'
import { View, FlatList, Image } from 'react-native'
import * as DocumentPicker from 'expo-document-picker'
import * as ExpoImagePicker from 'expo-image-picker'
import * as FileSystem from 'expo-file-system'

import { txt } from '../../../../locales/i18n'
import Colors from '../../../../constants/Colors'
import NormalIcon from '../../../icons/NormalIcon'
import Fontsizes from '../../../../constants/Fontsizes'
import { CustomText } from '../../../text/StyledText'
import CustomDialog from '../../../dialogs/CustomDialog'
import { ButtonPrimary } from '../../../buttons/StyledButtons'

import { getLibraryPermission } from '../../../../utils/getPermissions'
import { maximumDocumentSize, acceptedMimeTypes, acceptedImageMimeTypes } from '../../../../constants/FilesConfig'
import CustomPressable from "../../../pressables/CustomPressable"

export const ChatAttachmentToolbar = (props) => {
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null)

  const removeAttachment = async (targetIndex) => {
    props.setAttachments((attachments) => {
      return attachments.filter((attachment, index) => index !== targetIndex)
    })
  }

  const renderAttachment = ({ item, index }) => {
    const imageUri = 'data:' + item.mimeType + ';base64,' + item.base64

    const renderPreview = () => {
      if (item.mimeType.includes('image')) {
        return <Image source={{ uri: imageUri }} style={styles.imagePreview} accessibilityIgnoresInvertColors />
      }

      if (item.mimeType.includes('video')) {
        return (
          <NormalIcon
            name="video"
            stroke="fad"
            size={Fontsizes.xxl}
            color={Colors.blueAzure}
          />
        )
      }

      return (
        <NormalIcon name="file-alt" stroke="fad" size={Fontsizes.xxl} color={Colors.blueAzure} />
      )
    }

    return (
      <View style={styles.attachmentContainer} key={index}>
        <View style={styles.previewContainer}>
          {renderPreview()}

          <CustomPressable
            onPress={() => removeAttachment(index)}
            style={styles.removeAttachmentButton}
            accessibilityRole="button"
            accessibilityLabel={txt('conversation.attachmentToolbar.removeAttachment')}
            accessibilityHint={txt('conversation.attachmentToolbar.removeAttachmentHint')}
          >
            <NormalIcon name="times" size={Fontsizes.xs} color="white" />
          </CustomPressable>
        </View>

        <CustomText numberOfLines={1} ellipsizeMode="middle" style={styles.attachmentName}>
          {item.name}
        </CustomText>
      </View>
    )
  }

  if (props.visible === false) {
    return null
  }

  const handleSelectImages = async () => {
    if (Platform.OS !== 'web') {
      const permission = await getLibraryPermission()

      if (permission.granted === false) {
        const errorMessage = txt('conversation.attachmentToolbar.errors.missingPermission')
        return setUploadErrorMessage(errorMessage)
      }
    }

    const mediaTypes = ExpoImagePicker.MediaTypeOptions.Images
    const options = { mediaTypes,  allowsMultipleSelection: true, base64: true, quality: 1 }
    const imageResult = await ExpoImagePicker.launchImageLibraryAsync(options)

    if (imageResult.canceled) return // cancelled by user
    const images = imageResult.assets

    const createAttachments = (image) => {
      const attachment = {
        mimeType: 'image/jpeg',
        size: image.fileSize,
        base64: image.base64,
      }

      if (Platform.OS !== 'web') {
        const filenamePattern = /([\w-]+)\.(\w+)$/
        const [name, filename, extension] = filenamePattern.exec(image.uri)

        attachment.name = name
        attachment.filename = filename
        attachment.extension = extension

        return attachment
      }

      const mimeTypePattern = /data:(?<mimeType>.+);base64,/
      const mimeTypeResult = mimeTypePattern.exec(image.uri)
      const mimeType = mimeTypeResult.groups?.mimeType ?? null
      attachment.mimeType = mimeType

      const acceptedImageMimeType = acceptedImageMimeTypes.find((acceptedMimeType) => {
        return acceptedMimeType.mime === mimeType
      })

      if (!acceptedImageMimeType) {
        const errorMessage = txt('conversation.attachmentToolbar.errors.unsupportedImageFormat')
        throw errorMessage
      }

      attachment.filename = 'image'
      attachment.name = 'image.' + acceptedImageMimeType.extension
      attachment.extension = acceptedImageMimeType.extension

      return attachment
    }

    try {
      const attachments = images.map(createAttachments)
      props.setAttachments((currentAttachment) => [...currentAttachment, ...attachments])
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') {
        return setUploadErrorMessage(errorMessage)
      }

      const unknownErrorMessage = txt('conversation.attachmentToolbar.errors.unknown')
      setUploadErrorMessage(unknownErrorMessage)
    }
  }

  const handleSelectDocument = async () => {
    if (Platform.OS !== 'web') {
      const permission = await getLibraryPermission()

      if (permission.granted === false) {
        const errorMessage = txt('conversation.attachmentToolbar.errors.missingPermission')
        return setUploadErrorMessage(errorMessage)
      }
    }

    const { assets, canceled } = await DocumentPicker.getDocumentAsync()
    if (canceled) return // cancelled by user

    const [file] = assets
    const { name, size, uri, mimeType } = file

    const { extension, filename } = /(?<filename>.+)\.(?<extension>\w+)$/g.exec(name).groups

    const attachmentMimeType = acceptedMimeTypes.find((mimeType) => {
      return mimeType.extension === extension.toLowerCase()
    })

    if (!attachmentMimeType) {
      const errorMessage = txt('conversation.attachmentToolbar.errors.unsupportedDocumentFormat')
      return setUploadErrorMessage(errorMessage)
    }

    if (size > maximumDocumentSize) {
      const errorMessage = txt('conversation.attachmentToolbar.errors.unsupportedDocumentSize')
      const supportedSize = `${maximumDocumentSize * 10 ** -6} megabytes`
      return setUploadErrorMessage(`${errorMessage} ${supportedSize}`)
    }

    const attachment = { mimeType, name, size, filename, extension }

    if (Platform.OS === 'web') {
      const regex = /;base64,(?<data>.+)$/g.exec(uri)
      attachment.base64 = regex.groups.data
      props.setAttachments((attachments) => [...attachments, attachment])
      return
    }

    const options = { encoding: 'base64' }
    attachment.base64 = await FileSystem.readAsStringAsync(uri, options)

    props.setAttachments((attachments) => [...attachments, attachment])
  }

  const ListEmptyComponent = (
    <View style={styles.emptyListContainer}>
      <CustomText font="xlargeBold" style={{ color: Colors.grey }}>
        {txt('conversation.attachmentToolbar.emptyTitle')}
      </CustomText>

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <NormalIcon
          size={Fontsizes.xs}
          name="arrow-left"
          color={Colors.blueAzure}
          stroke="fas"
        />
        <CustomText font="xsmall" style={{ marginLeft: 6, color: Colors.blueAzure }}>
          {txt('conversation.attachmentToolbar.emptyDescription')}
        </CustomText>
      </View>
    </View>
  )

  const ActionContainer = (
    <View style={styles.actionContainer}>
      <View style={styles.actionButtonsContainer}>
        <CustomPressable
          accessible
          accessibilityRole="button"
          accessibilityLabel={txt('conversation.attachmentToolbar.selectImage')}
          accessibilityHint={txt('conversation.attachmentToolbar.selectImageHint')}
          onPress={handleSelectImages}
          style={styles.actionButton}
        >
          <NormalIcon
            size={Fontsizes.s}
            name="image"
            color={Colors.blueAzure}
            stroke="fad"
          />
        </CustomPressable>

        <CustomPressable
          accessible
          accessibilityRole="button"
          accessibilityLabel={txt('conversation.attachmentToolbar.selectDocument')}
          accessibilityHint={txt('conversation.attachmentToolbar.selectDocumentHint')}
          onPress={handleSelectDocument}
          style={styles.actionButton}
        >
          <NormalIcon size={Fontsizes.s} name="file-alt" color={Colors.blueAzure} stroke="fad" />
        </CustomPressable>
      </View>
    </View>
  )

  return (
    <View>
      <FlatList
        horizontal
        style={styles.list}
        data={props.attachments}
        renderItem={renderAttachment}
        ListHeaderComponent={ActionContainer}
        ListEmptyComponent={ListEmptyComponent}
      />

      <CustomDialog visible={uploadErrorMessage !== null} style={styles.errorDialog}>
        <CustomText font="largeBold">{txt('conversation.attachmentToolbar.errors.title')}</CustomText>
        <CustomText style={{ marginVertical: 12 }}>{uploadErrorMessage}</CustomText>

        <ButtonPrimary
          onPress={() => setUploadErrorMessage(null)}
          style={{ backgroundColor: Colors.blueAzure }}
          title={txt('global.ok')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    backgroundColor: Colors.white,
    borderRadius: 6,
  },

  removeAttachmentButton: {
    position: 'absolute',
    right: -6,
    top: -6,
    backgroundColor: Colors.greyLight,
    borderRadius: 12,
    padding: 3,
  },

  attachmentName: {
    width: 72,
    textAlign: 'center',
    fontSize: Fontsizes.xs,
  },

  previewContainer: {
    height: 64,
    width: 64,
    margin: 6,
    backgroundColor: Colors.greyLightLight,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  imagePreview: {
    height: 64,
    width: 64,
    resizeMode: 'cover',
    borderRadius: 6,
    borderColor: Colors.greyLightLight,
    borderWidth: 1,
  },

  attachmentContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 6,
    flex: 1,
  },

  actionContainer: {
    backgroundColor: Colors.white,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },

  actionButtonsContainer: {
    borderRightColor: Colors.greyLightLight,
    borderRightWidth: 1,
    margin: 9,
    paddingHorizontal: 6,
    justifyContent: 'center',
  },

  actionButton: {
    padding: 12,
    margin: 6,
    backgroundColor: Colors.greyLightLight,
    borderRadius: 12,
  },

  errorDialog: {
    padding: 24,
  },

  emptyListContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    margin: 12,
  },
})
