import React from 'react'
import { Button } from 'react-native-elements'
import { StyleSheet, ActivityIndicator, View } from 'react-native'
import Colors from '../../constants/Colors'
import Fontsizes from '../../constants/Fontsizes'
import NormalIcon, { BoblbergIcon } from '../icons/NormalIcon'
import { CustomText } from '../text/StyledText'
import { TypographyStyles, CornerStyles } from '../../styles'
import CustomPressable from "../pressables/CustomPressable"

export function RoundBtn(props) {
  return (
    <Button
      {...props}
      accessibilityRole="button"
      titleStyle={styles.btnTitle}
      buttonStyle={[styles.roundBtnStyle, styles.btnColor, props.style, props.buttonStyle]}
    />
  )
}

export function DeleteBtn(props) {
  return (
    <Button
      {...props}
      accessibilityRole="button"
      titleStyle={styles.btnTitle}
      buttonStyle={[styles.roundBtnStyle, styles.btnColorRed]}
    />
  )
}

export function GreenBtn(props) {
  return (
    <Button
      {...props}
      accessibilityRole="button"
      titleStyle={styles.btnTitle}
      buttonStyle={[styles.roundBtnStyle, styles.btnColorGreen, props.style]}
    />
  )
}

export function RotateRightBtn(props) {
  return (
    <CustomPressable
      {...props}
      accessibilityRole="button"
    >
      <NormalIcon
        name="redo"
        color={Colors.inactive}
        size={Fontsizes.xxl}
        style={styles.center}
      />
    </CustomPressable>
  )
}

export function RotateLeftBtn(props) {
  return (
    <CustomPressable
      {...props}
      accessibilityRole="button"
    >
      <NormalIcon
        name="undo"
        color={Colors.inactive}
        size={Fontsizes.xxl}
        style={styles.center}
      />
    </CustomPressable>
  )
}

export function DialogBtn(props) {
  return (
    <Button
      accessibilityRole="button"
      hitSlop={{ top: 30, bottom: 30 }}
      type="clear"
      titleStyle={[styles.btnText, styles.center]}
      {...props}
    />
  )
}

export function RoundSquareBtn(props) {
  return (
    <Button
      accessibilityRole="button"
      type="solid"
      titleStyle={[styles.center, { ...TypographyStyles.textSmallBold, color: props.buttonText || Colors.white }]}
      buttonStyle={[styles.squareBtnStyle, { backgroundColor: props.color || Colors.tintColor }, props.style]}
      {...props}
    />
  )
}

export function RoundSquareDeleteBtn(props) {
  return (
    <Button
      accessibilityRole="button"
      type="solid"
      titleStyle={[styles.btnTextWhite, styles.center]}
      buttonStyle={[styles.squareBtnStyle, styles.btnColorRed, props.style]}
      {...props}
    />
  )
}

function ButtonBase({
  title,
  loading,
  success,
  error,
  disabled,
  style,
  icon,
  iconRight,
  color,
  titleStyle,
  children,
  stroke,
  iconSize,
  label,
  boblbergIcon = false,
  ...props
}) {
  let statusIcon
  if (error) statusIcon = "times"
  if (success) statusIcon = "check"
  const status = success || error
  const textStyle = [
    styles.buttonPrimaryText,
    titleStyle,
    { opacity: disabled ? 0.5 : 1 },
  ]
  const iconStyle = {
    marginRight: 6,
    opacity: disabled ? 0.5 : 1,
  }
  const iconRightStyle = {
    marginLeft: 6,
    opacity: disabled ? 0.5 : 1,
  }
  const contentContainerStyle = {
    flexDirection: 'row',
    height: (status || loading) ? 0.01 : 'auto', // height 0 removes element completely from iOS render
    opacity: (status || loading) ? 0 : 1,
    overflow: 'hidden',
    alignItems: 'center',
  }
  return (
    <CustomPressable
      {...props}
      accessibilityRole="button"
      accessibilityLabel={label || title}
      disabled={disabled || loading || status}
      style={[styles.button, style]}
    >
      {loading && <ActivityIndicator color={color}/> }
      {status && <NormalIcon name={statusIcon} size={20} stroke='far' color={color}/>}
      <View style={contentContainerStyle}>
        {icon && boblbergIcon ? (
          <BoblbergIcon
            name={icon}
            size={iconSize || 20}
            stroke={stroke || 'fas'}
            color={color}
            style={ title ? iconStyle : { margin: 'auto' } } />
          ) : icon && !boblbergIcon && (
          <NormalIcon
            name={icon}
            size={iconSize || 20}
            stroke={stroke || 'fas'}
            color={color}
            style={ title ? iconStyle : { margin: 'auto' } } />
          )}
        <CustomText accessible={false} font="bold" style={textStyle}>
          {title}
          {children}
        </CustomText>
        {iconRight && boblbergIcon ? (
          <BoblbergIcon
            name={iconRight}
            size={iconSize || 20}
            stroke={stroke || 'fas'}
            color={color}
            style={iconRightStyle}
          />
          ) : iconRight && !boblbergIcon && (
          <NormalIcon
            name={iconRight}
            size={iconSize || 20}
            stroke={stroke || 'fas'}
            color={color}
            style={iconRightStyle}
          />
          )}
      </View>
    </CustomPressable>
  )
}

export function ButtonPrimary({ style, titleStyle, color, ...props }) {
  return (
    <ButtonBase
      style={[styles.buttonPrimary, style]}
      titleStyle={[styles.buttonPrimaryText, titleStyle]}
      color={color || Colors.white}
      {...props}
    />
  )
}

export function ButtonSecondary({ style, titleStyle, ...props }) {
  return (
    <ButtonBase
      style={[styles.buttonSecondary, style]}
      titleStyle={[styles.buttonSecondaryText, titleStyle]}
      color={Colors.blue}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  btnColor: { backgroundColor: Colors.tintColor },
  btnColorGreen: { backgroundColor: Colors.green },
  btnColorRed: { backgroundColor: Colors.errorBackground },
  btnPic: {
    alignSelf: 'center',
    borderColor: Colors.tintColor,
    borderStyle: 'dashed',
    borderWidth: 1.5,
    height: 90,
    justifyContent: 'center',
    width: 180,
  },
  btnPicBottom: { borderBottomLeftRadius: 200, borderBottomRightRadius: 200 },
  btnPicText: {
    alignSelf: 'center',
    ...TypographyStyles.textBold,
    color: Colors.tintColor,
  },
  btnPicTop: { borderTopLeftRadius: 200, borderTopRightRadius: 200 },
  btnText: { ...TypographyStyles.textSmallBold, color: Colors.text },
  btnTextWhite: { ...TypographyStyles.textSmallBold, color: Colors.white },
  btnTitle: { ...TypographyStyles.textSmallBold, color: Colors.white },
  button: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
  },
  buttonPrimary: {
    backgroundColor: Colors.blue,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  buttonSecondary: {
    backgroundColor: Colors.transparent,
    borderColor: Colors.blue,
    borderWidth: 2,
    paddingVertical: 6,
    paddingHorizontal: 14,
  },
  buttonPrimaryText: { color: Colors.white, textAlign: 'center' },
  buttonSecondaryText: { color: Colors.blue, textAlign: 'center' },
  center: { alignSelf: 'center' },
  roundBtnStyle: {
    ...CornerStyles.borderRadiusL,
    minWidth: 100,
    alignSelf: 'center',
    paddingHorizontal: 18,
  },
  squareBtnStyle: {
    ...CornerStyles.borderRadiusL,
    backgroundColor: Colors.tintColor,
    paddingHorizontal: 18,
  },
})
