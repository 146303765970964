// libraries
import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import KeyboardSpacer from 'react-native-keyboard-spacer'

// components
import MainContainer from '../../MainContainer/MainContainer'
import SignupField from '../../views/Auth/SignupField'
import { CustomText } from '../../text/StyledText'

// resources
import { txt } from '../../../locales/i18n'
import { SpacingStyles } from '../../../styles'

export const UnionsSignup = () => {
  const navigation = useNavigation()
  const route = useRoute()

  return (
    <MainContainer>
      <View style={styles.widthHeight}>
        <View style={styles.textContainer}>
          <CustomText font="bold" style={styles.text}>
            {txt('activities.signup.text1')}
          </CustomText>

          <CustomText style={styles.text}>{txt('activities.signup.text2')}</CustomText>
        </View>

        <SignupField
          navigation={navigation}
          originScreen="Unions"
          originStack="UnionStack"
          originRootStack="app"
          defaultBirthYear={1955}
          route={route}
        />
      </View>

      {Platform.OS === 'ios' && <KeyboardSpacer />}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  text: {
    marginBottom: 10,
  },

  textContainer: {
    textAlign: 'center',
  },

  widthHeight: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
})
