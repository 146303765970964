import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import React from 'react'

export default function ChangeVisibilityDialog({ visible, makeVisible, keepHidden }) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
        <CustomText>{txt('post.edit.saveVisibilityDialogText')}</CustomText>
      </ContentContainer>
      <ActionButton onPress={makeVisible}>
        {txt('post.edit.makeVisible')}
      </ActionButton>
      <ActionButton onPress={keepHidden}>
        {txt('post.edit.keepHidden')}
      </ActionButton>
    </CustomDialog>
  )
}