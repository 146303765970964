import React from 'react'
import Svg, { Path, Circle } from 'react-native-svg'
import { View } from 'react-native'

export function BoblbergIconPathLight(props) {
  switch (props.name) {
    case 'bubbles':
      return (
        <View>
          <Svg viewBox="0 0 500 500" fill={props.color}>
            <Path
              d="M371.12,40.25a88.63,88.63,0,1,1-88.62,88.63,88.73,88.73,0,0,1,88.62-88.63m0-32.5A121.13,121.13,0,1,0,492.25,128.88,121.13,121.13,0,0,0,371.12,7.75Z"
              transform="translate(-7.75 -7.75)"
            />
            <Path
              d="M309.88,124.5a8.24,8.24,0,0,1-1.62-.16,8.13,8.13,0,0,1-6.35-9.58,70.74,70.74,0,0,1,55-55.1,8.13,8.13,0,1,1,3.25,15.93A54.17,54.17,0,0,0,317.84,118,8.13,8.13,0,0,1,309.88,124.5Z"
              transform="translate(-7.75 -7.75)"
            />
            <Path
              d="M107.35,189.26a67.11,67.11,0,1,1-67.1,67.11,67.19,67.19,0,0,1,67.1-67.11m0-32.5A99.61,99.61,0,1,0,207,256.37a99.61,99.61,0,0,0-99.61-99.61Z"
              transform="translate(-7.75 -7.75)"
            />
            <Path
              d="M71.44,269.28a6.72,6.72,0,0,1-6.56-8A58.16,58.16,0,0,1,110.1,216a6.68,6.68,0,0,1,2.68,13.09A44.56,44.56,0,0,0,78,263.92,6.69,6.69,0,0,1,71.44,269.28Z"
              transform="translate(-7.75 -7.75)"
            />
            <Path
              d="M320.38,357.61a50.88,50.88,0,1,1-50.88,50.88,50.93,50.93,0,0,1,50.88-50.88m0-32.5a83.38,83.38,0,1,0,83.37,83.38,83.38,83.38,0,0,0-83.37-83.38Z"
              transform="translate(-7.75 -7.75)"
            />
          </Svg>
        </View>
      )
    case 'bobl':
      return (
        <View>
          <Svg viewBox="0 0 500 500" fill={props.color}>
            <Path
              d="M250,40.25a209.81,209.81,0,0,1,81.63,403A209.81,209.81,0,0,1,168.37,56.72,208.33,208.33,0,0,1,250,40.25m0-32.5C116.21,7.75,7.75,116.21,7.75,250S116.21,492.25,250,492.25,492.25,383.79,492.25,250,383.79,7.75,250,7.75Z"
              transform="translate(-7.75 -7.75)"
            />
            <Path
              d="M127.52,241.25a16.24,16.24,0,0,1-16-19.47,141.38,141.38,0,0,1,110-110.2,16.25,16.25,0,0,1,6.52,31.84,108.36,108.36,0,0,0-84.63,84.8A16.26,16.26,0,0,1,127.52,241.25Z"
              transform="translate(-7.75 -7.75)"
            />
          </Svg>
        </View>
      )
    default:
      return (
        <View>
          <Svg viewBox="0 0 208.92 198.7" fill={props.color}>
            <Circle cx="217.25" cy="98.25" r="98.25" />
          </Svg>
        </View>
      )
  }
}

export function BoblbergIconPathSolid(props) {
  switch (props.name) {
    case 'bubbles':
      return (
        <View>
          <Svg viewBox="0 0 500 500" fill={props.color}>
            <Path
              d="M371.12,7.75A121.13,121.13,0,1,0,492.25,128.88,121.13,121.13,0,0,0,371.12,7.75Zm-11,67.84A54.17,54.17,0,0,0,317.84,118a8.13,8.13,0,0,1-8,6.51,8.24,8.24,0,0,1-1.62-.16,8.13,8.13,0,0,1-6.35-9.58,70.74,70.74,0,0,1,55-55.1,8.13,8.13,0,1,1,3.25,15.93Z"
              transform="translate(-7.75 -7.75)"
            />
            <Path
              d="M107.35,156.76A99.61,99.61,0,1,0,207,256.37,99.61,99.61,0,0,0,107.35,156.76Zm5.43,72.29A44.56,44.56,0,0,0,78,263.92a6.68,6.68,0,0,1-13.1-2.65A58.16,58.16,0,0,1,110.1,216a6.68,6.68,0,0,1,2.68,13.09Z"
              transform="translate(-7.75 -7.75)"
            />
            <Circle cx="312.63" cy="400.74" r="83.38" />
          </Svg>
        </View>
      )
    case 'bobl':
      return (
        <View>
          <Svg viewBox="0 0 500 500" fill={props.color}>
            <Path
              d="M250,7.75C116.21,7.75,7.75,116.21,7.75,250S116.21,492.25,250,492.25,492.25,383.79,492.25,250,383.79,7.75,250,7.75ZM228.06,143.42a108.36,108.36,0,0,0-84.63,84.8,16.25,16.25,0,0,1-31.86-6.44,141.38,141.38,0,0,1,110-110.2,16.25,16.25,0,0,1,6.52,31.84Z"
              transform="translate(-7.75 -7.75)"
            />
          </Svg>
        </View>
      )
    default:
      return (
        <View>
          <Svg viewBox="0 0 208.92 198.7" fill={props.color}>
            <Circle cx="217.25" cy="98.25" r="98.25" />
          </Svg>
        </View>
      )
  }
}

export function BoblbergIconPathDuo(props) {
  switch (props.name) {
    case 'bobl':
      return (
        <View>
          <Svg viewBox="0 0 500 500" fill={props.color}>
						<Path
							d="m123.6,240.97c-9.26.05-16.8-7.42-16.85-16.67,0-1.15.11-2.29.33-3.42,11.68-57.21,56.33-101.95,113.52-113.73,9.07-1.86,17.94,3.99,19.79,13.07,1.86,9.07-3.99,17.94-13.07,19.79-44.06,8.96-78.46,43.44-87.34,87.51-1.58,7.81-8.43,13.42-16.4,13.45Z"
							transform="translate(-7.75 -7.75)"
						/>
						<Circle cx="250" cy="250" r="250" fillOpacity="0.4" />
          </Svg>
        </View>
      )
    default:
      return (
        <View>
          <Svg viewBox="0 0 208.92 198.7" fill={props.color}>
            <Circle cx="217.25" cy="98.25" r="98.25" />
          </Svg>
        </View>
      )
  }
}
