import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { ActivityIndicator, Modal, SafeAreaView, View } from 'react-native'
import { Dimensions, Platform, StyleSheet } from 'react-native'

import * as ImagePicker from 'expo-image-picker'
import { ImageEditor } from 'expo-image-editor'
import * as FileSystem from 'expo-file-system'
import { Camera } from 'expo-camera'
import * as Device from 'expo-device'

import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import { getMediaPermissions } from '../../utils/getPermissions'

import { CustomText } from '../text/StyledText'
import NormalIcon from '../icons/NormalIcon'
import { ScrollableDialog, ActionButton, ContentContainer } from '../dialogs/CustomDialog'
import CustomPressable from "../pressables/CustomPressable"

const mobilePlatforms = ['Android', 'iOS', 'iPadOS']

function isMobileDevice() {
  return Platform.OS !== 'web' || mobilePlatforms.includes(Device.osName)
}

function isMobileWebDevice() {
  return Platform.OS === 'web' && mobilePlatforms.includes(Device.osName)
}

export const CameraModal = forwardRef(({ onConfirm, aspectRatio }, ref) => {
  const [showModal, setShowModal] = useState(false)
  const [permission, setPermission] = useState(null)
  const [image, setImage] = useState(null)
  const cameraRef = useRef(null)

  useEffect(() => {
    if (ref === null) return
    ref.current = { open: openCamera }
  }, [])

  async function openCamera() {
    if (isMobileWebDevice() === false) {
      const permission = await getMediaPermissions('camera')
      setPermission(permission)
      if (permission === false) return
    }

    if (isMobileDevice() === false) return setShowModal(true)
    await openNativeCamera()
  }

  async function openNativeCamera() {
    setImage(null)
    const mediaTypes = ImagePicker.MediaTypeOptions.Images
    const cameraResult = await ImagePicker.launchCameraAsync({ mediaTypes, base64: true })
    const [image = null] = cameraResult.assets ?? []

    if (image === null || image.canceled === true) return handleDismiss()
    setImage(image)
    setShowModal(true)
  }

  async function handleCaptureWebImage() {
    const camera = cameraRef.current
    if (camera === null) return

    const image = await camera.takePictureAsync({ base64: true, isImageMirror: true })
    image.base64 = image.base64.replace('data:image/png;base64,', '')
    setImage(image)
  }

  async function handleEditingComplete(image) {
    if (Platform.OS !== 'web') {
      const base64 = await FileSystem.readAsStringAsync(image.uri, { encoding: 'base64' })
      image.uri = 'data:image/jpeg;base64,' + base64
    }

    onConfirm(image)
    handleDismiss()
  }

  function handleDismiss() {
    setImage(null)
    setPermission(null)
    setShowModal(false)
  }

  // Error when permission isn't granted
  if (permission === false) {
    return (
      <ScrollableDialog visible={permission === false}>
        <ContentContainer>
          <CustomText style={{ color: Colors.red }} font="bold">
            {txt('cameraModal.cameraAccessHeader')}
          </CustomText>

          <CustomText style={styles.marginTop}>{txt('cameraModal.cameraAccessInfo')}</CustomText>
        </ContentContainer>

        <ActionButton onPress={handleDismiss} style={styles.dialogButton}>
          {txt('global.ok')}
        </ActionButton>
      </ScrollableDialog>
    )
  }

  const webCamera = (
    <>
      {showModal && <Camera ref={cameraRef} style={styles.camera} type={Camera.Constants.Type.back} />}

      <CustomPressable
        style={styles.closeButton}
        onPress={handleDismiss}
        accessibilityRole="button"
        accessibilityLabel={txt('cameraModal.close')}
      >
        <NormalIcon stroke="fal" name="times" color={Colors.white} />
      </CustomPressable>

      <View style={styles.toolbar}>
        <CustomPressable
          style={styles.toolbarButton}
          onPress={handleCaptureWebImage}
          accessibilityRole="button"
          accessibilityLabel={txt('cameraModal.capture')}
        >
          <NormalIcon stroke="fas" name="camera" color={Colors.lightBlue} />
        </CustomPressable>
      </View>
    </>
  )

  const imageCropper = (
    <View style={styles.cropperContainer}>
      {image === null && <ActivityIndicator size="large" color={Colors.white} />}

      <ImageEditor
        asView
        mode="crop-only"
        imageUri={image?.uri}
        onCloseEditor={handleDismiss}
        onEditingComplete={handleEditingComplete}
        fixedCropAspectRatio={aspectRatio ?? 1}
        lockAspectRatio={aspectRatio !== undefined}
        minimumCropDimensions={{ width: 100, height: 100 }}
        backButtonText={txt('cameraModal.back')}
        doneButtonText={txt('cameraModal.done')}
      />
    </View>
  )

  return (
    <Modal visible={showModal} onDismiss={handleDismiss}>
      <SafeAreaView style={styles.container}>
        {isMobileDevice() === false && image === null ? webCamera : imageCropper}
      </SafeAreaView>
    </Modal>
  )
})

const styles = StyleSheet.create({
  camera: {
    height: Dimensions.get('window').height,
    width: (Dimensions.get('window').height / 3) * 4,
  },

  closeButton: {
    padding: 32,
    position: 'absolute',
    right: 0,
    top: 12,
  },

  container: {
    alignItems: 'center',
    backgroundColor: Colors.black,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },

  cropperContainer: {
    flex: 1,
    backgroundColor: Colors.black,
    justifyContent: 'center',
    width: '100%',
  },

  dialogButton: {
    padding: 10,
  },

  marginTop: {
    marginTop: 10,
  },

  toolbar: {
    bottom: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },

  toolbarButton: {
    backgroundColor: Colors.white,
    borderRadius: 35,
    marginHorizontal: 20,
    padding: 18,
  },
})
