import React, { useRef } from 'react'
import { Dimensions, Platform, Image, StyleSheet, View, TouchableWithoutFeedback } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import * as Linking from 'expo-linking'

import { txt } from '../locales/i18n'
import { CustomText } from './text/StyledText'
import Fontsizes from '../constants/Fontsizes'

const imageSizeThreshold = 620

const imageSizes = {
	small: { height: 100, width: 400 },
	large: { height: 100, width: 800 },
}

export const Advertisement = ({ advertisements }) => {
	const navigation = useNavigation()
	const advertisementIndex = useRef(Math.floor(Math.random() * advertisements.length))
	const advertisement = advertisements[advertisementIndex.current] ?? null
	if (advertisement === null) return null

	const windowWidth = Dimensions.get('window').width
	const smallDevice = windowWidth <= imageSizeThreshold

	const getImageDimenstion = () => {
		const imageSize = smallDevice ? imageSizes.small : imageSizes.large
		const aspectRatio = imageSize.width / imageSize.height

		return {
			width: Platform.OS === 'web' ? 'calc(100% - 2 * 12px)' : Dimensions.get('window').width - 2 * 12,
			aspectRatio,
		}
	}

	const getImageUri = () => {
		return smallDevice ? advertisement.imageSmall : advertisement.imageLarge
	}

	const openLink = () => {
		if (advertisement.isEvent) {
			const eventId = advertisement.linkTracking || null
			if (eventId === null) return navigation.navigate('EventStack', { screen: 'Events' })
			return navigation.navigate('EventStack', { screen: 'Event', params: { eventId } })
		}

		if (Platform.OS === 'web') {
			return window.open(advertisement.linkTracking, '_blank')
		}

		Linking.openURL(advertisement.linkTracking)
	}

	return (
		<TouchableWithoutFeedback onPress={openLink} accessibilityLabel={txt('global.advertisement')}>
			<View>
				<View style={[styles.container, getImageDimenstion()]}>
					<Image
						style={styles.image}
						resizeMode="contain"
						accessibilityIgnoresInvertColors
						source={{ uri: getImageUri() }}
					/>
				</View>

				{advertisement.adLabel && (
					<CustomText style={styles.advertisementInfo}>{txt('global.advertisement')}</CustomText>
				)}
			</View>
		</TouchableWithoutFeedback>
	)
}

const styles = StyleSheet.create({
	container: {
		margin: 12,
		borderRadius: 12,
		position: 'relative',
		overflow: 'hidden',
		cursor: 'pointer',
	},

	image: {
		width: '100%',
		height: '100%',
	},

	advertisementInfo: {
		textAlign: 'center',
		fontSize: Fontsizes.xs,
	},
})
