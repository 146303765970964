export const Actions = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_OWN_POST: 'SET_OWN_POST',
  UPDATE_OWN_POST: 'UPDATE_OWN_POST',
  SET_ANNOUNCEMENT: 'SET_ANNOUNCEMENT',
  SET_DATA_RESPONSIBLE: 'DATA_RESPONSIBLE',
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_DEVICE_ID: 'SET_DEVICE_ID',
  SET_USER: 'SET_USER',
  SET_CONVERSATIONS: 'SET_CONVERSATIONS',
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  REMOVE_CONVERSATION: 'REMOVE_CONVERSATION',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  ADD_CONVERSATION_MESSAGE: 'ADD_CONVERSATION_MESSAGE',
  DELETE_CONVERSATION_MESSAGE: 'DELETE_CONVERSATION_MESSAGE',
  SET_SHOW_MAKEINFLUENCE: 'SET_SHOW_MAKEINFLUENCE',
  SET_ORIGIN: 'SET_ORIGIN',
  SET_USER_UNIVERSES: 'SET_USER_UNIVERSES',
  SET_USER_PREFERENCES: 'SET_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  SET_GROUPS: 'SET_GROUPS',
  UPDATE_GROUP: 'UPDATE_GROUP',
  LEAVE_GROUP: 'LEAVE_GROUP',
  SET_CHRISTMAS_POST: 'SET_CHRISTMAS_POST',
  UPDATE_CHRISTMAS_POST: 'UPDATE_CHRISTMAS_POST',
  DELETE_CHRISTMAS_POST: 'DELETE_CHRISTMAS_POST',
  SET_CHRISTMAS_DIALOG_VISIBLE: 'SET_CHRISTMAS_DIALOG_VISIBLE',
  SET_CHRISTMAS_MATCH_DIALOG_VISIBLE: 'SET_CHRISTMAS_MATCH_DIALOG_VISIBLE',
  RESET_STATE: 'RESET_STATE',
  SET_GLOBAL_DIALOG: 'SET_GLOBAL_DIALOG',
  SET_MUNICIPALITIES: 'SET_MUNICIPALITIES',
}
