import React from 'react'
import { View, Linking, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import Fontsizes from '../../../constants/Fontsizes'
import NormalIcon from '../../icons/NormalIcon'
import { ImageHighlight } from '../../../components/images/ImageHighlight'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import { SpacingStyles } from '../../../styles'
import CustomPressable from '../../pressables/CustomPressable'

export default class AboutUs extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<View style={styles.widthHeight}>
				<PageTitle title={TxtTMD.aboutUs.title[TxtTMD.lang]} />

				<View style={styles.margin10}>
					<View style={styles.marginTop10}>
						<CustomText>
							{txt('aboutUs.startText')}
							<CustomPressable onPress={() => Linking.openURL('mailto:support@boblberg.dk')}>
								<CustomText style={styles.linkText}>{txt('aboutUs.supportMail')}</CustomText>
							</CustomPressable>
						</CustomText>
					</View>

					<View style={styles.marginTopBottom}>
						<CustomText font="largeBold">{txt('aboutUs.team')}</CustomText>
					</View>

					{/* Medstiftere */}
					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Rasmus.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">{txt('aboutUs.rasmusName')}</CustomText>
							<CustomText>{txt('aboutUs.coFounder')}</CustomText>

							<CustomPressable onPress={() => Linking.openURL('mailto:rs@boblberg.dk')}>
								<View style={styles.containerRow}>
									<NormalIcon
										accessibilityLabel={txt('aboutUs.emailIcon')}
										name="envelope"
										size={Fontsizes.s}
										color={Colors.text}
										style={styles.icon}
										stroke="fas"
									/>
									<CustomText>{txt('aboutUs.rasmusMail')}</CustomText>
								</View>
							</CustomPressable>

							<CustomPressable onPress={() => Linking.openURL(`tel:${+4520202078}`)}>
								<View style={styles.containerRow}>
									<NormalIcon
										accessibilityLabel={txt('aboutUs.phoneIcon')}
										name="phone-alt"
										size={Fontsizes.s}
										color={Colors.text}
										style={styles.icon}
										stroke="fas"
									/>
									<CustomText>{txt('aboutUs.rasmusPhone')}</CustomText>
								</View>
							</CustomPressable>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Nikolaj.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">{txt('aboutUs.nikolajName')}</CustomText>
							<CustomText>{txt('aboutUs.coFounder')}</CustomText>

							<CustomPressable onPress={() => Linking.openURL('mailto:ns@boblberg.dk')}>
								<View style={styles.containerRow}>
									<NormalIcon
										accessibilityLabel={txt('aboutUs.emailIcon')}
										name="envelope"
										size={Fontsizes.s}
										color={Colors.text}
										style={styles.icon}
										stroke="fas"
									/>
									<CustomText>{txt('aboutUs.nikolajMail')}</CustomText>
								</View>
							</CustomPressable>

							<CustomPressable onPress={() => Linking.openURL(`tel:${+4520202096}`)}>
								<View style={styles.containerRow}>
									<NormalIcon
										accessibilityLabel={txt('aboutUs.phoneIcon')}
										name="phone-alt"
										size={Fontsizes.s}
										color={Colors.text}
										style={styles.icon}
										stroke="fas"
									/>
									<CustomText>{txt('aboutUs.nikolajPhone')}</CustomText>
								</View>
							</CustomPressable>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Anders.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">{txt('aboutUs.andersName')}</CustomText>
							<CustomText>{txt('aboutUs.coFounder')}</CustomText>

							<CustomPressable onPress={() => Linking.openURL('mailto:as@boblberg.dk')}>
								<View style={styles.containerRow}>
									<NormalIcon
										accessibilityLabel={txt('aboutUs.emailIcon')}
										name="envelope"
										size={Fontsizes.s}
										color={Colors.text}
										style={styles.icon}
										stroke="fas"
									/>
									<CustomText>{txt('aboutUs.andersMail')}</CustomText>
								</View>
							</CustomPressable>

							<CustomPressable onPress={() => Linking.openURL(`tel:${+4522250520}`)}>
								<View style={styles.containerRow}>
									<NormalIcon
										accessibilityLabel={txt('aboutUs.phoneIcon')}
										name="phone-alt"
										size={Fontsizes.s}
										color={Colors.text}
										style={styles.icon}
										stroke="fas"
									/>
									<CustomText>{txt('aboutUs.andersPhone')}</CustomText>
								</View>
							</CustomPressable>
						</View>
					</View>

					{/* Administration */}
					<View style={styles.marginTopBottom}>
						<CustomText font="largeBold">{txt('aboutUs.administration')}</CustomText>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Rosa.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">{txt('aboutUs.rosaName')}</CustomText>
							<View>
								<CustomText>{txt('aboutUs.rosaPosition')}</CustomText>
							</View>
							<View style={[styles.containerRow, styles.marginTop10]}>
								<NormalIcon
									accessibilityLabel={txt('aboutUs.emailIcon')}
									name="envelope"
									size={Fontsizes.s}
									color={Colors.text}
									style={styles.icon}
									stroke="fas"
								/>
								<CustomText>{txt('aboutUs.rosaMail')}</CustomText>
							</View>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Emma.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">{txt('aboutUs.emmaName')}</CustomText>
							<View>
								<CustomText>{txt('aboutUs.emmaPosition')}</CustomText>
							</View>
							<View style={[styles.containerRow, styles.marginTop10]}>
								<NormalIcon
									accessibilityLabel={txt('aboutUs.emailIcon')}
									name="envelope"
									size={Fontsizes.s}
									color={Colors.text}
									style={styles.icon}
									stroke="fas"
								/>
								<CustomText>{txt('aboutUs.emmaMail')}</CustomText>
							</View>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Katja.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">{txt('aboutUs.katjaName')}</CustomText>
							<View>
								<CustomText>{txt('aboutUs.katjaPosition')}</CustomText>
							</View>
							<View style={[styles.containerRow, styles.marginTop10]}>
								<NormalIcon
									accessibilityLabel={txt('aboutUs.emailIcon')}
									name="envelope"
									size={Fontsizes.s}
									color={Colors.text}
									style={styles.icon}
									stroke="fas"
								/>
								<CustomText>{txt('aboutUs.katjaMail')}</CustomText>
							</View>
						</View>
					</View>

					{/* IT */}
					<View style={styles.marginTopBottom}>
						<CustomText font="largeBold">{txt('aboutUs.it')}</CustomText>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Jacob.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Jacob</CustomText>
							<CustomText>{txt('aboutUs.jacobPosition')}</CustomText>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Pernille.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Pernille</CustomText>
							<CustomText>{txt('aboutUs.softwareDeveloper')}</CustomText>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Stephan.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Stephan</CustomText>
							<CustomText>{txt('aboutUs.softwareDeveloper')}</CustomText>
						</View>
					</View>

					{/* Event medarbejdere */}
					<View style={styles.marginTopBottom}>
						<CustomText font="largeBold">{txt('aboutUs.eventEmployees')}</CustomText>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Camilla.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Camilla</CustomText>
							<CustomText>{txt('aboutUs.eventTitle')}</CustomText>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Cecilie.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Cecilie</CustomText>
							<CustomText>{txt('aboutUs.eventTitle')}</CustomText>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/AlberteM.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Alberte</CustomText>
							<CustomText>{txt('aboutUs.eventTitle')}</CustomText>
						</View>
					</View>

					<View style={styles.employeeContainer}>
						<View>
							<ImageHighlight source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/AmalieD.jpg' }} />
						</View>
						<View style={styles.employeeTextContainer}>
							<CustomText font="bold">Amalie</CustomText>
							<CustomText>{txt('aboutUs.eventTitle')}</CustomText>
						</View>
					</View>

					{/* Address */}
					<View style={styles.marginTopBottom}>
						<View style={styles.marginTopBottom}>
							<CustomText font="large">{txt('aboutUs.addressTitle')}</CustomText>
						</View>
						<CustomText>{txt('aboutUs.addressBody')}</CustomText>
					</View>

					<View style={styles.separator} />

					{/* Mission */}
					<View style={styles.marginBottom20}>
						<View style={styles.marginBottom10}>
							<CustomText font="large">{txt('aboutUs.missionTitle')}</CustomText>
						</View>
						<CustomText>{txt('aboutUs.missionBody')}</CustomText>
					</View>

					{/* Vision */}
					<View style={styles.marginBottom20}>
						<View style={styles.marginBottom10}>
							<CustomText font="large">{txt('aboutUs.visionTitle')}</CustomText>
						</View>
						<CustomText>{txt('aboutUs.visionBody')}</CustomText>
					</View>

					{/* Values */}
					<View style={styles.marginBottom20}>
						<View style={styles.marginBottom10}>
							<CustomText font="large">{txt('aboutUs.valuesTitle')}</CustomText>
						</View>
						<CustomText>{txt('aboutUs.valuesBody')}</CustomText>
					</View>
				</View>
			</View>
		)
	}
}

const styles = StyleSheet.create({
	icon: { marginRight: 6 },
	containerRow: { flexDirection: 'row', alignItems: 'center' },
	employeeContainer: { alignItems: 'center', flexDirection: 'row', margin: 10 },
	employeeTextContainer: { flexGrow: 1, flex: 1, marginLeft: 10 },
	linkText: {
		color: Colors.tintColor,
		marginTop: 5,
	},
	margin10: { margin: 10 },
	marginBottom10: { marginBottom: 10 },
	marginBottom20: { marginBottom: 20 },
	marginTop10: { marginTop: 10 },
	marginTopBottom: { marginBottom: 10, marginTop: 20 },
	separator: {
		borderBottomColor: Colors.inactive,
		borderBottomWidth: 1,
		marginBottom: 10,
		opacity: 0.3,
	},
	widthHeight: {
		...SpacingStyles.widthAndHeight,
	},
})
