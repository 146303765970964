import React, { useState, useEffect, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import Colors from '../../../constants/Colors'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import UnionFeed from '../../../components/views/Union/UnionFeed'
import CreateUnion from '../../../components/views/Union/CreateUnion'
import MyUnions from '../../../components/views/Union/MyUnions'
import { txt } from '../../../locales/i18n'
import FavoriteUnions from '../../../components/views/Union/FavoriteUnions'
import NormalIcon from '../../../components/icons/NormalIcon'
import { Dimensions } from 'react-native'
import { CustomChip } from '../../../components/chips/Chip'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { CommonActions } from '@react-navigation/native'
import { getUniverseConfig } from '../../../universe/universeController'
import {
  createUnion as createUnionApi,
  getOwnUnions as getOwnUnionsApi,
  getFavoriteUnions as getFavoriteUnionsApi,
  searchUnions as searchUnionsApi,
  getFacilties,
} from '../../../services/api/Unions'
import LoadingSpinner from '../../../components/activityIndicators/LoadingSpinner'
import { CustomText } from '../../../components/text/StyledText'
import ChooseFacultyItem from '../../../components/views/Union/ChooseFacultyItem'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function UnionsUniverseScreen(props) {
  const [universe, setUniverse] = useState(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [locations, setLocations] = useState([])
  const isSmallDevice = Dimensions.get('window').width < 375

  const [faculties, setFaculties] = useState([])
  const [facultiesVisible, setFacultiesVisible] = useState(false)
  const [selectedFaculties, setSelectedFaculties] = useState(new Set())
  const [municipality, setMunicipality] = useState('all')
  const previousSelectedFaculties = useRef(new Set())

  const user = useSelector((state) => state.user)

  useEffect(() => {
    getFacilties().then(setFaculties)
  }, [props.route.params])

  let ignoreSkip = 0
  let useSeparator = true

  useEffect(() => {
    let universeName = props.route.params?.universe?.toLowerCase()
    setUniverse(getUniverseConfig(universeName))
    setIsLoading(false)

    const chip = props.route.params?.chip
    setSelectedMenuItem(chip ? chip : '')

    ignoreSkip = 0
  }, [props.navigation, props.route.params])

  const triggerUpdate = () => {
    const currentSelectedFacultiesString = JSON.stringify(Array.from(selectedFaculties))
    const previousSelectedFacultiesString = JSON.stringify(Array.from(previousSelectedFaculties.current))

    if (currentSelectedFacultiesString === previousSelectedFacultiesString) return false
    previousSelectedFaculties.current = selectedFaculties
    return true
  }

  const setChip = (chip) => {
    props.navigation.dispatch(CommonActions.setParams({ chip }))
  }

  const navigateToUnion = (unionId) => {
    props.navigation.push('Union', {
      id: unionId,
      universe: universe.name,
    })
  }

  const navigateToMyUnions = () => {
    props.navigation.replace('Unions', {
      chip: txt('unions.chipParams.mine'),
      universe: universe.name,
    })
  }

  const createUnion = async (data) => {
    data.universe_id = universe.id
    return await createUnionApi(data)
  }

  const getOwnUnions = async () => {
    return await getOwnUnionsApi()
  }

  const getFavoriteUnions = async () => {
    return await getFavoriteUnionsApi()
  }

  const searchFaculties = async (faculties) => {
    setSelectedFaculties(faculties)
    setFacultiesVisible(false)
  }

  const searchUnions = async ({ search, skip, amount, isSearch }) => {
    // resets values if a search is executed
    if (isSearch) {
      ignoreSkip = 0
    }

    const universeResponse = await searchUnionsApi({
      search,
      skip,
      amount,
      universeId: universe.id,
      faculties: Array.from(selectedFaculties),
    })

    setLocations(universeResponse.locations)

    if (universeResponse.unions.length == 0) {
      const { unions } = await searchUnionsApi({
        search,
        skip: skip - ignoreSkip,
        amount,
        municipality: user.municipality_id
      })

      if (useSeparator && unions.length) {
        useSeparator = false
        return [{ noCount: true, isSeparator: true, id: Date.now() }, ...unions]
      }

      return unions
    }

    ignoreSkip += universeResponse.unions.length
    return universeResponse.unions
  }

  const renderFilters = () => {
    return (
      <>
        <ChooseFacultyItem
          visible={facultiesVisible}
          close={() => setFacultiesVisible(false)}
          faculties={faculties}
          onPress={searchFaculties}
        />
        <View style={styles.filterContainer}>
          <CustomPressable
            onPress={() => setFacultiesVisible(true)}
            accessibilityRole="button"
            style={styles.facultyButton}
          >
            <NormalIcon
              style={{ marginRight: 10 }}
              name="graduation-cap"
              size={18}
              color={Colors.text}
              badge={selectedFaculties.size}
            />
            <CustomText>{'Fakultet'}</CustomText>
          </CustomPressable>
        </View>
      </>
    )
  }

  const renderChips = () => (
    <View style={styles.center}>
      <View style={styles.chipContainer}>
        <View style={styles.chip}>
          <CustomChip
            icon="bobl"
            selected={selectedMenuItem === ''}
            onPress={() => setChip('')}
            accessibilityLabel={txt('unions.menu.all')}
            text={txt('unions.menu.all')}
          />
        </View>
        <View style={styles.chip}>
          <CustomChip
            icon="user"
            selected={selectedMenuItem === txt('unions.chipParams.mine')}
            onPress={() => setChip(txt('unions.chipParams.mine'))}
            accessibilityLabel={txt('unions.menu.mine')}
            text={txt('unions.menu.mine')}
          />
        </View>
        <View style={styles.chip}>
          <CustomChip
            icon="star"
            selected={selectedMenuItem === txt('unions.chipParams.favorites')}
            onPress={() => setChip(txt('unions.chipParams.favorites'))}
            accessibilityLabel={txt('activities.menu.favoriteActivities')}
            text={!isSmallDevice && txt('activities.menu.favoriteActivities')}
          />
        </View>
        <View style={styles.chip}>
          <CustomChip
            icon="plus-circle"
            selected={selectedMenuItem === txt('unions.chipParams.create')}
            onPress={() => setChip(txt('unions.chipParams.create'))}
            accessibilityLabel={txt('unions.menu.create')}
            text={txt('unions.menu.create')}
          />
        </View>
      </View>
    </View>
  )

  const loadMenuOption = () => {
    switch (selectedMenuItem) {
      case '':
        return (
          <UnionFeed
            navigation={props.navigation}
            navigateToUnion={navigateToUnion}
            searchUnions={searchUnions}
            renderChips={renderChips}
            filterBelow={universe.id === 12 ? renderFilters : null}
            backgroundColor={universe.backgroundColor}
            triggerUpdate={triggerUpdate}
            setMunicipality={setMunicipality}
            municipality={municipality}
            locations={locations}
          />
        )
      case txt('unions.chipParams.mine'):
        return (
          <MyUnions
            navigation={props.navigation}
            navigateToUnion={navigateToUnion}
            getOwnUnions={getOwnUnions}
            renderChips={renderChips}
          />
        )
      case txt('unions.chipParams.favorites'):
        return (
          <FavoriteUnions
            navigation={props.navigation}
            navigateToUnion={navigateToUnion}
            getFavoriteUnions={getFavoriteUnions}
            renderChips={renderChips}
          />
        )
      case txt('unions.chipParams.create'):
        return (
          <CreateUnion
            navigation={props.navigation}
            navigateToMyUnions={navigateToMyUnions}
            createUnion={createUnion}
            renderChips={renderChips}
          />
        )
    }
  }

  return (
    <MainContainer noScroll backgroundColor={universe?.backgroundColor}>
      <PageTitle title={TxtTMD.unions.title[TxtTMD.lang]} />
      {isLoading ? <LoadingSpinner /> : loadMenuOption()}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  center: { alignSelf: 'center', flexDirection: 'row' },
  chip: { marginBottom: 5, marginLeft: 3, marginRight: 3 },
  chipContainer: { alignSelf: 'center', flexDirection: 'row', marginTop: 5 },
  iconStyle: { marginRight: -12 },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8,
  },
  facultyButton: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
})
