import i18n from 'i18n-js'
import da from './da.json'
import en from './en.json'
import sv from './sv.json'
import fi from './fi.json'
import uk from './uk.json'
import de from './de.json'
import ar from './ar.json'

// Fallbacks to next language with existing kay
i18n.fallbacks = true

i18n.translations = {
  da,
  en,
  sv,
  fi,
  uk,
  de,
  ar,
}

export function txt(name, params = {}) {
  return i18n.t(name, params)
}

export default i18n
