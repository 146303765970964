import React from 'react'
import { StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import CustomPressable from "../../pressables/CustomPressable"

export default function CreateGroupConversationItem({ onPress }) {
  return (
    <CustomPressable
      accessibilityRole="button"
      accessibilityLabel={txt('conversations.create.cardTitle')}
      onPress={onPress}
      style={styles.container}
    >
      <CustomText font="smallBold">
        {txt('conversations.create.cardTitle')}
      </CustomText>
      <CustomText font="small" style={styles.text}>
        {txt('conversations.create.cardBody1')}
        <NormalIcon
          name="edit"
          size={16}
          stroke="far"
          style={{ paddingLeft: 6, paddingRight: 3 }}
        />
        {txt('conversations.create.cardBody2')}
      </CustomText>
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.whiteTransparent,
    marginHorizontal: 12,
    marginVertical: 6,
    padding: 12,
    borderRadius: 12,
    borderWidth: .5,
    borderColor: Colors.blackTransparent,
  },
  text: {
    color: Colors.inactive,
    paddingTop: 3,
  },
})
