import React from 'react'
import CreateEditPost from '../../../components/views/Post/CreateEditPost'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { getFullPost, updatePost, deletePost } from '../../../services/api/Posts'
import { txt } from '../../../locales/i18n'

export default function EditBoblUniverseScreen(props) {
  const navigateToMyPosts = () => {
    props.navigation.navigate('Bobls', {
      chip: txt('posts.chipParams.mine'),
    })
  }

  function navigateToPost() {
    props.navigation.navigate('Post', {
      id: props.route.params.id,
      universe: props.route.params.universe,
    })
  }

  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())

  const getPost = async (data) => {
    return await getFullPost(props.route.params.id)
  }

  const editPost = async (postId, data) => {
    return await updatePost(postId, data)
  }

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <CreateEditPost
        navigateToMyPosts={navigateToMyPosts}
        route={props.route}
        editPost={editPost}
        getPost={getPost}
        deletePost={deletePost}
      />
    </MainContainer>
  )
}
