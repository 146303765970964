import React from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import Settings from '../../../components/views/Settings/Settings'
import { getUniverseConfig } from '../../../universe/universeController'
import NotificationSettingsTypes from '../../../constants/NotificationSettingsTypes'

export default function SettingsUniverseScreen(props) {
  const universe = props.route.params?.universe?.toLowerCase()
  const universeObject = getUniverseConfig(universe)

  function navigateToNotificationSettings(type) {
    const id = NotificationSettingsTypes.indexOf(type)
    props.navigation.navigate('Notifications', {
      id,
      universe
    })
  }

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <Settings navigateToNotificationSettings={navigateToNotificationSettings} />
    </MainContainer>
  )
}
