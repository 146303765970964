import React from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import HomeScreen from '../screens/Post/HomeScreen'
import PostScreen from '../screens/Post/PostScreen'
import StackMenuScreen from '../screens/StackMenu/StackMenuScreen'
import ActivityScreen from '../screens/Activities/ActivityScreen'
import EditPostScreen from '../screens/Post/EditPostScreen'
import ProfileScreen from '../screens/Profile/ProfileScreen'
import GroupScreen from '../screens/Groups/GroupScreen'
import GroupsScreen from '../screens/Groups/GroupsScreen'
import { UnionFeedScreen } from '../screens/Unions/UnionFeedScreen'
import UnionScreen from '../screens/Unions/UnionScreen'
import EditActivityScreen from '../screens/Activities/EditActivityScreen'
import SnakSammenOversigt from '../components/views/snakSammen/SnakSammenOversigt'
import VolunteerSignup from '../components/views/snakSammen/VolunteerSignup'
import SproglandOversigt from '../components/views/LearnDanish/SproglandOversigt'
import CreatePostScreen from '../screens/Post/CreatePostScreen'
import InboxScreen from '../screens/Conversation/InboxScreen'
import ConversationScreen from '../screens/Conversation/ConversationScreen'
import ConversationInfoScreen from '../screens/Conversation/ConversationInfoScreen'
import EditUnionScreen from '../screens/Unions/EditUnionScreen'

import { EventFeedScreen } from '../screens/Events/EventFeedScreen'
import { EventScreen } from '../screens/Events/EventScreen'

import Colors from '../constants/Colors'
import { txt } from '../locales/i18n'
import { stackConfig } from './navigationConfig'
import SharedStack from './navigationStacks/sharedStack'
import TabBarIcon from '../components/icons/TabBarIcon'
import ActivitiesFeedScreen from '../screens/Activities/ActivitiesFeedScreen'
import SettingsScreen from '../screens/Settings/SettingsScreen'
import { selectUnreadGroupMessages, selectUnreadMessages } from '../redux/selectors'
import { useSelector } from 'react-redux'
import NotificationsScreen from '../screens/Settings/NotificationsScreen'
import SproglandJuniorScreen from '../screens/LearnDanish/SproglandJuniorScreen'
import ProfileViewScreen from '../screens/Profile/ProfileViewScreen'
import SproglandVolunteerScreen from '../screens/LearnDanish/VolunteerScreen'
import SnakSammenVolunteerScreen from '../screens/SnakSammen/VolunteerScreen'
import { WhatIsBoblbergScreen } from '../screens/cms/WhatIsBoblberg'

import { getExtraTabs } from './MenuConfig'

function HomeStack() {
  const Stack = createStackNavigator()

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{ ...stackConfig({}), headerMode: 'float' }}
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: txt('home.title'),
          headerLeft: () => null,
        }}
      />
      <Stack.Screen name="Post" component={PostScreen} options={{ title: '' }} />
      <Stack.Screen name="PostWithTitle" component={PostScreen} options={{ title: '' }} />
      <Stack.Screen name="EditPost" component={EditPostScreen} options={{ title: txt('post.editTitle') }} />
      <Stack.Screen name="Conversation" component={ConversationScreen} options={{ title: txt('conversation.title') }} />
      <Stack.Screen
        name="ConversationInfo"
        component={ConversationInfoScreen}
        options={{ title: txt('conversation.info') }}
      />
    </Stack.Navigator>
  )
}

function InboxStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator initialRouteName="Inbox" screenOptions={{ ...stackConfig({}), headerMode: 'float' }}>
      <Stack.Screen
        name="Inbox"
        component={InboxScreen}
        options={{
          title: txt('conversations.title'),
          headerLeft: () => null,
        }}
      />
      <Stack.Screen name="Conversation" component={ConversationScreen} options={{ title: txt('conversation.title') }} />
      <Stack.Screen
        name="ConversationInfo"
        component={ConversationInfoScreen}
        options={{ title: txt('conversation.info') }}
      />
      <Stack.Screen name="Post" component={PostScreen} options={{ title: '' }} />
      <Stack.Screen name="ProfileView" component={ProfileViewScreen} options={{ title: txt('profile.title') }} />
    </Stack.Navigator>
  )
}

function CreatePostStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator
      initialRouteName="CreatePost"
      screenOptions={{ ...stackConfig({}), headerMode: 'float' }}
    >
      <Stack.Screen
        name="CreatePost"
        component={CreatePostScreen}
        options={{
          title: txt('createPost.title'),
          headerLeft: () => null,
        }}
      />
    </Stack.Navigator>
  )
}

function GroupScreens() {
  const Stack = createStackNavigator()
  return (
    <>
      <Stack.Screen name="Groups" component={GroupsScreen} options={{ title: txt('groups.title') }} />
      <Stack.Screen name="Group" component={GroupScreen} options={{ title: txt('groups.group') }} />
    </>
  )
}
function GroupsStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator initialRouteName="Groups" screenOptions={{ ...stackConfig({}), headerMode: 'float' }}>
      {GroupScreens()}
    </Stack.Navigator>
  )
}

function ActivityScreens() {
  const Stack = createStackNavigator()
  return (
    <>
      <Stack.Screen name="Activities" component={ActivitiesFeedScreen} options={{ title: txt('activities.title') }} />
      <Stack.Screen name="Activity" component={ActivityScreen} options={{ title: txt('activities.activity') }} />
      <Stack.Screen
        name="EditActivity"
        component={EditActivityScreen}
        options={{ title: txt('activities.activity') }}
      />
    </>
  )
}

function ActivityStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator
      initialRouteName="Activities"
      screenOptions={{ ...stackConfig({}), headerMode: 'float' }}
    >
      {ActivityScreens()}
    </Stack.Navigator>
  )
}

function UnionScreens() {
  const Stack = createStackNavigator()
  return (
    <>
      <Stack.Screen name="Unions" component={UnionFeedScreen} options={{ title: txt('unions.title') }} />
      <Stack.Screen name="Union" component={UnionScreen} options={{ title: txt('unions.union.title') }} />
      <Stack.Screen name="EditUnion" component={EditUnionScreen} options={{ title: txt('unions.edit.title') }} />
    </>
  )
}

function UnionStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator
      initialRouteName="Unions"
      screenOptions={{ ...stackConfig({}), headerMode: 'float' }}
    >
      {UnionScreens()}
    </Stack.Navigator>
  )
}

function EventScreens() {
  const Stack = createStackNavigator()

  return (
    <>
      <Stack.Screen name="Events" component={EventFeedScreen} options={{ title: txt('events.title') }} />

      <Stack.Screen name="Event" component={EventScreen} options={{ title: txt('event.title') }} />
    </>
  )
}

function EventStack() {
  const Stack = createStackNavigator()

  return (
    <Stack.Navigator
      initialRouteName="Events"
      screenOptions={{ ...stackConfig({}), headerMode: 'float' }}
    >
      {EventScreens()}
    </Stack.Navigator>
  )
}

function MenuStack() {
  const Stack = createStackNavigator()
  const joinedGroupsCount = useSelector(({ groups }) => groups?.length ?? 0)
  const features = useSelector((state) => state.user.features)
  const extraTabs = getExtraTabs(features, joinedGroupsCount)

  return (
    <Stack.Navigator
      initialRouteName="Menu"
      screenOptions={{ ...stackConfig({}), headerMode: 'float' }}
    >
      <Stack.Screen
        name="Menu"
        component={StackMenuScreen}
        options={{
          title: txt('mainTabNavigator.menuName'),
          headerLeft: () => null,
        }}
      />
      <Stack.Screen name="Profile" component={ProfileScreen} options={{ title: txt('profile.title') }} />
      <Stack.Screen name="ProfileView" component={ProfileViewScreen} options={{ title: txt('profile.title') }} />
      <Stack.Screen name="Settings" component={SettingsScreen} options={{ title: txt('settings.title') }} />
      <Stack.Screen
        name="WhatIsBoblberg"
        component={WhatIsBoblbergScreen}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{ title: txt('settings.notifications') }}
      />
      <Stack.Screen
        name="SnakSammenOversigt"
        component={SnakSammenOversigt}
        options={{
          title: txt('videoChat.title'),
          headerBackTitle: txt('videoChat.backTitle'),
        }}
      />
      <Stack.Screen name="VolunteerSignup" component={VolunteerSignup} options={{ title: txt('videoChat.title') }} />
      <Stack.Screen
        name="SproglandOversigt"
        component={SproglandOversigt}
        options={{
          title: txt('learnDanish.title'),
          headerBackTitle: txt('learnDanish.backTitle'),
        }}
      />
      <Stack.Screen
        name="SproglandJunior"
        component={SproglandJuniorScreen}
        options={{
          title: txt('learnDanish.junior.title'),
          headerBackTitle: txt('learnDanish.backTitle'),
        }}
      />
      <Stack.Screen
        name="SproglandVolunteer"
        component={SproglandVolunteerScreen}
        options={{
          title: txt('learnDanish.volunteer.title'),
          headerBackTitle: txt('learnDanish.backTitle'),
        }}
      />
      <Stack.Screen name="Conversation" component={ConversationScreen} options={{ title: txt('conversation.title') }} />
      <Stack.Screen
        name="ConversationInfo"
        component={ConversationInfoScreen}
        options={{ title: txt('conversation.info') }}
      />
      <Stack.Screen name="Post" component={PostScreen} options={{ title: '' }} />

      <Stack.Screen
        name="SnakSammenVolunteer"
        component={SnakSammenVolunteerScreen}
        options={{
          title: txt('learnDanish.volunteer.title'),
          headerBackTitle: txt('learnDanish.backTitle'),
        }}
      />

      {extraTabs.includes('groups') === false && GroupScreens()}
      {extraTabs.includes('activities') === false && ActivityScreens()}
      {extraTabs.includes('unions') === false && UnionScreens()}
      {extraTabs.includes('events') === false && EventScreens()}
      {SharedStack()}
    </Stack.Navigator>
  )
}

function tabIcon({ focused, iconName, badge, boblbergIcon = false, create = false }) {
  return (
    <TabBarIcon
      name={iconName}
      color={create && !focused ? Colors.tabBarActive : focused ? Colors.tabBarActive : Colors.tabBarInActive}
      badge={badge}
      stroke={create && !focused ? 'fas' : focused ? 'fad' : 'fal'}
      boblbergIcon={boblbergIcon}
    />
  )
}

function TabNavigator() {
  const groupsBadge = useSelector(selectUnreadGroupMessages)
  const conversationsBadge = useSelector(selectUnreadMessages)
  const joinedGroupsCount = useSelector(({ groups }) => groups?.length ?? 0)
  const country = useSelector((state) => state.user.country)
  const features = useSelector((state) => state.user.features)
  const extraTabs = getExtraTabs(features, joinedGroupsCount)

  const withinDenmark = country === 'Denmark'
  const Tab = createBottomTabNavigator()

  const style = { backgroundColor: Colors.tabBarBackground }
  if (Platform.OS !== 'web') style.borderTopColor = Colors.tabBarInActive

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        lazy: Platform.OS === 'web',
        tabBarStyle: style,
        tabBarLabelStyle: { fontFamily: 'raleway-semibold', marginTop: 0 },
        tabBarIconStyle: { marginTop: 0 },
        tabBarActiveTintColor: Colors.tabBarActive,
        tabBarInactiveTintColor: Colors.tabBarInActive,
      }}
    >
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{
          tabBarLabel: txt('home.title'),
          tabBarIcon: ({ focused }) => tabIcon({ focused, iconName: "bobl", badge: 0, boblbergIcon: true }),
        }}
      />
      <Tab.Screen
        name="InboxStack"
        component={InboxStack}
        options={{
          tabBarLabel: txt('conversations.title'),
          tabBarIcon: ({ focused }) =>
            tabIcon({ focused, iconName: "comment-lines", badge: conversationsBadge }),
        }}
      />
      <Tab.Screen
        name="CreatePostStack"
        component={CreatePostStack}
        listeners={{
          tabPress: () => {
            if (Platform.OS === 'web') {
              window.plausible('createBobl', {
                props: { source: 'navbar' },
              })
            }
          },
        }}
        options={{
          tabBarLabel: txt('createPost.title'),
          tabBarIcon: ({ focused }) => tabIcon({ focused, iconName: "plus-circle", badge: 0, create: true }),
        }}
      />
      {withinDenmark && extraTabs.includes('groups') && (
        <Tab.Screen
          name="GroupsStack"
          component={GroupsStack}
          options={{
            tabBarLabel: txt('groups.title'),
            tabBarIcon: ({ focused }) => tabIcon({ focused, iconName: "users", badge: groupsBadge }),
          }}
        />
      )}
      {withinDenmark && extraTabs.includes('activities') && (
        <Tab.Screen
          name="ActivityStack"
          component={ActivityStack}
          options={{
            tabBarLabel: txt('activities.title'),
            tabBarIcon: ({ focused }) => tabIcon({ focused, iconName: "calendar-day" }),
          }}
        />
      )}
      {withinDenmark && extraTabs.includes('unions') && (
        <Tab.Screen
          name="UnionStack"
          component={UnionStack}
          options={{
            tabBarLabel: txt('unions.title'),
            tabBarIcon: ({ focused }) => tabIcon({ focused, iconName: "users-class" }),
          }}
        />
      )}

      {withinDenmark && extraTabs.includes('events') && (
        <Tab.Screen
          name="EventStack"
          component={EventStack}
          options={{
            tabBarLabel: txt('events.title'),
            tabBarIcon: ({ focused }) => tabIcon({ focused, iconName: "calendar-star" }),
          }}
        />
      )}

      <Tab.Screen
        name="MenuStack"
        component={MenuStack}
        options={{
          tabBarLabel: txt('mainTabNavigator.menuName'),
          tabBarIcon: ({ focused }) =>
            tabIcon({
              focused,
              iconName: "bars",
              badge: extraTabs.includes('groups') ? false : groupsBadge,
            }),
        }}
      />
    </Tab.Navigator>
  )
}

export default TabNavigator
