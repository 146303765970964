import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import React from 'react'

export function OwnPostDialog({visible, toggle}) {
  return (
    <CustomDialog visible={visible} onDismiss={toggle}>
      <ContentContainer>
        <CustomText>
          {txt('post.ownPostText')}
        </CustomText>
      </ContentContainer>
      <ActionButton onPress={toggle}>
        {txt('post.ok')}
      </ActionButton>
    </CustomDialog>
  )
}