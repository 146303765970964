import React from 'react'

import TxtTMD from '../../constants/TxtTMD'

import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import { LoginView } from '../../components/views/Auth/Login'

export default () => (
	<MainContainer noScroll>
		<PageTitle title={TxtTMD.login.title[TxtTMD.lang]} />
		<LoginView />
	</MainContainer>
)
