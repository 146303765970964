import Colors from '../constants/Colors'

export const universes = [
	{
		name: 'aauucn',
		id: 1,
		pageTitle: 'Studiebobler.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/studiebobler_logo.png',
		logoLabel: 'Studiebobler.dk logo',
		title: 'Studiebobler',
	},
	{
		name: 'ffb',
		id: 2,
		pageTitle: 'Frederiksberg Forenede Boligselskaber | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/ffb_logo.png',
		logoLabel: 'FFB logo',
		title: 'Frederiksberg Forenede Boligselskaber',
	},
	{
		name: 'sab',
		id: 3,
		pageTitle: 'Samvirkende Boligselskaber | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/sab_logo.png',
		logoLabel: 'SAB logo',
		title: 'Samvirkende Boligselskaber',
	},
	{
		name: 'bes',
		id: 4,
		pageTitle: 'Ballerup Ejendomsselskab | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/bes_logo.png',
		logoLabel: 'BES logo',
		title: 'Ballerup Ejendomsselskab',
	},
	{
		name: 'lbs',
		id: 5,
		pageTitle: 'Lyngby Boligselskab | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/lbs_logo.png',
		logoLabel: 'LBS logo',
		title: 'Lyngby Boligselskab',
	},
	{
		name: 'bsf',
		id: 6,
		pageTitle: 'Boligselskabet Friheden | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/bsf_logo.png',
		logoLabel: 'BSF logo',
		title: 'Boligselskabet Friheden',
	},
	{
		name: 'abs',
		id: 7,
		pageTitle: 'Avedøre Boligselskab | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/abs_logo.png',
		logoLabel: 'ABS logo',
		title: 'Avedøre Boligselskab',
	},
	{
		name: 'rf',
		id: 8,
		pageTitle: 'Roskilde Festival | Boblberg.dk',
		backgroundColor: Colors.greyLightLight,
		leftChatBubbleBackground: Colors.white,
		rightChatBubbleBackground: Colors.black,
		conversationPostsItemBackground: Colors.white,
		tabBarColor: Colors.dark,
		tabBarTextColor: Colors.white,
		tabBarActiveTextColor: Colors.orangeRf,
		logo: 'https://filer.boblberg.dk/assets/img/universe/rf_logo.png',
		logoLabel: 'RF logo',
		title: 'Roskilde Festival',
	},
	{
		name: 'herlev',
		id: 9,
		pageTitle: 'Herlevbobler | Boblberg.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/herlevbobler_logo.png',
		logoLabel: 'Herlevbobler.dk logo',
		title: 'Herlevbobler',
	},
	{
		name: 'nytaarsvenner',
		id: 10,
		pageTitle: 'Nytårsvenner | Boblberg.dk',
		backgroundColor: Colors.white,
		leftChatBubbleBackground: '#efcf93',
		rightChatBubbleBackground: Colors.black,
		conversationPostsItemBackground: Colors.white,
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.newYearPrimary,
		tabBarActiveTextColor: Colors.newYearPrimary,
		boblHighlightColor: Colors.newYearPrimary,
		chipUnselectedColor: '#e5be6a',
		chipTextColor: Colors.black,
		separatorColor: Colors.newYearPrimary,
		logo: 'https://filer.boblberg.dk/assets/img/universe/nytaarsvenner-logo-dk.png',
		logoLabel: 'Nytårsvenner logo',
		title: 'Nytårsvenner',
	},
	{
		name: 'au',
		id: 12,
		pageTitle: 'Studiebobler.dk',
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.textLight,
		tabBarActiveTextColor: Colors.tintColor,
		logo: 'https://filer.boblberg.dk/assets/img/universe/studiebobler/sr-studiebobler-logo.png',
		logoLabel: 'Studiebobler.dk logo',
		title: 'Studiebobler',
	},
	{
		name: 'krifa',
		id: 13,
		pageTitle: 'Krifa',
		backgroundColor: '#f8f9ff',
		tabBarColor: '#651560',
		tabBarTextColor: Colors.whiteTransparent,
		tabBarActiveTextColor: Colors.white,
		logo: 'https://filer.boblberg.dk/assets/img/universe/krifa_boblberg_logo.png',
		logoLabel: 'Boblberg i samarbejde med Krifa logo',
		title: 'Krifa',
	},
	{
		name: 'nyarsvanner',
		id: 14,
		pageTitle: 'Nyårsvänner | Boblberg.se',
		backgroundColor: Colors.white,
		leftChatBubbleBackground: '#efcf93',
		rightChatBubbleBackground: Colors.black,
		conversationPostsItemBackground: Colors.white,
		tabBarColor: Colors.white,
		tabBarTextColor: Colors.newYearPrimary,
		tabBarActiveTextColor: Colors.newYearPrimary,
		boblHighlightColor: Colors.newYearPrimary,
		chipUnselectedColor: '#e5be6a',
		chipTextColor: Colors.black,
		separatorColor: Colors.newYearPrimary,
		logo: 'https://filer.boblberg.dk/assets/img/universe/nytaarsvenner-logo-se.png',
		logoLabel: 'Nyårsvänner logo',
		title: 'Nyårsvänner',
	},
]
