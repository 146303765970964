import React from 'react'
import { getUniverseId } from '../../universe/universeController'
import { joinUniverseById } from '../../services/api/Universe'
import { useSelector } from 'react-redux'

import AauucnIntro from './IntroViews/AauucnIntro'
import FFBIntro from './IntroViews/FFBIntro'
import SABIntro from './IntroViews/SABIntro'
import BESIntro from './IntroViews/BESIntro'
import LBSIntro from './IntroViews/LBSIntro'
import BSFIntro from './IntroViews/BSFIntro'
import ABSIntro from './IntroViews/ABSIntro'
import RFIntro from './IntroViews/RFIntro'
import HerlevIntro from './IntroViews/HerlevIntro'
import { getOwnUser } from '../../services/api/User'
import { openURL } from 'expo-linking'

export default function IntroUniverseScreen(props) {
	const universe = props.route.params.universe?.toLowerCase()
	const userToken = useSelector((state) => state.userToken)

	async function joinUniverse(universeName) {
		if (userToken) {
			const universeId = getUniverseId(universeName.toLowerCase())
			await joinUniverseById(universeId)
			await getOwnUser()
		} else {
			props.navigation.navigate('SignupUniverse', {
			universe: universeName.toLowerCase(),
			})
		}
	}

	async function joinNewyearUniverse(universe) {
		const newyearUniverses = {
			'nytaarsvenner': { id: 10, region: 'dk' },
			'nyarsvanner': { id: 14, region: 'se' }
		}

		if (!userToken) {
			return openURL(`https://boblberg.${newyearUniverses[universe].region}/${universe}`)
		}

		await joinUniverseById(newyearUniverses[universe].id)
		await getOwnUser()

		props.navigation.navigate('universe', {
			screen: 'UniverseBoblStack',
			params: {
				screen: 'Bobls',
				initial: true,
				params: { universe: universe },
			},
		})
	}

	switch (universe) {
		case 'aauucn':
			return (
			<AauucnIntro navigation={props.navigation} joinUniverse={joinUniverse}/>
			)
		case 'ffb':
			return (
			<FFBIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'sab':
			return (
			<SABIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'bes':
			return (
			<BESIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'lbs':
			return (
			<LBSIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'bsf':
			return (
			<BSFIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'abs':
			return (
			<ABSIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'rf':
			return (
			<RFIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'herlev':
			return (
			<HerlevIntro navigation={props.navigation} joinUniverse={joinUniverse} />
			)
		case 'nytaarsvenner':
			joinNewyearUniverse(universe)
			return <></>
		case 'nyarsvanner':
			joinNewyearUniverse(universe)
			return <></>
		default:
			props.navigation.popToTop()
			return <></>
	}
}
