import { apiGet, apiPost } from './ApiRequest'

export const surveyIds = {
  sproglandVolunteer: 'sproglandVolunteer',
  sproglandUser: 'sproglandUser',
  snaksammenVolunteer: 'snaksammenVolunteer',
  snaksammenUser: 'snaksammenUser',
}

export async function logSurveyPrompt(participated, surveyId) {
  const body = {
    participated: participated ? 1 : 0,
    surveyId: surveyId,
  }
  const res = await apiPost(body, 'surveyPrompts/')
  return res.json()
}

export async function getLatestSurveyPrompt(surveyId) {
  const res = await apiGet(`surveyPrompts/?surveyId=${surveyId}`)
  return res?.[0] || false
}

export async function getPreviousSLSurvey() {
  return await apiGet(`surveyPrompts/sl`)
}

export async function createSLSurvey(body) {
  await apiPost(body, 'surveyPrompts/sl')
}
