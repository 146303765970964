import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import NormalIcon from '../icons/NormalIcon'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import CustomPressable from "../pressables/CustomPressable"

export default function OptionButton({
  onPress,
  vertical,
  size,
  hoverColor,
  color,
  style
}) {
  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  return (
    <CustomPressable
      style={[styles.moreBtnBg, style, hover && { backgroundColor: hoverColor ? Colors[hoverColor] : Colors.white }]}
      onPress={onPress}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      accessibilityRole="button"
      accessibilityLabel={txt('options.optionButton')}
      accessibilityHint={txt('options.optionButtonHint')}
      hitSlop={{ left: 10, right: 20 }}
    >
      <NormalIcon
        style={styles.moreBtn}
        color={color ? Colors[color] : Colors.black}
        name={vertical ? "ellipsis-v" : "ellipsis-h"}
        size={size}
        stroke="far"
      />
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  moreBtn: {
    opacity: 0.4,
    padding: 6,
  },
  moreBtnBg: {
    borderRadius: 100,
  },
})
