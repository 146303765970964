export const borderRadiusS = {
  borderRadius: 12,
}

export const borderRadiusM = {
  borderRadius: 24,
}

export const borderRadiusL = {
  borderRadius: 36,
}

export const roundImg = {
  borderRadius: 300,
}
