import { apiPost, apiGet, apiPut, apiDelete } from './ApiRequest'

export async function getPostFeedIds({search, universeId, offset, birthYearLow, birthYearHigh, genders, maxDistance}) {
  let url = `posts/feed-ids?search=${search??''}&offset=${offset}&birthYearLow=${birthYearLow}&birthYearHigh=${birthYearHigh}&genders=${genders}&maxDistance=${maxDistance}`
  if (universeId) {
    url = url + `&universeId=${universeId}`
  }
  const { postIds, universePostIds } = await apiGet(url)
  return { postIds, universePostIds }
}

export async function getPostsFromIds(ids) {
  try {
    return apiGet(`posts/?ids=[${ids}]`)
  } catch (e) {
    return []
  }
}

export async function getFavoritePosts(universeId) {
  const res = await apiGet(`posts/favorites?universeId=${universeId}`)
  if (!res.data) return []
  return res.data
}

export async function getOwnPosts(universeId) {
  const res = await apiGet(`posts/own?universeId=${universeId}`)
  if (!res.data) return []
  return res.data
}

export async function getFullPost(postId, universeId) {
  if (!postId) return
  const res = await apiGet(`posts/${postId}?universeId=${universeId}`)
  return res.data?.[0]
}

export async function updatePost(postId, data) {
  const res = await apiPut(data, `posts/${postId}`)
  if (res?.status === 429) return res // 429 Too Many Requests = Update limit reached
  const resJson = await res?.json()
  return resJson.updated
}

export async function createPost(data) {
  const res = await apiPost(data, 'posts')
  const resJson = await res.json()
  return resJson
}

export async function setPostFavorite(postId, favorite) {
  let res
  if (favorite) {
    res = await apiDelete({}, `posts/${postId}/favorite`)
  } else {
    res = await apiPost({}, `posts/${postId}/favorite`)
  }
  return res.json()
}

export async function deletePost(postId) {
  await apiDelete({}, `posts/${postId}`)
}

export async function getPostsByUserId(user_id, universeId) {
  const res = await apiGet(`posts/user/${user_id}?universeId=${universeId}`)
  if (!res.data) return
  return res.data
}

export async function hideAllButNewestPost() {
  let res = await apiPut({}, 'posts/update-visibility')
  if (!res) return false
  res = await res.json()
  return res?.updated
}

/** INFO
 * This request handles all searches
 * but may in the future be implemented
 * specific for Posts, Unions, Activities etc.
 */
export async function saveSearchString({searchString, birthYearLow, birthYearHigh, genders, maxDistance}) {
  await apiPost({
    searchString,
    birthYearLow,
    birthYearHigh,
    genders,
    maxDistance,
  }, 'posts/saveSearchString')
}
