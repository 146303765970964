import React from 'react'
import Activity from '../../components/views/Activities/Activity'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class ActivityScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  navigateToEditActivity = (activityId) => {
    this.props.navigation.navigate('EditActivity', {
      id: activityId,
    })
  }

  render() {
    return (
      <MainContainer>
        <Activity
          navigation={this.props.navigation}
          id={this.props.route.params.id}
          date={this.props.route.params.date}
          navigateToEditActivity={this.navigateToEditActivity}
          route={this.props.route}
        />
      </MainContainer>
    )
  }
}
