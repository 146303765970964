// This document contains (T)Titles and (MD)MetaDescriptions, primarly for SEO purposes
export default {
  // [Danish, English]
  // Current language, TODO: make into function and get from AsyncStorage/DB

  lang: 0,
  frontpage: {
    title: ['Boblberg.dk | En digital platform for fællesskaber'],
  },
  login: {
    title: ['Log ind | Boblberg.dk'],
  },
  resetPassword: {
    title: ['Nulstil adgangskode | Boblberg.dk'],
  },
  home: {
    title: ['Bobl oversigt | Boblberg.dk'],
  },
  inbox: {
    title: ['Besked oversigt | Boblberg.dk'],
  },
  createPost: {
    title: ['Opret bobl | Boblberg.dk'],
  },
  stackMenu: {
    title: ['Menu | Boblberg.dk'],
  },
  favorites: {
    title: ['Favoritter | Boblberg.dk'],
  },
  myPosts: {
    title: ['Mine bobler | Boblberg.dk'],
  },
  snakSammen: {
    title: ['SnakSammen | Boblberg.dk'],
    md: [
      'SnakSammen er en snak mellem to mennesker. En frivillig samtaleven fra Røde Kors og dig. I kan snakke om alt: om fælles interesser, om dagens begivenheder og dagens nyheder. Eller lidt om familie, hverdagen eller oplevelser. De frivillige samtalevenner er bare helt almindelige mennesker, og hverken rådgivere eller eksperter.',
    ],
  },
  learnDanish: {
    title: ['Sprogland | Boblberg.dk'],
    md: [
      'Sprogland.dk - her har du mulighed for at træne dansk med frivillige fra Røde Kors. I kan for eksempel træne ”hverdagsdansk” eller du kan få hjælp til at øve ord og udtale.',
    ],
  },
  stories: {
    title: ['Historier fra medlemmer | Boblberg.dk'],
    md: [
      'Her kan du læse nogle af de gode historier, erfaringer og oplevelser som medlemmer har haft igennem Boblberg.dk. Fælles for historierne er at de er skabt ud fra oprettede bobler og kontakt med andre medlemmer. Her er fokus på de gode oplevelser og fællesskaber, som man har mulighed for at blive en del af gennem Boblberg.',
    ],
  },
  story: {
    md: [
      'Her kan du læse nogle af de gode historier, erfaringer og oplevelser som medlemmer har haft igennem Boblberg.dk. Fælles for historierne er at de er skabt ud fra oprettede bobler og kontakt med andre medlemmer.',
    ],
  },
  about: {
    title: ['Om Boblberg | Boblberg.dk'],
    md: [
      'På denne oversigtsside kan du blive guidet videre rundt til forskellige undersider, med mere information om Boblberg og hvad du kan bruge det til.',
    ],
  },
  contact: {
    title: ['Kontakt os, support og vejledning | Boblberg.dk'],
    md: [
      'Find kontaktinformation til vores support, som du altid er velkommen til at skrive til. Find svar på de oftest stillede spørgsmål, se vores betingelser, privatlivspolitik og cookiepolitik.',
    ],
  },
  help: {
    title: ['Hjælp og info | Boblberg.dk'],
    md: [
      'På siden hjælp og vejledning kan du finde svar på de mest stillede spørgsmål omkring Boblberg. Få blandt andet råd til, hvordan du opretter en god bobl.',
    ],
  },
  conditions: {
    title: ['Betingelser | Boblberg.dk'],
  },
  profile: {
    title: ['Min profil | Boblberg.dk'],
  },
  partners: {
    title: ['Samarbejdspartnere | Boblberg.dk'],
    md: [
      'Boblberg samarbejder med en lang række organisationer og foreninger om at styrke den fysiske, mentale og sociale sundhed. Sammen kæmper vi for at skabe et sundere og bedre liv for alle, så menneskers trivsel og livskvalitet øges og samskabelsen fremmes.',
    ],
  },
  subjects: {
    title: ['Emner | Boblberg.dk'],
    md: [
      'Her finder du en oversigt over hvilke emner Boblberg blandt andet kan bruges til. Du kan bruge listen til at blive inspireret til hvordan du kan bruge Boblberg, og læse hvordan andre har brugt siden inden',
    ],
  },
  press: {
    title: ['Presse - Omtale i medierne, nyheder, tv og radio | Boblberg.dk'],
    md: [
      'Ønsker du at se den omtale der har været af Boblberg i pressen? Her på siden kan du finde et bredt udvalg af artikler, tv-indslag og radioindslag, som er bragt om Boblberg i pressen',
    ],
  },
  blog: {
    title: ['Blog | Boblberg.dk'],
    md: [
      'På Boblbergs blog samler vi spændende og hjælpsomme indlæg. Her på siden kan du finde forskellige blog indlæg omkring alverdens emner, som har at gøre med noget af alt det, som kan findes på Boblberg.',
    ],
  },
  projects: {
    title: ['Projekter | Boblberg.dk'],
    md: [
      'Læs om de projekter Boblberg er en del af, sammen med forskellige kommuner og oganisationer, inden for emner som mental sundhed og demens',
    ],
  },
  arrangements: {
    title: ['Arrangementer | Boblberg.dk'],
    md: [
      'Boblberg deltager i et bredt udvalg af arrangementer. Her har du mulighed for at se nogle af de arrangementer vi tidligere har deltaget ved.',
    ],
  },
  faellesskabsprisen: {
    title: ['Fællesskabsprisen | Boblberg.dk'],
    md: [
      'Hele konceptet hos Boblberg er, at skabe sammenhold, relationer og ikke mindst fællesskaber. Derfor er vi selvfølgelig også rigtig stolte over at modtage Fællesskabsprisen 2019.',
    ],
  },
  municipalityInfo: {
    title: ['Info til kommuner | Boblberg.dk'],
    md: [
      'Sammen med kommunerne arbejder Boblberg på at højne og fremme et mangfoldigt fritidsliv, som styrker de sociale fællesskaber i de enkelte kommuner.',
    ],
  },
  aboutUs: {
    title: ['Om os | Boblberg.dk'],
    md: ['Her på siden kan du se hvem der er en del af teamet hos Boblberg'],
  },
  unions: {
    title: ['Foreningsoversigt | Boblberg.dk'],
  },
  groups: {
    title: ['Grupper | Boblberg.dk'],
    md: [
      'Gruppemodulet på Boblberg.dk gør det mulig at samle mennesker, i lukkede grupper, hvor de kan dele viden og kommunikerer sammen',
    ],
  },
  myGroups: {
    title: ['Mine grupper | Boblberg.dk'],
  },
  allGroups: {
    title: ['Alle grupper | Boblberg.dk'],
  },
  createGroup: {
    title: ['Opret gruppe | Boblberg.dk'],
  },
  activities: {
    title: ['Aktivitetsoversigt | Boblberg.dk'],
  },
  activitiesFavorites: {
    title: ['Aktiviteter Favoritter | Boblberg.dk'],
  },
  activitiesIntro: {
    title: ['Aktiviteter | Boblberg.dk'],
    md: ['Her kan du finde en oversigt over de aktiviteter, der finder sted i dit nærområde.'],
  },
  unionsFavorites: {
    title: ['Foreninger Favoritter | Boblberg.dk'],
  },
  conversation: {
    title: ['Samtale | Boblberg.dk'],
  },
  events: {
    title: ['Eventoversigt | Boblberg.dk'],
  },
}
