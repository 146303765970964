import { apiGet } from './ApiRequest'

export async function getPress({ search, types, skip, amount }) {
  const press = await apiGet(
    `press?search=${search}&types=${types}&skip=${skip}&amount=${amount}`
  )
  return press.press
}

export async function getPressById(id) {
  const press = await apiGet(`press/${id}`)
  return press.press
}
