import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'
import { URLOpener } from '../../../../utils/URLOpener'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

export default class Rikke extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.rikke.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/rikke.jpg',
              }}
              accessibilityIgnoresInvertColors
            />
            <CustomText font="bold">{txt('blog.rikke.author')}</CustomText>
          </View>
          <CustomText>{txt('blog.rikke.text1')}</CustomText>
          <Image
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-reservebedste-1.jpg',
            }}
            resizeMode="contain"
            style={styles.imageBig}
            accessibilityIgnoresInvertColors
          />
          <CustomText font="bold" style={styles.marginBottom}>
            {txt('blog.rikke.headline1')}
          </CustomText>
          <View>
            <CustomText>{txt('blog.rikke.text2')}</CustomText>
            <CustomPressable
              onPress={() =>
                URLOpener(
                  'https://www.aeldresagen.dk/viden-og-raadgivning/hverdagsliv/boerneboern/gode-raad/derfor-er-bedsteforaeldre-vigtige'
                )
              }
              style={styles.marginTop}
            >
              <CustomText font="small">
                {txt('blog.rikke.text2Note')}
              </CustomText>
            </CustomPressable>
          </View>
          <CustomText>{txt('blog.rikke.text22')}</CustomText>
          <CustomText font="bold" style={styles.marginBottom}>
            {txt('blog.rikke.headline2')}
          </CustomText>
          <View>
            <CustomText>{txt('blog.rikke.text3')}</CustomText>
          </View>

          <Image
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-reservebedste-2.jpg',
            }}
            resizeMode="contain"
            style={styles.imageBig}
            accessibilityIgnoresInvertColors
          />
          <CustomText>{txt('blog.rikke.text4')}</CustomText>

          <CustomText font="bold" style={styles.marginBottom}>
            {txt('blog.rikke.headline3')}
          </CustomText>

          <CustomText>{txt('blog.rikke.text5')}</CustomText>

          <Image
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-reservebedste-3.jpg',
            }}
            resizeMode="contain"
            style={styles.imageBig}
            accessibilityIgnoresInvertColors
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  imageBig: { alignSelf: 'center', height: 300, width: width * 0.9 },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
