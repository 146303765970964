import React, { useState, useRef } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Fontsizes from '../../../constants/Fontsizes'
import Colors from '../../../constants/Colors'
import NormalIcon from '../../icons/NormalIcon'
import { URLOpener } from '../../../utils/URLOpener'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import { ShadowStyles, SpacingStyles } from '../../../styles'
import Layout from '../../../constants/Layout'
import { FilterButton } from '../../chips/FilterButton'
import SearchList from '../../lists/SearchList'
import { getPress } from '../../../services/api/Press'
import PressContact from './PressContact'
import moment from 'moment'
import CustomPressable from "../../pressables/CustomPressable"

export default function Press(props) {
  const [filter, setFilter] = useState({
    article: false,
    radio: false,
    tv: false,
  })
  const prevFilters = useRef([])

  async function fetchPress({ search, skip, amount }) {
    let types = []

    const typeCheck = [
      filter.article ? 'article' : null,
      filter.radio ? 'radio' : null,
      filter.tv ? 'video' : null,
    ]

    types = typeCheck.filter((type) => type != null)

    const press = await getPress({
      search,
      types: types,
      skip,
      amount,
    })
    return press
  }

  const triggerUpdate = () => {
    const isTriggable = shouldUpdate()
    if (isTriggable) {
      prevFilters.current = filter

      return isTriggable
    }
    return isTriggable
  }

  const shouldUpdate = () => {
    if (prevFilters.current !== filter) return true
    return false
  }

  const updateFilter = (mediaType) => {
    const newFilter = { ...filter }
    newFilter[mediaType] = !filter[mediaType]
    setFilter(newFilter)
  }

  const isSmallDevice = Dimensions.get('window').width < 375

  const Icon = ({ iconName, mediaType, selected }) => {
    return (
      <View style={styles.iconStyle}>
        <FilterButton
          mode={'outlined'}
          onPress={() => updateFilter(mediaType)}
          iconName={iconName}
          text={txt('pressChip.' + mediaType)}
          selected={selected}
        />
      </View>
    )
  }

  const FilterIcons = () => {
    return (
      <View style={styles.iconContainer}>
        {/* Article */}
        <Icon
          iconName="newspaper"
          mediaType={'article'}
          menuItemValue={2}
          isSmallScreen={isSmallDevice}
          selected={filter.article}
        />
        {/* Video */}
        <Icon
          iconName="tv-retro"
          mediaType={'tv'}
          menuItemValue={1}
          isSmallScreen={isSmallDevice}
          selected={filter.tv}
        />
        {/* Radio */}
        <Icon
          iconName="radio-alt"
          mediaType={'radio'}
          menuItemValue={3}
          isSmallScreen={isSmallDevice}
          selected={filter.radio}
        />
      </View>
    )
  }

  const pressItem = ({ item, index }) => {
    return (
      <View key={index}>
        <CustomPressable style={styles.itemContainer} onPress={() => URLOpener(item.url)}>
          <NormalIcon
            name={
              item.type === 'radio'
                ? "radio-alt"
                : item.type === 'video'
                ? "tv-retro"
                : "newspaper"
            }
            size={Fontsizes.xxl}
            style={{ paddingRight: 12 }}
            accessibilityLabel={
              item.type === "radio-alt"
                ? txt('press.icons.radio')
                : item.type === "tv-retro"
                ? txt('press.icons.tv')
                : txt('press.icons.article')
            }
          />

          <View style={styles.textContainer}>
            <CustomText font="bold">{item.media}</CustomText>
            <CustomText>{item.title}</CustomText>
            <CustomText style={styles.dateText} font="small">
              {moment(item.date).format('DD-MM-YYYY')}
            </CustomText>
          </View>
        </CustomPressable>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <PageTitle title={TxtTMD.press.title[TxtTMD.lang]} />
      <PressContact />
      <SearchList
        renderItem={pressItem}
        getData={fetchPress}
        triggerUpdate={triggerUpdate}
        filterAbove={() => <FilterIcons />}
        navigation={props.navigation}
        center
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
  },
  dateText: {
    alignSelf: 'flex-end',
    backgroundColor: Colors.skyBlue,
    borderColor: Colors.transparent,
    borderRadius: 15,
    overflow: 'hidden',
    padding: 6,
    paddingHorizontal: 12,
    textAlign: 'center',
  },
  iconContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    marginTop: 6,
  },
  iconStyle: { marginBottom: 12, marginLeft: 3, marginRight: 3 },
  itemContainer: {
    alignItems: 'center',
    ...ShadowStyles.shadowInputs,
    backgroundColor: Colors.white,
    borderRadius: 20,
    flexDirection: 'row',
    marginHorizontal: 12,
    marginTop: 12,
    padding: 12,
  },
  textContainer: {
    flex: 1,
  },
})
