import React from 'react'
import Profile from '../../components/views/Profile/Profile'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useNavigation } from '@react-navigation/core'

export default function ProfileScreen() {
  const navigation = useNavigation()

  function navigateToProfileView() {
    navigation.navigate('ProfileView')
  }

  return (
    <MainContainer>
      <PageTitle title={TxtTMD.profile.title[TxtTMD.lang]} />
      <Profile navigateToProfileView={navigateToProfileView} />
    </MainContainer>
  )
}
