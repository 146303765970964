import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Keyboard, StyleSheet, View, Platform } from 'react-native'
import { CommonActions } from '@react-navigation/routers'
import { useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { List } from '../../lists/List'
import NormalIcon from '../../icons/NormalIcon'
import DatePicker from '../../dates/DatePicker'
import { SearchBar } from '../../text/SearchBar'
import { CustomText } from '../../text/StyledText'
import CategoryDialog from '../../dialogs/CategoryDialog'
import CustomPressable from '../../pressables/CustomPressable'
import MunicipalityFilter from '../../shared/MunicipalityFilter'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import { useUniverseConfig } from '../../../hooks/UniverseConfig'

import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import Colors from '../../../constants/Colors'
import { ShadowStyles } from '../../../styles'

import ActivityListItem from './ActivityListItem'
import ActivitiesMap from '../../maps/ActivitiesMap'
import * as ActivityService from '../../../services/api/Activities'

const universeOnlyIds = [13 /* krifa */]

export default function ActivityFeed(props) {
	moment.locale('da')
	const navigation = useNavigation()
	const universeActivityCountRef = useRef(0)
	const universeConfig = useUniverseConfig()
	const user = useSelector((state) => state.user)

	// data
	const [activities, setActivities] = useState([])
	const [pins, setPins] = useState([])

	// filters
	const [searchText, setSearchText] = useState('')
	const [dateSelected, setDateSelected] = useState(new Date())
	const [categoriesSelected, setCategoriesSelected] = useState([])
	const [municipalitySelected, setMunicipalitySelected] = useState(String(user?.municipality_id))

	// states
	const [loading, setLoading] = useState(true)
	const [refreshing, setRefreshing] = useState(false)
	const [endReached, setEndReached] = useState(false)
	const [mapVissible, setMapVissible] = useState(false)
	const [componentVissible, setComponentVissible] = useState(true)
	const [activitySelected, setActivitySelected] = useState(null)
	const [municipalityDialogVissible, setMunicipalityDialogVissible] = useState(false)

	const queryActivities = useCallback(
		async (skip = 0) => {
			setEndReached(false)
			setLoading(true)

			const amount = 20
			const activities = []
			const overide = skip === 0

			if (overide) {
				universeActivityCountRef.current = 0
				setActivities([])
				setPins([])
			}

			const query = { search: searchText, amount, skip, date: dateSelected, mapBounds: null }
			if (categoriesSelected.length !== 0) query.categories = categoriesSelected

			if (municipalitySelected) query.municipality = municipalitySelected
			if (universeOnlyIds.includes(universeConfig?.id)) query.municipality = 'undefined'

			if (universeConfig && universeActivityCountRef.current === query.skip) {
				const universe = await ActivityService.getActivities({ ...query, universeId: universeConfig.id })
				universeActivityCountRef.current += universe.activities.length

				activities.push(...universe.activities)
				setPins(universe.pins)
			}

			if (activities.length < amount) {
				query.skip = Math.max(0, query.skip - universeActivityCountRef.current)
				const boblberg = await ActivityService.getActivities(query)

				activities.push(...boblberg.activities)
				if (universeConfig === null) setPins(boblberg.pins)
			}

			if (activities.length < query.amount) setEndReached(true)
			setActivities((current) => (overide ? activities : [...current, ...activities]))
			setLoading(false)
		},
		[searchText, municipalitySelected, categoriesSelected, dateSelected, universeConfig]
	)

	useEffect(() => {
		const unsubscribeFocus = navigation.addListener('focus', () => setComponentVissible(true))
		const unsubscribeBlur = navigation.addListener('blur', () => setComponentVissible(false))

		return () => {
			unsubscribeFocus()
			unsubscribeBlur()
		}
	}, [])

	useEffect(() => {
		queryActivities(0)
	}, [queryActivities])

	useEffect(() => {
		if (municipalitySelected === null) return
		navigation.dispatch(CommonActions.setParams({ municipality: municipalitySelected }))
	}, [municipalitySelected])

	const activityNavigation = (activityId, date) => {
		const options = { id: activityId, date }
		if (universeConfig) options.universe = universeConfig.name
		navigation.push('Activity', options)
	}

	const paginate = async () => {
		if (loading || endReached) return
		await queryActivities(activities.length)
	}

	const refresh = async () => {
		setRefreshing(true)
		await queryActivities(0)
		setRefreshing(false)
	}

	const toggleMap = () => {
		setMapVissible((current) => !current)
		setActivitySelected(null)
	}

	const renderActivity = ({ item, index }) => {
		const universeId = universeConfig?.id ?? null

		if (universeId === null) {
			return <ActivityListItem item={item} onPressAction={() => activityNavigation(item.id, item.date_start)} />
		}

		const universeIdCurrent = item.universe_id ?? null
		const universeIdPrevious = activities[index - 1]?.universe_id ?? null

		const emptyUniverseResult = index === 0 && universeIdCurrent === null
		const separationPoint = universeIdPrevious === universeId && universeIdCurrent === null

		return (
			<>
				{(separationPoint || emptyUniverseResult) && (
					<CustomText style={styles.separator}>{txt('listSeparators.activities')}</CustomText>
				)}

				<ActivityListItem item={item} onPressAction={() => activityNavigation(item.id, item.date_start)} />
			</>
		)
	}

	const renderListFooter = () => {
		if (loading) return <LoadingSpinner />

		if (activities.length === 0) {
			return <CustomText style={styles.listFooter}>{txt('noResults.noSearchResults')}</CustomText>
		}

		return null
	}

	const getDisplayValue = (contentType) => {
		if (Layout.isSmallDevice === false) return 'flex'
		if (contentType === 'map') return mapVissible ? 'flex' : 'none'
		if (contentType === 'list') return mapVissible === false ? 'flex' : 'none'
		return undefined
	}

	const renderHeader = () => {
		const showDate = moment().format('YYYY-MM-DD') !== moment(dateSelected).format('YYYY-MM-DD')

		const renderDatePickerCustomInput = ({ value, onClick }) => (
			<CustomPressable accessibilityRole="button" onPress={onClick} style={styles.datePickerCustomInput}>
				<NormalIcon name="calendar-alt" size={15} color={Colors.text} />
				{showDate ? <CustomText>{value}</CustomText> : <CustomText>{txt('activities.date')}</CustomText>}
			</CustomPressable>
		)

		return (
			<View style={[styles.header, { backgroundColor: universeConfig?.backgroundColor ?? Colors.skyBlue }]}>
				{props.renderChips()}
				<SearchBar value={searchText} onChangeText={setSearchText} />

				<View style={styles.filters}>
					<CategoryDialog selectedCategories={categoriesSelected} setCategories={setCategoriesSelected} />

					<DatePicker
						date={dateSelected}
						dateFormat="dd-MM-yyyy"
						handleDateChange={setDateSelected}
						minDate={new Date()}
						maxDate={new Date().setFullYear(new Date().getFullYear() + 5)}
						CustomInput={renderDatePickerCustomInput}
					/>

					{universeConfig === null && (
						<MunicipalityFilter
							onChange={setMunicipalitySelected}
							municipalityId={municipalitySelected}
							focus={municipalityDialogVissible}
							setFocus={setMunicipalityDialogVissible}
							description={txt('activities.municipalityDialog.body')}
							feature={2}
						/>
					)}
				</View>
			</View>
		)
	}

	return (
		<View style={[styles.container, props.style]}>
			<View style={[styles.listContainer, { display: getDisplayValue('list') }]}>
				<List
					data={activities}
					loading={loading}
					onEndReached={paginate}
					refreshing={Platform.OS !== 'web' && refreshing}
					onRefresh={Platform.OS !== 'web' ? refresh : undefined}
					renderItem={renderActivity}
					keyExtractor={({ id, date_start }) => String(id) + date_start}
					AnimatedHeader={renderHeader}
					ListFooterComponent={renderListFooter}
					onScroll={() => Keyboard.dismiss()}
				/>
			</View>

			<View style={[Layout.isSmallDevice ? { flex: 1 } : styles.mapContainer, { display: getDisplayValue('map') }]}>
				{componentVissible && (
					<ActivitiesMap
						selectedActivity={activitySelected}
						setSelectedActivity={setActivitySelected}
						navigateToActivity={activityNavigation}
						pins={pins}
					/>
				)}
			</View>

			{Layout.isSmallDevice && (
				<CustomPressable onPress={toggleMap} style={[styles.mapButton, { bottom: activitySelected ? 148 : 16 }]}>
					<NormalIcon name={mapVissible ? 'list' : 'map'} stroke="fal" size={30} color={Colors.white} />
				</CustomPressable>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		flexDirection: 'row',
		marginHorizontal: Layout.isSmallDevice ? 0 : 12,
	},

	header: {
		gap: 6,
		paddingVertical: 10,
		paddingHorizontal: 12,
	},

	listContainer: {
		flex: 1,
		maxWidth: Layout.maxFeedWidth,
	},

	separator: {
		marginVertical: 12,
		textAlign: 'center',
		color: Colors.black,
	},

	listFooter: {
		marginVertical: 12,
		textAlign: 'center',
		color: Colors.inactive,
	},

	filters: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 8,
	},

	datePickerCustomInput: {
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
		marginHorizontal: 8,
		gap: 6,
	},

	mapContainer: {
		flex: 1,
		marginBottom: 10,
		marginLeft: 10,
		marginRight: 10,
		maxWidth: Layout.maxFeedWidth,
	},

	mapButton: {
		width: 64,
		aspectRatio: 1,
		borderRadius: 64,
		position: 'absolute',
		right: 16,

		alignItems: 'center',
		justifyContent: 'center',

		backgroundColor: Colors.blue,
		...ShadowStyles.dropShadow,
	},
})
