import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import MainContainer from '../../../components/MainContainer/MainContainer'
import ChristmasPostItem from '../christmasFeed/ChristmasPostItem'
import { FlatList, View } from 'react-native'
import {
  confirmMatch,
  createChristmasPost,
  getChristmasPost,
  updateChristmasPost,
} from '../../../services/api/ChristmasFriends'
import { CustomText } from '../../../components/text/StyledText'
import { StyleSheet } from 'react-native'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import { ButtonPrimary } from '../../../components/buttons/StyledButtons'
import MatchQuestionDialog from '../../components/match/MatchQuestionDialog'
import MatchChooseDialog from '../../components/match/MatchChooseDialog'
import { CustomSwitch } from '../../../components/switches/StyledSwitches'
import PostHiddenDialog from '../../components/match/PostHiddenDialog'
import store from '../../../redux/store'
import { setChristmasMatchDialogVisible } from '../../../redux/actions'
import AfterMatchDialog from '../../components/match/AfterMatchDialog'
import { useFocusEffect } from '@react-navigation/native'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function MyPostsChristmasScreen() {
  const myPost = useSelector((state) => state.christmas.post)
  const conversations = useSelector((state) => state.conversations)
  const [posts, setPosts] = useState([])
  const [showMatchQuestion, setShowMatchQuestion] = useState(false)
  const [showMatchChoose, setShowMatchChoose] = useState(false)
  const [showPostHidden, setShowPostHidden] = useState(false)

  useFocusEffect(
    useCallback(() => {
      getChristmasPosts()
    }, [conversations])
  )

  async function getChristmasPosts() {
    const posts = []
    for (let i = 0; i < conversations.length; i++) {
      if (conversations[i].christmas_post_id) {
        const post = await getChristmasPost(conversations[i].christmas_post_id)
        if (post) posts.push(post)
      }
    }
    setPosts(posts)
  }

  function handleConfirmQuestion() {
    setShowMatchQuestion(false)
    setShowMatchChoose(true)
  }

  function handleDismissQuestion() {
    setShowMatchQuestion(false)
  }

  async function handleConfirmMatch(postId) {
    setShowMatchChoose(false)
    await confirmMatch(postId)
    if (myPost.type === 'guest') setShowPostHidden(true)
    else store.dispatch(setChristmasMatchDialogVisible(true))
  }

  function handleDismissChoose() {
    setShowMatchChoose(false)
    setShowMatchQuestion(true)
  }

  async function toggleHidePost(show) {
    const replacePostFromLastYear = myPost.created.slice(0,4) < new Date().getFullYear() && show
    if(replacePostFromLastYear) {
      return createChristmasPost(myPost)
    }
    const status = show ? 1 : -1
    await updateChristmasPost({ status })
    if (!show) setShowMatchQuestion(true)
  }

  function handleDismissHidePost() {
    setShowPostHidden(false)
    store.dispatch(setChristmasMatchDialogVisible(true))
  }

  function hidePostSwitch() {
    return (
      <CustomPressable style={{ alignItems: 'center', marginLeft: 6 }}>
        <CustomText font="small">
          {txt(`ownPosts.${myPost.status > 0 ? 'visible' : 'hidden'}`)}
        </CustomText>
        <CustomSwitch
          style={{ margin: 0, marginTop: 6 }}
          value={myPost.status > 0}
          onValueChange={toggleHidePost}
        />
      </CustomPressable>
    )
  }

  return (
    <MainContainer>
      <View style={styles.container}>
        <View>
          <CustomText font="bold" style={styles.title}>
            {txt('christmasFriends.mine.myPost')}
          </CustomText>
          <ChristmasPostItem post={myPost} rightElement={hidePostSwitch} />
          <ButtonPrimary
            title={txt('christmasFriends.match.buttonTitle')}
            style={styles.matchButton}
            onPress={() => setShowMatchQuestion(true)}
          />
        </View>

        <CustomText font="bold" style={styles.title}>
          {txt('christmasFriends.mine.conversationPosts')}
        </CustomText>
        {posts.length > 0 ? (
          <FlatList
            data={posts}
            renderItem={({ item }) => <ChristmasPostItem post={item} />}
            keyExtractor={(item, index) => index.toString()}
          />
        ) : (
          <CustomText style={{ margin: 12 }} font="italic">
            {txt('christmasFriends.mine.conversationPostsPlaceholder')}
          </CustomText>
        )}
      </View>

      <MatchQuestionDialog
        visible={showMatchQuestion}
        confirm={handleConfirmQuestion}
        dismiss={handleDismissQuestion}
      />
      <MatchChooseDialog
        visible={showMatchChoose}
        confirm={handleConfirmMatch}
        dismiss={handleDismissChoose}
      />
      <PostHiddenDialog
        visible={showPostHidden}
        dismiss={handleDismissHidePost}
      />
      <AfterMatchDialog />
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
  },
  title: { margin: 12, marginTop: 6 },
  matchButton: {
    alignSelf: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
})
