import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import OptionsList from '../../components/lists/OptionsList'
import { txt } from '../../locales/i18n'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import Colors from '../../constants/Colors'
import { useSelector } from 'react-redux'
import { joinUniverseById } from '../../services/api/Universe'
import layout from '../../constants/Layout'
import { URLOpener } from '../../utils/URLOpener'
import Paths from '../../constants/Paths'

export default function StackChristmasMenuScreen(props) {
	const universes = useSelector((state) => state.user?.universes)

	const data = [
		{
			body: txt('christmasFriends.faq.title'),
			icon: { name: "info-circle" },
			onPress: () => { props.navigation.navigate('ChristmasFAQ') },
		},
		{
			body: txt('christmasFriends.afterSignup.title'),
			icon: { name: "candy-cane" },
			onPress: () => { props.navigation.navigate('AfterSignupChristmas') },
		},
		{
			body: txt('christmasFriends.afterMatch.title'),
			icon: { name: "handshake-alt" },
			onPress: () => { props.navigation.navigate('AfterMatchChristmas') },
		},
		{
			body: txt('christmasFriends.stories'),
			icon: { name: "book-open" },
			onPress: () => URLOpener(Paths.getBaseUrl() + 'julevenner/julehistorier'),
		},
		{
			body: txt('christmasFriends.together'),
			icon: { name: "handshake-alt" },
			onPress: () => URLOpener(Paths.getBaseUrl() + 'julevenner/sammen-om-julevenner'),
		},
		{
			body: txt('christmasFriends.contact.title'),
			icon: { name: "phone-alt" },
			onPress: () => { props.navigation.navigate('Contact') },
		},
		{
			body: txt('profile.title'),
			icon: { name: "user" },
			onPress: () => { props.navigation.navigate('Profile') },
		},
		{
			body: txt('settings.title'),
			icon: { name: "cog" },
			onPress: () => { props.navigation.navigate('Settings') },
		},
	]

  if (Platform.OS === 'web') {
    const cookieButton = {
      body: txt('cookieLabel'),
      onPress: () => showCookieBanner(),
      icon: {
        name: "cookie-bite",
      },
    }

    // adds cookie button to second last position
    data.splice(data.length - 1, 0, cookieButton)
  }

  universes?.forEach((universe) => {
    if (universe.name === 'nytaarsvenner') return
    const universeData = {
      body: txt('boblberg.goTo') + universe.title,
      onPress: () => {
        props.navigation.push('universe', {
          screen: 'UniverseBoblStack',
          params: {
            screen: 'Bobls',
            params: {
              universe: universe.name,
            },
          },
        })
      },
      icon: {
        name: "bubbles",
        boblbergIcon: true,
      },
    }
    data.unshift(universeData)
  })

  data.unshift({
    body: txt('boblberg.goTo') + txt('stackMenu.nytaarsvenner'),
    onPress: async () => {
      const universe = universes.find((uni) => uni.name === 'nytaarsvenner')
      if (!universe) await joinUniverseById(10)
      props.navigation.push('universe', {
        screen: 'UniverseBoblStack',
        params: {
          screen: 'Universe',
          initial: true,
          params: {
            universe: 'nytaarsvenner',
          },
        },
      })
    },
    icon: {
      name: "glass-cheers",
    },
  })

  data.unshift({
    body: txt('boblberg.goToBoblberg'),
    onPress: () => {
      props.navigation.navigate('app')
    },
    icon: {
      name: "bobl",
      boblbergIcon: true,
    },
  })

  return (
    <MainContainer backgroundColor={Colors.background}>
        <PageTitle title={TxtTMD.stackMenu.title[TxtTMD.lang]} />
        <View style={styles.container}>
          <OptionsList data={data}/>
        </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
container: {
	alignSelf: 'center',
	width: '100%',
	maxWidth: layout.maxFeedWidth,
},
})
