import React from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../constants/Colors'
import { cardShadow } from '../styles/shadows'
import CustomPressable from "./pressables/CustomPressable"

export default function Card({ style, children, largeRadius, onPress, ...props }) {
''
  const containerStyle = [styles.container, { borderRadius: largeRadius ? 36 : 12 }, style]

  if (onPress) {
    return <CustomPressable onPress={onPress} style={containerStyle} {...props}>{children}</CustomPressable>
  } else {
    return <View style={containerStyle} {...props}>{children}</View>
  }

}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    ...cardShadow
  }
})