import React from 'react'
import { StyleSheet, FlatList, View } from 'react-native'
import CustomDialog from './CustomDialog'
import { CustomText } from '../text/StyledText'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { borderRadiusS } from '../../styles/corners'
import CustomPressable from "../pressables/CustomPressable"

const OptionsDialog = (props) => (
  <CustomDialog onDismiss={props.onDismiss} options={true} style={{ ...borderRadiusS }} {...props}>

    {props.title && (
      <View style={styles.titleContainer}>
        <CustomText style={{ color: Colors.inactive, textAlign: 'center' }}>
          {props.title}
        </CustomText>
      </View>
    )}
    
    <FlatList
      data={props.options}
      style={{flexGrow: 0}}
      keyExtractor={(item, index) => index.toString()}
      renderItem={({ item, i }) => {
        const last = props.options.length === i - 1
        const danger = item.type === 'danger'
        return (
          <CustomPressable
            style={[styles.option, last && styles.noBorder]}
            onPress={item.action}
            accessible
            accessibilityRole="button"
            accessibilityLabel={item.label || ''}
            accessibilityHint={item.hint || ''}
          >
            <CustomText
              font={props.font || 'bold'}
              style={[{flexWrap: 'wrap'}, danger && { color: Colors.danger }, !item.icon && { marginRight: 12}]}
              accessible={false}
            >
              {item.label}
            </CustomText>
            {item.icon && (
              <NormalIcon
                accessible={false}
                size={20}
                color={danger ? Colors.danger : Colors.text}
                style={styles.icon}
                name={item.icon}
              />
            )}
          </CustomPressable>
        )
      }}
    />
  </CustomDialog>
)

const styles = StyleSheet.create({
  icon: {
    marginLeft: 24,
  },
  noBorder: {
    borderWidth: 0,
  },
  titleContainer: {
    display: 'flex',
    borderBottomWidth: 1,
    borderColor: Colors.blackTransparent,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  option: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.blackTransparent,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
})

export default OptionsDialog
