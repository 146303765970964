import React from 'react'
import {
  View,
  ScrollView,
  StyleSheet,
  Dimensions,
  Image,
  Linking,
} from 'react-native'
import Colors from '../../../constants/Colors'
import { SpacingStyles, TypographyStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import PageTitle from '../../tags/PageTitle'
import TxtTMD from '../../../constants/TxtTMD'
import Layout from '../../../constants/Layout'

const { width, height } = Dimensions.get('window')

export default class VolunteerSignup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      imageWidth: 0,
      imageHeight: 0,
    }
  }

  componentDidMount() {
    this.getImageSize()
  }

  getImageSize() {
    Image.getSize(
      'https://filer.boblberg.dk/Assets/Img/snaksammen/snaksammen-banner-2.png',
      (imageWidth, imageHeight) => {
        this.setState({ imageWidth, imageHeight })
      },
      (error) => {
        this.setState({ imageWidth: width, imageHeight: height * 0.2 })
      }
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <PageTitle title={TxtTMD.snakSammen.title[TxtTMD.lang]} />
        <ScrollView style={styles.widthHeight}>
          <View>
            <Image
              style={
                ({
                  height: this.state.imageHeight,
                  width: this.state.imageWidth,
                },
                styles.imageSimple)
              }
              resizeMode={'contain'}
              accessibilityIgnoresInvertColors
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/snaksammen/snaksammen-banner-2.png',
              }}
            />
          </View>
          <View style={styles.marginLeftRight}>
            <View style={styles.textContainer}>
              <CustomText style={styles.title}>
                {txt('videoChat.volunteerSignup.title')}
              </CustomText>
            </View>

            <View style={styles.textContainer}>
              <CustomText style={styles.text}>
                {txt('videoChat.volunteerSignup.body')}
              </CustomText>
            </View>

            <View style={styles.textContainer}>
              <CustomText style={styles.text}>
                {txt('videoChat.volunteerSignup.email')}
                <CustomText
                  style={styles.link}
                  onPress={() =>
                    Linking.openURL(
                      'mailto:' +
                        txt('videoChat.volunteerSignup.contactEmail')
                    )
                  }
                >
                  {txt('videoChat.volunteerSignup.contactEmail')}
                </CustomText>
              </CustomText>
              <CustomText style={styles.text}>
                {txt('videoChat.volunteerSignup.phone')}
                <CustomText
                  onPress={() =>
                    Linking.openURL(
                      `tel:${
                        +45 + txt('videoChat.volunteerSignup.contactPhone')
                      }`
                    )
                  }
                  style={styles.link}
                >
                  {txt('videoChat.volunteerSignup.contactPhone')}
                </CustomText>
              </CustomText>
            </View>
            <View style={styles.textContainer}>
              <CustomText style={styles.italic}>
                {txt('videoChat.volunteerSignup.outro')}
              </CustomText>
            </View>
            <View style={styles.imageContainer}>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/snaksammen/tdc-logo.png',
                }}
                resizeMode={'contain'}
                style={styles.image}
                accessibilityIgnoresInvertColors
                accessible
                accessibilityRole="image"
                accessibilityLabel={txt('videoChat.intro.tdcLogo')}
              />
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/snaksammen/pdj-logo.png',
                }}
                resizeMode={'contain'}
                style={styles.image}
                accessibilityIgnoresInvertColors
                accessible
                accessibilityRole="image"
                accessibilityLabel={txt('videoChat.intro.PDJ')}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.background,
    flex: 1,
  },
  image: {
    height: Layout.isSmallDevice ? width * 0.4 : Layout.maxFeedWidth * 0.3,
    width: Layout.isSmallDevice ? width * 0.4 : Layout.maxFeedWidth * 0.3,
  },
  imageContainer: { flexDirection: 'row', justifyContent: 'space-evenly' },
  imageSimple: {
    alignSelf: 'center',
    height: Layout.isSmallDevice ? height * 0.21 : height * 0.25,
    width: Layout.isSmallDevice ? width : Layout.maxFeedWidth,
  },
  italic: { ...TypographyStyles.textSmallItalic },
  link: { ...TypographyStyles.text, color: Colors.tintColor },
  marginLeftRight: { marginLeft: 10, marginRight: 10 },
  text: {
    ...TypographyStyles.text,
    color: Colors.text,
  },
  textContainer: { marginTop: 10 },
  title: { ...TypographyStyles.titleBold, color: Colors.text },
  widthHeight: {
    backgroundColor: Colors.background,
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
})
