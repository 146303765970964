import React, { useState, useEffect, useRef } from 'react'
import { View, ScrollView, Platform, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import { ButtonPrimary, ButtonSecondary } from '../../buttons/StyledButtons'
import { CustomInputs, CustomMultilineInputs } from '../../text/StyledTextinputs'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { ShadowStyles, SpacingStyles, TypographyStyles } from '../../../styles'
import { UploadImage } from '../../../services/api/UploadImage'
import { CustomText } from '../../text/StyledText'
import { Picker } from '@react-native-picker/picker'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import DatePicker from '../../dates/DatePicker'
import { ModalSelectorCustom } from '../../modalSelectors/ModalSelector'
import CategoryDialog from '../../dialogs/CategoryDialog'
import * as AddressService from '../../../services/api/Addresses'
import RecurringDateForm from '../../formElements/RecurringDateForm'
import { useSelector } from 'react-redux'
import { deleteActivity, getFullActivity, updateActivity } from '../../../services/api/Activities'
import { useNavigation } from '@react-navigation/core'
import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import store from '../../../redux/store'
import Paths from '../../../constants/Paths'
import { getMunicipality, municipalities } from '../../../constants/Municipalities'
import imageBobl from '../../../assets/images/bobl.png'
import CustomPressable from "../../pressables/CustomPressable"

export default function CreateActivity(props) {
  const user = useSelector((state) => state.user)
  const [activity, setActivity] = useState({
    title: '',
    body: '',
    organizer: '',
    location: '',
    address: '',
    ticket_price: '',
    url: '',
    img_id: null,
    image: {},
    municipality_id: user.municipality_id || 0,
    municipality_name: '',
    city: '',
    postal_code: null,
    categories: [],
    latlng: null,
  })
  const [dates, setDates] = useState([{ start: roundUpDate(new Date()), end: roundUpDate(new Date()) }])
  const [suggestions, setSuggestions] = useState([])
  const [showRecurringForm, setShowRecurringForm] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState({
    save: false,
    delete: false,
  })
  const [isMounting, setIsMounting] = useState(true)
  const [errors, setErrors] = useState({
    postNotCreated: false,
    titleTooShort: false,
    descriptionTooShort: false,
    noCategories: false,
    showDateError: false,
    showErrorSnackbar: false,
    showAddressError: false,
    showPostalCodeError: false,
  })
  const municipalityId = store.getState().user.municipality_id
  const navigation = useNavigation()
  const timeout = useRef(null)
  const SUGGESTIONS_PER_PAGE = 4

  useEffect(() => {
    async function setMunicipalityList() {
      const municipality = getMunicipality(municipalityId)
      _setActivity({ municipality_name: municipality.name })
      if (!props.id) setIsMounting(false)
    }
    setMunicipalityList()
  }, [])

  useEffect(() => {
    loadActivity()
  }, [props.id])

  async function loadActivity() {
    const activity = await getFullActivity(props.id)
    if (activity) {
      activity.latlng = { lat: activity.lat, lng: activity.lng }
      setDates(activity.dates)
      setActivity(activity)
      if (activity.dates.length > 1) setShowRecurringForm(true)
    }
    setIsMounting(false)
  }

  function _setActivity(property) {
    setActivity((prev) => ({ ...prev, ...property }))
  }

  function _setErrors(error) {
    setErrors((prev) => ({ ...prev, ...error }))
  }

  function roundUpDate(date) {
    if (props.id) return date
    const coeff = 1000 * 60 * 5
    const roundedDate = Math.ceil(date / coeff) * coeff
    return new Date(roundedDate)
  }

  function uploadImg() {
    if (image) {
      return UploadImage(image, 0, 'Activities').catch((err) => {
        if (__DEV__) console.log('IMAGE UPLOAD FAIL ', err)
      })
    }
  }


  async function validateAddress() {
    const { address, postal_code } = activity
    if (address.length > 0) {
      if (!postal_code) {
        _setErrors({ showPostalCodeError: true })
        return false
      } else _setErrors({ showPostalCodeError: false })
      const data = await AddressService.getAddress(address, postal_code)
      if (data) {
        _setErrors({ showAddressError: false })
        return true
      }
      return false
    } else {
      _setActivity({ latlng: { lat: null, lng: null } })
      _setErrors({ showAddressError: false, showPostalCodeError: false })
      return true
    }
  }

  async function createActivityFull() {
    setLoading({ save: true, delete: false })
    const isDateValid = await validateDate()
    const isActivityValid = await validateActivity()
    const isAddressValid = await validateAddress()
    if (isDateValid && isActivityValid && isAddressValid) {
      const img_id = await uploadImg()
      const data = {
        id: Number(props.id),
        title: activity.title,
        body: activity.body,
        organizer: activity.organizer,
        location: activity.location,
        address: activity.address,
        ticket_price: activity.ticket_price === '' ? 0 : activity.ticket_price,
        url: checkURL(),
        dates: dates,
        municipality_id: parseInt(activity.municipality_id, 10),
        img_id: img_id || activity.img_id,
        city: activity.city,
        postal_code: activity.postal_code === '' ? null : activity.postal_code,
        categories: activity.categories,
        lat: activity.latlng?.lat,
        lng: activity.latlng?.lng,
        universeId: props.universeId,
      }
      const activityCreated = props.id ? await updateActivity(data) : await props.createActivity(data)
      setLoading({ save: false, delete: false })
      if (activityCreated) {
        props.navigateToMyActivities()
      }
    } else {
      setLoading({ save: false, delete: false })
    }
  }

  async function removeActivity() {
    setLoading({ save: false, delete: true })
    toggleDeleteDialog()
    const success = await deleteActivity(activity.id, props?.universeId)
    setLoading({ save: false, delete: false })
    if (success) {
      navigation.navigate('Activities')
    }
  }

  async function validateDate() {
    if (dates.length === 0) {
      _setErrors({ showDateError: true })
      return false
    }
    const date = dates[0]

    if (date.start < new Date() || date.start >= date.end) {
      _setErrors({ showDateError: true })
      return false
    }
    _setErrors({ showDateError: false })
    return true
  }

  async function validateActivity() {
    const { title, body, categories } = activity
    if (title.length === 0) {
      _setErrors({ titleTooShort: true })
    } else {
      _setErrors({ titleTooShort: false })
    }
    if (body.length === 0) {
      _setErrors({ descriptionTooShort: true })
    } else {
      _setErrors({ descriptionTooShort: false })
    }
    if (categories.length === 0) {
      _setErrors({ noCategories: true })
    } else {
      _setErrors({ noCategories: false })
    }
    return title.length > 0 && body.length > 0 && categories.length > 0
  }

  function getMaximumDate() {
    const date = new Date()
    date.setYear(date.getFullYear() + 6)
    return date
  }

  function handleStartDateChange(date, isTime) {
    const startDate = dates[0]
    if (isTime) {
      const newDate = new Date(startDate.start)
      newDate.setHours(date.getHours(), date.getMinutes())
      startDate.start = newDate
      setDates([startDate])
      return
    }
    startDate.start = date
    setDates([startDate])
  }

  function handleEndDateChange(date, isTime) {
    const endDate = dates[0]
    if (isTime) {
      const newDate = new Date(endDate.end)
      newDate.setHours(date.getHours(), date.getMinutes())
      endDate.end = newDate
      setDates([endDate])
      return
    }
    endDate.end = date
    setDates([endDate])
  }

  function pickMunicipality() {
    if (Platform.OS === 'web') {
      return (
        <Picker
          accessibilityLabel={txt('activities.create.municipality')}
          accessibilityHint={txt('activities.create.municipalityText')}
          value={activity.municipality_id}
          onValueChange={(value) => {
            _setActivity({ municipality_id: value })
          }}
          style={styles.picker}
        >
          {municipalities.map((item) => {
            return <Picker.Item label={item.name} value={item.id} key={item.id} />
          })}
        </Picker>
      )
    } else {
      return (
        <ModalSelectorCustom
          data={municipalities}
          keyExtractor={(data) => data.id?.toString()}
          labelExtractor={(data) => data.name}
          key={(data) => data.id}
          initValue={activity.municipality_name || txt('signupField.municipalityPlaceholder')}
          style={{ shadowColor: Colors.transparent }}
          onChange={(value) => _setActivity({ municipality_id: value.id })}
        />
      )
    }
  }

  function checkURL() {
    if (activity.url.startsWith('http') || activity.url === '') {
      return activity.url
    } else {
      return 'http://' + activity.url
    }
  }

  function showDateForm() {
    setShowRecurringForm(true)
    _setErrors({ showDateError: false })
  }

  function hideDateForm() {
    setShowRecurringForm(false)
    _setErrors({ showDateError: false })
    if (activity) {
      setDates([
        {
          start: roundUpDate(new Date(dates[0].start)),
          end: roundUpDate(new Date(dates[0].end)),
        },
      ])
    } else {
      setDates([{ start: roundUpDate(new Date()), end: roundUpDate(new Date()) }])
    }
  }

  function toggleDeleteDialog() {
    setShowDeleteDialog((prev) => !prev)
  }

  async function handleAddressChange(value) {
    clearTimeout(timeout.current)
    _setActivity({ address: value })
    timeout.current = setTimeout(async () => {
      const suggestions = await AddressService.getSuggestions(value)
      if (suggestions.length === 0 && value) {
        _setErrors({ showAddressError: true })
      } else {
        _setErrors({ showAddressError: false })
      }
      setSuggestions(suggestions)
    }, 800)
  }

  async function handleSuggestionClick(suggestion) {
    const address = suggestion.tekst.split(',')[0]
    const otherInfo = suggestion.adgangsadresse
    const data = await AddressService.getAddress(address, otherInfo.postnr)
    const latlng = data.adgangsadresse.adgangspunkt.koordinater
    _setActivity({})
    _setActivity({
      address: address,
      postal_code: otherInfo.postnr,
      city: otherInfo.postnrnavn,
      latlng: { lat: latlng[1], lng: latlng[0] },
    })
    setSuggestions([])
  }

  function renderSuggestions() {
    return (
      <View style={styles.suggestionsContainer}>
        {suggestions.map((suggestion, index) => {
          const borderWidth = index !== suggestions.length - 1 ? 1 : 0
          return (
            <CustomPressable
              style={[ styles.suggestion, { borderBottomWidth: borderWidth }]}
              key={index.toString()}
              onPress={() => handleSuggestionClick(suggestion)}
            >
              <CustomText>{suggestion.tekst}</CustomText>
            </CustomPressable>
          )
        })}
      </View>
    )
  }

  function handlePriceChange(price) {
    price = price.replace(/[^0-9]/g, '')
    _setActivity({ ticket_price: price })
  }

  async function handlePostalCodeChange(postalCode) {
    postalCode = postalCode.replace(/[^0-9]/g, '')
    if (postalCode.length === 4) {
      const city = await AddressService.getCityFromPostalCode(postalCode)
      _setActivity({ city })
    }
    _setActivity({
      postal_code: postalCode,
    })
  }

  function getStartDate() {
    if (dates.length > 0) {
      return {
        start: new Date(dates[0].start),
        end: new Date(dates[0].end),
      }
    }
    return { start: new Date(), end: new Date() }
  }

  if (isMounting) {
    return <LoadingSpinner />
  }

  return (
    <View style={[styles.container, props.style]}>
      {props.renderChips?.()}
      <ScrollView keyboardShouldPersistTaps={'handled'}>
        <View style={styles.imagePickerContainer}>
          <ImagePicker
            editMode
            setImage={setImage}
            defaultImage={activity?.img_filename ? Paths.activityUrlBase + activity.img_filename : null}
            onDelete={() => _setActivity({ img_filename: null, img_id: null })}
            placeholder={imageBobl}
          />
        </View>

        <View style={styles.marginInput}>
          <CustomInputs
            accessibilityLabel={txt('activities.create.title')}
            accessibilityHint={txt('activities.create.titleHint')}
            placeholder={txt('activities.create.title')}
            maxLength={35}
            value={activity.title}
            onChangeText={(value) => {
              _setActivity({
                title: value,
              })
            }}
          />
          {errors.titleTooShort && (
            <CustomText style={styles.errorMessage}>{txt('activities.create.titleTooShort')}</CustomText>
          )}
        </View>
        <View style={styles.marginInput}>
          <CustomMultilineInputs
            accessibilityLabel={txt('activities.create.description')}
            accessibilityHint={txt('activities.create.descriptionHint')}
            placeholder={txt('activities.create.description')}
            value={activity.body}
            onChangeText={(value) => {
              _setActivity({
                body: value,
              })
            }}
          />
          {errors.descriptionTooShort && (
            <CustomText style={styles.errorMessage}>{txt('activities.create.descriptionTooShort')}</CustomText>
          )}
        </View>
        <View style={styles.marginInput}>
          <CustomInputs
            accessibilityLabel={txt('activities.create.organizer')}
            accessibilityHint={txt('activities.create.organizerHint')}
            placeholder={txt('activities.create.organizer')}
            value={activity.organizer}
            onChangeText={(value) => {
              _setActivity({
                organizer: value,
              })
            }}
          />
        </View>
        <View style={styles.marginInput}>
          <CustomInputs
            accessibilityLabel={txt('activities.create.location')}
            accessibilityHint={txt('activities.create.locationHint')}
            placeholder={txt('activities.create.location')}
            value={activity.location}
            onChangeText={(value) => {
              _setActivity({
                location: value,
              })
            }}
          />
        </View>
        <View style={styles.marginInput}>
          <CustomInputs
            accessibilityLabel={txt('activities.create.address')}
            accessibilityHint={txt('activities.create.addressHint')}
            placeholder={txt('activities.create.address')}
            value={activity.address}
            onChangeText={handleAddressChange}
          />
          {errors.showAddressError && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('activities.create.invalidAddress')}
            </CustomText>
          )}
          {suggestions.length > 0 && activity.address !== '' && renderSuggestions()}
        </View>

        <View style={styles.inputRow}>
          <View style={styles.marginInputSplit}>
            <CustomInputs
              placeholder={txt('activities.create.postalCode')}
              value={activity.postal_code}
              inputMode="numeric"
              onChangeText={handlePostalCodeChange}
            />
            {errors.showPostalCodeError && (
              <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
                {txt('activities.create.postalCodeRequired')}
              </CustomText>
            )}
          </View>
          <View style={[styles.marginInputSplit, { zIndex: -1 }]}>
            <CustomInputs
              placeholder={txt('activities.create.city')}
              value={activity.city}
              onChangeText={(value) => {
                _setActivity({
                  city: value,
                })
              }}
            />
          </View>
        </View>
        <View style={[styles.marginInput, { zIndex: -1 }]}>
          <CustomInputs
            placeholder={txt('activities.create.ticketPrice')}
            value={activity.ticket_price}
            inputMode="numeric"
            onChangeText={handlePriceChange}
          />
        </View>
        <View style={[styles.marginInput, { zIndex: -1 }]}>
          <CustomInputs
            placeholder={txt('activities.create.url')}
            value={activity.url}
            onChangeText={(text) => {
              _setActivity({ url: text })
            }}
          />
        </View>
        <View style={styles.dateButtonsContainer}>
          <ButtonPrimary
            title={txt('activities.create.single')}
            onPress={hideDateForm}
            style={[{ width: 148 }, showRecurringForm && { backgroundColor: Colors.grey }]}
          />
          <ButtonPrimary
            title={txt('activities.create.multiple')}
            onPress={showDateForm}
            style={[{ width: 148 }, !showRecurringForm && { backgroundColor: Colors.grey }]}
          />
        </View>

        <View style={showRecurringForm ? {} : { display: 'none' }}>
          <RecurringDateForm
            date={getStartDate()}
            setDates={setDates}
            dates={dates}
            editing={!!props.id}
            isVisible={showRecurringForm}
          />
        </View>

        <View style={[styles.datepickerContainer, showRecurringForm ? { display: 'none' } : {}]}>
          <View>
            <CustomText font="bold" style={styles.textCenter}>
              {txt('activities.create.startDate')}
            </CustomText>
            <View style={styles.pickDateContainer}>
              <DatePicker
                date={new Date(dates[0].start)}
                minDate={new Date()}
                maxDate={getMaximumDate()}
                minuteInterval={15}
                handleDateChange={handleStartDateChange}
              />
            </View>
          </View>
          <View>
            <CustomText font="bold" style={styles.textCenter}>
              {txt('activities.create.endDate')}
            </CustomText>
            <View style={styles.pickDateContainer}>
              <DatePicker
                date={new Date(dates[0].end)}
                minDate={new Date(dates[0].start)}
                maxDate={getMaximumDate()}
                minuteInterval={15}
                handleDateChange={handleEndDateChange}
              />
            </View>
          </View>
        </View>
        {errors.showDateError && (
          <CustomText style={[styles.errorMessage, styles.textCenter]}>{txt('activities.create.dateError')}</CustomText>
        )}
        <View style={styles.marginLeftRight}>
          <CustomText text={txt('activities.create.municipality')} />
          <CustomText font="small" text={txt('activities.create.municipalityText')} />
          {pickMunicipality()}
        </View>
        <View style={styles.marginLeftRight}>
          <CustomText text={txt('activities.create.category')} />
          <CustomText font="small" text={txt('activities.create.categoryHint')} />
          <CategoryDialog
            normalInput
            selectedCategories={activity.categories}
            setCategories={(categories) => _setActivity({ categories })}
          />
        </View>
        {errors.noCategories && (
          <CustomText style={[styles.errorMessage, styles.textCenter]}>
            {txt('activities.create.noCategories')}
          </CustomText>
        )}
        {(errors.titleTooShort || errors.descriptionTooShort || errors.showDateError || errors.showAddressError) && (
          <CustomText style={[styles.errorMessage, styles.textCenter]}>
            {txt('invalidNotice.invalidBooking')}
          </CustomText>
        )}
        <View style={styles.actionButtons}>
          {activity?.id && (
            <ButtonPrimary
              style={{ marginHorizontal: 8, backgroundColor: Colors.redLight }}
              title={txt('activities.edit.deleteButton')}
              loading={loading.delete}
              onPress={toggleDeleteDialog}
              color={Colors.white}
            />
          )}
          <ButtonPrimary
            style={{ marginHorizontal: 8 }}
            title={activity?.id ? txt('activities.edit.saveButton') : txt('activities.create.createActivity')}
            loading={loading.save}
            onPress={createActivityFull}
            color={Colors.white}
          />
        </View>
        <CustomDialog visible={showDeleteDialog}>
          <ContentContainer>
            <CustomText>{txt('activities.edit.deleteText')}</CustomText>
            <ButtonPrimary
              style={styles.removeActivityBtn}
              title={txt('activities.edit.deleteButton')}
              onPress={removeActivity}
            />
            <ButtonSecondary
              style={styles.cancelRemoveBtn}
              title={txt('activities.edit.cancel')}
              onPress={toggleDeleteDialog}
            />
          </ContentContainer>
        </CustomDialog>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  actionButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 16,
  },
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  dateButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  datepickerContainer: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row',
    justifyContent: 'space-evenly',
    margin: 10,
  },
  errorMessage: {
    color: Colors.errorBackground,
    marginVertical: 4,
  },
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 5,
    zIndex: -1,
  },
  marginInput: {
    ...SpacingStyles.marginRightLeft10,
    marginBottom: 15,
  },
  marginInputSplit: {
    flex: 1,
    marginBottom: 15,
    marginHorizontal: 5,
  },
  marginLeftRight: { margin: 10 },
  pickDateContainer: {
    alignItems: 'center',
    flex: 1,
    marginBottom: 10,
    marginTop: 5,
  },
  picker: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    height: 40,
    paddingLeft: 5,
    paddingRight: 5,
    ...TypographyStyles.text,
    borderColor: Colors.transparent,
    color: Colors.text,
  },
  suggestion: {
    borderBottomColor: Colors.blackTransparent,
    padding: 10,
  },
  suggestionsContainer: {
    ...ShadowStyles.shadowInputs,
    backgroundColor: Colors.white,
    borderRadius: 12,
    position: 'absolute',
    top: 48,
    width: '100%',
    zIndex: 1,
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  textCenter: { textAlign: 'center' },
  removeActivityBtn: {
    backgroundColor: Colors.redLight,
    width: '100%',
    marginTop: 24,
  },
  cancelRemoveBtn: { width: '100%', marginTop: 12 },
})
