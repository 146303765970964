/* eslint-disable react-native/no-raw-text */
import React from 'react'
import { View, StyleSheet, Image, Dimensions } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import {
  getFavoriteActivities,
  getFullActivity,
  removeActivityFavorite,
  setActivityFavorite,
} from '../../../services/api/Activities'
import { SpacingStyles } from '../../../styles'
import { formatForMetaTitle, formatForSeoBody } from '../../text/TextFormatter'
import PageTitle from '../../tags/PageTitle'
import moment from 'moment'
import Paths from '../../../constants/Paths'
import Colors from '../../../constants/Colors'
import FavoriteIndicator from '../../headers/FavoriteIndicator'
import NormalIcon from '../../icons/NormalIcon'
import Logo from '../../../assets/images/icon.png'
import mapImgWeb from '../../../assets/images/Google_maps_web.png'
import { URLOpener, formatURL } from '../../../utils/URLOpener'
import Fontsizes from '../../../constants/Fontsizes'
import { txt } from '../../../locales/i18n'
import { openMap } from '../../../utils/openMap'
import store from '../../../redux/store'
import Card from '../../Card'
import ReportContent from '../../callToActions/reportContent'
import CustomPressable from "../../pressables/CustomPressable"

const small = Dimensions.get('window').width < 500

export default class Activity extends React.Component {
  constructor(props) {
    super(props)

    moment.locale([txt('videoChat.locale')])

    this.state = {
      loading: true,
      item: {
        id: 0,
        user_id: null,
        title: '',
        body: '',
        organizer: '',
        location: '',
        address: '',
        ticket_price: 0,
        url: '',
        img_id: 0,
        image_filename: '',
        municipality_id: 0,
        status_id: 0,
        created: '',
        recurring: null,
        city: '',
        postal_code: null,
        is_favorite: 0,
      },
      showOtherDates: false,
      favIconHighlight: false,
      startDate: null,
      endDate: null,
    }
  }

  async componentDidMount() {
    if (this.state.loading) {
      const activity = await getFullActivity(this.props.id)
      const user = store.getState().user
      const own_id = user?.id

      // Check if activity is favorit when not logged in
      if (!user) {
        const favorites = await getFavoriteActivities()
        activity.is_favorite = favorites.find(a => a.id === activity.id) ? 1 : 0
      }

      this.setState({ own_id, item: activity, loading: false })

      own_id && activity.user_id !== own_id && this.props.navigation.setOptions({
        headerRight: () => <ReportContent reportingTypeId={2} contentId={this.props.id} />,
      })

      this.unsubscribe = this.props.navigation.addListener('focus', async () => {
        const item = await getFullActivity(this.props.id)
        this.setState({ item })
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribe?.()
  }

  setLoading = (loading) => this.setState({ loading })

  setActivity = (data) => this.setState({ item: data, loading: false })

  getDateString(dateStart, dateEnd) {
    return `${this.formatDate(dateStart, false)} - ${this.formatDate(dateEnd, true)}`
  }

  formatDate(date, isEndDate) {
    if (date === null) return
    if (isEndDate) return moment(date).format('LT')
    return moment(date).format('llll')
  }

  toggleFavorite = async () => {
    const { item } = this.state
    if (!item.is_favorite) {
      await setActivityFavorite(item)
    } else {
      await removeActivityFavorite(item)
    }

    await this.setState((state) => ({
      item: {
        ...state.item,
        is_favorite: !item.is_favorite,
      },
    }))
  }

  toggleOtherDates = () => {
    const showOtherDates = !this.state.showOtherDates
    this.setState({ showOtherDates })
  }

  render() {
    const { item } = this.state
    const ownId = item.user_id == this.state?.own_id
    if (!this.state.loading) {
      return (
        <View style={styles.widthHeight}>
          <PageTitle title={formatForMetaTitle(item.title)} />
          <Card style={styles.container}>
            <View>
              {item.img_id ? (
                <ImageHighlight
                  source={{ uri: Paths.activityUrlBase + item.img_filename }}
                  size="medium"
                  borderColor={Colors.black}
                />
              ) : (
                <ImageHighlight
                  source={Logo}
                  size="medium"
                  borderColor={Colors.black}
                />
              )}

              <View style={styles.favoriteContainer}>
                {ownId ? (
                  <CustomPressable
                    onPress={() => this.props.navigateToEditActivity(item.id)}
                    accessibilityRole="button"
                    accessibilityLabel={txt('activities.edit.title')}
                  >
                    <NormalIcon name="edit" />
                  </CustomPressable>
                ) : (
                  <FavoriteIndicator toggleFavorite={this.toggleFavorite} isFavorite={item.is_favorite} />
                )}
              </View>
            </View>

            <CustomText style={styles.title} font="largeBold">
              {item.title}
            </CustomText>

            <View style={styles.dateContainer}>
              <View style={{ flexDirection: 'row' }}>
                <NormalIcon style={styles.iconMargin} name="clock" size={Fontsizes.l} />
                <CustomText font="small">
                  {this.getDateString(this.props.route.params.date, item.dates[0].end)}
                </CustomText>
              </View>

              {this.state.item.dates.length > 1 && (
                <CustomPressable style={{ flexDirection: 'row', marginTop: 8 }} onPress={this.toggleOtherDates}>
                  <NormalIcon
                    style={styles.iconMargin}
                    name={this.state.showOtherDates ? 'chevron-up' : 'chevron-down'}
                    size={Fontsizes.l}
                  />
                  <CustomText font={'small'}>{txt('activities.otherDates')}</CustomText>
                </CustomPressable>
              )}
              {this.state.showOtherDates &&
                this.state.item.dates.map((date, index) => {
                  if (index === 0) return
                  return (
                    <CustomText style={{ marginTop: 8 }} font={'small'} key={date.id.toString()}>
                      {this.getDateString(date.start, date.end)}
                    </CustomText>
                  )
                })}
            </View>

            <CustomText style={{ marginBottom: 24 }}>{item.body}</CustomText>

            <View style={styles.infoContainer}>
              {item.organizer !== '' && (
                <View style={styles.iconContainer}>
                  <NormalIcon
                    name="users"
                    size={Fontsizes.l}
                    style={styles.iconMargin}
                    stroke="fas"
                  />
                  <CustomText font="small">{item.organizer}</CustomText>
                </View>
              )}

              {item.ticket_price !== null && (
                <View style={styles.iconContainer}>
                  <NormalIcon
                    name="ticket-alt"
                    size={Fontsizes.l}
                    style={styles.iconMargin}
                    stroke="fas"
                  />
                  <CustomText font="small" text={item.ticket_price + ' kr.'} />
                </View>
              )}

              {item.location !== null && item.location !== '' && (
                <View style={styles.iconContainer}>
                  <NormalIcon
                    name="building"
                    size={Fontsizes.l}
                    style={styles.iconMargin}
                    stroke="fas"
                  />
                  <CustomText font="small">{item.location}</CustomText>
                </View>
              )}
            </View>

            {item.address !== null && item.address !== '' && (
              <CustomPressable
                accessibilityRole="button"
                accessibilityLabel={txt('activities.map')}
                onPress={() => openMap(item.address, item.postal_code)}
              >
                <Image source={mapImgWeb} resizeMode="cover" style={styles.mapImg} />
              </CustomPressable>
            )}

            <View style={styles.dateContainer}>
              {item.address !== '' && item.address !== null && (
                <CustomPressable onPress={() => openMap(item.address, item.postal_code)} style={styles.flex}>
                  <View style={styles.flexCenter}>
                    <NormalIcon
                      style={styles.iconMargin}
                      name="map"
                      size={Fontsizes.l}
                      stroke="fas"
                    />

                    <CustomText font="small">
                      {item.address}
                      {!!item.postal_code && `, ${item.postal_code}`}
                      {!!item.city && item.city !== '' && ` ${item.city}`}
                    </CustomText>
                  </View>
                </CustomPressable>
              )}
            </View>
            {item.url !== null && item.url !== '' && (
              <View style={styles.flexCenter}>
                <NormalIcon
                  style={styles.iconMargin}
                  name="globe"
                  size={Fontsizes.l}
                  stroke="fas"
                />
                <CustomPressable
                  onPress={() => URLOpener(formatURL(item.url))}
                  style={{ maxWidth: '100%' }}
                >
                  <CustomText font="small" style={{ color: Colors.tintColor }}>
                    {item.url}
                  </CustomText>
                </CustomPressable>
              </View>
            )}
          </Card>
        </View>
      )
    }

    return <View />
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 12,
    padding: small ? 18 : 36,
  },
  title: {
    marginTop: 24,
    marginBottom: 6,
  },
  dateContainer: {
    marginBottom: 18,
  },
  favoriteContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  flex: { flexGrow: 1, flex: 1 },
  flexCenter: { alignItems: 'center', flexDirection: 'row' },
  iconContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 12,
  },
  iconMargin: { marginRight: 6 },
  infoContainer: {
    flex: 1,
    marginBottom: 12,
  },
  mapImg: { borderRadius: 10, height: 100, marginBottom: 12, width: '100%' },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
