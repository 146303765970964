import React from 'react'
import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'

export default function DeletePostDialog({visible, parentId, dismiss, deletePost}) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
        {parentId === null ? (
          <CustomText>{txt('groups.bulletin.removeParentMessageText')}</CustomText>
        ) : (
          <CustomText>{txt('groups.bulletin.removeChildMessageText')}</CustomText>
        )}
      </ContentContainer>
      <ActionButton onPress={deletePost} style={{ padding: 20 }}>
        {txt('groups.members.yes')}
      </ActionButton>
      <ActionButton onPress={dismiss} style={{ padding: 20 }}>
        {txt('groups.members.cancel')}
      </ActionButton>
    </CustomDialog>
  )
}