import React from 'react'
import { connect } from 'react-redux'

class MakeInfluence extends React.Component {
  render() {
    return (
      <>
        {this.props.showMakeInfluence && (
          <img
            style={{ display: 'none' }}
            src={
              'https://makeinfluence.com/p?bid=752fce48-ee0a-11ea-a9d8-9681f5067383&value=13&uid=' +
              this.props.userId
            }
            alt="MakeInfluence"
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  userId: state.user?.id,
  showMakeInfluence: state.showMakeInfluence,
})

export default connect(mapStateToProps)(MakeInfluence)
