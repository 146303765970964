import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { getPreviousBookedSessions } from '../../../../services/api/LearnDanish'
import Layout from '../../../../constants/Layout'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import Colors from '../../../../constants/Colors'
import { ButtonSecondary } from '../../../buttons/StyledButtons'
import { ShadowStyles, TypographyStyles } from '../../../../styles'
import moment from 'moment'
import { getConversationId } from '../../../../services/api/Inbox'
import TextLabel from '../TextLabel'
import { useNavigation } from '@react-navigation/core'

export default function PreviousSessions() {
  //sets locale to danish time
  moment.locale([txt('videoChat.locale')])
  const navigation = useNavigation()
  const [data, setData] = useState([])

  useEffect(() => {
    getPreviousBookedSessions().then(_setData)
  }, [])

  const _setData = (data) => {
    var formattedArr = data.map(function(val, index) {
      return {
        key: index,
        id: val.id,
        start_date: val.start_date,
        end_date: val.end_date,
        username: val.username,
        bookerId: val.booker_id,
        room_key: val.room_key,
        junior: val.junior,
        juniorName: val.juniorName,
        juniorAge: val.juniorAge,
        categories: val.categories,
        vonage_session_id: val.vonage_session_id,
      }
    })
    setData(formattedArr)
  }

  function formatUnixDate(date) {
    var d = moment(date).format('dddd [d.] DD/MM [kl.] HH:mm')
    return d.charAt(0).toUpperCase() + d.substr(1)
  }

  async function handleSendMessage(item) {
    const { bookerId, id } = item
    if (!bookerId || !id) return
    const conversationId = await getConversationId({
      slSessionId: id,
      receiverId: bookerId,
    })
    if (conversationId) navigateToConversation(conversationId)
    else navigateToNewConversation(bookerId, id)
  }

  const navigateToConversation = (conversationId) => {
    navigation.navigate('Conversation', {
      id: conversationId,
    })
  }

  const navigateToNewConversation = (userId, sessionId) => {
    navigation.navigate('Conversation', {
      id: 'new',
      receiverId: userId,
      slSessionId: sessionId,
    })
  }

  return (
    <View style={styles.container}>
      {data.length === 0 ? (
        <View>
          <CustomText font="bold" style={styles.centerText} accessibilityRole="header" aria-level={2}>
            {txt('learnDanish.volunteer.previousSessions')}
          </CustomText>
          <CustomText style={styles.noSession}>
            {txt('learnDanish.volunteer.noPreviousSessions')}
          </CustomText>
        </View>
      ) : (
        <CustomText font="bold" style={styles.centerText} accessibilityRole="header" aria-level={2}>
          {txt('learnDanish.volunteer.previous')}
        </CustomText>
      )}

      {data.map((item, key) => {
        var date = formatUnixDate(item.start_date)
        return (
          <View key={key} style={styles.sessionContainer}>
            <View style={styles.textContainer}>
              <CustomText font="small">{date}</CustomText>
              <CustomText
                font="small"
                text={
                  txt('videoChat.duration') +
                  ': ' +
                  (new Date(item.end_date).getTime() - new Date(item.start_date).getTime()) / 1000 / 60 +
                  ' min.'
                }
              />
              {!item.junior && <CustomText font="small" text={txt('videoChat.bookedBy') + ': ' + item.username} />}

              {item.junior && (
                <CustomText font="small">
                  {txt('videoChat.bookedBy') + ': ' + item.juniorName + ' - ' + item.juniorAge + ' ' + txt('learnDanish.junior.juniorAge')}
                </CustomText>
              )}
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {item.categories?.map(id => (
                  <TextLabel
                    key={id}
                    text={txt(`learnDanish.categories.${id}`)}
                    style={{ marginRight: 6, marginTop: 6 }}
                  />
                ))}
                {item.junior && <TextLabel junior style={{ marginTop: 6 }} />}
              </View>
            </View>
            <View style={styles.buttonsContainer}>
              <ButtonSecondary
                title={txt('conversations.messageSend')}
                titleStyle={{ ...TypographyStyles.textSmallBold }}
                style={{ borderWidth: 1 }}
                onPress={() => handleSendMessage(item)}
                icon="paper-plane"
                iconSize={16}
                stroke={'fal'}
              />
            </View>
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  centerText: {
    textAlign: 'center',
  },
  container: {
    width: '100%',
    maxWidth: Layout.maxFeedWidth,
    alignSelf: 'center',
    marginVertical: 24,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
    textAlign: 'center',
  },
  sessionContainer: {
    backgroundColor: Colors.white,
    borderRadius: 2,
    flexDirection: 'row',
    marginTop: 12,
    padding: 10,
    marginHorizontal: 12,
    ...ShadowStyles.liftedShadow,
  },
  textContainer: {
    flex: 2,
    justifyContent: 'center',
    paddingRight: 12,
  },
})
