import React from 'react'
import { View, Platform, StyleSheet, Image } from 'react-native'
import KeyboardSpacer from 'react-native-keyboard-spacer'

import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import SignupField from '../../components/views/Auth/SignupField'
import MainContainer from '../../components/MainContainer/MainContainer'
import { TypographyStyles, SpacingStyles } from '../../styles'
import Layout from '../../constants/Layout'
import Colors from '../../constants/Colors'

export default class SnakSammenSignupScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0,
      activeIndexMunicipalities: 0,
    }
  }

  componentDidMount() {
    this.props.navigation.setOptions({
      headerStyle: { 
        backgroundColor: Colors.background,
        borderBottomColor: 'transparent',
        elevation: 0, // remove shadow on Android
        shadowOpacity: 0,
        height: 100
      },
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/snaksammen-logo.png' }}
          accessibilityLabel={'Snaksammen logo'}
          accessibilityIgnoresInvertColors
        />
      ),
    })
  }

  keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  render() {
    return (
      <MainContainer>
        <View style={styles.widthHeight}>
          <View style={styles.textContainer}>
            <CustomText style={styles.textBold}>
              {txt('videoChat.Signup.title')}
            </CustomText>
            <CustomText style={styles.text}>
              {txt('videoChat.Signup.subtitle')}
            </CustomText>
          </View>

          <SignupField
            navigation={this.props.navigation}
            originScreen={'SnakSammenOversigt'}
            originStack={'MenuStack'}
            originRootStack={'app'}
            route={this.props.route}
          />
        </View>
        {this.keyboardSpacerShow()}
      </MainContainer>
    )
  }
}

const styles = StyleSheet.create({
  text: { ...TypographyStyles.text, marginBottom: 15, textAlign: 'center' },
  textBold: { ...TypographyStyles.textBold, textAlign: 'center' },
  textContainer: { marginLeft: 10, marginRight: 10 },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
  logo: { width: 200, height: Layout.isSmallDevice ? 60 : 70 }
})
