import React from 'react'
import { FlatList, View, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import UnionListItem from './UnionListItem'
import { SpacingStyles } from '../../../styles'

export default class FavoriteUnions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      unions: [],
    }
  }

  async componentDidMount() {
    let unions = await this.props.getFavoriteUnions()
    this.setState({ unions })

    this.unsubscribe = this.props.navigation.addListener('focus', async () => {
      unions = await this.props.getFavoriteUnions()
      this.setState({ unions })
    })
  }

  componentWillUnmount() {
    this.unsubscribe?.()
  }

  renderItem = ({ item }) => (
    <UnionListItem
      item={item}
      onPressAction={() => {
        this.props.navigateToUnion(item.id)
      }}
    />
  )

  noUnions = () => (
    <View style={styles.container}>
      <CustomText style={styles.text}>
        {txt('unions.favorites.noUnionsTitle')}
      </CustomText>
      <CustomText style={styles.text}>
        {txt('unions.favorites.noUnionsText')}
      </CustomText>
      <View style={styles.alignSelfCenter}>
        <NormalIcon
          accessibilityLabel={txt('fullPost.favoriteIcon')}
          name="star"
          color={Colors.inactive}
        />
      </View>
    </View>
  )

  render() {
    return (
      <View style={styles.container}>
        {this.props.renderChips()}
        <FlatList
          keyExtractor={(item) => item.id?.toString()}
          data={this.state.unions}
          renderItem={this.renderItem}
          keyboardDismissMode="on-drag"
          ListEmptyComponent={this.noUnions}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  alignSelfCenter: { alignSelf: 'center', margin: 10 },
  container: { flex: 1, ...SpacingStyles.widthAndHeight },
  text: {
    color: Colors.inactive,
    margin: 5,
    marginTop: 20,
    textAlign: 'center',
  },
})
