import React from 'react'
import { Image } from 'react-native'
import { StyleSheet, View } from 'react-native'
import Layout from '../../constants/Layout'
import { CustomText } from '../text/StyledText'
import Colors from '../../constants/Colors'

export default function Case(props) {
	return (
		<View style={[styles.caseContainer, styles[props.direction]]}>
			<Image
				style={Layout.isSmallDevice ? styles.caseImage : styles.caseImageBig}
				resizeMode={'cover'}
				source={props.img}
				accessible
				accessibilityRole='image'
				accessibilityIgnoresInvertColors
				accessibilityLabel={props.name || ''}
			/>
			<View style={Layout.isSmallDevice ? '' : styles.flex}>
				{props.title && (
					<CustomText
						style={{ color: '#117325', marginBottom: 12 }}
						font='largeBold'
					>
						{props.title}
					</CustomText>
				)}
				<CustomText
					style={{ color: props.color || Colors.text, lineHeight: 20 }}
					font={Layout.isSmallDevice ? 'small' : ''}
				>
					{props.body}
				</CustomText>
				{props.name && (
					<CustomText font='bold' style={{ paddingTop: 6 }}>
						{props.name}
					</CustomText>
				)}
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	caseContainer: {
		alignItems: 'center',
		marginVertical: Layout.isSmallDevice ? 18 : 30,
		gap: Layout.isSmallDevice ? 24 : 36,
	},
	caseImage: { borderRadius: 75, height: 150, width: 150 },
	caseImageBig: { borderRadius: 200, height: 175, width: 175 },
	flex: { flex: 1 },

	// right og left bliver brugt ved caseContainer
	right: {
		flexDirection: Layout.isSmallDevice ? 'column' : 'row',
	},
	left: {
		flexDirection: Layout.isSmallDevice ? 'column' : 'row-reverse',
	},
})
