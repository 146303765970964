import { getRegion } from '../services/localization'

export default {
	// apiBaseUrl: 'http://localhost:3000/api/v1/',
	// apiBaseUrl: 'https://testapi.boblberg.dk/api/v1/',
	apiBaseUrl: 'https://api.boblberg.dk/api/v1/',
	socketIOBaseUrl: 'https://sockets.boblberg.dk/',
	imageUrlBase: 'https://filer.boblberg.dk/Img/Bobler/',
	imageEventUrlBase: 'https://filer.boblberg.dk/Img/Events/',
	imageUrlBaseJV: 'https://filer.boblberg.dk/Img/Julevenner/',
	storyUrlBase: 'https://filer.boblberg.dk/Img/Historier/',
	activityUrlBase: 'https://filer.boblberg.dk/Img/Aktiviteter/',
	groupUrlBase: 'https://filer.boblberg.dk/Img/Grupper/',

	getBaseUrl: () => {
		if (__DEV__) {
			return 'http://localhost:3001/'
		}

		const region = getRegion()
		// const baseUrl = `https://test.boblberg.${region}/`
		const baseUrl = `https://boblberg.${region}/`
		return baseUrl
	},
}