import React from 'react'
import {
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Document,
  pdf,
} from '@react-pdf/renderer'
import PrintButton from './PrintButton'
import { URLOpener } from '../../../utils/URLOpener'
import moment from 'moment'
import headerLogo from '../../../assets/images/FindAktiviteter.png'
import footerLogo from '../../../assets/images/icon.png'

export default function PrintDocumentButton({ favorites }) {
  const pdfDoc = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View fixed style={styles.header}>
            <Image style={styles.headerLogo} src={headerLogo} />
          </View>
          {favorites.map((fav, index) => (
            <View key={index} wrap={false} style={styles.container}>
              <View style={styles.description}>
                <Text style={styles.title}>{fav.title}</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.bold}>Arrangør:</Text>
                  <Text style={styles.text}>{fav.organizer}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.bold}>Adresse:</Text>
                  <Text style={styles.text}>{fav.address}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.bold}>By:</Text>
                  <Text style={styles.text}>
                    {fav.postal_code} {fav.city}
                  </Text>
                </View>

                <View style={styles.textContainer}>
                  <Text style={styles.bold}>Sted/Placering:</Text>
                  <Text style={styles.text}>{fav.location}</Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.bold}>Pris:</Text>
                  <Text style={styles.text}>
                    {fav.ticketPrice ? `${fav.ticket_price} kr.` : '0 kr.'}
                  </Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.bold}>Webside:</Text>
                  <Text style={styles.text}>
                    <Link src={fav.url}>{fav.url}</Link>
                  </Text>
                </View>
                <View style={styles.textContainer}>
                  <Text style={styles.bold}>Dato:</Text>
                  <Text style={styles.text}>
                    {fav.recurring
                      ? 'Gentagende'
                      : `${moment(fav.date_start).format('llll')} - ${moment(
                          fav.date_end
                        ).format('llll')}`}
                  </Text>
                </View>
                <Text style={styles.body}>{fav.body}</Text>
              </View>
            </View>
          ))}
          <View fixed style={styles.footer}>
            <Image style={styles.footerLogo} src={footerLogo} />
          </View>
          <Text
            style={[styles.pageNumbers, styles.text]}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  )

  const generatePDF = async () => {
    const pdfBlob = await pdf(pdfDoc).toBlob()

    const blobUrl = URL.createObjectURL(pdfBlob)
    URLOpener(blobUrl)
  }

  return <PrintButton generatePDF={generatePDF} />
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
  },
  container: {
    borderBottom: 1,
  },
  header: {
    marginTop: 18,
    alignSelf: 'center',
  },
  headerLogo: {
    width: 256,
  },
  description: {
    marginTop: 20,
    marginHorizontal: 24,
  },
  title: {
    fontSize: 18,
    marginBottom: 12,
  },
  textContainer: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  bold: {
    fontSize: 12,
  },
  text: {
    fontSize: 12,
    marginLeft: 4,
  },
  body: {
    fontSize: 12,
    marginBottom: 12,
  },
  footer: {
    position: 'absolute',
    left: '44.5%',
    bottom: 0,
  },
  footerLogo: {
    width: 64,
    height: 64,
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 28,
    right: 48,
  },
})
