import React from 'react'
import { URLOpener } from '../../../../utils/URLOpener'
import { CustomText } from '../../../text/StyledText'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../../constants/Colors'
import NormalIcon from '../../../icons/NormalIcon'
import { txt } from '../../../../locales/i18n'
import CustomPressable from '../../../pressables/CustomPressable'

export default function Materials() {
	const Material = ({ title, link, icon }) => (
		<CustomPressable style={styles.linkContainer} onPress={() => link && URLOpener(link)}>
			<CustomText font="gotham-bold-small" style={styles.linkText}>
				{title}
			</CustomText>
			{icon && <NormalIcon name={icon} stroke={'fal'} size={20} />}
		</CustomPressable>
	)

	return (
		<View style={styles.container}>
			<View style={{ marginHorizontal: 12 }}>
				<CustomText font="gotham-xlarge">{txt('videoChat.materialsHeader')}</CustomText>
				<Material
					title="Guide til SnakSammen - Kom godt i gang som frivillig"
					icon="arrow-to-bottom"
					link={'https://filer.boblberg.dk/Files/SnakSammen/guide-til-snaksammen.pdf'}
				/>
				<Material
					title="Guide til SnakSammen - Kom godt i gang med teknikken"
					icon="arrow-to-bottom"
					link={'https://filer.boblberg.dk/Files/SnakSammen/kom-godt-i-gang-med-teknikken.pdf'}
				/>
				<Material
					title="Oversigt over relevante rådgivningstilbud"
					icon="arrow-to-bottom"
					link={'https://filer.boblberg.dk/Files/SnakSammen/oversigt-over-relevante-raadgivningstilbud.pdf'}
				/>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
		maxWidth: 700,
		alignSelf: 'center',
		marginVertical: 24,
	},
	linkText: {
		color: Colors.tintColor,
		textDecorationLine: 'underline',
		paddingRight: 12,
	},
	linkContainer: {
		marginTop: 12,
		backgroundColor: Colors.white,
		borderRadius: 10,
		padding: 12,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
})
