import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import { URLOpener } from '../../../utils/URLOpener'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import { SpacingStyles, CornerStyles } from '../../../styles'
import Layout from '../../../constants/Layout'
import CustomPressable from "../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

export default class Faellesskabsprisen extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.container}>
        <PageTitle title={TxtTMD.faellesskabsprisen.title[TxtTMD.lang]} />

        <View style={styles.widthHeight}>
          <View>
            <View style={styles.marginTop}>
              <CustomText font="bold" style={styles.marginBottom}>
                {txt('faellesskabsprisen.startTextTitle')}
              </CustomText>

              <CustomText>
                {txt('faellesskabsprisen.startTextBody')}
              </CustomText>
              <CustomPressable
                accessibilityRole="link"
                href="#"
                onPress={() => URLOpener('https://www.tv2lorry.dk/lorryland/ildsjaele-med-samfundets-svage-oje-haedret-med-faellesskabsprisen')}
              >
                <CustomText style={styles.linkText}>
                  {txt('faellesskabsprisen.startTextReadMore')}
                </CustomText>
              </CustomPressable>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/faellesskabsprisen/faellesskabs-gif.gif',
                }}
                resizeMode="contain"
                style={styles.gif}
                accessibilityIgnoresInvertColors
                accessible
                accessibilityRole="image"
                accessibilityLabel={txt('faellesskabsprisen.logo')}
              />
            </View>
            <View>
              <CustomText font="bold" style={styles.marginBottom}>
                {txt('faellesskabsprisen.whatIsTitle')}
              </CustomText>
              <CustomText font="italic">
                {txt('faellesskabsprisen.whatIsBody1')}
              </CustomText>
              <CustomPressable
                accessibilityRole="link"
                href="#"
                onPress={() => URLOpener('https://faellesskabsprisen.dk/om-f%C3%A6llesskabsprisen')}
              >
                <CustomText style={[styles.linkText, styles.marginBottom]}>
                  {txt('faellesskabsprisen.whatIsLink')}
                </CustomText>
              </CustomPressable>
              <CustomText>
                {txt('faellesskabsprisen.whatIsBody2')}
              </CustomText>
            </View>
            <View>
              <CustomText style={[styles.marginBottom, styles.marginTop]}>
                {txt('faellesskabsprisen.thankYou')}
              </CustomText>
            </View>

            <View>
              <CustomText style={[styles.marginBottom, styles.marginTop]}>
                {txt('faellesskabsprisen.whatIsTitle')}
              </CustomText>
              <CustomText>{txt('faellesskabsprisen.whyBody')}</CustomText>
            </View>
            <View>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/faellesskabsprisen/faellesskabspris-1.jpg',
                }}
                resizeMode="contain"
                style={styles.image}
                accessibilityIgnoresInvertColors
                accessible
                accessibilityRole="image"
                accessibilityLabel={txt('faellesskabsprisen.teamImage')}
              />
              <CustomText>
                {txt('faellesskabsprisen.image1Text')}
              </CustomText>
            </View>
            <View style={[styles.marginBottom, styles.marginTop]}>
              <CustomText style={[styles.center, styles.marginBottom]}>
                {txt('faellesskabsprisen.image2Text')}
              </CustomText>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/faellesskabsprisen/faellesskabspris-2.jpg',
                }}
                resizeMode="contain"
                style={styles.roundImage}
                accessibilityIgnoresInvertColors
                accessible
                accessibilityRole="image"
                accessibilityLabel={txt('faellesskabsprisen.statuette')}
              />
            </View>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  container: {
    backgroundColor: Colors.background,
    flex: 1,
    ...SpacingStyles.marginRightLeft10,
  },
  gif: {
    alignSelf: 'center',
    borderRadius: 10,
    height: Layout.isSmallDevice ? 200 : 250,
    margin: 5,
    marginBottom: 20,
    marginTop: 10,
    padding: 10,
    width: width,
  },
  image: {
    alignSelf: 'center',
    height: Layout.isSmallDevice ? 250 : 400,
    marginBottom: 10,
    marginTop: 10,
    width: width * 0.95,
    ...CornerStyles.borderRadiusS,
  },
  linkText: { color: Colors.tintColor },
  marginBottom: { marginBottom: 10 },
  marginTop: { marginTop: 10 },
  roundImage: {
    ...CornerStyles.roundImg,
    alignSelf: 'center',
    height: 300,
    width: 300,
  },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
