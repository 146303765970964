import React from 'react'
import Conversation from '../../components/views/Conversations/Conversation'
import { createConversation } from '../../services/api/Inbox'
import { useSelector } from 'react-redux'
import MainContainer from '../../components/MainContainer/MainContainer'

export default function ConversationScreen(props) {
  const conversation = useSelector((state) =>
    state.conversations.find((c) => c.conversation_id === parseInt(props.route.params.id))
  )

  async function createNewConversation() {
    const { boblId, groupId, slSessionId, vsSessionId, receiverId } = props.route.params
    return createConversation({
      postId: parseInt(boblId),
      groupId: parseInt(groupId),
      slSessionId: parseInt(slSessionId),
      vsSessionId: parseInt(vsSessionId),
      receiverId: parseInt(receiverId),
    })
  }

  function navigateToInfo() {
    props.navigation.navigate('ConversationInfo', {
      id: props.route.params.id,
      christmasPostId: conversation?.christmas_post_id,
    })
  }

  function navigateToProfile(userId) {
    props.navigation.navigate('ProfileView', {
      id: userId,
    })
  }

  return (
    <MainContainer noScroll>
      <Conversation
        createNewConversation={createNewConversation}
        route={props.route}
        navigation={props.navigation}
        navigateToInfo={navigateToInfo}
        navigateToProfile={navigateToProfile}
      />
    </MainContainer>
  )
}

