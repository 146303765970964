import React from 'react'
import { View, FlatList, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { SpacingStyles } from '../../../styles'
import UnionListItem from './UnionListItem'
import { CustomText } from '../../text/StyledText'

export default class MyUnions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      unions: [],
    }
  }

  async componentDidMount() {
    const unions = await this.props.getOwnUnions()
    this.setState({ unions })

    this.unsubscribe = this.props.navigation.addListener('focus', async () => {
      this.setState({ unions: [] })
      const unions = await this.props.getOwnUnions()
      this.setState({ unions })
    })
  }

  componentWillUnmount() {
    this.unsubscribe?.()
  }

  keyExtractor = (item, index) => index.toString()

  _renderItem = ({ item }) => (
    <UnionListItem
      item={item}
      onPressAction={() => {
        this.props.navigateToUnion(item.id)
      }}
    />
  )

  noUnions = () => {
    return (
      <View style={styles.container}>
        <CustomText style={styles.noMessage}>
          {txt('unions.edit.noOwnUnionsTitle')}
        </CustomText>
        <CustomText style={styles.noMessage}>
          {txt('unions.edit.noOwnUnionsText')}
        </CustomText>
      </View>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        {this.props.renderChips()}
        <FlatList
          keyExtractor={this.keyExtractor}
          extraData={this.state}
          data={this.state.unions}
          renderItem={this._renderItem}
          keyboardDismissMode="on-drag"
          ListEmptyComponent={this.noUnions}
        />
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  noMessage: {
    color: Colors.inactive,
    margin: 5,
    marginTop: 20,
    textAlign: 'center',
  },
})
