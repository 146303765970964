import React, { useEffect, useState } from 'react'
import { Image, Linking, StyleSheet, View } from 'react-native'
import { txt } from '../../../locales/i18n'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { getAvailableDates, getUserCountry } from '../../../services/api/LearnDanish'
import BookedSessions from '../LearnDanish/BookedSessions'
import { AvailableSessions } from '../LearnDanish/AvailableSessions'
import { useNavigation } from '@react-navigation/native'
import { NormalSnackbar } from '../../snackbars/Snackbar'
import { URLOpener } from '../../../utils/URLOpener'
import BehaviorDialog from './BehaviorDialog'
import PhoneInputForm from '../../formElements/PhoneInputForm'
import OptionsDialog from '../../dialogs/OptionsDialog'
import { ShadowStyles, TypographyStyles } from '../../../styles'
import { useSelector } from 'react-redux'
import Layout from '../../../constants/Layout'
import { CustomText } from '../../text/StyledText'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import { dropShadow } from '../../../styles/shadows'
import CustomPressable from "../../pressables/CustomPressable"
import { SproglandCountryDialog } from "../../dialogs/SproglandCountryDialog"
import { CafeMap } from './CafeMap'

export default function SproglandUserView({ getBookedSessions, bookedSessions, bookSession, juniorId }) {
  const navigation = useNavigation()
  const [numberUpdated, setNumberUpdated] = useState(false)
  const [numberDidNotUpdate, setNumberDidNotUpdate] = useState(false)
  const [availableDates, setAvailableDates] = useState([])
  const [showHelpPopup, setShowHelpPopup] = useState(false)
  const [optionsVisible, setOptionsVisible] = useState(false)
  const phoneNumber = useSelector((state) => state.user?.phone)
  const [countryDialogVisible, setCountryDialogVisible] = useState(false)

  useEffect(() => {
    fetchAvailableDates()
    getUserCountry().then((country) => {
      if (!country) setCountryDialogVisible(true)
    })
  }, []);

  function navigateToConversation(conversationId) {
    navigation.navigate('InboxStack', {
      screen: 'Conversation',
      initial: false,
      params: { id: conversationId },
    })
  }

  function navigateToNewConversation(userId, sessionId) {
    navigation.navigate('InboxStack', {
      screen: 'Conversation',
      initial: false,
      params: {
        id: 'new',
        receiverId: userId,
        slSessionId: sessionId,
      },
    })
  }

  async function fetchAvailableDates() {
    const dates = await getAvailableDates(juniorId);
    const updatedArray = dates.map(item => ({ ...item, dates: [], dropdownActive: false }));
    setAvailableDates(updatedArray);
  }

  function toggleModal() {
    setShowHelpPopup((prev) => !prev)
  }

  const junior = {
    label: txt(`learnDanish.guide.linkTextJunior`),
    icon: "info-circle",
    action: async () => {
      await URLOpener(`https://filer.boblberg.dk/Files/Guides/guide_sprogland_junior_dansk.pdf`)
      setOptionsVisible(false)
    },
  }

  const guideEnglish = {
    label: txt(`learnDanish.guide.linkTextEnglish`),
    icon: "info-circle",
    action: async () => {
      await URLOpener(`https://filer.boblberg.dk/Files/Guides/guide_sprogland_english.pdf`)
      setOptionsVisible(false)
    },
  }

  const guideDanish = {
    label: txt(`learnDanish.guide.linkTextDanish`),
    icon: "info-circle",
    action: async () => {
      await URLOpener(`https://filer.boblberg.dk/Files/Guides/guide_sprogland_dansk.pdf`)
      setOptionsVisible(false)
    },
  }

  const dictionary = {
    label: txt('learnDanish.volunteer.materials.dictionary'),
    icon: "book",
    action: async () => {
      await URLOpener('https://filer.boblberg.dk/Files/Sprogland/Ukrainian-Danish-English-dictionary.pdf')
      setOptionsVisible(false)
    },
  }

  const behavior = {
    label: txt('learnDanish.help.label'),
    icon: "shield",
    action: async () => {
      toggleModal()
      setOptionsVisible(false)
    },
  }

  const options = [
    {
      label: txt('learnDanish.guide.faq'),
      icon: "question-circle",
      action: async () => {
        await URLOpener(`https://boblberg.dk/sprogland/faq`)
        setOptionsVisible(false)
      },
    },
  ]

  if (juniorId) options.push(junior)
  if (!juniorId) options.push(guideEnglish, guideDanish)
  options.push(dictionary)
  options.push(behavior)

  return (
    <View>
      {(phoneNumber === 0 || !phoneNumber) && (
        <View style={{ maxWidth: 400, alignSelf: 'center' }}>
          <PhoneInputForm buttonTitleStyle={{ ...TypographyStyles.textSmall }} />
        </View>
      )}

      <View style={styles.sessionContainer}>
        <View style={!Layout.isSmallDevice && styles.layout}>
          <View style={!Layout.isSmallDevice && styles.element}>
            <View style={!Layout.isSmallDevice && { flexDirection: 'row', justifyContent: 'space-evenly', flex: 4 }}>
              <View style={!Layout.isSmallDevice && { flex: 2 }}>
                <BookedSessions
                  sessions={bookedSessions}
                  loadSessions={getBookedSessions}
                  reloadAvailable={fetchAvailableDates}
                  startConversation
                  navigateToConversation={navigateToConversation}
                  navigateToNewConversation={navigateToNewConversation}
                  navigation={navigation}
                  juniorId={juniorId}
                />
              </View>
              <View style={!Layout.isSmallDevice && { flex: 2 }}>
                <AvailableSessions
                  availableDates={availableDates}
                  getAvailableDates={fetchAvailableDates}
                  getBookedSessions={getBookedSessions}
                  bookSession={bookSession}
                  juniorId={juniorId}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={[!Layout.isSmallDevice && styles.layout, availableDates.length < 1 && bookedSessions.length < 1 && {marginTop: 50}]}>
          <View style={!Layout.isSmallDevice && styles.element}>
            <View style={!Layout.isSmallDevice && { flexDirection: 'row', justifyContent: 'space-evenly', flex: 4 }}>
              <View style={!Layout.isSmallDevice && { flex: 2 }}>
                <View style={{ margin: 10 }}>
                  <CustomText font="bold" style={{ textAlign: 'center' }}>
                    {txt('learnDanish.user.help')}
                  </CustomText>
                  <View style={styles.item}>
                    <NormalIcon name="envelope" size={16} stroke="fas" style={{ paddingRight: 10 }}
                                color={Colors.redCrossRed} />
                    <CustomText font="small" style={{ lineHeight: 22, flex: 1, flexWrap: 'wrap' }}>
                      {txt('learnDanish.user.helpText')}
                      <CustomPressable onPress={() => Linking.openURL(juniorId ? 'mailto:sproglandjunior@rodekors.dk' : 'mailto:sprogland@rodekors.dk')}>
                        <CustomText font="small" style={{ color: Colors.blueAzure }}>
                          {juniorId ? 'sproglandjunior@rodekors.dk' : 'sprogland@rodekors.dk'}
                        </CustomText>
                      </CustomPressable>
                    </CustomText>
                  </View>
                </View>
              </View>
              <View style={!Layout.isSmallDevice && { flex: 2 }}>
                <View style={{ margin: 10 }}>
                  <CustomText font="bold" style={{ textAlign: 'center' }}>
                    {txt('learnDanish.user.reference')}
                  </CustomText>
                  <View style={styles.item}>
                    <Image
                      style={{ width: 16, height: 16, marginRight: 10 }}
                      resizeMode={'contain'}
                      accessibilityIgnoresInvertColors
                      source={{ uri: 'https://filer.boblberg.dk/Assets/Img/sprogland/rk-logo.png' }}
                      accessible
                      accessibilityRole="image"
                    />
                    <CustomText font="small" style={{ lineHeight: 22, flex: 1, flexWrap: 'wrap' }}>
                      {txt('learnDanish.user.referenceText')}
                      <CustomPressable onPress={() => URLOpener('https://www.rodekors.dk/sprogunivers/vidensbank')}>
                        <CustomText font="small" style={{ color: Colors.blueAzure }}>
                          {txt('learnDanish.user.referenceLink')}
                        </CustomText>
                      </CustomPressable>
                    </CustomText>
                  </View>
                </View>
              </View>
            </View>

			<CafeMap />

          </View>
        </View>
        <BehaviorDialog
          visible={showHelpPopup}
          close={toggleModal}
        />
        <ButtonPrimary
          title={txt('learnDanish.guide.supportingMaterials')}
          onPress={() => setOptionsVisible(true)}
          titleStyle={{ ...TypographyStyles.textSmall }}
          style={[styles.button, { marginTop: 12 }]}
        />
        <OptionsDialog
          visible={optionsVisible}
          onDismiss={() => setOptionsVisible(false)}
          options={options}
          font="smallBold"
        />
        <SproglandCountryDialog
          countryDialogVisible={countryDialogVisible}
          setCountryDialogVisible={setCountryDialogVisible}
        />
      </View>

      <NormalSnackbar style={{position: 'absolute', bottom: 12}} visible={numberUpdated} onDismiss={() => setNumberUpdated(false)}>
        {txt('videoChat.phoneUpdated')}
      </NormalSnackbar>
      <NormalSnackbar visible={numberDidNotUpdate} onDismiss={() => setNumberDidNotUpdate(false)}>
        {txt('videoChat.phoneNotUpdated')}
      </NormalSnackbar>
    </View>
  )
}

const styles = StyleSheet.create({
  sessionContainer: {
    alignSelf: 'stretch',
    marginBottom: 20,
  },
  button: {
    width: 150,
    paddingHorizontal: 8,
  },
  layout: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  element: {
    width: '100%',
    minWidth: 500,
    maxWidth: 900,
  },
  item: {
    backgroundColor: Colors.white,
    borderRadius: 2,
    marginTop: 10,
    padding: 10,
    ...ShadowStyles.liftedShadow,
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    borderRadius: 70,
    backgroundColor: Colors.redCrossRed,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    ...dropShadow,
    position: 'absolute',
    right: 10,
    zIndex: 10,
  },
  badgeBig: {
    width: Layout.isSmallDevice ? 120 : 140,
    height: Layout.isSmallDevice ? 120 : 140,
    top: -170,
  },
  badgeText: {
    color: Colors.white,
    textAlign: 'center',
    lineHeight: 24,
    marginTop: Layout.isSmallDevice ? 6 : 12
  },
})
