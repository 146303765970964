export function getHexColor(userId) {
  const userIdString = userId.toString()
  let hash = 0
  for (let i = 0; i < userIdString.length; i++) {
    hash = userIdString.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}
