import { URLOpener } from './URLOpener'
import store from '../redux/store'

export default async function startVideoSession(roomKey, vonageSessionId, type) {
  const userId = store.getState().user?.id
  if (!vonageSessionId) {
    if (type === 'snaksammen') return URLOpener('https://video.boblberg.dk/video/room/0/' + roomKey)
    else return URLOpener('https://video.boblberg.dk/video/room/1/' + roomKey)
  } else return URLOpener(`https://v.boblberg.dk/${type}/${vonageSessionId}/${userId}`)
}
