import React, { useEffect } from 'react'
import { View, Platform, StyleSheet, Image } from 'react-native'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import SignupField from '../../components/views/Auth/SignupField'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import { TypographyStyles, SpacingStyles } from '../../styles'
import MainContainer from '../../components/MainContainer/MainContainer'

export default function LearnDanishSignupScreen(props) {
  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Image
          style={{ height: 24, width: 200 }}
          resizeMode="contain"
          source={require('../../assets/images/sprogland_dk.png')}
          accessibilityLabel={'Sprogland'}
          accessibilityIgnoresInvertColors
        />
      ),
    })
  }, [])

  function keyboardSpacerShow() {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  return (
    <MainContainer>
      <View style={styles.widthHeight}>
        <View style={styles.textContainer}>
          <CustomText style={styles.textBold}>{txt('learnDanish.signup.title')}</CustomText>
          <CustomText style={styles.text}>{txt('learnDanish.signup.subtitle')}</CustomText>
        </View>

        <SignupField
          navigation={props.navigation}
          originScreen={props.route.params.originScreen}
          originStack={'MenuStack'}
          originRootStack={'app'}
          route={props.route}
        />
      </View>
      {keyboardSpacerShow()}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  text: { ...TypographyStyles.text, marginBottom: 15, textAlign: 'center' },
  textBold: { ...TypographyStyles.textBold, textAlign: 'center' },
  textContainer: { marginLeft: 10, marginRight: 10 },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
})
