import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { useRoute } from '@react-navigation/core'
import { useSelector } from 'react-redux'

import PostItem from './PostItem'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { SpacingStyles } from '../../../styles'
import { PostFeedSubscriptionDialog } from './Dialogs/PostFeedSubscriptionDialog'
import { Features } from '../../../constants/Features'
import { SubscriptionPromoCard } from './SubscriptionPromoCard'

export default function FavoritePosts(props) {
	const { params } = useRoute()

	const [posts, setPosts] = useState([])
	const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false)
	const [showSubscriptionCard, setShowSubscriptionCard] = useState(false)
	const country = useSelector(state => state.user.country)
	const userFeatures = useSelector(state => state.user.features)
	
	useEffect(() => {
		if (params?.chip !== txt('posts.chipParams.favorites')) return

		getFavoritePosts()

		if (userFeatures.includes(Features.START_CONVERSATION)) setShowSubscriptionCard(false)
		else setShowSubscriptionCard(true)
	}, [params])

	async function getFavoritePosts() {
		const posts = await props.getFavoritePosts()
		setPosts(posts)
	}

	function handlePostClick(postId, postIndex) {
		if (country === 'Sweden' && !userFeatures.includes(Features.START_CONVERSATION)) {
			const showDialog = postIndex % 3 === 2
			if (showDialog) {
				return setShowSubscriptionDialog(true)
			}
		}
		props.navigateToPost(postId)
	}

	function renderItem({ item, index }) {
		return (
			<PostItem
				item={item}
				onPressAction={() => handlePostClick(item.id, index)}
			/>
		)
	}

	const renderListHeaderComponent = () => {
		if (showSubscriptionCard === false || posts.length === 0) return null
		return (
			<SubscriptionPromoCard
				title={txt('posts.promoteSubscriptionCard.favoritePosts.title')}
				description={txt('posts.promoteSubscriptionCard.favoritePosts.description')}
				origin={'favorite-posts-card'}
				universe={props.universe}
			/>
		)
	}

	function noPosts() {
		return (
		<View style={[styles.container, { marginTop: 24 }]}>
			<CustomText font={'bold'} style={styles.text}>
				{txt('favoritePosts.noPostsTitle')}
			</CustomText>
			<CustomText style={styles.text}>
				{txt('favoritePosts.noPostsText')}
			</CustomText>
			<View style={styles.alignSelfCenter}>
				<NormalIcon
					name="star"
					color={Colors.inactive}
				/>
			</View>
		</View>
		)
	}

	return (
		<View style={[styles.container, { ...props.style }]}>
			{props?.renderChips()}
			<FlatList
				ListHeaderComponent={renderListHeaderComponent}
				keyExtractor={(item) => item.id?.toString()}
				data={posts}
				renderItem={renderItem}
				keyboardDismissMode="on-drag"
				ListEmptyComponent={noPosts}
			/>
			<PostFeedSubscriptionDialog
				visible={showSubscriptionDialog}
				close={() => setShowSubscriptionDialog(false)}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: { flex: 1, ...SpacingStyles.widthAndHeight },
	alignSelfCenter: { alignSelf: 'center', margin: 12 },
	text: {
		color: Colors.inactive,
		textAlign: 'center',
		marginBottom: 6
	},
})
