import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Colors from '../../../constants/Colors'
import { ShadowStyles } from '../../../styles'
import { Portal } from 'react-native-paper'
import MainContainer from '../../MainContainer/MainContainer'
import { useNavigation } from '@react-navigation/core'
import PhoneInputForm from '../../formElements/PhoneInputForm'
import { BookedSessions } from './BookedSessions'
import { AvailableSessions } from './AvailableSessions'
import { getAvailableDates, getBookedSessions } from '../../../services/api/snakSammen'
import Layout from '../../../constants/Layout'
import { CustomText } from "../../text/StyledText"
import CustomPressable from "../../pressables/CustomPressable"
import GetStartedDialog from "./dialogs/GetStartedDialog"
import { txt } from "../../../locales/i18n"
import NormalIcon from "../../icons/NormalIcon"
import { useSelector } from "react-redux"
import CustomDialog, { ContentContainer } from "../../dialogs/CustomDialog"
import { ButtonPrimary } from "../../buttons/StyledButtons"

export default function SnakSammenOversigt() {
  const navigation = useNavigation()

  const [errorMessage, setErrorMessage] = useState(null)
  const [getStartedDialogVisible, setGetStartedDialogVisible] = useState(false)
  const [availableDates, setAvailableDates] = useState([])
  const [bookedSessions, setBookedSessions] = useState([])
  const phoneNumber = useSelector((state) => state.user?.phone)

  useEffect(() => {
    getAvailableDates().then(setAvailableDates)
    getBookedSessions().then(setBookedSessions)
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      await handleRefresh()
    }, 60000)
    return () => clearInterval(interval)
  })

  async function handleRefresh() {
    await getBookedSessions().then(setBookedSessions)
    await getAvailableDates().then(setAvailableDates)
  }

  useEffect(() => {
    navigation.setOptions({
      title: 'SnakSammen | Boblberg',
      headerBackTitle: ' ',
      headerStyle: {
        height: Layout.isSmallDevice ? 115 : 100,
        elevation: 0,
        shadowOpacity: 0,
        borderBottomWidth: 0,
        backgroundColor: Colors.white,
        borderBottomColor: Colors.redCross.border,
      },
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={{ uri: 'https://filer.boblberg.dk/assets/img/logo/rode-kors-og-boblberg-logo.png' }}
          accessibilityLabel={'SnakSammen'}
          accessibilityIgnoresInvertColors
        />
      ),
      headerRight: () => (
        <CustomPressable
          onPress={() => setGetStartedDialogVisible(true)}
          style={styles.helpButton}
        >
          <NormalIcon name="question-circle" color={Colors.redCross.text} size={16} />
          <CustomText font="gotham-bold" style={{ color: Colors.redCross.text, paddingLeft: 6, fontSize: 14 }}>
            {Layout.small ? txt('videoChat.user.help') : txt('videoChat.user.getStarted')}
          </CustomText>
        </CustomPressable>
      ),

    })
  }, [])

  return (
    <Portal.Host>
      <MainContainer backgroundColor={Colors.redCross.background}>
        <View style={styles.widthHeight}>

          {(phoneNumber === 0 || !phoneNumber) && (
            <View style={{marginVertical: 12}}>
              <PhoneInputForm
                buttonTitleStyle={{ fontFamily: 'gotham-bold', fontSize: 16, color: Colors.white }}
                buttonStyle={{ backgroundColor: Colors.redCross.base, borderRadius: 10 }}
                style={{...ShadowStyles.unsetShadows, borderRadius: 10}}
                textStyle={{ textAlign: 'left', fontFamily: 'gotham-book', color: Colors.inactive }}
              />
            </View>
          )}

          <BookedSessions bookedSessions={bookedSessions} handleRefresh={handleRefresh} />

          <AvailableSessions
            handleRefresh={handleRefresh}
            availableDates={availableDates}
            setAvailableDates={setAvailableDates}
            setErrorMessage={setErrorMessage}
          />

          <GetStartedDialog
            visible={getStartedDialogVisible}
            close={() => setGetStartedDialogVisible(false)}
          />

          <CustomDialog visible={errorMessage !== null} onDismiss={() => setErrorMessage(null)}>
            <ContentContainer>
              <CustomText font="gotham-bold" style={styles.dialogText}>{txt('videoChat.bookingErrors.header')}</CustomText>
              <CustomText font="gotham-small" style={[styles.dialogText, { color: Colors.textLight }]}>{errorMessage}</CustomText>
              <ButtonPrimary
                style={styles.dialogButton}
                title={txt('videoChat.bookingErrors.buttonTitle')}
                titleStyle={{ fontSize: 16 }}
                onPress={() => setErrorMessage(null)}
              />
            </ContentContainer>
          </CustomDialog>

        </View>
      </MainContainer>

    </Portal.Host>
  )
}

const styles = StyleSheet.create({
  widthHeight: {
    flex: 1,
    maxWidth: 1000,
    width: '100%',
    alignSelf: 'center',
    marginBottom: 50
  },
  helpButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 25,
    borderWidth: 1,
    borderColor: Colors.redCross.baseSecondary,
    backgroundColor: Colors.redCross.baseSecondary,
    marginHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  dialogText: {
    margin: 10,
    textAlign: 'center',
  },
  dialogButton: {
    borderRadius: 10,
    backgroundColor: Colors.blueAzure,
    marginTop: 12,
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  logo: {
    width: Layout.small ? 125 : 150,
    height: Layout.isSmallDevice ? 40 : 70
  },
})
