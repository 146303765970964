import React from 'react'
import { Linking, Platform } from 'react-native'
import { StyleSheet } from 'react-native'
import * as Updates from 'expo-updates'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../dialogs/CustomDialog'
import CustomPressable from "../../pressables/CustomPressable"
import { ButtonPrimary } from '../../buttons/StyledButtons'

export function MunicipalityInfoDialog(props) {
  return (
    <CustomDialog visible={props.visible} {...props}>
      <ContentContainer>
        <CustomText>
          {txt('profile.dialogText')}
          <CustomPressable onPress={() => Linking.openURL('mailto:' + txt('profile.support'))}>
            <CustomText style={styles.linkText}>
              {txt('profile.support')}
            </CustomText>
          </CustomPressable>
        </CustomText>
      </ContentContainer>
      <ActionButton onPress={props.onPress}>
        {txt('profile.dialog')}
      </ActionButton>
    </CustomDialog>
  )
}

export function LanguageChangedDialog(props) {
  return (
    <CustomDialog visible={props.visible} {...props}>
      <ContentContainer>
        <CustomText>{txt('settings.languageChangedText')}</CustomText>
      </ContentContainer>
      <ActionButton onPress={props.onPress}>{txt('dialog.ok')}</ActionButton>
      {props.children}
    </CustomDialog>
  )
}

export const ReloadDialog = ({ visible }) => (
	<CustomDialog visible={visible}>
		<ContentContainer noScroll style={reloadStyles.content}>
			<CustomText font="largeBold">{txt('profile.municipalityChangedTitle')}</CustomText>
			<CustomText>{txt('profile.municipalityChangedText1')}</CustomText>
			<CustomText>{txt('profile.municipalityChangedText2')}</CustomText>
			<CustomText>{txt('profile.municipalityChangedText3')}</CustomText>
			<ButtonPrimary
				onPress={() => Platform.OS === 'web' ? window.location.reload() : Updates.reloadAsync()}
				title={txt('global.ok')}
				titleStyle={{ paddingHorizontal: 12 }}
				style={{ backgroundColor: Colors.blueAzure}}
			/>
		</ContentContainer>
	</CustomDialog>
)

const reloadStyles = StyleSheet.create({
	content: {
		gap: 12,
	}
})

const styles = StyleSheet.create({
  linkText: {
    color: Colors.blue,
  },
})
