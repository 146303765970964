import { FlatList, Image, Modal, Platform, StyleSheet, useWindowDimensions, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import NormalIcon from '../../icons/NormalIcon'
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import CustomPressable from "../../pressables/CustomPressable"

function Arrow({ direction, onPress }) {
  const [hover, setHover] = useState(false)
  const dimensions = useWindowDimensions()

  const icons = {
    left: "chevron-left",
    right: "chevron-right",
  }

  return (
    <CustomPressable
      style={[styles.imageArrow, { height: dimensions.height }, hover ? styles.arrowBackground : {}]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onPress={onPress}
    >
      <NormalIcon name={icons[direction]} stroke={'far'} size={48} color={Colors.white} />
    </CustomPressable>
  )
}

export default function ImageSlider({ visible, images, startIndex, dismiss }) {
  const [imageIndex, setImageIndex] = useState()
  const [isZooming, setIsZooming] = useState(false)
  const [isSliding, setIsSliding] = useState(false)
  const dimensions = useWindowDimensions()
  const listRef = useRef()
  const insets = useSafeAreaInsets()

  useEffect(() => {
    setImageIndex(startIndex)
  }, [startIndex])

  useEffect(() => {
    if (imageIndex !== undefined) {
      setTimeout(scrollToImage, 0)
    }
  }, [imageIndex])

  function scrollToImage() {
    const offset = imageIndex * dimensions.width
    listRef.current.scrollToOffset({
      offset,
      animated: false,
    })
  }

  function handleWebPreviousImage() {
    if (imageIndex > 0) {
      setImageIndex((prev) => prev - 1)
    }
  }

  function handleWebNextImage() {
    if (imageIndex < images.length - 1) {
      setImageIndex((prev) => prev + 1)
    }
  }

  function renderImage({ item }) {
    return (
      <View style={{ height: dimensions.height, width: dimensions.width }}>
        <ReactNativeZoomableView
          maxZoom={10}
          minZoom={1}
          contentWidth={dimensions.width}
          contentHeight={dimensions.height}
          onZoomBefore={() => isSliding}
          onShiftingBefore={(_, state, event) => event.zoomLevel <= 1}
          onTransform={(event) => setIsZooming(event.zoomLevel > 1)}
        >
          <Image
            style={StyleSheet.absoluteFillObject}
            resizeMode={'contain'}
            source={{ uri: item.base64 }}
            accessibilityIgnoresInvertColors
          />
        </ReactNativeZoomableView>
      </View>
    )
  }

  return (
    <Modal animationType={'none'} transparent={true} visible={visible}>
      <View style={{ flex: 1, backgroundColor: Colors.blackTransparent82 }}>
        {Platform.OS === 'web' && !Layout.isSmallDevice && (
            <View style={styles.imageDialogArrows}>
              <Arrow direction="left" onPress={handleWebPreviousImage} />
              <Arrow direction="right" onPress={handleWebNextImage} />
            </View>
        )}
        <CustomPressable style={[styles.imageDialogClose, { top: insets.top + 24 }]} onPress={dismiss}>
          <NormalIcon name="times-circle" stroke={'fad'} size={32} color={Colors.white} />
        </CustomPressable>
        <FlatList
          ref={listRef}
          contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
          data={images}
          keyExtractor={(item, index) => index.toString()}
          renderItem={renderImage}
          pagingEnabled
          scrollEnabled={!isZooming}
          horizontal
          onScrollBeginDrag={() => setIsSliding(true)}
          onScrollEndDrag={() => setIsSliding(false)}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  imageDialogClose: {
    position: 'absolute',
    right: 20,
    zIndex: 10,
    backgroundColor: Colors.blackTransparent54,
    borderRadius: 16,
  },
  imageDialogArrows: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  imageArrow: {
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  arrowBackground: {
    backgroundColor: Colors.blackTransparent2,
  },
})
