import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Dimensions, Platform, StyleSheet, View } from 'react-native'
import { WebView } from 'react-native-webview'

import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs'
import { useHeaderHeight } from '@react-navigation/elements'
import { useNavigation } from '@react-navigation/core'

import Paths from '../constants/Paths'
import colors from '../constants/Colors'
import { getAppConfig } from '../navigation/navigationConfig'
import * as Linking from 'expo-linking'

const createNavigationUrlPattern = (path) => {
	const trimPattern = /[/|?]$/
	const pathNormalized = path.replace(trimPattern, '')

	const paramPattern = /:(\w+)/g
	const patternString = pathNormalized.replace(paramPattern, ($, param) => `(?<${param}>\\w+)`)

	const pattern = new RegExp('^' + patternString + '\\/?$')
	return pattern
}

const createAppNavigations = (appConfig) => {
	const navigationResult = {}

	const getNavigationResult = (node, rootStack = null, stack = null, level = 1) => {
		for (const key in node) {
			if (key === 'initialRouteName') continue
			const nodeCurrent = node[key]

			if (typeof nodeCurrent === 'string') {
				const pathNormalized = nodeCurrent.replace('/:chip?', '')
				const pattern = createNavigationUrlPattern(pathNormalized)
				navigationResult[nodeCurrent] = { screen: key, stack, rootStack, pattern }
				continue
			}

			const stackCurrent = key.includes('Stack') ? key : stack
			const rootStackCurrent = level === 2 ? key : rootStack
			getNavigationResult(nodeCurrent, rootStackCurrent, stackCurrent, level + 1)
		}
	}

	getNavigationResult(appConfig)
	return Object.values(navigationResult)
}

export const getAppNavigation = (url) => {
	const appConfig = getAppConfig()
	const appNavigations = createAppNavigations(appConfig)

	const appNavigation = appNavigations.find(({ pattern }) => pattern.test(url)) ?? null
	if (appNavigation === null) return null

	const { groups: params = {} } = appNavigation.pattern.exec(url)
	appNavigation.params = params

	return appNavigation
}

export const CmsView = ({ uri }) => {
	const [headerHeight, tabBarHeight] = [useHeaderHeight(), useBottomTabBarHeight()]
	const [transitionEnded, setTransitionEnded] = useState(false)
	const [iframeMounted, setIframeMounted] = useState(true)
	const [loadingEnded, setLoadingEnded] = useState(false)
	const navigation = useNavigation()

	const containerDimensions = {
		height: Dimensions.get('window').height - headerHeight - tabBarHeight,
		width: Dimensions.get('window').width,
	}

	useEffect(() => {
		if (Platform.OS !== 'web') return

		const messageHandler = (event) => {
			if (event?.data?.type !== 'iframeNavigation') return

			const url = event?.data?.url ?? null
			if (url === null) return

			appNavigationHandler(url)
		}

		window.addEventListener('message', messageHandler)
		return () => window.removeEventListener('message', messageHandler)
	}, [])

	useEffect(() => {
		// mount webview after transition-end (android bug)
		const unsubscribeTransitionEnd = navigation.addListener('transitionEnd', () => setTransitionEnded(true))
		return () => unsubscribeTransitionEnd()
	}, [navigation])

	const remount = () => {
		setLoadingEnded(false)
		setIframeMounted(false)
		setTransitionEnded(false)

		setTimeout(() => {
			setIframeMounted(true)
			setTransitionEnded(true)
		}, 100)
	}

	const appNavigationHandler = (url) => {
		// ignores local navigations
		if (url.includes('?appMode')) return

		const transformPattern = /^https:\/\/app.boblberg\.\w+\//
		const urlNormalized = url.replace(transformPattern, '')

		const appNavigation = getAppNavigation(urlNormalized)

		if (appNavigation === null) {
			if (Platform.OS === 'web') {
				window.open(url, '_blank')
				return remount()
			}

			Linking.openURL(url)
			return remount()
		}

		navigation.navigate(appNavigation.rootStack, {
			params: { screen: appNavigation.screen, params: { ...appNavigation.params } },
			screen: appNavigation.stack,
		})

		remount()
	}

	return (
		<View style={[styles.container, containerDimensions]}>
			{loadingEnded === false && (
				<View style={styles.activityIndicatorContainer}>
					<ActivityIndicator color={colors.blue} />
				</View>
			)}

			{Platform.OS === 'web' && iframeMounted && (
				<iframe
					title="Boblberg website"
					style={styles.iframe}
					src={Paths.getBaseUrl() + uri + '?appMode'}
					onLoad={() => setLoadingEnded(true)}
				/>
			)}

			{Platform.OS !== 'web' && transitionEnded && (
				<WebView
					source={{ uri: Paths.getBaseUrl() + uri + '?appMode' }}
					onLoadEnd={() => setLoadingEnded(true)}
					style={[styles.webView, { display: loadingEnded ? 'flex' : 'none' }]}
					onNavigationStateChange={({ url }) => appNavigationHandler(url)}
				/>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.background,
	},

	activityIndicatorContainer: {
		justifyContent: 'center',
		position: 'absolute',
		height: '100%',
		width: '100%',
		zIndex: 1,
	},

	iframe: {
		height: '100%',
		border: 'none',
	},

	webView: {
		backgroundColor: colors.background,
	},
})
