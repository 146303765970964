import React from 'react'

import ABCMentalSundhed from '../../components/views/About/PartnersViews/ABCMentalSundhed'
import AdipositasForeningen from '../../components/views/About/PartnersViews/AdipositasForeningen'
import Arbejdermuseet from '../../components/views/About/PartnersViews/Arbejdermuseet'
import BørneBuddies from '../../components/views/About/PartnersViews/BørneBuddies'
import CHC from '../../components/views/About/PartnersViews/CHC'
import Copa from '../../components/views/About/PartnersViews/Copa'
import DLSC from '../../components/views/About/PartnersViews/DLSC'
import DrugRebels from '../../components/views/About/PartnersViews/DrugRebels'
import FamilieKlub from '../../components/views/About/PartnersViews/FamilieKlub'
import FrederiksbergMuseerne from '../../components/views/About/PartnersViews/FrederiksbergMuseerne'
import Fremdrift from '../../components/views/About/PartnersViews/Fremdrift'
import GirlTalk from '../../components/views/About/PartnersViews/GirlTalk'
import GivingTuesday from '../../components/views/About/PartnersViews/GivingTuesday'
import HivDanmark from '../../components/views/About/PartnersViews/HivDanmark'
import Hjerteforeningen from '../../components/views/About/PartnersViews/Hjerteforeningen'
import Idan from '../../components/views/About/PartnersViews/Idan'
import LandsforeningenLEV from '../../components/views/About/PartnersViews/LandsforeningenLEV'
import LærForLivet from '../../components/views/About/PartnersViews/LærForLivet'
import Misbrugsportalen from '../../components/views/About/PartnersViews/Misbrugsportalen'
import Modensomhed from '../../components/views/About/PartnersViews/Modensomhed'
import NSCC from '../../components/views/About/PartnersViews/NSCC'
import SindUngdom from '../../components/views/About/PartnersViews/SindUngdom'
import SOMS from '../../components/views/About/PartnersViews/SOMS'
import Startlinjen from '../../components/views/About/PartnersViews/Startlinjen'
import SUMH from '../../components/views/About/PartnersViews/SUMH'
import Veteran from '../../components/views/About/PartnersViews/Veteran'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class PartnersScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  checkSwitch = (param) => {
    switch (param) {
      case 'ABCMentalSundhed':
        return this.ABCMentalSundhed()

      case 'AdipositasForeningen':
        return this.AdipositasForeningen()

      case 'Arbejdermuseet':
        return this.Arbejdermuseet()

      case 'BørneBuddies':
        return this.BørneBuddies()

      case 'CHC':
        return this.CHC()

      case 'Copa':
        return this.Copa()

      case 'DrugRebels':
        return this.DrugRebels()

      case 'FamilieKlub':
        return this.FamilieKlub()

      case 'FrederiksbergMuseerne':
        return this.FrederiksbergMuseerne()

      case 'Fremdrift':
        return this.Fremdrift()

      case 'GirlTalk':
        return this.GirlTalk()

      case 'GivingTuesday':
        return this.GivingTuesday()

      case 'HivDanmark':
        return this.HivDanmark()

      case 'Hjerteforeningen':
        return this.Hjerteforeningen()

      case 'Idan':
        return this.Idan()

      case 'LandsforeningenLEV':
        return this.LandsforeningenLEV()

      case 'LærForLivet':
        return this.LærForLivet()

      case 'Misbrugsportalen':
        return this.Misbrugsportalen()

      case 'Modensomhed':
        return this.Modensomhed()

      case 'NSCC':
        return this.NSCC()

      case 'SindUngdom':
        return this.SindUngdom()

      case 'SOMS':
        return this.SOMS()

      case 'Startlinjen':
        return this.Startlinjen()

      case 'SUMH':
        return this.SUMH()

      case 'Veteran':
        return this.Veteran()

      case 'DLSC':
        return this.DLSC()

      default:
        if (__DEV__) console.log('DEFAULT SWITCH')
    }
  }

	ABCMentalSundhed = () => { return <ABCMentalSundhed /> }
	AdipositasForeningen = () => { return <AdipositasForeningen /> }
	Arbejdermuseet = () => { return <Arbejdermuseet /> }
	BørneBuddies = () => { return <BørneBuddies /> }
	CHC = () => { return <CHC /> }
	Copa = () => { return <Copa /> }
	DLSC = () => { return <DLSC /> }
	DrugRebels = () => { return <DrugRebels /> }
	FamilieKlub = () => { return <FamilieKlub /> }
	FrederiksbergMuseerne = () => { return <FrederiksbergMuseerne /> }
	Fremdrift = () => { return <Fremdrift /> }
	GirlTalk = () => { return <GirlTalk /> }
	GivingTuesday = () => { return <GivingTuesday /> }
	HivDanmark = () => { return <HivDanmark /> }
	Hjerteforeningen = () => { return <Hjerteforeningen /> }
	Idan = () => { return <Idan /> }
	LandsforeningenLEV = () => { return <LandsforeningenLEV /> }
	LærForLivet = () => { return <LærForLivet /> }
	Misbrugsportalen = () => { return <Misbrugsportalen /> }
	Modensomhed = () => { return <Modensomhed /> }
	NSCC = () => { return <NSCC /> }
	SindUngdom = () => { return <SindUngdom /> }
	SOMS = () => { <SOMS /> }
	Startlinjen = () => { return <Startlinjen /> }
	SUMH = () => { return <SUMH /> }
	Veteran = () => { return <Veteran /> }

	render() {
		return (
			<MainContainer>
				{this.checkSwitch(this.props.route.params.id)}
			</MainContainer>
		)
	}
}
