import store from '../redux/store'
import { setUserPreferences, setUserToken, resetStateAction, setDeviceId, setUser } from '../redux/actions'
import { connectSocket, disconnectSocket } from './socketService'
import { getConversations } from './api/Inbox'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { unregisterPushNotifications } from './notifications'
import i18n from '../locales/i18n'
import * as Notifications from 'expo-notifications'
import { AppState, Platform } from 'react-native'
import { selectUnreadNotifications } from '../redux/selectors'
import { getJoinedGroupsList } from './api/Groups'
import Cookies from 'js-cookie'
import { getOwnChristmasPost } from './api/ChristmasFriends'
import * as Device from 'expo-device'
import * as Crypto from 'expo-crypto'

import { getUserMunicipalities } from './api/Municipalities'
import { getRegion } from './localization'
import { apiGet, apiPost } from "./api/ApiRequest"
import Paths from "../constants/Paths"

const asyncNames = ['userToken', 'language', 'userId']
let appStateChangeSubscription = null

// Gets all notifications every time the app is reopened from the background
const handleAppStateChange = async (newState) => {
  if (newState === 'active') {
    await getConversations()
    await getJoinedGroupsList()
    const badgeCount = selectUnreadNotifications(store.getState())
    await Notifications.setBadgeCountAsync(badgeCount)
  }
}

export async function generateDeviceId(userId) {
  if (Platform.OS === 'web' || !userId) return
  const { deviceName, modelName, totalMemory } = Device
  const userInfo = deviceName.concat(modelName).concat(totalMemory.toString()).concat(userId)
  const deviceId = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, userInfo) //SHA1 because max length of id is 50
  if (deviceId) store.dispatch(setDeviceId(deviceId))
}

export async function postLoginAction(token) {
  store.dispatch(setUserToken(token))
  const user = await getOwnAuthUser()
  if (!user) return await authLogout()
  await generateDeviceId(user.id)
  const preferences = await getAuthUserPreferences()
  i18n.locale = preferences.language
  store.dispatch(setUserPreferences(preferences))
  await getOwnChristmasPost()
  await connectSocket(token)
  await getConversations()
  await getJoinedGroupsList()
  await getUserMunicipalities()

  if (Platform.OS !== 'web') {
    appStateChangeSubscription = AppState.addEventListener('change', handleAppStateChange)
  }
}

export async function postLogoutAction() {
  await unregisterPushNotifications()
  await AsyncStorage.multiRemove(asyncNames)

  if (Platform.OS === 'web') removeToken()
  store.dispatch(resetStateAction())
  await disconnectSocket()

  if (Platform.OS === 'web') {
    window.location.replace(Paths.getBaseUrl())
  }

  if (Platform.OS !== 'web') appStateChangeSubscription?.remove()
}

export async function storeAccessToken(token) {
  if (Platform.OS === 'web') {
    const config = getTokenCookieConfig()
    Cookies.set('token', token, config)
  } else {
    // Only use AsyncStorage on native app
    await AsyncStorage.setItem('userToken', token)
  }
}

export async function getOwnAuthUser() {
  const res = await apiGet('users/getOwn')
  const user = res?.data
  if (user) store.dispatch(setUser(user))
  return user
}

export async function getAuthUserPreferences() {
  const res = await apiGet('users/user/preferences')
  return res?.data
}

export async function authLogout() {
  await apiPost({},'users/logout')
  await postLogoutAction()
}

export const getTokenCookieConfig = () => {
	const config = { sameSite: 'strict', expires: 365 }

	if (__DEV__ === false) {
		config.domain = 'boblberg.' + getRegion()
		config.secure = true
	}

	return config
}

export const getToken = () => {
	const config = getTokenCookieConfig()
	const tokenLegacy = Cookies.get('token') ?? null

	if (tokenLegacy) {
		Cookies.remove('token')
		Cookies.set('token', tokenLegacy, config)
	}
	
	const token = Cookies.get('token', config)
	return token ?? null
}

const removeToken = () => {
	const config = getTokenCookieConfig()
	Cookies.remove('token', config)
	Cookies.remove('token') // legacy token
}
