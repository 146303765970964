import React, { useState } from 'react'
import { View, StyleSheet, ScrollView, Platform } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { SpacingStyles, TypographyStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import { CustomInputs, CustomMultilineInputs } from '../../text/StyledTextinputs'
import { RoundSquareBtn } from '../../buttons/StyledButtons'
import Colors from '../../../constants/Colors'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import { createGroup } from '../../../services/api/Groups'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { UploadImage } from '../../../services/api/UploadImage'
import { RadioButton } from '../../formElements/formElements'
import imageBobl from '../../../assets/images/bobl.png'

export default function GroupCreate(props) {
  const [group, setGroup] = useState({
    name: '',
    description: '',
    password: '',
    image: {},
    groupType: null,
  })
  const [image, setImage] = useState(null)

  const [groupValidation, setGroupValidation] = useState({
    nameTooShort: false,
    descriptionTooShort: false,
    imageMissing: false,
    groupTypeMissing: false,
    groupPasswordMissing: false,
  })

  function updateGroupState(update) {
    setGroup((prev) => ({
      ...prev,
      ...update,
    }))
  }

  function updateGroupValidationState(update) {
    setGroupValidation((prev) => ({
      ...prev,
      ...update,
    }))
  }

  const keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  function validateGroup() {
    if (group.name.length >= 1) {
      updateGroupValidationState({ nameTooShort: false })
    }

    if (group.description.length >= 1) {
      updateGroupValidationState({ descriptionTooShort: false })
    }
    if (group.image.image) {
      updateGroupValidationState({ imageMissing: false })
    }

    if (!group.groupType === 2) {
      updateGroupState({ password: '' })
    }

    if (group.groupType) {
      updateGroupValidationState({ groupTypeMissing: false })
    }

    if (group.name.length < 1) {
      updateGroupValidationState({ nameTooShort: true })
      return false
    } else if (!image) {
      updateGroupValidationState({ imageMissing: true })
      return false
    } else if (group.description.length < 1) {
      updateGroupValidationState({ descriptionTooShort: true })
      return false
    } else if (!group.groupType) {
      updateGroupValidationState({ groupTypeMissing: true })
      return false
    } else if (group.groupType === 2 && group.password < 1) {
      updateGroupValidationState({ groupPasswordMissing: true })
      return false
    }

    return true
  }

  function uploadImg() {
    if (image) {
      return UploadImage(image, 0, 'Groups').catch((err) => {
        if (__DEV__) console.log('IMAGE UPLOAD FAIL ', err)
      })
    }
  }

  function clearInputs() {
    setImage(null, null)
    updateGroupState({
      name: '',
      description: '',
      password: '',
      image: {},
      groupType: null,
    })
  }

  async function createNewGroup() {
    if (validateGroup()) {
      const img_id = await uploadImg()

      const data = {
        name: group.name,
        description: group.description,
        password: group.groupType === 2 ? group.password : '',
        groupType: group.groupType,
        img_id: img_id,
      }

      if (props.universeId) data.universeId = props.universeId

      const created = await createGroup(data)

      if (created) {
        clearInputs()
        props.navigateToGroup(created)
      }
    }
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.settingsContainer}>
        <View style={styles.marginBottom}>
          <CustomText style={styles.title}>{txt('groups.settings.groupImage')}</CustomText>
        </View>
        <View style={styles.marginBottom}>
          <View style={styles.imagePickerContainer}>
            <ImagePicker
              editMode
              setImage={setImage}
              defaultImage={group?.image?.uri ?? null}
              onDelete={() => {}}
              placeholder={imageBobl}
              hideDelete
            />
          </View>

          {/* <View style={styles.marginBottom}>
            <ImagePicker setImage={setImage} image={group?.image?.image ?? null}/>
          </View> */}

          {groupValidation.imageMissing && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('groups.addGroup.missingImage')}
            </CustomText>
          )}
        </View>
        <View style={styles.marginBottom}>
          <CustomText style={styles.title}>{txt('groups.settings.groupName')}</CustomText>
        </View>
        <View style={styles.marginBottom}>
          <View style={styles.marginBottom}>
            <CustomInputs
              value={group.name}
              placeholder={txt('groups.addGroup.namePlaceholder')}
              accessibilityLabel={txt('groups.addGroup.namePlaceholder')}
              accessibilityHint={txt('groups.addGroup.nameHint')}
              onChangeText={(value) => {
                updateGroupState({
                  name: value,
                })
              }}
            />
          </View>

          {groupValidation.nameTooShort && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('groups.addGroup.invalidName')}
            </CustomText>
          )}
        </View>
        <View style={styles.marginBottom}>
          <CustomText style={styles.title}>{txt('groups.settings.groupDescription')}</CustomText>
        </View>
        <View style={styles.marginBottom}>
          <View style={styles.marginBottom}>
            <CustomMultilineInputs
              value={group.description}
              placeholder={txt('groups.addGroup.descriptionPlaceholder')}
              accessibilityLabel={txt('groups.addGroup.descriptionPlaceholder')}
              accessibilityHint={txt('groups.addGroup.descriptionHint')}
              onChangeText={(value) => {
                updateGroupState({
                  description: value,
                })
              }}
            />
          </View>
          {groupValidation.descriptionTooShort && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('groups.addGroup.invalidDescription')}
            </CustomText>
          )}
        </View>

        <View style={styles.marginBottom}>
          <CustomText style={styles.title}>{txt('groups.settings.groupType')}</CustomText>
        </View>
        <View style={styles.marginBottom}>
          {groupValidation.groupTypeMissing && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('groups.addGroup.missingGroupType')}
            </CustomText>
          )}
          {/* Public group */}
          <View style={styles.row}>
            <RadioButton
              checked={group.groupType === 4 ? true : false}
              onPress={() => updateGroupState({ groupType: 4 })}
              text={txt('groups.addGroup.openGroupOption')}
            />
          </View>
        </View>
        <View style={styles.marginBottom}>
          {/* Request group */}
          <View style={styles.row}>
            <RadioButton
              checked={group.groupType === 3 ? true : false}
              onPress={() => updateGroupState({ groupType: 3 })}
              text={txt('groups.addGroup.requestGroupOption')}
            />
          </View>
        </View>
        <View style={styles.marginBottom}>
          {/* Password group */}
          <View style={styles.row}>
            <RadioButton
              checked={group.groupType === 2 ? true : false}
              onPress={() => updateGroupState({ groupType: 2 })}
              text={txt('groups.addGroup.passwordGroupOption')}
            />
          </View>
          {group.groupType === 2 && groupValidation.groupPasswordMissing && (
            <CustomText font="bold" style={[styles.errorMessage, styles.textCenter]}>
              {txt('groups.addGroup.missingGroupPassword')}
            </CustomText>
          )}
          {group.groupType === 2 && (
            <View style={[styles.marginBottom, { marginTop: 5 }]}>
              <CustomInputs
                value={group.password}
                placeholder={txt('groups.addGroup.passwordPlaceholder')}
                accessibilityLabel={txt('groups.addGroup.passwordPlaceholder')}
                accessibilityHint={txt('groups.addGroup.passwordHint')}
                onChangeText={(value) => {
                  updateGroupState({
                    password: value,
                  })
                }}
              />
            </View>
          )}
        </View>
        <View style={styles.marginBottom}>
          {/* Hidden group */}
          <View style={styles.row}>
            <RadioButton
              checked={group.groupType === 1 ? true : false}
              onPress={() => updateGroupState({ groupType: 1 })}
              text={txt('groups.settings.hidden')}
            />
          </View>
        </View>
        <View style={styles.marginBottom}>
          <CustomText style={styles.smallText}>{txt('groups.settings.hiddenText')}</CustomText>
        </View>
        <View>
          <RoundSquareBtn title={txt('groups.addGroup.savePlaceholder')} onPress={async () => createNewGroup()} />
        </View>
      </View>
      {keyboardSpacerShow()}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    ...SpacingStyles.widthAndHeight,
  },
  errorMessage: {
    color: Colors.errorBackground,
    ...TypographyStyles.textSmallBold,
    marginBottom: 5,
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  marginBottom: { marginBottom: 10 },
  row: { alignItems: 'center', flexDirection: 'row' },
  settingsContainer: { ...SpacingStyles.marginRightLeft10, marginBottom: 30 },
  smallText: { ...TypographyStyles.textSmallBold, color: Colors.inactive },
  title: { ...TypographyStyles.textBold },
})
