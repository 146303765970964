import React, { useEffect, useRef, useState } from 'react'
import { Animated, FlatList, StyleSheet, View } from 'react-native'
import './List.d.ts'

export const List = (props) => {
	const [headerVissible, setHeaderVissible] = useState(true)
	const [headerHeight, setHeaderHeight] = useState(0)

	const headerYRef = useRef(new Animated.Value(0))
	const animatingRef = useRef(false)
	const scrollOffsetRef = useRef(0)

	useEffect(() => {
		const toValue = headerVissible ? 0 : -headerHeight - 6
		const options = { toValue, duration: 250, useNativeDriver: false }

		animatingRef.current = true
		const animation = Animated.timing(headerYRef.current, options)
		animation.start(({ finished }) => finished && (animatingRef.current = false))

		return () => {
			animatingRef.current = false
			animation.stop()
		}
	}, [headerVissible])

	const scrollHandler = (event) => {
		if (props.onScroll) props.onScroll(event)
		if (!props.AnimatedHeader) return // no header no animation

		const scrollOffsetCurrent = scrollOffsetRef.current
		const scrollOffset = event.nativeEvent.contentOffset.y
		const layoutHeight = event.nativeEvent.layoutMeasurement.height
		const contentHeight = event.nativeEvent.contentSize.height

		if (scrollOffset === scrollOffsetCurrent) return // no changes no animation

		const bounding = scrollOffset < 0 || scrollOffset > contentHeight - layoutHeight
		if (bounding) return // bounce effect no animation

		const scrollingUpward = scrollOffsetCurrent > scrollOffset
		const scrollTopReached = scrollOffset <= headerHeight / 2

		setHeaderVissible(scrollTopReached || scrollingUpward)
		scrollOffsetRef.current = scrollOffset
	}

	const getHeaderHeight = ({ nativeEvent }) => setHeaderHeight(nativeEvent.layout.height)

	return (
		<View style={[styles.container, props.containerStyle]}>
			{props.AnimatedHeader && (
				<Animated.View
					onLayout={getHeaderHeight}
					style={[styles.header, { zIndex: props.refreshing ? 0 : 1, transform: [{ translateY: headerYRef.current }] }]}
				>
					{props.AnimatedHeader()}
				</Animated.View>
			)}

			<FlatList {...props} ListHeaderComponent={<View style={{ height: headerHeight }} />} onScroll={scrollHandler} />
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100%',
	},

	header: {
		top: 0,
		position: 'absolute',
		width: '100%',
	},
})
