import CustomDialog, { ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import React from 'react'
import { ButtonPrimary, ButtonSecondary } from "../../../buttons/StyledButtons"
import Colors from "../../../../constants/Colors"
import { StyleSheet, View } from "react-native"
import { txt } from "../../../../locales/i18n"

export function WeakPostDialog({ visible, submit, dismiss, edit, weakPost, weakText, noImage }) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
        <CustomText font="bold">
          { weakPost ? txt('createPost.weakPostDialog.noImageDescriptionTitle')
            : weakText ? txt('createPost.weakPostDialog.descriptionTitle')
              : noImage && txt('createPost.weakPostDialog.noImageTitle')
          }
        </CustomText>
        <CustomText font="small" style={{ paddingTop: 12 }}>
          { weakPost ? txt('createPost.weakPostDialog.noImageDescriptionText')
            : weakText ? txt('createPost.weakPostDialog.descriptionText')
              : noImage && txt('createPost.weakPostDialog.noImageText')
          }
        </CustomText>
      </ContentContainer>
      <View style={styles.buttonContainer}>
        <ButtonSecondary
          onPress={submit}
          style={styles.secondaryButton}
          title={edit ? txt('createPost.weakPostDialog.save') : txt('createPost.weakPostDialog.create')}
          titleStyle={{ paddingHorizontal: 12, color: Colors.blueAzure }}
        />
        <ButtonPrimary
          onPress={dismiss}
          style={styles.primaryButton}
          title={txt('createPost.weakPostDialog.edit')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    paddingBottom: 24,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingHorizontal: 3,
    justifyContent: 'space-evenly'
  },
  secondaryButton: {
    borderColor: Colors.blueAzure,
    marginRight: 3,
    paddingHorizontal: 6,
    width: 150
  },
  primaryButton: {
    backgroundColor: Colors.blueAzure,
    marginLeft: 3,
    paddingHorizontal: 6,
    width: 150
  }
})