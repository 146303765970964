import React from 'react'
import { Platform, View } from 'react-native'
import { Helmet } from 'react-helmet'

export default class FacebookPixel extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      consentGiven: !!(
        window.CookieInformation && window.CookieInformation.getConsentGivenFor('cookie_cat_marketing') === true
      ),
    }
  }

  componentDidMount() {
    if (Platform.OS === 'web') {
      this.unsubscribeFromCookieInformation = window.addEventListener(
        'CookieInformationConsentGiven',
        (e) => {
          if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
            this.setState({ consentGiven: true })
          } else {
            this.setState({ consentGiven: false })
          }
        },
        false
      )
    }
  }

  componentWillUnmount() {
    // unsubscribe from cookieInfo on unmount
    if (this.unsubscribeFromCookieInformation) this.unsubscribeFromCookieInformation()
  }

  render() {
    if (__DEV__) console.log('consentGiven to Facebook =', this.state.consentGiven)
    if (Platform.OS === 'web' && this.state.consentGiven === true) {
      return (
        <Helmet>
          <script>
            {`
                !function (f, b, e, v, n, t, s) {
                  if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                  }; if (!f._fbq) f._fbq = n;
                  n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
                  t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
                }(window,
                  document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
                fbq('init', '862002367260340'); // BB
                fbq('init', '1927495120844743'); // RK
                fbq('track', 'PageView');
                `}
          </script>
          <noscript>
            {`
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=862002367260340&ev=PageView&noscript=1"
              />
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=1927495120844743&ev=PageView&noscript=1"
              />
            `}
          </noscript>
        </Helmet>
      )
    } else {
      return <View></View>
    }
  }
}
