import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { URLOpener } from '../../../../utils/URLOpener'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'
import Colors from '../../../../constants/Colors'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

export default class Nicolaj1 extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.nicolaj1.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/blog/nicolaj.png' }}
              accessibilityIgnoresInvertColors
            />
            <CustomText font="bold">
              {txt('blog.nicolaj1.author')}
            </CustomText>
          </View>
          <View>
            <CustomText font="italic">
              {txt('blog.nicolaj1.text1')}
            </CustomText>
            <Image
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-nicolaj.jpg' }}
              resizeMode="contain"
              style={styles.imageBig}
              accessibilityIgnoresInvertColors
            />
          </View>

          <View>
            <CustomText font="bold" style={styles.marginBottom}>
              {txt('blog.nicolaj1.headline1')}
            </CustomText>
            <CustomText>{txt('blog.nicolaj1.text2')}</CustomText>
            <CustomText font="bold" style={styles.marginBottom}>
              {txt('blog.nicolaj1.headline2')}
            </CustomText>
            <CustomText>{txt('blog.nicolaj1.text3')}</CustomText>
            <CustomText font="bold" style={styles.marginBottom}>
              {txt('blog.nicolaj1.headline3')}
            </CustomText>
            <CustomText>{txt('blog.nicolaj1.text4')}</CustomText>
            <CustomText font="bold" style={styles.marginBottom}>
              {txt('blog.nicolaj1.headline4')}
            </CustomText>
            <CustomText>{txt('blog.nicolaj1.text5')}</CustomText>
          </View>
          <View style={styles.marginBottom}>
            <CustomPressable
              onPress={() => URLOpener('https://boblberg.dk/blogs/Nicolaj2')}
              style={styles.marginTop}
            >
              <CustomText font="italic">
                {txt('blog.nicolaj1.references1Title')}

                <CustomText style={styles.linkText}>
                  {txt('blog.nicolaj1.references1')}
                </CustomText>
              </CustomText>
            </CustomPressable>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  imageBig: { alignSelf: 'center', height: 300, width: width * 0.9 },
  linkText: { color: Colors.tintColor },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  marginTop: { marginTop: 10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
