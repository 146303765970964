import React, { useEffect, useState } from 'react'
import { CustomText } from '../../text/StyledText'
import { Image, StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import { useSelector } from 'react-redux'
import { getMunicipalityById } from '../../../services/api/Municipalities'
import { SpacingStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import { getUser, getUserImage } from '../../../services/api/User'
import { useRoute } from '@react-navigation/native'
import { isConnected } from '../../../services/api/ConnectionApi'
import { useNavigation } from '@react-navigation/core'

export default function ProfileView() {
  const router = useRoute()
  const navigation = useNavigation()
  const profileId = router.params?.id
  const user = useSelector((state) => state.user)
  const ownProfile = user.id === parseInt(profileId)
  const [ownProfileText, setOwnProfileText] = useState(null)
  const [municipality, setMunicipality] = useState(null)
  const [userInfo, setUserInfo] = useState({})
  const genderList = txt('genders')

  useEffect(() => {
    if (profileId && !ownProfile) getUserProfile().then(setUser).catch(navigation.popToTop)
    else {
      setUser(user)
      setOwnProfileText(txt('profile.userProfile.intro'))
    }
  }, [])

  const getUserProfile = async () => {
    const hasAccess = await isConnected(profileId)
    if (hasAccess) return await getUser(profileId)
  }

  const setUser = async (u) => {
    setUserInfo(u)
    await getProfileImage(u.id)
    getMunicipalityById(parseInt(u.municipality_id)).then(mun => {
      setMunicipality(mun.name)
    })
    setUserInfo((prev) => ({
      ...prev,
      gender: genderList[u.gender_id ?? 0],
    }))
  }

  const getProfileImage = async (userId) => {
    const image = await getUserImage(userId)

    setUserInfo((prev) => ({
      ...prev,
      filename: image?.filename,
      base64: image?.base64,
    }))
  }

  if (!userInfo) return <></>
  else return (
    <View style={styles.container}>
      {ownProfileText && (
        <View style={styles.introContainer}>
          <CustomText font="small" style={styles.intro}>{ownProfileText}</CustomText>
        </View>
      )}
      <View style={styles.contentContainer}>
        <Image
          accessibilityIgnoresInvertColors
          style={styles.image}
          source={userInfo.filename ? { uri: userInfo.base64 } : require('../../../assets/images/bobl.png')}
        />
        <View style={styles.textContainer}>
          <CustomText font="bold" style={styles.name}>{userInfo.username}</CustomText>
          <CustomText font="bold" style={styles.title}>{txt('profile.userProfile.location').toUpperCase()}</CustomText>
          <CustomText style={styles.value}>{municipality + ' ' + txt('profile.userProfile.municipality')}</CustomText>
          <CustomText font="bold" style={styles.title}>{txt('profile.userProfile.gender').toUpperCase()}</CustomText>
          <CustomText style={styles.value}>{userInfo.gender}</CustomText>
          <CustomText font="bold" style={styles.title}>{txt('profile.userProfile.birthYear').toUpperCase()}</CustomText>
          <CustomText style={styles.value}>{userInfo.birth_year}</CustomText>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, ...SpacingStyles.widthAndHeight },
  contentContainer: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    paddingVertical: 12,
    margin: 12,
  },
  image: {
    width: 175,
    height: 175,
    borderRadius: 87,
    borderWidth: 2,
    borderColor: Colors.lightBlue,
    alignSelf: 'center',
  },
  textContainer: { marginVertical: 24, marginHorizontal: 12 },
  name: { textAlign: 'center', fontSize: 24 },
  value: { marginTop: 6, color: Colors.blackTransparent54 },
  title: { color: Colors.tintColor, marginTop: 24 },
  intro: { color: Colors.blackTransparent54, padding: 12 },
  introContainer: { backgroundColor: Colors.white, padding: 6, borderRadius: 10, margin: 12 },
})