import React from 'react'
import MessageNotifications from './MessageNotifications'
import GroupNotifications from './GroupNotifications'
import VideoConversationsNotifications from './VideoConversationsNotifications'
import MainContainer from '../../MainContainer/MainContainer'
import { View } from 'react-native'

export default function Notifications({ type, universe }) {
  const renderNotifications = () => {
    switch (Number(type)) {
      case 0: {
        return <MessageNotifications />
      }
      case 1: {
        return <GroupNotifications />
      }
      case 2: {
        return <VideoConversationsNotifications />
      }
    }
  }

  return (
    <MainContainer backgroundColor={universe?.backgroundColor}>
      <View style={{ marginHorizontal: 16 }}>{renderNotifications()}</View>
    </MainContainer>
  )
}