import React from 'react'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import ProfileView from '../../components/views/Profile/ProfileView'

export default function ProfileViewScreen() {
  return (
    <MainContainer>
      <PageTitle title={TxtTMD.profile.title[TxtTMD.lang]} />
      <ProfileView />
    </MainContainer>
  )
}
