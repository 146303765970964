import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import { View } from 'react-native'
import React from 'react'

export default function SignUpDialog({ navigateToFrontpage, username, visible, toggle }) {
  return (
    <CustomDialog
      style={{ maxWidth: 400 }}
      visible={visible}
      onDismiss={toggle}
    >
      <ContentContainer>
        <CustomText>
          {txt('post.notSignedIn', {name: username})}
        </CustomText>
      </ContentContainer>
      <ActionButton onPress={navigateToFrontpage}>
        {txt('post.signUp')}
      </ActionButton>
    </CustomDialog>
  )
}