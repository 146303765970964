import { Platform } from 'react-native'
import * as ImageManipulator from 'expo-image-manipulator'

export async function scaleImages(rawImages) {
  const images = await Promise.all(
    rawImages.map(async (image) => {
      return await scaleImage(image)
    })
  )
  return images
}

export async function scaleImage(image) {
  const desiredSize = 1000 // 1000px
  const resize = image.width < image.height ? { height: desiredSize } : { width: desiredSize }
  const actions = [{ resize }]

  const options = {
    format: ImageManipulator.SaveFormat.JPEG,
    compress: 0.8,
    base64: true,
  }

  const scaledImage = await ImageManipulator.manipulateAsync(image.uri, actions, options)

  if (Platform.OS === 'web') return scaledImage

  scaledImage.base64 = 'data:image/jpeg;base64,' + scaledImage.base64
  return scaledImage
}
