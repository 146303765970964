import React from 'react'
import Fontsizes from '../../constants/Fontsizes'
import Colors from '../../constants/Colors'
import { StyleSheet, View } from 'react-native'
import { NotificationBadge } from './NotificationBadge'
import NormalIcon, { BoblbergIcon } from './NormalIcon'

export default function TabBarIcon({name, boblbergIcon, badge, ...props}) {
    return (
      <View>
        { boblbergIcon
          ? <BoblbergIcon name={name} size={Fontsizes.xl} color={Colors.text} {...props}/>
          : <NormalIcon name={name} size={Fontsizes.xl} color={Colors.text} {...props}/>
        }
        {!!badge && (
          <NotificationBadge number={badge} style={styles.badge} />
        )}
      </View>
    )
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    right: -8,
    top: -4,
  },
})
