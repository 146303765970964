import React from 'react'
import { View, Image, StyleSheet } from 'react-native'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import Colors from '../../../../constants/Colors'
import { SpacingStyles } from '../../../../styles'
import { URLOpener } from '../../../../utils/URLOpener'

export default class BørneBuddies extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image}
            resizeMode="contain"
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/partners/BørneBuddies.png',
            }}
            accessibilityIgnoresInvertColors
          />
        </View>
        <View style={styles.marginLeftRight}>
          <CustomText>{txt('partners.børnebuddies')}</CustomText>
          <CustomText
            style={styles.text}
            onPress={() => URLOpener('https://www.kp.dk/boernebuddies/')}
          >
            {txt('partners.linkText')}{' '}
            {txt('partners.børnebuddiesName')}
          </CustomText>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: { ...SpacingStyles.widthAndHeight },
  image: { height: 200, width: 300 },
  imageContainer: { alignItems: 'center' },
  marginLeftRight: { marginLeft: 10, marginRight: 10 },
  text: {
    color: Colors.tintColor,
  },
})
