import { apiGet, apiPost, apiPut } from './ApiRequest'

export async function getCourses() {
	const courses = await apiGet('courses')
	return courses ?? []
}

export async function getCourse(id) {
	const course = await apiGet(`courses/${id}`)
	return course ?? {}
}

export async function getUserCourse() {
	const userCourse = await apiGet(`courses/user`)
	return userCourse ?? null
}

export async function getDeclinedCourses() {
	const declinedCourses = await apiGet(`courses/decline`)
	return declinedCourses ?? []
}

export async function declineCourse(courseId, declinedStage) {
	const res = await apiPost( { courseId, declinedStage }, `courses/decline`)
	return res?.ok
}

export async function startCourse(data) {
	let res = await apiPost(data, 'courses/start')
	if (res.ok === false) return {}
	res = await res.json()
	return res ?? {}
}

export async function endCourse(data) {
	let res = await apiPut(data, 'courses/end')
	if (res.ok === false) return {}
	res = await res.json()
	return res ?? {}
}

export async function getUserAnalysis() {
	const analysis = await apiGet('courses/analysis')
	return analysis ?? null
}