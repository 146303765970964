import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Paths from '../../constants/Paths'
import { ShadowStyles } from '../../styles'
import { CustomText } from '../text/StyledText'
import moment from 'moment'
import Logo from '../../assets/images/icon.png'
import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import CustomPressable from "../pressables/CustomPressable"

export default function MapModal({ selectedActivity, navigateToActivity }) {
  moment.locale([txt('videoChat.locale')])

  const formatDate = (dateStart) => {
    if (!dateStart) {
      return [txt('activities.repeatingActivity')]
    } else {
      return moment(dateStart).format('llll')
    }
  }
  return (
    <CustomPressable
      onPress={() => navigateToActivity(selectedActivity.id, selectedActivity.date_start)}
      style={styles.container}
    >
      <View style={styles.topContainer}>
        <View style={styles.imageContainer}>
          {selectedActivity.img_filename ? (
            <Image
              style={styles.image}
              source={{
                uri: Paths.activityUrlBase + selectedActivity.img_filename,
              }}
            />
          ) : (
            <Image style={styles.image} source={Logo} />
          )}
        </View>

        <View style={styles.bodyContainer}>
          <CustomText font="bold">{selectedActivity.title}</CustomText>
          <CustomText font="small" numberOfLines={1}>
            {selectedActivity.body}
          </CustomText>
        </View>
      </View>

      <View style={styles.bottomContainer}>
        <CustomText font="small">{formatDate(selectedActivity.date_start)}</CustomText>
        <CustomText font="small">{' ' + selectedActivity.location}</CustomText>
      </View>
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  bodyContainer: {
    flex: 3,
    justifyContent: 'space-evenly',
    padding: 6,
  },
  bottomContainer: {
    alignItems: 'center',
    borderTopColor: Colors.blackTransparent,
    borderTopWidth: 2,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    paddingLeft: 12,
    paddingRight: 12,
  },
  container: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 12,
    bottom: 12,
    height: 120,
    maxWidth: 400,
    position: 'absolute',
    width: '90%',
    ...ShadowStyles.dropShadow,
  },
  image: { borderRadius: 35, borderWidth: 1, height: 60, width: 60 },
  imageContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 6,
  },
  topContainer: { flex: 3, flexDirection: 'row' },
})
