import { Keyboard, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import React, { useState } from 'react'
import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import NormalIcon, { BoblbergIcon } from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import Colors from '../../../constants/Colors'
import { getHexColor } from '../../../utils/getHexColor'
import { whoLiked } from '../../../services/api/Groups'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import CustomPressable from "../../pressables/CustomPressable"

export default function Likes({ hasLiked, likes, groupId, msgId, toggleLike }) {
  const [showLikedList, setShowLikedList] = useState(false)
  const [listOfLikes, setListOfLikes] = useState([])

  async function toggleModal() {
    Keyboard.dismiss()
    setShowLikedList((prev) => !prev)
    const whoHasLiked = await whoLiked(groupId, msgId)
    setListOfLikes(whoHasLiked)
  }

  function renderAllLikes() {
    return (
      <CustomDialog style={styles.customDialogStyles} visible={showLikedList} onDismiss={toggleModal}>
        <CustomText style={{ paddingTop: 24, paddingHorizontal: 24 }} font={'largeBold'}>
          {txt('groups.likes.header')}
        </CustomText>
        <ContentContainer>
          {listOfLikes.map((user) => (
            <TouchableWithoutFeedback key={user.id?.toString()}>
              <View style={styles.listOfHasLikedStyles}>
                <View style={[styles.avatar, { backgroundColor: getHexColor(user.id) }]}>
                  <NormalIcon
                    accessibilityLabel={txt('groups.likes.liked')}
                    name="user"
                    size={Fontsizes.s}
                    color={Colors.white}
                    stroke="fas"
                  />
                </View>
                <CustomText font="small">{user.username}</CustomText>
              </View>
            </TouchableWithoutFeedback>
          ))}
        </ContentContainer>
        <View style={{ paddingBottom: 16 }}>
          <ButtonPrimary
            onPress={toggleModal}
            style={{ backgroundColor: Colors.blueAzure }}
            title={txt('groups.likes.closeModal')}
            titleStyle={{ paddingHorizontal: 12 }}
          />
        </View>
      </CustomDialog>
    )
  }

  return (
    <>
      <View style={styles.likeContainer}>
        <CustomPressable
          onPress={toggleLike}
          accessibilityRole="button"
          accessibilityLabel={
            hasLiked ? txt('groups.likes.accessibilityLabelUnlike') : txt('groups.likes.accessibilityLabelLike')
          }
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <BoblbergIcon
            name="bobl"
            stroke={hasLiked ? "fas" : "fal"}
            size={Fontsizes.m}
            color={Colors.tintColor}
          />
          <CustomText
            font={hasLiked ? 'smallBold' : 'small'}
            style={{ color: hasLiked ? Colors.tintColor : Colors.text, marginLeft: 6 }}
          >
            {`${txt('groups.likes.like')}`}
          </CustomText>
        </CustomPressable>
        {likes > 0 && (
          <CustomPressable
            onPress={toggleModal}
            accessibilityRole="button"
            accessibilityLabel={txt('groups.likes.whoLiked')}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'flex-end',
            }}
          >
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              borderRadius: 10,
              marginRight: 12,
            }}>
              <BoblbergIcon
                name="bobl"
                stroke="fas"
                size={Fontsizes.s}
                color={Colors.tintColor}
              />
              <CustomText font={'small'} style={styles.textStyles}>{`${likes}`}</CustomText>
            </View>
          </CustomPressable>
        )}
      </View>

      {renderAllLikes()}
    </>
  )
}

const styles = StyleSheet.create({
  customDialogStyles: {
    flexDirection: 'column',
    maxHeight: '60%',
    justifyContent: 'space-between',
    width: '80%',
  },
  likeContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 12,
  },
  listOfHasLikedStyles: {
    flexDirection: 'row',
    marginVertical: 4,
    alignItems: 'center'
  },
  textStyles: {
    marginLeft: 6,
  },
  avatar: {
    borderRadius: 100,
    marginRight: 6,
    opacity: 0.4,
    padding: 4,
  },
})
