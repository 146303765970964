import React, { useEffect } from 'react'
import { View, Platform, StyleSheet, Image } from 'react-native'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import SignupField from '../../components/views/Auth/SignupField'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import { TypographyStyles, SpacingStyles } from '../../styles'
import MainContainer from '../../components/MainContainer/MainContainer'
import { updateUserPreferences } from '../../services/api/User'

export default function SignupChristmasScreen(props) {
  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={require('../../assets/images/christmas/julevenner_logo.png')}
          accessibilityRole="image"
          accessibilityLabel={txt('christmasFriends.logoLabel')}
          accessibilityIgnoresInvertColors
        />
      ),
    })
  })

  const keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  async function handleSignupSuccess() {
    await updateUserPreferences({ default_universe: 11 }) // christmas friends
  }

  return (
    <MainContainer>
      <View style={styles.widthHeight}>
        <View style={styles.textContainer}>
          <CustomText style={styles.textBold}>
            {txt('christmasFriends.signup.title')}
          </CustomText>
          <CustomText style={styles.text}>
            {txt('christmasFriends.signup.subtitle')}
          </CustomText>
        </View>

        <SignupField
          navigation={props.navigation}
          originScreen={'ChristmasTypeSelection'}
          originStack={'ChristmasTypeSelection'}
          originRootStack={'christmasFriends'}
          onSignupSuccess={handleSignupSuccess}
          route={props.route}
        />
      </View>
      {keyboardSpacerShow()}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  logo: { height: 40, width: 100 },
  text: { ...TypographyStyles.text, marginBottom: 15, textAlign: 'center' },
  textBold: { ...TypographyStyles.textBold, textAlign: 'center' },
  textContainer: { marginLeft: 10, marginRight: 10 },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
})
