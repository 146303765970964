import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import React from 'react'

export default function VisibilityInfo({ visible, ok }) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
        <CustomText font="bold">{txt('post.edit.visibilityDialogTitle')}</CustomText>
        <CustomText style={{ paddingTop: 12 }}>{txt('post.edit.visibilityDialogText')}</CustomText>
      </ContentContainer>
      <ActionButton onPress={ok}>
        {txt('dialog.ok')}
      </ActionButton>
    </CustomDialog>
  )
}