import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import NormalIcon from '../../icons/NormalIcon'
import { txt } from '../../../locales/i18n'
import Fontsizes from '../../../constants/Fontsizes'
import { CustomText } from '../../text/StyledText'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import { getJuniorProfileList } from '../../../services/api/LearnDanish'
import CustomPressable from "../../pressables/CustomPressable"

export default function JuniorList({ email, selectedJunior, setSelectedJunior }) {
  const [profiles, setProfiles] = useState([])
  const [isProfilesAvailable, setIsProfilesAvailable] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getProfiles()
  }, [email])

  async function getProfiles() {
    setLoading(true)
    const _profiles = await getJuniorProfileList(email)
    setProfiles(_profiles)
    setIsProfilesAvailable(_profiles.length !== 0)
    setLoading(false)
  }

  function JuniorProfile({profile}) {
    return (
      <CustomPressable
        onPress={() => setSelectedJunior(profile.id)}
        style={[styles.juniorItem, { borderColor: profile.id === selectedJunior ? Colors.tintColor : Colors.darkerSkyBlue }]}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={[styles.avatar, { backgroundColor: profile.id === selectedJunior ? Colors.tintColor : Colors.darkerSkyBlue }]}>
            <NormalIcon
              accessibilityLabel={txt('fullPost.usernameIcon')}
              name="user"
              size={Fontsizes.s}
              color={Colors.white}
              stroke="fas"
            />
          </View>
          <CustomText font={profile.id === selectedJunior ? 'smallBold' : 'small'}>{profile.name}</CustomText>
        </View>
        <NormalIcon
          name={profile.id === selectedJunior ? "check-circle" : "circle"}
          size={Fontsizes.xl}
          color={profile.id === selectedJunior ? Colors.tintColor : Colors.darkerSkyBlue}
          stroke="fas"
        />
      </CustomPressable>
    )
  }

  if (loading && email.length > 0)
    return <LoadingSpinner />

  return (
    <View style={{marginTop: 8}}>
      {!!email && (
        <>
          <CustomText font={'smallBold'}>{txt('learnDanish.junior.juniorProfiles')}</CustomText>
          {!isProfilesAvailable && (
            <InvalidInputNoticeCentered>
              {txt('learnDanish.junior.noJuniorProfiles')}
            </InvalidInputNoticeCentered>
          )}
          {profiles.map((profile) => (
            <JuniorProfile key={profile.id.toString()} profile={profile} />
          ))}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  avatar: {
    borderRadius: 100,
    marginRight: 8,
    padding: 6,
  },
  juniorItem: {
    flexDirection: 'row',
    borderRadius: 8,
    borderWidth: 2,
    marginTop: 8,
    padding: 6,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})