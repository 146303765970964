import React from 'react'
import { View, Text, Image, Dimensions, StyleSheet } from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import PageTitle from '../../../tags/PageTitle'
import Layout from '../../../../constants/Layout'
import { ProjectStyles } from './ProjectStyles'
const { width } = Dimensions.get('window')
export default class SBB extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={ProjectStyles.widthHeight}>
        <PageTitle title={TxtProjects.sbb.title[TxtProjects.lang]} />

        <View style={ProjectStyles.container}>
          <Text
            style={ProjectStyles.blogTitle}
            accessibilityRole="header"
            aria-level={1}
          >
            {TxtProjects.sbb.title[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageEU}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/eu_logo_OPI_FAKTO.png',
            }}
          />

          <Text style={ProjectStyles.blogText}>
            {TxtProjects.sbb.text1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.sbb.text2[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.sbb.text3[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.sbb.involved[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.sbb.partner1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.sbb.partner2[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.sbb.partner3[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.sbb.partner4[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partnersLast}>
            {TxtProjects.sbb.partner5[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.sbb.projectPeriod[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.sbb.period[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.sbb.supporter}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.sbb.supporterText[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageSD}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Syddansk_Vaekstforum_logo.png',
            }}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  imageEU: {
    height: Layout.isSmallDevice ? width * 0.26 : 85,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.75 : 240,
  },
  imageSD: {
    height: Layout.isSmallDevice ? width * 0.3 : 150,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.51 : 250,
  },
})
