import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'

// components
import { CustomText } from '../../text/StyledText'
import CustomPressable from '../../pressables/CustomPressable'
import { FormInput } from '../../text/StyledTextinputs'
import { ButtonPrimary } from '../../buttons/StyledButtons'

import NormalIcon from '../../icons/NormalIcon'

// services
import * as UserService from '../../../services/api/User'
import * as UniverseService from '../../../services/api/Universe'

// styles
import Fontsizes from '../../../constants/Fontsizes'
import { borderRadiusM } from '../../../styles/corners'
import { ShadowStyles } from '../../../styles'
import Colors from '../../../constants/Colors'

// utils
import { getUniverseId } from '../../../universe/universeController'
import { txt } from '../../../locales/i18n'

import * as Localization from '../../../services/localization'
import { URLOpener } from '../../../utils/URLOpener'

export const LoginForm = () => {
	const [loading, setLoading] = useState(false)
	const [formInputs, setFormInputs] = useState({ email: '', password: '' })
	const [passwordHidden, setPasswordHidden] = useState(true)
	const [loginError, setLoginError] = useState(null)

	const navigation = useNavigation()
	const universes = useSelector((state) => state?.user?.universes ?? [])
	const universe = useSelector((state) => state.origin?.universe ?? null)

	useEffect(() => {
		setLoginError(null)
	}, [formInputs])

	const loginHandler = async () => {
		setLoading(true)
		const loginSuccessful = await UserService.login(formInputs.email, formInputs.password)

		if (loginSuccessful === false) {
			const message = txt('loginField.loginFailed')
			setLoginError(message)
			return setLoading(false)
		}

		const universeJoinable = universe && universes.every(({ name }) => name !== universe)

		if (universeJoinable) {
			const universeId = getUniverseId(universe)
			if (universeId) await UniverseService.joinUniverseById(universeId)
		}

		setLoading(false)
	}

	return (
		<View style={styles.container}>
			<View style={styles.cardContainer}>
				<FormInput
					autoComplete="email"
					accessibilityLabel={txt('loginField.emailPlaceHolder')}
					accessibilityHint={txt('loginField.emailHint')}
					placeholder={txt('loginField.emailPlaceHolder')}
					autoCapitalize="none"
					onChangeText={(email) => setFormInputs((formInputs) => ({ ...formInputs, email }))}
				/>

				<FormInput
					accessibilityLabel={txt('loginField.passwordPlaceHolder')}
					accessibilityHint={txt('loginField.passwordHint')}
					placeholder={txt('loginField.passwordPlaceHolder')}
					secureTextEntry={passwordHidden}
					onChangeText={(password) => setFormInputs((formInputs) => ({ ...formInputs, password }))}
					onSubmitEditing={loginHandler}
				>
					<CustomPressable
						hitSlop={40}
						accessibilityRole="button"
						accessibilityLiveRegion="polite"
						accessibilityLabel={passwordHidden ? txt('loginField.passwordHide') : txt('loginField.passwordShow')}
						accessibilityHint={txt('loginField.passwordHint')}
						onPress={() => setPasswordHidden((passwordHidden) => !passwordHidden)}
					>
						<NormalIcon
							name={passwordHidden ? "eye-slash" : "eye"}
							size={Fontsizes.l}
							color={Colors.text}
							stroke="fas"
						/>
					</CustomPressable>
				</FormInput>

				{loginError && (
					<CustomText font="smallBold" style={styles.errorText}>
						{loginError}
					</CustomText>
				)}

				<ButtonPrimary
					title={txt('loginField.loginButtonText')}
					onPress={loginHandler}
					loading={loading}
					style={styles.loginButton}
				/>
			</View>

			<View style={{ gap: 6 }}>
				<CustomText font="smallBold" style={styles.password}>
					{txt('passwordField.forgotPassword')}
				</CustomText>

				<CustomPressable onPress={() => navigation.navigate('ResetPassword')}>
					<CustomText font="smallBold" style={styles.passwordLink}>
						{txt('passwordField.resetPassword')}
					</CustomText>
				</CustomPressable>
			</View>

			<View style={styles.signupContainer}>
				<View style={styles.signupTextContainer}>
					<View style={styles.signupDivider} />

					<CustomText font="smallBold" style={{ color: Colors.inactive }}>
						{txt('loginField.notAUser')}
					</CustomText>

					<View style={styles.signupDivider} />
				</View>

				<ButtonPrimary
					style={{ minWidth: 160, alignSelf: 'center' }}
					title={txt('loginField.signup')}
					onPress={() => URLOpener('https://boblberg.' + Localization.getRegion())}
				/>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		maxWidth: 360,
		width: '100%',
		gap: 32,
	},

	loginButton: {
		width: '100%',
	},

	cardContainer: {
		padding: 24,
		width: '100%',
		gap: 24,
		alignSelf: 'center',
		backgroundColor: Colors.white,
		...borderRadiusM,
		...ShadowStyles.shadowInputs,
	},

	password: {
		color: Colors.inactive,
		textAlign: 'center',
	},

	passwordLink: {
		color: Colors.inactive,
		textAlign: 'center',
		textDecorationLine: 'underline',
	},

	signupContainer: {
		gap: 24,
	},

	signupDivider: {
		height: 1,
		width: '20%',
		backgroundColor: Colors.inactive,
		opacity: 0.4,
	},

	signupTextContainer: {
		gap: 12,
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},

	errorText: {
		textAlign: 'center',
		color: Colors.redLight,
	},
})
