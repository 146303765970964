/*global L*/
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import MapModal from './MapModal'
import { borderRadiusS } from '../../styles/corners'
import { useNavigation } from '@react-navigation/core'

export default function ActivitiesMap({ pins, navigateToActivity, selectedActivity, setSelectedActivity }) {
  const initialRegion = { lat: 56.2386833, lng: 10.2219608, zoom: 8 }
  const mapRef = useRef(null)
  const markersRef = useRef(null)
  const navigation = useNavigation()
  const [render, setRender] = useState(true)

  const handleFocus = useCallback(() => setRender(true), [])
  const handleBlur = useCallback(() => {
    setRender(false)
    mapRef.current = null
  }, [])

  useEffect(renderMap, [render])
  useEffect(renderMarkers, [pins])

  useEffect(() => {
    navigation.addListener('focus', handleFocus)
    navigation.addListener('blur', handleBlur)

    return () => {
      navigation.removeListener('focus', handleFocus)
      navigation.removeListener('blur', handleBlur)
    }
  }, [])

  function renderMap() {
    if (render === false) return
    if (mapRef.current !== null) return

    let map
    try {
      map = new L.map('map', { center: [initialRegion.lat, initialRegion.lng], zoom: initialRegion.zoom })
    } catch (error) {
      return window.location.reload()
      /*TODO:
        Reload to prevent the error "Map container is already initialized", 
        when jumping between municipalities map and universes map.
        We should probably find a better solution at some point. 
      */
    }
    map.on('click', () => setSelectedActivity(null))

    const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution }).addTo(map)

    function iconCreateFunction(cluster) {
      const html = '<div><span><b>' + cluster.getChildCount() + '</b></span></div>'
      const iconSize = new L.Point(40, 40)
      return new L.DivIcon({ html, iconSize, className: 'marker-cluster' })
    }

    const markers = L.markerClusterGroup({ iconCreateFunction })
    mapRef.current = map
    markersRef.current = markers
  }

  function renderMarkers() {
    const map = mapRef.current
    const markers = markersRef.current
    if (map === null || markers === null) return

    markers.clearLayers()
    const bounds = []

    for (const pin of pins) {
      if (pin.lat === null || pin.lng === null) continue

      const marker = new L.marker([pin.lat, pin.lng])
      marker.on('click', () => setSelectedActivity(pin))
      marker.addTo(markers)
      bounds.push([pin.lat, pin.lng])
    }

    markers.addTo(map)

    if (bounds.length === 0) return
    window.dispatchEvent(new Event('resize'))
    setTimeout(() => map.fitBounds(bounds, { padding: [24, 24] }), 0)
  }

  if (render === false) return null

  return (
    <>
      <View nativeID="map" style={styles.map} />
      {selectedActivity && <MapModal selectedActivity={selectedActivity} navigateToActivity={navigateToActivity} />}
    </>
  )
}

const styles = StyleSheet.create({
  map: {
    ...borderRadiusS,
    height: '100%',
    width: '100%',
  },
})
