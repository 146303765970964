import React from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import { CustomText } from '../text/StyledText'
import NormalIcon from '../icons/NormalIcon'
import Layout from '../../constants/Layout'
import CustomPressable from "../pressables/CustomPressable"

export default function CategorySelectList({ categories, selectedCategories, selectCategory }) {
  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {categories.map(({ value, name }, index) => (
        <CustomPressable
          key={index.toString()}
          onPress={() => selectCategory(value)}
          style={[styles.category, selectedCategories.includes(value) ? styles.categorySelected : {}]}
        >
          {!Layout.isSmallDevice && selectedCategories.includes(value) && (
            <NormalIcon
              name="check"
              size={14}
              color={Colors.blue}
              style={{ paddingRight: 6 }}
            />
          )}
          <CustomText
            font="small"
            style={{ color: selectedCategories.includes(value) ? Colors.blue : Colors.textLight }}
          >
            {name}
          </CustomText>
        </CustomPressable>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  category: {
    marginTop: 3,
    marginRight: 3,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderColor: Colors.greyLight,
    borderWidth: 2,
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  categorySelected: {
    borderWidth: 2,
    borderColor: Colors.blue,
    backgroundColor: Colors.bluePale,
  },
})