import { apiGet } from './ApiRequest'

export const getAddress = async (search, postalCode) => {
  const url = `addresses?search=${search}&postalCode=${postalCode}`
  const address = await apiGet(url)
  return address ?? null
}

export const getSuggestions = async (search, count = 4) => {
  const url = `addresses/suggestions?search=${search}&count=${count}`
  const suggestions = await apiGet(url)
  return suggestions ?? []
}

export const getCityFromPostalCode = async (postalCode) => {
  const url = 'https://services.datafordeler.dk/DAR/DAR/1/REST/postnummer?Postnr=' + postalCode
  const response = await fetch(url)

  if (response.ok === false) {
    return null
  }

  const [city] = await response.json()
  return city?.navn ?? null
}
