import React, { useEffect, useState } from 'react'
import { getChristmasPost } from '../../../services/api/ChristmasFriends'
import PostChristmas from '../../components/PostChristmas'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getConversationId } from '../../../services/api/Inbox'
import { useSelector } from 'react-redux'
import PageTitle from '../../../components/tags/PageTitle'

export default function PostChristmasScreen(props) {
  const [post, setPost] = useState(null)
  const ownPost = useSelector((state) => state.christmas.post)

  useEffect(() => {
    getChristmasPost(props.route.params.id).then(setPost)
  }, [props.route.params.id])

  async function handleSend() {
    const params = {
      receiverId: post.userId,
      christmasPostIdInitiator: ownPost.id,
      christmasPostIdReceiver: post.id,
    }
    const conversationId = await getConversationId(params)
    if (conversationId) {
      props.navigation.navigate('ChristmasConversationStack', {
        screen: 'Conversation',
        initial: false,
        params: { id: conversationId },
      })
    } else {
      props.navigation.navigate('ChristmasConversationStack', {
        screen: 'Conversation',
        initial: false,
        params: { id: 'new', ...params },
      })
    }
  }

  function handleEdit() {
    props.navigation.navigate('MyPostStack', {
      screen: 'ChristmasPostEdit',
      params: { id: post.id, type: post.type },
    })
  }

  return (
    <MainContainer>
      <PageTitle title={post?.title} />
      <PostChristmas
        post={post}
        onSendMessage={handleSend}
        editPost={handleEdit}
      />
    </MainContainer>
  )
}
