import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, ScrollView } from 'react-native'
import Layout from '../../constants/Layout'
import { txt } from '../../locales/i18n'
import { ButtonPrimary } from '../buttons/StyledButtons'
import NormalIcon from '../icons/NormalIcon'
import CustomDialog from '../../components/dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import Colors from '../../constants/Colors'
import { getCategories } from '../../services/api/Activities'
import { ShadowStyles, TypographyStyles } from '../../styles'
import { useRoute } from '@react-navigation/native'
import CustomPressable from "../pressables/CustomPressable"

export default function CategoryDialog(props) {
  const [showCategories, setShowCategories] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [categoryList, setCategoryList] = useState([])  
  const params = useRoute()

  useEffect(() => {
    const universe = params.params?.universe
    getCategories(universe).then(setCategoryList)
  }, [])

  useEffect(() => {
    setSelectedCategories(props.selectedCategories)
  }, [props.selectedCategories])

  const toggleCategories = () => {
    setShowCategories((prevState) => !prevState)
  }

  const addOrRemoveCategory = (categoryId) => {
    const category = selectedCategories.find((id) => id === categoryId)
    if (category !== undefined) {
      const categoriesLeft = selectedCategories.filter((id) => {
        return id !== categoryId
      })
      setSelectedCategories(categoriesLeft)
    } else {
      setSelectedCategories((prev) => [...prev, categoryId])
    }
  }

  const submitCategories = () => {
    props.setCategories(selectedCategories)
    setShowCategories(false)
  }

  const getSelectedStyle = (categoryId) => {
    const category = selectedCategories.find((id) => id === categoryId)
    if (category !== undefined) {
      return { opacity: 1 }
    }
  }

  const renderInputButton = () => {
    if (props.normalInput) {
      return (
        <CustomPressable
          onPress={toggleCategories}
          accessibilityRole="button"
          style={styles.input}
        >
          {selectedCategories.length > 0 ? (
            <CustomText>{`${selectedCategories.length} ${txt(
              'activities.categories'
            )}`}</CustomText>
          ) : (
            <CustomText>{txt('activities.categories')}</CustomText>
          )}
        </CustomPressable>
      )
    }
    return (
      <CustomPressable
        onPress={toggleCategories}
        accessibilityRole="button"
        style={styles.categoryButton}
      >
        <NormalIcon
          style={{ marginRight: 6 }}
          name="list"
          size={15}
          color={Colors.text}
          badge={selectedCategories.length}
        />
        <CustomText>{txt('activities.categories')}</CustomText>
      </CustomPressable>
    )
  }

  return (
    <View>
      {renderInputButton()}
      <CustomDialog visible={showCategories} onDismiss={toggleCategories} style={styles.dialog}>
        <CustomText font="largeBold" style={{ marginTop: 24, textAlign: 'center' }}>
          {txt('activities.categories')}
        </CustomText>
        <CustomText style={{ marginTop: 6, textAlign: 'center' }}>{txt('activities.selectCategories')}</CustomText>

        <View style={styles.resetButton}>
          <CustomPressable onPress={() => setSelectedCategories([])}>
            <CustomText font="small" style={{ color: Colors.tintColor }}>
              {txt('activities.reset')}
            </CustomText>
          </CustomPressable>
        </View>
        <ScrollView style={{ maxHeight: 300 }}>
          <View style={styles.categoryContainer}>
            {categoryList.map((item) => (
              <CustomPressable key={item.id} onPress={() => addOrRemoveCategory(item.id)} style={styles.category}>
                <Image accessibilityIgnoresInvertColors source={{ uri: item.image }}
                       style={[styles.image, getSelectedStyle(item.id)]} />
                <CustomText numberOfLines={2} font={'xsmall'} style={{ marginTop: 6, textAlign: 'center' }}>
                  {item.name}
                </CustomText>
              </CustomPressable>
            ))}
          </View>
        </ScrollView>
        <ButtonPrimary
          onPress={submitCategories}
          style={{ backgroundColor: Colors.blueAzure }}
          title={selectedCategories?.length ? txt('activities.select') : txt('signupField.close')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  category: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
    width: Layout.isSmallDevice ? 74 : 140,
  },
  categoryButton: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
  categoryContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 24,
    paddingHorizontal: 12,
  },
  dialog: {
    maxWidth: Layout.isSmallDevice ? 400 : 600,
    paddingBottom: 24,
  },
  image: {
    borderRadius: 35,
    height: 50,
    opacity: 0.25,
    width: 50,
  },
  input: {
    borderRadius: 10,
    height: 40,
    justifyContent: 'center',
    paddingLeft: 12,
    ...TypographyStyles.text,
    backgroundColor: Colors.white,
    borderColor: Colors.transparent,
    color: Colors.text,
    ...ShadowStyles.shadowInputs,
  },
  resetButton: {
    left: 18,
    position: 'absolute',
    top: 18,
    zIndex: 10,
  },
})
