import { View, StyleSheet, Image } from 'react-native'
import { ButtonPrimary } from '../buttons/StyledButtons'
import React from 'react'
import { txt } from '../../locales/i18n'
import { CustomText } from '../text/StyledText'
import Colors from '../../constants/Colors'
import CustomPressable from "../pressables/CustomPressable"

export function SignInCardCall(props) {
  return (
    <CustomPressable style={styles.card} onPress={props.onPress}>
      <Image
        source={{ uri: 'https://filer.boblberg.dk/Assets/Img/frontpage/create-user.png' }}
        resizeMode="cover"
        style={styles.cardImage}
        accessibilityIgnoresInvertColors
      />
      <View style={styles.textContainer}>
        <CustomText font="bold" style={styles.cardTitle}>
          {txt('frontPage.card1Title')}
        </CustomText>
        <CustomText style={styles.cardText}>
          {txt('frontPage.card1Text')}
        </CustomText>
        <ButtonPrimary
          title={txt('signupField.signupBtn')}
          font="smallBold"
          onPress={props.onPress}
          style={{ marginTop: 24 }}
        />
      </View>
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 12,
    padding: 12,
    justifyContent: 'space-around',
  },
  cardImage: { alignSelf: 'center', height: 180, width: 180,},
  cardText: {
    textAlign: 'center',
  },
  cardTitle: {
    marginBottom: 24,
    textAlign: 'center',
  },
  textContainer: {
    position: 'relative',
    flex: 1,
    minWidth: 250,
    justifyContent: 'center',
    minHeight: 220,
  },
})
