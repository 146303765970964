import React from 'react'
import { Platform } from 'react-native'
import { Helmet } from 'react-helmet'

export default class PageTitle extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    if (Platform.OS === 'web') {
      return (
        <Helmet>
          <title>{this.props.title ?? ''}</title>
        </Helmet>
      )
    } else {
      return <></>
    }
  }
}
