import * as WebBrowser from 'expo-web-browser'
import { Platform } from 'react-native'

export async function URLOpener(url) {
  if (Platform.OS === 'web') {
    window.open(url, '_blank')
  } else {
    url = url.replace('å', '%c3%a5').replace('æ', '%c3%a6').replace('ø', '%c3%b8')
    return WebBrowser.openBrowserAsync(url, {
      toolbarColor: '#dceef3',
      enableBarCollapsing: true,
    })
  }
}

export function formatURL(url) {
  if (url.startsWith('http') || url === '') return url
  else return 'http://' + url
}
