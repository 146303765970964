import React, { useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { ModalSelectorCustomVideoChat } from '../../../modalSelectors/ModalSelector'
import { CustomText } from '../../../text/StyledText'
import Colors from "../../../../constants/Colors"
import ModalSelector from "react-native-modal-selector"
import Layout from "../../../../constants/Layout"

export default function SnakSammenDurationSelector({ duration, handleDurationChange }) {
  const durations = useRef([15, 30, 45, 60])

  function renderSelector() {
    return (
      <ModalSelectorCustomVideoChat
        data={durations.current}
        keyExtractor={(data) => data}
        labelExtractor={(data) => data}
        initValue={duration.toString()}
        onChange={handleDurationChange}
      />
    )
  }

  return (
    <View>
      <CustomText font="gotham-bold" accessibilityRole="header" aria-level={3}>
        {txt('videoChat.volunteer.create.duration')}
      </CustomText>
      <View style={styles.pickDateContainer}>{renderSelector()}</View>
    </View>
  )
}

export function SnakSammenDuration({ duration, handleDurationChange }) {
  const durationsRef = useRef([
    { value: 15, label: txt('videoChat.volunteer.create.duration15') },
    { value: 30, label: txt('videoChat.volunteer.create.duration30') },
    { value: 45, label: txt('videoChat.volunteer.create.duration45') },
    { value: 60, label: txt('videoChat.volunteer.create.duration60') },
  ])

  return (
    <View>
      <CustomText font="gotham-bold-small" style={{ marginTop: 12, marginBottom: 12 }}>
        {txt('videoChat.volunteer.create.duration')}
      </CustomText>

        <ModalSelector
          data={durationsRef.current}
          keyExtractor={(data) => data.value}
          labelExtractor={(data) => data.label}
          initValue={duration.toString()}
          onChange={handleDurationChange}

          backdropPressToClose={true}
          animationType="fade"
          cancelText={txt('videoChat.volunteer.create.close')}
          style={[styles.modalCustom, { paddingVertical: 2 }]}
          initValueTextStyle={styles.textSmall}
          selectStyle={{ borderColor: Colors.transparent }}
          selectTextStyle={styles.textSmall}
          optionTextStyle={[styles.textSmall, { textAlign: 'center' }]}
          optionContainerStyle={styles.optionContainer}
          cancelStyle={styles.optionContainer}
          cancelTextStyle={[styles.textSmall, { textAlign: 'center' }]}
        />
    </View>
  )
}

const styles = StyleSheet.create({
  pickDateContainer: {
    borderRadius: 10,
    height: 40,
    minWidth: '100%',
    marginBottom: 10,
    marginTop: 5,
    justifyContent: 'flex-start',
  },


  modalCustom: {
    backgroundColor: Colors.white,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: Colors.redCrossGrey,
  },
  optionContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white,
    maxHeight: 500,
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
    alignSelf: 'center',
  },
  textSmall: {
    fontFamily: 'gotham-book',
    fontSize: 15,
    color: Colors.text,
    textAlign: 'left',
  },
})
