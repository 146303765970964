import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { txt } from '../../locales/i18n'
import { CustomText } from '../text/StyledText'
import CustomDialog, { ActionButton, ContentContainer, ScrollableDialog } from './CustomDialog'

import { getScreeningConsent, updateScreeningConsent } from '../../services/api/Inbox'
import Colors from '../../constants/Colors'

export default function ScreeningConsentDialog({ visible, handleHideDialog }) {
  const [consentGiven, setConsentGiven] = useState(false)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (visible == true) {
      getCurrentConsent()
    }
  }, [visible])

  const handleScreeningConsent = async () => {
    const res = await updateScreeningConsent(consentGiven)
    if (res) {
      const consentRemoved = !!res.data
      if (!consentRemoved) setMessage(txt('conversations.consentRemoved'))
      else setMessage(txt('conversations.consentGiven'))
      setTimeout(() => {
        handleHideDialog()
        setMessage(null)
        setConsentGiven(consentRemoved)
      }, 1500)
    }
  }

  const getCurrentConsent = async () => {
    const consent = await getScreeningConsent()
    setConsentGiven(!!consent)
  }

  if (consentGiven) {
    return (
      <CustomDialog visible={visible}>
        {message ? (
          <ContentContainer>
            <CustomText font="bold" style={{ color: Colors.red }}>{message}</CustomText>
          </ContentContainer>
        ) : (
          <>
            <ContentContainer>
              <View>
                <CustomText font="bold">
                  {txt('conversations.removeScreeningConsent')}
                </CustomText>
                <CustomText style={styles.marginTop}>{txt('conversations.removeConsentBody1')}<CustomText
                  style={{ color: Colors.red }}>{txt('conversations.removeConsentBody2')}</CustomText>
                  {txt('conversations.removeConsentBody3')}
                </CustomText>
              </View>
            </ContentContainer>
            <ActionButton onPress={handleScreeningConsent} style={styles.dialogButton} danger>
              {txt('conversations.removeScreeningConsent')}
            </ActionButton>
            <ActionButton onPress={handleHideDialog} style={styles.dialogButton}>
              {txt('conversations.screeningCancel')}
            </ActionButton>
          </>
        )}
      </CustomDialog>
    )
  } else {
    return (
      <ScrollableDialog visible={visible}>
        {message ? (
          <ContentContainer>
            <CustomText font="bold" style={{ color: Colors.green }}>{message}</CustomText>
          </ContentContainer>
        ) : (
          <>
            <ContentContainer>
              <CustomText font="bold">
                {txt('conversations.screeningHeader')}
              </CustomText>
              <CustomText style={styles.marginTop}>{txt('conversations.screeningBody')}</CustomText>
            </ContentContainer>
            <ActionButton onPress={handleScreeningConsent} style={styles.dialogButton} success>
              {txt('conversations.screeningConsent')}
            </ActionButton>
            <ActionButton onPress={handleHideDialog} style={styles.dialogButton}>
              {txt('conversations.screeningCancel')}
            </ActionButton>
          </>
        )}
      </ScrollableDialog>
    )
  }
}

const styles = StyleSheet.create({
  dialogButton: { padding: 10 },
  marginTop: { marginTop: 10 },
})
