import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import { CustomText } from '../text/StyledText'
import { ActionButton, ContentContainer, ScrollableDialog } from './CustomDialog'

import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'

import { getJuniorConsent, updateJuniorConsent } from '../../services/api/LearnDanish'

export function SproglandJuniorConsentDialog({ visible, setVisible, onCancel, acceptOnly }) {
  const [consentGiven, setConsentGiven] = useState(null)
  const [notification, setNotification] = useState(null)
  const [showUpdateErrorDialog, setShowUpdateErrorDialog] = useState(false)

  useEffect(() => {
    if (visible === false) return

    if (acceptOnly) {
      return setConsentGiven(false)
    }

    getJuniorConsent().then((consentGiven) => {
      setConsentGiven(consentGiven)
    })
  }, [visible])

  async function handleUpdateConsent() {
    try {
      if (acceptOnly) {
        await updateJuniorConsent(true)
        setNotification(txt('learnDanish.junior.consentGiven'))
      } else { 
        await updateJuniorConsent(!consentGiven)
        setNotification(consentGiven ? txt('learnDanish.junior.consentRemove') : txt('learnDanish.junior.consentGiven'))
      }
    } catch (error) {
      return setShowUpdateErrorDialog(true)
    }

    setTimeout(() => {
      setVisible(false)
      setNotification(null)
      setConsentGiven(null)
    }, 1500)
  }

  async function handleDismiss() {
    setVisible(false)
    setNotification(null)
    setConsentGiven(null)
    onCancel()
  }

  if (consentGiven === null) return null

  if (showUpdateErrorDialog) {
    return (
      <ScrollableDialog visible={visible}>
        <ContentContainer>
          <CustomText font="bold" style={{ color: Colors.red }}>
            {txt('learnDanish.junior.updateErrorHeader')}
          </CustomText>

          <CustomText style={styles.marginTop}>{txt('learnDanish.junior.updateErrorMessage')}</CustomText>
        </ContentContainer>

        <ActionButton onPress={() => setShowUpdateErrorDialog(false)} style={styles.dialogButton}>
          {txt('global.ok')}
        </ActionButton>
      </ScrollableDialog>
    )
  }

  if (notification === null) {
    return (
      <ScrollableDialog visible={visible}>
        <ContentContainer>
          <CustomText font="bold">{txt('learnDanish.junior.consentHeader')}</CustomText>

          <CustomText style={styles.marginTop}>
            {consentGiven ? txt('learnDanish.junior.removeConsentBody') : txt('learnDanish.junior.consentBody')}
          </CustomText>
        </ContentContainer>

        <ActionButton
          onPress={handleUpdateConsent}
          style={styles.dialogButton}
          danger={consentGiven}
          success={!consentGiven}
        >
          {consentGiven ? txt('learnDanish.junior.removeConsent') : txt('learnDanish.junior.approveConsent')}
        </ActionButton>

        <ActionButton onPress={handleDismiss} style={styles.dialogButton}>
          {txt('learnDanish.junior.cancelConsent')}
        </ActionButton>
      </ScrollableDialog>
    )
  }

  return (
    <ScrollableDialog visible={visible}>
      <ContentContainer>
        <CustomText font="bold" style={{ color: consentGiven ? Colors.red : Colors.green }}>
          {notification}
        </CustomText>
      </ContentContainer>
    </ScrollableDialog>
  )
}

const styles = StyleSheet.create({
  dialogButton: { padding: 10 },
  marginTop: { marginTop: 10 },
})
