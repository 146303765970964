import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { deleteBooking } from '../../../services/api/LearnDanish'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { ButtonSecondary } from '../../buttons/StyledButtons'
import { ShadowStyles, TypographyStyles } from '../../../styles'
import moment from 'moment'
import CustomDialog, { ActionButton, ContentContainer } from '../../dialogs/CustomDialog'
import TextLabel from './TextLabel'

export default function OwnNotBookedSessions(props) {
  //sets locale to danish time
  moment.locale([txt('videoChat.locale')])

  const [dialogVisibleNotBooked, setDialogVisibleNotBooked] = useState(false)
  const [currentNotBookedItem, setCurrentNotBookedItem] = useState('')

  function formatUnixDate(date) {
    var d = moment(date).format('dddd [d.] DD/MM [kl.] HH:mm')
    return d.charAt(0).toUpperCase() + d.substr(1)
  }

  return (
    <View style={styles.container}>
      {props.ownNotBookedSessions.length === 0 ? (
        <View>
          <CustomText font="largeBold" style={styles.titleBold}>
            {txt('videoChat.ownNotBookedSessions')}
          </CustomText>
          <CustomText font="small" style={styles.noSession}>{txt('videoChat.noOwnNotBookedSessions')}</CustomText>
        </View>
      ) : (
        <CustomText font="largeBold" style={styles.titleBold}>
          {txt('videoChat.ownNotBookedSessions')}
        </CustomText>
      )}
      {props.ownNotBookedSessions.map((item, key) => {
        var date = formatUnixDate(item.start_date)
        return (
          <View key={key} style={styles.sessionContainer}>
            <View style={{ flex: 2 }}>
              <CustomText font="small">{date}</CustomText>
              <CustomText
                font="small"
                text={txt('videoChat.duration') + ': ' +
                (new Date(item.end_date).getTime() - new Date(item.start_date).getTime()) / 1000 / 60 +
                ' min.'}
              />
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {item.categories?.map(id => (
                  <TextLabel
                    key={id}
                    text={txt(`learnDanish.categories.${id}`)}
                    style={{ marginRight: 6, marginTop: 6 }}
                  />
                ))}
                {item.junior && <TextLabel junior style={{ marginTop: 6 }} />}
              </View>
            </View>
            <View>
              <ButtonSecondary
                title={txt('videoChat.delete')}
                titleStyle={{ ...TypographyStyles.textSmallBold, color: Colors.redCrossRed }}
                style={[styles.button, { borderWidth: 1, borderColor: Colors.redCrossRed }]}
                onPress={() => {
                  setCurrentNotBookedItem(item)
                  setDialogVisibleNotBooked(true)
                }}
                icon="times"
                iconSize={16}
                color={Colors.redCrossRed}
                stroke="fal"
              />
            </View>
          </View>
        )
      })}
      <CustomDialog visible={dialogVisibleNotBooked}>
        <ContentContainer>
          <CustomText>{txt('videoChat.deleteDialogText')}</CustomText>
        </ContentContainer>
        <ActionButton
          onPress={async () => {
            const res = await deleteBooking(currentNotBookedItem.id)
            if (res) await props.reloadAll()
            setDialogVisibleNotBooked(false)
          }}
        >
          {txt('videoChat.dialogYes')}
        </ActionButton>
        <ActionButton onPress={() => setDialogVisibleNotBooked(false)}>{txt('videoChat.dialogNo')}</ActionButton>
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    width: 150,
    paddingHorizontal: 8,
  },
  container: {
    margin: 10,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
    textAlign: 'center',
    backgroundColor: Colors.white,
    borderRadius: 2,
    padding: 10,
    ...ShadowStyles.liftedShadow,
  },
  sessionContainer: {
    backgroundColor: Colors.white,
    borderRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    padding: 10,
    ...ShadowStyles.liftedShadow,
  },
  titleBold: {
    textAlign: 'center',
  },
})
