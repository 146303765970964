import React, { useEffect, useState } from 'react'
import { Dimensions, Image, Modal, Platform, ScrollView, StyleSheet, View } from 'react-native'
import Layout from '../../constants/Layout'
import PageTitle from '../../components/tags/PageTitle'
import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/core'
import MainContainer from '../../components/MainContainer/MainContainer'
import MySessions from '../../components/views/snakSammen/volunteer/MySessions'
import PreviousSessions from '../../components/views/snakSammen/volunteer/PreviousSessions'
import Materials from '../../components/views/snakSammen/volunteer/Materials'
import { txt } from '../../locales/i18n'
import { getVolunteerAccess } from '../../services/api/volunteerApi'
import Colors from "../../constants/Colors"
import ConversationCreate from "../../components/views/Conversations/ConversationCreate"
import Messages from '../../components/views/snakSammen/volunteer/Messages'
import { CustomText } from "../../components/text/StyledText"
import CustomPressable from "../../components/pressables/CustomPressable"
import VolunteerProfile from "../../components/views/snakSammen/volunteer/VolunteerProfile"
import Paths from "../../constants/Paths"
import NormalIcon from "../../components/icons/NormalIcon"

export default function VolunteerScreen() {
  const route = useRoute()
  const navigation = useNavigation()

  const [menuOpen, setMenuOpen] = useState(false)

  let chip = route.params?.chip

  const mine = txt('videoChat.volunteer.chipMine')
  const previous = txt('videoChat.volunteer.chipPrevious')
  const materials = txt('videoChat.volunteer.chipMaterials')
	const messages = txt('videoChat.volunteer.chipMessages')
  const profile = txt('videoChat.volunteer.chipProfile')

  useEffect(() => {
    navigation.setOptions({
      headerBackTitle: ' ',
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0,
        borderBottomWidth: 0,
        backgroundColor: Colors.white,
        borderBottomColor: Colors.redCross.border,
        height: Layout.small ? 115 : 100
      },
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={{ uri: 'https://filer.boblberg.dk/assets/img/logo/rode-kors-og-boblberg-logo.png' }}
          accessibilityLabel={'SnakSammen'}
          accessibilityIgnoresInvertColors
        />
      ),
    })

    getVolunteerAccess('snaksammen').then((volunteer) => {
      if (!volunteer.isVolunteer) navigation.navigate('SnakSammen')
    })

    let chip = route.params?.chip

    if (Number(chip)) {
      navigation.navigate('SnakSammenVolunteer', {
        id: chip,
      })
    } else if (!chip) {
      chip = mine
      setChipParam(chip)
    }
  }, [])

  function handleNavigateToUserPage() {
    if (Platform.OS === 'web') return window.location.replace(Paths.getBaseUrl() + 'snaksammen')
    setMenuOpen(false)
    return navigation.navigate('SnakSammen')
  }

  const HomeButton = () => (
    <CustomPressable onPress={handleNavigateToUserPage} style={styles.homeButton}>
      <CustomText font="gotham-small">{txt('videoChat.frontPage')}</CustomText>
    </CustomPressable>
  )

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        if (chip === messages && !Layout.small) return <ConversationCreate />
        if (Layout.small) return renderMenuTab()
        else return <HomeButton />
      },
    })
  }, [chip])

  // const SnakSammenWelcome = () => (
  //   <View style={styles.welcomeContainer}>
  //     <CustomText font="gotham-bold-xlarge" style={{ fontSize: 24 }}>
  //       {'SnakSammen'}
  //     </CustomText>
  //     <CustomText font="gotham" style={{ marginTop: 6 }}>
  //       {'Et samarbejde mellem'}
  //     </CustomText>
  //     <Image
  //       source={{ uri: 'https://filer.boblberg.dk/assets/img/logo/rode-kors-og-boblberg-logo.png' }}
  //       resizeMode="contain"
  //       style={{ width: 150, height: 60, marginTop: 6 }}
  //     />
  //   </View>
  // )

  const SnakSammenTab = ({ selected, onPress, text }) => (
    <CustomPressable onPress={onPress} accessibilityLabel={text}>
      <CustomText font="gotham-bold-xsmall" style={{ paddingBottom: 8, paddingHorizontal: 3 }}>
        {text}
      </CustomText>
      {selected && (
        <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.redCross.base }} />
      )}
    </CustomPressable>
  )

  const MenuTab = () => {
    const vHeight = Dimensions.get('window').height

    function handleSelect(chip) {
      setChipParam(chip)
      setMenuOpen(false)
    }

    return (
      <Modal
        transparent={true}
        visible={menuOpen}
        onRequestClose={() => setMenuOpen(false)}
      >
        <View style={[styles.modal, { height: vHeight }]}>

          <CustomPressable
            onPress={() => setMenuOpen(false)}
            style={styles.closeIcon}
            hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
          >
            <NormalIcon name="times" size={24} color={Colors.redCross.base} />
          </CustomPressable>

          <View style={{ gap: 18 }}>
            <CustomPressable onPress={() => handleSelect(mine)}>
              <CustomText style={{ textAlign: 'center' }} font={route.params?.chip === mine ? 'gotham-bold-small' : 'gotham-small'}>
                {txt('videoChat.volunteer.mine')}
              </CustomText>
            </CustomPressable>
            <CustomPressable onPress={() => handleSelect(previous)}>
              <CustomText style={{ textAlign: 'center' }} font={route.params?.chip === previous ? 'gotham-bold-small' : 'gotham-small'}>
                {txt('videoChat.volunteer.previous')}
              </CustomText>
            </CustomPressable>
            <CustomPressable onPress={() => handleSelect(materials)}>
              <CustomText style={{ textAlign: 'center' }} font={route.params?.chip === materials ? 'gotham-bold-small' : 'gotham-small'}>
                {txt('videoChat.volunteer.materials')}
              </CustomText>
            </CustomPressable>
            <CustomPressable onPress={() => handleSelect(messages)}>
              <CustomText style={{ textAlign: 'center' }} font={route.params?.chip === messages ? 'gotham-bold-small' : 'gotham-small'}>
                {txt('learnDanish.volunteer.messages')}
              </CustomText>
            </CustomPressable>
            <CustomPressable onPress={() => handleSelect(profile)}>
              <CustomText style={{ textAlign: 'center' }} font={route.params?.chip === profile ? 'gotham-bold-small' : 'gotham-small'}>
                {txt('videoChat.volunteer.profile.title')}
              </CustomText>
            </CustomPressable>
            <CustomPressable onPress={handleNavigateToUserPage}>
              <CustomText style={{ textAlign: 'center' }} font={'gotham-small'}>
                {txt('videoChat.frontPage')}
              </CustomText>
            </CustomPressable>
          </View>

        </View>
      </Modal>
    )
  }

  function renderMenuTab() {
    return (
      <View style={{ alignItems: 'flex-end', backgroundColor: Colors.white, paddingRight: 18, width: '100%' }}>
        <CustomPressable
          onPress={() => setMenuOpen(true)}
          hitSlop={{ left: 20, right: 20, top: 20, bottom: 20 }}
        >
          <NormalIcon name="bars" size={30} color={Colors.redCross.base} />
        </CustomPressable>
      </View>
    )
  }

  const setChipParam = (chip) => navigation.setParams({ chip })

  const Wrapper = ({ children }) => {
    if (Layout.isSmallDevice) return (
      <ScrollView horizontal>
        {children}
      </ScrollView>
    )
    return (
      <View>
        {children}
      </View>
    )
  }

  function renderChips() {
    return (
      <View style={{backgroundColor: Colors.white}}>
        <Wrapper>
          <View style={styles.chipContainer}>
            {/* Create + own bookings */}
            <View style={styles.chip}>
              <SnakSammenTab
                onPress={() => setChipParam(mine)}
                selected={route.params?.chip === mine}
                text={txt('videoChat.volunteer.mine')}
              />
            </View>

            {/* Previous bookings */}
            <View style={styles.chip}>
              <SnakSammenTab
                onPress={() => setChipParam(previous)}
                selected={route.params?.chip === previous}
                text={txt('videoChat.volunteer.previous')}
              />
            </View>

            {/* Materials */}
            <View style={styles.chip}>
              <SnakSammenTab
                onPress={() => setChipParam(materials)}
                selected={route.params?.chip === materials}
                text={txt('videoChat.volunteer.materials')}
              />
            </View>

            {/* Inbox */}
            <View style={styles.chip}>
              <SnakSammenTab
                onPress={() => setChipParam(messages)}
                selected={route.params?.chip === messages}
                text={txt('learnDanish.volunteer.messages')}
              />
            </View>

            {/* Profile */}
            <View style={styles.chip}>
              <SnakSammenTab
                onPress={() => setChipParam(profile)}
                selected={route.params?.chip === profile}
                text={txt('videoChat.volunteer.profile.title')}
              />
            </View>
          </View>
        </Wrapper>
      </View>
    )
  }

  switch (chip) {
    case previous:
      return (
        <MainContainer backgroundColor={Colors.redCross.background}>
          <PageTitle title={txt('videoChat.volunteer.previous')} />
          {!Layout.small && renderChips()}
          {menuOpen && <MenuTab/>}
          <PreviousSessions />
        </MainContainer>
      )
    case materials:
      return (
        <MainContainer backgroundColor={Colors.redCross.background}>
          <PageTitle title={txt('videoChat.volunteer.materials')} />
          {!Layout.small && renderChips()}
          {menuOpen && <MenuTab/>}
          <Materials />
        </MainContainer>
      )
    case messages:
      return (
        <MainContainer noScroll backgroundColor={Colors.redCross.background}>
          <PageTitle title={txt('learnDanish.volunteer.messages')} />
          {!Layout.small && renderChips()}
          {menuOpen && <MenuTab/>}
          <Messages />
        </MainContainer>
      )
    case profile:
      return (
        <MainContainer backgroundColor={Colors.redCross.background}>
          <PageTitle title={txt('videoChat.volunteer.profile.title')} />
          {menuOpen && <MenuTab/>}
          {!Layout.small && renderChips()}
          <VolunteerProfile />
        </MainContainer>
      )
    case mine:
    default:
      return (
        <MainContainer backgroundColor={Colors.redCross.background}>
          <PageTitle title={txt('videoChat.volunteer.mine')} />
          {!Layout.small && renderChips()}
          {menuOpen && <MenuTab/>}
          {/*{!Layout.isSmallDevice && (*/}
          {/*  <SnakSammenWelcome />*/}
          {/*)}*/}
          <MySessions />
        </MainContainer>
      )
  }
}

const styles = StyleSheet.create({
  // container: {
  //   width: '100%',
  //   maxWidth: Layout.maxFeedWidth,
  //   alignSelf: 'center',
  // },
  // welcomeContainer: {
  //   width: '100%',
  //   maxWidth: 1000,
  //   alignSelf: 'center',
  //   paddingHorizontal: 12,
  //   paddingTop: Layout.isSmallDevice ? 18 : 50,
  //   paddingBottom: 12,
  //   backgroundColor: Colors.redCross.background,
  // },
  chip: {
    marginHorizontal: Layout.isSmallDevice ? 6 : 12,
  },
  chipContainer: {
    backgroundColor: Colors.white,
    alignSelf: 'center',
    flexDirection: 'row',
    paddingTop: 8,
    width: '100%',
    maxWidth: 1000,
    justifyContent: Layout.isSmallDevice ? 'space-evenly' : 'center',
  },
  logo: {
    width: Layout.small ? 125 : 150,
    height: Layout.isSmallDevice ? 40 : 70
  },
  homeButton: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 25,
    backgroundColor: Colors.redCross.baseSecondary,
    alignSelf: 'flex-end',
    marginRight: 12,
  },
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.redCross.background,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    backgroundColor: Colors.redCross.border,
    borderRadius: 12,
    padding: 8,
    position: 'absolute',
    top: Platform.OS === 'web' ? 50 : 70,
    right: 12,
    zIndex: 10
  }
})
