import { CustomText } from '../../text/StyledText'
import { StyleSheet, View } from 'react-native'
import { CustomSwitch } from '../../switches/StyledSwitches'
import { createConnection, removeConnection } from '../../../services/api/ConnectionApi'
import React, { useEffect, useState } from 'react'
import { getUsername } from '../../../services/api/User'
import { cardShadow } from '../../../styles/shadows'
import NormalIcon from '../../icons/NormalIcon'
import ConnectionDialog from './ConnectionDialog'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import CustomPressable from "../../pressables/CustomPressable"

export default function Connection({ users, connection, setConnection }) {
  const [connectionUser, setConnectionUser] = useState(null)
  const [connectionDialogVisible, setConnectionDialogVisible] = useState(false)
  const connectionId = users[0]?.id

  useEffect(() => {
    getUsername(connectionId).then(setConnectionUser)
  }, [])

  async function handleShareProfile() {
    if (connection) {
      const connectionRemoved = await removeConnection(connectionId)
      if (connectionRemoved) setConnection(false)
    } else {
      const connectionCreated = await createConnection(connectionId)
      if (connectionCreated) setConnection(true)
    }
  }

  return (
    <View style={{...cardShadow }}>
      {users.length > 1 ? (
        <CustomPressable
          accessibilityRole="button"
          accessible
          style={styles.container}
          onPress={() => setConnectionDialogVisible(true)}
        >
          <CustomText font="small" style={{flex: 1}}>
            {txt('connections.allowMembers')}
          </CustomText>
          <NormalIcon accessible={false} name="chevron-right" size={16} />
        </CustomPressable>
      ) : (
        <View style={styles.container}>
          <CustomText font="small" style={{flex: 1}}>
            {txt('connections.allow', {name: connectionUser?.username})}
          </CustomText>
          <CustomSwitch
            value={connection}
            onValueChange={handleShareProfile}
            label={connection ? txt('connections.connectionOnLabel') : txt('connections.connectionOffLabel')}
          />
        </View>
      )}
      <ConnectionDialog
        visible={connectionDialogVisible}
        onDismiss={() => setConnectionDialogVisible(false)}
        users={users}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 12,
    paddingRight: 6,
    paddingBottom: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 37,
    marginHorizontal: 'auto',
    width: '100%',
    maxWidth: Layout.maxFeedWidth,
  },
})