import store from '../../redux/store'
import { apiGet } from './ApiRequest'

export async function isUserVolunteer() {
  const userId = store.getState().user.id
  return await apiGet(`volunteers/${userId}`)
}

export async function getVolunteerAccess(service) {
  return await apiGet(`volunteers/${service}`)
}
