import Colors from '../constants/Colors'

export const dropShadow = {
  shadowColor: Colors.black, // IOS
  shadowOffset: { height: 5, width: 0 }, // IOS
  shadowOpacity: 0.2, // IOS
  shadowRadius: 7, //IOS
  elevation: 5, //Android
}

export const shadowInputs = {
  shadowColor: Colors.black, // IOS
  shadowOffset: { height: 1, width: 0 }, // IOS
  shadowOpacity: 0.2, // IOS
  shadowRadius: 3, //IOS
  elevation: 2, //Android
}

export const cardShadow = {
  shadowColor: Colors.darkGrey, // IOS
  shadowOffset: { height: 3, width: 0 }, // IOS
  shadowOpacity: 0.15, // IOS
  shadowRadius: 5, //IOS
  elevation: 5, //Android
}

export const liftedShadow = {
  shadowColor: Colors.black, // IOS
  shadowOffset: { height: 8, width: 8 }, // IOS
  shadowOpacity: 0.07, // IOS
  shadowRadius: 27, //IOS
  elevation: 5, //Android
}

export const unsetShadows = {
  shadowColor: Colors.transparent, // IOS
  shadowOffset: { height: 0, width: 0 }, // IOS
  shadowOpacity: 0, // IOS
  shadowRadius: 0, //IOS
  elevation: 0, //Android
}
