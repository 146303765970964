import React from 'react'
import { Dimensions, Platform, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import NormalIcon from '../../components/icons/NormalIcon'
import Fontsizes from '../../constants/Fontsizes'
import store from '../../redux/store'
import { CarouselSingle } from '../../components/carousels/CarouselPrimary'
import Layout from '../../constants/Layout'
import { setChristmasDialogVisible } from '../../redux/actions'
import { ScrollableDialog } from '../../components/dialogs/CustomDialog'

const smallWeb = Platform.OS === 'web' && Layout.isSmallDevice

export default function AfterSignupDialog() {
  const dialogVisible = useSelector((state) => state.christmas.dialogVisible)

  const items = [
    {
      title: txt('christmasFriends.afterSignup.createdPostTitle'),
      body: txt('christmasFriends.afterSignup.createdPost'),
      icon: "wreath",
      color: Colors.green,
    },
    {
      title: txt('christmasFriends.afterSignup.sendMessageTitle'),
      body: txt('christmasFriends.afterSignup.sendMessage'),
      icon: "paper-plane",
      color: Colors.blue,
    },
    {
      title: txt('christmasFriends.afterSignup.favoriteTitle'),
      body: txt('christmasFriends.afterSignup.favorite'),
      icon: "star",
      color: Colors.julevenner.yellow,
    },
    {
      title: txt('christmasFriends.afterSignup.editTitle'),
      body: txt('christmasFriends.afterSignup.edit'),
      icon: "edit",
      color: Colors.grey,
    },
    {
      title: txt('christmasFriends.afterSignup.matchTitle'),
      body: txt('christmasFriends.afterSignup.match'),
      icon: "tree-christmas",
      color: Colors.green,
    },
  ]

  function close() {
    store.dispatch(setChristmasDialogVisible(false))
  }

  function renderItems(item) {
    return (
      <View style={styles.itemContainer}>
        <NormalIcon
          name={item.icon}
          color={item.color}
          size={80}
          style={{
            marginVertical: Dimensions.get('window').height < 600 ? 10 : 40,
          }}
          stroke="fad"
        />
        <View style={styles.textContainer}>
          <CustomText
            style={{
              fontFamily: 'oswald-bold',
              fontSize: smallWeb ? Fontsizes.l : Fontsizes.xl,
            }}
          >
            {item.title}
          </CustomText>
          <CustomText font={smallWeb ? 'small' : null} style={{ marginTop: 12 }}>
            {item.body}
          </CustomText>
        </View>
      </View>
    )
  }

  return (
    <ScrollableDialog
      visible={dialogVisible}
      onDismiss={close}
    >
      <CarouselSingle
        data={items}
        renderItem={({ item }) => renderItems(item)}
        pagination
        dismiss={close}
      />
    </ScrollableDialog>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
  },
  textContainer: {
    paddingHorizontal: 12,
  },
})
