import { Platform } from 'react-native'

const webErrors = ['Warning: Failed prop type:']
const webWarnings = [
  'Animated: `useNativeDriver` is not supported',
  'StyleSheet.compose(a, b) is deprecated; use array syntax, i.e., [a,b].' //@react-native-community/slider
]

let warnings = ['Constants.deviceId has been deprecated']
let errors = [
  'VirtualizedLists should never be nested inside plain ScrollViews with the same orientation because it can break windowing and other functionality - use another VirtualizedList-backed container instead.',
  'BackHandler is not supported on web and should not be used.'
]

if (Platform.OS === 'web') {
  errors.push(...webErrors)
  warnings.push(...webWarnings)
}

export default function ignoreErrors() {
  const consoleError = console.error
  console.error = (error) => {
    if (typeof error !== 'string') return
    for (const i in errors) {
      if (error.includes(errors[i])) return
    }
    consoleError(error)
  }

  const consoleWarn = console.warn
  console.warn = (warning) => {
    if (typeof warning !== 'string') return
    for (const i in warnings) {
      if (warning.includes(warnings[i])) return
    }
    consoleWarn(warning)
  }
}
