import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { addToWaitlist, bookSession, getAvailableSessions, getVolunteerProfile, getWaitlistUser, waitlistAccess } from '../../../services/api/snakSammen'

import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'

import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import { formatDate } from "./utils/helperFunctions"
import Layout from "../../../constants/Layout"
import CustomPressable from "../../pressables/CustomPressable"
import NormalIcon from "../../icons/NormalIcon"
import CustomDialog, { ContentContainer } from "../../dialogs/CustomDialog"


export function AvailableSessions({ availableDates, handleRefresh, setErrorMessage }) {
	const [sessionDates, setSessionDates] = useState([])
	const [sessions, setSessions] = useState([])
	const [volunteerProfile, setVolunteerProfile] = useState(null)
	const [volunteerProfileVisible, setVolunteerProfileVisible] = useState(false)
	const [showWaitlistButton, setShowWaitlistButton] = useState(false)
	const [showWaitlistSuccess, setShowWaitlistSuccess] = useState(false)

	const userId = useSelector((state) => state.user.id)

	useEffect(() => {
		moment.locale([txt('global.locale')])
	}, [])

	useEffect(() => {
		async function getSessionDates() {
			const sessionDatesTasks = availableDates.map(async (availableDate) => {
				const startDate = moment(availableDate.date).valueOf()
				const endDate = moment(availableDate.date).add(1, 'days').valueOf()
				const dates = await getAvailableSessions(startDate, endDate)
				return { ...availableDate, dates }
			})

			const sessionDates = await Promise.all(sessionDatesTasks)
			setSessionDates(sessionDates)
			flattenSessionDates(sessionDates);
		}

		getSessionDates()
	}, [availableDates])

	useEffect(() => {
		waitlistButtonVisibility()
	}, [sessions])

	function flattenSessionDates(sessionDates) {
		const allSessions = sessionDates.reduce((acc, session) => {
			return acc.concat(session.dates);
		}, []);
		setSessions(allSessions);
	}

	async function handleBookSession(sessionId) {
		const { bookingErrors } = txt('videoChat')

		const ERR_ENUMS = {
			LIMIT_REACHED: bookingErrors.limitReached,
			MAXIMUM_LIMIT_REACHED: bookingErrors.maximumLimitReached,
			SESSION_NOT_FOUND: bookingErrors.SessionNotFound,
			SESSION_ALREADY_BOOKED: bookingErrors.alreadyBooked,
			OWN_BOOKING: bookingErrors.ownBooking,
			DOUBLE_BOOKING: bookingErrors.doubleBook,
			DATE_EXPIRED: bookingErrors.dateExpired,
			TIMEFRAME_OCCUPIED: bookingErrors.timeframeOccupied,
			VOLUNTEER_IS_ALREADY_IN_TIMEFRAME: bookingErrors.volunteerInTimeframe,
		}

		const { err_code } = await bookSession(sessionId)

		if (err_code !== undefined) {
			const errorMessage = ERR_ENUMS[err_code] ?? bookingErrors.unknown
			return setErrorMessage(errorMessage)
		}

		await handleRefresh()
	}

	async function handlePressVolunteer(userId, username) {
		if (userId) {
			const profileRes = await getVolunteerProfile(userId)
			setVolunteerProfile({ description: profileRes?.description, username })
			setVolunteerProfileVisible(true)
		}
	}

	async function handleAddToWaitlist() {
		const added = await addToWaitlist()
		if (!added) return setErrorMessage(txt('videoChat.user.getStartedDialog.waitlistError'))
		
		setShowWaitlistSuccess(true)
		setShowWaitlistButton(false)
	}
	
	async function waitlistButtonVisibility() {
		const hasWaitlistAccess = await waitlistAccess()
		if (!hasWaitlistAccess) return setShowWaitlistButton(false)

		const alreadySignedUp = await getWaitlistUser(userId)
		if (alreadySignedUp) return setShowWaitlistButton(false)

		if (sessions.length > 3) return setShowWaitlistButton(false)
		
		setShowWaitlistButton(true)
	}

	return (
		<View style={styles.container}>
			<View>
				<CustomText font="gotham-xlarge" accessibilityRole="header" aria-level={2}>
					{txt('videoChat.availableSessions')}
				</CustomText>
				{sessionDates.length === 0 && (
					<CustomText font="gotham-small" style={[styles.text, sessionDates.length === 0 && styles.noSession]}>
						{txt('videoChat.noAvailableSessions')}
					</CustomText>
				)}
			</View>

			{sessions.map((session, index) => {
				const formattedDate = formatDate(session.start_date, session.end_date);
				return (
					<View key={index} style={styles.card}>
						<View style={{ flex: 5 }}>
							<CustomText font="gotham-bold-small">{formattedDate}</CustomText>

							<CustomPressable onPress={() => handlePressVolunteer(session.volunteer_id, session.username)}>
								<CustomText font="gotham-small" style={{textDecorationLine: 'underline'}}>
									<CustomText font="gotham-small">{txt('videoChat.volunteer.title') + ': '}</CustomText>
									{session.username}
								</CustomText>
							</CustomPressable>
						</View>

						<ButtonPrimary
							title={txt('videoChat.book')}
							onPress={() => handleBookSession(session.id)}
							style={styles.button}
							titleStyle={styles.buttonTitle}
						/>
					</View>
				);
			})}

			{showWaitlistButton && (
				<ButtonPrimary
					title={txt('videoChat.user.waitlist.button')}
					onPress={handleAddToWaitlist}
					style={styles.waitlistButton}
					titleStyle={styles.waitlistButtonTitle}
				/>
			)}

			<CustomDialog visible={showWaitlistSuccess} onDismiss={() => setShowWaitlistSuccess(false)}>
				<CustomPressable onPress={() => setShowWaitlistSuccess(false)} style={styles.iconContainer}>
					<NormalIcon name="times" size={20} color={Colors.text} />
				</CustomPressable>

				<ContentContainer>
					<CustomText font="gotham-bold-large" style={{ marginRight: 24, marginBottom: 6 }}>
						{txt('videoChat.user.waitlist.successTitle')}
					</CustomText>
					<CustomText font="gotham-small">
						{txt('videoChat.user.waitlist.successBody')}
					</CustomText>
				</ContentContainer>
			</CustomDialog>

			<CustomDialog visible={volunteerProfileVisible} onDismiss={() => setVolunteerProfileVisible(false)}>
				<CustomPressable onPress={() => setVolunteerProfileVisible(false)} style={styles.iconContainer}>
					<NormalIcon name="times" size={20} color={Colors.text} />
				</CustomPressable>

				<ContentContainer>
					<View style={{ marginTop: 18 }}>
						<CustomText font="gotham-bold">{volunteerProfile?.username}</CustomText>
						{volunteerProfile?.description ? (
							<CustomText font="gotham" style={{ marginTop: 18 }}>{volunteerProfile?.description}</CustomText>
						) : (
							<CustomText font="gotham" style={{ marginTop: 18 }}>{txt('videoChat.user.noVolunteerProfile')}</CustomText>
						)}
					</View>
				</ContentContainer>
			</CustomDialog>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		margin: 10,
	},
	card: {
		backgroundColor: Colors.white,
		borderRadius: 10,
		flexDirection: Layout.small ? 'column' : 'row',
		alignItems: Layout.small ? 'flex-start' : 'center',
		marginTop: 10,
		paddingVertical: Layout.small ? 18 : 12,
		paddingHorizontal: Layout.small ? 24 : 18,
	},
	text: {
		color: Colors.inactive,
		marginTop: 10,
	},
	noSession: {
		marginBottom: 60,
	},
	button: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.background,
		paddingVertical: 13,
		paddingHorizontal: 32,
		marginLeft: 12,
		minWidth: 115,
		marginTop: Layout.small ? 12 : 0,
	},
	buttonTitle: {
		color: Colors.redCross.text,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
	iconContainer: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	},
	waitlistButton: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.base,
		paddingVertical: 13,
		paddingHorizontal: 32,
		marginLeft: 12,
		minWidth: 115,
		marginTop: 24,
	},
	waitlistButtonTitle: {
		color: Colors.white,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
})
