import { View } from 'react-native'
import { ModalSelectorCustomVideoChat } from '../../../modalSelectors/ModalSelector'
import { CustomInputs } from '../../../text/StyledTextinputs'
import NormalIcon from '../../../icons/NormalIcon'
import React, { useEffect, useState } from 'react'
import { txt } from '../../../../locales/i18n'
import { ShadowStyles } from '../../../../styles'
import { CustomText } from '../../../text/StyledText'
import Colors from '../../../../constants/Colors'
import moment from 'moment'
import 'moment/min/locales' //needs to be there to update locale to another countrycode.
import CustomPressable from "../../../pressables/CustomPressable"

export default function RenderPreviousBookers({ data, onChange, selected, reset }) {
	const [listData, setListData] = useState(data)
	moment.locale('da')

	useEffect(() => {
		const setComponent = (user, index) => (
		<View key={index}>
			<CustomText font="gotham-bold-small">{user?.username}</CustomText>
			<CustomText font="gotham-small" style={{color: Colors.inactive, fontSize: 14}}>
				{txt('videoChat.volunteer.create.latest') + moment(user?.lastSessionDate).format('ll') + ' • ' + txt('videoChat.volunteer.create.count') + user?.sessionCount}
			</CustomText>
		</View>
		)

		if (data?.length > 0) {
			const newArr = data.map((c, index) => ({...c, component: setComponent(c, index)}))
			setListData(newArr)
		}
	}, [data])

	return (
		<View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1}}>
			<View style={{ flex: 9 }}>
				<ModalSelectorCustomVideoChat
					data={listData}
					keyExtractor={(booker) => (booker.user_id ? booker.user_id?.toString() : booker.id?.toString())}
					labelExtractor={(booker) => (booker.username)}
					onChange={onChange}
					style={{
						borderWidth: 1,
						borderColor: Colors.redCross.border,
						...ShadowStyles.unsetShadows,
						backgroundColor: Colors.white,
						borderRadius: 6,
					}}
				>
				<CustomInputs
					placeholder={txt('videoChat.volunteer.create.chooseFromPrev')}
					value={selected}
					textStyle={{
						fontFamily: 'gotham-book',
						fontSize: 15,
					}}
					style={{
						borderWidth: 0,
						...ShadowStyles.unsetShadows,
						borderRadius: 6,
						backgroundColor: Colors.transparent,
						fontFamily: 'gotham-book',
						fontSize: 15,
					}}
					disabled
				/>
				</ModalSelectorCustomVideoChat>
			</View>
			{(selected === true || selected !== '') && (
				<CustomPressable onPress={reset} style={{position: 'absolute', right: 6}}>
					<NormalIcon name="times" size={18} color={Colors.redCross.red} style={{ marginHorizontal: 12 }} />
				</CustomPressable>
			)}
		</View>
	)
}