import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import Layout from '../../../constants/Layout'
import CustomPressable from "../../pressables/CustomPressable"

export default function PostPin({isAdmin, isChildPost, isPinned, togglePin}) {
  const [isHovering, setIsHovering] = useState(false)
  const helpText = isPinned
    ? txt('groups.bulletin.unpinPostHelpText')
    : txt('groups.bulletin.pinPostHelpText')
  return (
    <>
      {isAdmin && !isChildPost && (
        <View>
          {isHovering && !Layout.isSmallDevice && (
            <View style={styles.tooltip}>
              <CustomText style={{ color: Colors.white }}>
                {helpText}
              </CustomText>
              <View style={styles.tooltipArrow} />
            </View>
          )}
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <CustomPressable
              style={{ marginHorizontal: 8 }}
              hoverColor={Colors.skyBlue}
              accessibilityRole="header"
              accessibilityLabel={helpText}
              onPress={togglePin}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <NormalIcon
                name="thumbtack"
                size={17}
                color={isPinned ? Colors.tintColor : Colors.inactive}
                stroke={isPinned ? "fas" : "fal"}
              />
            </CustomPressable>
          </View>
        </View>
      )}
      {!isAdmin && isPinned && !isChildPost && (
        <View>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{ marginRight: 8 }} size={24} hoverColor={Colors.skyBlue}>
              <NormalIcon
                name="thumbtack"
                size={Fontsizes.m}
                color={Colors.tintColor}
                stroke="fas"
              />
            </View>
          </View>
        </View>
      )}
    </>
  )
}


const styles = StyleSheet.create({
  tooltip: {
    backgroundColor: Colors.blackTransparent82,
    borderRadius: 8,
    bottom: 28,
    position: 'absolute',
    width: 160,
    paddingVertical: 4,
    paddingHorizontal: 8,
    alignSelf: 'center',
    alignItems: 'center',
    marginRight: 48,
    textAlign: 'center',
  },
  tooltipArrow: {
    width: 0,
    height: 0,
    backgroundColor: Colors.transparent,
    borderStyle: 'solid',
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderBottomWidth: 8,
    borderLeftColor:  Colors.transparent,
    borderRightColor:  Colors.transparent,
    borderBottomColor: Colors.blackTransparent82,
    transform: [{rotate: "180deg"}],
    position: 'absolute',
    right: 52,
    top: 28
  }
})