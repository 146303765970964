import { StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { CustomText } from '../text/StyledText'
import React from 'react'
import { ShadowStyles } from '../../styles'
import { txt } from '../../locales/i18n'
import CustomPressable from "../pressables/CustomPressable"

export default function NumberPicker({
  value,
  onValueChange,
  style,
  iconStyle,
}) {
  function countUp() {
    onValueChange(value + 1)
  }

  function countDown() {
    onValueChange(value === 0 ? value : value - 1)
  }

  return (
    <View style={[styles.container, style]}>
      <CustomPressable onPress={countDown}>
        <NormalIcon
          name="minus"
          style={[styles.icon, iconStyle]}
          size={20}
          accessibilityRole="button"
          accessibilityLabel={txt('formElements.numberPicker.countDown')}
        />
      </CustomPressable>
      <CustomText font="bold">{value}</CustomText>
      <CustomPressable onPress={countUp}>
        <NormalIcon
          name="plus"
          style={[styles.icon, iconStyle]}
          size={20}
          accessibilityRole="button"
          accessibilityLabel={txt('formElements.numberPicker.countUp')}
        />
      </CustomPressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
    width: 100,
    ...ShadowStyles.shadowInputs,
  },
  icon: {
    backgroundColor: Colors.background,
    borderRadius: 6,
    padding: 3,
  },
})
