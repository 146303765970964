import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Dimensions, Image, Platform } from 'react-native'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import PageTitle from '../../components/tags/PageTitle'
import { Portal } from 'react-native-paper'
import TxtTMD from '../../constants/TxtTMD'
import Layout from '../../constants/Layout'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useSelector } from 'react-redux'
import { getVolunteerAccess } from '../../services/api/volunteerApi'
import { URLOpener } from '../../utils/URLOpener'
import Colors from '../../constants/Colors'
import { ShadowStyles } from '../../styles'
import NormalIcon from '../../components/icons/NormalIcon'
import { useNavigation } from '@react-navigation/core'
import { sproglandHeaderBackImage, sproglandHeaderStyle, sproglandHeaderTitle } from './SproglandOptions'
import { LanguagePicker } from '../../components/formElements/LanguagePicker'
import CustomPressable from "../../components/pressables/CustomPressable"

const { width } = Dimensions.get('window')
const small = width < 800
const bannerBig = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-banner-web.png'
const bannerSmall = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-banner-native.png'
const sproglandReg = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-almindelig.jpg'
const sproglandVolunteer = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-frivillig.jpg'
const sproglandJunior = 'https://filer.boblberg.dk/Assets/Img/sprogland/sproglandjunior.jpg'

export default function LearnDanishScreen(props) {
  const userToken = useSelector((state) => state.userToken)
  const [isVolunteer, setIsVolunteer] = useState(false)
  const navigation = useNavigation()

  if (userToken) getVolunteerAccess('sprogland').then((volunteer) => setIsVolunteer(volunteer.isVolunteer))

  useEffect(() => {
    let volunteer = false
    if (userToken) getVolunteerAccess('sprogland').then((v) => (volunteer = v?.isVolunteer))
    props.navigation.setOptions({
      headerBackTitle: small && ' ',
      headerBackTitleStyle: { color: Colors.text, fontFamily: 'raleway-regular' },
      headerBackImage: () => sproglandHeaderBackImage(navigation),
      headerTitle: () => sproglandHeaderTitle(true),
      headerStyle: { ...sproglandHeaderStyle, backgroundColor: Colors.white },
      headerRight: () => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <LanguagePicker onChangeStartScreen={'Sprogland'} />
          <CustomPressable
            accessibilityRole="button"
            style={styles.headerRight}
            onPress={() => {
              if (!userToken) props.navigation.navigate('Login')
              else if (volunteer && userToken) props.navigation.navigate('SproglandVolunteer')
              else if (volunteer === false) URLOpener('https://boblberg.dk/sprogland/bliv-frivillig')
            }}
          >
            {!small && (
              <CustomText style={styles.headerRightText}>
                {!userToken
                  ? txt('loginField.loginButtonText')
                  : volunteer && userToken
                  ? txt('learnDanish.intro.volunteerButton')
                  : txt('learnDanish.intro.volunteerApplyButton')}
              </CustomText>
            )}
            <NormalIcon
              name={userToken ? "user-headset" : "sign-in-alt"}
              size={20}
              color={Colors.redCrossRed}
              stroke={'fad'}
            />
          </CustomPressable>
        </View>
      ),
    })
  }, [])

  const handleGetStartedClick = (screen) => {
    if (userToken) {
      props.navigation.navigate(screen)
    } else {
      if (Platform.OS !== 'web') {
        const originScreen = screen === 'SproglandJunior' ? '/sprogland/junior' : '/sprogland-oversigt'
        URLOpener(`https://boblberg.dk/opret?originScreen=${originScreen}`)
      } else {
        props.navigation.navigate('LearnDanishSignup', { originScreen: screen })
      }
    }
  }

  const CardButton = ({ title, onPress, description, icon }) => {
    return (
      <CustomPressable style={small ? styles.card : styles.cardBig} onPress={onPress}>
        <View style={small ? { flexDirection: 'row', alignItems: 'center' } : { flexDirection: 'column' }}>
          <View style={{ flexDirection: 'column', flex: 1, marginBottom: small ? 0 : 12 }}>
            <CustomText style={{ paddingTop: small ? 0 : 6 }} font="smallBold">
              {title}
            </CustomText>
            <CustomText style={{ paddingTop: 6, paddingRight: 6 }} font="small">
              {description}
            </CustomText>
          </View>
          <NormalIcon name={icon} size={14} color={Colors.redCrossRed} stroke={'far'} style={{ paddingRight: 6 }} />
        </View>
      </CustomPressable>
    )
  }

  const CardButtonImg = ({ title, onPress, description, icon, img }) => {
    return (
      <View>
        <Image
          style={small ? styles.cardImage : styles.cardImageBig}
          resizeMode="contain"
          source={{ uri: img }}
          accessibilityIgnoresInvertColors
        />
        <CustomPressable style={small ? styles.card : styles.cardBig} onPress={onPress}>
          <View style={small ? { flexDirection: 'row', alignItems: 'center' } : { flexDirection: 'column' }}>
            <View style={{ flexDirection: 'column', flex: 1, marginBottom: small ? 0 : 12 }}>
              <CustomText style={{ paddingTop: small ? 0 : 6 }} font="smallBold">
                {title}
              </CustomText>
              <CustomText style={{ paddingTop: 6, paddingRight: 6 }} font="small">
                {description}
              </CustomText>
            </View>
            <NormalIcon name={icon} size={14} color={Colors.redCrossRed} stroke={'far'} style={{ paddingRight: 6 }} />
          </View>
        </CustomPressable>
      </View>
    )
  }

  return (
    <Portal.Host>
      <MainContainer backgroundColor={Colors.white}>
        <PageTitle title={TxtTMD.learnDanish.title[TxtTMD.lang]} />
        <View style={styles.container}>
          <View style={styles.marginLeftRight}>
            <View style={styles.imageContainer}>
              <Image
                style={styles.bannerImage}
                resizeMode={'contain'}
                accessibilityIgnoresInvertColors
                accessibilityLabel={'Sprogland illustration'}
                source={{ uri: small ? bannerSmall : bannerBig }}
              />
            </View>

            <View style={[styles.contentContainer, !small && { ...ShadowStyles.liftedShadow }]}>
              <CustomText center font="xlargeBold" accessibilityRole="header" aria-level={2}>
                {txt('learnDanish.intro.header1')}
              </CustomText>
              <CustomText font="small" center style={{ paddingTop: 12 }}>
                {txt('learnDanish.intro.body1')}
              </CustomText>
              <CustomText
                center
                font="smallBold"
                accessibilityRole="header"
                aria-level={2}
                style={{ paddingVertical: 12 }}
              >
                {txt('learnDanish.intro.header2')}
              </CustomText>
            </View>
            <View style={styles.cardsContainer}>
              {small ? (
                <>
                  <CardButton
                    title={txt('learnDanish.intro.actionButton')}
                    description={txt('learnDanish.intro.actionButtonSubtitle')}
                    onPress={() => handleGetStartedClick('SproglandOversigt')}
                    icon="arrow-right"
                  />
                  <CardButton
                    title={txt('learnDanish.intro.junior.heading')}
                    description={txt('learnDanish.intro.junior.text')}
                    onPress={() => handleGetStartedClick('SproglandJunior')}
                    icon="arrow-right"
                    junior
                  />
                  {isVolunteer && userToken && (
                    <CardButton
                      title={txt('learnDanish.intro.volunteerButton')}
                      description={txt('learnDanish.intro.volunteerButtonSubtitle')}
                      onPress={() => props.navigation.navigate('SproglandVolunteer')}
                      icon="arrow-right"
                    />
                  )}
                  {isVolunteer === false && (
                    <CardButton
                      title={txt('learnDanish.intro.volunteerApplyButton')}
                      description={txt('learnDanish.intro.volunteerApplySubtitle')}
                      onPress={() => URLOpener('https://boblberg.dk/sprogland/bliv-frivillig')}
                      icon="arrow-right"
                    />
                  )}
                </>
              ) : (
                <>
                  <CardButtonImg
                    title={txt('learnDanish.intro.actionButton')}
                    description={txt('learnDanish.intro.actionButtonSubtitle')}
                    onPress={() => handleGetStartedClick('SproglandOversigt')}
                    icon="arrow-right"
                    img={sproglandReg}
                  />
                  <CardButtonImg
                    title={txt('learnDanish.intro.junior.heading')}
                    description={txt('learnDanish.intro.junior.text')}
                    onPress={() => handleGetStartedClick('SproglandJunior')}
                    icon="arrow-right"
                    junior
                    img={sproglandJunior}
                  />
                  {isVolunteer && userToken && (
                    <CardButtonImg
                      title={txt('learnDanish.intro.volunteerButton')}
                      description={txt('learnDanish.intro.volunteerButtonSubtitle')}
                      onPress={() => props.navigation.navigate('SproglandVolunteer')}
                      icon="arrow-right"
                      img={sproglandVolunteer}
                    />
                  )}
                  {isVolunteer === false && (
                    <CardButtonImg
                      title={txt('learnDanish.intro.volunteerApplyButton')}
                      description={txt('learnDanish.intro.volunteerApplySubtitle')}
                      onPress={() => URLOpener('https://boblberg.dk/sprogland/bliv-frivillig')}
                      icon="arrow-right"
                      img={sproglandVolunteer}
                    />
                  )}
                </>
              )}
            </View>
						<View style={styles.videoLinksContainer}>
							<CustomText center font="bold" accessibilityRole="header" aria-level={2} style={{ marginBottom: 16 }}>
								{txt('learnDanish.intro.videoGuidesTitle')}
							</CustomText>
							<View style={styles.videoWrapper}>
								<CustomPressable onPress={() => URLOpener('https://filer.boblberg.dk/video/sprogland/video-guide-1.mp4')}>
									<Image
										source={{ uri: 'https://filer.boblberg.dk/video/sprogland/video-guide-1-thumbnail.png' }}
										style={{ width: 178, height: 100, alignSelf: 'center' }}
										accessibilityIgnoresInvertColors
									/>
								</CustomPressable>  
								<View style={styles.videoTextContainer}>
									<CustomText font="small" style={styles.videoLink} onPress={() => URLOpener('https://filer.boblberg.dk/video/sprogland/video-guide-1.mp4')}>
										{txt('learnDanish.intro.videoLink1')}
									</CustomText>
								</View>
							</View>

							<View style={styles.videoWrapper}>
								<CustomPressable onPress={() => URLOpener('https://filer.boblberg.dk/video/sprogland/video-guide-2.mp4')}>
									<Image
										source={{ uri: 'https://filer.boblberg.dk/video/sprogland/video-guide-2-thumbnail.png' }}
										style={{ width: 178, height: 100, alignSelf: 'center' }}
										accessibilityIgnoresInvertColors
									/>
								</CustomPressable>
								<View style={styles.videoTextContainer}>
									<CustomText font="small" style={styles.videoLink} onPress={() => URLOpener('https://filer.boblberg.dk/video/sprogland/video-guide-2.mp4')}>
										{txt('learnDanish.intro.videoLink2')}
									</CustomText>
								</View>
							</View>
						</View>
            <CustomText center font="smallItalic" style={styles.infoText}>
              {txt('learnDanish.intro.body3')}
            </CustomText>
          </View>
        </View>
      </MainContainer>
    </Portal.Host>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: 1200,
  },
  imageContainer: {
    backgroundColor: Colors.redCrossEmber,
    paddingBottom: !small ? 24 : 0,
  },
  contentContainer: {
    backgroundColor: Colors.white,
    paddingTop: 24,
    paddingBottom: small ? 0 : 24,
    paddingHorizontal: small ? 0 : 24,
    width: small ? width : '100%',
    maxWidth: small ? width * 0.85 : 900,
    marginBottom: small ? 0 : 24,
    marginTop: !small ? -40 : 0,
    alignSelf: 'center',
  },
  bannerImage: {
    alignSelf: 'center',
    width: width * 0.9,
    height: small ? 150 : 250,
  },
  marginLeftRight: { marginLeft: 12, marginRight: 12 },
  cardsContainer: {
    flexDirection: 'row',
    justifyContent: small ? 'space-evenly' : 'space-between',
    flexWrap: 'wrap',
    paddingBottom: 12,
    width: small ? width : '100%',
    maxWidth: small ? width * 0.85 : 900,
    alignSelf: 'center',
  },
  card: {
    backgroundColor: Colors.white,
    width: width * 0.9,
    ...ShadowStyles.liftedShadow,
    padding: 12,
    marginTop: 12,
    alignSelf: 'center',
  },
  cardBig: {
    backgroundColor: Colors.white,
    width: Layout.maxFeedWidth * 0.28,
    ...ShadowStyles.liftedShadow,
    padding: 24,
    alignSelf: 'center',
    marginTop: -50,
  },
  cardImage: {
    height: 200,
    width: width * 0.9,
  },
  cardImageBig: {
    height: 200,
    width: Layout.maxFeedWidth * 0.33,
  },
  headerRight: {
    marginHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRightText: {
    marginRight: 6,
    color: Colors.text,
  },
	videoLinksContainer: {
		flexDirection: 'column',
		marginTop: small ? 24 : 48,
		paddingHorizontal: small ? 12 : 0,
	},
	videoWrapper: {
		display: 'flex',
		flexDirection: small ? 'column' : 'row',
		alignSelf: 'center',
		justifyContent: 'center',
		gap: small ? 12 : 24,
		width: small ? '100%' : 500,
		marginBottom: small ? 36 : 24,
	},
	videoTextContainer: {
		flex: 1,
		alignSelf: 'center',
	},
	videoLink: {
		color: Colors.blueAzure,
		textAlign: small ? 'center' : 'left',
	},
  infoText: {
    maxWidth: !small ? 900 : '100%',
    paddingTop: small ? 0 : 24,
    paddingHorizontal: small ? 12 : 0,
    alignSelf: 'center',
    marginBottom: 36,
  },
})
