import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { useNavigation } from '@react-navigation/core'

import { CustomText } from '../../text/StyledText'

import { getFullUnion } from '../../../services/api/Unions'
import Logo from '../../../assets/images/icon.png'
import { borderRadiusS } from '../../../styles/corners'
import { dropShadow } from '../../../styles/shadows'
import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import CustomPressable from "../../pressables/CustomPressable"

export const UnionLocationModal = ({ location }) => {
  const navigation = useNavigation()
  const [union, setUnion] = useState(null)

  useEffect(() => {
    getFullUnion(location.id).then(setUnion)
  }, [location])

  const renderAddress = () => {
    const addressInfo = [union.address, union.city, union.postal_code]

    if (addressInfo.every(value => value !== null)) {
      return union.address + ', ' + union.postal_code + ' ' + union.city
    } else {
      return '...'
    }
  }

  if (union === null) return null

  return (
    <View style={styles.container}>
      <CustomPressable style={styles.mapDialog} onPress={() => navigation.push('Union', { id: union.id })}>
        <Image
          style={styles.image}
          source={ union.img_filename ? { uri: Paths.imageUrlBase + union.img_filename } : Logo }
          accessibilityIgnoresInvertColors
        />

        <View style={styles.textContainer}>
          <CustomText font="bold">{union.title}</CustomText>

          <CustomText font="small" numberOfLines={1}>
            {union.body}
          </CustomText>

          <View style={styles.separator} />

          <CustomText font="small" numberOfLines={1}>
            {renderAddress()}
          </CustomText>
        </View>
      </CustomPressable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },

  mapDialog: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 12,
    width: '90%',
    borderRadius: 12,
    maxWidth: 400,
    position: 'absolute',
    bottom: 12,
    ...borderRadiusS,
    ...dropShadow,
  },

  image: {
    borderRadius: 50,
    borderWidth: 1,
    height: 80,
    width: 80,
    alignSelf: 'center',
  },

  textContainer: {
    flex: 1,
    marginLeft: 12,
    overflow: 'hidden',
  },

  separator: {
    height: 1,
    margin: 10,
    backgroundColor: Colors.bluePale,
  },
})
