import React, { useState, useEffect, useRef } from 'react'
import { StyleSheet, Platform, View } from 'react-native'
import OptionsList from '../../../components/lists/OptionsList'
import { txt } from '../../../locales/i18n'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { useSelector } from 'react-redux'
import { Modules } from '../../../constants/Modules'
import { joinUniverseById } from '../../../services/api/Universe'
import layout from '../../../constants/Layout'
import { URLOpener } from '../../../utils/URLOpener'
import Paths from '../../../constants/Paths'
import Layout from '../../../constants/Layout'
import { getRegion } from '../../../services/localization'
import { getVolunteerAccess } from '../../../services/api/volunteerApi'

export default function StackUniverseMenuScreen(props) {
	const [universe, setUniverse] = useState('')
	const [loading, setLoading] = useState(true)
	const [menuOptions, setMenuOptions] = useState([])
	const country = useSelector((state) => state.user.country)	
	const universes = useSelector((state) => state.user?.universes)
	const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())
	const data = useRef([]).current
	const region = getRegion()

	useEffect(() => {
		const universeName = props.route.params.universe?.toLowerCase()
		const universe = universes.find((uni) => uni.name === universeName)
		if (universe) {
			setUniverse(universe)
		} else {
			props.navigation.navigate('app')
		}
	}, [])

	useEffect(() => {
		if (universe && loading) {
			setLoading(false)
		}
		if (!loading) {
			generateMenu()
		}
	}, [universe, loading])

	function generateMenu() {
		data.push(
			{
				body: ['nytaarsvenner', 'nyarsvanner'].includes(universe.name) ? txt('stackMenu.newYearStories') : txt('stackMenu.stories'),
				onPress: async () => {
					if (universe.name === 'nytaarsvenner') {
						await URLOpener(Paths.getBaseUrl() + 'nytaarsvenner/nytaarshistorier')
					} else if (universe.name === 'nyarsvanner') {
						await URLOpener(Paths.getBaseUrl() + 'nyarsvanner/nyaarshistorier')
					} else {
						props.navigation.navigate('Stories', {
							universe: universe.name,
						})
					}
				},
				icon: { name: 'book-open' },
			},
			{
				body: txt('profile.title'),
				onPress: () => {
					props.navigation.navigate('Profile', {
						universe: universe.name,
					})
				},
				icon: { name: 'user' },
			},
			{
				body: txt('settings.title'),
				onPress: () => {
					props.navigation.navigate('Settings', {
						universe: universe.name,
					})
				},
				icon: { name: 'cog' },
			},
		)

		if (['aauucn', 'au', 'herlev', 'krifa'].includes(universe.name)) {
			data.splice(data.length - 2, 0, {
				body: txt('events.title'),
				onPress: () => {
					props.navigation.navigate('Events', {
						universe: universe.name,
					})
				},
				icon: { name: 'calendar-star' },
			})
		}

		if (universe.modules?.includes(Modules.GROUPS)) {
			if (!['herlev', 'krifa'].includes(universe.name) || Layout.window.width < 375) {
				data.splice(data.length - 2, 0, {
					body: txt('groups.title'),
					onPress: () => {
						props.navigation.navigate('Groups', {
							universe: universe.name,
						})
					},
					icon: { name: 'users' },
				})
			}
		}

		if (universe.name === 'krifa') {
			data.splice(data.length - 2, 0, {
				body: txt('videoChat.title'),
				icon: { name: "comments" },
				onPress: async () => {
					const isVolunteer = await getVolunteerAccess('snaksammen') ?? false
					const isWeb = Platform.OS === 'web'
					if (isVolunteer?.isVolunteer) return props.navigation.navigate('SnakSammenVolunteer')

					if (isWeb === true) return window.location.replace(Paths.getBaseUrl() + 'snaksammen')
					props.navigation.navigate('SnakSammen')
				},
				hide: region !== 'dk',
			})
		}
  
		if (universe.name === 'herlev') {
			const unionsButton = {
				body: txt('unions.title'),
				onPress: () => {
					props.navigation.navigate('Unions', {
						universe: universe.name,
					})
				},
				icon: { name: 'users-class' },
			}

			const snaksammenButton = {
				body: txt('videoChat.title'),
				icon: { name: "comments" },
				onPress: async () => {
					const isVolunteer = await getVolunteerAccess('snaksammen') ?? false
					const isWeb = Platform.OS === 'web'
					if (isVolunteer?.isVolunteer) return props.navigation.navigate('SnakSammenVolunteer')

					if (isWeb === true) return window.location.replace(Paths.getBaseUrl() + 'snaksammen')
					props.navigation.navigate('SnakSammen')
				},
				hide: region !== 'dk',
			}
	
			const partnersButton = {
				body: txt('universe.herlev.offers'),
				onPress: () => {
					props.navigation.navigate('HerlevOffers', {
						universe: universe.name,
					})
				},
				icon: { name: 'grip-vertical'},
			}

			const buttons = [unionsButton, snaksammenButton, partnersButton]
			buttons.forEach(button => {
				data.splice(data.length - 2, 0, button)
			})
		}

		if (Platform.OS === 'web') {
			const cookieButton = {
				body: txt('cookieLabel'),
				onPress: () => showCookieBanner(),
				icon: { name: 'cookie-bite' },
			}

			// adds cookie button to second last position
			data.splice(data.length - 2, 0, cookieButton)
		}

		universes?.forEach((universe) => {
			if (universe.name === 'nytaarsvenner') return
			if (universe.name !== universeObject?.name) {
				const universeData = {
					body: txt('boblberg.goTo') + universe.title,
					onPress: () => {
							props.navigation.push('universe', {
							screen: 'UniverseBoblStack',
							params: {
								universe: universe.name,
							},
						})
					},
					icon: { name: 'bubbles', boblbergIcon: true },
				}
				data.unshift(universeData)
			}
		})

		if (universeObject?.name === 'nytaarsvenner') {
			data.splice(data.length - (Platform.OS === 'web' ? 3 : 2 ), 0, {
				body: txt('universe.newyear.partnerships'),
				onPress: () => URLOpener(Paths.getBaseUrl() + 'nytaarsvenner/partnerskaber'),
				icon: { name: 'handshake-alt' },
			})
		}

		if (universeObject?.name !== 'nytaarsvenner' && country === 'Denmark') {
			data.unshift({
				body: txt('boblberg.goTo') + txt('stackMenu.nytaarsvenner'),
				onPress: async () => {
					const universe = universes.find((uni) => uni.name === 'nytaarsvenner')
					if (!universe) await joinUniverseById(10)
					props.navigation.push('universe', {
						screen: 'UniverseBoblStack',
						params: {
							screen: 'Universe',
							initial: true,
							params: { universe: 'nytaarsvenner' },
						},
					})
				},
				icon: { name: 'glass-cheers' },
			})
		}

		if (country === 'Denmark') {
			data.unshift({
				body: txt('christmasFriends.title'),
				onPress: () => {
					props.navigation.navigate('christmasFriends')
				},
				icon: { name: 'hat-santa' },
			})
		}

		data.unshift({
			body: txt('boblberg.goToBoblberg'),
			onPress: () => {
				props.navigation.navigate('app')
			},
			icon: { name: 'bobl', boblbergIcon: true },
		})

		setMenuOptions(data)
	}

	return (
		<MainContainer backgroundColor={universeObject?.backgroundColor}>
			<PageTitle title={TxtTMD.stackMenu.title[TxtTMD.lang]} />
			<View style={styles.container}>
				<OptionsList data={menuOptions} />
			</View>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	container: {
		alignSelf: 'center',
		width: '100%',
		maxWidth: layout.maxFeedWidth,
	},
})
