import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import NormalIcon from '../../icons/NormalIcon'
import JuniorCreateDialog from './JuniorCreateDialog'
import { txt } from '../../../locales/i18n'
import JuniorSelectDialog from './JuniorSelectDialog'
import { ShadowStyles } from '../../../styles'
import CustomPressable from "../../pressables/CustomPressable"

export default function SproglandJunior({ juniors, selectedJunior, setSelectedJunior, createJuniorProfile, updateJuniorProfile, deleteJuniorProfile, style }) {
  const [showJuniorCreate, setShowJuniorCreate] = useState(false)
  const [showJuniorSelect, setShowJuniorSelect] = useState(false)
  const [juniorEdit, setJuniorEdit] = useState(null)

  function handleJuniorPress() {
    if (selectedJunior) setShowJuniorSelect(true)
    else setShowJuniorCreate(true)
  }

  function handleOpenCreate(junior) {
    setShowJuniorCreate(true)
    if (junior) setJuniorEdit(junior)
  }

  async function handleCreateJunior(junior) {
    const juniorRes = await createJuniorProfile(junior)
    setSelectedJunior(juniorRes)
  }

  function dismissJuniorCreate() {
    setShowJuniorCreate(false)
    setJuniorEdit(null)
  }

  return (
    <View style={style}>
      <CustomText font="largeBold" style={styles.heading}>
        {txt('learnDanish.junior.heading')}
      </CustomText>
      <CustomText style={styles.paragraph}>
        {txt(`learnDanish.junior.${juniors.length ? 'chooseJunior' : 'description'}`)}
      </CustomText>

      <CustomPressable onPress={handleJuniorPress} style={styles.juniorProfile}>
        <NormalIcon
          stroke="fas"
          size={20}
          name={juniors.length ? "user" : "user-plus" }
          style={styles.userIcon}
          color={Colors.grey}
        />
        <CustomText font={juniors.length ? 'bold' : 'medium'} style={{flexGrow: 1}}>
          {selectedJunior?.name || txt('learnDanish.junior.createPlaceholder')}
        </CustomText>
        { selectedJunior &&
          <NormalIcon
            stroke='fas'
            size={20}
            name={ "chevron-down" }
            color={Colors.grey}
          />
        }
      </CustomPressable>

      <JuniorCreateDialog
        visible={showJuniorCreate}
        onDismiss={dismissJuniorCreate}
        createJuniorProfile={handleCreateJunior}
        updateJuniorProfile={updateJuniorProfile}
        deleteJuniorProfile={deleteJuniorProfile}
        juniorEdit={juniorEdit}
      />

      { selectedJunior &&
        <JuniorSelectDialog
          juniors={juniors}
          selected={selectedJunior}
          visible={showJuniorSelect}
          select={setSelectedJunior}
          openCreate={handleOpenCreate}
          onDismiss={() => setShowJuniorSelect(false)}
        />
      }
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    marginTop: 12
  },
  paragraph: {
    marginTop: 12
  },
  juniorProfile: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
    padding: 10,
    paddingHorizontal: 18,
    backgroundColor: Colors.white,
    borderRadius: 2,
    ...ShadowStyles.liftedShadow,
  },
  userIcon: {
    marginRight: 12,
  }
})
