import React from 'react'
import MainContainer from '../../components/MainContainer/MainContainer'
import { Group } from '../../components/views/Groups/Group'

export default function GroupScreen(props) {
  function navigateToConversation(conversationId) {
    props.navigation.navigate('InboxStack', {
      screen: 'Conversation',
      initial: false,
      params: {
        id: conversationId,
      },
    })
  }

  const navigateToNewConversation = (userId) => {
    props.navigation.navigate('InboxStack', {
      screen: 'Conversation',
      initial: false,
      params: {
        id: 'new',
        receiverId: userId,
        groupId: props.route.params.id,
      },
    })
  }

  function navigateToGroups() {
    props.navigation.replace('Groups')
  }

  return (
    <MainContainer noScroll>
      <Group
        navigateToGroups={navigateToGroups}
        navigateToNewConversation={navigateToNewConversation}
        navigateToConversation={navigateToConversation}
      />
    </MainContainer>
  )
}
