import React, { useState, useEffect } from 'react'
import Post from '../../../components/views/Post/Post'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { StackActions } from "@react-navigation/native"

export default function BoblUniverseScreen(props) {
  const [universe, setUniverse] = useState('')

  useEffect(() => {
    let currentUniverse = props.route.params?.universe?.toLowerCase()

    setUniverse(currentUniverse)
  }, [props.navigation, props.route.params])

  const navigateToConversation = (conversationId) => {
    props.navigation.navigate('Conversation', {
      id: conversationId,
      universe,
    })
  }

  const navigateToNewConversation = (item) => {
    props.navigation.navigate('Conversation', {
      id: 'new',
      receiverId: item.user_id,
      boblId: item.id,
      universe,
    })
  }

  const navigateToEditPost = (id) => {
    const action = StackActions.replace('EditPost', { id, universe })
    props.navigation.dispatch(action)
  }

  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <Post
        id={props.route.params.id}
        navigateToEditPost={navigateToEditPost}
        navigateToConversation={navigateToConversation}
        navigateToNewConversation={navigateToNewConversation}
        navigateToBobls={() => props.navigation.navigate('Bobls')}
      />
    </MainContainer>
  )
}
