import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import Colors from '../../../constants/Colors'
import { CustomText } from '../../../components/text/StyledText'
import { URLOpener } from '../../../utils/URLOpener'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import { trackPlausibleEvent } from '../../../services/api/plausible'

export default function Area9() {
	const handleLinkClick = (url, linkIndex) => {
		trackPlausibleEvent('HerlevOfferClick', { origin: `Area9-link${linkIndex}` }, 'herlev/HerlevOffers')
		URLOpener(url)
	}

	return (
		<View style={styles.partnerInfoContainer}>
			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/area9.png' }}
				resizeMode="contain"
				style={[styles.logo, styles.partnerInfoHeader]}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
				accessibilityLabel={txt('universe.herlev.area9')}
			/>
			<CustomText font="largeBold" style={styles.partnerInfoTitle}>
				{txt('universe.herlev.partnerInfo.area9.title')}
			</CustomText>
			<CustomText>{txt('universe.herlev.partnerInfo.area9.body')}</CustomText>
			<CustomText
				style={{ color: Colors.blueAzure, marginBottom: 12 }}
				onPress={() => handleLinkClick('https://eu.rhapsode.com/learner.html?s=wFHTQ91M2EzM0EzXRJDbwFHTQFlMsBXc&lang=da#home&access_code=CXA3U30AJ1E3', 1)}
			>
				{txt('universe.herlev.partnerInfo.area9.linkText1')}
			</CustomText>
			<CustomText
				style={{ color: Colors.blueAzure, marginBottom: 12 }}
				onPress={() => handleLinkClick('https://eu.rhapsode.com/learner.html?s=wFHTQ91M2EzM0EzXRJDbwFHTQFlMsBXc&lang=da#home&access_code=CJVKABXEGDJZ', 2)}
			>
				{txt('universe.herlev.partnerInfo.area9.linkText2')}
			</CustomText>
			<CustomText
				style={{ color: Colors.blueAzure, marginBottom: 12 }}
				onPress={() => handleLinkClick('https://eu.rhapsode.com/learner.html?s=wFHTQ91M2EzM0EzXRJDbwFHTQFlMsBXc&lang=da#home&access_code=C7UJUDBMMAT6', 3)}
			>
				{txt('universe.herlev.partnerInfo.area9.linkText3')}
			</CustomText>
			<CustomText
				style={{ color: Colors.blueAzure, marginBottom: 12 }}
				onPress={() => handleLinkClick('https://eu.rhapsode.com/learner.html?s=wFHTQ91M2EzM0EzXRJDbwFHTQFlMsBXc&lang=da#home&access_code=CX9R53EK3P8P', 4)}
			>
				{txt('universe.herlev.partnerInfo.area9.linkText4')}
			</CustomText>

			<View style={styles.imageContainer}>
				<Image
					source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/area9-1.png' }}
					resizeMode="contain"
					style={[styles.image, { marginRight: Layout.isSmallDevice ? 0 : 6 }]}
					accessibilityIgnoresInvertColors
					accessible
					accessibilityRole="image"
					accessibilityLabel={txt('universe.herlev.area9')}
				/>
				<Image
					source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/area9-2.png' }}
					resizeMode="contain"
					style={[styles.image, { marginLeft: Layout.isSmallDevice ? 0 : 6 }]}
					accessibilityIgnoresInvertColors
					accessible
					accessibilityRole="image"
					accessibilityLabel={txt('universe.herlev.area9')}
				/>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	logo: {
		alignSelf: 'center',
		height: 60,
		marginVertical: 6,
		maxWidth: 300,
		minWidth: 200,
	},
	partnerInfoContainer: {
		alignSelf: 'center',
		flex: 1,
		maxWidth: 600,
		width: '100%',
	},
	partnerInfoHeader: {
		marginBottom: 24,
	},
	partnerInfoTitle: { marginBottom: 12, marginTop: 12 },
	imageContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		paddingTop: 12,
	},
	image: {
		flex: 1,
		alignSelf: 'center',
		height: 200,
		minWidth: 250,
		marginRight: Layout.isSmallDevice ? 0 : 6,
	},
})
