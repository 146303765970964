import { useState, useEffect } from 'react'
import { useRoute } from '@react-navigation/core'

import { getUniverseConfig } from '../universe/universeController'

export const useUniverseConfig = () => {
	const { params } = useRoute()
	const [config, setConfig] = useState(getUniverseConfig(params?.universe) ?? null)

	useEffect(() => {
		const universe = params?.universe ?? null
		const config = getUniverseConfig(universe)
		setConfig(config ?? null)
	}, [params])

	return config
}
