import React from 'react'
import Profile from '../../../components/views/Profile/Profile'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { useNavigation } from '@react-navigation/core'

export default function UniverseProfileScreen(props) {
  const navigation = useNavigation()
  const universeObject = getUniverseConfig(props.route?.params?.universe?.toLowerCase())

  function navigateToProfileView() {
    navigation.navigate('ProfileView', {
      universe: universeObject.name,
    })
  }

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <PageTitle title={TxtTMD.profile.title[TxtTMD.lang]} />
      <Profile navigateToProfileView={navigateToProfileView} universe={universeObject} />
    </MainContainer>
  )
}
