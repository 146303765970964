import Colors from '../../../constants/Colors'
import { StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import React from 'react'
import NormalIcon from '../../icons/NormalIcon'

export default function TextLabel({ style, junior = false, first = false, text, showInfoIcon = false }) {
	return (
		<View
			style={[
				style,
				styles.container,
				{ backgroundColor: junior ? Colors.juniorLabelBackground : first ? Colors.greenLight : Colors.labelBackground },
			]}
		>
			<CustomText
				style={{ color: junior ? Colors.juniorLabelText : first ? Colors.greenText : Colors.labelText }}
				font="small"
			>
				{junior ? txt('learnDanish.junior.juniorLabel') : first ? txt('learnDanish.volunteer.firstSession') : text}
			</CustomText>
			{showInfoIcon && (
				<NormalIcon
					name={'info-circle'}
					size={12}
					color={junior ? Colors.juniorLabelText : first ? Colors.greenText : Colors.labelText}
					style={{ marginLeft: 4 }}
				/>
			)}
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'flex-start',
		alignItems: 'center',
		paddingHorizontal: 6,
		paddingVertical: 4,
		borderRadius: 4,
	},
})
