export default {
  xxl: 28,
  xl: 25,
  l: 20,
  m: 17,
  s: 15,
  xs: 13,

  fontWeightBold: '500',

  roundImage: {
    xl: 400,
  },

  cornerRounding: 12,
}
