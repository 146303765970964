import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { TypographyStyles } from '../../styles'
import { URLOpener } from '../../utils/URLOpener'
import { ButtonPrimary } from '../buttons/StyledButtons'
import CustomDialog, { ContentContainer } from './CustomDialog'
import NormalIcon from '../icons/NormalIcon'
import { CustomText } from '../text/StyledText'
import { getLatestSurveyPrompt, logSurveyPrompt } from '../../services/api/Surveys'
import CustomPressable from "../pressables/CustomPressable"

export const platforms = {
  SnakSammen: 'SnakSammen',
  Sprogland: 'Sprogland',
}

export default function SurveyPromptDialog({ visible, surveyId, surveyLink, onDismiss, volunteer, platform }) {
  let logo
  if (platform === platforms.SnakSammen) logo = require('../../assets/images/snaksammen-logo.png')
  if (platform === platforms.Sprogland) logo = require('../../assets/images/sprogland_dk.png')

  function close() {
    logSurveyPrompt(false, surveyId)
    onDismiss()
  }

  function submit() {
    logSurveyPrompt(true, surveyId)
    URLOpener(surveyLink)
    onDismiss()
  }

  return (
    <CustomDialog visible={visible} style={styles.dialog} onDismiss={close}>
      <ContentContainer>
        <CustomPressable onPress={close}>
          <NormalIcon style={styles.headerContainer} name="times" color={Colors.darkGrey} />
        </CustomPressable>
        <Image
          style={styles.imageContainer}
          resizeMode="contain"
          source={logo}
          accessibilityIgnoresInvertColors
          accessible
          accessibilityRole="image"
        />
        <CustomText style={styles.heading} accessibilityRole={'header'} aria-level={2}>
          {txt(`surveyPrompt.${volunteer ? 'volunteer' : platform + 'User'}.header`, { platform })}
        </CustomText>
        <CustomText>{txt(`surveyPrompt.${volunteer ? 'volunteer' : platform + 'User'}.body`, { platform })}</CustomText>

        <View style={{ paddingBottom: 12 }}>
          <ButtonPrimary
            onPress={submit}
            style={{ backgroundColor: Colors.blueAzure, marginTop: 24 }}
            title={txt('surveyPrompt.participate')}
            titleStyle={{ paddingHorizontal: 12 }}
          />
        </View>
      </ContentContainer>
    </CustomDialog>
  )
}

export async function shouldShowSurveyPrompt(surveyId) {
  const lastSurveyPrompt = await getLatestSurveyPrompt(surveyId)
  if (!lastSurveyPrompt) return true
  const lastTime = Date.parse(lastSurveyPrompt.created)
  const DAY = 1000 * 60 * 60 * 24
  if (surveyId === 'snaksammenUser') {
    return lastTime < Date.now() - DAY
  } else {
    return lastTime < Date.now() - DAY * 3
  }
}

const styles = StyleSheet.create({
  heading: {
    ...TypographyStyles.blogHeader,
    marginTop: 24,
    marginBottom: 8,
  },
  dialog: {
    maxWidth: 400,
  },
  imageContainer: {
    width: 200,
    height: 32,
    alignSelf: 'center',
  },
  headerContainer: {
    alignSelf: 'flex-end',
  },
})
