import React from 'react'
import { View, StyleSheet } from 'react-native'
import moment from 'moment'
import TextLabel from '../../../views/LearnDanish/TextLabel'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import NormalIcon from '../../../icons/NormalIcon'
import Colors from '../../../../constants/Colors'
import { ShadowStyles } from '../../../../styles'

export default function AllSessions(props) {
  //sets locale to danish time
  moment.locale([txt('videoChat.locale')])

  function formatUnixDate(date) {
    var d = moment(date).format('dddd [d.] DD/MM [kl.] HH:mm')
    return d.charAt(0).toUpperCase() + d.substr(1)
  }

  return (
    <View style={styles.container}>
      {!props.sessions?.length ? (
        <View>
          <CustomText font="largeBold" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
            {txt('learnDanish.volunteer.allSessions')}
          </CustomText>
          <CustomText font="small" style={styles.noSession}>{txt('learnDanish.volunteer.noAllSessions')}</CustomText>
        </View>
      ) : (
        <CustomText font="largeBold" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
          {txt('learnDanish.volunteer.allSessions')}
        </CustomText>
      )}

      {props.sessions?.map((item, key) => {
        var date = formatUnixDate(item.start_date)
        return (
          <View key={key} style={[styles.item, item.booked === 1 && { backgroundColor: Colors.greenExtraLight }]}>

            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
                  <NormalIcon
                    name="user-headset"
                    size={18}
                    stroke="fal"
                    style={{ paddingRight: 6 }}
                    color={item.booked === 1 ? Colors.greenIcon : Colors.blackTransparent54}
                  />
                  <CustomText font="smallBold" numberOfLines={1} style={{ flex: 1, flexWrap: 'wrap' }}>
                    {item.username}
                  </CustomText>
                </View>
                {!item.junior ? (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <NormalIcon name="user" size={18} style={{ paddingRight: 6 }}
                                color={item.booked === 1 ? Colors.greenIcon : Colors.blackTransparent54} />
                    <CustomText
                      numberOfLines={1}
                      font={item?.booked_username ? 'small' : 'smallItalic'}
                      style={{ color: Colors.inactive, flex: 1, flexWrap: 'wrap' }}
                    >
                      {item?.booked_username ?? ' - '}
                    </CustomText>
                  </View>
                ) : (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <NormalIcon name="user" size={18} stroke="fal" style={{ paddingRight: 6 }}
                                color={item.booked === 1 ? Colors.greenIcon : Colors.blackTransparent54} />
                    <CustomText numberOfLines={1} font="small">
                      {item.booked === 1 ? (item.juniorName + ' - ' + item.juniorAge + ' ' + txt('learnDanish.junior.juniorAge')) : ' - '}
                    </CustomText>
                  </View>
                )}
              </View>

              <View>
                <View style={[styles.dateTime, { marginBottom: 12 }]}>
                  <CustomText font="small">{date}</CustomText>
                </View>
                <View style={styles.dateTime}>
                  <CustomText font="smallBold">
                    {((new Date(item.end_date).getTime() - new Date(item.start_date).getTime()) / 1000 / 60) + ' min.'}
                  </CustomText>
                </View>
              </View>
            </View>
            {item.categories || item.junior ? (
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 12 }}>
                {item.categories?.map(id => (
                  <TextLabel key={id} text={txt(`learnDanish.categories.${id}`)} style={styles.label} />
                ))}
                {item.junior && <TextLabel junior style={{ marginTop: 6 }} />}
              </View>
            ) : null}
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
    textAlign: 'center',
    backgroundColor: Colors.white,
    borderRadius: 2,
    padding: 10,
    ...ShadowStyles.liftedShadow,
  },
  titleBold: {
    textAlign: 'center',
  },
  label: {
    marginRight: 6,
    marginTop: 6,
  },
  item: {
    backgroundColor: Colors.white,
    borderRadius: 2,
    marginTop: 10,
    padding: 10,
    ...ShadowStyles.liftedShadow,
  },
  dateTime: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})
