import React, { useEffect, useState } from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import EditUnion from '../../../components/views/Union/EditUnion'
import { getUniverseConfig } from '../../../universe/universeController'
import {
  deleteUnion as deleteUnionApi,
  getFullUnion as getFullUnionApi,
  updateUnion as updateUnionApi,
} from '../../../services/api/Unions'

export default function EditUnionUniverseScreen(props) {
  const [universe, setUniverse] = useState(null)
  const universeObject = getUniverseConfig(
    props.route.params.universe?.toLowerCase()
  )

  useEffect(() => {
    let universeName = props.route.params?.universe?.toLowerCase()
    setUniverse(getUniverseConfig(universeName))
  }, [props.navigation, props.route.params])

  async function getFullUnion(id) {
    return await getFullUnionApi(id)
  }

  async function deleteUnion(unionId) {
    return await deleteUnionApi(unionId)
  }

  async function updateUnion(data) {
    data.universe_id = universe.id
    return await updateUnionApi(data)
  }

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <EditUnion
        getFullUnion={getFullUnion}
        deleteUnion={deleteUnion}
        updateUnion={updateUnion}
        navigation={props.navigation}
        id={props.route.params.id}
      />
    </MainContainer>
  )
}
