import React, { useEffect, useState } from 'react'
import Conversation from '../../../components/views/Conversations/Conversation'
import { createConversation } from '../../../services/api/Inbox'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { useSelector } from 'react-redux'
import { conversationMessages } from '../../../redux/selectors'
import {
  confirmMatch,
  getConversationNudge,
  getMatchConfirmations,
  registerConversationNudge,
} from '../../../services/api/ChristmasFriends'
import MatchQuestionDialog from '../../components/match/MatchQuestionDialog'
import PostHiddenDialog from '../../components/match/PostHiddenDialog'
import store from '../../../redux/store'
import AfterMatchDialog from '../../components/match/AfterMatchDialog'
import { setChristmasMatchDialogVisible } from '../../../redux/actions'

export default function ConversationChristmasScreen(props) {
  const myPost = useSelector((state) => state.christmas.post)
  const [showMatchDialog, setShowMatchDialog] = useState(false)
  const [nudgeState, setNudgeState] = useState(null)
  const [confirmedMatch, setConfirmedMatch] = useState(false)
  const [showPostHidden, setShowPostHidden] = useState(false)

  const messages = useSelector((state) =>
    conversationMessages(state, parseInt(props.route.params.id))
  )

  const conversation = useSelector((state) =>
    state.conversations.find(
      (c) => c.conversation_id === parseInt(props.route.params.id)
    )
  )

  function isChristmas() {
    return !!conversation?.christmas_post_id
  }

  useEffect(() => {
    if (isChristmas()) askIfMatch()
  }, [messages])

  async function askIfMatch() {
    if (confirmedMatch) return
    const matches = await getMatchConfirmations()
    const match = matches
      .map((m) => m.postIdReceiver)
      .includes(conversation.christmas_post_id)
    if (match) {
      setConfirmedMatch(true)
      return
    }
    const nudge =
      nudgeState || (await getConversationNudge(props.route.params.id))
    setNudgeState(nudge)
    const userMessages = messages.filter((msg) => !msg.announcement_id)
    if (userMessages.length >= nudge.messages + 5) {
      await registerConversationNudge(
        props.route.params.id,
        userMessages.length
      )
      setNudgeState({ messages: userMessages.length })
      setShowMatchDialog(true)
    }
  }

  async function handleConfirmMatch() {
    setShowMatchDialog(false)
    await confirmMatch(conversation.christmas_post_id)
    if (myPost.type === 'guest') setShowPostHidden(true)
  }

  function handleDismissMatch() {
    setShowMatchDialog(false)
  }

  function navigateToInfo() {
    props.navigation.navigate('ConversationInfo', {
      id: props.route.params.id,
      christmasPostId: conversation?.christmas_post_id,
    })
  }

  const createNewConversation = async () => {
    const { christmasPostIdInitiator, christmasPostIdReceiver, receiverId } =
      props.route.params
    return createConversation({
      receiverId: parseInt(receiverId),
      christmasPostIdInitiator: parseInt(christmasPostIdInitiator),
      christmasPostIdReceiver: parseInt(christmasPostIdReceiver),
    })
  }

  function navigateToProfile(userId) {
    props.navigation.navigate('ProfileView', {
      id: userId,
      christmasPostId: conversation?.christmas_post_id,
    })
  }

  return (
    <MainContainer noScroll>
      <Conversation
        createNewConversation={createNewConversation}
        route={props.route}
        navigation={props.navigation}
        navigateToInfo={navigateToInfo}
        navigateToProfile={navigateToProfile}
      />
      {isChristmas() && (
        <>
          <MatchQuestionDialog
            visible={showMatchDialog}
            confirm={handleConfirmMatch}
            dismiss={handleDismissMatch}
            username={conversation?.otherUsers?.[0]?.username}
          />
          <PostHiddenDialog
            visible={showPostHidden}
            dismiss={() => {
              setShowPostHidden(false)
              store.dispatch(setChristmasMatchDialogVisible(true))
            }}
          />
          <AfterMatchDialog />
        </>
      )}
    </MainContainer>
  )
}
