import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import Fontsizes from '../../../constants/Fontsizes'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import { NotificationSwitch } from '../../switches/StyledSwitches'
import { CustomText } from '../../text/StyledText'
import { useSelector } from 'react-redux'
import { updateUserPreferences } from '../../../services/api/User'
import { useNavigation } from '@react-navigation/core'
import Card from '../../Card'

export default function MessageNotifications() {
  const preferences = useSelector((state) => state.preferences)
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({ title: txt('settings.messages') })
  }, [])

  function handleMailSwitch(value) {
    updateUserPreferences({ message_mail_notification: value })
  }

  function handlePushSwitch(value) {
    updateUserPreferences({ message_push_notification: value })
  }

  return (
    <Card style={styles.container}>
      <View style={[styles.notificationGroup, styles.borderBottom]}>
        <CustomText font="largeBold">{txt('settings.email')}</CustomText>
        <NotificationSwitch
          title={txt('settings.newMsg')}
          value={preferences.message_mail_notification}
          handleChange={handleMailSwitch}
        />
      </View>
      <View style={styles.notificationGroup}>
        <CustomText font="largeBold">{txt('settings.push')}</CustomText>
        <NotificationSwitch
          title={txt('settings.newMsg')}
          value={preferences.message_push_notification}
          handleChange={handlePushSwitch}
        />
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  borderBottom: {
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: 1,
    paddingBottom: 12,
  },
  container: {
    alignSelf: 'center',
    marginTop: 12,
    maxWidth: Layout.maxFeedWidth,
    paddingHorizontal: 12,
    paddingTop: 12,
    width: '100%',
  },
  notificationGroup: {
    marginBottom: 12,
  },
})
