export default {
  // EXAMPLES PATTERNS
  // '^.{8,}$', // min 8 chars
  // '(?=.*\\d)', // number required
  // '(?=.*[A-Z])', // uppercase letters A-Z

  email: [
    '^.{5,}$', // min 5 chars
    '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$', // @ required
  ],
  username: '^.{2,}$', // min 2 chars
  password: '^.{4,}$', // min 4 chars
}
