import React from 'react'
import { useSelector } from 'react-redux'

import AboutScreen from '../../screens/AboutScreens/AboutScreen'
import ContactScreen from '../../screens/AboutScreens/ContactScreen'
import StoriesScreen from '../../screens/Stories/StoriesScreen'
import StoryScreen from '../../screens/Stories/StoryScreen'
import ConditionsScreen from '../../screens/HelpMenu/ConditionsScreen'
import BlogsMenu from '../../components/views/About/BlogsMenu'
import BlogScreen from '../../screens/AboutScreens/BlogScreen'
import ProjectsScreen from '../../screens/AboutScreens/ProjectsScreen'
import ProjectsMenu from '../../components/views/About/ProjectsMenu'
import MunicipalityInfoScreen from '../../screens/AboutScreens/MunicipalityInfoScreen'
import PressScreen from '../../screens/AboutScreens/PressScreen'
import PartnersMenu from '../../components/views/About/PartnersMenu'
import AboutUsScreen from '../../screens/AboutScreens/AboutUsScreen'
import PartnersScreen from '../../screens/AboutScreens/PartnersScreen'
import ArrangementScreen from '../../screens/AboutScreens/ArrangementScreen'
import Arrangements from '../../components/views/About/Arrangements'
import FaellesskabsprisenScreen from '../../screens/AboutScreens/FaellesskabsprisenScreen'
import LearnDanishScreen from '../../screens/LearnDanish/LearnDanish'
import SnakSammenIntroScreen from '../../screens/SnakSammen/SnakSammenIntro'
import IntroUniverseScreen from '../../screens/Universe/IntroUniverse'
import NeighborIntroScreen from '../../screens/Universe/NeighborIntro'
import ChangePasswordScreen from '../../screens/Auth/ChangePasswordScreen'

import { txt } from '../../locales/i18n'
import { createStackNavigator } from '@react-navigation/stack'
import NormalIcon from '../../components/icons/NormalIcon'
import Colors from '../../constants/Colors'
import Fontsizes from '../../constants/Fontsizes'
import { getRegion } from '../../services/localization'
import CustomPressable from "../../components/pressables/CustomPressable"

const Stack = createStackNavigator()

export default function SharedStack() {
  const region = getRegion()
  const user = useSelector((state) => state?.user ?? null)
  const fullAccess = (user === null && region === 'dk') || user?.country === 'Denmark'

  const defaultScreens = [
    <Stack.Screen name="About" component={AboutScreen} options={{ title: txt('about.title') }} key="about" />,
    <Stack.Screen name="Contact" component={ContactScreen} options={{ title: txt('contact.title') }} key="contact" />,

    <Stack.Screen name="Stories" component={StoriesScreen} options={{ title: txt('stories.title') }} key="stories" />,
    <Stack.Screen name="Story" component={StoryScreen} options={{ title: txt('story.title') }} key="story" />,

    <Stack.Screen
      name="Conditions"
      component={ConditionsScreen}
      options={{
        title: txt('conditions.title'),
        headerBackTitle: txt('mainTabNavigator.backTitle'),
      }}
      key="conditions"
    />,

    <Stack.Screen
      name="BlogsMenu"
      component={BlogsMenu}
      options={{
        title: txt('blog.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="blogs menu"
    />,

    <Stack.Screen name="Blogs" component={BlogScreen} options={{ title: txt('blog.title') }} key="blogs" />,

    <Stack.Screen
      name="ProjectsMenu"
      component={ProjectsMenu}
      options={{
        title: txt('projects.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="project menu"
    />,

    <Stack.Screen
      name="Projects"
      component={ProjectsScreen}
      options={{ title: txt('projects.project') }}
      key="projects"
    />,

    <Stack.Screen
      name="MunicipalityInfo"
      component={MunicipalityInfoScreen}
      options={{
        title: txt('MunicipalityInfo.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="municipality info"
    />,

    <Stack.Screen
      name="Press"
      component={PressScreen}
      options={{
        title: txt('press.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="press"
    />,

    <Stack.Screen
      name="PartnersMenu"
      component={PartnersMenu}
      options={{
        title: txt('partners.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="partners menu"
    />,

    <Stack.Screen
      name="Partners"
      component={PartnersScreen}
      options={{ title: txt('partners.partner') }}
      key="partners"
    />,

    <Stack.Screen
      name="AboutUs"
      component={AboutUsScreen}
      options={{
        title: txt('about.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="about us"
    />,

    <Stack.Screen
      name="Arrangements"
      component={Arrangements}
      options={{
        title: txt('arrangements.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="arrangements"
    />,

    <Stack.Screen name="Arrangement" component={ArrangementScreen} options={{ title: txt('arrangements.title') }} key="arrangement" />,

    <Stack.Screen
      name="Faellesskabsprisen"
      component={FaellesskabsprisenScreen}
      options={{
        title: txt('faellesskabsprisen.title'),
        headerBackTitle: txt('about.backTitle'),
      }}
      key="faellesskabsprisen"
    />,

    <Stack.Screen
      name="ChangePassword"
      component={ChangePasswordScreen}
      options={({ navigation }) => ({
        title: txt('profile.changePassword'),
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <CustomPressable onPress={navigation.goBack}>
            <NormalIcon
              color={Colors.blue}
              name="chevron-left"
              size={Fontsizes.m}
              stroke="fas"
              style={{ paddingLeft: 6, paddingRight: 20 }}
            />
          </CustomPressable>
        ),
      })}
      key="change password"
    />,
  ]

  const extendedScreens = [
    <Stack.Screen
      name="LearnDanish"
      component={LearnDanishScreen}
      options={{ title: txt('learnDanish.title') }}
      key="learn danish"
    />,

    <Stack.Screen name="SnakSammen" component={SnakSammenIntroScreen} key="snaksammen" />,
    <Stack.Screen name="IntroUniverse" component={IntroUniverseScreen} key="intro universe" />,

    <Stack.Screen
      name="NeighborIntro"
      component={NeighborIntroScreen}
      options={{
        title: txt('universe.neighborIntro.title'),
        headerBackTitle: txt('universe.neighborIntro.headerBackTitle'),
      }}
      key="neighbor intro"
    />,
  ]

  if (fullAccess) {
    return [...defaultScreens, ...extendedScreens]
  }

  return defaultScreens
}
