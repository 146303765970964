import { useState, useEffect } from 'react'
import { Keyboard } from 'react-native'

export const useKeyboardHeight = () => {
	const [height, setHeight] = useState(0)

	useEffect(() => {
		const handler = (event) => setHeight(event.endCoordinates.height)
		const showKeyboardSubscription = Keyboard.addListener('keyboardDidShow', handler)
		const hideKeyboardSubscription = Keyboard.addListener('keyboardDidHide', () => setHeight(0))

		return () => {
			showKeyboardSubscription.remove()
			hideKeyboardSubscription.remove()
		}
	}, [])

	return height
}
