import * as Notifications from 'expo-notifications'
import { apiDelete, apiPost } from './api/ApiRequest'
import { Platform } from 'react-native'
import * as RootNavigation from '../navigation/RootNavigation'
import { useSelector } from 'react-redux'
import { selectUnreadNotifications } from '../redux/selectors'
import { useEffect } from 'react'
import store from '../redux/store'
import { getConversations } from './api/Inbox'
import { setGlobalDialogAction, updateGroup } from '../redux/actions'
import { Audio } from 'expo-av'
import { promptTypes } from '../components/dialogs/GlobalDialog'
import { getExtraTabs } from "../navigation/MenuConfig"
import Constants from "expo-constants"

let registered = false
let subscriptions = []

export async function registerPushNotifications() {
  if (Platform.OS === 'web') return

  Notifications.setNotificationHandler({
    handleNotification: async () => {
      const notificationBehaviour = {
        shouldShowAlert: false,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }
      return notificationBehaviour
    },
  })

  let permission = await Notifications.getPermissionsAsync()
  if (!permission.granted) {
    permission = await Notifications.requestPermissionsAsync()
  }
  if (!permission.granted) return

  let token = (await Notifications.getExpoPushTokenAsync({ projectId: Constants.expoConfig?.extra?.eas?.projectId })).data

  if (token) {
    await apiPost({ pushToken: token }, 'users/pushTokens')
    registered = true
  }

  // In app notifications
  const notificationReceived = Notifications.addNotificationReceivedListener(
    async (notification) => {
      await playNotificationSound()
      const { data, body, title } = notification.request.content

      // Group message
      if (data.groupID) {
        const id = Number(data.groupID)
        const group = store.getState().groups.find((g) => g.id === id)
        if (group) {
          store.dispatch(updateGroup({ id, unread: group.unread + 1 }))
        }
      }

      // Dialog prompt
      else if (data.prompt) {
        store.dispatch(setGlobalDialogAction({
          visible: true,
          text: body,
          title,
          type: data.type,
          data: data
        }))
      }
    }
  )

  // Open app via push notification
  const responseReceived =
    Notifications.addNotificationResponseReceivedListener(async (res) => {
      const { data } = res.notification.request.content

      // Message
      if (data.conversationId) {
        const id = Number(data.conversationId)
        const conversation = store.getState().conversations.find((c) => c.conversation_id === id)
        if (!conversation) {
          await getConversations()
        }
        RootNavigation.navigate('InboxStack', {
          screen: 'Conversation',
          initial: false,
          params: { id },
        })
      }

      // Group message
      else if (data.groupID) {
        const id = Number(data.groupID)
        RootNavigation.navigate('GroupsStack', {
          screen: 'Group',
          initial: false,
          params: { id, refresh: true },
        })
      }

      // Post
      else if (data.postID && !data.universe) {
        const id = Number(data.postID)
        RootNavigation.navigate('HomeStack', {
          screen: 'Post',
          initial: false,
          params: { id },
        })
      }
			// Events feed
			else if (data.screen === 'Events') {
				RootNavigation.navigate('EventStack', {
					screen: 'Events',
					initial: true,
				})
			}

      // Event
      else if (data.eventID) {
        const id = Number(data.eventID)
        const features = store.getState()?.user?.features
        const joinedGroups = store.getState()?.groups
        const extraTabs = getExtraTabs(features ?? [], joinedGroups?.length ?? 0)
        const stack = extraTabs.includes('events') ? 'EventStack' : 'MenuStack'

        RootNavigation.navigate(stack, {
          screen: 'Event',
          initial: false,
          params: { eventId: id },
        })
      }

      // Universe Post
      else if (data.universe && data.postID) {
        const id = Number(data.postID)
        RootNavigation.navigate('universe', {
          screen: 'UniverseBoblStack',
          params: {
            screen: 'Post',
            params: {
              universe: data.universe,
              id
            },
          },
        })
      }

      // Universe feed
      else if (data.universe && !data.postID) {
        RootNavigation.navigate('universe', { universe: data.universe })
      }

      else if (data.type === promptTypes.CHRISTMAS) {
        RootNavigation.navigate('christmasFriends')
      }
      else if (data.type === promptTypes.NEW_YEAR) {
        RootNavigation.navigate('universe', { universe: 'nytaarsvenner' })
      }
      else if (data.type === promptTypes.NEW_YEAR_SE) {
        RootNavigation.navigate('universe', { universe: 'nyarsvanner' })
      }
    })
  subscriptions.push(notificationReceived, responseReceived)
}

export async function unregisterPushNotifications() {
  if (registered) {
    let token = (await Notifications.getExpoPushTokenAsync({ projectId: Constants.expoConfig?.extra?.eas?.projectId })).data
    if (token) {
      await apiDelete({ pushToken: token }, 'users/pushTokens')
      registered = false
    }
    subscriptions.forEach((subscription) => subscription.remove())
    subscriptions = []
  }
}

export function NotificationsBadgeListener() {
  const badgeCount = useSelector(selectUnreadNotifications)

  useEffect(() => {
    Notifications.setBadgeCountAsync(badgeCount)
  }, [badgeCount])

  return null
}

export async function playNotificationSound() {
  // try {
  //   const { sound } = await Audio.Sound.createAsync(require('../assets/sounds/bobble.mp3'))
  //   sound.playAsync().then(setTimeout(sound.unloadAsync), 1000).catch()
  // } catch (err) {
  //   if (__DEV__) console.error(err)
  // }
}
