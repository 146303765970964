import { apiGet } from './ApiRequest'
import store from '../../redux/store'
import { setMunicipalities } from '../../redux/actions'

export async function getMunicipalityList({ feature, countryId }) {
  const urlQueryParams = {}

  if (feature !== undefined) urlQueryParams.feature = feature
  if (countryId !== undefined) urlQueryParams.countryId = countryId

  const urlQuery = Object.entries(urlQueryParams).map(([key, value]) => {
    return key + '=' + value
  })

  const response = await apiGet('municipalities?' + urlQuery.join('&'))
  return response?.data ?? []
}

export async function getActiveMunicipalities(setData) {
  return apiGet(`municipalities/active`).then((res) => {
    if (!res.data) return
    setData && setData(res.data)
    return res.data
  })
}

export async function getMunicipalityById(municipalityId) {
  const res = await apiGet(`municipalities/${municipalityId}`)
  return res.municipality
}

export async function getMunicipalityUserCountById(municipalityId) {
  if (!municipalityId) return 0
  const res = await apiGet(`municipalities/count/${municipalityId}`)
  return res?.users ?? 0
}

export async function getCountryMunicipalities(countryName) {
  const municipalities = await apiGet('municipalities/country/' + countryName)
  return municipalities
}

export async function getUserMunicipalities() {
  const municipalities = await apiGet('municipalities/user')
  store.dispatch(setMunicipalities(municipalities))
}