import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { CornerStyles, TypographyStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import {
  createMessage,
  deleteMessage,
  getGroupMessageComments,
  getGroupMessageDocuments,
  getGroupMessageImages,
  pinGroupPost,
  unpinGroupPost,
  like,
  removeLike,
} from '../../../services/api/Groups'
import moment from 'moment'

import ScreeningConsentDialog from '../../dialogs/ScreeningConsentDialog'
import { getConversationId, getScreeningConsent } from '../../../services/api/Inbox'
import OptionButton from '../../buttons/OptionButton'
import OptionDialog from '../../dialogs/OptionsDialog'
import Likes from './Likes'
import { getHexColor } from '../../../utils/getHexColor'
import EditPostDialog from './dialogs/EditPostDialog'
import { addLinkToText } from '../../../utils/addLinkToText'
import DeletePostDialog from './dialogs/DeletePostDialog'
import PostPin from './PostPin'
import GroupCommentInput from './GroupCommentInput'
import { GroupPostFileLink } from './GroupPostFileLink'
import GroupPostImages from './GroupPostImages'
import Card from '../../Card'
import store from '../../../redux/store'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupPost({
  post,
  group,
  index,
  scrollToPost,
  refreshMessages,
  removePost,
  child,
  createPostComment,
  navigateToConversation,
  navigateToNewConversation,
}) {
  const [groupState, setGroupState] = useState({
    body: post.body,
    images: [],
    documents: [],
    comments: post?.child_messages || [],
    showImageSlider: false,
    showRemoveDialog: false,
    showEditDialog: false,
    childImage: null,
    showOptionsDialog: false,
    showScreeningDialog: false,
    likes: post.likes || '',
    hasLiked: post.hasLiked,
    isPinned: post.pinned,
    optionsDialogTitle: undefined,
    options: [],
  })
  const [hasMoreComments, setHasMoreComments] = useState(post?.totalComments > 5 || false)
  const [fetchingComments, setFetchingComments] = useState(false)
  const ownUserId = store.getState().user?.id

  moment.locale(txt('videoChat.locale'))

  useEffect(() => {
    getMessageDocuments()
    getMessageImages()
  }, [])

  useEffect(() => {
    const { body, child_messages, likes, hasLiked, pinned } = post
    if (body || child_messages || likes || hasLiked || pinned) {
      setGroupState((prev) => ({ ...prev, body, comments: child_messages ?? [], likes, hasLiked, isPinned: pinned }))
    }
  }, [post])

  function updateState(update) {
    setGroupState((prev) => ({ ...prev, ...update }))
  }

  async function getMessageDocuments() {
    if (!post.child && post.documents > 0) {
      const documents = await getGroupMessageDocuments(group.id, post.id)
      updateState({ documents })
    }
  }

  async function getMessageImages() {
    if (!post.child && post.images > 0) {
      const images = await getGroupMessageImages(group.id, post.id)
      updateState({ images })
    }
  }

  function updatePost(text) {
    if (text) {
      updateState({ body: text })
    }
    toggleEditMessageDialog()
  }

  function toggleRemoveMessageDialog() {
    let { showRemoveDialog } = groupState
    updateState({ showRemoveDialog: !showRemoveDialog, showOptionsDialog: false })
  }

  function toggleEditMessageDialog() {
    let { showEditDialog } = groupState
    updateState({ showEditDialog: !showEditDialog, showOptionsDialog: false })
  }

  const editOption = {
    label: txt('groups.bulletin.edit'),
    hint: txt('groups.bulletin.editHint'),
    icon: "edit",
    action: toggleEditMessageDialog,
  }
  const removeOption = {
    label: txt('groups.bulletin.delete'),
    hint: txt('groups.bulletin.deleteHint'),
    icon: "trash",
    action: toggleRemoveMessageDialog,
    type: 'danger',
  }
  const sendMessageOption = {
    label: txt('groups.bulletin.sendMessage'),
    icon: "comment-lines",
    action: async () => {
      if (!post.user_id) return
      const conversationId = await getConversationId({
        groupId: group.id,
        receiverId: post.user_id,
      })
      if (conversationId) navigateToConversation(conversationId)
      else navigateToNewConversation(post.user_id)
      updateState({ showOptionsDialog: false })
    },
  }

  function isAdmin() {
    if (!group) return false
    const { own_user_rank } = group
    return own_user_rank === 1
  }

  function handleUserClick() {
    if (ownUserId === post.user_id) return
    const options = [sendMessageOption]
    
    updateState({ showOptionsDialog: true, options, optionsDialogTitle: post.username })
  }

  function handleOptionButtonClick() {
    const options = []
    if (ownUserId !== post.user_id) {
      options.push(sendMessageOption)
    }
  
    if (ownUserId === post.user_id || isAdmin()) {
      options.push(editOption, removeOption)
    }

    updateState({ showOptionsDialog: true, options, optionsDialogTitle: undefined })
  }

  async function changePinnedPost() {
    const { isPinned } = groupState
    if (isPinned) {
      await unpinGroupPost(group.id, post.id)
    } else {
      await pinGroupPost(group.id, post.id)
    }
    updateState({ isPinned: !isPinned })

    await refreshMessages()
  }

  async function sendComment(comment) {
    if (!comment) return
    const consentGiven = await getScreeningConsent()

    if (consentGiven === 0) {
      return updateState({ showScreeningDialog: true })
    }

    const postData = {
      groupId: group.id,
      body: comment,
      images: groupState.childImage,
      parentId: post.id,
    }

    const message = await createMessage(postData)

    if (message !== undefined) {
      updateState({ message: '', comments: [...groupState.comments, message] })
    }
  }

  function removeComment(id) {
    const filtered = groupState.comments.filter((item) => item.id !== id)
    updateState({ comments: filtered })
  }

  async function handleDeletePost() {
    const wasDeleted = await deleteMessage(group.id, post.id)
    if (wasDeleted && wasDeleted.status_code >= 200 && wasDeleted.status_code <= 299) {
      await removePost(post.id)
    }
    toggleRemoveMessageDialog()
    await refreshMessages()
  }

  async function getMoreComments() {
    setFetchingComments(true)
    const { comments } = groupState
    const skip = comments.length

    if (hasMoreComments) {
      const res = await getGroupMessageComments(group.id, post.id, skip)
      if (res.comments.length + comments.length === res.totalComments) {
        setHasMoreComments(false)
      }

      res.comments.reverse()
      updateState({ comments: [...res.comments, ...comments] })
    }

    setFetchingComments(false)
  }

  async function toggleLike() {
    let { hasLiked, likes } = groupState
    if (hasLiked === true) {
      await removeLike(group.id, post.id)
      likes--;
    } else {
      await like(group.id, post.id)
      likes++;
    }
    updateState({hasLiked: !hasLiked, likes})
  }

  function ParentPost() {
    return (
      <>
        <View style={styles.postHeader}>
          <CustomPressable onPress={handleUserClick} style={{ flex: 1, flexDirection: 'row', alignItems: 'center', maxWidth: 500 }}>
            <View
              style={[styles.avatarContainer, { backgroundColor: getHexColor(post.user_id) }]}>
              <NormalIcon
                style={styles.avatar}
                accessibilityLabel={txt('fullPost.usernameIcon')}
                name="user"
                size={Fontsizes.s}
                color={Colors.white}
                stroke="fas"
              />
            </View>
            <View style={{ flex: 1 }}>
              <CustomText font="smallBold" style={{ color: Colors.blackTransparent82 }} numberOfLines={1}>
                {post.username}
              </CustomText>
              <CustomText font="small" style={styles.lightText}>
                {moment(post.created).format('DD-MM-YYYY HH:mm')}
              </CustomText>
            </View>
          </CustomPressable>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <PostPin
              isAdmin={isAdmin()}
              isChildPost={child}
              isPinned={groupState.isPinned}
              togglePin={changePinnedPost}
            />
            <OptionButton
              onPress={handleOptionButtonClick}
              size={20}
              hoverColor={Colors.skyBlue}
            />
          </View>
        </View>
        <View style={styles.postBody}>
          <CustomText style={TypographyStyles.text}>{addLinkToText(groupState.body)}</CustomText>
          {groupState.images?.length > 0 && <GroupPostImages images={groupState.images} />}

          {groupState.documents.length !== 0 && (
            <View style={styles.filesContainer}>
              {groupState.documents.map(({ id, name }) => (
                <GroupPostFileLink key={id} id={id} name={name} groupId={group.id} />
              ))}
            </View>
          )}

        </View>
        <Likes
          hasLiked={groupState.hasLiked}
          likes={groupState.likes}
          toggleLike={toggleLike}
          groupId={group.id}
          msgId={post.id}
        />
        <View style={styles.commentSectionLine} />
      </>
    )
  }

  const renderMoreCommentButton = () => {
    if (child || hasMoreComments === false) return null

    return (
      <CustomPressable onPress={getMoreComments} disabled={fetchingComments} style={styles.moreCommentsButton}>
        {fetchingComments === false && (
          <CustomText font="smallBold" style={{ color: Colors.tintColor }}>
            {txt('groups.bulletin.showMoreComments')}
          </CustomText>
        )}
        {fetchingComments === true && <ActivityIndicator color={Colors.tintColor} />}
      </CustomPressable>
    )
  }

  return (
    <Card
      style={[!child ? styles.postContainer : {
        paddingLeft: 20,
        shadowColor: Colors.transparent,
        elevation: 0,
      }, { backgroundColor: group?.theme?.secondary || Colors.white }]}>
      {ParentPost()}
      {renderMoreCommentButton()}
      {groupState.comments?.map((msg) => (
        <GroupPost
          key={msg.id.toString()}
          post={msg}
          child={true}
          group={group}
          removePost={removeComment}
          refreshMessages={refreshMessages}
          createPostComment={createPostComment}
          navigateToConversation={navigateToConversation}
          navigateToNewConversation={navigateToNewConversation}
        />
      ))}

      {!child &&
        <GroupCommentInput
          group={group}
          sendComment={sendComment}
          scrollToPost={() => scrollToPost(index)}
        />
      }

      <ScreeningConsentDialog
        visible={groupState.showScreeningDialog}
        handleHideDialog={() => updateState({ showScreeningDialog: false })}
      />

      <OptionDialog
        visible={groupState.showOptionsDialog}
        onDismiss={() => updateState({ showOptionsDialog: false })}
        options={groupState.options}
        title={groupState.optionsDialogTitle}
      />

      <DeletePostDialog
        visible={groupState.showRemoveDialog}
        dismiss={toggleRemoveMessageDialog}
        parentId={post?.parent_message_id || null}
        deletePost={handleDeletePost}
      />

      <EditPostDialog
        visible={groupState.showEditDialog}
        dismiss={toggleEditMessageDialog}
        post={post}
        updatePost={updatePost}
        groupId={group.id}
      />
    </Card>
  )
}

const styles = StyleSheet.create({
  avatar: {
    alignSelf: 'center',
  },
  avatarContainer: {
    borderRadius: 100,
    opacity: 0.4,
    width: 31,
    height: 31,
    justifyContent: 'center',
    marginRight: 6,
  },
  commentSectionLine: {
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: 1,
    marginBottom: 10,
    opacity: 0.9,
  },
  lightText: {
    color: Colors.inactive,
  },
  postBody: {
    marginVertical: 12,
  },
  postContainer: {
    ...CornerStyles.borderRadiusS,
    flexDirection: 'column',
    marginBottom: 12,
    padding: 10,
  },
  postHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  moreCommentsButton: {
    paddingTop: 10,
    paddingBottom: 14,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'flex-start',
  },
})
