import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { StyleSheet, Text, View } from 'react-native'
import { BoblbergIconPathDuo, BoblbergIconPathLight, BoblbergIconPathSolid } from './BoblbergIcons'

import Fontsizes from '../../constants/Fontsizes'
import Colors from '../../constants/Colors'

// Set name in props when using function. name={whatevericonname}
export default function NormalIcon(props) {
  const { name, stroke, color, size, style, ...other } = props
  let iconType = stroke ? stroke : 'fal'
  if (name !== undefined) {
    return (
      <View style={[{position: 'relative'}, style]} {...other}>
        {!!props.badge &&
          <View style={styles.badge}>
            <Text style={styles.badgeNumber}>{props.badge}</Text>
          </View>
        }
        <FontAwesomeIcon
          color={color || Colors.tintColor}
          icon={[iconType, name]}
          size={size || Fontsizes.xl}
        />
      </View>
    )
  } else {
    return <FontAwesomeIcon icon={'question'} size={40} />
  }
}

export function BoblbergIcon(props) {
  const { name, stroke, color, size, style, ...other } = props
  return (
    <View
      {...other}
      style={[{
        width: size || Fontsizes.xl,
        height: size || Fontsizes.xl,
      }, style]}
    >
      {stroke === 'fas' ? (
        <BoblbergIconPathSolid name={name} color={color} />
			) : stroke === 'fad' ? (
				<BoblbergIconPathDuo name={name} color={color} />
			) : (
        <BoblbergIconPathLight size={size} name={name} color={color} />
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  badge: {
    zIndex: 1,
    position: 'absolute',
    minWidth: 16,
    height: 16,
    paddingHorizontal: 4,
    right: -8,
    top: -8,
    borderRadius: 35,
    backgroundColor: Colors.tintColor,
    justifyContent: 'center',
    alignItems: 'center',

  },
  badgeNumber: {
    color: Colors.white,
    fontSize: 12,
  },
})