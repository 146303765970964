import React, { useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import ChristmasFeedScreen from '../christmasFriends/screens/christmasFeed/ChristmasFeedScreen'
import { txt } from '../locales/i18n'
import {
  Platform,
  StyleSheet,
  Image,
} from 'react-native'
import PostTypeScreenChristmas from '../christmasFriends/screens/post/PostTypeScreenChristmas'
import PostCreateChristmasScreen from '../christmasFriends/screens/post/PostCreateChristmasScreen'
import IntroChristmasScreen from '../christmasFriends/screens/IntroChristmasScreen'
import PostEditChristmasScreen from '../christmasFriends/screens/post/PostEditChristmasScreen'
import FAQScreenChristmas from '../christmasFriends/screens/FAQScreenChristmas'
import AfterSignupChristmasScreen from '../christmasFriends/screens/AfterSignupChristmasScreen'
import AfterMatchChristmasScreen from '../christmasFriends/screens/AfterMatchChristmasScreen'
import ContactChristmasScreen from '../christmasFriends/screens/ContactChristmasScreen'
import NotificationsScreen from '../screens/Settings/NotificationsScreen'
import { useSelector } from 'react-redux'
import { getOwnChristmasPost } from '../services/api/ChristmasFriends'
import PostChristmasScreen from '../christmasFriends/screens/post/PostChristmasScreen'
import TabBarIcon from '../components/icons/TabBarIcon'
import { selectUnreadGroupMessages, selectUnreadMessages } from "../redux/selectors";
import InboxChristmasScreen from '../christmasFriends/screens/conversation/InboxChristmasScreen'
import ConversationChristmasScreen from '../christmasFriends/screens/conversation/ConversationChristmasScreen'
import ConversationInfoChristmasScreen from '../christmasFriends/screens/conversation/ConversationInfoChristmasScreen'
import { stackConfig } from './navigationConfig'
import StackChristmasMenuScreen from '../christmasFriends/screens/StackChristmasMenuScreen'
import SettingsScreen from '../screens/Settings/SettingsScreen'
import ProfileScreen from '../screens/Profile/ProfileScreen'
import Colors from '../constants/Colors'
import MyPostsChristmasScreen from '../christmasFriends/screens/post/MyPostsChristmasScreen'
import ProfileViewScreen from '../screens/Profile/ProfileViewScreen'
import GroupsScreen from '../screens/Groups/GroupsScreen'
import GroupScreen from '../screens/Groups/GroupScreen'

function IntroStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={{ ...stackConfig({}), headerMode: 'float' }}>
      <Stack.Screen
        name="ChristmasIntro"
        component={IntroChristmasScreen}
        options={{
          headerShown: false,
          title: txt('christmasFriends.browserTitle'),
        }}
      />
      <Stack.Screen
        name="ChristmasTypeSelection"
        component={PostTypeScreenChristmas}
        options={{
          headerTitle: '',
          title: txt('christmasFriends.postType.typeTitle'),
        }}
      />
      <Stack.Screen
        name={'ChristmasCreate'}
        component={PostCreateChristmasScreen}
        options={{
          headerTitle: '',
          title: txt('christmasFriends.createEditPost.header'),
        }}
      />
      <Stack.Screen
        name={'ChristmasEdit'}
        component={PostEditChristmasScreen}
        options={{
          headerTitle: '',
          title: txt('christmasFriends.createEditPost.header'),
        }}
      />
    </Stack.Navigator>
  )
}

function tabIcon(focused, iconName, badge) {
  return (
    <TabBarIcon
      name={iconName}
      color={focused ? Colors.greenChristmas : Colors.textLight}
      badge={badge}
      stroke={focused ? 'fas' : 'fal'}
    />
  )
}

function TapNavigator() {
  const conversationsBadge = useSelector(selectUnreadMessages)
  const groupsBadge = useSelector(selectUnreadGroupMessages)
  const Tab = createBottomTabNavigator()
  return (
    <Tab.Navigator
      screenOptions={{
      headerShown: false,
      lazy: Platform.OS === 'web',
      tabBarLabelStyle: { fontFamily: "raleway-semibold" },
      tabBarActiveTintColor: Colors.greenChristmas,
      tabBarInactiveTintColor: Colors.textLight,
      }}
    >
      <Tab.Screen
        name="PostFeedStack"
        component={PostFeedStack}
        options={{
          tabBarLabel: txt('christmasFriends.feed.title'),
          tabBarIcon: ({ focused }) => tabIcon(focused, "hat-santa"),
        }}
      />
      <Tab.Screen
        name="MyPostStack"
        component={MyPostStack}
        options={{
          tabBarLabel: txt('christmasFriends.mine.title'),
          tabBarIcon: ({ focused }) => tabIcon(focused, "user"),
        }}
      />
      <Tab.Screen
        name="ChristmasConversationStack"
        component={ChristmasConversationStack}
        options={{
          tabBarLabel: txt('conversations.title'),
          tabBarIcon: ({ focused }) =>
            tabIcon(
              focused,
              "comment-lines",
              conversationsBadge
            ),
        }}
      />
      <Tab.Screen
        name="ChristmasMenuStack"
        component={ChristmasMenuStack}
        options={{
          tabBarLabel: txt('mainTabNavigator.menuName'),
          tabBarIcon: ({ focused }) =>
            tabIcon(focused, "bars", groupsBadge),
        }}
      />
    </Tab.Navigator>
  )
}

function PostFeedStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={stackConfig({ rightElement: 'Boblberg' })}>
      <Stack.Screen
        name={'ChristmasFeed'}
        component={ChristmasFeedScreen}
        options={{
          title: txt('christmasFriends.feed.title'),
          headerTitle: () => (
            <Image
              style={styles.headerLogo}
              resizeMode="contain"
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/julevenner/julevenner_logo.png',
              }}
              accessibilityIgnoresInvertColors
              accessibilityRole="image"
            />
          ),
        }}
      />
      <Stack.Screen
        name={'ChristmasPost'}
        component={PostChristmasScreen}
        options={{
          headerTitle: '',
          title: txt('christmasFriends.post.title'),
        }}
      />
    </Stack.Navigator>
  )
}

function MyPostStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator
      initialRouteName="MyPostsChristmas"
      screenOptions={stackConfig({ rightElement: 'Boblberg' })}
    >
      <Stack.Screen
        name={'MyPostsChristmas'}
        component={MyPostsChristmasScreen}
        options={{
          headerTitle: txt('christmasFriends.mine.title'),
          title: txt('christmasFriends.mine.myPost'),
        }}
      />
      <Stack.Screen
        name={'ChristmasPostEdit'}
        component={PostEditChristmasScreen}
        options={{
          headerTitle: '',
          title: txt('christmasFriends.createEditPost.header'),
        }}
      />
      <Stack.Screen
        name={'ChristmasPost'}
        component={PostChristmasScreen}
        options={{
          headerTitle: '',
          title: txt('christmasFriends.post.title'),
        }}
      />
    </Stack.Navigator>
  )
}

function ChristmasConversationStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={stackConfig({ rightElement: 'Boblberg' })}>
      <Stack.Screen
        name="Inbox"
        component={InboxChristmasScreen}
        options={{
          title: txt('conversations.title'),
          headerLeft: () => null,
        }}
      />
      <Stack.Screen
        name="Conversation"
        component={ConversationChristmasScreen}
        options={{ title: txt('conversation.title') }}
      />
      <Stack.Screen
        name="ConversationInfo"
        component={ConversationInfoChristmasScreen}
        options={{ title: txt('conversation.info') }}
      />
      <Stack.Screen
        name={'ChristmasPost'}
        component={PostChristmasScreen}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="ProfileView"
        component={ProfileViewScreen}
        options={{ title: txt('profile.title') }}
      />
    </Stack.Navigator>
  )
}

function ChristmasMenuStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={stackConfig({ rightElement: 'Boblberg' })}>
      <Stack.Screen
        name="Menu"
        component={StackChristmasMenuScreen}
        options={{
          title: txt('mainTabNavigator.menuName'),
          headerLeft: () => null,
        }}
      />
      <Stack.Screen
        name="ChristmasFAQ"
        component={FAQScreenChristmas}
        options={{ title: txt('christmasFriends.faq.title') }}
      />
      <Stack.Screen
        name="AfterSignupChristmas"
        component={AfterSignupChristmasScreen}
        options={{ title: txt('christmasFriends.afterSignup.title') }}
      />
      <Stack.Screen
        name="AfterMatchChristmas"
        component={AfterMatchChristmasScreen}
        options={{ title: txt('christmasFriends.afterMatch.title') }}
      />
      <Stack.Screen
        name="Contact"
        component={ContactChristmasScreen}
        options={{ title: txt('christmasFriends.contact.title') }}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ title: txt('profile.title') }}
      />
      <Stack.Screen
        name="ProfileView"
        component={ProfileViewScreen}
        options={{ title: txt('profile.title') }}
      />
      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{ title: txt('settings.title') }}
      />
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{ title: txt('settings.notifications') }}
      />
      <Stack.Screen
        name="Groups"
        component={GroupsScreen}
        options={{ title: txt('groups.title') }}
      />
      <Stack.Screen
        name="Group"
        component={GroupScreen}
        options={{ title: txt('groups.group') }}
      />
    </Stack.Navigator>
  )
}

export default function ChristmasFriendsStack() {
  const user = useSelector((state) => state.user)
  const post = useSelector((state) => state.christmas.post)

  useEffect(() => {
    if (!post && user) {
      getOwnChristmasPost().then()
    }
  }, [user])

  return post ? <TapNavigator /> : <IntroStack />
}

const styles = StyleSheet.create({
  headerLogo: {
    height: 40,
    width: 50,
  }
})
