import Constants from 'expo-constants'
import { CustomText } from '../text/StyledText'
import React from 'react'
import Colors from '../../constants/Colors'
import { StyleSheet } from 'react-native'

export default function Version({ style }) {
  return (
    <CustomText font={'xsmall'} style={[styles.container, style]}>
      Version {Constants.expoConfig.version}
    </CustomText>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    color: Colors.grey,
    paddingBottom: 16,
  },
})
