import * as React from 'react'
import { Switch, StyleSheet, View, Platform } from 'react-native'
import Colors from '../../constants/Colors'
import Fontsizes from '../../constants/Fontsizes'
import { txt } from '../../locales/i18n'
import NormalIcon from '../icons/NormalIcon'
import { CustomText } from '../text/StyledText'

export function CustomSwitch(props) {
  return (
    <Switch
      trackColor={{ true: props.trackColorTrue ?? Colors.blueAzure, false: props.trackColorFalse ?? Colors.blackTransparent }}
      thumbColor={Colors.white}
      activeThumbColor={Colors.white}
      ios_backgroundColor={Colors.blackTransparent}
      style={{
        transform: Platform.OS !== 'web' && [{ scaleX: .8 }, { scaleY: .8 }],
        ...props.style,
      }}
      accessibilityRole="switch"
      accessibilityLabel={props.label}
      {...props}
    />
  )
}

export function NotificationSwitch({ title, value, handleChange, icon }) {
  return (
    <View style={styles.notificationSwitch}>
      <View style={styles.iconTextContainer}>
        {icon && ( <NormalIcon name={icon} size={Fontsizes.l} color={Colors.text} style={styles.iconContainer}/>)}
        <CustomText>{title}</CustomText>
      </View>
      <CustomSwitch
        value={value}
        onValueChange={handleChange}
        accessibilityLabel={
          value
            ? txt('settings.notificationOnLabel')
            : txt('settings.notificationOffLabel')
        }
        accessibilityHint={txt('settings.notificationHint')}
        accessibilityRole="switch"
      />
    </View>
  )
}

export function SnakSammenSwitch({ title, value, handleChange }) {
  return (
    <View style={styles.notificationSwitch}>
      <CustomText font="smallBold">{title}</CustomText>
      <View style={{ paddingVertical: 3 }}>
        <CustomSwitch
          value={value}
          onValueChange={handleChange}
          accessibilityLabel={
            value
              ? txt('settings.notificationOnLabel')
              : txt('settings.notificationOffLabel')
          }
          accessibilityHint={txt('settings.notificationHint')}
          accessibilityRole="switch"
          trackColorTrue={Colors.greenBtn}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    marginRight: 6,
    width: Fontsizes.xl,
  },
  iconTextContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationSwitch: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 6,
  },
})
