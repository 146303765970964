import { Platform, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import React, { useState } from 'react'
import CommunitySlider from '@react-native-community/slider'
import { CustomText } from "../text/StyledText"
import CustomPressable from "../pressables/CustomPressable"

export default function Slider(props) {
  return (
    <CommunitySlider
      tapToSeek={true}
      thumbTintColor={Platform.OS !== 'ios'? Colors.blue : undefined}
      minimumTrackTintColor={Colors.blue}
      maximumTrackTintColor={Platform.OS !== 'ios'? Colors.greyLight : undefined}
      style={{flexGrow: 1, height: 40}}
      {...props}
    />

  )
}

export function CustomSlider(props) {
  const [value, setValue] = useState(props.option ?? 0)
  const [temporaryValue, setTemporaryValue] = useState(props.option ?? 0)

  const numberList = Array.from({ length: props.maxValue - props.minValue + 1 }, (_, index) => props.minValue + index);

  function changeValue(val) {
    setValue(val)
    props.setOption(val)
  }

  return (
    <View>
      <CommunitySlider
        style={{ height: 40, marginHorizontal: Platform.OS === 'web' ? 0 : 0 }}
        value={value}
        thumbTintColor={Platform.OS !== 'ios' ? Colors.blueAzure : undefined}
        onSlidingComplete={(val) => changeValue(val)}
        onValueChange={(item) => setTemporaryValue(item)}
        minimumValue={props.minValue ?? 0}
        maximumValue={props.maxValue ?? 0}
        tapToSeek={true}
        minimumTrackTintColor={Colors.blueAzure}
        maximumTrackTintColor={Colors.greyLight}
        {...props}
      />
      <View style={styles.container}>
        {numberList?.map((number) => (
          <CustomPressable
            key={number}
            onPress={() => changeValue(number)}
            style={{ width: Platform.OS === 'web' ? 20 : 30, justifyContent: 'center' }}
          >
            <CustomText style={number === Number(temporaryValue) ? styles.highlightedText : styles.normalText}>
              {number}
            </CustomText>
          </CustomPressable>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  normalText: {
    fontSize: 14,
    color: Colors.darkGrey,
    fontFamily: 'gotham-book',
    textAlign: 'center',
  },
  highlightedText: {
    fontSize: 14,
    color: Colors.blueAzure,
    fontFamily: 'gotham-bold',
    textAlign: 'center',
  },
})