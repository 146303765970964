import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Bubble } from 'react-native-gifted-chat'
import * as WebBrowser from 'expo-web-browser'

import { CustomText } from '../../../text/StyledText'
import Colors from '../../../../constants/Colors'
import { getUniverseConfig } from '../../../../universe/universeController'
import { getAttachmentTempLink } from '../../../../services/api/Inbox'

import NormalIcon from '../../../icons/NormalIcon'
import Fontsizes from '../../../../constants/Fontsizes'
import { txt } from '../../../../locales/i18n'
import CustomPressable from "../../../pressables/CustomPressable"

export const ChatBubble = (props) => {
  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())
  const colorLeft = universeObject?.leftChatBubbleBackground
  const colorRight = universeObject?.rightChatBubbleBackground
  const isOwnMessage = props.user._id === props.currentMessage.user_id

  if (props.currentMessage.removed) {
    return (
      <Bubble
        {...props}
        renderUsernameOnMessage={false}
        textStyle={{ right: styles.deletedText, left: styles.deletedText }}
        wrapperStyle={{ right: styles.deletedWrapper, left: styles.deletedWrapper }}
      />
    )
  }

  const handleOpenAttachment = async (attachmentId) => {
    const conversationId = props.route.params.id
    const attachmentLink = await getAttachmentTempLink(conversationId, attachmentId)
    if (attachmentLink === null) return
    await WebBrowser.openBrowserAsync(attachmentLink)
  }

  const renderFileAttachment = (attachment) => {
    return (
      <View key={attachment.id} style={{ flex: 1 }}>
        <CustomPressable
          accessibilityRole="button"
          accessibilityLabel={txt('conversation.openAttachment')}
          accessibilityHint={txt('conversation.openAttachmentHint')}
          onPress={() => handleOpenAttachment(attachment.id)}
          style={styles.attachmentButton}
        >
          <NormalIcon
            size={Fontsizes.s}
            name="arrow-to-bottom"
            color={Colors.blueAzure}
            stroke="fad"
          />

          <CustomText
            ellipsizeMode="middle"
            numberOfLines={1}
            style={{ marginLeft: 4, maxWidth: 128, color: Colors.blueAzure }}
            key={attachment.id}
            font="xsmallBold"
          >
            {attachment.name === 'image' ? txt('conversation.image') : attachment.name}.{attachment.extension}
          </CustomText>
        </CustomPressable>
      </View>
    )
  }

  return (
    <View style={{ maxWidth: '100%' }}>
      <View style={{ alignItems: isOwnMessage ? 'flex-end' : 'flex-start', marginTop: 12 }}>
        {props.currentMessage?.attachments?.map(renderFileAttachment)}
      </View>

      <Bubble
        {...props}
        renderCustomView={() => (props.showOptionsButton ? props.renderOptionsButton(props) : null)}
        wrapperStyle={{
          right: [styles.rightWrapper, { backgroundColor: colorRight || Colors.blue }],
          left: [styles.leftWrapper, { backgroundColor: colorLeft || Colors.white }],
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  deletedWrapper: {
    backgroundColor: Colors.transparent,
    borderColor: Colors.blackTransparent,
    borderWidth: 1,
    paddingBottom: 2,
  },

  leftWrapper: {
    flex: Platform.OS === 'web' ? 1 : 0,
  },

  rightWrapper: {
    flex: Platform.OS === 'web' ? 1 : 0,
    minWidth: 60,
    margin: 0,
  },

  deletedText: {
    color: Colors.grey,
  },

  attachmentButton: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginHorizontal: 'auto',
    marginBottom: 6,
    padding: 8,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: Colors.blueAzure,
    backgroundColor: Colors.blueAzurePale,
  },
})
