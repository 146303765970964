import React from 'react'
import { CustomText } from '../../../components/text/StyledText'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import layout from '../../../constants/Layout'
import ImgHighlightChristmas from '../../components/ImgHighlightChristmas'
import { useNavigation } from '@react-navigation/core'
import { txt } from '../../../locales/i18n'
import Card from '../../../components/Card'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function ChristmasPostItem({ post, rightElement }) {
  const navigation = useNavigation()

  function handlePress() {
    navigation.navigate('ChristmasPost', {
      id: post.id,
    })
  }

  return (
    <Card style={{marginHorizontal: 12, marginVertical: 6, opacity: post.status < 1 ? 0.5 : 1}}>
      <CustomPressable onPress={handlePress} style={styles.container}>
        <ImgHighlightChristmas type={post.type} imgFilename={post.imgFilename} />
        <View style={{ flex: 1 }}>
          <View style={styles.textContainer}>
            <CustomText numberOfLines={2} font="bold">
              {post.title}
            </CustomText>
            <CustomText numberOfLines={2} style={styles.body}>
              {post.body}
            </CustomText>
          </View>

          {post.distance !== null && post.distance !== undefined && (
            <View style={{ alignItems: 'flex-end' }}>
              <CustomText style={styles.distanceStyle} font={'small'}>
                {post.distance === 0
                  ? txt('christmasFriends.feed.inMunicipality')
                  : txt('christmasFriends.feed.distance', {
                    distance: post.distance,
                  })}
              </CustomText>
            </View>
          )}
        </View>
        {rightElement?.()}
      </CustomPressable>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: layout.maxFeedWidth,
    padding: 12,
  },
  distanceStyle: {
    color: Colors.greenChristmas,
    marginHorizontal: 3,
    marginTop: 6,
    paddingHorizontal: 6,
    paddingRight: 6,
  },
  textContainer: {
    marginLeft: 12,
    justifyContent: 'center',
    flex: 1,
  },
  body: {
    marginTop: 6,
  },
})
