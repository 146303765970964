import React, { useState, useEffect } from 'react'
import { View, ScrollView, Platform, StyleSheet, TouchableOpacity, Keyboard } from 'react-native'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import { RoundBtn, DeleteBtn } from '../../buttons/StyledButtons'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import { CustomInputs, CustomMultilineInputs } from '../../text/StyledTextinputs'
import { Progress } from '../../progressbars/ProgressBar'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import ImagePicker from '../../ImagePicker/ImagePicker'
import { ShadowStyles, SpacingStyles } from '../../../styles'
import { NormalSnackbar } from '../../snackbars/Snackbar'
import Paths from '../../../constants/Paths'
import { UploadImage } from '../../../services/api/UploadImage'
import { getActiveMunicipalities } from '../../../services/api/Municipalities'
import CustomDialog, { ActionButton, ContentContainer } from '../../dialogs/CustomDialog'
import { badWords } from '../../../constants/BadWords'
import { CustomSwitch } from '../../switches/StyledSwitches'
import Fontsizes from '../../../constants/Fontsizes'
import DeletePostDialog from './Dialogs/DeletePostDialog'
import VisibilityInfo from './Dialogs/VisibilityInfo'
import ChangeVisibilityDialog from './Dialogs/ChangeVisibilityDialog'
import { RadioButtonOutlined } from '../../formElements/formElements'
import { useSelector } from 'react-redux'
import imageBobl from '../../../assets/images/bobl.png'
import { WeakPostDialog } from './Dialogs/WeakPostDialog'
import { useNavigation } from '@react-navigation/core'
import { getSubscription } from '../../../services/api/User'
import { getOwnPosts, hideAllButNewestPost } from "../../../services/api/Posts"
import store from "../../../redux/store"
import { UpdateNotAllowedDialog } from "./Dialogs/UpdateNotAllowedDialog"
import { showSubscriptionDialogAtom, showTooManyActivePostsDialogAtom } from "../../../atoms"
import { useSetAtom } from "jotai"
import moment from "moment"

const getShowSubscriptionDialog = async (userMunicipalityId) => {
  const activeMunicipalities = await getActiveMunicipalities()
  const isActiveMunicipality = activeMunicipalities.some((municipality) => municipality.id === userMunicipalityId)
  if (isActiveMunicipality) return false

  const subscription = await getSubscription()
  if (subscription === null || subscription?.state === 'expired') return true

  if (subscription.state !== 'active') return true
  if (subscription.pending_invoices !== 0) return true
  if (subscription.dunning_invoices !== 0) return true

  return false
}

const isExpirationDateWithinThreshold = async (threshold) => {
  const subscription = await getSubscription()
  if (!subscription || subscription?.state !== 'expired') return false
  const expiredDate = moment(subscription?.expired_date)
  const diff = moment().diff(expiredDate, 'days')
  return diff <= threshold
}

const isSubscriptionActive = (subscription) => {
  if (!subscription) return false
  if (subscription.state !== 'active') return false
  if (subscription.pending_invoices !== 0) return false
  if (subscription.dunning_invoices !== 0) return false
  return true
}

const getShowTooManyActivePostsDialog = async () => {
  const features = store.getState().user?.features
  if (features.includes(1)) return false // User has feature 1

  const subscription = await getSubscription()
  const activeSubscription = isSubscriptionActive(subscription)
  if (activeSubscription) return false // User has active subscription

  const ownPosts = await getOwnPosts()
  const numberOfActivePosts = ownPosts.reduce((accumulator, currentVal) => {
    if (currentVal.status_id === 1 && currentVal.universeId === null) accumulator += 1;
    return accumulator;
  }, 0)
  if (numberOfActivePosts <= 1) return false // User has 1 or less active post

  return true
}

export default function CreateEditPost(props) {
  const user = useSelector((state) => state.user)
  const ownGender = useSelector((state) => state.user?.gender_id)
  const [image, setImage] = useState(null)
  const [post, setPost] = useState({
    item: null,
    inputs: ['', '', ''], //[title, description, location]
    inputMaxChars: [10, 200, 2],
    inputWeightPercentage: [15, 40, 5],
    isImage: false,
    progressPoints: 0,
    progressText: txt('createPost.progressBarLowText'),
    statusId: null,
    img_id: null,
    tempImage: null,
    rotation: null,
    uniqueValue: 1,
    genderResponse: 1,
    maleContact: 0,
    femaleContact: 0,
    otherContact: 0,
  })
  const [feedback, setFeedback] = useState({
    showCustomDialog: false,
    postWasNotCreated: false,
    tooManyPosts: false,
    postTooShort: false,
    isButtonDisabled: false,
  })
  const [loading, setLoading] = useState(false)
  const [badWordDialog, setBadWordDialog] = useState({
    showCustomDialog: false,
    badWordChecked: false,
  })
  const [visibilityDialogVisible, setVisibilityDialogVisible] = useState(false)
  const [weakPostDialogVisible, setWeakPostDialogVisible] = useState(false)
  const [weakPost, setWeakPost] = useState(false)
  const [weakText, setWeakText] = useState(false)
  const [noImage, setNoImage] = useState(false)
  const [saveChangesDialog, setSaveChangesDialog] = useState(false)
  const [editedPost, setEditedPost] = useState(null)
  const [tempData, setTempData] = useState(null)
  const [notAllowedDialog, setNotAllowedDialog] = useState(false)
  const navigation = useNavigation()
  const setShowSubscription = useSetAtom(showSubscriptionDialogAtom)
  const setTooManyActivePosts = useSetAtom(showTooManyActivePostsDialogAtom)

  const shortPostLength = 150

  useEffect(() => {
    if (ownGender === 0) updatePost({ genderResponse: 0 })
    if (props.getPost) {
      props.getPost().then((data) => {
        const uri = data.img_filename ? Paths.imageUrlBase + data.img_filename : null

        updatePost({
          item: { ...data, uri },
          isImage: !!data.img_id,
          img_id: data.img_id,
          inputs: [data.title, data.body, data.location],
          statusId: data.statusId,
          maleContact: data.maleContact,
          femaleContact: data.femaleContact,
          otherContact: data.otherContact,
          genderResponse: data.maleContact === data.femaleContact && data.maleContact === data.otherContact ? 0 : 1,
        })
      })
    }
  }, [props.route, ownGender])

  useEffect(() => {
    let sum = 0
    for (let i = 0; i < post.inputs.length; i++) {
      if (post.inputs[i].length >= post.inputMaxChars[i]) {
        sum += post.inputWeightPercentage[i]
        continue
      }

      sum += (post.inputs[i].length / post.inputMaxChars[i]) * post.inputWeightPercentage[i]
    }
    if (image?.uri || post.img_id) {
      sum += 40
    }
    if (sum <= 40) {
      updatePost({ progressText: txt('createPost.progressBarLowText') })
    } else if (sum <= 80) {
      updatePost({ progressText: txt('createPost.progressBarMediumText') })
    } else {
      updatePost({ progressText: txt('createPost.progressBarHighText') })
    }
    updatePost({ progressPoints: sum / 100 })
  }, [image?.uri, post.inputMaxChars, post.inputWeightPercentage, post.inputs])

  function updatePost(values) {
    setPost((preInfo) => ({ ...preInfo, ...values }))
  }

  function updateFeedback(values) {
    setFeedback((preFeedback) => ({ ...preFeedback, ...values }))
  }

  function changeText(value, input) {
    switch (input) {
      case 0:
        setPost((preInfo) => ({ ...preInfo, inputs: [value, preInfo.inputs[1], preInfo.inputs[2]] }))
        break
      case 1:
        setPost((preInfo) => ({ ...preInfo, inputs: [preInfo.inputs[0], value, preInfo.inputs[2]] }))
        break
      case 2:
        setPost((preInfo) => ({ ...preInfo, inputs: [preInfo.inputs[0], preInfo.inputs[1], value] }))
        break
    }
  }

  function checkToBeCleared() {
    if (post.inputs[0].length >= 4 && post.inputs[1].length >= 4) {
      updatePost({
        inputs: ['', '', ''],
        tempImage: null,
        uniqueValue: post.uniqueValue + 1,
        progressPoints: 0,
        genderResponse: 1,
      })
      updateFeedback({ isButtonDisabled: false })
      setImage(null)
    }
  }

  function handleContactGender() {
    if (post.genderResponse === 1) {
      if (ownGender === 1) return { maleContact: true, femaleContact: false, otherContact: false }
      if (ownGender === 2) return { maleContact: false, femaleContact: true, otherContact: false }
      if (ownGender === 3) return { maleContact: false, femaleContact: false, otherContact: true }
    }
    return { maleContact: true, femaleContact: true, otherContact: true }
  }

  async function preparePost() {
    if (post.inputs[0].length < 5 || post.inputs[1].length < 5) {
      return updateFeedback({ postTooShort: true })
    }

    updateFeedback({ isButtonDisabled: true })
    setLoading(true)
    const { maleContact, femaleContact, otherContact } = handleContactGender()

    const data = {
      title: post.inputs[0],
      body: post.inputs[1],
      location: post.inputs[2],
      imgId: post.img_id,
      maleContact,
      femaleContact,
      otherContact,
    }

    if (image) {
      data.imgId = await UploadImage(image)
    }

		if (props.editPost) {
			const previousPost = await props.getPost()

			const previousPostData = [
				previousPost.title,
				previousPost.body,
				previousPost.location,
				previousPost.img_id,
				previousPost.maleContact,
				previousPost.femaleContact,
				previousPost.otherContact,
				previousPost.statusId,
			]

			const currentPostData = [
				data.title,
				data.body,
				data.location,
				data.imgId,
				data.maleContact,
				data.femaleContact,
				data.otherContact,
				post.statusId,
			]

      // Check if user has an expired subscription within the last 7 days
      const isWithinThreshold = await isExpirationDateWithinThreshold(7)
      if (isWithinThreshold) {
        setLoading(false)
        return setNotAllowedDialog(isWithinThreshold)
      }

			// no changes detected
			if (currentPostData.toString() === previousPostData.toString()) {
				setLoading(false)
				const params = { chip: txt('posts.chipParams.mine') }

        const hasActivePosts = await getShowTooManyActivePostsDialog()
        if (hasActivePosts) setTooManyActivePosts(hasActivePosts)

        if (!hasActivePosts) {
          const showSubscriptionDialog = await getShowSubscriptionDialog(user.municipality_id)
          if (showSubscriptionDialog) setShowSubscription(showSubscriptionDialog)
        }

				if (props.route?.params?.universe) {
					const navigationOptions = { screen: 'Bobls', initial: true, params }
					return navigation.navigate('UniverseBoblStack', navigationOptions)
				}

				const navigationOptions = { screen: 'Home', initial: true, params }
				return navigation.navigate('HomeStack', navigationOptions)
			}

			const postIsHidden = post.statusId === -1

			if (postIsHidden) {
				setEditedPost(data)
				return setSaveChangesDialog(true)
			}

			data.statusId = post.statusId === 1 ? 1 : -1
		}

    setTempData(data)
    if (!image && !data.imgId && post.inputs[1].length < shortPostLength) {
      setWeakPost(true)
      return setWeakPostDialogVisible(true)
    }
    if (!image && !data.imgId) {
      setNoImage(true)
      return setWeakPostDialogVisible(true)
    }
    if (post.inputs[1].length < shortPostLength) {
      setWeakText(true)
      return setWeakPostDialogVisible(true)
    }
    await setPostObject(data)
  }

  async function handleCreateWeakPost() {
    setWeakPostDialogVisible(false)
    setWeakText(false)
    setNoImage(false)
    setWeakPost(false)
    await setPostObject(tempData)
  }

  async function handleRewritePost() {
    setLoading(false)
    updateFeedback({ isButtonDisabled: false })
    setWeakPostDialogVisible(false)
    setWeakText(false)
    setNoImage(false)
    setWeakPost(false)
  }

  async function handleVisibilityChange(statusId) {
    const data = editedPost
    data.statusId = statusId
    setSaveChangesDialog(false)
    await setPostObject(data)
  }

  const setPostObject = async (data) => {
    setLoading(false)
    const postObject = props.createPost ? await props.createPost(data) : await props.editPost(post.item.id, data)

    if (postObject?.status === 403) {
      return updateFeedback({ tooManyPosts: true, isButtonDisabled: false })
    }
    if (postObject?.status === 429) {
      return setNotAllowedDialog(true)
    }

    checkToBeCleared()
    const params = { chip: txt('posts.chipParams.mine') }

    const hasActivePosts = await getShowTooManyActivePostsDialog()
    if (hasActivePosts) setTooManyActivePosts(hasActivePosts)

    if (!hasActivePosts) {
      const showSubscriptionDialog = await getShowSubscriptionDialog(user.municipality_id)
      if (showSubscriptionDialog) setShowSubscription(showSubscriptionDialog)
    }

    if (hasActivePosts) {
      await hideAllButNewestPost()
    }

		// Navigate to "My posts" in universe, if post was created within a universe
		if (props.route?.params.universe) {
			return navigation.navigate('UniverseBoblStack', { screen: 'Bobls', initial: true, params })
		}

    navigation.navigate('HomeStack', { screen: 'Home', initial: true, params })
  }

  function keyboardSpacerShow() {
    if (Platform.OS === 'ios') return <KeyboardSpacer />
  }

  function BadWordPopup() {
    return (
      <CustomDialog visible={badWordDialog.showCustomDialog}>
        <ContentContainer>
          <CustomText>{txt('createPost.badWordCheck')}</CustomText>
        </ContentContainer>
        <ActionButton onPress={() => setBadWordDialog({ showCustomDialog: false, badWordChecked: true })}>
          {txt('createPost.accept')}
        </ActionButton>
      </CustomDialog>
    )
  }

  function checkBadWords(checkInput) {
    if (!badWordDialog.badWordChecked) {
      badWords.forEach((word) => {
        if (post.inputs[checkInput].toLowerCase().includes(word)) {
          setBadWordDialog((prev) => ({ ...prev, showCustomDialog: true }))
        }
      })
    }
  }

	async function handleDeletePost() {
		await props.deletePost(post.item.id)
		updateFeedback({ showCustomDialog: false })

		if (props.route?.params.universe) {
			return navigation.navigate('UniverseBoblStack', {
				screen: 'Bobls',
				initial: true,
				params: { chip: txt('posts.chipParams.mine') }
			})
		}

		navigation.navigate('HomeStack', {
			screen: 'Home',
			initial: true,
			params: { chip: txt('posts.chipParams.mine') },
		})
	}

  return (
    <View style={[styles.container, { ...props.style }]}>
      {props.renderChips?.()}
      <ScrollView keyboardShouldPersistTaps={'handled'}>
        <View style={styles.margin12} key={post.uniqueValue}>
          <View style={styles.imagePickerContainer}>
            <ImagePicker
              editMode
              setImage={setImage}
              defaultImage={post?.item?.uri ?? null}
              onDelete={() => updatePost({ img_id: null })}
              placeholder={imageBobl}
            />
          </View>

          <View style={styles.marginBottom}>
            <CustomText style={{ marginBottom: 6 }}>{txt('createPost.titlePlaceHolder')}</CustomText>
            <CustomInputs
              accessibilityLabel={txt('createPost.titlePlaceHolder')}
              accessibilityHint={txt('createPost.titleHint')}
              maxLength={35}
              value={post.inputs[0]}
              onChangeText={(value) => changeText(value, 0)}
              onBlur={() => checkBadWords(0)}
            >
              <View style={{ justifyContent: 'center', height: '100%' }}>
                <CustomText font="small" style={styles.inactive}>
                  {35 - post.inputs[0].length}
                </CustomText>
              </View>
            </CustomInputs>
          </View>
          <View style={styles.marginBottom}>
            <CustomText style={{ marginBottom: 6 }}>{txt('createPost.description')}</CustomText>
            <CustomMultilineInputs
              accessibilityLabel={txt('createPost.descriptionPlaceHolder')}
              accessibilityHint={txt('createPost.descriptionHint')}
              placeholder={txt('createPost.descriptionPlaceHolder')}
              value={post.inputs[1]}
              onChangeText={(value) => changeText(value, 1)}
              onBlur={() => checkBadWords(1)}
            />
          </View>
          <View style={styles.marginBottom}>
            <CustomText style={{ marginBottom: 6 }}>{txt('createPost.locationPlaceHolder')}</CustomText>
            <CustomInputs
              accessibilityLabel={txt('createPost.locationPlaceHolder')}
              accessibilityHint={txt('createPost.locationHint')}
              value={post.inputs[2]}
              onChangeText={(value) => changeText(value, 2)}
            />
          </View>

          <View style={styles.marginBottom}>
            <CustomText style={{ marginBottom: 6 }}>
              {txt('createPost.genderResponse')}
            </CustomText>
            {ownGender === 0 && (
              <CustomText font="smallItalic" style={{ marginBottom: 6 }}>
                {txt('createPost.genderResponseDisabled')}
              </CustomText>
            )}
            <View
              style={[styles.genderResponseContainer, { backgroundColor: ownGender === 0 ? Colors.greyLightLight : Colors.white }]}>
              <RadioButtonOutlined
                checked={post.genderResponse === 0}
                onPress={() => updatePost({ genderResponse: 0 })}
                size={10}
                border={2}
                text={txt('createPost.genderAll')}
                color={Colors.tintColor}
                disabled={ownGender === 0}
                label={post.genderResponse === 0 ? txt('radioButton.selected') + txt('createPost.genderAll') : txt('createPost.genderAll')}
                style={{ paddingVertical: 6, marginRight: 12 }}
              />
              <RadioButtonOutlined
                checked={post.genderResponse === 1}
                onPress={() => updatePost({ genderResponse: 1 })}
                size={10}
                border={2}
                text={txt('createPost.genderOwn')}
                color={Colors.tintColor}
                disabled={ownGender === 0}
                label={post.genderResponse === 1 ? txt('radioButton.selected') + txt('createPost.genderOwn') : txt('createPost.genderOwn')}
                style={{ paddingVertical: 6 }}
              />
            </View>
          </View>

          {props.editPost && (
            <View style={styles.marginBottom}>
              <View style={styles.visibilityTitleContainer}>
                <CustomText>{txt('post.edit.visibility')}</CustomText>
                <TouchableOpacity
                  onPress={() => setVisibilityDialogVisible(true)}
                  accessibilityRole="button"
                  accessibilityLabel={txt('post.edit.visibilityReadMore')}
                  style={{ paddingHorizontal: 6 }}
                >
                  <NormalIcon name="info-circle" size={Fontsizes.s} />
                </TouchableOpacity>
              </View>
              <View style={styles.visibilityContainer}>
                <CustomText>{post?.statusId > 0 ? txt('post.edit.visible') : txt('post.edit.hidden')}</CustomText>
                <CustomSwitch
                  accessibilityLabel={post?.statusId > 0 ? txt('post.edit.visible') : txt('post.edit.hidden')}
                  value={post?.statusId > 0}
                  onValueChange={(item) => updatePost({ statusId: item === true ? 1 : 0 })}
                />
              </View>
            </View>
          )}

          <Progress progress={post.progressPoints} style={{ marginTop: 12 }} />
          <View style={[styles.center, { marginBottom: 24 }]}>
            <CustomText font="smallBold">
              {Math.round(post.progressPoints * 100) + '% '}
              {post.progressText}
            </CustomText>
          </View>

          <TouchableOpacity
            style={{ width: 1, height: 1 }}
            accessibilityLabel={txt('accessibility.closeKeyboard')}
            accessibilityRole="button"
            onPress={Keyboard.dismiss}
          />

          {props.createPost && (
            <View style={[styles.marginBottom, styles.center]}>
              <RoundBtn
                title={txt('createPost.createPostButtonText')}
                disabled={feedback.isButtonDisabled || loading}
                onPress={preparePost}
              />
            </View>
          )}
          {props.editPost && (
            <View style={styles.buttonsContainer}>
              <DeleteBtn title={txt('post.deletePost')} onPress={() => updateFeedback({ showCustomDialog: true })} />
              <RoundBtn title={txt('post.savePost')} disabled={loading} onPress={preparePost}>
                {feedback.postSaved ? 'Hide' : 'Show'}
              </RoundBtn>
            </View>
          )}
        </View>
        {keyboardSpacerShow()}
      </ScrollView>
      <NormalSnackbar
        visible={feedback.postWasNotCreated}
        onDismiss={() => updateFeedback({ postWasNotCreated: false })}
      >
        {txt('createPost.error')}
      </NormalSnackbar>
      <NormalSnackbar visible={feedback.tooManyPosts} onDismiss={() => updateFeedback({ tooManyPosts: false })}>
        {txt('createPost.tooManyPosts')}
      </NormalSnackbar>
      <NormalSnackbar visible={feedback.postTooShort} onDismiss={() => updateFeedback({ postTooShort: false })}>
        {txt('createPost.postTooShort')}
      </NormalSnackbar>
      <NormalSnackbar visible={feedback.postSaved} onDismiss={() => updateFeedback({ postSaved: false })}>
        {txt('post.savePostSnackBar')}
      </NormalSnackbar>

      <DeletePostDialog
        visible={feedback.showCustomDialog}
        deletePost={handleDeletePost}
        cancel={() => updateFeedback({ showCustomDialog: false })}
      />

      <VisibilityInfo visible={visibilityDialogVisible} ok={() => setVisibilityDialogVisible(false)} />

      <ChangeVisibilityDialog
        visible={saveChangesDialog}
        makeVisible={() => handleVisibilityChange(1)}
        keepHidden={() => handleVisibilityChange(-1)}
      />

      <WeakPostDialog
        edit={!!props.getPost}
        visible={weakPostDialogVisible}
        submit={handleCreateWeakPost}
        dismiss={handleRewritePost}
        weakText={weakText}
        noImage={noImage}
        weakPost={weakPost}
      />

      <UpdateNotAllowedDialog
        visible={notAllowedDialog}
        dismiss={() => setNotAllowedDialog(false)}
      />

      <BadWordPopup />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonsContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 5,
  },
  center: { alignSelf: 'center', margin: 5 },
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  imagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  inactive: { color: Colors.inactive },
  margin12: { margin: 12 },
  marginBottom: { marginBottom: 12 },
  visibilityContainer: {
    backgroundColor: Colors.white,
    padding: 10,
    borderRadius: 12,
    ...ShadowStyles.shadowInputs,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
  },
  visibilityTitleContainer: {
    marginBottom: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  genderResponseContainer: {
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    ...ShadowStyles.shadowInputs,
    flexWrap: 'wrap',
  },

})
