import React, { useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import moment from 'moment'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import { createBooking, getPreviousBookers, getPreviousJuniorBookers } from '../../../services/api/LearnDanish'
import { ShadowStyles, SpacingStyles, TypographyStyles } from '../../../styles'
import RegexPatterns from '../../../constants/RegexPatterns'
import { CustomInputs } from '../../text/StyledTextinputs'
import { NormalSnackbar } from '../../snackbars/Snackbar'
import { Portal } from 'react-native-paper'
import DatePicker from '../../dates/DatePicker'
import { getVolunteerAccess } from '../../../services/api/volunteerApi'
import JuniorList from './JuniorList'
import SproglandDurationSelector from './SproglandDurationSelector'
import RenderPreviousBookers from './volunteer/RenderPreviousBookers'
import CategorySelectList from '../../formElements/CategorySelectList'
import { getSproglandCategories } from '../../../constants/SproglandCategories'
import { getJuniorConsent } from '../../../services/api/LearnDanish'
import { SproglandJuniorConsentDialog } from '../../dialogs/SproglandJuniorConsentDialog'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import NormalIcon from '../../icons/NormalIcon'
import CustomPressable from "../../pressables/CustomPressable"

const small = Layout.isSmallDevice

export default function CreateSessions(props) {
  moment.locale([txt('videoChat.locale')])

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [durationChosen, setDurationChosen] = useState(15)
  const [emailToInvite, setEmailToInvite] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedJunior, setSelectedJunior] = useState(null)
  const [isBookingValid, setIsBookingValid] = useState(false)
  const [isSessionCreated, setIsSessionCreated] = useState(false)
  const [isEmailToInviteValid, setIsEmailToInviteValid] = useState(true)
  const [isEmailInviteChecked, setIsEmailInviteChecked] = useState(false)
  const [isJuniorSession, setIsJuniorSession] = useState(false)
  const [hasJuniorAccess, setHasJuniorAccess] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [previousBookers, setPreviousBookers] = useState([])
  const [selectedBooker, setSelectedBooker] = useState('')

  const [created, setCreated] = useState(false)
  const minTime = useRef(new Date().setHours(5, 0))
  const maxTime = useRef(new Date().setHours(23, 0))
  const maximumDate = useRef(new Date().setMonth(new Date().getMonth() + 6))
  const [showConsentDialog, setShowConsentDialog] = useState(false)

  useEffect(() => {
    getVolunteerAccess('sprogland').then((volunteer) => {
      if (volunteer) setHasJuniorAccess(volunteer.juniorAccess)
    })
    getPreviousBookers().then(setPreviousBookers)
    roundUpDate()
  }, [])

  useEffect(() => {
    if (isJuniorSession) {
      getPreviousJuniorBookers().then((juniorBookers) => setPreviousBookers(juniorBookers))
    } else {
      getPreviousBookers().then((bookers) => setPreviousBookers(bookers))
    }
  }, [isJuniorSession])

  useEffect(() => {
    if (isEmailInviteChecked) validateEmail()
  }, [emailToInvite, isEmailInviteChecked])

  useEffect(() => {
    if (isBookingValid) {
      create()
    }
  }, [isBookingValid])

	function handleErrorMessages(msg) {
		const { createErrors } = txt('learnDanish')

		const ERR_ENUMS = {
			USER_NOT_FOUND: createErrors.emailNotExisting,
			OWN_USER: createErrors.emailIsVolunteersOwn,
			USER_IS_OCCUPIED: createErrors.userIsOccupied,
			VOLUNTEER_IS_OCCUPIED: createErrors.volunteerIsOccupied,
			VOLUNTEER_LIMIT_REACHED: createErrors.volunteerLimitReached,
			VOLUNTEER_HAS_TOO_MANY_SESSIONS: createErrors.volunteerHasTooManySessions,
		}

		let errorMsg = ERR_ENUMS[msg] || createErrors.unknown
		setErrorMsg(errorMsg)
	}

  function roundUpDate() {
    const coeff = 1000 * 60 * 5
    const roundedStartDate = new Date(Math.ceil(startDate / coeff) * coeff)
    setStartDate(roundedStartDate)
  }

  function checkIfDateAndTimeIsValid() {
    const date = new Date(startDate)
    const isDateValid = date > new Date()
    if (isDateValid) {
      const endDate = new Date(date)
      endDate.setMinutes(date.getMinutes() + Number(durationChosen))
      setEndDate(endDate)
      return true
    } else {
      setErrorMsg(txt('invalidNotice.dateTimeHasPassed'))
      return false
    }
  }

  function checkIfJuniorIsSelected() {
    if (!isJuniorSession) return true
    else {
      if (!selectedJunior && isEmailInviteChecked) {
        setErrorMsg(txt('learnDanish.junior.noJuniorSelected'))
        return false
      } else return true
    }
  }

  function validateEmail() {
    setErrorMsg('')
    if (!isEmailInviteChecked) {
      setEmailToInvite('')
      setIsEmailToInviteValid(true)
      return true
    } else {
      const conditions = RegexPatterns.email.map((rule) => new RegExp(rule, 'g'))
      const isEmailValid = conditions.map((condition) => condition.test(emailToInvite))
      if (isEmailValid.includes(false)) {
        setIsEmailToInviteValid(false)
        setErrorMsg(txt('learnDanish.emailNotValid'))
        return false
      } else {
        setIsEmailToInviteValid(true)
        return true
      }
    }
  }

	async function create() {
		const response = await createBooking({
		startDate: startDate,
		endDate: endDate,
		emailToInvite: emailToInvite,
		junior: isJuniorSession,
		juniorId: selectedJunior,
		categories: selectedCategories,
		})

		if (response?.status_code > 300) handleErrorMessages(response.status_msg)
		props.reloadAll()

		if (response?.status_code > 200 && response?.status_code < 300) {
			setCreated(true)
			resetInputs()
		}
		setIsBookingValid(false)
		setIsEmailInviteChecked(false)
		setEmailToInvite('')
		setSelectedBooker('')
		setTimeout(() => setCreated(false), 600)
	}

  function resetInputs() {
    setErrorMsg('')
    setEmailToInvite('')
    setSelectedJunior(undefined)
    setSelectedCategories([])
    setIsEmailInviteChecked(false)
    setIsEmailToInviteValid(true)
    setSelectedBooker('')
    setIsJuniorSession(false)
    setStartDate(new Date())
    setEndDate(new Date())
    setDurationChosen(15)
    setIsSessionCreated(false)
  }

  async function handleBookingSubmit() {
    const isValid = (checkIfDateAndTimeIsValid() && validateEmail() && checkIfJuniorIsSelected())
      
      if (isJuniorSession) {
        const consentGiven = await getJuniorConsent()
        setShowConsentDialog(!consentGiven)
        if (!consentGiven) return
      }

    setIsBookingValid(isValid)
  }

  function setMinTime() {
    const today = new Date()
    const isToday = (
      startDate.getDate() === today.getDate() &&
      startDate.getMonth() === today.getMonth() &&
      startDate.getFullYear() === today.getFullYear()
    )
    if (isToday) return new Date()
    return minTime.current
  }

  function handleEmailChange(email) {
    setEmailToInvite(email)
    if (email.length > 0) setIsEmailInviteChecked(true)
    else setIsEmailInviteChecked(false)
  }

  function handleDateChange(date, isTime) {
    if (!isTime) return setStartDate(date)

    setStartDate((startDate) => {
      const newDate = new Date(startDate)
      newDate.setHours(date.getHours(), date.getMinutes(), 0, 0)
      return newDate < new Date() ? new Date() : newDate
    })
  }

  function handleDurationChange(duration) {
    setDurationChosen(duration)
  }

  function handleSelectCategory(id) {
    let categories = [...selectedCategories]
    const index = categories.indexOf(id)
    if (index !== -1) categories.splice(index, 1)
    else categories.push(id)
    setSelectedCategories(categories)
  }

  function toggleSelectedJunior(id) {
    setSelectedJunior(selectedJunior === id ? null : id)
  }

  async function handleJuniorCheckPress() {
    setIsJuniorSession(!isJuniorSession)
    setSelectedJunior(undefined)
  }

  const selectBooker = (booker) => {
    setSelectedBooker(booker.username)
    setIsEmailInviteChecked(true)
    setEmailToInvite(booker.email)
  }

  const resetBooker = () => {
    setSelectedBooker('')
    setEmailToInvite('')
    setIsEmailInviteChecked(false)
    setSelectedJunior(undefined)
  }

  return (
    <View style={styles.container}>
      <CustomText font="largeBold" style={[styles.text, styles.pickDateContainer]}>
        {txt('learnDanish.volunteer.create.title')}
      </CustomText>

      <View style={!small && styles.card}>
        <View style={!small && styles.rowContainer}>
          <View style={!small && styles.contentContainer}>
            <View style={small ? { flex: 5, flexDirection: 'row' } : styles.rowContainer}>
              <View style={{ flex: 3, paddingRight: 12 }}>
                <CustomText font="smallBold" accessibilityRole="header" aria-level={3}>
                  {txt('learnDanish.volunteer.create.dateAndTime')}
                </CustomText>
                <View style={styles.pickDateContainer}>
                  <DatePicker
                    date={startDate}
                    minTime={setMinTime()}
                    maxTime={maxTime.current}
                    minDate={new Date()}
                    maxDate={maximumDate.current}
                    minuteInterval={5}
                    handleDateChange={handleDateChange}
                    textStyle={{ ...TypographyStyles.textSmall }}
                  />
                </View>
              </View>
              <View style={{ flex: 2 }}>
                <SproglandDurationSelector duration={durationChosen} handleDurationChange={handleDurationChange} />
              </View>
            </View>
            <View>
              <CustomText style={{ paddingTop: 12 }} font="smallBold" accessibilityRole="header" aria-level={3}>
                {txt('learnDanish.volunteer.chooseTheme')}
              </CustomText>
              <View style={styles.themeContainer}>
                <CategorySelectList
                  categories={getSproglandCategories()}
                  selectedCategories={selectedCategories}
                  selectCategory={handleSelectCategory}
                />
                {hasJuniorAccess && (
                  <CustomPressable
                    onPress={handleJuniorCheckPress}
                    style={[styles.junior, isJuniorSession && styles.juniorSelected, small && { marginTop: 12 }]}
                  >
                    {!small && isJuniorSession && (
                      <NormalIcon name="check" size={14} color={Colors.blue} style={{ paddingRight: 6 }} />
                    )}
                    <CustomText font="small" style={{ color: isJuniorSession ? Colors.blue : Colors.textLight }}>
                      {txt('learnDanish.volunteer.juniorSession')}
                    </CustomText>
                  </CustomPressable>
                )}
              </View>
            </View>
          </View>
          <View style={[styles.contentContainer, !small && styles.sideSeparator]}>
            <CustomText
              style={small && { paddingTop: 12 }}
              font="smallBold"
              accessibilityRole="header"
              aria-level={3}>
              {txt('learnDanish.volunteer.chooseUser')}
            </CustomText>
            {previousBookers.length > 0 && (
              <View style={{ marginTop: -8 }}>
                <RenderPreviousBookers
                  data={previousBookers}
                  onChange={selectBooker}
                  reset={resetBooker}
                  selected={selectedBooker}
                />
              </View>
            )}
            {!selectedBooker && (
              <CustomInputs
                value={emailToInvite}
                placeholder={txt('learnDanish.emailPlaceholder')}
                inputMode="email"
                autoComplete={'email'}
                autoCapitalize="none"
                pattern={RegexPatterns.email}
                onChangeText={handleEmailChange}
                textStyle={{ ...TypographyStyles.textSmall }}
              />
            )}
            {isEmailToInviteValid && isJuniorSession && (
              <JuniorList
                email={emailToInvite}
                selectedJunior={selectedJunior}
                setSelectedJunior={toggleSelectedJunior}
              />
            )}
          </View>
        </View>
        <View style={{ alignSelf: 'center', marginTop: 24 }}>
          <ButtonPrimary
            title={txt('videoChat.create')}
            titleStyle={{ ...TypographyStyles.textSmallBold }}
            icon="plus"
            success={created}
            iconSize={16}
            stroke={'fal'}
            onPress={handleBookingSubmit}
          />
        </View>
      </View>

      {!!errorMsg && <InvalidInputNoticeCentered>{errorMsg}</InvalidInputNoticeCentered>}
      <Portal>
        <NormalSnackbar visible={isSessionCreated} onDismiss={() => setIsSessionCreated(false)}>
          {txt('learnDanish.sessionCreated')}
        </NormalSnackbar>
      </Portal>

      <SproglandJuniorConsentDialog
        visible={showConsentDialog}
        setVisible={setShowConsentDialog}
        onCancel={() => setShowConsentDialog(false)}
        acceptOnly
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.marginRightLeft10,
  },
  pickDateContainer: {
    marginBottom: 10,
    marginTop: 5,
  },
  text: {
    textAlign: 'center',
  },
  card: {
    backgroundColor: Colors.white,
    padding: 24,
    borderRadius: 2,
    ...ShadowStyles.liftedShadow,
  },
  junior: {
    marginTop: 6,
    marginRight: 6,
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderColor: Colors.greyLight,
    borderWidth: 2,
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  juniorSelected: {
    borderWidth: 2,
    borderColor: Colors.blue,
    backgroundColor: Colors.bluePale,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sideSeparator: {
    borderLeftWidth: 1,
    borderLeftColor: Colors.blackTransparent,
    paddingLeft: 36,
  },
  contentContainer: {
    maxWidth: 400,
    width: '100%',
    alignSelf: 'flex-start',
  },
  themeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
})
