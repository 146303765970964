import React, { useEffect, useState, useCallback } from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import Paths from '../../../constants/Paths'
import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import { getFullUnion, setUnionFavorite } from '../../../services/api/Unions'
import { SpacingStyles } from '../../../styles'
import { formatForMetaTitle } from '../../text/TextFormatter'
import PageTitle from '../../tags/PageTitle'
import { URLOpener, formatURL } from '../../../utils/URLOpener'
import { RoundBtn } from '../../buttons/StyledButtons'
import NormalIcon from '../../icons/NormalIcon'
import FavoriteIndicator from '../../headers/FavoriteIndicator'
import { txt } from '../../../locales/i18n'
import Logo from '../../../assets/images/icon.png'
import { useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { openMap } from '../../../utils/openMap'
import Fontsizes from '../../../constants/Fontsizes'
import Card from '../../Card'
import ReportContent from '../../callToActions/reportContent'
import { useNavigation } from '@react-navigation/core'
import CustomPressable from "../../pressables/CustomPressable"
import Colors from "../../../constants/Colors"

const small = Dimensions.get('window').width < 500

export default function Union(props) {
  const [loading, setLoading] = useState(true)
  const [union, setUnion] = useState({
    id: 0,
    municipality_id: 0,
    is_favorite: 0,
    groupId: 0,
    title: '',
    body: '',
    username: '',
    user_id: null,
    img_filename: '',
    url: '',
    address: '',
    postal_code: 0,
    city: '',
    lat: 0,
    lng: 0,
  })

  const navigation = useNavigation()
  const ownId = useSelector((state) => state.user?.id)

  useEffect(() => {
    getFullUnion(props.route.params.id).then((unionRes) => {
      if (!unionRes) return props.navigation.popToTop()
      setUnion(unionRes)
      setLoading(false)
      unionRes.user_id !== ownId && navigation.setOptions({
        headerRight: () => <ReportContent reportingTypeId={3} contentId={props.route.params.id} />,
      })
    })
  }, [props.route.params.id])

  useFocusEffect(useCallback(fetchUnion, []))

  function fetchUnion() {
    getFullUnion(props.route.params.id).then(setUnion)
  }

  async function toggleFavorite() {
    const { is_favorite } = await setUnionFavorite(union.id, union.is_favorite)
    setUnion((union) => ({ ...union, is_favorite }))
  }

  function showEditBtn() {
    return union.user_id === ownId
  }

  function showFavoriteBtn() {
    return ownId && ownId !== union.user_id
  }

  if (!loading) {
    return (
      <View style={styles.widthHeight}>
        <PageTitle title={formatForMetaTitle(union.title)} />

        <Card largeRadius style={styles.container}>
          <View>
            {union.img_filename ? (
              <ImageHighlight
                size="medium"
                border="green"
                borderColor={Colors.green}
                source={{
                  uri: Paths.imageUrlBase + union.img_filename,
                }}
              />
            ) : (
              <ImageHighlight size="medium" borderColor={Colors.green} source={Logo} />
            )}
            <View style={styles.actionContainer}>
              {showEditBtn() && (
                <CustomPressable
                  onPress={() => props.navigateToUnion(union.id)}
                  style={styles.marg}
                  accessibilityRole="button"
                  accessibilityLabel={txt('unions.edit.title')}
                >
                  <NormalIcon name="edit" />
                </CustomPressable>
              )}
              {showFavoriteBtn() && (
                <FavoriteIndicator
                  toggleFavorite={toggleFavorite}
                  isFavorite={union.is_favorite}
                />
              )}
            </View>
          </View>
          <CustomText
            font="largeBold"
            accessible
            accessibilityRole="header"
            aria-level={2}
            style={styles.title}
          >
            {union.title}
          </CustomText>
          <CustomText style={styles.body}>{union.body.trim()}</CustomText>

            {!!union.address || !!union.city ? (
              <CustomPressable
                onPress={() => openMap(union.address, union.postal_code)}
                style={styles.address}
              >
                <View style={styles.flexCenter}>
                  <NormalIcon
                    style={styles.iconMargin}
                    name="map"
                    size={Fontsizes.l}
                  />

                  <CustomText font="small">
                    {union.address}
                    {!!union.postal_code && `, ${union.postal_code}`}
                    {!!union.city && union.city !== '' && ` ${union.city}`}
                  </CustomText>
                </View>
              </CustomPressable>
            ) : (
              <></>
            )}

          {!!union.url && (
            <RoundBtn
              style={styles.button}
              title={txt('unions.union.visit')}
              onPress={() => URLOpener(formatURL(union.url))}
            />
          )}
        </Card>
      </View>
    )
  }
  return <View />
}

const styles = StyleSheet.create({
  actionContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    margin: 12,
    padding: small ? 18 : 36,
  },
  address: {
    marginTop: 12
  },
  flexCenter: { alignItems: 'center', flexDirection: 'row' },
  iconMargin: { marginRight: 6 },
  button: { marginTop: 12 },
  body: { marginTop: 12 },
  title: { marginTop: 12, textAlign: 'center' },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
})
