import React, { useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { View, StyleSheet } from 'react-native'
import { ButtonPrimary } from '../buttons/StyledButtons'
import Slider from '@react-native-community/slider'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { CustomText } from '../text/StyledText'

export default function ImageCropper({ image, saveCroppedImage }) {
  const [zoom, setZoom] = useState(1)
  const [rotate, setRotate] = useState(0)
  const width = 225
  const editor = useRef()

  function cropImage() {
    if (editor.current) {
      const image = editor.current.getImageScaledToCanvas()
      const url = image.toDataURL()
      saveCroppedImage(url)
    }
  }

  function resetValues() {
    setZoom(1)
    setRotate(0)
  }

  return (
    <View style={{ alignItems: 'center' }}>
      <AvatarEditor
        ref={editor}
        image={image}
        width={width}
        height={width}
        border={50}
        color={[0, 0, 0, 0.6]}
        scale={zoom}
        rotate={rotate}
        borderRadius={width / 2}
        onCrop={cropImage}
      />
      <View style={styles.sliderContainer}>
        <CustomText style={{ marginRight: 6 }}>{txt('imagePicker.zoom')}</CustomText>
        <Slider
          value={zoom}
          onValueChange={(value) => setZoom(value)}
          style={{ width: 300, height: 40 }}
          minimumValue={1}
          maximumValue={5}
          minimumTrackTintColor={Colors.blue}
          maximumTrackTintColor="#000000"
          thumbTintColor={Colors.blue}
          step={0.25}
        />
      </View>
      <View style={styles.sliderContainer}>
        <CustomText style={{ marginRight: 6 }}>{txt('imagePicker.rotate')}</CustomText>
        <Slider
          value={rotate}
          onValueChange={(value) => setRotate(value)}
          style={{ width: 300, height: 40 }}
          minimumValue={-180}
          maximumValue={180}
          minimumTrackTintColor={Colors.blue}
          maximumTrackTintColor="#000000"
          thumbTintColor={Colors.blue}
          step={1}
        />
      </View>
      <CustomText style={[styles.resetBtn, { marginBottom: 12 }]} onPress={resetValues}>
        {txt('imagePicker.reset')}
      </CustomText>
      <ButtonPrimary title={txt('imagePicker.crop')} onPress={cropImage} />
    </View>
  )
}

const styles = StyleSheet.create({
  sliderContainer: { flexDirection: 'row', alignItems: 'center' },
  resetBtn: { color: Colors.blue, fontSize: 14 },
})
