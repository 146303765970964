import { useState, useEffect } from 'react'
import { Platform } from 'react-native'
import * as Linking from 'expo-linking'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useAtom, useSetAtom } from 'jotai'

import { deepLinkingStatusAtom, forceReloadAtom } from '../atoms'
import { postLoginAction, getToken } from '../services/authActions'
import * as Updates from "expo-updates"

export const useDeepLinkingStatus = () => {
	const [deepLinkingStatus, setDeepLinkingStatus] = useAtom(deepLinkingStatusAtom)
	const setForceReloadAtom = useSetAtom(forceReloadAtom)
	const [queryParams, setQueryParams] = useState(null)

	useEffect(() => {
		if (Platform.OS === 'web') return

		const urlSubscriber = Linking.addEventListener('url', async ({ url }) => {
			const { queryParams = null } = Linking.parse(url)
			if (queryParams === null) return setDeepLinkingStatus('error')
			setQueryParams(queryParams)

			const { status = null, action = null } = queryParams

			if (action === 'reload') return await Updates.reloadAsync()

			if (status === 'cancel') return setDeepLinkingStatus('idle')
			if (status === 'error') return setDeepLinkingStatus('error')

			if (status === 'success') {
				setDeepLinkingStatus('success')
				const token = Platform.OS === 'web' ? getToken() : await AsyncStorage.getItem('userToken')
				if (token) await postLoginAction(token)
				setForceReloadAtom(Date.now())
				return setDeepLinkingStatus('idle')
			}
		})

		return () => urlSubscriber.remove()
	}, [])

	return { status: deepLinkingStatus, set: setDeepLinkingStatus, queryParams }
}
