import React from 'react'
import Post from '../../components/views/Post/Post'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useNavigation } from "@react-navigation/core"
import { StackActions } from "@react-navigation/native"

export default function PostScreen(props) {
  const navigation = useNavigation()

  const navigateToConversation = (conversationId) => {
    navigation.navigate('Conversation', {
      id: conversationId,
    })
  }

  const navigateToNewConversation = (item) => {
    navigation.navigate('Conversation', {
      id: 'new',
      receiverId: item.user_id,
      boblId: item.id,
    })
  }

  const navigateToFrontpage = () => {
    navigation.navigate('Frontpage')
  }

  const navigateToEditPost = (id) => {
    const action = StackActions.replace('EditPost', { id })
    navigation.dispatch(action)
  }

  return (
    <MainContainer>
      <Post
        id={props.route.params.id}
        navigateToConversation={navigateToConversation}
        navigateToNewConversation={navigateToNewConversation}
        navigateToFrontpage={navigateToFrontpage}
        navigateToBobls={() => navigation.navigate('Home')}
        navigateToEditPost={navigateToEditPost}
      />
    </MainContainer>
  )
}
