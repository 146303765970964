import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import { URLOpener } from '../../../utils/URLOpener'
import TxtTMD from '../../../constants/TxtTMD'
import TxtProjects from '../../../constants/TxtProjects'
import PageTitle from '../../tags/PageTitle'
import {
  SpacingStyles,
  CornerStyles,
  BorderStyles,
  TypographyStyles,
} from '../../../styles'
import { ImageHighlightMedium } from '../../images/ImageHighlight'
import { RoundBtn } from '../../buttons/StyledButtons'
import CustomPressable from "../../pressables/CustomPressable"

const { width } = Dimensions.get('window')
const carouselWidth =
  width > Layout.maxFeedWidth ? Layout.maxFeedWidth - 36 : width - 18

const UserExperiences = [
  {
    name: 'MunicipalityInfo.michelle',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/angst-michelle.jpg',
    body: 'MunicipalityInfo.michelleBody',
  },
  {
    name: 'MunicipalityInfo.jan',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/jan-diemer.jpg',
    body: 'MunicipalityInfo.janBody',
  },
  {
    name: 'MunicipalityInfo.hasan',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/hasan1.jpg',
    body: 'MunicipalityInfo.hasanBody',
  },
  {
    name: 'MunicipalityInfo.ida',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/ida-moelgaard.jpg',
    body: 'MunicipalityInfo.idaBody',
  },
]

const Municipalities = [
  {
    title: 'MunicipalityInfo.aalborgTitle',
    body: 'MunicipalityInfo.aalborgBody',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/aalborg-kommune.png',
    label: 'MunicipalityInfo.aalborgLogo',
  },
  {
    title: 'MunicipalityInfo.gribskovTitle',
    body: 'MunicipalityInfo.gribskovBody',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/gribskov-kommune.png',
    label: 'MunicipalityInfo.gribskovLogo',
  },
  {
    title: 'MunicipalityInfo.næstvedTitle',
    body: 'MunicipalityInfo.næstvedBody',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/naestved-kommune.png',
    label: 'MunicipalityInfo.næstvedLogo',
  },
]

const FN = [
  {
    body: 'MunicipalityInfo.fnBody1',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/fn-verdensmaal-1.jpg',
    label: 'MunicipalityInfo.fnImage1',
  },
  {
    body: 'MunicipalityInfo.fnBody2',
    uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/fn-verdensmaal-2.jpg',
    label: 'MunicipalityInfo.fnImage2',
  },
]

export default class MunicipalityInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0,
      activeIndexMunicipalities: 0,
    }
  }

  renderStories({ item }) {
    return (
      <View style={styles.storiesContainer}>
        <PageTitle title={TxtTMD.municipalityInfo.title[TxtTMD.lang]} />

        <ImageHighlightMedium
          source={{ uri: item.uri }}
          style={[styles.storiesImage, styles.dkImage]}
          accessibilityIgnoresInvertColors
        />
        <View style={styles.carouselTextContainer}>
          <CustomText style={styles.textAlignCenter}>
            {txt(item.body)}
          </CustomText>
          <CustomText font={'bold'} style={styles.textAlignCenter}>
            {txt(item.name)}
          </CustomText>
        </View>
      </View>
    )
  }

  renderMunicipalities({ item }) {
    return (
      <View style={styles.storiesContainer}>
        <Image
          source={{ uri: item.uri }}
          resizeMode="contain"
          style={styles.dkImage}
          accessibilityIgnoresInvertColors
          accessible
          accessibilityRole="image"
          accessibilityLabel={txt(item.label)}
        />
        <View style={styles.carouselTextContainer}>
          <CustomText font={'bold'} style={styles.textAlignCenter}>
            {txt(item.title)}
          </CustomText>
          <CustomText style={styles.textAlignCenter}>
            {txt(item.body)}
          </CustomText>
        </View>
      </View>
    )
  }

  render() {
    return (
      <View style={styles.widthAndHeight}>
        <View style={styles.container}>
          <View style={styles.containerRow}>
            <CustomText
              style={styles.blogTitle}
              accessibilityRole="header"
              aria-level={1}
            >
              {txt('MunicipalityInfo.header')}
            </CustomText>
            <CustomText style={styles.blogText}>
              {txt('MunicipalityInfo.startText')}
            </CustomText>
            
            <CustomText>{txt('MunicipalityInfo.startText1')}</CustomText>

            <CustomText
              accessibilityRole="header"
              aria-level={3}
              style={styles.blogSubtitle}
            >
              {txt('MunicipalityInfo.experiencesTitle')}
            </CustomText>

            <View style={styles.carouselContainer}>
              <Carousel
                layout={'default'}
                ref={(ref) => {
                  if (!this.state.storiesRef) {
                    this.setState({ storiesRef: ref })
                  }
                }}
                data={UserExperiences}
                sliderWidth={carouselWidth}
                itemWidth={carouselWidth}
                renderItem={this.renderStories}
                onSnapToItem={(index) => this.setState({ activeIndex: index })}
                loop
                autoplay
                inactiveSlideOpacity={1} //makes web view show both items equally big
                inactiveSlideScale={1} //makes web view show both items equally big
                autoplayInterval={4000}
                enableSnap={true}
              />
              <Pagination
                dotsLength={UserExperiences.length}
                activeDotIndex={this.state.activeIndex}
                carouselRef={this.state.storiesRef}
                tappableDots={!!this.state.storiesRef}
                dotStyle={styles.pagination}
                dotContainerStyle={styles.padding5}
              />
            </View>
            <CustomText>
              {txt('MunicipalityInfo.readMore')}
              <CustomPressable
                onPress={() => URLOpener('https://filer.boblberg.dk/Files/Documents/Boblberg_Forvaltninger.pdf')}
              >
                <CustomText style={styles.linkText}>
                  {txt('MunicipalityInfo.readMoreLink')}
                </CustomText>
              </CustomPressable>
            </CustomText>
          </View>

          <View style={styles.containerRow}>
            <CustomText
              style={styles.blogHeader}
              accessibilityRole="header"
              aria-level={2}
            >
              {txt('MunicipalityInfo.boblbergHealthTitle')}
            </CustomText>
            <CustomText>
              {txt('MunicipalityInfo.boblbergHealthBody1')}
            </CustomText>
            <View style={styles.redCrossContainer}>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/municipalityInfo/roede-kors.png',
                }}
                resizeMode="contain"
                style={[styles.alignSelfCenter, styles.image]}
                accessibilityIgnoresInvertColors
                accessible
                accessibilityRole="image"
                accessibilityLabel={txt('MunicipalityInfo.redCrossLogo')}
              />
              <CustomText font={'bold'} style={styles.textAlignCenter}>
                {txt('MunicipalityInfo.redCrossStatementTitle')}
              </CustomText>
              <CustomText font={'italic'} style={styles.textAlignCenter}>
                {txt('MunicipalityInfo.redCrossStatementBody')}
              </CustomText>
            </View>
            <CustomText>
              {txt('MunicipalityInfo.boblbergHealthBody2')}
            </CustomText>
          </View>
          <View>
            <CustomPressable onPress={() => this.props.navigation.navigate('Press')}>
              <CustomText style={styles.linkText}>
                {txt('MunicipalityInfo.readPress')}
              </CustomText>
            </CustomPressable>
          </View>
          <View style={styles.carouselContainer}>
            <Carousel
              layout={'default'}
              ref={(ref) => {
                if (!this.state.storiesRef) {
                  this.setState({ storiesRef: ref })
                }
              }}
              data={Municipalities}
              sliderWidth={carouselWidth}
              itemWidth={carouselWidth}
              renderItem={this.renderMunicipalities}
              onSnapToItem={(index) => this.setState({ activeIndex: index })}
              loop
              autoplay
              inactiveSlideOpacity={1} //makes web view show both items equally big
              inactiveSlideScale={1} //makes web view show both items equally big
              autoplayInterval={4000}
              enableSnap={true}
            />
            <Pagination
              dotsLength={Municipalities.length}
              activeDotIndex={this.state.activeIndex}
              carouselRef={this.state.storiesRef}
              tappableDots={!!this.state.storiesRef}
              dotStyle={styles.pagination}
              dotContainerStyle={styles.padding5}
            />
          </View>

          <View style={styles.containerRow}>
            <CustomText
              style={styles.blogHeader}
              accessibilityRole="header"
              aria-level={2}
            >
              {TxtProjects.OEUG.title[TxtProjects.lang]}
            </CustomText>
            <CustomText style={styles.blogText}>
              {TxtProjects.OEUG.shortInfo[TxtProjects.lang]}
            </CustomText>
            <View style={styles.OEUGimage}>
              <Image
                accessibilityIgnoresInvertColors
                style={styles.image}
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/projects/OEUG_Bobler.png',
                }}
                resizeMode="cover"
              />
            </View>
            <CustomText style={styles.blogText}>
              {TxtProjects.OEUG.shortInfo2[TxtProjects.lang]}
            </CustomText>
            <RoundBtn title={TxtProjects.OEUG.readMoreBtn[TxtProjects.lang]} onPress={() => this.props.navigation.navigate('ProjectsMenu')}/>
          </View>

          <View style={styles.containerRow}>
            <CustomText style={styles.blogHeader}>
              {txt('MunicipalityInfo.fnTitle')}
            </CustomText>
            <View style={styles.fnBox}>
              {FN.map((image, i) => {
                return (
                  <View
                    key={i}
                    style={i === 0 ? styles.fnFirstCard : styles.fnCard}
                  >
                    <Image
                      source={{ uri: image.uri }}
                      resizeMode="contain"
                      style={styles.fnImages}
                      accessibilityIgnoresInvertColors
                      accessible
                      accessibilityRole="image"
                      accessibilityLabel={txt(image.label)}
                    />
                    <CustomText style={styles.textAlignCenter}>
                      {txt(image.body)}
                    </CustomText>
                  </View>
                )
              })}
            </View>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  OEUGimage: {
    alignSelf: 'center',
    height: width * 0.9,
    marginBottom: 24,
    marginTop: 12,
    maxHeight: 400,
    maxWidth: 402,
    width: width * 0.9,
  },
  alignSelfCenter: { alignSelf: 'center' },
  blogHeader: {
    ...TypographyStyles.blogHeader,
    marginBottom: 12,
    marginTop: 24,
  },
  blogSubtitle: {
    ...TypographyStyles.blogSubtitle,
    marginBottom: 12,
    marginTop: 24,
  },
  blogText: { ...TypographyStyles.blogText, marginBottom: 12 },
  blogTitle: {
    ...TypographyStyles.blogTitle,
    marginBottom: 24,
    marginTop: Layout.isSmallDevice ? 18 : 0,
    textAlign: 'center',
  },
  carouselContainer: {
    alignSelf: 'center',
    flex: 1,
  },
  carouselTextContainer: { flex: 1, padding: 15 },
  container: {
    backgroundColor: Colors.white,
    ...SpacingStyles.marginRightLeft10,
    borderRadius: 12,
    marginBottom: 20,
    padding: Layout.isSmallDevice ? 18 : 36,
  },
  containerRow: {
    marginBottom: 24,
  },
  dkImage: {
    height: Layout.isSmallDevice ? 150 : 200,
    width: Layout.isSmallDevice ? 150 : 200,
  },
  fnBox: {
    flex: 1,
    flexDirection: Layout.isSmallDevice ? 'column' : 'row',
  },
  fnCard: {
    flex: 1,
  },
  fnFirstCard: {
    flex: 1,
    marginRight: Layout.isSmallDevice ? 0 : 24,
  },
  fnImages: {
    ...CornerStyles.borderRadiusS,
    alignSelf: 'center',
    height: 150,
    margin: 20,
    width: 150,
  },
  image: {
    flex: 1,
    height: undefined,
    width: undefined,
  },
  linkText: { color: Colors.tintColor },
  municipalityImage: { alignSelf: 'center', height: 300, width: 200 },
  padding5: { padding: 5 },
  pagination: { borderRadius: 10, height: 12, width: 12 },
  redCrossContainer: { paddingBottom: 30 },
  storiesContainer: { alignItems: 'center', ...SpacingStyles.widthAndHeight },
  storiesImage: { ...CornerStyles.roundImg, ...BorderStyles.blueBorder },
  textAlignCenter: { flex: 1, textAlign: 'center' },
  widthAndHeight: { ...SpacingStyles.widthAndHeight },
})
