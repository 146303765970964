import React from 'react'
import { Text, StyleSheet } from 'react-native'
import { TypographyStyles } from '../../styles'
import Colors from '../../constants/Colors'

export function CustomText(props) {
  let textStyle

  switch (props.font) {
    // xsmall
    case 'xsmall':
      textStyle = styles.xsmall
      break
    case 'xsmallBold':
      textStyle = styles.xsmallBold
      break
    case 'xsmallItalic':
      textStyle = styles.xsmallItalic
      break
    // small
    case 'small':
      textStyle = styles.small
      break
    case 'smallBold':
      textStyle = styles.smallBold
      break
    case 'smallItalic':
      textStyle = styles.smallItalic
      break
    // normal
    case 'bold':
      textStyle = styles.bold
      break
    case 'italic':
      textStyle = styles.italic
      break
    // large
    case 'large':
      textStyle = styles.large
      break
    case 'largeBold':
      textStyle = styles.largeBold
      break
    case 'largeItalic':
      textStyle = styles.largeItalic
      break
    // xlarge
    case 'xlarge':
      textStyle = styles.xlarge
      break
    case 'xlargeBold':
      textStyle = styles.xlargeBold
      break
    case 'xlargeItalic':
      textStyle = styles.xlargeItalic
      break



    case 'gotham':
      textStyle = {
        fontFamily: 'gotham-book',
        fontSize: 16,
        lineHeight: 25.6,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-xsmall':
      textStyle = {
        fontFamily: 'gotham-book',
        fontSize: 14,
        lineHeight: 22.4,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-small':
      textStyle = {
        fontFamily: 'gotham-book',
        fontSize: 15,
        lineHeight: 24,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-large':
      textStyle = {
        fontFamily: 'gotham-book',
        fontSize: 18,
        lineHeight: 28.8,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-xlarge':
      textStyle = {
        fontFamily: 'gotham-book',
        fontSize: 21,
        lineHeight: 23.6,
        color: Colors.redCross.text,
      }
      break


    case 'gotham-bold':
      textStyle = {
        fontFamily: 'gotham-bold',
        fontSize: 16,
        lineHeight: 25.6,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-bold-xsmall':
      textStyle = {
        fontFamily: 'gotham-bold',
        fontSize: 14,
        lineHeight: 22.4,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-bold-small':
      textStyle = {
        fontFamily: 'gotham-bold',
        fontSize: 15,
        lineHeight: 24,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-bold-large':
      textStyle = {
        fontFamily: 'gotham-bold',
        fontSize: 18,
        lineHeight: 28.8,
        color: Colors.redCross.text,
      }
      break
    case 'gotham-bold-xlarge':
      textStyle = {
        fontFamily: 'gotham-bold',
        fontSize: 21,
        lineHeight: 23.6,
        color: Colors.redCross.text,
      }
      break



    default:
      textStyle = styles.text
      break
  }

  return (
    <Text
      accessible
      {...props}
      style={[{ color: Colors.text }, props.center && { textAlign: 'center' }, { userSelect: 'text' }, textStyle, props.style]}
    >
      {props.text || props.children}
    </Text>
  )
}

const styles = StyleSheet.create({
  bold: {
    ...TypographyStyles.textBold,
  },
  italic: {
    ...TypographyStyles.textItalic,
  },
  large: {
    ...TypographyStyles.textLarge,
  },
  largeBold: {
    ...TypographyStyles.textLargeBold,
  },
  largeItalic: {
    ...TypographyStyles.textLargeItalic,
  },
  xlarge: {
    ...TypographyStyles.textXLarge,
  },
  xlargeBold: {
    ...TypographyStyles.textXLargeBold,
  },
  xlargeItalic: {
    ...TypographyStyles.textXLargeItalic,
  },
  small: {
    ...TypographyStyles.textSmall,
  },
  smallBold: {
    ...TypographyStyles.textSmallBold,
  },
  smallItalic: {
    ...TypographyStyles.textSmallItalic,
  },
  text: {
    ...TypographyStyles.text,
  },
  xsmall: {
    ...TypographyStyles.textXSmall,
  },
  xsmallBold: {
    ...TypographyStyles.textXSmallBold,
  },
  xsmallItalic: {
    ...TypographyStyles.textXSmallItalic,
  },
})
