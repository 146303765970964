export const acceptedMimeTypes = [
  { extension: 'pdf', mime: 'application/pdf' },
  // { extension: 'rtf', mime: 'application/rtf' },
  { extension: 'txt', mime: 'text/plain' },
  { extension: 'csv', mime: 'text/csv' },

  // // google formats
  { extension: 'gdoc', mime: 'application/vnd.google-apps.document' },
  { extension: 'gslides', mime: 'application/vnd.google-apps.spreadsheet' },
  { extension: 'gsheet', mime: 'application/vnd.google-apps.presentation' },

  // // apple formats
  { extension: 'pages', mime: 'application/vnd.apple.pages' },
  { extension: 'numbers', mime: 'application/vnd.apple.numbers' },
  { extension: 'key', mime: 'application/vnd.apple.keynote' },

  // office formats
  { extension: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { extension: 'pptx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
  { extension: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },

  // office formats legacy
  { extension: 'doc', mime: 'application/msword' },
  { extension: 'ppt', mime: 'application/vnd.ms-powerpoint' },
  { extension: 'xls', mime: 'application/vnd.ms-excel' },
  { extension: 'dot', mime: 'application/application/msword' },
  { extension: 'pot', mime: 'application/application/vnd.ms-powerpoint' },
  { extension: 'pps', mime: 'application/application/vnd.ms-powerpoint' },
  { extension: 'xlt', mime: 'application/application/vnd.ms-excel' },
]

export const acceptedImageMimeTypes = [
  { extension: 'jpg', mime: 'image/jpeg' },
  { extension: 'png', mime: 'image/png' },
]

export const maximumDocumentSize = 100000000 // 100 megabytes
