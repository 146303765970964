import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import moment from 'moment'

import Colors from '../../../../constants/Colors'
import Layout from '../../../../constants/Layout'
import { txt } from '../../../../locales/i18n'

import { CustomText } from '../../../text/StyledText'
import { ButtonPrimary } from '../../../buttons/StyledButtons'

import { getPreviousBookers } from '../../../../services/api/snakSammen'
import { getConversationId } from '../../../../services/api/Inbox'
import { Picker } from '@react-native-picker/picker';
import { SelectModal } from '../../../modalSelectors/SelectList'
import UserDetailsDialog from "../dialogs/UserDetailsDialog"
import OptionsDialog from "../../../dialogs/OptionsDialog"
import CreateSessionDialog from "../dialogs/CreateSessionDialog"
import ReportSproglandUserDialog from '../dialogs/ReportSproglandUserDialog'

export default function PreviousSessions() {
	//sets locale to danish time
	moment.locale([txt('videoChat.locale')])
	const navigation = useNavigation()
	const [data, setData] = useState([])
	const [selectedSortingOption, setSelectedSortingOption] = useState({ id: 'username-a-z', name: txt('videoChat.volunteer.previousParticipants.sort.usernameAsc')})
	const [currentItem, setCurrentItem] = useState('')
	const [userSessionsDialogVisible, setUserSessionsDialogVisible] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const [inviteDialogVisible, setInviteDialogVisible] = useState(false)
	const [reportDialogVisible, setReportDialogVisible] = useState(false)

	useEffect(() => {
		getPreviousBookers(selectedSortingOption.id).then(setData)
	}, [selectedSortingOption])

	function formatDate(date) {
		let dateString = moment(date).format('dddd DD.MM.YYYY')
		return dateString.charAt(0).toUpperCase() + dateString.slice(1)
	}

	async function handleSendMessage() {
		// No session id is available for invitation; using 1 as identifier for invitation scenarios.
		const sessionId = 1

		const conversationId = await getConversationId({ vsSessionId: sessionId, receiverId: currentItem?.user_id })

		if (conversationId) navigateToConversation(conversationId)
		else navigateToNewConversation(currentItem?.user_id, sessionId)
	}

	const navigateToConversation = (conversationId) => {
		navigation.navigate('Conversation', {
			id: conversationId,
		})
	}

	const navigateToNewConversation = (userId, sessionId) => {
		navigation.navigate('Conversation', {
			id: 'new',
			receiverId: userId,
			vsSessionId: sessionId,
		})
	}

	function handleUserDetailsPress() {
		setUserSessionsDialogVisible(true)
	}

	const handleInvite = (item) => {
		setCurrentItem(item)
		setInviteDialogVisible(true)
	}

	const handleMore = (item) => {
		setCurrentItem(item)
		setShowOptions(true)
	}

	const sortingOptions = [
		{ id: 'username-a-z', name: txt('videoChat.volunteer.previousParticipants.sort.usernameAsc') },
		{ id: 'username-z-a', name: txt('videoChat.volunteer.previousParticipants.sort.usernameDesc') },
		{ id: 'session-latest', name: txt('videoChat.volunteer.previousParticipants.sort.sessionLatest') },
		{ id: 'session-oldest', name: txt('videoChat.volunteer.previousParticipants.sort.sessionOldest') },
		{ id: 'sessions-high-low', name: txt('videoChat.volunteer.previousParticipants.sort.sessionsDesc') },
		{ id: 'sessions-low-high', name: txt('videoChat.volunteer.previousParticipants.sort.sessionsAsc') },
	]

	const options = [
		{
			label: txt('videoChat.messageSend'),
			action: async () => {
				setShowOptions(false)
				await handleSendMessage(currentItem)
			},
		},
		{
			label: txt('videoChat.participantInfo'),
			action: () => {
				handleUserDetailsPress()
				setShowOptions(false)
			},
		},
		{
			label: txt('videoChat.volunteer.reportLanguageTraining'),
			action: () => {
				setShowOptions(false)
				setReportDialogVisible(true)
			}
		},
	]

	return (
		<View style={styles.container}>
			<CustomText font="gotham-xlarge" style={{marginTop: 12, marginBottom: 24, marginHorizontal: 10}}>
				{txt('videoChat.volunteer.previousParticipants.title')}
			</CustomText>
			<View>
				<CustomText font="gotham-bold" style={{marginLeft: 10}}>{txt('videoChat.volunteer.previousParticipants.sort.title')}</CustomText>
				<SelectModal
					list={sortingOptions}
					selectedValue={selectedSortingOption.id}
					initValue={selectedSortingOption}
					onValueChange={(value) => {
						const id = value.id ? value.id : value
						setSelectedSortingOption({ id, name: sortingOptions.find((option) => option.id === id).name})
					}}
					specialID={true}
					style={styles.picker}
				>
					{sortingOptions.map((option) => {
						return <Picker.Item key={option.id} label={option.name} value={option.id} />
					})}
				</SelectModal>
			</View>

			{data.length === 0 && (
				<CustomText font="gotham-small" style={styles.noSession}>{txt('videoChat.volunteer.previousParticipants.none')}</CustomText>
			)}

			{!Layout.small && (
				<View style={styles.headerContainer}>
					<View style={{ flex: 3 }}>
						<CustomText font="gotham-bold-small">{txt('videoChat.volunteer.previousParticipants.name')}</CustomText>
					</View>
					<View style={{ flex: 3 }}>
						<CustomText font="gotham-bold-small">{txt('videoChat.volunteer.previousParticipants.lastSession')}</CustomText>
					</View>
					<View style={{ flex: 2 }}>
						<CustomText font="gotham-bold-small">{txt('videoChat.volunteer.previousParticipants.totalSessions')}</CustomText>
					</View>
					<View style={{ flex: 3 }}>
						<CustomText font="gotham-bold-small">{''}</CustomText>
					</View>
				</View>
			)}

			{data.map((item, key) => {
				return (
					<View key={key} style={styles.card}>
						<View style={styles.dataContainer}>
							<View style={!Layout.small && { flex: 3, alignSelf: 'center' }}>
								<CustomText font="gotham-small" style={{ textAlign: 'left', alignSelf: 'flex-start' }}>
									{Layout.small && <CustomText font="gotham-bold-small">{txt('videoChat.volunteer.previousParticipants.name') + ': '}</CustomText>}
									{item.username}
								</CustomText>
							</View>
							<View style={!Layout.small && {flex: 3, alignSelf: 'center'}}>
								<CustomText font="gotham-small">
									{Layout.small && <CustomText font="gotham-bold-small">{txt('videoChat.volunteer.previousParticipants.lastSession') + ': '}</CustomText>}
									{formatDate(item.lastSessionDate)}
								</CustomText>
							</View>
							<View style={!Layout.small && {flex: 2, alignSelf: 'center'}}>
								<CustomText font="gotham-small">
									{Layout.small && <CustomText font="gotham-bold-small">{txt('videoChat.volunteer.previousParticipants.totalSessions') + ': '}</CustomText>}
									{item.sessionCount90Days}
								</CustomText>
							</View>
							<View style={[styles.buttonsContainer, !Layout.small && {flex: 3, alignSelf: 'center'}]}>
								<View style={styles.buttonStartContainer}>
									<ButtonPrimary
										title={txt('videoChat.volunteer.previousParticipants.invite')}
										onPress={ () => handleInvite(item)}
										style={styles.button}
										titleStyle={styles.buttonTitle}
									/>
								</View>
								<View style={styles.buttonStartContainer}>
									<ButtonPrimary
										title={txt('videoChat.volunteer.previousParticipants.more')}
										titleStyle={styles.buttonTitle}
										onPress={() => handleMore(item)}
										style={styles.button}
									/>
								</View>
							</View>
						</View>
					</View>
				)
			})}

			<CreateSessionDialog
				visible={inviteDialogVisible}
				onDismiss={() => setInviteDialogVisible(false)}
				user={currentItem}
			/>

			<OptionsDialog
				visible={showOptions}
				onDismiss={() => setShowOptions(false)}
				options={options}
			/>

			<UserDetailsDialog
				visible={userSessionsDialogVisible}
				onDismiss={() => setUserSessionsDialogVisible(false)}
				currentItem={currentItem}
			/>

			<ReportSproglandUserDialog
				visible={reportDialogVisible}
				onDismiss={() => setReportDialogVisible(false)}
				currentItem={currentItem}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: Layout.small ? 12 : 0,
	},
	buttonStartContainer: {
		flex: 1,
		justifyContent: 'center',
		paddingRight: 2,
	},
  container: {
		margin: 10,
    zIndex: -1,
    maxWidth: 1000,
    width: '100%',
    alignSelf: 'center',
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
    textAlign: 'center',
  },
	card: {
		backgroundColor: Colors.white,
		borderRadius: 10,
		flexDirection: Layout.small ? 'column' : 'row',
		alignItems: Layout.small ? 'flex-start' : 'center',
		marginTop: 10,
		marginHorizontal: 10,
		paddingVertical: Layout.small ? 18 : 12,
		paddingHorizontal: Layout.small ? 24 : 18,
	},
	button: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.background,
		paddingVertical: 13,
		paddingHorizontal: 32,
		marginLeft: 12,
		minWidth: 115,
	},
	buttonTitle: {
		color: Colors.redCross.text,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},

	dataContainer: {
		flex: 1,
		flexDirection: Layout.small ? 'column' : 'row',
		alignItems: Layout.small ? 'flex-start' : 'center',
		justifyContent: 'space-between',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 24,
		paddingHorizontal: Layout.small ? 24 : 18,
	},
	picker: {
		paddingHorizontal: 10,
		paddingVertical: 8,
		marginLeft: 10,
		marginRight: 10,
		marginTop: 10,
		fontSize: 15
	}
})
