import { apiDelete, apiGet, apiPost, apiPut } from './ApiRequest'
import store from '../../redux/store'
import {
  deleteChristmasPostAction,
  setChristmasPost,
  updateChristmasPostAction,
} from '../../redux/actions'

export async function createChristmasPost(post) {
  const res = await apiPost(post, 'christmas-friends/posts')
  const resJson = await res.json()
  if (resJson.post) {
    store.dispatch(setChristmasPost(resJson.post))
  }
  return resJson.post
}

export async function getChristmasPost(christmasPostId) {
  const res = await apiGet(`christmas-friends/posts/${christmasPostId}`)
  return res.post
}

export async function updateChristmasPost(postUpdate) {
  let post = store.getState().christmas.post
  const body = { ...post, ...postUpdate }
  const postRes = await apiPut(body, `christmas-friends/posts/${post.id}`)
  if (!postRes.ok) return null
  const updatedPost = await postRes.json()
  store.dispatch(updateChristmasPostAction(updatedPost))
  return updatedPost
}

export async function getOwnChristmasPost() {
  const userId = store.getState().user.id
  const res = await apiGet(`christmas-friends/posts?userId=${userId}`)
  const post = res.posts[0]
  if (post) {
    store.dispatch(setChristmasPost(post))
  }
  if (post) return post
}

export async function deleteChristmasPost(id) {
  const res = await apiDelete({}, `christmas-friends/posts/${id}`)
  if (res.status === 200) {
    store.dispatch(deleteChristmasPostAction(null))
  }
}

export async function getChristmasPosts({
  amount,
  skip,
  search,
  distance,
  type,
  favorite,
}) {
  let uri = `christmas-friends/posts?amount=${amount}&skip=${skip}`
  uri = search ? `${uri}&search=${search}` : uri
  uri = distance ? `${uri}&distance=${distance}` : uri
  uri = type ? `${uri}&type=${type}` : uri
  uri = favorite ? `${uri}&favorite=${favorite}` : uri

  const res = await apiGet(uri)
  return res.posts
}

export async function getSamplePosts() {
  return await apiGet(`christmas-friends/posts/sample`)
}

export async function getChristmasFavoritePosts() {
  const res = await apiGet(`christmas-firends/favorites`)
  if (!res.data) return []
  return res.data
}

export async function setChristmasPostFavorite(postId, favorite) {
  let res
  if (favorite) {
    res = await apiDelete({}, `christmas-friends/posts/${postId}/favorite`)
  } else {
    res = await apiPost({}, `christmas-friends/posts/${postId}/favorite`)
  }
  return res
}

export async function getConversationNudge(conversationId) {
  return apiGet(`christmas-friends/conversations/${conversationId}/nudge`)
}

export async function registerConversationNudge(conversationId, messageCount) {
  const body = { messageCount }
  return apiPut(body, `christmas-friends/conversations/${conversationId}/nudge`)
}

export async function confirmMatch(postIdReceiver) {
  const ownPost = store.getState().christmas.post
  if (ownPost.type === 'guest')
    store.dispatch(updateChristmasPostAction({ status: -1 }))
  const body = { postIdReceiver }
  await apiPost(body, `christmas-friends/posts/${ownPost.id}/matches`)
}

export async function getMatchConfirmations() {
  const ownPostId = store.getState().christmas.post.id
  return apiGet(`christmas-friends/posts/${ownPostId}/matches`)
}
