import React, { useEffect, useState } from 'react'
import { Platform, View, StyleSheet, Image, ActivityIndicator } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'

import { LoginForm } from './LoginForm'
import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import CustomPressable from '../../pressables/CustomPressable'

import { initialUrlAtom, jotaiStore } from '../../../atoms'
import * as Localization from '../../../services/localization'
import * as AuthService from '../../../services/authActions'
import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import { txt } from '../../../locales/i18n'
import Version from "../../tags/Version"

const images = {
	idle: 'https://filer.boblberg.dk/Assets/Img/logo/boblberg-logo-rk.png',
	pending: 'https://filer.boblberg.dk/Assets/Img/logo/boblberg-logo-rk.png',
	success: 'https://filer.boblberg.dk/Assets/Img/logo/boblberg-logo-rk.png',
	error: 'https://filer.boblberg.dk/Assets/Img/logo/boblberg-logo-rk.png',
}

export const LoginView = () => {
	// idle | pending | success | error
	const [authState, setAuthState] = useState('idle')
	const navigation = useNavigation()

	useEffect(() => {
		navigation.setOptions({
			headerTitle: '',
			headerStyle: {
				backgroundColor: Colors.background,
				elevation: 0,
				shadowOpacity: 0,
				borderBottomWidth: 0,
			},
		})
	}, [])

	useEffect(() => {
		const urlSubscriber = Linking.addEventListener('url', authLinkHandler)
		return () => urlSubscriber.remove()
	}, [])

	const authLinkHandler = async ({ url }) => {
		const { queryParams = null } = Linking.parse(url)
		if (queryParams === null) return

		const { event = null, status = null, token = null } = queryParams
		if (event !== 'login' || status === null) return

		if (status === 'cancel') return setAuthState('idle')
		if (status === 'error') return setAuthState('error')

		if (status === 'success') {
			if (token === null) return setAuthState('error')
			setAuthState('success')

			try {
				await AuthService.storeAccessToken(token)
				await AuthService.postLoginAction(token)

				const initialUrl = jotaiStore.get(initialUrlAtom)
				if (initialUrl === null) return null

				const { path, queryParams } = Linking.parse(initialUrl);
				if (path === null) return
				
				const link = Linking.createURL(path, { queryParams })
				return Linking.openURL(link)
			} catch (error) {
				return setAuthState('error')
			}
		}
	}

	const loginNativeHandler = async () => {
		setAuthState('pending')

		const queries = []
		const url = Paths.getBaseUrl() + 'login'

		const appDeeplink = AuthSession.makeRedirectUri()
		queries.push('appDeeplink=' + encodeURIComponent(appDeeplink))

		const lang = await Localization.getLanguage()
		if (lang) queries.push('lang=' + lang)

		await Linking.openURL(url + '?' + queries.join('&'))
	}

	if (Platform.OS === 'web') {
		return (
			<View style={styles.webContainer}>
				<View accessible style={styles.webTextContainer}>
					<CustomText font="large">{txt('signupField.welcome')}</CustomText>
					<CustomText style={styles.webText}>{txt('loginField.instructions')}</CustomText>
				</View>

				<LoginForm />
			</View>
		)
	}

	const renderInfoContainer = () => {
		if (authState === 'pending') {
			return (
				<View
					style={[
						styles.infoContainer,
						{ borderWidth: 1, borderColor: Colors.blueAzure, maxWidth: '90%', alignSelf: 'center', marginBottom: 12 },
					]}
				>
					<CustomText style={styles.infoText}>{txt('loginField.logging active')}</CustomText>
				</View>
			)
		}

		if (authState === 'success') {
			return (
				<View style={[styles.infoContainer, { backgroundColor: Colors.greenBtn, width: 250, alignSelf: 'center' }]}>
					<CustomText style={[styles.infoText, { color: Colors.white }]}>{txt('loginField.logging in')}</CustomText>
					<ActivityIndicator color={Colors.white} />
				</View>
			)
		}

		if (authState === 'error') {
			return (
				<View
					style={[
						styles.infoContainer,
						{ borderWidth: 2, borderColor: Colors.redSoft, maxWidth: '90%', alignSelf: 'center', marginBottom: 12 },
					]}
				>
					<CustomText font="bold" style={[styles.infoText, { color: Colors.redSoft }]}>
						{txt('loginField.error')}
					</CustomText>
				</View>
			)
		}

		return null
	}

	return (
		<View style={styles.nativeContainer}>
			<Image
				accessibilityIgnoresInvertColors
				style={styles.backgroundImage}
				source={{ uri: 'https://filer.boblberg.dk/Assets/Img/frontpage/app-login-background.png' }}
			/>

			<View style={styles.imageContainer}>
				<Image accessibilityIgnoresInvertColors style={styles.image} source={{ uri: images[authState] }} />
			</View>

			<View style={styles.bottomContainer}>
				{renderInfoContainer()}

				{authState !== 'success' && (
					<>
						<ButtonPrimary
							onPress={loginNativeHandler}
							title={authState !== 'error' ? txt('loginField.getting started') : txt('loginField.retry')}
							loading={authState === 'pending'}
							style={styles.buttonLogin}
						/>

						<View style={{ paddingVertical: 12 }}>
							{authState === 'pending' && (
								<CustomPressable onPress={() => setAuthState('idle')}>
									<CustomText style={styles.cancelButton}>{txt('loginField.cancel')}</CustomText>
								</CustomPressable>
							)}
						</View>
					</>
				)}

				{authState === 'success' && (
					<View style={{ paddingVertical: 12, marginTop: 24 }}>
						<CustomPressable onPress={() => setAuthState('idle')}>
							<CustomText style={styles.cancelButton}>{txt('loginField.retry')}</CustomText>
						</CustomPressable>
					</View>
				)}
				<Version />
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	logo: {
		height: 64,
		aspectRatio: 1,
	},

	webContainer: {
		paddingVertical: 12,
		paddingHorizontal: 24,
		alignItems: 'center',
		gap: 32,
	},

	webTextContainer: {
		alignItems: 'center',
		gap: 12,
	},

	webText: {
		textAlign: 'center',
	},

	nativeContainer: {
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	backgroundImage: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		top: 0,
		objectFit: 'cover',
	},

	imageContainer: {
		marginVertical: 24,
	},

	image: {
		height: 150,
		width: 150,
		objectFit: 'contain',
	},

	infoContainer: {
		padding: 12,
		flexDirection: 'row',
		borderRadius: 10,
	},

	infoText: {
		color: Colors.blueAzure,
		textAlign: 'center',
		flex: 1,
	},

	cancelButton: {
		color: Colors.blueBaby,
		textAlign: 'center',
		marginVertical: -12,
	},

	buttonLogin: {
		minWidth: 250,
		backgroundColor: Colors.blueAzure,
		paddingVertical: 13,
		paddingHorizontal: 24,
		borderRadius: 10,
		marginBottom: 24,
	},

	bottomContainer: {
		width: '100%',
		marginBottom: 50,
	},
})
