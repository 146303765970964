import React, { useEffect, useState } from 'react'
import SproglandUserView from '../../components/views/Sprogland/SproglandUserView'
import MainContainer from '../../components/MainContainer/MainContainer'
import { Dimensions, Image, StyleSheet, View } from 'react-native'
import Layout from '../../constants/Layout'
import SproglandJunior from '../../components/views/Sprogland/SproglandJunior'
import {
  createJuniorProfile,
  deleteJuniorProfile,
  getJuniorProfiles,
  updateJuniorProfile,
} from '../../services/api/JuniorApi'
import { bookSession, getBookedSessions } from '../../services/api/LearnDanish'
import Colors from '../../constants/Colors'
import { useNavigation } from '@react-navigation/core'
import {
  sproglandHeaderBackImage,
  sproglandHeaderStyle,
  sproglandJuniorHeaderTitle,
} from './SproglandOptions'

const { width } = Dimensions.get('window')
const small = width < 800

const banner = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-junior-banner.png'

export default function SproglandJuniorScreen() {
  const navigation = useNavigation()

  const [juniors, setJuniors] = useState([])
  const [selectedJunior, setSelectedJunior] = useState(null)
  const [bookedSessions, setBookedSessions] = useState([])

  useEffect(() => {
    navigation.setOptions({
      headerBackTitle: small && ' ',
      headerBackTitleStyle: { color: Colors.text, fontFamily: 'raleway-regular' },
      headerBackImage: () => sproglandHeaderBackImage(navigation),
      headerTitle: () => sproglandJuniorHeaderTitle(true),
      headerStyle: {...sproglandHeaderStyle, backgroundColor: Colors.white},
    })

    getJuniorProfiles().then(juniors => {
      setJuniors(juniors)
      setSelectedJunior(juniors[0])
    })
  }, [])


  useEffect(() => {
    if (selectedJunior) loadBookedSessions(selectedJunior.id)
  }, [selectedJunior])

  async function handleCreateJunior({name, birthday}) {
    const junior = await createJuniorProfile({name, birthday})
    setJuniors(prevState => ([...prevState, junior]))
    return junior
  }

  async function handleUpdateJunior({name, birthday, id}) {
    const junior = await updateJuniorProfile({name, birthday, id})
    if (junior) {
      setJuniors(prevState => {
        const juniors = prevState.map(j => j.id === junior.id ? junior : j)
        return [...juniors]
      })
    }
  }

  async function handleDeleteJunior(id) {
    const res = await deleteJuniorProfile(id)
    if (!res.error) {
      setJuniors(prevState => {
        const juniors = prevState.filter(j => j.id !== id)
        if (id === selectedJunior.id) setSelectedJunior(juniors[0])
        return [...juniors]
      })
    }
    return res
  }

  function bookJuniorSession(sessionId) {
    return bookSession(sessionId, selectedJunior.id)
  }

  async function loadBookedSessions() {
    const sessions = await getBookedSessions(selectedJunior.id)
    setBookedSessions(sessions)
  }

  return (
    <MainContainer backgroundColor={Colors.redCrossBackground}>
      <View style={styles.container}>
        <Image
          style={[styles.bannerImage, !small && {height: 150}]}
          resizeMode={'contain'}
          accessibilityIgnoresInvertColors
          source={{ uri: banner }}
          accessible
          accessibilityRole="image"
        />
        <View style={!Layout.isSmallDevice && styles.element}>
          <SproglandJunior
            style={styles.junior}
            juniors={juniors}
            selectedJunior={selectedJunior}
            setSelectedJunior={setSelectedJunior}
            createJuniorProfile={handleCreateJunior}
            updateJuniorProfile={handleUpdateJunior}
            deleteJuniorProfile={handleDeleteJunior}
          />
        </View>
        {selectedJunior &&
        <SproglandUserView
          getBookedSessions={loadBookedSessions}
          bookedSessions={bookedSessions}
          bookSession={bookJuniorSession}
          juniorId={selectedJunior.id}
        />
        }
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  junior: {
    margin: 10,
    marginBottom: 24,
  },
  bannerImage: {
    alignSelf: 'center',
    width: width,
    height: 125,
    marginVertical: 24,
  },
  element: {
    minWidth: 500,
    maxWidth: 900,
    width: '100%',
    alignSelf: 'center',
  },
})
