export const municipalities = [
  { id: 1, name: 'Aabenraa', lat: 54.979245, lng: 9.318688 },
  { id: 2, name: 'Aalborg', lat: 57.026147, lng: 9.928661 },
  { id: 3, name: 'Aarhus', lat: 56.161364, lng: 10.125875 },
  { id: 5, name: 'Albertslund', lat: 55.677059, lng: 12.351181 },
  { id: 6, name: 'Allerød', lat: 55.85496, lng: 12.320007 },
  { id: 7, name: 'Assens', lat: 55.298033, lng: 10.07149 },
  { id: 8, name: 'Ballerup', lat: 55.730843, lng: 12.368361 },
  { id: 9, name: 'Billund', lat: 55.734904, lng: 8.970712 },
  { id: 98, name: 'Bornholms', lat: 55.1424877, lng: 14.6388933 },
  { id: 10, name: 'Brøndby', lat: 55.643965, lng: 12.412267 },
  { id: 11, name: 'Brønderslev', lat: 57.237307, lng: 10.128541 },
  { id: 12, name: 'Dragør', lat: 55.580556, lng: 12.629148 },
  { id: 13, name: 'Egedal', lat: 55.76908, lng: 12.234093 },
  { id: 14, name: 'Esbjerg', lat: 55.422277, lng: 8.718652 },
  { id: 15, name: 'Faaborg-Midtfyn', lat: 55.199447, lng: 10.383724 },
  { id: 16, name: 'Fanø', lat: 55.404996, lng: 8.420189 },
  { id: 17, name: 'Favrskov', lat: 56.304948, lng: 9.947562 },
  { id: 18, name: 'Faxe', lat: 55.287502, lng: 12.072312 },
  { id: 19, name: 'Fredensborg', lat: 55.945561, lng: 12.452792 },
  { id: 20, name: 'Fredericia', lat: 55.571463, lng: 9.679837 },
  { id: 21, name: 'Frederiksberg', lat: 55.680954, lng: 12.525922 },
  { id: 22, name: 'Frederikshavn', lat: 57.41122, lng: 10.442963 },
  { id: 23, name: 'Frederikssund', lat: 55.814881, lng: 12.034435 },
  { id: 24, name: 'Furesø', lat: 55.796976, lng: 12.368718 },
  { id: 25, name: 'Gentofte', lat: 55.749543, lng: 12.553114 },
  { id: 26, name: 'Gladsaxe', lat: 55.746481, lng: 12.477068 },
  { id: 27, name: 'Glostrup', lat: 55.677155, lng: 12.408927 },
  { id: 28, name: 'Greve', lat: 55.587009, lng: 12.25787 },
  { id: 29, name: 'Gribskov', lat: 56.055903, lng: 12.249454 },
  { id: 30, name: 'Guldborgsund', lat: 54.790965, lng: 11.866098 },
  { id: 31, name: 'Haderslev', lat: 55.23866, lng: 9.378435 },
  { id: 32, name: 'Halsnæs', lat: 55.954565, lng: 12.002069 },
  { id: 33, name: 'Hedensted', lat: 55.782167, lng: 9.739428 },
  { id: 34, name: 'Helsingør', lat: 56.037258, lng: 12.508053 },
  { id: 35, name: 'Herlev', lat: 55.733792, lng: 12.43171 },
  { id: 36, name: 'Herning', lat: 56.139785, lng: 8.921954 },
  { id: 37, name: 'Hillerød', lat: 55.926992, lng: 12.275105 },
  { id: 38, name: 'Hjørring', lat: 57.455039, lng: 9.998041 },
  { id: 39, name: 'Høje-Taastrup', lat: 55.661428, lng: 12.245176 },
  { id: 40, name: 'Holbæk', lat: 55.660479, lng: 11.610549 },
  { id: 41, name: 'Holstebro', lat: 56.352867, lng: 8.662293 },
  { id: 42, name: 'Horsens', lat: 55.924699, lng: 9.79425 },
  { id: 43, name: 'Hørsholm', lat: 55.887556, lng: 12.494405 },
  { id: 44, name: 'Hvidovre', lat: 55.631563, lng: 12.473143 },
  { id: 45, name: 'Ikast-Brande', lat: 56.047199, lng: 9.255805 },
  { id: 46, name: 'Ishøj', lat: 55.62271, lng: 12.321494 },
  { id: 47, name: 'Jammerbugt', lat: 57.128657, lng: 9.434677 },
  { id: 48, name: 'Kalundborg', lat: 55.614447, lng: 11.269268 },
  { id: 49, name: 'Kerteminde', lat: 55.439287, lng: 10.626548 },
  { id: 50, name: 'Københavns', lat: 55.673955, lng: 12.571786 },
  { id: 51, name: 'Køge', lat: 55.459106, lng: 12.075344 },
  { id: 52, name: 'Kolding', lat: 55.459633, lng: 9.47219 },
  { id: 53, name: 'Læsø', lat: 57.27667, lng: 10.992733 },
  { id: 54, name: 'Langeland', lat: 54.92319, lng: 10.781989 },
  { id: 55, name: 'Lejre', lat: 55.62123, lng: 11.922718 },
  { id: 56, name: 'Lemvig', lat: 56.48912, lng: 8.299065 },
  { id: 57, name: 'Lolland', lat: 54.799083, lng: 11.298027 },
  { id: 58, name: 'Lyngby-Taarbæk', lat: 55.786055, lng: 12.516836 },
  { id: 59, name: 'Mariagerfjord', lat: 56.68612, lng: 9.970863 },
  { id: 60, name: 'Middelfart', lat: 55.455512, lng: 9.908975 },
  { id: 61, name: 'Morsø', lat: 56.797419, lng: 8.742318 },
  { id: 62, name: 'Næstved', lat: 55.267327, lng: 11.754733 },
  { id: 63, name: 'Norddjurs', lat: 56.456752, lng: 10.712994 },
  { id: 64, name: 'Nordfyns', lat: 55.527256, lng: 10.26204 },
  { id: 65, name: 'Nyborg', lat: 55.289593, lng: 10.729612 },
  { id: 66, name: 'Odder', lat: 55.944271, lng: 10.151739 },
  { id: 67, name: 'Odense', lat: 55.39141, lng: 10.387144 },
  { id: 68, name: 'Odsherred', lat: 55.846707, lng: 11.59015 },
  { id: 69, name: 'Randers', lat: 56.492967, lng: 10.075268 },
  { id: 70, name: 'Rebild', lat: 56.837134, lng: 9.823429 },
  { id: 71, name: 'Ringkøbing-Skjern', lat: 56.021253, lng: 8.479293 },
  { id: 72, name: 'Ringsted', lat: 55.459325, lng: 11.815581 },
  { id: 73, name: 'Rødovre', lat: 55.688637, lng: 12.451921 },
  { id: 74, name: 'Roskilde', lat: 55.644917, lng: 12.112129 },
  { id: 75, name: 'Rudersdal', lat: 55.836219, lng: 12.494515 },
  { id: 76, name: 'Samsø', lat: 55.854441, lng: 10.613796 },
  { id: 77, name: 'Silkeborg', lat: 56.189976, lng: 9.545624 },
  { id: 78, name: 'Skanderborg', lat: 56.083577, lng: 9.890223 },
  { id: 79, name: 'Skive', lat: 56.646569, lng: 9.005593 },
  { id: 80, name: 'Slagelse', lat: 55.348736, lng: 11.365193 },
  { id: 81, name: 'Solrød', lat: 55.536458, lng: 12.182618 },
  { id: 82, name: 'Sønderborg', lat: 54.94778, lng: 9.802881 },
  { id: 83, name: 'Sorø', lat: 55.483009, lng: 11.57981 },
  { id: 84, name: 'Stevns', lat: 55.330427, lng: 12.326484 },
  { id: 85, name: 'Struer', lat: 56.523991, lng: 8.563381 },
  { id: 86, name: 'Svendborg', lat: 55.097964, lng: 10.624116 },
  { id: 87, name: 'Syddjurs', lat: 56.326726, lng: 10.569825 },
  { id: 88, name: 'Tårnby', lat: 55.61313, lng: 12.609487 },
  { id: 89, name: 'Thisted', lat: 56.994642, lng: 8.655882 },
  { id: 90, name: 'Tønder', lat: 55.073539, lng: 8.925966 },
  { id: 91, name: 'Vallensbæk', lat: 55.632538, lng: 12.373843 },
  { id: 92, name: 'Varde', lat: 55.680043, lng: 8.523893 },
  { id: 93, name: 'Vejen', lat: 55.461584, lng: 9.061884 },
  { id: 94, name: 'Vejle', lat: 55.732833, lng: 9.384391 },
  { id: 95, name: 'Vesthimmerlands', lat: 56.813946, lng: 9.416298 },
  { id: 96, name: 'Viborg', lat: 56.464233, lng: 9.432205 },
  { id: 97, name: 'Vordingborg', lat: 55.01956, lng: 12.101209 },
  { id: 4, name: 'Ærø', lat: 54.864438, lng: 10.386498 },
]

export function getMunicipality(id) {
  return municipalities.find(m => m.id === id)
}
