/*global L*/
import React, { useCallback, useEffect, useRef } from 'react'
import { StyleSheet, View } from 'react-native'

import { borderRadiusS } from '../../styles/corners'
const initialRegion = { lat: 56.2386833, lng: 10.2219608, zoom: 8 }

export const Map = (props) => {
  const mapRef = useRef(null)
  const markersRef = useRef(null)

  const renderMap = useCallback(() => {
    const mapOptions = {
      center: [initialRegion.lat, initialRegion.lng],
      zoom: initialRegion.zoom,
    }

    const map = new L.map('map', mapOptions)
    map.on('click', () => props.onMapPress?.())

    const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution }).addTo(map)

    const iconCreateFunction = (cluster) => {
      const html = '<div><span><b>' + cluster.getChildCount() + '</b></span></div>'
      const iconSize = new L.Point(40, 40)
      return new L.DivIcon({ html, iconSize, className: 'marker-cluster' })
    }

    markersRef.current = L.markerClusterGroup({ iconCreateFunction, maxClusterRadius: props.noCluster ? 0 : undefined })
    mapRef.current = map
  }, [])

  useEffect(() => {
    if (mapRef.current !== null) return

    try {
      setTimeout(renderMap, 0)
    } catch (error) {
      window.location.reload()
    }
  }, [renderMap])

  useEffect(() => {
    const map = mapRef.current
    const markers = markersRef.current
    if (map === null || markers === null) return

    markers.clearLayers()
    const bounds = []

    for (const location of props.locations) {
      if (location.lat === null || location.lng === null) continue

      const marker = new L.marker([location.lat, location.lng])
      marker.on('click', () => props.onMarkerPress?.(location))
      marker.addTo(markers)
      bounds.push([location.lat, location.lng])
    }

    markers.addTo(map)
    if (bounds.length === 0) return

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
      setTimeout(() => map.fitBounds(bounds, { padding: [24, 24] }), 100)
    }, 0)
  }, [props.locations])

  return <View nativeID="map" style={[styles.map, props.style ?? {}]} />
}

const styles = StyleSheet.create({
  map: {
    height: '100%',
    width: '100%',
    ...borderRadiusS,
  },
})
