import React from 'react'
import { Pressable } from 'react-native'

export default function CustomPressable({ onPress, ...props }) {
  return (
    <Pressable
      onPress={onPress}
      {...props}
      style={({pressed}) => [ pressed ? { opacity: 0.5 } : {}, props.style]}
    >
      {props.children}
    </Pressable>
  )
}