import React from 'react'
import { StyleSheet } from 'react-native'
import CustomDialog, {
  ContentContainer,
} from '../../../components/dialogs/CustomDialog'
import { CustomText } from '../../../components/text/StyledText'
import { ButtonPrimary } from '../../../components/buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../../components/icons/NormalIcon'
import Colors from '../../../constants/Colors'

export default function PostHiddenDialog({ visible, dismiss }) {
  return (
    <CustomDialog visible={visible} onDismiss={dismiss}>
      <ContentContainer>
        <CustomText>
          {txt('christmasFriends.match.postHidden.title')}
          <NormalIcon name="user" stroke="fas" color={Colors.text} size={17}/>
          <CustomText font='bold'> Mine</CustomText>
        </CustomText>
        <ButtonPrimary onPress={dismiss} style={styles.button}>
          {txt('christmasFriends.match.postHidden.dismiss')}
        </ButtonPrimary>
      </ContentContainer>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    marginTop: 24,
  },
})
