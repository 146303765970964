import React from 'react'
import { SearchBar } from 'react-native-elements'
import { Platform, StyleSheet, TextInput, View } from 'react-native'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { InvalidInputNoticeCentered } from '../notices/InvalidInputNotices'
import {
  CornerStyles,
  ShadowStyles,
  SpacingStyles,
  TypographyStyles,
} from '../../styles'
import Layout from '../../constants/Layout'
import CustomPressable from "../pressables/CustomPressable"
import NormalIcon from '../icons/NormalIcon'
import Fontsizes from '../../constants/Fontsizes'

export function FormInput(props) {
  const { children, style, ...other } = props
  return (
    <View style={[styles.form, style]}>
      <View style={{ flex: 9 }}>
        <TextInput
          {...other}
          inputStyle={{
            ...(Platform.OS === 'web' ? { outline: 'none' } : {}), //Webview focus square
          }}
          spellCheck={false}
          style={styles.text}
        />
      </View>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>{children}</View>
    </View>
  )
}

export function MessageTextInput(props) {
  return (
    <TextInput
      style={[
        styles.messageTextInput,
        props.height &&{
          height: props.height,
        },
      ]}
      multiline
      spellCheck
      enablesReturnKeyAutomatically
      {...props}
    />
  )
}

export function CustomInputs(props) {
  const { children, ...other } = props
  return (
    <View
      style={[
        styles.customContainer,
        styles.input,
        styles.customInputContainer,
        {
          backgroundColor:
            props.theme === 'light' ? Colors.greyLight : Colors.white,
        },
        { ...props.style },
      ]}
    >
      <View style={{ flex: 9 }}>
        <TextInput {...other} style={[styles.text, props.textStyle]} spellCheck={true} />
      </View>
      <View style={{ flex: 1, alignItems: 'flex-end' }}>{children}</View>
    </View>
  )
}

// use when input requires validation.
// REQUIRES:pattern, onValidation, invalidMessage and isValid props or it will skip validation
// pattern = regex expression, either string or array of strings
// onValidation = function that expects a validation result (boolean) and should save the result to the state
// invalidMessage = message that is shown when an input is invalid
// isInputValid = takes validation state (boolean or boolean array) from the state as input, this boolean shows or hides the notice
export function CustomInputWithValidationNoShadow(props) {
  const { children, ...other } = props
  // run input through regex with given pattern
  // pattern can be string or array
  function handleValidation(value) {
    const { pattern } = props
    if (!pattern) return true
    // string pattern, one validation rule
    if (typeof pattern === 'string') {
      const condition = new RegExp(pattern, 'g')
      return condition.test(value)
    }
    // array patterns, multiple validation rules
    if (typeof pattern === 'object') {
      const conditions = pattern.map((rule) => new RegExp(rule, 'g'))
      return conditions.map((condition) => condition.test(value))
    }
  }

  // on text change validate and check if notice should show
  function onChange(value) {
    // make sure all required props are given
    const {
      onChangeText,
      onValidation,
      invalidMessage,
      pattern,
      isInputValid,
    } = props
    if (
      !onValidation ||
      !invalidMessage ||
      !pattern ||
      typeof isInputValid === 'undefined'
    ) {
      return
    }
    let isValid = handleValidation(value)
    if (typeof isValid === 'boolean' && !isValid) {
      isValid = false
    } else if (typeof isValid === 'object' && isValid.includes(false)) {
      isValid = false
    } else {
      isValid = true
    }
    onValidation && onValidation(isValid)
    onChangeText && onChangeText(value)
  }

  function showInvalidNotice(isValid) {
    if (typeof isValid === 'boolean' && !isValid) {
      // console.log('NOT VALID BOOLEAN')
      return false
    } else if (typeof isValid === 'object' && isValid.includes(false)) {
      return false
    } else {
      return true
    }
  }

  return (
    <View>
      <View
        style={[styles.customInputContainerNoShadow, styles.customContainer]}
      >
        <View style={{ flex: 9 }}>
          <TextInput
            {...other}
            style={styles.text}
            placeholderTextColor={Colors.text}
            spellCheck={true}
            onChangeText={(value) => onChange(value)}
          />
        </View>
        <View style={{ flex: 1 }}>{children}</View>
      </View>
      {showInvalidNotice(props.isInputValid) ? null : (
        <InvalidInputNoticeCentered>
          {props.invalidMessage}
        </InvalidInputNoticeCentered>
      )}
    </View>
  )
}

//To be used whenever there is a multiline input
export function CustomMultilineInputs(props) {
  return (
    <TextInput
      {...props}
      multiline={true}
      style={[
        styles.text,
        styles.customContainer,
        styles.multilineInput,
        styles.customInputContainer,
        props.style,
      ]}
      spellCheck={true}
    />
  )
}

export function CustomGroupInput(props) {
  return (
    <TextInput
      multiline={true}
      spellCheck={true}
      {...props}
      style={[
        styles.text,
        styles.groupInput,
        { height: props.height },
        props.noShadow && styles.groupInputNoShadow,
        !props.noShadow && styles.customInputContainer,
        props.style,
      ]}
    />
  )
}

export const SearchBarWeb = (props) => (
  <View style={styles.searchWeContainer}>
    <NormalIcon color={Colors.grey} size={Fontsizes.l} name="search" />
    <TextInput
      placeholder={txt('searchField.placeHolder')}
      placeholderTextColor={Colors.inactive}
      style={styles.searchWebInput}
      {...props}
    />
    {!!props.value && (
      <CustomPressable
        onPress={props.clearText}
        accessibilityRole="button"
        accessibilityLabel={txt('searchField.clearLabel')}
        accessibilityHint={txt('searchField.clearHint')}
      >
        <NormalIcon color={Colors.grey} size={Fontsizes.m} name="times" />
      </CustomPressable>
    )}
  </View>
)

export function SearchBarCustom(props) {
  /*
  example inputs:
  onChangeText={this.updateSearch}
  value={this.state.text}
  showLoading={this.state.loading}
  */
  return (
    <SearchBar
      placeholder={txt('searchField.placeHolder')}
      placeholderTextColor={Colors.inactive}
      platform="android"
      containerStyle={styles.searchContainer}
      inputStyle={styles.searchText}
      style={styles.search}
      {...props}
    />
  )
}

export function InputPrimary(props) {
  return (
    <TextInput
      multiline={props.multiline}
      value={props.value}
      placeholderTextColor={Colors.textPlaceholder}
      {...props}
      style={[ styles.inputPrimary, props.multiline && styles.multiline, props.style ]}
    />
  )
}

const styles = StyleSheet.create({
  customContainer: {
    paddingHorizontal: 0,
  },
  customInputContainer: {
    backgroundColor: Colors.white,
    borderBottomWidth: 0,
    ...CornerStyles.borderRadiusS,
    ...SpacingStyles.paddingRightLeft10,
    ...ShadowStyles.shadowInputs,
    flexDirection: 'row',
  },
  customInputContainerNoShadow: {
    backgroundColor: Colors.white,
    borderBottomWidth: 0,
    height: 40,
    ...CornerStyles.borderRadiusS,
    ...SpacingStyles.paddingRightLeft10,
    flexDirection: 'row',
  },
  form: {
    borderBottomColor: Colors.text,
    borderBottomWidth: 1,
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 6,
  },
  groupInput: {
    maxHeight: 300,
    maxWidth: Layout.maxFeedWidth,
    minHeight: 100,
    minWidth: '80%',
    paddingTop: 10,
    verticalAlign: 'top',
  },
  groupInputNoShadow: {
    borderColor: Colors.shadow,
    borderWidth: 1,
    ...CornerStyles.borderRadiusS,
    ...SpacingStyles.paddingRightLeft10,
  },
  input: { ...TypographyStyles.text, height: 40 },
  inputPrimary: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    marginTop: 6,
    padding: 10,
    ...ShadowStyles.shadowInputs,
    ...TypographyStyles.text,
  },
  messageTextInput: {
    ...TypographyStyles.text,
    ...CornerStyles.borderRadiusM,
    backgroundColor: Colors.white,
    minHeight: 40,
    paddingHorizontal: 18,
    paddingTop: 10,
    paddingVertical: 10,
    ...ShadowStyles.shadowInputs
  },
  multiline: { minHeight: 100 },
  multilineInput: {
    maxHeight: 120,
    minHeight: 120,
    paddingTop: 10,
    verticalAlign: 'top',
    ...TypographyStyles.text,
  },
  search: {
    backgroundColor: Colors.white,
  },
  searchContainer: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    height: 40,
    justifyContent: 'center',
    flexGrow: 1,
    ...ShadowStyles.shadowInputs,
  },
  searchText: { ...TypographyStyles.text },
  searchWeContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 10,
    ...ShadowStyles.shadowInputs
  },
  searchWebInput: {
    ...TypographyStyles.text,
    marginLeft: 6,
    width: '100%',
  },
  text: {
    height: '100%',
    ...TypographyStyles.text,
    color: Colors.text,
  },
})
