import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { URLOpener } from '../../../../utils/URLOpener'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'
import Colors from '../../../../constants/Colors'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

export default class Line extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.line.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/line.png',
              }}
              style={styles.image}
              accessibilityIgnoresInvertColors
            />

            <CustomText font="bold">{txt('blog.line.author')}</CustomText>
          </View>
          <View>
            <CustomText>{txt('blog.line.text1')}</CustomText>
            <Image
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/voksenven.jpg',
              }}
              resizeMode="contain"
              style={styles.imageBig}
              accessibilityIgnoresInvertColors
            />
            <CustomText font="bold" style={styles.marginBottom}>
              {txt('blog.line.question')}
            </CustomText>
            <CustomText>{txt('blog.line.text2')}</CustomText>
          </View>
          <View style={styles.marginBottom}>
            <CustomText font="large">
              {txt('blog.line.referencesTitle')}
            </CustomText>
            <CustomPressable
              onPress={() => URLOpener('https://www.maryfonden.dk/files/files/Ensommidtilivet2014.pdf')}
              style={styles.marginTop}
            >
              <CustomText font="bold">
                {txt('blog.line.references1Title')}
              </CustomText>
              <CustomText style={styles.linkText}>
                {txt('blog.line.references1')}
              </CustomText>
            </CustomPressable>
            <CustomPressable
              onPress={() => URLOpener('https://www.sst.dk/-/media/Udgivelser/2014/Den-nationale-sundhedsprofil-2013/Danskernes-sundhed,-d-,-Den-nationale-sundhedsprofil-2013.ashx?la=da&hash=B1BF89ED1840977F703CBB9A60DF6FE36515896C')}
              style={styles.marginTop}
            >
              <CustomText font="bold">
                {txt('blog.line.references2Title')}
              </CustomText>
              <CustomText style={styles.linkText}>
                {txt('blog.line.references2')}
              </CustomText>
            </CustomPressable>
            <CustomPressable
              onPress={() => URLOpener('https://www.psykiatrifonden.dk/viden/gode-raad-og-temaer')}
              style={styles.marginTop}
            >
              <CustomText font="bold">
                {txt('blog.line.references3Title')}
              </CustomText>
              <CustomText style={styles.linkText}>
                {txt('blog.line.references3')}
              </CustomText>
            </CustomPressable>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  image: { alignSelf: 'center', height: 200 },
  imageBig: { alignSelf: 'center', height: 300, width: width * 0.9 },
  linkText: { color: Colors.tintColor },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  marginTop: { marginTop: 10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
