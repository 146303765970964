import { apiPost, apiGet, apiPut, apiDelete } from './ApiRequest'
import store from '../../redux/store'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { txt } from '../../locales/i18n'

// Used if no stopLoading function passed to functions
const setLoadingFunc = () => {
  if (__DEV__) console.log('No setLoadingFunc passed')
}
export async function getActivities({ search, skip, amount, date, categories, mapBounds, universeId, municipality }) {
  const urlQueryParams = {}

  if (universeId !== undefined) urlQueryParams.universeId = universeId
  if (amount !== undefined) urlQueryParams.amount = amount
  if (skip !== undefined) urlQueryParams.skip = skip
  if (search !== undefined) urlQueryParams.search = search
  if (date !== undefined) urlQueryParams.date = date.toISOString()
  if (municipality !== 'all' && municipality !== 'undefined') urlQueryParams.municipalityId = municipality

  if (categories?.length > 0) {
    urlQueryParams.categories = categories.toString()
  }

  if (mapBounds !== null) {
    urlQueryParams.neLat = mapBounds.ne.lat
    urlQueryParams.neLng = mapBounds.ne.lng
    urlQueryParams.swLat = mapBounds.sw.lat
    urlQueryParams.swLng = mapBounds.sw.lng
  }

  const urlQuery = Object.entries(urlQueryParams).map(([key, value]) => {
    return key + '=' + value
  })

  const { result } = await apiGet('activities/search?' + urlQuery.join('&'))
  return result ?? { activities: [], pins: [] }
}

export async function getFullActivity(activityId) {
  if (!activityId) return
  const res = await apiGet(`activities/activity/${activityId}`)
  if (!res.data[0]) return
  return res.data[0]
}

export async function createActivity(data) {
  const res = await apiPost(data, 'activities/createActivity')
  const resJson = await res.json()
  if (!resJson.data) return
  return resJson.data[0]
}

export async function updateActivity(data) {
  const res = await apiPut(data, `activities/updateActivity/${data.id}`)
  return res.ok
}

export async function getOwnActivities() {
  const res = await apiGet(`activities/getOwnActivities`)
  if (!res.activities) return
  return res.activities
}

export async function deleteActivity(activityId, universeId) {
  const res = await apiDelete({ universeId }, `activities/deleteActivity/${activityId}`)
  return res.json()
}

export async function getHasAccessToActivities(setLoading = setLoadingFunc) {
  return apiGet('activities/getHasAccessToActivities')
    .then((res) => {
      return res.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.error(error)
      setLoading(false)
    })
}

export async function setActivityFavorite(activity) {
  const user = store.getState().user
  if (user) {
    const res = await apiPost({}, `activities/${activity.id}/favorite`)
    const result = await res.json()
    return result.data.is_favorite
  } else {
    const activities = JSON.parse(await AsyncStorage.getItem('favoriteActivities')) || []
    activities.push(activity)
    AsyncStorage.setItem('favoriteActivities', JSON.stringify(activities))
  }
}

export async function removeActivityFavorite(activity) {
  const user = store.getState().user
  if (user) {
    const res = await apiDelete({}, `activities/${activity.id}/favorite`)
    const result = await res.json()
    return result.data.is_favorite
  } else {
    let activities = JSON.parse(await AsyncStorage.getItem('favoriteActivities')) || []
    activities = activities.filter((a) => a.id !== activity.id)
    AsyncStorage.setItem('favoriteActivities', JSON.stringify(activities))
  }
}

export async function getFavoriteActivities() {
  const user = store.getState().user
  if (user) {
    const res = await apiGet(`activities/favorites`)
    return res.activities
  } else {
    return JSON.parse(await AsyncStorage.getItem('favoriteActivities')) || []
  }
}

export async function getCategories(universe = null) {
	const query = new URLSearchParams();
	if (universe !== null) query.append('universe', universe)

	const categories = await apiGet('activities/categories?' + query.toString())
	if (categories === undefined) return []

	categories.forEach((category) => {
		category.name = txt(`activities.categoryList.${category.name}`)
	});

	return categories
}