import React, { useState } from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { deleteBooking } from '../../../services/api/snakSammen'
import Layout from '../../../constants/Layout'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import moment from 'moment'
import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import SurveyPromptDialog, { platforms, shouldShowSurveyPrompt } from '../../dialogs/SurveyPromptDialog'
import { surveyIds } from '../../../services/api/Surveys'
import startVideoSession from '../../../utils/startVideoSession'
import { getConversationId } from '../../../services/api/Inbox'
import TextLabel from "../LearnDanish/TextLabel"
import UserDetailsDialog from "./dialogs/UserDetailsDialog"
import { formatDate } from "./utils/helperFunctions"
import OptionsDialog from "../../dialogs/OptionsDialog"
import ReportSproglandUserDialog from './dialogs/ReportSproglandUserDialog'

const isTablet = Dimensions.get('window').width < 900 && Dimensions.get('window').width > 400

export default function OwnBookedSessions(props) {
	//sets locale to danish time
	moment.locale([txt('videoChat.locale')])

	const [cancelDialogVisible, setCancelDialogVisible] = useState(false)
	const [currentItem, setCurrentItem] = useState('')
	const [surveyPromptVisible, setSurveyPromptVisible] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const [userSessionsDialogVisible, setUserSessionsDialogVisible] = useState(false)
	const [reportDialogVisible, setReportDialogVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	async function handleSendMessage() {
		const { bookerId, id } = currentItem
		if (!bookerId || !id) return
		
		const conversationId = await getConversationId({
			vsSessionId: id,
			receiverId: bookerId,
		})

		if (conversationId) props.navigateToConversation(conversationId)
		else props.navigateToNewConversation(bookerId, id)
	}

	// const triggerSurveyPrompt = async () => {
	// 	const show = await shouldShowSurveyPrompt(surveyIds.snaksammenVolunteer)
	// 	if (show) {
	// 		setSurveyPromptVisible(true)
	// 	}
	// }

	async function handleStartPress(item) {
		await startVideoSession(item.room_key, item.vonage_session_id, 'snaksammen')
		// await triggerSurveyPrompt()
	}

	function handleUserDetailsPress() {
		setUserSessionsDialogVisible(true)
	}

	const handleMore = (item) => {
		setCurrentItem(item)
		setShowOptions(true)
	}

	const handleDelete = async () => {
		setLoading(true)
		await deleteBooking(currentItem.id)
		await props.reloadAll()
		setCancelDialogVisible(false)
		setLoading(false)
	}

	const options = [
		{
			label: txt('videoChat.messageSend'),
			action: async () => {
				setShowOptions(false)
				await handleSendMessage()
			},
		},
		{
			label: txt('videoChat.cancel'),
			action: () => {
				setShowOptions(false)
				setCancelDialogVisible(true)
			}
		},
		{
			label: txt('videoChat.participantInfo'),
			action: () => {
				handleUserDetailsPress()
				setShowOptions(false)
			},
		},
		{
			label: txt('videoChat.volunteer.reportLanguageTraining'),
			action: () => {
				setShowOptions(false)
				setReportDialogVisible(true)
			}
		},
	]

	return (
		<View style={styles.container}>
			{props.ownBookedSessions?.length === 0 ? (
				<View>
					<CustomText font="gotham-xlarge" style={styles.titleBold}>
						{txt('videoChat.ownBookedSessions')}
					</CustomText>
					<CustomText font="gotham" style={styles.noSession}>
						{txt('videoChat.noOwnBookedSessions')}
					</CustomText>
				</View>
			) : (
				<CustomText font="gotham-xlarge" style={styles.titleBold}>
					{txt('videoChat.ownBookedSessions')}
				</CustomText>
			)}

			{props.ownBookedSessions?.map((item, key) => {
				const formattedDate = formatDate(item.start_date, item.end_date)
				return (
					<View key={key} style={styles.card}>
						<View style={styles.textContainer}>
							<CustomText font="gotham-bold-small">{formattedDate}</CustomText>
							<CustomText font="gotham-small">{txt('videoChat.participant') + ': ' + item.username}</CustomText>
							<View>
								{item.firstSession === true && (
									<TextLabel first style={{ marginRight: 6, marginTop: 6 }}/>
								)}
							</View>
						</View>

						<View style={styles.buttonsContainer}>
							<View style={styles.buttonStartContainer}>
								<ButtonPrimary
									title={txt('videoChat.start')}
									titleStyle={styles.buttonTitle}
									onPress={() => handleStartPress(item)}
									style={styles.button}
								/>
							</View>
							<View style={styles.buttonStartContainer}>
								<ButtonPrimary
									title={txt('videoChat.more')}
									titleStyle={styles.buttonTitle}
									onPress={() => handleMore(item)}
									style={styles.button}
								/>
							</View>
						</View>
					</View>
				)
			})}

			<CustomDialog visible={cancelDialogVisible} onDismiss={() => setCancelDialogVisible(false)}>
				<ContentContainer>
					<CustomText>{txt('videoChat.deleteDialogText')}</CustomText>
					<View style={[styles.buttonsContainer, { marginTop: 24, alignSelf: 'center' }]}>
						<ButtonPrimary
							onPress={() => setCancelDialogVisible(false)}
							style={styles.button}
							titleStyle={styles.buttonTitle}
						>
							{txt('videoChat.dialogNo')}
						</ButtonPrimary>
						<ButtonPrimary
							loading={loading}
							onPress={handleDelete}
							style={styles.button}
							titleStyle={styles.buttonTitle}
						>
							{txt('videoChat.dialogYes')}
						</ButtonPrimary>
					</View>
				</ContentContainer>
			</CustomDialog>

			<SurveyPromptDialog
				visible={surveyPromptVisible}
				surveyId={surveyIds.snaksammenVolunteer}
				platform={platforms.SnakSammen}
				surveyLink={'https://www.survey-xact.dk/LinkCollector?key=UY3XFQ9TS531'}
				onDismiss={() => setSurveyPromptVisible(false)}
				volunteer
			/>

			<OptionsDialog
				visible={showOptions}
				onDismiss={() => setShowOptions(false)}
				options={options}
			/>

			<UserDetailsDialog
				visible={userSessionsDialogVisible}
				onDismiss={() => setUserSessionsDialogVisible(false)}
				currentItem={currentItem}
			/>

			<ReportSproglandUserDialog
				visible={reportDialogVisible}
				onDismiss={() => setReportDialogVisible(false)}
				currentItem={currentItem}
			/>
      </View>
    )
}

const styles = StyleSheet.create({
	buttonStartContainer: {
		flex: isTablet ? null : 1,
		justifyContent: 'center',
		paddingRight: 2,
	},
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: Layout.small ? 12 : 0,
		alignSelf: Layout.small ? 'center' : 'auto',
	},
	container: {
		margin: 10,
		zIndex: -1,
	},
	noSession: {
		color: Colors.inactive,
		marginTop: 10,
	},
	textContainer: {
		flex: isTablet ? null : 2,
		justifyContent: 'center',
	},
	card: {
		backgroundColor: Colors.white,
		borderRadius: 10,
		flexDirection: Layout.small ? 'column' : 'row',
		alignItems: Layout.small ? 'flex-start' : 'center',
		marginTop: 10,
		paddingVertical: Layout.small ? 18 : 12,
		paddingHorizontal: Layout.small ? 24 : 18,
		justifyContent: 'space-between'
	},
	titleBold: {
		marginTop: 24,
		marginBottom: 6,
	},
	button: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.background,
		paddingVertical: 13,
		paddingHorizontal: 32,
		marginLeft: 12,
		minWidth: 115,
	},
	buttonTitle: {
		color: Colors.redCross.text,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
})
