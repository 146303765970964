import React from 'react'
import { View, FlatList, StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { SpacingStyles } from '../../../styles'
import ActivityListItem from './ActivityListItem'
import { CustomText } from '../../text/StyledText'

//https://react-native-elements.github.io/react-native-elements/docs/searchbar.html
export default class MyActivities extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activities: [],
    }
  }

  async componentDidMount() {
    const activities = await this.props.getOwnActivities()
    this.setState({ activities })

    this.unsubscribe = this.props.navigation.addListener('focus', async () => {
      this.setState({ activities: [] })
      const activities = await this.props.getOwnActivities()
      this.setState({ activities })
    })
  }

  componentWillUnmount() {
    this.unsubscribe?.()
  }

  // add activities to state
  setActivities = (activities) => {
    this.setState({ activities })
  }

  // https://react-native-elements.github.io/react-native-elements/docs/listitem.html
  keyExtractor = (item, index) => index.toString()

  _renderItem = ({ item }) => (
    <ActivityListItem
      item={item}
      onPressAction={() => {
        this.props.navigateToActivity(item.id, item.date_start)
      }}
    />
  )

  noActivities = () => {
    return (
      <View style={styles.container}>
        <CustomText style={styles.noMessage}>
          {txt('activities.edit.noOwnActivitiesTitle')}
        </CustomText>
        <CustomText style={styles.noMessage}>
          {txt('activities.edit.noOwnActivitiesText')}
        </CustomText>
      </View>
    )
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        {this.props.renderChips()}
        <FlatList
          keyExtractor={this.keyExtractor}
          data={this.state.activities}
          renderItem={this._renderItem}
          keyboardDismissMode="on-drag"
          ListEmptyComponent={this.noActivities}
        />
      </View>
    )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  noMessage: {
    color: Colors.inactive,
    margin: 5,
    marginTop: 20,
    textAlign: 'center',
  },
})
