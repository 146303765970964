import React from 'react'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import { ImageHighlight } from '../../images/ImageHighlight'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import { SpacingStyles } from '../../../styles'
import Layout from '../../../constants/Layout'
import MainContainer from '../../MainContainer/MainContainer'
import CustomPressable from "../../pressables/CustomPressable"

const images = [
  {
    name: 'Line',
    title: 'blog.lineTitle',
    header: 'blog.lineHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/line.png',
  },
  {
    name: 'Zuzan',
    title: 'blog.zuzanTitle',
    header: 'blog.zuzanHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/zuzan.png',
  },
  {
    name: 'Morten',
    title: 'blog.mortenTitle',
    header: 'blog.mortenHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/morten.jpeg',
  },
  {
    name: 'Thomas',
    title: 'blog.thomasTitle',
    header: 'blog.thomasHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/thomas.jpg',
  },
  {
    name: 'Conny',
    title: 'blog.connyTitle',
    header: 'blog.connyHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/conny.jpg',
  },
  {
    name: 'Emma',
    title: 'blog.emmaTitle',
    header: 'blog.emmaHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/emma.jpg',
  },
  {
    name: 'Rikke',
    title: 'blog.rikkeTitle',
    header: 'blog.rikkeHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/rikke.jpg',
  },
  {
    name: 'Anna',
    title: 'blog.annaTitle',
    header: 'blog.annaHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/anna.jpg',
  },
  {
    name: 'Nicolaj1',
    title: 'blog.nicolajTitle1',
    header: 'blog.nicolajHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/nicolaj.png',
  },
  {
    name: 'Nicolaj2',
    title: 'blog.nicolajTitle2',
    header: 'blog.nicolajHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/nicolaj.png',
  },
  {
    name: 'Finn',
    title: 'blog.finnTitle',
    header: 'blog.finnHeader',

    uri: 'https://filer.boblberg.dk/Assets/Img/blog/finn.jpg',
  },
]

class Panel extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View>
        <PageTitle title={TxtTMD.blog.title[TxtTMD.lang]} />

        <CustomPressable
          style={styles.padding25}
          onPress={() => this.props.pressF(this.props.name)}
        >
          <View style={styles.storyContainer}>
            <View>
              <ImageHighlight source={{ uri: this.props.uri }} />
            </View>
            <View style={styles.textContainer}>
              <CustomText
                font="smallBold"
                style={{ paddingLeft: 10 }}
                numberOfLines={Layout.isSmallDevice ? 3 : 4}
              >
                {txt(this.props.title)}
              </CustomText>
              <CustomText
                font="small"
                style={{ paddingLeft: 10 }}
                numberOfLines={Layout.isSmallDevice ? 3 : 4}
              >
                {txt(this.props.header)}
              </CustomText>
            </View>
          </View>
        </CustomPressable>
      </View>
    )
  }
}

export default class BlogsMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  navigateToPost = (blog) => {
    this.props.navigation.push('Blogs', {
      id: blog,
    })
  }

  render() {
    return (
      <MainContainer>
        <View style={styles.container}>
          <View style={styles.helpText}>
            <CustomText>{txt('blog.helpText')}</CustomText>
          </View>
          <View>
            {images.map((image, i) => {
              return (
                <Panel
                  uri={images[i].uri}
                  pressF={this.navigateToPost}
                  key={i}
                  header={image.header}
                  name={image.name}
                  title={image.title}
                />
              )
            })}
          </View>
        </View>
      </MainContainer>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.background,
    ...SpacingStyles.widthAndHeight,
    ...SpacingStyles.paddingRightLeft10,
  },
  helpText: { paddingBottom: 10 },
  padding25: { paddingBottom: 25 },
  storyContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },

  textContainer: { flex: 1 },
})
