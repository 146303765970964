import React, { useEffect, useState } from 'react'
import { Image, Linking, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import PageTitle from '../../components/tags/PageTitle'
import TxtTMD from '../../constants/TxtTMD'
import MainContainer from '../../components/MainContainer/MainContainer'
import store from '../../redux/store'
import { useNavigation } from '@react-navigation/core'
import { ButtonPrimary } from "../../components/buttons/StyledButtons"
import { getSessionsLast30Days } from "../../services/api/snakSammen"
import Layout from "../../constants/Layout"
import { isUserVolunteer } from "../../services/api/volunteerApi"

export default function SnakSammenIntroScreen() {
  const [userToken, setUserToken] = useState(null)
  const [sessionCount30Days, setSessionCount30Days] = useState(0)
  const [isVolunteer, setIsVolunteer] = useState(false)
  const navigation = useNavigation()

  useEffect(() => {
    const userToken = store.getState().userToken
    setUserToken(userToken)

    if (userToken) {
      isUserVolunteer().then(setIsVolunteer)
    }

    navigation.setOptions({
      headerBackTitle: ' ',
      headerStyle: {
        height: Layout.isSmallDevice ? 115 : 100,
        elevation: 0,
        shadowOpacity: 0,
        borderBottomWidth: 0,
        backgroundColor: Colors.white,
        borderBottomColor: Colors.redCross.border,
      },
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={{ uri: 'https://filer.boblberg.dk/assets/img/logo/rode-kors-og-boblberg-logo.png' }}
          accessibilityLabel={'SnakSammen'}
          accessibilityIgnoresInvertColors
        />
      ),
    })
  }, [])

  useEffect(() => {
    getSessionsLast30Days().then(setSessionCount30Days)
  }, [])

  const handleGetStartedClick = () => {
    if (userToken) navigation.navigate('SnakSammenOversigt')
    else navigation.navigate('SnakSammenSignup')
  }

  return (
    <MainContainer backgroundColor={Colors.redCross.background}>
      <PageTitle title={TxtTMD.snakSammen.title[TxtTMD.lang]} />

      <View style={[styles.section, {backgroundColor: Colors.redCross.backgroundTertiary}]}>
        <CustomText font="gotham-bold" style={styles.header}>
          {txt('videoChat.landing.header')}
        </CustomText>
       <CustomText font="gotham" style={styles.intro}>
          {txt('videoChat.landing.intro')}
        </CustomText>

        <ButtonPrimary style={styles.bookButton} titleStyle={styles.bookButtonText} onPress={handleGetStartedClick}>
          {txt('videoChat.landing.bookButton')}
        </ButtonPrimary>

        {isVolunteer === true && (
          <ButtonPrimary style={styles.volunteerButton} titleStyle={styles.bookButtonText} onPress={() => navigation.navigate('SnakSammenVolunteer')}>
            {txt('videoChat.landing.volunteerNavButton')}
          </ButtonPrimary>
        )}
        

        <CustomText style={{marginTop: 36, textAlign: 'center'}}>{txt('videoChat.landing.partnerTitle')}</CustomText>
        <Image
          source={{ uri: 'https://filer.boblberg.dk/assets/img/logo/rode-kors-og-boblberg-logo.png' }}
          style={styles.partnerImage}
          resizeMode="contain"
          accessibilityIgnoresInvertColors
        />

        <CustomText style={{marginTop: 24, textAlign: 'center'}}>{txt('videoChat.landing.supportedBy')}</CustomText>
        <Image
          source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/velux_fonden_logo_sort.png' }}
          style={styles.supportImage}
          resizeMode="contain"
          accessibilityIgnoresInvertColors
        />

        <View style={styles.quoteImageContainer}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/Eva.jpg' }}
            style={styles.quoteImage}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
          />
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/Christian.jpg' }}
            style={styles.quoteImage}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
          />
          <View style={styles.quoteContainer}>
            <CustomText font="gotham-small" style={styles.quote}>{txt('videoChat.landing.quote')}</CustomText>
            <CustomText font="gotham-bold-small" style={styles.quote}>{txt('videoChat.landing.quoteName')}</CustomText>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <CustomText font="gotham-large" style={styles.headerSecondary}>
          {txt('videoChat.landing.whatIsSnakSammen')}
        </CustomText>
        <CustomText font="gotham-bold-xlarge" style={styles.headerPrimary}>
          {txt('videoChat.landing.whatIsSnakSammenTitle')}
        </CustomText>
        <CustomText font="gotham">
          {txt('videoChat.landing.whatIsSnakSammenText')}
        </CustomText>

        <Image
          source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/Karoline-ung-bred.jpg'}}
          style={styles.imageFullWidth}
          resizeMode="cover"
          accessibilityIgnoresInvertColors
        />
      </View>


      <View style={[styles.section, {backgroundColor: Colors.white}]}>
        <CustomText font="gotham" style={[styles.headerSecondary, { textAlign: 'center' }]}>
          {txt('videoChat.landing.howToUse')}
        </CustomText>
        <CustomText font="gotham-bold" style={[styles.headerPrimary, { textAlign: 'center' }]}>
          {txt('videoChat.landing.snakSammen')}
        </CustomText>

        <View style={styles.howToUseItem}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/lock-icon.png'}}
            style={styles.howToUseIcon}
            resizeMode="contain"
            accessibilityIgnoresInvertColors
          />
          <CustomText font="gotham-bold" style={[styles.headerSecondary, { textAlign: 'center' }]}>
            {txt('videoChat.landing.howToUse1Title')}
          </CustomText>
          <CustomText font="gotham" style={{ textAlign: 'center', marginTop: 24 }}>
            {txt('videoChat.landing.howToUse1Body')}
          </CustomText>
        </View>

        <View style={styles.howToUseItem}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/person-icon.png'}}
            style={styles.howToUseIcon}
            resizeMode="contain"
            accessibilityIgnoresInvertColors
          />
          <CustomText font="gotham-bold" style={[styles.headerSecondary, { textAlign: 'center' }]}>
            {txt('videoChat.landing.howToUse2Title')}
          </CustomText>
          <CustomText font="gotham" style={{ textAlign: 'center', marginTop: 24 }}>
            {txt('videoChat.landing.howToUse2Body')}
          </CustomText>
        </View>

        <View style={styles.howToUseItem}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/monitor-play-icon.png'}}
            style={styles.howToUseIcon}
            resizeMode="contain"
            accessibilityIgnoresInvertColors
          />
          <CustomText font="gotham-bold" style={[styles.headerSecondary, { textAlign: 'center' }]}>
            {txt('videoChat.landing.howToUse3Title')}
          </CustomText>
          <CustomText font="gotham" style={{ textAlign: 'center', marginTop: 24 }}>
            {txt('videoChat.landing.howToUse3Body')}
          </CustomText>
        </View>

        <View style={styles.howToUseItem}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/certificate-icon.png'}}
            style={styles.howToUseIcon}
            resizeMode="contain"
            accessibilityIgnoresInvertColors
          />
          <CustomText font="gotham-bold" style={[styles.headerSecondary, { textAlign: 'center' }]}>
            {txt('videoChat.landing.howToUse4Title')}
          </CustomText>
          <CustomText font="gotham" style={{ textAlign: 'center', marginTop: 24 }}>
            {txt('videoChat.landing.howToUse4Body')}
          </CustomText>
        </View>

        <View style={styles.testimonialsContainer}>
          <CustomText font="gotham-bold" style={[styles.headerPrimary, { textAlign: 'center' }]}>
            {txt('videoChat.landing.testimonials')}
          </CustomText>

          <View style={styles.testimonialContainer}>
            <CustomText font="gotham">{txt('videoChat.landing.testimonials1Body')}</CustomText>
            <CustomText font="gotham-bold">{txt('videoChat.landing.testimonials1Name')}</CustomText>
          </View>

          <View style={styles.testimonialContainer}>
            <CustomText font="gotham">{txt('videoChat.landing.testimonials2Body')}</CustomText>
            <CustomText font="gotham-bold">{txt('videoChat.landing.testimonials2Name')}</CustomText>
          </View>

          <View style={styles.testimonialContainer}>
            <CustomText font="gotham">{txt('videoChat.landing.testimonials3Body')}</CustomText>
            <CustomText font="gotham-bold">{txt('videoChat.landing.testimonials3Name')}</CustomText>
          </View>

          <CustomText font="gotham-bold" style={[styles.headerSecondary, { textAlign: 'center', marginTop: 48 }]}>
            {txt('videoChat.landing.notAlone')}
          </CustomText>

          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/testimonials.png' }}
            resizeMode="contain"
            style={styles.peopleIllustration}
            accessibilityIgnoresInvertColors
          />

        </View>

      </View>

      <View style={[styles.section, { backgroundColor: Colors.white }]}>
        <CustomText font="gotham" style={[styles.headerSecondary, { textAlign: 'center' }]}>
          {txt('videoChat.landing.volunteerTitle')}
        </CustomText>
        <CustomText font="gotham-bold" style={[styles.headerPrimary, { textAlign: 'center' }]}>
          {txt('videoChat.landing.meetVolunteers')}
        </CustomText>

        <View style={styles.volunteerContainer}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/Marianne-frivilligportraet.jpg' }}
            style={styles.volunteerImage}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
          />
          <View style={styles.volunteerName}>
            <Image
              source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/user-icon.png' }}
              style={styles.personIcon}
              resizeMode="contain"
              accessibilityIgnoresInvertColors
            />
            <CustomText font="gotham" style={{color: Colors.redCross.textSecondary}}>
              {txt('videoChat.landing.volunteer1Name')}
            </CustomText>
          </View>
          <CustomText font="gotham" style={{marginTop: 6}}>
            {txt('videoChat.landing.volunteer1Testimonial')}
          </CustomText>
        </View>

        <View style={styles.volunteerContainer}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/Fernando-med-kop.jpg' }}
            style={styles.volunteerImage}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
          />
          <View style={styles.volunteerName}>
            <Image
              source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/user-icon.png' }}
              style={styles.personIcon}
              resizeMode="contain"
              accessibilityIgnoresInvertColors
            />
            <CustomText font="gotham" style={{color: Colors.redCross.textSecondary}}>
              {txt('videoChat.landing.volunteer2Name')}
            </CustomText>
          </View>
          <CustomText font="gotham" style={{marginTop: 6}}>
            {txt('videoChat.landing.volunteer2Testimonial')}
          </CustomText>
        </View>


        <View style={styles.volunteerContainer}>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/Yvonne-frivilligportraet.jpg' }}
            style={styles.volunteerImage}
            resizeMode="cover"
            accessibilityIgnoresInvertColors
          />
          <View style={styles.volunteerName}>
            <Image
              source={{ uri: 'https://filer.boblberg.dk/assets/img/snaksammen/landing/icons/user-icon.png' }}
              style={styles.personIcon}
              resizeMode="contain"
              accessibilityIgnoresInvertColors
            />
            <CustomText font="gotham" style={{color: Colors.redCross.textSecondary}}>
              {txt('videoChat.landing.volunteer3Name')}
            </CustomText>
          </View>
          <CustomText font="gotham" style={{marginTop: 6}}>
            {txt('videoChat.landing.volunteer3Testimonial')}
          </CustomText>
        </View>

        <ButtonPrimary
          style={[styles.bookButton, { backgroundColor: Colors.redCross.base, marginVertical: 24 }]}
          titleStyle={styles.bookButtonText}
          onPress={() => Linking.openURL('https://www.rodekors.dk/snaksammen/frivillig')}
        >
          {txt('videoChat.landing.volunteerButton')}
        </ButtonPrimary>

      </View>

      <View style={[styles.section, { backgroundColor: Colors.redCross.backgroundTertiary, paddingBottom: 48 }]}>
        <CustomText font="gotham" style={[styles.headerSecondary, { textAlign: 'center' }]}>
          {txt('videoChat.landing.sessionsCount1')}
        </CustomText>

        <CustomText font="gotham-bold" style={[styles.header, { textAlign: 'center', marginTop: 24, marginBottom: 0 }]}>
          {sessionCount30Days}
        </CustomText>

        <CustomText font="gotham" style={[styles.headerSecondary, { textAlign: 'center', marginTop: 24 }]}>
          {txt('videoChat.landing.sessionsCount2')}
        </CustomText>

      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  logo: {
    width: Layout.small ? 125 : 150,
    height: Layout.isSmallDevice ? 40 : 70
  },
  section: {
    backgroundColor: Colors.redCross.background,
    flexDirection: 'column',
    padding: 24,
  },
  header: {
    fontSize: 36,
    lineHeight: 40,
    textAlign: 'center',
    marginBottom: 24,
  },
  headerPrimary: {
    fontSize: 24,
    lineHeight: 28,
    marginVertical: 24,
  },
  headerSecondary: {
    fontSize: 18,
    lineHeight: 22,
    marginTop: 24,
  },
  intro: {
    textAlign: 'center'
  },
  bookButton: {
    backgroundColor: Colors.redCross.red,
    borderRadius: 6,
    width: '100%',
    paddingVertical: 15,
    marginTop: 24,
  },
  volunteerButton: {
    backgroundColor: Colors.redCross.red,
    borderRadius: 6,
    width: '100%',
    paddingVertical: 15,
    marginTop: 24,
  },
  bookButtonText: {
    fontFamily: 'gotham-bold',
    fontSize: 16,
  },
  partnerImage: {
    width: 250,
    height: 100,
    alignSelf: 'center',
  },
  supportImage: {
    width: 175,
    height: 75,
    alignSelf: 'center',
  },
  quoteImageContainer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 48,
  },
  quoteImage: {
    height: 300,
    width: '45%',
    borderRadius: 6,
  },
  quoteContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 12,
    position: 'absolute',
    bottom: 12,
    left: '7.5%',
    width: '85%',
  },
  quote: {
    lineHeight: 22,
  },
  imageFullWidth: {
    width: '100%',
    height: 200,
    alignSelf: 'center',
    borderRadius: 6,
    marginVertical: 48,
  },
  howToUseItem: {
    marginTop: 24,
    paddingHorizontal: 12,
  },
  howToUseIcon: {
    height: 60,
    width: 60,
    alignSelf: 'center',
  },
  testimonialsContainer: {
    backgroundColor: Colors.redCross.backgroundSecondary,
    marginTop: 48,
    padding: 24,
  },
  testimonialContainer: {
    backgroundColor: Colors.white,
    padding: 24,
    marginTop: 24,
  },
  peopleIllustration: {
    width: '70%',
    height: 100,
    alignSelf: 'center',
  },
  volunteerContainer: {
    marginTop: 24,
    marginBottom: 24,
  },
  volunteerName: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
  },
  volunteerImage: {
    width: '100%',
    height: 300,
  },
  personIcon: {
    height: 18,
    width: 18,
    marginRight: 6,
  }
})
