import React, { useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../../components/text/StyledText'
import { useRoute } from '@react-navigation/core'
import { getUniverseConfig } from '../../../universe/universeController'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { setOrigin } from '../../../redux/actions'
import store from '../../../redux/store'
import { joinUniverseById } from '../../../services/api/Universe'
import { getOwnUser } from '../../../services/api/User'
import CustomPressable from "../../../components/pressables/CustomPressable"

const participants = [
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/herlev_3b_logo.png',
    label: txt('universe.herlev.3b'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/herlev_sab_logo.png',
    label: txt('universe.herlev.sab'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/herlev_akb_logo.png',
    label: txt('universe.herlev.akb'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/herlev_herlevboligselskab_logo.png',
    label: txt('universe.herlev.hb'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/herlev_sortemosen_logo.png',
    label: txt('universe.herlev.sortemosen'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/kab_logo.png',
    label: txt('universe.herlev.kablogo'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/herlev_kommune_logo.png',
    label: txt('universe.herlev.herlevMunicipality'),
  },
]

const supporters = [
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/det-obelske-familiefond.png',
    label: txt('universe.herlev.3b'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/landsbyggefonden.svg',
    label: txt('universe.herlev.sab'),
  },
  {
    source: 'https://filer.boblberg.dk/assets/img/universe/novo-nordisk-fonden.svg',
    label: txt('universe.herlev.akb'),
  },
]

export default function HerlevIntro(props) {
  const route = useRoute()
  let universeName = route.params?.universe?.toLowerCase()
  const universeObject = getUniverseConfig(universeName)

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={{ uri: universeObject?.logo }}
          accessibilityLabel={universeObject?.logoLabel}
          accessibilityIgnoresInvertColors
          accessibilityRole="image"
        />
      ),
      title: universeObject?.pageTitle,
      headerBackTitle: txt('universe.neighborIntro.headerBackTitle'),
    })
  }, [])

  const handleGetStartedClick = async () => {
    if (store.getState().userToken) {
      await joinUniverseById(9)
      props.navigation.navigate('UniverseMenuStack', {
        screen: 'HerlevOffers',
        initial: false,
        params: { universe: universeName },
      })
      getOwnUser()
    } else {
      store.dispatch(setOrigin({ stack: 'UniverseMenuStack', screen: 'HerlevOffers' }))
      props.joinUniverse(universeName)
    }
  }

  const offers = [
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/boblberg.png',
      'label': txt('universe.herlev.partnerInfo.boblberg.name'),
      'text': txt('universe.herlev.partnerInfo.boblberg.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/broen-herlev.png',
      'label': txt('universe.herlev.partnerInfo.broenHerlev.name'),
      'text': txt('universe.herlev.partnerInfo.broenHerlev.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/area9.png',
      'label': txt('universe.herlev.partnerInfo.area9.name'),
      'text': txt('universe.herlev.partnerInfo.area9.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/rode-kors-herlev.png',
      'label': txt('universe.herlev.partnerInfo.rodeKorsHerlev.name'),
      'text': txt('universe.herlev.partnerInfo.rodeKorsHerlev.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/rode-kors.png',
      'label': txt('universe.herlev.partnerInfo.rodeKors.name'),
      'text': txt('universe.herlev.partnerInfo.rodeKors.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/we-care.png',
      'label': txt('universe.herlev.partnerInfo.weCare.name'),
      'text': txt('universe.herlev.partnerInfo.weCare.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/laer-at-tackle.png',
      'label': txt('universe.herlev.partnerInfo.laerAtTackle.name'),
      'text': txt('universe.herlev.partnerInfo.laerAtTackle.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/frivilligcenter-herlev.png',
      'label': txt('universe.herlev.partnerInfo.frivilligcenter.name'),
      'text': txt('universe.herlev.partnerInfo.frivilligcenter.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/herlev-bibliotek.png',
      'label': txt('universe.herlev.partnerInfo.herlevbibliotek.name'),
      'text': txt('universe.herlev.partnerInfo.herlevbibliotek.offerBox'),
    },
    {
      'img': 'https://filer.boblberg.dk/assets/img/universe/herlev-kommune-logo.png',
      'label': txt('universe.herlev.partnerInfo.herlevkommune.name'),
      'text': txt('universe.herlev.partnerInfo.herlevkommune.offerBox'),
    },
  ]

  return (
    <MainContainer backgroundColor={Colors.background}>
      <View style={styles.container}>
        <CustomText font="largeBold" style={styles.title}>
          {txt('universe.herlev.title')}
        </CustomText>
        <CustomText font="largeBold" style={styles.header}>
          {txt('universe.herlev.header')}
        </CustomText>

        <CustomPressable
          onPress={handleGetStartedClick}
          style={styles.btn}
          accessible
          accessibilityRole="button"
          accessibilityLabel={txt('universe.herlev.ctaBtn')}
        >
          <CustomText font="bold" style={styles.btnText}>
            {txt('universe.herlev.ctaBtn')}
          </CustomText>
        </CustomPressable>

        <CustomText font="bold" style={{ textAlign: 'center', textDecoration: 'underline', marginVertical: 12 }}>
          {txt('universe.herlev.offersHeader')}
        </CustomText>

        <View style={styles.flexContainer}>
          {offers.map((offer) => {
            return (
              <CustomPressable
                key={offer.label}
                style={styles.offerContainer}
                accessibilityRole='button'
              >
                <CustomText font="bold" style={styles.offerText}>{offer.text}</CustomText>
                <Image
                  source={{ uri: offer.img }}
                  resizeMode="contain"
                  style={styles.offerImage}
                  accessibilityIgnoresInvertColors
                  accessible
                  accessibilityRole="image"
                  accessibilityLabel={offer.label}
                />
              </CustomPressable>
            ) 
          })}
        </View>

        <CustomText font="largeBold" style={{ textAlign: 'center', marginTop: 12 }}>
          {txt('universe.herlev.endText')}
        </CustomText>

        <View style={styles.quotesContainer}>

          <View style={styles.quoteContainer}>
            <Image
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/erik-nielsen.jpg' }}
              resizeMode='contain'
              style={styles.quoteImage}
              accessible
              accessibilityRole='image'
              accessibilityLabel={txt('universe.herlev.quotes.quote1Name')}
              accessibilityIgnoresInvertColors
            />
            <View style={styles.quoteText}>
              <CustomText style={styles.quote}>{txt('universe.herlev.quotes.quote1')}</CustomText>
              <CustomText font={'smallBold'}>
                {txt('universe.herlev.quotes.quote1Name')}
                <CustomText font={'small'} style={{ color: Colors.inactive, fontWeight: 'normal' }}>
                  {txt('universe.herlev.quotes.quote1Title')}
                </CustomText>
              </CustomText>
            </View>
          </View>

          <View style={styles.quoteContainer}>
            <Image
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/thomas-gyldal-petersen.jpg' }}
              resizeMode='contain'
              style={styles.quoteImage}
              accessible
              accessibilityRole='image'
              accessibilityLabel={txt('universe.herlev.quotes.quote2Name')}
              accessibilityIgnoresInvertColors
            />
            <View style={styles.quoteText}>
              <CustomText style={styles.quote}>{txt('universe.herlev.quotes.quote2')}</CustomText>
              <CustomText font={'smallBold'}>
                {txt('universe.herlev.quotes.quote2Name')}
                <CustomText font={'small'} style={{ color: Colors.inactive, fontWeight: 'normal' }}>
                  {txt('universe.herlev.quotes.quote2Title')}
                </CustomText>
              </CustomText>
            </View>
          </View>

        </View>

      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 40,
    color: '#d86435',
    textAlign: 'center',
  },
  header: {
    marginBottom: 12,
    textAlign: 'center'
  },
  btn: {
    alignSelf: 'center',
    backgroundColor: '#d86435',
    borderRadius: 24,
    color: Colors.white,
    marginVertical: 24,
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  btnText: {
    color: Colors.white,
  },
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
    marginHorizontal: 12,
  },
  logo: {
    alignSelf: 'center',
    height: 32,
    marginRight: Layout.isSmallDevice ? 0 : 16,
    width: 200,
  },
  flexContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: Layout.maxFeedWidth,
  },
  offerContainer: {
    margin: Layout.isSmallDevice ? 6 : 10,
    minHeight: 140,
    width: Layout.isSmallDevice ? 156 : 200,
    backgroundColor: Colors.white,
    padding: Layout.isSmallDevice ? 6 : 12,
    borderRadius: 6,
  },
  offerImage: {
    alignSelf: 'center',
    height: Layout.isSmallDevice ? 50 : 50,
    maxWidth: 100,
    minWidth: 100,
  },
  offerText: {
    height: 60,
    marginBottom: 12,
    textAlign: 'center',
  },
  quotesContainer: {
    alignItems: 'center',
    marginVertical: 48,
    marginHorizontal: 12,
  },
  quoteContainer: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row',
    alignItems: 'center',
    maxWidth: 636,
    backgroundColor: Colors.white,
    padding: 12,
    borderRadius: 6,
    marginBottom: 24,
  },
  quoteImage: {
    width: 75,
    height: 75,
    borderRadius: 150,
  },
  quoteText: {
    flex: Layout.isSmallDevice ? null : 1,
    textAlign: Layout.isSmallDevice ? 'center' : 'left',
    marginLeft: Layout.isSmallDevice ? 0 : 24,
    marginTop: Layout.isSmallDevice ? 12 : 0,
  },
  quote: {
    marginBottom: 20
  }
})
