import React from "react"
import { StyleSheet } from "react-native"

import { txt } from "../../../../locales/i18n"

import CustomPressable from "../../../pressables/CustomPressable"
import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import { CustomText } from "../../../text/StyledText"

import NormalIcon from "../../../icons/NormalIcon"
import Colors from "../../../../constants/Colors"


export default function SessionCategoryDialog({ visible, onDismiss, categoryId }) {
	return (
		<CustomDialog visible={visible} onDismiss={onDismiss}>
			<CustomPressable onPress={onDismiss} style={styles.closeIcon}>
				<NormalIcon name="times" size={20} color={Colors.darkGrey}/>
			</CustomPressable>
			<ContentContainer style={{ margin: 36 }}>
				<CustomText font='largeBold' style={{ marginBottom: 12 }}>
					{txt(`learnDanish.categories.${categoryId}`)}
				</CustomText>
				<CustomText>
					{txt(`learnDanish.categories.descriptions.${categoryId}`)}
				</CustomText>
			</ContentContainer>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	closeIcon: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	},
})
