import React from 'react'
import { Platform, View } from 'react-native'
import { Helmet } from 'react-helmet'

export default class FacebookPixelSignup extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    if (Platform.OS === 'web') {
      return (
        <Helmet>
          <script>
            {`
              function fbPixel() { 
                fbq('trackSingle', '862002367260340', 'CompleteRegistration', {
                value: 0.00,
                currency: 'DKK'
                });
                fbq('trackSingle', '1927495120844743', 'Contact');
              }
              fbPixel()
              `}
          </script>
        </Helmet>
      )
    } else {
      return <View></View>
    }
  }
}
