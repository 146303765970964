import React, { useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'

import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import NormalIcon from '../../icons/NormalIcon'

import { txt } from '../../../locales/i18n'
import Paths from '../../../constants/Paths'
import Colors from '../../../constants/Colors'
import { SpacingStyles, TypographyStyles } from '../../../styles'

import * as groupService from '../../../services/api/Groups'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupInfo(props) {
  const [notifications, setNotifications] = useState(props.group.notifications)
  const [showNotificationDialog, setShowNotificationDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  async function handleToggleNotifications() {
    if (notifications) {
      return setShowNotificationDialog(true)
    }

    await updateNotificationSetting(true)
  }

  async function removeNotifications() {
    await updateNotificationSetting(false)
    setShowNotificationDialog(false)
  }

  async function updateNotificationSetting(notificationSetting) {
    setLoading(true)

    const notifications = await groupService.updateNotificationSetting(props.group.id, notificationSetting)
    notifications === null ? setShowErrorDialog(true) : setNotifications(notifications)

    setLoading(false)
  }

  return (
    <View style={[styles.container, props.style]}>
      <ScrollView>
        <View style={styles.margin}>
          <View style={styles.marginBottom}>
            <ImageHighlight
              source={{ uri: Paths.groupUrlBase + props.group.img_filename }}
              size="medium"
              borderColor={Colors.black}
            />
          </View>

          <CustomPressable disabled={loading} onPress={handleToggleNotifications} style={styles.notificationButton}>
            <NormalIcon
              stroke="fas"
              color={notifications ? Colors.tintColor : Colors.red}
              name={notifications ? "bell" : "bell-slash"}
            />
          </CustomPressable>

          <View accessible style={styles.textContainer}>
            <View style={styles.marginBottom}>
              <View style={styles.marginBottom}>
                <CustomText style={styles.title}>{txt('groups.info.title')}</CustomText>
              </View>
              <View style={styles.marginBottom}>
                <CustomText style={styles.text}>{props.group.name}</CustomText>
              </View>
            </View>
            <View style={styles.marginBottom}>
              <View style={styles.marginBottom}>
                <CustomText style={styles.title}>{txt('groups.info.description')}</CustomText>
              </View>
              <View style={styles.marginBottom}>
                <CustomText style={styles.text}>{props.group.description}</CustomText>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>

      <CustomDialog visible={showNotificationDialog} onDismiss={() => setShowNotificationDialog(false)}>
        <ContentContainer>
          <CustomPressable
            accessibilityRole="button"
            style={styles.dialogDismissButton}
            onPress={() => setShowNotificationDialog(false)}
          >
            <NormalIcon name="times" color={Colors.inactive} />
          </CustomPressable>

          <CustomText style={styles.dialogHeader}>{txt('groups.info.notificationLabel')}</CustomText>
          <CustomText style={styles.dialogBody}>{txt('groups.info.notificationDialogDescription')}</CustomText>

        </ContentContainer>
        <View style={{ paddingBottom: 16 }}>
          <ButtonPrimary
            onPress={removeNotifications}
            style={{ backgroundColor: Colors.errorBackground }}
            title={txt('groups.info.notificationDialogConfirm')}
            titleStyle={styles.dialogAcceptButton}
          />
        </View>
      </CustomDialog>

      <CustomDialog visible={showErrorDialog} onDismiss={() => setShowErrorDialog(false)}>
        <ContentContainer>
          <CustomPressable
            accessibilityRole="button"
            style={styles.dialogDismissButton}
            onPress={() => setShowErrorDialog(false)}
          >
            <NormalIcon name="times" color={Colors.inactive} />
          </CustomPressable>

          <CustomText style={[styles.dialogHeader, { color: Colors.red }]}>{txt('groups.info.errorLabel')}</CustomText>
          <CustomText style={styles.dialogBody}>{txt('groups.info.errorDialogDescription')}</CustomText>
        </ContentContainer>
        <View style={{ paddingBottom: 16 }}>
          <ButtonPrimary
            onPress={() => setShowErrorDialog(false)}
            style={{ backgroundColor: Colors.blueAzure }}
            title={txt('global.ok')}
            titleStyle={styles.dialogAcceptButton}
          />
        </View>
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  margin: { marginTop: 20 },
  marginBottom: { marginBottom: 10 },
  text: { ...TypographyStyles.text, textAlign: 'center' },
  textContainer: { margin: 10 },
  title: { ...TypographyStyles.titleBold, textAlign: 'center' },

  notificationButton: {
    position: 'absolute',
    top: 0,
    right: 10,
    margin: 12,
  },

  dialogDismissButton: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingLeft: 24,
    zIndex: 1,
  },

  dialogHeader: {
    fontSize: 26,
    fontFamily: 'raleway-semibold',
  },

  dialogBody: {
    marginTop: 12,
  },

  dialogAcceptButton: {
    paddingHorizontal: 12,
  },
})
