import React from 'react'
import CustomDialog from '../../dialogs/CustomDialog'
import { ScrollView, StyleSheet, View } from 'react-native'
import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import CustomPressable from "../../pressables/CustomPressable"

export default function JuniorSelectDialog({juniors, visible, onDismiss, selected, select, openCreate}) {

  function handleCreate() {
    onDismiss()
    openCreate()
  }

  function handleEdit(junior) {
    onDismiss()
    openCreate(junior)
  }

  function handleSelect(junior) {
    select(junior)
    onDismiss()
  }

  return (
    <CustomDialog visible={visible} onDismiss={onDismiss} style={styles.container}>
      <ScrollView style={{ flexGrow: 0 }}>
        {juniors.map(junior => {
          const isSelected = selected.id === junior.id
          return (
            <View key={junior.id} style={[styles.item, isSelected && styles.itemSelected]} >
              <CustomPressable
                style={{flexGrow: 1, flexDirection: 'row'}}
                onPressIn={() => select(junior)}
                onPress={() => handleSelect(junior)}
              >
                { isSelected &&
                  <NormalIcon
                    stroke='far'
                    size={20}
                    name="check"
                    color={Colors.text}
                    style={styles.iconLeft}
                  />
                }
                <CustomText font='bold' style={styles.text} numberOfLines={1}>
                  {junior.name}
                </CustomText>
              </CustomPressable>
              <CustomPressable onPress={() => handleEdit(junior)}>
                <NormalIcon
                  stroke='far'
                  size={16}
                  name="pen"
                  color={Colors.text}
                  style={styles.iconRight}
                />
              </CustomPressable>
            </View>
        )})}
        <View style={styles.item} >
          <CustomPressable onPress={handleCreate}>
            <NormalIcon
              stroke='fas'
              size={20}
              name="user-plus"
              color={Colors.text}
              style={styles.iconLeft}
            />
            <CustomText font='bold' style={styles.text}>
              {txt('learnDanish.junior.createPlaceholder')}
            </CustomText>
        </CustomPressable>
        </View>
      </ScrollView>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 300,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  item: {
    flexGrow: 1,
    flexDirection: 'row',
    paddingLeft: 56,
  },
  itemSelected: {
    backgroundColor: Colors.darkerSkyBlue
  },
  iconLeft: {
    position: 'absolute',
    left: -50,
    padding: 12,
  },
  iconRight: {
    padding: 14,
    paddingHorizontal: 20,
  },
  text: {
    flexGrow: 1,
    paddingVertical: 12,
    color: Colors.text,
  }
})