import React, { useEffect, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import Fontsizes from '../../constants/Fontsizes'
import OptionButton from '../buttons/OptionButton'
import OptionsDialog from '../dialogs/OptionsDialog'
import CustomDialog, { ContentContainer } from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { InputPrimary } from '../text/StyledTextinputs'
import { reportContent } from '../../services/api/Reports'
import { ButtonPrimary, ButtonSecondary } from '../buttons/StyledButtons'
import { txt } from '../../locales/i18n'
import Card from '../Card'
import NormalIcon from '../icons/NormalIcon'

export default function ReportContent({ optionList, reportingTypeId, contentId }) {
  const [showOptions, setShowOptions] = useState(false)
  const [showReportConfirm, setShowReportConfirm] = useState(false)
  const [options, setOptions] = useState(optionList ? [...optionList] : [])

  useEffect(() => {
    const reportOption = {
      label: txt('report.title'),
      icon: "flag-alt",
      action: handleReportPress,
    }

    setOptions((options) => [...options, reportOption])
  }, [])

  const toggleShowOptions = () => {
    setShowOptions((prev) => !prev)
  }

  const handleReportContent = async (message) => {
    const res = await reportContent({ reportingTypeId, contentId, message })
    if (res) return true
    toggleShowReportConfirm()
  }

  const toggleShowReportConfirm = () => {
    setShowReportConfirm((prev) => !prev)
  }

  const handleReportPress = async () => {
    toggleShowOptions()
    toggleShowReportConfirm()
  }

  return (
    <>
      {reportingTypeId === 1 ? (
        <Card style={styles.box} onPress={toggleShowReportConfirm}>
          <NormalIcon name="flag-alt" size={16} />
          <CustomText font="bold" style={{ paddingLeft: 12 }}>
            {txt('report.title')}
          </CustomText>
        </Card>
      ) : (
        <View style={{ padding: 6, marginHorizontal: 6 }}>
          <OptionButton
            style={{ marginLeft: 6 }}
            onPress={toggleShowOptions}
            accessibilityRole="button"
            accessibilityLabel={txt('report.title')}
            size={Fontsizes.l}
          />
        </View>
      )}
      <OptionsDialog visible={showOptions} onDismiss={toggleShowOptions} options={options} />
      <ConfirmReportDialog visible={showReportConfirm} confirm={handleReportContent} cancel={toggleShowReportConfirm} />
    </>
  )
}

function ConfirmReportDialog({ confirm, cancel, visible }) {
  const [reportMsg, setReportMsg] = useState(null)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const handleConfirm = async () => {
    const reported = await confirm(reportMsg)
    if (reported) {
      setReportMsg(null)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
        cancel()
      }, 1500)
    } else setError(true)
  }

  const handleClose = () => {
    setReportMsg(null)
    setError(false)
    cancel()
  }

  return (
    <CustomDialog onDismiss={error ? handleClose : Keyboard.dismiss} style={{ width: '90%' }} visible={visible}>
      <ContentContainer>
        <View style={{ margin: 6 }}>
          <CustomText font={'largeBold'} style={{ paddingBottom: 12 }}>
            {success ? txt('report.success') : error ? txt('report.error') : txt('report.title')}
          </CustomText>
          {!success && !error && (
            <InputPrimary
              multiline
              accessibilityLabel={txt('report.placeholder')}
              placeholder={txt('report.placeholder')}
              value={reportMsg}
              onChangeText={(value) => setReportMsg(value)}
            />
          )}
        </View>

        <ButtonPrimary
          title={txt('report.submit')}
          style={styles.button}
          icon="flag-alt"
          onPress={handleConfirm}
          success={success}
          error={error}
          disabled={reportMsg === null || reportMsg?.length < 2}
        />
        {!success && !error && (
          <ButtonSecondary title={txt('report.cancel')} style={styles.button} onPress={cancel} />
        )}
      </ContentContainer>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  button: { marginTop: 12, width: '100%' },
  box: {
    marginBottom: 12,
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
