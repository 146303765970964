import React from 'react'
import Union from '../../components/views/Union/Union'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class UnionScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  navigateToUnion = (unionId) => {
    this.props.navigation.push('EditUnion', {
      id: unionId,
    })
  }

  render() {
    return (
      <MainContainer>
        <Union
          navigation={this.props.navigation}
          navigateToUnion={this.navigateToUnion}
          route={this.props.route}
        />
      </MainContainer>
    )
  }
}
