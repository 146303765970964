//const tintColor = '#007ACC'

const colors = {
	white: '#fff',
	whiteTransparent: 'rgba(255,255,255,0.8)',
	red: '#E32020',
	redLight: '#ff6464',
	redMatte: '#cc614b',
	redChristmas: '#b11e31',
	orangeRf: '#ee7203',
	orange: '#f99746',
	darkBlue: '#0000ff',
	blue: '#2a6592',
	bluePale: 'rgba(42,101,146,0.1)',
	blueAzure: '#0084ff',
	blueAzurePale: '#cbe4ff',
	blueBaby: '#34bdeb',
	lightBlue: '#87cefa',
	lightLightBlue: '#EAF5F8',
	skyBlue: 'rgb(237,247,249)',
	darkerSkyBlue: '#A0CDDA',
	green: '#3b803a',
	greenBtn: '#21C18C',
	greenChristmas: '#096344',
	greenLight: '#97E17D',
	greenExtraLight: '#E7FCE9',
	greenIcon: '#1DAF59',
	greenText: '#084B00',
	black: '#000000',
	blackTransparent: 'rgba(0,0,0,0.12)',
	blackTransparent2: 'rgba(0,0,0,0.2)',
	blackTransparent54: 'rgba(0,0,0,0.54)',
	blackTransparent82: 'rgba(0,0,0,0.82)',
	dark: '#0a0a0a',
	darkDarkGrey: '#333333',
	darkGrey: '#696969',
	grey: '#808080',
	greyLight: '#c0c0c0',
	greyLightLight: '#f1f1f1',
	gold: '#c49c45',
	bronze: '#cd853f',
	transparent: 'transparent',
	labelText: '#045aff',
	labelBackground: '#e3ecff',
	juniorLabelText: '#197973',
	juniorLabelBackground: '#cbfffc',
	redCrossEmber: '#fef5f5',
	redCrossRed: '#e30a0b',
	redCrossGrey: '#e7e5e7',
	redCrossBackground: '#f8f8f8',
	redSoft: '#ff6464',
	julevenner: {
		yellow: '#EAEB5E',
	},
	redCross: {
		background: '#F4F4F2', // Secondary Sage 50
		backgroundSecondary: '#FEF5F5',
		text: '#473F3C', // Sage 900
		textSecondary: '#514742', // Sage 800
		backgroundTertiary: '#E4E3DD',
		base: '#73665B', // Secondary/Sage/600 (base)
		baseSecondary: '#CBC6BD', // Secondary/Sage/200
		red: '#E30A0B', // Red 600
		border: '#e7e5e7',
	}
}

const theme = {
	background: colors.skyBlue, // Also change in app.json for splash image
	backgroundContent: colors.white,
	tintColor: colors.blue,
	errorBackground: colors.red,
	errorText: colors.white,
	noticeBackground: colors.blue,
	noticeText: colors.white,
	badgeColor: colors.redMatte,
	danger: colors.redLight,
	title: colors.darkBlue,
	text: colors.darkDarkGrey,
	textLight: colors.blackTransparent54,
	textPlaceholder: colors.greyLight,
	tabBarActive: colors.blue,
	tabBarInActive: colors.blackTransparent54,
	tabBarBackground: colors.skyBlue,
	tabBarBackgroundActive: colors.skyBlue,
	postBackground: colors.white,
	inactive: colors.darkGrey,
	shadow: colors.blackTransparent2,
	chipSelected: colors.white,
	chipUnselected: colors.blackTransparent,
	modalBackground: colors.blackTransparent54,
	filterBackground: colors.white,
	filterBackgroundClicked: colors.blue,
	newYearPrimary: colors.gold,
}

export default { ...theme, ...colors }

// Used for progress bars
export const getColorFromPercent = (percent) => {
	let r = percent <= 50 ? 100 : 100 - percent + 50
	let g = percent * 2 > 100 ? 100 : percent * 2
	let b = 0
	return `rgb(
		${parseInt((255 / 100) * r, 10)},
		${parseInt((255 / 100) * g, 10)}, 
		${parseInt((255 / 100) * b, 10)})`
}
