import { getUniverseConfigById } from '../universe/universeController'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import * as RootNavigation from './RootNavigation'
import { useSelector } from 'react-redux'
import store from '../redux/store'
import { setOrigin } from '../redux/actions'
import { initialUrlAtom, jotaiStore } from '../atoms'

export function useRedirect() {
	const origin = useSelector((state) => state.origin)
	const preferences = useSelector((state) => state.preferences)
	const ownGroups = useSelector((state) => state.groups)

	useEffect(() => {
		// Redirect user after login if origin is set

		if (origin.rootStack) {
			let params = (origin.screen && origin.stack) ?
				{
					screen: origin.stack,
					params: {
						screen: origin.screen,
						initial: false,
						params: { universe: origin.universe, ...origin.params },
					},
				}
			:
				{ universe: origin.universe }

			setTimeout(() => {
				RootNavigation.navigate(origin.rootStack, params)
				store.dispatch(setOrigin({ screen: null, stack: null, universe: null }))
			}, 0)
			return
		}

		// Redirect user according to preferences
		if (Platform.OS === 'web') {
			const regex = /^http[s]*:\/\/[a-z.:0-9]*\/(?![L,l]ogin).+$/gm // Matches any sub path after 'boblberg.dk/' except for 'boblberg.dk/login'
			if (regex.exec(window.location.href)) return // If match do not redirect further
		}

		const initialUrl = jotaiStore.get(initialUrlAtom)
		if (initialUrl?.includes('/u/')) return

		if (preferences.default_universe === 11) {
			return RootNavigation?.replace('christmasFriends')
		}

		if (preferences.default_universe) {
			const universe = getUniverseConfigById(preferences.default_universe)
			if (universe) {
				RootNavigation?.navigate('universe', {
					screen: 'UniverseBoblStack',
					params: {
						screen: 'Bobls',
						initial: false,
						params: { universe: universe.name },
					},
				})
			}
			return
		}

		if (preferences.start_screen) {
			if (preferences.start_screen === 'Groups') {
				const stack = ownGroups?.length > 0 ? 'GroupsStack' : 'MenuStack'
				return RootNavigation.navigate(stack, {
					screen: preferences.start_screen,
					initial: false,
				})
			}

			if (preferences.start_screen === 'Sprogland') {
				return RootNavigation.navigate('MenuStack', {
					screen: 'LearnDanish',
					initial: false,
				})
			}
		}
	}, [])
}
