import React from 'react'
import CreateEditPost from '../../../components/views/Post/CreateEditPost'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { createPost as createPostApi } from '../../../services/api/Posts'
import { getUniverseConfig } from '../../../universe/universeController'

export default function CreateBoblUniverseScreen(props) {
	const universe = getUniverseConfig(
    props.route.params.universe?.toLowerCase()
	)
	
	const createPost = async (data) => {
		// Don't set universeId, if universe is 'Krifabobler' (id = 13
    if (universe.id !== 13) data.universeId = universe.id
    return await createPostApi(data)
  }

  return (
    <MainContainer backgroundColor={universe?.backgroundColor}>
      <PageTitle title={TxtTMD.createPost.title[TxtTMD.lang]} />
			<CreateEditPost
				createPost={createPost}
				navigation={props.navigation}
				route={props.route}
			/>
    </MainContainer>
  )
}
