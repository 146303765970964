import React from 'react'

import Demantec2 from '../../components/views/About/ProjectsViews/Demantec2'
import SBB from '../../components/views/About/ProjectsViews/SBB'
import PHD from '../../components/views/About/ProjectsViews/PHD'
import CHS from '../../components/views/About/ProjectsViews/CHS'
import OPILDL from '../../components/views/About/ProjectsViews/OPILDL'
import OPIFAKTO from '../../components/views/About/ProjectsViews/OPIFakto'
import MainContainer from '../../components/MainContainer/MainContainer'
import OEUG from '../../components/views/About/ProjectsViews/OEUG'
import AnalyseAfMentalMistrivsel from '../../components/views/About/ProjectsViews/AnalyseAfMentalMistrivsel'

export default function ProjectsScreen(props) {
	function checkSwitch(param) {
		switch (param) {
		case 'OEUG':
			return <OEUG />

		case 'Demantec2':
			return <Demantec2 />

		case 'SBB':
			return <SBB />

		case 'PHD':
			return <PHD />

		case 'CHS':
			return <CHS />

		case 'OPILDL':
			return <OPILDL />

		case 'OPIFAKTO':
			return <OPIFAKTO />
			
		case 'analyse-af-mental-mistrivsel':
			return <AnalyseAfMentalMistrivsel />	

		default:
			if (__DEV__) console.log('DEFAULT SWITCH')
		}
	}

	return <MainContainer>{checkSwitch(props.route.params.id)}</MainContainer>
}
