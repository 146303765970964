import React from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import ChristmasPostEdit from '../../components/ChristmasPostEdit'
import {
  createChristmasPost,
  deleteChristmasPost,
  updateChristmasPost,
} from '../../../services/api/ChristmasFriends'
import { useSelector } from 'react-redux'

export default function PostEditChristmasScreen(props) {
  const post = useSelector((state) => state.christmas.post)

  async function handleSubmit(christmasPost) {
    christmasPost.type = post.type
    const replacePostFromLastYear = post.created.slice(0,4) < new Date().getFullYear() && christmasPost.status === 1
    const postResult = replacePostFromLastYear ?
      await createChristmasPost(christmasPost) :
      await updateChristmasPost(christmasPost)
    if (postResult) {
      props.navigation.navigate('MyPostsChristmas')
    }
  }

  async function handleDelete(id) {
    return deleteChristmasPost(id)
  }

  return (
    <MainContainer>
      <ChristmasPostEdit
        post={post}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
    </MainContainer>
  )
}
