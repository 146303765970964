import React from 'react'
import { Snackbar } from 'react-native-paper'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import { StyleSheet } from 'react-native'
import { CornerStyles } from '../../styles'

export function NormalSnackbar(props) {
  return (
    <Snackbar
      duration={3000}
      {...props}
      style={[styles.snackbar, props.style]}
    />
  )
}

export function ErrorNormalSnackbar(props) {
  return (
    <Snackbar
      duration={3000}
      {...props}
      style={[styles.errorSnackbar, props.style]}
    />
  )
}

export function PopupSnackbar(props) {
  return (
    <Snackbar
      {...props}
      visible={props.visible}
      duration={1000000}
      style={styles.snackbarCenter}
      theme={{
        colors: { accent: Colors.white },
        fonts: { light: 'raleway-semibold' },
      }}
    >
      {props.children}
    </Snackbar>
  )
}

const styles = StyleSheet.create({
  errorSnackbar: {
    ...CornerStyles.borderRadiusM,
    alignSelf: 'center',
    backgroundColor: Colors.red,
    color: Colors.white,
    maxWidth: Layout.maxFeedWidth,
  },
  snackbar: {
    alignSelf: 'center',
    backgroundColor: Colors.text,
    ...CornerStyles.borderRadiusM,
  },
  snackbarCenter: {
    backgroundColor: Colors.green,
    ...CornerStyles.borderRadiusS,
    alignSelf: 'center',
    width: 225,
  },
})
