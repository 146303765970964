// libraries
import React, { useEffect, useRef, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Portal } from 'react-native-paper'
import moment from 'moment'

// api
import { createBooking, getPreviousBookers } from '../../../services/api/snakSammen'

// components
import { SnakSammenDuration } from './volunteer/SnakSammenDurationSelector'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import RenderPreviousBookers from './volunteer/RenderPreviousBookers'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { CustomInputs } from '../../text/StyledTextinputs'
import { NormalSnackbar } from '../../snackbars/Snackbar'
import { CustomText } from '../../text/StyledText'
import DatePicker from '../../dates/DatePicker'

// other
import { SpacingStyles, ShadowStyles } from '../../../styles'
import RegexPatterns from '../../../constants/RegexPatterns'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import { useSelector } from "react-redux"

const small = Layout.isSmallDevice

export default function CreateSessions(props) {
	moment.locale([txt('global.locale')])
	const userRedux = useSelector((state) => state.user)

	const [loading, setLoading] = useState(false)
	const [startDate, setStartDate] = useState(new Date())
	const [durationChosen, setDurationChosen] = useState(15)
	const [durationLabelChosen, setDurationLabelChosen] = useState(txt('videoChat.volunteer.create.duration15'))
	const [emailToInvite, setEmailToInvite] = useState('')
	const [isEmailInviteChecked, setIsEmailInviteChecked] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	const [previousBookers, setPreviousBookers] = useState([])
	const [selectedBooker, setSelectedBooker] = useState('')

	const [created, setCreated] = useState(false)
	const minTime = useRef(new Date().setHours(5, 0))
	const maxTime = useRef(new Date().setHours(23, 0))
	const maximumDate = useRef(new Date().setMonth(new Date().getMonth() + 6))

	useEffect(() => {
		getPreviousBookers().then(setPreviousBookers)
		roundUpDate()
	}, [])

	useEffect(() => {
		setErrorMsg('')
		if (isEmailInviteChecked) validateEmail()
	}, [emailToInvite])

	function handleErrorMessages(message) {
		if (message === 'assigned not found') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.notFound'))
		if (message === 'assigned and volunteer are the same') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.identical'))
		if (message === 'volunteer already booked in time frame') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.volunteerAlreadyBooked'))
		if (message === 'volunteer or assigned already booked in time frame') return setErrorMsg(txt('videoChat.volunteer.bookingErrors.participantAlreadyBooked'))
		return setErrorMsg(txt('videoChat.volunteer.bookingErrors.invalidInputs'))
	}

	function roundUpDate() {
		const coeff = 1000 * 60 * 5
		const roundedStartDate = new Date(Math.ceil(startDate / coeff) * coeff)
		setStartDate(roundedStartDate)
	}

	function checkIfDateAndTimeIsValid() {
		const date = new Date(startDate)
		const isDateValid = date > new Date()
		if (isDateValid) return true
		setErrorMsg(txt('invalidNotice.dateTimeHasPassed'))
		return false
	}

	function validateEmail() {
		if (!isEmailInviteChecked) {
			setEmailToInvite('')
			return true
		}
		if (emailToInvite === userRedux.email) {
			setErrorMsg(txt('videoChat.volunteer.bookingErrors.cannotInviteSelf'))
			return false
		}
		const conditions = RegexPatterns.email.map((rule) => new RegExp(rule, 'g'))
		const isEmailValid = conditions.map((condition) => condition.test(emailToInvite))
		if (isEmailValid.includes(false) && emailToInvite?.length > 0) {
			setErrorMsg(txt('videoChat.volunteer.bookingErrors.invalidEmail'))
			return false
		} else return true
	}

	async function create() {
		setLoading(true)

		const date = new Date(startDate)
		const newEndDate = new Date(startDate)
		newEndDate.setMinutes(date.getMinutes() + Number(durationChosen))

		const created = await createBooking({
			startDate: startDate,
			endDate: newEndDate,
			emailToInvite: emailToInvite,
		})


		if (!created.id) {
			handleErrorMessages(created)
			return setLoading(false)
		}

		setCreated(true)
		resetInputs()
		setIsEmailInviteChecked(false)
		setSelectedBooker('')
		setTimeout(() => setCreated(false), 600)

		props.reloadAll()
		setLoading(false)
	}

	function resetInputs() {
		setErrorMsg('')
		setEmailToInvite('')
		setIsEmailInviteChecked(false)
		setSelectedBooker('')
		setStartDate(new Date())
		setDurationChosen(15)
		setDurationLabelChosen(txt('videoChat.volunteer.create.duration15'))
		resetBooker()
	}

	async function handleBookingSubmit() {
		const isValid = (checkIfDateAndTimeIsValid() && validateEmail())
		if (isValid === true) await create()
	}

	function setMinTime() {
		const today = new Date()
		const isToday = (
			startDate.getDate() === today.getDate() &&
			startDate.getMonth() === today.getMonth() &&
			startDate.getFullYear() === today.getFullYear()
		)
		if (isToday) return new Date()
		return minTime.current
	}

	function handleEmailChange(email) {
		setEmailToInvite(email)
		if (email.length > 0) setIsEmailInviteChecked(true)
		else setIsEmailInviteChecked(false)
	}

	function handleDateChange(date, isTime) {
		if (!isTime) return setStartDate(date)

		setStartDate((startDate) => {
			const newDate = new Date(startDate)
			newDate.setHours(date.getHours(), date.getMinutes(), 0, 0)
			return newDate < new Date() ? new Date() : newDate
		})
	}

	function handleDurationChange(duration) {
		setDurationLabelChosen(duration.label)
		setDurationChosen(duration.value)
	}

	const selectBooker = (booker) => {
		setSelectedBooker(booker.username)
		setIsEmailInviteChecked(true)
		setEmailToInvite(booker.email)
	}

	const resetBooker = () => {
		setSelectedBooker('')
		setEmailToInvite('')
		setIsEmailInviteChecked(false)
	}

	return (
		<View style={styles.container}>
			<CustomText font="gotham-xlarge" style={styles.pickDateContainer}>
				{txt('videoChat.volunteer.create.createSession')}
			</CustomText>

			<View style={!small && { flexDirection: 'row', alignItems: 'center', gap: 12, flex: 1 }}>

				<View style={[{ marginTop: 12}, !Layout.isSmallDevice && { flex: 3 }]}>
					<CustomText font="gotham-bold-small">
						{txt('videoChat.volunteer.create.date')}
					</CustomText>
					<DatePicker
						date={startDate}
						minTime={setMinTime()}
						maxTime={maxTime.current}
						minDate={new Date()}
						maxDate={maximumDate.current}
						minuteInterval={5}
						handleDateChange={handleDateChange}
						textStyle={{ fontFamily: 'gotham-book', fontSize: 15 }}
						style={styles.picker}
						buttonStyle={[styles.button, { marginTop: 12, marginLeft: 0 }]}
						buttonTitleStyle={styles.buttonTitle}
					/>
				</View>

				<SnakSammenDuration
					duration={durationLabelChosen}
					handleDurationChange={handleDurationChange}
					style={!Layout.isSmallDevice && {flex: 2}}
				/>

				<View style={[{ marginTop: 12 }, !Layout.isSmallDevice && { flex: 4 }]}>
					<CustomText font="gotham-bold-small" style={{ marginBottom: 12 }}>
						{txt('videoChat.volunteer.create.invite')}
					</CustomText>

					{(previousBookers.length > 0) ? (
						<RenderPreviousBookers
							data={previousBookers}
							onChange={selectBooker}
							reset={resetBooker}
							selected={selectedBooker}
						/>
					) : (
						<CustomInputs
							value={emailToInvite}
							placeholder={txt('learnDanish.emailPlaceholder')}
							keyboardType={'email-address'}
							autoComplete={'email'}
							autoCapitalize="none"
							pattern={RegexPatterns.email}
							onChangeText={handleEmailChange}
							textStyle={{ fontFamily: 'gotham-book', fontSize: 15 }}
							style={{...ShadowStyles.unsetShadows, ...styles.picker, marginTop: 0 }}
						/>
					)}
				</View>

				{(previousBookers.length > 0 && !selectedBooker) && (
					<View style={!Layout.isSmallDevice && { marginTop: 36, flex: 4 }}>
							<CustomInputs
								value={emailToInvite}
								placeholder={txt('learnDanish.emailPlaceholder')}
								keyboardType={'email-address'}
								autoComplete={'email'}
								autoCapitalize="none"
								pattern={RegexPatterns.email}
								onChangeText={handleEmailChange}
								textStyle={{ fontFamily: 'gotham-book', fontSize: 15 }}
								style={{...ShadowStyles.unsetShadows, ...styles.picker }}
							/>
					</View>
				)}


				<ButtonPrimary
					title={txt('videoChat.volunteer.create.createSession')}
					titleStyle={styles.buttonTitle}
					style={styles.button}
					icon="plus"
					success={created}
					iconSize={16}
					stroke={'fal'}
					onPress={handleBookingSubmit}
					loading={loading}
				/>

			</View>

			{(errorMsg !== '' && errorMsg !== null) && (
				<InvalidInputNoticeCentered>{errorMsg}</InvalidInputNoticeCentered>
			)}
			<Portal>
				<NormalSnackbar visible={created} onDismiss={() => setCreated(false)}>
					{txt('videoChat.volunteer.create.sessionCreated')}
				</NormalSnackbar>
			</Portal>
		</View>
	)
}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		...SpacingStyles.marginRightLeft10,
	},
	pickDateContainer: {
		marginBottom: 10,
		marginTop: 5,
	},
	picker: {
		borderRadius: 6,
		...ShadowStyles.unsetShadows,
		borderWidth: 1,
		marginTop: 12,
		borderColor: Colors.redCross.border,
	},
	button: {
		borderRadius: 6,
		backgroundColor: Colors.redCross.red,
		paddingVertical: 10,
		marginLeft: Layout.isSmallDevice ? 0 : 12,
		marginTop: Layout.isSmallDevice ? 24 : 48,
		minWidth: 115,
	},
	buttonTitle: {
		color: Colors.white,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
})
