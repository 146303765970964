import { apiPost } from './ApiRequest'

/**
 table: reporting_type
 0 - post
 1 - conversation
 2 - activity
 3 - union
 */
export async function reportContent({ reportingTypeId, contentId, message }) {
  const body = { reportingTypeId, contentId, message }
  let res = await apiPost(body, 'reportings')
  return res.json()
}