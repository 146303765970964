import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../../dialogs/CustomDialog'
import Colors from '../../../../constants/Colors'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import CustomPressable from "../../../pressables/CustomPressable"
import NormalIcon from "../../../icons/NormalIcon"

const DataConsentDialog = (props) => {
  const [error, setError] = useState('')

  const handleSend = () => {
    if (props.consent) {
      props.send()
      setError('')
    } else {
      setError(txt('groups.dataResponsibilityConsentRequired'))
    }
  }

  const handleCheckPress = () => {
    setError('')
    props.toggleConsent()
  }

  return (
    <CustomDialog visible={props.open} onDismiss={props.cancel}>
      <ContentContainer>
        <CustomText>
          {txt('groups.dataResponsibilityConsent') +
            props.dataResponsible || ''}
        </CustomText>
        <CustomPressable style={styles.checkBoxContainer} onPress={handleCheckPress}>
          <NormalIcon
            name={props.consent ? 'square-check' : 'square'}
            color={props.consent ? Colors.blueAzure : Colors.greyLight}
            stroke={props.content ? 'fas' : 'fal'}
            size={18}
            style={{paddingRight: 12}}
          />
          <CustomText style={{ flex: 1, flexWrap: 'wrap' }}>
            {txt('groups.dataResponsibilityAccept') +
              props.dataResponsible || ''}
          </CustomText>
        </CustomPressable>
        {!!error && (
          <CustomText style={{ color: Colors.red }}>{error}</CustomText>
        )}
      </ContentContainer>
      <ActionButton onPress={props.cancel}>
        {txt('groups.cancel')}
      </ActionButton>
      <ActionButton onPress={handleSend}>
        {txt('groups.accessText.access')}
      </ActionButton>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  checkBoxContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 12,
  },
})

export default DataConsentDialog
