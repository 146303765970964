import React from 'react'
import { Image, View, StyleSheet } from 'react-native'
import { BorderStyles } from '../../styles'
import Colors from '../../constants/Colors'

export function ImageHighlight(props) {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          accessibilityIgnoresInvertColors
          style={[
            props.size === 'small'
              ? styles.smallHighlight
              : props.size === 'medium'
              ? styles.mediumHighlight
              : props.size === 'large'
              ? styles.largeHighlight
              : styles.highlight,

            props.borderColor ? { borderWidth: 2, borderColor: props.borderColor } : styles.border,

          ]}
          {...props}
        />
      </View>
      <View>
        <Image
          style={
            props.size === 'small'
              ? styles.smallHighlight
              : props.size === 'medium'
              ? styles.mediumHighlight
              : props.size === 'large'
              ? styles.largeHighlight
              : styles.highlight
          }
          source={require('../../assets/images/bobl_highlight.png')}
          accessibilityIgnoresInvertColors
        />
      </View>
    </View>
  )
}

export function ImageHighlightPost(props) {
  return (
    <View style={[styles.container, { flex: 1 }]}>
      <View style={styles.imageContainer}>
        <Image
          {...props}
          accessibilityIgnoresInvertColors
          style={[styles[`image${props.size || 'large'}`], props.borderColor ? { borderWidth: 2, borderColor: props.borderColor } : styles.border]}
        />
      </View>
      <View>
        <Image
          style={styles[`image${props.size || 'large'}`]}
          accessibilityIgnoresInvertColors
          source={require('../../assets/images/bobl_highlight_lg.png')}
        />
      </View>
    </View>
  )
}

export function ImageHighlightMedium(props) {
  return (
    <View style={styles.centerAlignItems}>
      <View style={styles.imageContainer}>
        <Image
          {...props}
          style={[styles.mediumHighlight, styles.border]}
          accessibilityIgnoresInvertColors
        />
      </View>
      <View>
        <Image
          {...props}
          style={styles.mediumHighlight}
          source={require('../../assets/images/bobl_highlight.png')}
          accessibilityIgnoresInvertColors
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  blackBorder: {
    borderColor: Colors.black,
    borderWidth: 2,
  },
  border: {
    borderColor: Colors.lightBlue,
    borderWidth: 2,
  },
  centerAlignItems: { alignItems: 'center' },
  container: { alignItems: 'center' },
  greenBorder: {
    borderColor: Colors.green,
    borderWidth: 2,
  },
  highlight: {
    borderRadius: 200,
    height: 100,
    width: 100,
  },
  imageContainer: { position: 'absolute' },
  imagelarge: {
    borderRadius: 200,
    height: 225,
    width: 225,
  },
  imagemedium: {
    borderRadius: 200,
    height: 200,
    width: 200,
  },
  imagesmall: {
    borderRadius: 100,
    height: 50,
    width: 50,
  },
  largeHighlight: {
    borderRadius: 200,
    height: 225,
    width: 225,
  },
  mediumHighlight: {
    borderRadius: 200,
    height: 150,
    width: 150,
  },
  smallHighlight: {
    borderRadius: 100,
    height: 50,
    width: 50,
  },
})
