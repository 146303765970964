import React, { useEffect, useRef } from 'react'
import { View, StyleSheet } from "react-native"
import { useNavigation } from "@react-navigation/core"
import LottieView from 'lottie-react-native';

import CustomDialog from '../dialogs/CustomDialog'
import CustomPressable from '../pressables/CustomPressable'
import { CustomText } from '../text/StyledText'

import Colors from "../../constants/Colors"
import { txt } from "../../locales/i18n"
import { useDeepLinkingStatus } from '../../hooks/DeepLinkingStatus'

import bubbleAnimation from '../../assets/animations/bubble.json'
import checkedAnimation from '../../assets/animations/check.json'
import errorAnimation from '../../assets/animations/error.json'

export const DeepLinkSessionDialog = () => {
	const { status, set } = useDeepLinkingStatus()

	const navigation = useNavigation()
	const animation = useRef(null)

	useEffect(() => {
		if (navigation) {
			const unsubscribeFocus = navigation?.addListener('focus', () => animation.current?.resume())
			const unsubscribeBlur = navigation?.addListener('blur', () => animation.current?.pause())

			return () => {
				unsubscribeFocus()
				unsubscribeBlur()
			}
		}
	}, [])

	const LoadingContent = ({ header, body, buttonText, animationFile }) => (
		<View style={styles.contentContainer}>
			<CustomText font="largeBold" style={styles.header}>{header}</CustomText>
			<CustomText font="small" style={styles.body}>{body}</CustomText>
			{/*<View style={{ marginVertical: 24 }}>*/}
			{/*	<LottieView autoPlay ref={animation} style={styles.animation} source={animationFile ?? require('../../assets/animations/bubble.json')} />*/}
			{/*</View>*/}

			<CustomPressable accessibilityRole="button" onPress={() => set('idle')}>
				<CustomText font="smallBold" style={styles.buttonText}>{buttonText}</CustomText>
			</CustomPressable>
		</View>
	)

	const statusProps = {
		success: {
			header: txt('subscription.dialogs.deepLinkSessionDialog.successTitle'),
			body: txt('subscription.dialogs.deepLinkSessionDialog.successBody'),
			buttonText: txt('subscription.dialogs.deepLinkSessionDialog.successCancel'),
			animationFile: checkedAnimation,
		},

		error: {
			header: txt('subscription.dialogs.deepLinkSessionDialog.errorTitle'),
			body: txt('subscription.dialogs.deepLinkSessionDialog.errorBody'),
			buttonText: txt('subscription.dialogs.deepLinkSessionDialog.errorCancel'),
			animationFile: errorAnimation,
		},

		pending: {
			header: txt('subscription.dialogs.deepLinkSessionDialog.pendingTitle'),
			body: txt('subscription.dialogs.deepLinkSessionDialog.pendingBody'),
			buttonText: txt('subscription.dialogs.deepLinkSessionDialog.pendingCancel'),
			animationFile: bubbleAnimation,
		},
	}

	return (
		<CustomDialog visible={status !== 'idle'} style={{ width: '80%' }}>
			<LoadingContent {...(statusProps[status] ?? statusProps.pending)} />
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	contentContainer: {
		justifyContent: 'center',
		padding: 36,
		backgroundColor: Colors.whiteTransparent,
		borderRadius: 24,
	},
	header: {
		textAlign: 'center',
	},
	body: {
		textAlign: 'center',
		paddingVertical: 12,
	},
	animation: {
		width: 200,
		height: 100,
		alignSelf: 'center',
	},
	buttonText: {
		textAlign: 'center',
		color: Colors.textLight,
	}
})
