import React, { useState, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'

import { CustomText } from '../../text/StyledText'
import { FormInput } from '../../text/StyledTextinputs'
import { RoundSquareBtn } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { SpacingStyles } from '../../../styles'

import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import { changePasswordByToken } from '../../../services/api/User'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import CustomPressable from "../../pressables/CustomPressable"

export default function ChangePassword({ navigation, route }) {
  const token = route.params.token
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [hidePassword, setHidePassword] = useState(true)
  const [hidePasswordConfirm, setHidePasswordConfirm] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (token === undefined || token.length !== 36) {
      navigation.navigate('Frontpage')
    }
  }, [])

  async function handleChangePassword() {
    if (password.trim().length === 0 || passwordConfirm.trim().length === 0) {
      setError(txt('profile.passwordErrors.code1'))
      return
    }

    if (password !== passwordConfirm) {
      setError(txt('profile.passwordErrors.code0'))
      return
    }

    const didChangePassword = await changePasswordByToken(token, password)

    if (didChangePassword.status_code !== 200) {
      setError(txt('profile.passwordErrors.code3'))
      return
    }

    setError(null)
    setPassword('')
    setPasswordConfirm('')
    navigation.navigate('Login')
  }

  return (
    <View style={styles.container}>
      <View style={styles.flexGrow1} />
      <View style={styles.passwordContainer}>
        <CustomText font="largeBold" style={[styles.textCenter, styles.marginBottom12]}>
          {txt('passwordField.titleReset')}
        </CustomText>

        <CustomText style={[styles.textCenter, styles.marginBottom12]}>
          {txt('passwordField.changeDescription')}
        </CustomText>

        <CustomText font="small">{txt('profile.passwordNew')}</CustomText>
        <View>
          <FormInput
            style={styles.inputBox}
            accessibilityLabel={txt('profile.passwordNew')}
            accessibilityHint={txt('profile.passwordHintNew')}
            value={password}
            placeholder={''}
            onChangeText={setPassword}
            secureTextEntry={hidePassword}
          >
            <CustomPressable
              hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
              accessibilityLabel={hidePassword ? txt('profile.passwordChangeShow') : txt('profile.passwordChangeHide')}
              accessibilityHint={txt('profile.passwordChangeHint')}
              accessibilityLiveRegion="polite"
              accessibilityRole="button"
              onPress={() => setHidePassword((previous) => !previous)}
            >
              <NormalIcon
                name={hidePassword ? "eye-slash" : "eye"}
                size={Fontsizes.l}
                color={Colors.inactive}
                stroke="fas"
              />
            </CustomPressable>
          </FormInput>
        </View>

        <CustomText font="small">{txt('profile.passwordNewConfirm')}</CustomText>
        <View>
          <FormInput
            style={styles.inputBox}
            accessibilityLabel={txt('profile.passwordNewConfirm')}
            accessibilityHint={txt('profile.passwordHintConfirm')}
            value={passwordConfirm}
            placeholder={''}
            onChangeText={setPasswordConfirm}
            secureTextEntry={hidePasswordConfirm}
          >
            <CustomPressable
              hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
              accessibilityLabel={
                hidePasswordConfirm ? txt('profile.passwordChangeShow') : txt('profile.passwordChangeHide')
              }
              accessibilityHint={txt('profile.passwordChangeHint')}
              accessibilityLiveRegion="polite"
              accessibilityRole="button"
              onPress={() => setHidePasswordConfirm((previous) => !previous)}
            >
              <NormalIcon
                name={hidePasswordConfirm ? "eye-slash" : "eye"}
                size={Fontsizes.l}
                color={Colors.inactive}
                stroke="fas"
              />
            </CustomPressable>
          </FormInput>
        </View>

        <RoundSquareBtn
          style={styles.passwordBtn}
          title={txt('profile.changePassword')}
          onPress={handleChangePassword}
        />
        {error && <InvalidInputNoticeCentered>{error}</InvalidInputNoticeCentered>}
      </View>

      <View style={styles.flexGrow3} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    ...SpacingStyles.widthAndHeight,
  },
  flexGrow1: { flexGrow: 1 },
  flexGrow3: { flexGrow: 3 },
  inputBox: {
    borderBottomColor: Colors.text,
    borderBottomWidth: 1,
    marginBottom: 12,
    marginTop: 6,
    paddingBottom: 6,
  },
  marginBottom12: { marginBottom: 12 },
  passwordBtn: {
    alignSelf: 'center',
    marginTop: 12,
  },
  passwordContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    margin: 12,
    padding: 24,
  },
  textCenter: { textAlign: 'center' },
})
