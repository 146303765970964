import React, { useEffect, useState } from 'react'
import Colors from '../../constants/Colors'
import { StyleSheet, View } from 'react-native'
import ImgHighlightChristmas from './ImgHighlightChristmas'
import NormalIcon from '../../components/icons/NormalIcon'
import Layout from '../../constants/Layout'
import FavoriteIndicator from '../../components/headers/FavoriteIndicator'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'
import { useSelector } from 'react-redux'
import LoadingSpinner from '../../components/activityIndicators/LoadingSpinner'
import { setChristmasPostFavorite } from '../../services/api/ChristmasFriends'
import Fontsizes from '../../constants/Fontsizes'
import { SpacingStyles } from '../../styles'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function PostChristmas({ post, editPost, onSendMessage }) {
  const ownUserId = useSelector((state) => state.user.id)
  const [favorite, setFavorite] = useState(false)

  useEffect(() => {
    setFavorite(!!post?.is_favorite || false)
  }, [post])

  async function toggleFavorite() {
    await setChristmasPostFavorite(post.id, favorite)
    setFavorite(!favorite)
  }

  if (!post) return <LoadingSpinner />

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <ImgHighlightChristmas
          size="large"
          type={post.type}
          imgFilename={post.imgFilename}
        />
      </View>

      <View style={styles.actionContainer}>
        {editPost && ownUserId === post.userId && (
          <CustomPressable onPress={editPost}>
            <NormalIcon
              name="edit"
              accessibilityLabel={txt('fullPost.usernameIcon')}
              size={Fontsizes.xl}
            />
          </CustomPressable>
        )}

        {ownUserId && ownUserId !== post.userId && (
          <FavoriteIndicator
            size={Layout.isSmallDevice ? 28 : 36}
            isFavorite={favorite}
            toggleFavorite={toggleFavorite}
          />
        )}
      </View>

      <View style={styles.contentContainer}>
        <View
          style={[
            styles.smallInfo,
            {
              marginBottom: 12,
            },
          ]}
        >
          <NormalIcon
            name="user"
            size={Fontsizes.xs}
            stroke="fas"
          />
          <CustomText
            font={'smallBold'}
            style={{ color: Colors.inactive, marginLeft: 6 }}
          >
            {post.username}
          </CustomText>
        </View>
        <CustomText font="largeBold" accessibilityRole="header" aria-level={1}>
          {post.title}
        </CustomText>
        <CustomText style={{ marginTop: 12 }}>{post.body}</CustomText>
        <View
          style={[
            styles.smallInfo,
            {
              marginTop: 12,
            },
          ]}
        >
          <NormalIcon
            name="map-marker-alt"
            accessibilityLabel={txt('fullPost.locationIcon')}
            size={Fontsizes.xs}
            stroke="fas"
          />
          <CustomText style={{ marginLeft: 6 }}>
            {post.postalCode === 0
              ? post.city + ','
              : post.postalCode + ', ' + post.city + ', '}
          </CustomText>
          {post.distance !== null && (
            <CustomText>
              {txt('christmasFriends.feed.distance', {
                distance: post.distance,
              })}
            </CustomText>
          )}
        </View>
      </View>

      <View
        style={{
          marginTop: 24,
          flexDirection: Layout.isSmallDevice ? 'column' : 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <View style={styles.guestHostContainer}>
          <CustomText
            accessibilityLabel={'header'}
            aria-level={2}
            font="smallBold"
            style={styles.countHeader}
          >
            {(post.type === 'host' &&
              post.numAdults + post.numChildren === 1) ||
            (post.type !== 'host' && post.numAdults + post.numChildren === 1)
              ? txt('christmasFriends.post.numberSingular')
              : txt('christmasFriends.post.numberPlural')}
          </CustomText>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View style={styles.countContainer}>
              <CustomText font="largeBold" style={styles.countNumber}>
                {post.numAdults}
              </CustomText>
              <CustomText font="smallBold" style={styles.countLabel}>
                {post.numAdults === 1
                  ? txt('christmasFriends.post.adult')
                  : txt('christmasFriends.post.adults')}
              </CustomText>
            </View>
            <View style={styles.countContainer}>
              <CustomText font="largeBold" style={styles.countNumber}>
                {post.numChildren}
              </CustomText>
              <CustomText font="smallBold" style={styles.countLabel}>
                {post.numChildren === 1
                  ? txt('christmasFriends.post.child')
                  : txt('christmasFriends.post.children')}
              </CustomText>
            </View>
          </View>
        </View>
        {post.type === 'host' && (
          <View style={[styles.guestHostContainer, styles.margin]}>
            <CustomText font="smallBold" style={styles.countHeader}>
              {post.type === 'host' && post.numAdults + post.numChildren === 1
                ? txt('christmasFriends.post.hostSpacesSingular')
                : txt('christmasFriends.post.hostSpacesPlural')}
            </CustomText>
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
            >
              <View style={styles.countContainer}>
                <CustomText font="largeBold" style={styles.countNumber}>
                  {post.adultSpaces}
                </CustomText>
                <CustomText font="smallBold" style={styles.countLabel}>
                  {post.adultSpaces === 1
                    ? txt('christmasFriends.post.adult')
                    : txt('christmasFriends.post.adults')}
                </CustomText>
              </View>
              <View style={styles.countContainer}>
                <CustomText font="largeBold" style={styles.countNumber}>
                  {post.childSpaces}
                </CustomText>
                <CustomText font="smallBold" style={styles.countLabel}>
                  {post.childSpaces === 1
                    ? txt('christmasFriends.post.child')
                    : txt('christmasFriends.post.children')}
                </CustomText>
              </View>
            </View>
          </View>
        )}
      </View>

      {ownUserId !== post.userId ? (
        <ButtonPrimary
          style={{ marginTop: 24 }}
          title={txt('christmasFriends.post.sendMessage')}
          onPress={onSendMessage}
        />
      ) : (
        <View
          style={{ alignSelf: 'center', alignItems: 'center', marginTop: 24 }}
        ></View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  actionContainer: {
    position: 'absolute',
    right: Layout.isSmallDevice ? 12 : 24,
    top: Layout.isSmallDevice ? 12 : 24,
  },
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
    padding: 12,
  },
  contentContainer: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    marginTop: 24,
    padding: 12,
  },
  countContainer: {
    backgroundColor: Colors.background,
    borderRadius: 6,
    height: 75,
    padding: 6,
    width: 75,
  },
  countHeader: {
    marginBottom: 6,
    textAlign: 'center',
  },
  countLabel: {
    color: Colors.inactive,
    textAlign: 'center',
  },
  countNumber: {
    alignSelf: 'center',
    color: Colors.blue,
    fontFamily: 'oswald-bold',
    fontSize: 24,
  },
  guestHostContainer: {
    backgroundColor: Colors.white,
    borderRadius: 12,
    flex: 1,
    padding: 12,
  },
  imageContainer: { alignSelf: 'center' },
  margin: {
    marginLeft: Layout.isSmallDevice ? 0 : 12,
    marginTop: Layout.isSmallDevice ? 12 : 0,
  },
  smallInfo: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})
