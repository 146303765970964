import React from 'react'
import { StyleSheet } from 'react-native'
import ListItem from './ListItem'
import Card from '../Card'

export default class OptionsList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Card style={[styles.container, this.props.style]}>
        {this.props.data.map((item, i) => (
          <ListItem
            item={item}
            key={i}
            last={i === this.props.data.length - 1}
            badge={item.badge}
          />
        ))}
      </Card>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 12,
  },
})
