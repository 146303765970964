import React, { useEffect } from 'react'
import { View, StyleSheet, Image, ImageBackground, Dimensions, Platform } from 'react-native'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { CustomText } from '../../../components/text/StyledText'
import { getUniverseConfig } from '../../../universe/universeController'
import { useRoute } from '@react-navigation/core'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function RFIntro(props) {
  const route = useRoute()
  let universeName = route.params?.universe?.toLowerCase()
  const universeObject = getUniverseConfig(universeName)

  useEffect(() => {
    props.navigation.setOptions({
      headerShown: false,
      title: universeObject?.pageTitle,
    })
  }, [])

  const handleGetStartedClick = () => {
    props.joinUniverse(universeName)
  }

  const width = Dimensions.get('window').width

  return (
    <MainContainer backgroundColor={Colors.black}>
      <ImageBackground
        source={{
          uri: 'https://filer.boblberg.dk/assets/img/universe/rf_banner.jpg',
        }}
        style={width < 500 ? styles.bannerMobile : styles.bannerDesktop}
      >
        <View style={styles.bannerContainer}>
          <CustomText
            font="largeBold"
            style={
              width < 500
                ? styles.bannerHeadlineMobile
                : styles.bannerHeadlineDesktop
            }
          >
            {txt('universe.rf.headline')}
          </CustomText>
          <CustomPressable
            onPress={handleGetStartedClick}
            style={styles.btnBanner}
            accessible
            accessibilityRole="button"
            accessibilityLabel={txt('universe.rf.signupBtn')}
          >
            <CustomText font="largeBold" style={styles.btnBannerText}>
              {txt('universe.rf.signupBtn')}
            </CustomText>
          </CustomPressable>
        </View>
        <CustomText font="xsmallItalic" style={styles.imageCredit}>
          {txt('universe.rf.imageCredit')}
        </CustomText>
      </ImageBackground>
      <View style={styles.container}>
        <View style={styles.marginLeftRight}>
          <CustomText font="largeBold" style={styles.title}>
            {txt('universe.rf.title')}
          </CustomText>

          <CustomText font="largeBold" style={styles.header}>
            {txt('universe.rf.header')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.rf.example1')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.rf.example2')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.rf.example3')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.rf.example4')}
          </CustomText>

          <CustomPressable
            onPress={handleGetStartedClick}
            style={styles.btn}
            accessible
            accessibilityRole="button"
            accessibilityLabel={txt('universe.rf.signupBtn')}
          >
            <CustomText font="largeBold" style={styles.btnText}>
              {txt('universe.rf.signupBtn')}
            </CustomText>
          </CustomPressable>

          <CustomText style={[styles.textAlign, styles.marginBottom30]}>
            {txt('universe.rf.endText')}
          </CustomText>

          <Image
            source={{
              uri: 'https://filer.boblberg.dk/assets/img/universe/rf_logo.png',
            }}
            resizeMode="contain"
            style={styles.rfLogo}
            accessibilityIgnoresInvertColors
            accessible
            accessibilityRole="image"
            accessibilityLabel={txt('universe.rf.headerImageLabel')}
          />

          <CustomText style={[styles.textAlign, styles.marginBottom30]}>
            {txt('universe.rf.createdBy')}
          </CustomText>
        </View>
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  bannerContainer: {
    // alignSelf: 'center',
    marginHorizontal: 12,
    width: '100%',
  },
  bannerDesktop: {
    justifyContent: 'center',
    minHeight: '40vh',
    width: '100%',
  },
  bannerHeadlineDesktop: {
    color: Colors.white,
    fontSize: 100,
    textAlign: 'center',
  },
  bannerHeadlineMobile: {
    color: Colors.white,
    fontSize: 50,
    textAlign: 'center',
  },
  bannerMobile: {
    flex: 1,
    justifyContent: 'center',
    minHeight: Platform.OS === 'web' ? '40vh' : 300,
    width: '100%',
  },
  btn: {
    alignSelf: 'center',
    backgroundColor: Colors.orangeRf,
    borderRadius: 10,
    color: Colors.white,
    marginVertical: 20,
    padding: 16,
  },
  btnBanner: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    color: Colors.white,
    marginVertical: 20,
    padding: 16,
  },
  btnBannerText: {
    color: Colors.orangeRf,
  },
  btnText: {
    color: Colors.white,
  },
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
  },
  header: {
    color: Colors.white,
    marginBottom: 6,
    marginTop: 24,
    textAlign: 'center',
  },
  imageCredit: {
    bottom: 5,
    color: Colors.white,
    position: 'absolute',
    right: 12,
  },
  marginBottom30: { marginBottom: 30 },
  marginBottom6: { marginBottom: 6 },
  marginLeftRight: { marginHorizontal: 12 },
  rfLogo: { alignSelf: 'center', height: 50, marginBottom: 30, width: 150 },
  subtitle: { color: Colors.white, marginBottom: 12, textAlign: 'center' },
  textAlign: { color: Colors.white, textAlign: 'center' },
  title: {
    color: Colors.white,
    marginBottom: 12,
    marginTop: 24,
    textAlign: 'center',
  },
})
