import React from 'react'
import { View, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import Layout from '../../../constants/Layout'
import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import { TypographyStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import Logo from '../../../assets/images/icon.png'
import CustomPressable from "../../pressables/CustomPressable"
export default class UnionListItem extends React.Component {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <CustomPressable
        accessible
        accessibilityRole="button"
        accessibilityLabel={this.props.item.title}
        accessibilityHint={txt('unions.feed.unionHint')}
        activeOpacity={0.8}
        onPress={this.props.onPressAction}
      >
        <View style={styles.container}>
          <View>
            {this.props.item.img_filename ? (
              <ImageHighlight
                borderColor={Colors.green}
                source={{ uri: Paths.imageUrlBase + this.props.item.img_filename }}
              />
            ) : (
              <ImageHighlight borderColor={Colors.green} source={Logo} />
            )}
          </View>
          <View style={styles.postContainer}>
            <CustomText font="smallBold" style={styles.textSmallBold}>
              {this.props.item.title}
            </CustomText>
            <CustomText
              numberOfLines={Layout.isSmallDevice ? 3 : 4}
              style={styles.textSmall}
            >
              {this.props.item.body}
            </CustomText>
          </View>
          {/* rightElement is used for myPosts */}
          {this.props.rightElement ?? null}
        </View>
      </CustomPressable>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-between',
    marginBottom: 25,
    marginLeft: 10,
    marginRight: 10,
  },
  postContainer: {
    alignSelf: 'center',
    flex: 1,
    maxHeight: 100,
    overflow: 'hidden',
  },
  textSmall: {
    flexWrap: 'nowrap',
    paddingLeft: 10,
    width: '100%',
    ...TypographyStyles.textSmall,
    color: Colors.text,
  },
  textSmallBold: {
    flexWrap: 'wrap',
    paddingLeft: 10,
  },
})
