import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { TypographyStyles } from '../../styles'
import { ButtonPrimary } from '../buttons/StyledButtons'
import { CustomText } from '../text/StyledText'
import { PhoneNumberInput } from './PhoneNumberInput'
import { useSelector } from 'react-redux'
import { updateUser } from '../../services/api/User'

export default function PhoneInputForm(props) {

  const user = useSelector(state => state.user)
  const [phone, setPhone] = useState(`${user.phone ?? ''}`)
  const [callCode, setCallCode] = useState(user.phone_prefix)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const phoneInputRef = useRef(null)

  useEffect(() => {
    setInvalidPhone(!phoneInputRef.current.isValid())
  }, [phone, callCode])

  async function submit() {
    setLoading(true)
    const success = await updateUser({
      phone: phone,
      phone_prefix: callCode,
    })
    setLoading(false)
    if (success) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 1500)
    } else {
      setError(true)
    }
  }

  function handlePhoneChange(phone) {
    setError(false)
    setPhone(phone)
  }

  return (
    <>
      <View style={styles.margin}>
        <CustomText font="small" style={[styles.textSmall, props.textStyle]}>
          {txt('videoChat.phone')}
        </CustomText>
      </View>
      <View style={styles.margin}>
        <View style={styles.postContainer}>
          <View style={styles.inputContainer}>
            <PhoneNumberInput
              ref={phoneInputRef}
              accessibilityLabel={txt('videoChat.phonePlaceholder')}
              accessibilityHint={txt('videoChat.phoneHint')}
              placeholder={txt('videoChat.phonePlaceholder')}
              phoneNumber={phone}
              setPhoneNumber={handlePhoneChange}
              callCode={callCode}
              setCallCode={setCallCode}
              style={props.style}
            />
          </View>
          <View style={(styles.buttonContainer, styles.marginLeft)}>
            <View style={styles.margin5}>
              <ButtonPrimary
                title={txt('videoChat.phoneSave')}
                style={props.buttonStyle}
                titleStyle={props.buttonTitleStyle}
                onPress={submit}
                success={success}
                error={error}
                loading={loading}
                disabled={invalidPhone}
              />
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  buttonContainer: { flexGrow: 1 },
  inputContainer: { flexGrow: 1 },
  margin: { marginLeft: 10, marginRight: 10, marginTop: 5 },
  margin5: { marginBottom: 5, marginTop: 5 },
  marginLeft: { marginLeft: 10 },
  postContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  textSmall: {
    ...TypographyStyles.textSmall,
    color: Colors.text,
    textAlign: 'center',
  },
})
