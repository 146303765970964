import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import StudieboblerSelectionScreen from '../screens/Universe/StudieboblerSelection'
import { stackConfig } from './navigationConfig'

function IntroStack() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={{ ...stackConfig({}), headerMode: 'float' }}>
      <Stack.Screen
        name="StudieboblerSelection"
        component={StudieboblerSelectionScreen}
      />
    </Stack.Navigator>
  )
}


export default function StudieboblerStack() {
  return <IntroStack />
}
