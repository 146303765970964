import React from 'react'
import { View, Dimensions, Platform, StyleSheet } from 'react-native'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import Fontsizes from '../../../../constants/Fontsizes'
import Layout from '../../../../constants/Layout'
import { ImageHighlight, ImageHighlightPost } from '../../../images/ImageHighlight'
import NormalIcon from '../../../icons/NormalIcon'
import Colors from '../../../../constants/Colors'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import { CornerStyles, BorderStyles, SpacingStyles } from '../../../../styles'

const { width } = Dimensions.get('window')

const festivales = [
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede1.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede2.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede3.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede4.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede5.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede6.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede7.png',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival/billede8.png',
  },
]

export default class Festivaler extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.title,
      activeIndex: 0,
    }
  }

  renderImage({ item }) {
    return (
      <View style={styles.imageContainer}>
        <ImageHighlightPost
          source={{ uri: item.uri }}
          style={styles.image}
          accessibilityIgnoresInvertColors
        />
      </View>
    )
  }

  render() {
    return (
      <View style={styles.widthAndHeight}>
        <View style={styles.marginBottom}>
          <View style={styles.marginLeftRight}>
            <View style={styles.marginBottom}>
              <CustomText style={styles.textCenter}>
                {txt('festivals.title')}
              </CustomText>
            </View>
            <CustomText style={styles.textCenter}>
              {txt('festivals.startText')}
            </CustomText>
            <CustomText style={styles.textCenter}>
              {txt('festivals.startText1')}
            </CustomText>
            <View style={styles.margin20}>
              <CustomText style={styles.textCenter}>
                {txt('festivals.list')}
              </CustomText>
            </View>
            <View style={styles.margin20}>
              <CustomText font="italic" style={styles.statement}>
                {txt('festivals.statement')}
              </CustomText>
            </View>
          </View>
        </View>

        <View style={styles.center}>
          <View style={styles.row}>
            <Carousel
              layout={'default'}
              ref={(ref) => {
                if (!this.state.pagRef) {
                  this.setState({ pagRef: ref })
                }
              }}
              data={festivales}
              sliderWidth={Platform.OS === 'web' ? 400 : width}
              itemWidth={Platform.OS === 'web' ? 400 : width}
              renderItem={this.renderImage}
              onSnapToItem={(index) => this.setState({ activeIndex: index })}
              loop
              autoplay
              enableSnap={true}
            />
          </View>
          <Pagination
            dotsLength={festivales.length}
            activeDotIndex={this.state.activeIndex}
            carouselRef={this.state.pagRef}
            tappableDots={!!this.state.pagRef}
            dotStyle={styles.pagination}
            dotContainerStyle={styles.dotContainer}
          />
        </View>

        <View style={styles.marginLeftRight}>
          <CustomText font="large">{txt('festivals.contact')}</CustomText>
        </View>
        <View style={styles.employeeContainer}>
          <View>
            <ImageHighlight
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/employees/Rasmus.jpg',
              }}
            />
          </View>

          <View style={styles.employeeTextContainer}>
            <CustomText font="bold">
              {txt('aboutUs.rasmusName')}
            </CustomText>
            <View>
              <CustomText>{txt('aboutUs.rasmusPosition')}</CustomText>
            </View>
            <View style={[styles.row, styles.margin20]}>
              <NormalIcon
                accessibilityLabel={txt('aboutUs.emailIcon')}
                name="envelope"
                size={Fontsizes.l}
                color={Colors.text}
                style={{ marginRight: 6 }}
              />
              <CustomText>{txt('aboutUs.rasmusMail')}</CustomText>
            </View>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { justifyContent: 'center' },
  dotContainer: { padding: 5 },
  employeeContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: 10,
    marginBottom: 20,
  },
  employeeTextContainer: {
    flexGrow: 1,
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  image: {
    height: Layout.isSmallDevice ? 200 : 300,
    width: Layout.isSmallDevice ? 200 : 300,
    ...CornerStyles.roundImg,
    ...BorderStyles.blueBorder,
  },
  imageContainer: {
    height: Layout.isSmallDevice ? 250 : 400,
    width: Layout.isSmallDevice ? width : 400,
    ...CornerStyles.borderRadiusM,
    alignItems: 'center',
  },
  margin20: {
    marginBottom: 20,
    marginTop: 20,
  },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  pagination: { borderRadius: 10, height: 12, width: 12 },
  row: { flexDirection: 'row' },
  statement: {
    textAlign: 'center',
  },
  textCenter: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  widthAndHeight: { ...SpacingStyles.widthAndHeight },
})
