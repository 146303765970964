import React from 'react'
import Colors from '../../constants/Colors'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../text/StyledText'

export function InvalidInputNoticeCentered(props) {
  return (
    <View style={styles.containerCentered}>
      <CustomText font="smallBold" {...props} style={styles.text} />
    </View>
  )
}

export function InvalidInputNoticeSimple(props) {
  return (
    <View style={styles.containerSimple}>
      <CustomText font="smallBold" {...props} style={styles.text} />
    </View>
  )
}

const styles = StyleSheet.create({
  containerCentered: {
    alignSelf: 'center',
    marginTop: 12,
  },
  containerSimple: {
    marginTop: 12,
  },
  text: {
    color: Colors.redLight,
  },
})
