import React from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import ChristmasPostEdit from '../../components/ChristmasPostEdit'
import { createChristmasPost } from '../../../services/api/ChristmasFriends'

export default function PostCreateChristmasScreen() {
  return (
    <MainContainer>
      <ChristmasPostEdit onSubmit={createChristmasPost} />
    </MainContainer>
  )
}
