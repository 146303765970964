import React from 'react'
import { View } from 'react-native'
import Webcam from 'react-webcam'
import Layout from '../../constants/Layout'
import { txt } from '../../locales/i18n'
import { RoundBtn } from '../buttons/StyledButtons'

export const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    props.captureImage(imageSrc)
  }, [webcamRef])

  const isSmall = Layout.isSmallDevice

  const size = {
    width: isSmall ? 250 : 500,
    height: isSmall ? 250 : 500,
  }

  return (
    <View style={{ flexDirection: 'column', alignSelf: 'center' }}>
      <Webcam
        audio={false}
        height={size.height}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={size.width}
        videoConstraints={{
          width: size.width,
          height: size.height,
          facingMode: 'user',
        }}
      />
      <RoundBtn
        style={{ margin: 10 }}
        title={txt('createPost.takePictureButtonText')}
        onPress={capture}
      />
    </View>
  )
}
