import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, FlatList, StyleSheet, Platform, AppState } from 'react-native'
import GroupPost from '../Groups/GroupPost'
import { getGroupMessages, readAllGroupMessages } from '../../../services/api/Groups'
import { SpacingStyles } from '../../../styles'
import GroupInput from './GroupInput'
import { useFocusEffect } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs'
import Layout from '../../../constants/Layout'
import { useKeyboardHeight } from '../../../utils/hooks'

export default function GroupBulletin(props) {
  const [data, setData] = useState([])
  const [activeInput, setActiveInput] = useState(undefined)
  const groupRedux = useSelector(state => state.groups?.find(g => g.id === props.group.id))
  const listRef = useRef()
  const inputHeight = useRef(0)
  const listHeight = useRef()
  const layouts = useRef([])
  const tabHeight = useBottomTabBarHeight()
  const height = useKeyboardHeight()
  const keyboardHeight = useRef(0)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const appState = useRef(AppState.currentState)

  useEffect(() => {
    if (Platform.OS === 'web') return
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        getGroupMessages(props.group.id, 0, 20).then(setData)
      }
      appState.current = nextAppState
    })

    return () => subscription?.remove()
  }, [])

  useFocusEffect(
    useCallback(
      readAllMessages,
      [groupRedux?.unread]),
  )

  useEffect(() => {
    if (!keyboardHeight.current && height) {
      keyboardHeight.current = height
    }
  }, [height])

  useEffect(() => {
    if (activeInput !== undefined && keyboardHeight.current > 0) {
      scrollToPost(activeInput)
      setActiveInput(undefined)
    }
  }, [activeInput, height])

//   useEffect(() => {
//     if (Platform.OS !== 'web') return
//     //only refresh on web because we already do it for native in the eventlistener in first useEffect
//     refreshData()
//   }, [props.group.id])

  async function getData() {
    const dataRes = await getGroupMessages(props.group.id, data.length, 20)
    setData((prevData) => [...prevData, ...dataRes])
  }

  async function refreshData() {
    setIsRefreshing(true)
    const dataRes = await getGroupMessages(props.group.id, 0, 20)
    setData(dataRes)
    setIsRefreshing(false)
  }

 const refreshMessages = useCallback(async () => {
    const dataRes = await getGroupMessages(props.group.id, 0, 20)
    setData(dataRes)
  },[])

  function readAllMessages() {
    readAllGroupMessages({ id: props.group.id })
  }

  const removePost = useCallback((id) => {
    const newData = data.filter((e) => e.id !== id)
    setData(newData)
  }, [])

  const _setActiveInput = useCallback((index) => {
    if (Platform.OS === 'ios') {
      if (keyboardHeight.current > 0) {
        scrollToPost(index)
      } else {
        setActiveInput(index)
      }
    }
  },[])

  function scrollToPost(index) {
    let offset = inputHeight.current
    for (let i = 0; i < index; i++) {
      offset += layouts.current[i]
    }
    offset -= listHeight.current - layouts.current[index]
    if (Layout.isSmallDevice) offset += keyboardHeight.current - tabHeight
    listRef.current.scrollToOffset({
      offset,
      animate: true,
    })
  }

  const renderPost = ({ item, index }) => (
    <View onLayout={(e) => layouts.current[index] = e.nativeEvent.layout.height} style={styles.margin10}>
      <GroupPost
        post={item}
        index={index}
        child={false}
        group={props.group}
        removePost={removePost}
        scrollToPost={_setActiveInput}
        refreshMessages={refreshMessages}
        navigateToConversation={props.navigateToConversation}
        navigateToNewConversation={props.navigateToNewConversation}
      />
    </View>
  )

  const ListHeader = useCallback(() => (
    <GroupInput
      onLayout={(e) => inputHeight.current = e.nativeEvent.layout.height}
      group={props.group}
      onMessageCreated={refreshMessages} />
  ), [props.group])

  return (
    <View style={[styles.container, props.style]}>
      <FlatList
        onLayout={(e) => listHeight.current = e.nativeEvent.layout.height}
        ref={listRef}
        ListHeaderComponent={ListHeader}
        keyboardShouldPersistTaps={'handled'}
        data={data}
        keyExtractor={(item) => item?.id?.toString()}
        renderItem={renderPost}
        onEndReached={getData}
        onRefresh={refreshData}
        refreshing={isRefreshing}
        onEndReachedThreshold={0.5}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  margin10: {
    marginHorizontal: 10,
  },
})
