import React from 'react'
import InboxOverview from '../../../components/views/Conversations/InboxOverview'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { useSelector } from 'react-redux'

export default function InboxChristmasScreen(props) {
  const groups = useSelector(state => state.groups)

  const navigateToConversation = (conversationId) => {
    props.navigation.push('Conversation', {
      id: conversationId,
    })
  }

  const goToPost = (postId) => {
    props.navigation.navigate('app', {
      screen: 'HomeStack',
      initial: false,
      params: {
        screen: 'Post',
        params: {id: postId},
      }
    })
  }

  const goToInformation = (conversationId) => {
    props.navigation.navigate('ConversationInfo', {
      id: conversationId,
    })
  }

  function goToChristmasPost(id) {
    props.navigation.navigate('PostFeedStack', {
      screen: 'ChristmasPost',
      initial: false,
      params: { id },
    })
  }

  const goToGroup = (id) => {
    props.navigation.navigate('app', {
      screen: groups?.length > 0 ? 'GroupsStack' : 'MenuStack',
      initial: false,
      params: {
        screen: 'Group',
        params: { id },
      },
    })
  }

  return (
    <MainContainer>
      <InboxOverview
        navigateToConversation={navigateToConversation}
        goToPost={goToPost}
        goToInformation={goToInformation}
        goToChristmasPost={goToChristmasPost}
        goToGroup={goToGroup}
        hideSubscriptionCard={true}
      />
    </MainContainer>
  )
}
