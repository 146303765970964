import React from 'react'
import CreateEditPost from '../../components/views/Post/CreateEditPost'
import MainContainer from '../../components/MainContainer/MainContainer'
import { getFullPost, updatePost, deletePost } from '../../services/api/Posts'
import { txt } from '../../locales/i18n'

export default function EditPostScreen(props) {
  function navigateToMyPosts() {
    props.navigation.replace('Home', {
      chip: txt('posts.chipParams.mine'),
    })
  }

  const getPost = async () => {
    return await getFullPost(props.route.params.id)
  }

  const editPost = async (postId, data) => {
    return await updatePost(postId, data)
  }

  return (
    <MainContainer>
      <CreateEditPost
        navigateToMyPosts={navigateToMyPosts}
        route={props.route}
        editPost={editPost}
        getPost={getPost}
        deletePost={deletePost}
      />
    </MainContainer>
  )
}
