import { View } from 'react-native'
import Colors from '../../../../constants/Colors'
import NormalIcon from '../../../icons/NormalIcon'
import { CustomText } from '../../../text/StyledText'
import React from 'react'
import CustomPressable from "../../../pressables/CustomPressable"

export default function ThemeRadioButton({ onPress, checked, border, color, size, text }) {
  return (
    <CustomPressable
      accessibilityRole={'radio'}
      onPress={onPress}
      style={{ flexDirection: 'row', alignItems: 'center' }}
    >
      <View style={[{ borderWidth: border || 3, padding: 4, borderColor: Colors.transparent },
        checked && { borderColor: color || Colors.black, borderRadius: 30 },
      ]}>
        <NormalIcon
          name="circle"
          stroke="fas"
          size={size || 26}
          color={color || Colors.tintColor}
        />
      </View>
      <CustomText style={{ paddingLeft: 6 }}>{text}</CustomText>
    </CustomPressable>
  )
}