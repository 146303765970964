import React, { useRef, useState } from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import ChristmasPostItem from './ChristmasPostItem'
import SearchList from '../../../components/lists/SearchList'
import { View } from 'react-native'
import { getChristmasPosts } from '../../../services/api/ChristmasFriends'
import FeedFilterIcons from './FeedFilterIcons'
import { useSelector } from 'react-redux'
import AfterSignupChristmasScreen from '../AfterSignupChristmasScreen'
import AfterSignupDialog from '../../components/AfterSignupDialog'

export default function ChristmasFeedScreen(props) {
  const myPost = useSelector((state) => state.christmas.post)

  const [filter, setFilter] = useState({
    host: myPost.type === 'guest',
    guest: myPost.type === 'host',
    favorite: false,
    distance: false,
  })

  // Longest distance in Denmark is 452 km therefore 500.
  const [distance, setDistance] = useState(500)
  const [showDistanceDiag, setShowDistanceDiag] = useState(false)

  const prevFilters = useRef(filter)
  const prevDistance = useRef(distance)

  async function getPosts({ search, skip, amount }) {
    let type
    let favorite
    if (filter.guest) {
      type = 'guest'
      favorite = null
    } else if (filter.host) {
      type = 'host'
      favorite = null
    } else if (filter.favorite) {
      type = null
      favorite = 1
    } else {
      type = ''
    }

    const posts = await getChristmasPosts({
      search,
      skip,
      amount,
      distance,
      type,
      favorite,
    })
    return posts
  }

  const shouldUpdate = () => {
    const update = JSON.stringify(prevFilters.current) !== JSON.stringify(filter)
    const distanceUpdate = prevDistance.current !== distance
    if (update) prevFilters.current = filter
    if (distanceUpdate) prevDistance.current = distance
    return update || distanceUpdate
  }

  function updateFilter(type) {
    const newFilter = {
      host: false,
      guest: false,
      favorite: false,
      distance: filter.distance,
    }
    newFilter[type] = !filter[type]
    setFilter(newFilter)
  }

  function isScrolling(value) {
    if (value) setShowDistanceDiag(false)
  }

  return (
    <MainContainer noScroll>
      <View style={{ flex: 1 }}>
        {myPost.rkMatch ? (
          <AfterSignupChristmasScreen />
        ) : (
          <SearchList
            renderItem={({ item }) => <ChristmasPostItem post={item} />}
            getData={getPosts}
            triggerUpdate={shouldUpdate}
            filterAbove={() => (
              <FeedFilterIcons
                updateFilter={updateFilter}
                showDistanceDiag={showDistanceDiag}
                setShowDistanceDiag={setShowDistanceDiag}
                filter={filter}
                setFilter={setFilter}
                distance={distance}
                setDistance={setDistance}
              />
            )}
            navigation={props.navigation}
            isScrolling={(val) => isScrolling(val)}
            center
            christmas
          />
        )}
        <AfterSignupDialog />
      </View>
    </MainContainer>
  )
}
