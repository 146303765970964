import React, { useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { txt } from '../../../locales/i18n'
import { ModalSelectorCustomSprogland } from '../../modalSelectors/ModalSelector'
import { CustomText } from '../../text/StyledText'

export default function SproglandDurationSelector({duration, handleDurationChange}) {
  const durations = useRef([15, 30, 45, 60])

    function renderSelector() {
      return (
        <ModalSelectorCustomSprogland
          data={durations.current}
          keyExtractor={(data) => data}
          labelExtractor={(data) => data}
          initValue={duration.toString()}
          onChange={handleDurationChange}
        />
      )
    }

    return (
      <View>
        <CustomText font="smallBold" accessibilityRole="header" aria-level={3}>
          {txt('learnDanish.volunteer.create.duration')}
        </CustomText>
        <View style={styles.pickDateContainer}>
          {renderSelector()}
        </View>
      </View>
    )

}

const styles = StyleSheet.create({
  pickDateContainer: {
    borderRadius: 10,
    height: 40,
    minWidth: '100%',
    marginBottom: 10,
    marginTop: 5,
    justifyContent: 'flex-start',
  },
})