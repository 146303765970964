import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'

import { CustomText } from '../../../text/StyledText'
import { ButtonPrimary, ButtonSecondary } from '../../../buttons/StyledButtons'
import CustomDialog from '../../../dialogs/CustomDialog'

import * as AuthService from '../../../../services/authActions'
import * as UserService from '../../../../services/api/User'

import * as Localization from '../../../../services/localization'
import Colors from '../../../../constants/Colors'
import Paths from '../../../../constants/Paths'
import { txt } from '../../../../locales/i18n'

export function LogoutDialog(props) {
	// idle | pending | success | error
	const [authState, setAuthState] = useState('idle')

	useEffect(() => {
		const urlSubscriber = Linking.addEventListener('url', authLinkHandler)
		return () => urlSubscriber.remove()
	}, [])

	const authLinkHandler = async ({ url }) => {
		const { queryParams = null } = Linking.parse(url)
		if (queryParams === null) return

		const { event = null, status = null } = queryParams
		if (event !== 'logout' || status === null) return

		if (status === 'cancel') return dismissHandler()
		if (status === 'error') return setAuthState('error')
		if (status === 'success') return logoutHandler()
	}

	const logoutHandler = async () => {
		setAuthState('success')

		try {
			return await AuthService.postLogoutAction()
		} catch (error) {
			return setAuthState('error')
		}
	}

	const logoutNativeHandler = async () => {
		setAuthState('pending')

		const queries = []
		const url = Paths.getBaseUrl() + 'auth/logout'

		const appDeeplink = AuthSession.makeRedirectUri()
		queries.push('appDeeplink=' + encodeURIComponent(appDeeplink))

		const lang = await Localization.getLanguage()
		if (lang) queries.push('lang=' + lang)

		await Linking.openURL(url + '?' + queries.join('&'))
	}

	const dismissHandler = () => {
		setAuthState('idle')
		props?.closeDialog?.()
	}

	return (
		<CustomDialog onDismiss={dismissHandler} visible={props.visible} style={styles.container}>
			<CustomText style={styles.title}>{txt('logout.logOut')}</CustomText>
			{['idle', 'error'].includes(authState) && <CustomText style={{ textAlign: 'center', paddingBottom: 12 }}>{txt('logout.dialog')}</CustomText>}

			{authState === 'pending' && (
				<View style={[styles.infoContainer, { borderWidth: 1, borderColor: Colors.blueAzure, alignSelf: 'center', marginBottom: 10 }]}>
					<CustomText style={styles.containerText}>{txt('logout.pending')}</CustomText>
				</View>
			)}

			{authState === 'success' && (
				<View style={[styles.infoContainer, { backgroundColor: Colors.greenBtn, width: 250, alignSelf: 'center' }]}>
					<CustomText style={[styles.containerText, { color: Colors.white }]}>{txt('logout.goodbye')}</CustomText>
					<ActivityIndicator color={Colors.white} />
				</View>
			)}

			{authState === 'error' && (
				<View style={[styles.infoContainer, { borderWidth: 2, borderColor: Colors.redSoft, alignSelf: 'center', marginBottom: 12 }]}>
					<CustomText font="bold" style={[styles.containerText, { color: Colors.redSoft }]}>{txt('logout.error')}</CustomText>
				</View>
			)}

			{authState !== 'success' && (
				<View style={styles.buttons}>
					<ButtonSecondary
						title={txt('logout.dialogNo')}
						onPress={dismissHandler}
						style={[styles.button, { borderColor: Colors.blueAzure }]}
						titleStyle={[styles.buttonTitle, { color: Colors.blueAzure }]}
					/>

					<ButtonPrimary
						loading={authState === 'pending'}
						title={authState === 'error' ? txt('logout.retry') : txt('logout.dialogYes')}
						onPress={UserService.logout}
						// functionality for web logout - prepared for future use
						// onPress={Platform.OS === 'web' ? UserService.logout : logoutNativeHandler}
						style={[styles.button, { backgroundColor: Colors.blueAzure }]}
						titleStyle={styles.buttonTitle}
					/>
				</View>
			)}
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	container: {
		padding: 24,
		gap: 12,
		minWidth: 240,
	},

	title: {
		fontSize: 26,
		fontFamily: 'raleway-semibold',
		textAlign: 'center',
	},

	buttons: {
		flexDirection: 'row',
		gap: 12,
		justifyContent: 'space-between',
	},

	button: {
		minWidth: '45%',
		paddingVertical: 13,
		paddingHorizontal: 24,
		borderRadius: 10,
	},

	buttonTitle: {
		paddingHorizontal: 12,
	},

	infoContainer: {
		padding: 12,
		flexDirection: 'row',
		borderRadius: 10,
	},

	containerText: {
		color: Colors.blueAzure,
		textAlign: 'center',
		flex: 1,
	},
})
