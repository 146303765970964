// libraries
import React, { useEffect, useState } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { useNavigation, useRoute, CommonActions } from '@react-navigation/core'
import { useSelector } from 'react-redux'

// components
import PageTitle from '../../components/tags/PageTitle'
import { CustomChip } from '../../components/chips/Chip'

// views
import UnionFeed from '../../components/views/Union/UnionFeed'
import MyUnions from '../../components/views/Union/MyUnions'
import FavoriteUnions from '../../components/views/Union/FavoriteUnions'
import CreateUnion from '../../components/views/Union/CreateUnion'
import { UnionsSignup } from '../../components/views/Union/UnionsSignup'

// resources
import * as unionService from '../../services/api/Unions'
import TxtTMD from '../../constants/TxtTMD'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'

export const UnionFeedScreen = () => {
  const isSmallDevice = Dimensions.get('window').width < 375
  const user = useSelector((state) => state.user ?? null)
  const navigation = useNavigation()
  const route = useRoute()

  const [locations, setLocations] = useState([])
  const [selectedChip, setSelectedChip] = useState(route.params?.chip ?? '')
  const [municipality, setMunicipality] = useState(String(user?.municipality_id ?? route.params?.municipality ?? 'all'))

  useEffect(() => {
    const action = CommonActions.setParams({ chip: selectedChip })
    navigation.dispatch(action)
  }, [selectedChip])

  useEffect(() => {
    if (user === null) {
      const action = CommonActions.setParams({ municipality })
      navigation.dispatch(action)
      return
    }

    const action = CommonActions.setParams({ municipality: String(user.municipality_id) })
    navigation.dispatch(action)
  }, [municipality])

  const searchUnions = async ({ search, skip, amount }) => {
    const { unions, locations } = await unionService.searchUnions({ search, skip, amount, municipality })
    setLocations(locations)
    return unions
  }

  const renderChips = () => (
    <View style={styles.chipContainer}>
      {/* search unions */}
      <View style={styles.chip}>
        <CustomChip
          selected={selectedChip === ''}
          onPress={() => setSelectedChip('')}
          accessibilityLabel={txt('unions.menu.all')}
          icon="users-class"
          text={txt('unions.menu.all')}
        />
      </View>

      {/* my unions */}
      {user !== null && (
        <View style={styles.chip}>
          <CustomChip
            selected={selectedChip === txt('unions.chipParams.mine')}
            onPress={() => setSelectedChip(txt('unions.chipParams.mine'))}
            accessibilityLabel={txt('unions.menu.mine')}
            icon="user"
            text={txt('unions.menu.mine')}
          />
        </View>
      )}

      {/* favorite unions */}
      {user !== null && (
        <View style={styles.chip}>
          <CustomChip
            selected={selectedChip === txt('unions.chipParams.favorites')}
            onPress={() => setSelectedChip(txt('unions.chipParams.favorites'))}
            accessibilityLabel={txt('activities.menu.favoriteActivities')}
            icon="star"
            text={isSmallDevice === false && txt('activities.menu.favoriteActivities')}
          />
        </View>
      )}

      {/* create Union */}
      {user !== null && (
        <View style={styles.chip}>
          <CustomChip
            selected={selectedChip === txt('unions.chipParams.create')}
            onPress={() => setSelectedChip(txt('unions.chipParams.create'))}
            accessibilityLabel={txt('unions.menu.create')}
            icon="plus-circle"
            text={txt('unions.menu.create')}
          />
        </View>
      )}

      {/* user signup */}
      {user === null && (
        <View style={styles.chip}>
          <CustomChip
            onPress={() => setSelectedChip(txt('global.signup'))}
            accessibilityLabel={txt('global.signup')}
            icon="user"
            text={txt('global.signup')}
          />
        </View>
      )}
    </View>
  )

  const renderView = () => {
    if (selectedChip === '') {
      return (
        <UnionFeed
          navigation={navigation}
          navigateToUnion={(id) => navigation.push('Union', { id })}
          searchUnions={searchUnions}
          renderChips={renderChips}
          setMunicipality={setMunicipality}
          municipality={municipality}
          locations={locations}
        />
      )
    }

    if (user !== null && selectedChip === txt('unions.chipParams.mine')) {
      return (
        <MyUnions
          navigation={navigation}
          navigateToUnion={(id) => navigation.push('Union', { id })}
          getOwnUnions={unionService.getOwnUnions}
          renderChips={renderChips}
        />
      )
    }

    if (selectedChip === txt('unions.chipParams.favorites')) {
      return (
        <FavoriteUnions
          navigation={navigation}
          navigateToUnion={(id) => navigation.push('Union', { id })}
          getFavoriteUnions={unionService.getFavoriteUnions}
          renderChips={renderChips}
        />
      )
    }

    if (user !== null && selectedChip === txt('unions.chipParams.create')) {
      return (
        <CreateUnion
          navigation={navigation}
          navigateToMyUnions={() => navigation.replace('Unions', { chip: txt('unions.chipParams.mine') })}
          createUnion={unionService.createUnion}
          renderChips={renderChips}
        />
      )
    }

    if (user === null && selectedChip === txt('global.signup')) {
      return <UnionsSignup />
    }

    return null
  }

  return (
    <View style={styles.container}>
      <PageTitle title={TxtTMD.unions.title[TxtTMD.lang]} />
      {renderView()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.background,
  },

  chipContainer: {
    marginTop: 5,
    alignSelf: 'center',
    flexDirection: 'row',
  },

  chip: {
    marginBottom: 5,
    marginHorizontal: 3,
  },

  iconStyle: {
    marginRight: -12,
  },
})
