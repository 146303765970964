export function selectUnreadMessages(state) {
  if (state.conversations) {
    let unreadMessages = 0
    state.conversations.forEach((conv) => {
      unreadMessages += conv.unread_messages
    })
    return unreadMessages
  }
  return 0
}

export function selectUnreadGroupMessages(state) {
  let unread = 0
  state.groups?.forEach(group => {
    unread += group.unread
  })
  return unread
}

export function selectUnreadNotifications(state) {
  return selectUnreadMessages(state) + selectUnreadGroupMessages(state)
}

export function conversationMessages(state, conversationID) {
  const conversation = state.conversations.find(
    (conv) => conv.conversation_id === conversationID
  )
  return conversation?.messages || []
}

export function selectChristmasPostsFromConversations(state) {
  const conversations = state.conversations.filter(conv => conv.christmas_post_id)
  return conversations.map(conv => ({
    id: conv.christmas_post_id,
    title: conv.title,
    type: conv.type,
    imgFilename: conv.img_filename,
  }))
}

export function selectUsersFromConversations(state) {
  const conversations = state.conversations
  const users = conversations.reduce((prev, curr) => ([...prev, ...curr.otherUsers]), [])

  return uniqBy(users, JSON.stringify)
}

function uniqBy(a, key) {
  let seen = {};
  return a.filter(function(item) {
    const k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  })
}