import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import NormalIcon from '../../components/icons/NormalIcon'
import { CustomText } from '../../components/text/StyledText'
import PostItemSmall from '../../components/views/Conversations/PostItemSmall'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { getChristmasPost } from '../../services/api/ChristmasFriends'

export default function ChristmasInfoOverview({
  christmasPostId,
  navigateToPost,
}) {
  const [post, setPost] = useState(null)

  useEffect(() => {
    getChristmasPost(christmasPostId).then(setPost)
  }, [])

  return (
    <View style={styles.postContainer}>
      <View style={styles.icon}>
        <NormalIcon
          name="hat-santa"
          size={20}
          color={Colors.tintColor}
          style={styles.spacing}
        />
        <CustomText accessible font={'small'} style={styles.textContainer}>
          {txt('conversations.info.christmasPost').toUpperCase()}
        </CustomText>
      </View>
      {post ? (
        <PostItemSmall
          item={post}
          onPressAction={() => navigateToPost(christmasPostId)}
        />
      ) : (
        <CustomText style={{ color: Colors.inactive }}>
          {txt('conversations.info.christmasNoPost')}
        </CustomText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  icon: { alignItems: 'center', flexDirection: 'row', marginRight: 6 },
  postContainer: { marginBottom: 24 },
  spacing: { marginRight: 6 },
  textContainer: { marginBottom: 12, marginTop: 12 },
})
