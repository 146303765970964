import CustomDialog from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import React from 'react'
import { ButtonPrimary } from "../../../buttons/StyledButtons"
import Colors from "../../../../constants/Colors"
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native"
import NormalIcon from "../../../icons/NormalIcon"
import { txt } from "../../../../locales/i18n"
import { handlePaymentClick } from "../../../../utils/handlePaymentClick"

export function UpdateNotAllowedDialog({ visible, dismiss }) {
  return (
    <CustomDialog visible={visible} style={styles.dialog} onDismiss={dismiss}>
      <View style={styles.contentContainer}>
        <TouchableOpacity style={styles.closeIcon} accessibilityRole="button" onPress={dismiss}>
          <NormalIcon name="times" color={Colors.inactive} />
        </TouchableOpacity>
        <View>
          <CustomText style={styles.title} font="largeBold">
            {txt('subscription.dialogs.updateNotAllowed.title')}
          </CustomText>

          <CustomText style={{ marginVertical: 12 }}>
            {txt('subscription.dialogs.updateNotAllowed.intro')}
          </CustomText>
          <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 18, marginLeft: 10}}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point1')}
            </CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point2')}
            </CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point3')}
            </CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point4')}
            </CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point5')}
            </CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point6')}
            </CustomText>
          </View>
          <View style={styles.pointContainer}>
            <View style={styles.point}>
              <NormalIcon name="check" size={14} color={Colors.greenIcon} />
            </View>
            <CustomText style={styles.pointText}>
              {txt('subscription.dialogs.subscriptionDialog.point7')}
            </CustomText>
          </View>
        </View>
      </View>

      <ButtonPrimary
        style={styles.button}
        onPress={async () => {
          const origin = 'update-limit-reached'
          if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin }})
          dismiss()

          await handlePaymentClick({ origin })
        }}
        title={txt('subscription.dialogs.updateNotAllowed.button')}
        accessibilityLabel={txt('subscription.dialogs.updateNotAllowed.button')}
        accessibilityHint={txt('subscription.dialogs.updateNotAllowed.buttonHint')}
      />
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    padding: 20,
  },
  contentContainer: {
    marginBottom: 24,
  },
  title: {
    color: Colors.blueAzure,
    paddingRight: 24,
    fontSize: 20
  },
  button: {
    paddingHorizontal: 12,
    backgroundColor: Colors.greenBtn,
  },
  closeIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingLeft: 24,
    zIndex: 1
  },
  pointContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 10
  },
  point: {
    padding: 3,
    backgroundColor: Colors.greenExtraLight,
    borderRadius: 10
  },
  pointText: {
    paddingLeft: 6
  }
})