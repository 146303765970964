import React from 'react'
import { View, StyleSheet } from 'react-native'
import Paths from '../../../constants/Paths'
import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import { txt } from '../../../locales/i18n'
import ImgHighlightChristmas from '../../../christmasFriends/components/ImgHighlightChristmas'
import Card from '../../Card'
import CustomPressable from "../../pressables/CustomPressable"

export default class PostItemSmall extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Card style={styles.box}>
        <CustomPressable
          accessible
          accessibilityRole="button"
          accessibilityLabel={this.props.item.title}
          accessibilityHint={txt('home.postHint')}
          activeOpacity={0.8}
          onPress={this.props.onPressAction}
        >
          <View style={styles.itemContainer}>
            <View style={styles.img}>
              {this.props.item.type ? (
                <ImgHighlightChristmas
                  type={this.props.item.type}
                  imgFilename={this.props.item.imgFilename}
                />
              ) : (
                <ImageHighlight
                  source={{
                    uri: Paths.imageUrlBase + this.props.item.img_filename,
                  }}
                  size={'small'}
                />
              )}
            </View>
            <View style={styles.itemText}>
              <CustomText text={this.props.item.title} font={'bold'} />
              <CustomText numberOfLines={1} text={this.props.item.body} />
            </View>
          </View>
        </CustomPressable>
      </Card>
    )
  }
}

const styles = StyleSheet.create({
  box: {
    marginBottom: 12,
    padding: 12,
  },
  img: { marginRight: 12 },
  itemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  itemText: {
    alignSelf: 'center',
    flex: 1,
    overflow: 'hidden',
  },
})
