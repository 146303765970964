import React from 'react'
import CreateEditPost from '../../components/views/Post/CreateEditPost'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import { createPost } from '../../services/api/Posts'

export default function CreatePostScreen() {
  return (
    <MainContainer>
      <PageTitle title={TxtTMD.createPost.title[TxtTMD.lang]} />
      <CreateEditPost createPost={createPost} />
    </MainContainer>
  )
}
