import React from 'react'
import { Linking } from 'react-native'
import { View, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import Fontsizes from '../../../constants/Fontsizes'
import { txt } from '../../../locales/i18n'
import { TypographyStyles } from '../../../styles'
import NormalIcon from '../../icons/NormalIcon'
import { ImageHighlight } from '../../images/ImageHighlight'
import { CustomText } from '../../text/StyledText'
import CustomPressable from "../../pressables/CustomPressable"

export default function PressContact() {
  return (
    //Rasmus
    <View style={styles.employeeContainer}>
      <View>
        <ImageHighlight
          source={{
            uri: 'https://filer.boblberg.dk/Assets/Img/employees/Rasmus.jpg',
          }}
        />
      </View>
      <View style={styles.employeeTextContainer}>
        <View style={{ flexDirection: 'row' }}>
          <CustomText font="bold" style={styles.employeeName}>
            {txt('aboutUs.rasmusName')}
          </CustomText>
        </View>
        <View style={{ textAlign: 'left' }}>
          <CustomText>{txt('aboutUs.rasmusPosition')}</CustomText>
        </View>

        <CustomPressable onPress={() => Linking.openURL('mailto:rs@boblberg.dk')}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <NormalIcon
              accessibilityLabel={txt('aboutUs.emailIcon')}
              name="envelope"
              size={Fontsizes.s}
              color={Colors.text}
            />
            <View style={styles.paddingLeft}>
              <CustomText>{txt('aboutUs.rasmusMail')}</CustomText>
            </View>
          </View>
        </CustomPressable>

        <CustomPressable onPress={() => Linking.openURL(`tel:${+4520202078}`)}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <NormalIcon
              accessibilityLabel={txt('aboutUs.phoneIcon')}
              name="phone-alt"
              size={Fontsizes.s}
              color={Colors.text}
            />
            <View style={styles.paddingLeft}>
              <CustomText>{txt('aboutUs.rasmusPhone')}</CustomText>
            </View>
          </View>
        </CustomPressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  employeeContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginHorizontal: 12,
    marginTop: 12,
  },
  employeeName: { ...TypographyStyles.textBold },
  employeeTextContainer: { flexGrow: 1, flex: 1, marginLeft: 12 },
  paddingLeft: {
    paddingLeft: 12,
  },
})
