import React from 'react'
import { StyleSheet, View, TextInput } from 'react-native'
import './SearchBar.d.ts'

import { txt } from '../../locales/i18n'
import NormalIcon from '../icons/NormalIcon'
import CustomPressable from '../pressables/CustomPressable'

import Colors from '../../constants/Colors'
import Fontsizes from '../../constants/Fontsizes'
import { ShadowStyles, TypographyStyles, CornerStyles } from '../../styles'

export const SearchBar = (props) => (
	<View style={[styles.container, props.style]}>
		<NormalIcon color={Colors.grey} size={Fontsizes.l} name="search" />

		<TextInput
			placeholder={txt('searchField.placeHolder')}
			placeholderTextColor={Colors.inactive}
			accessibilityLabel={txt('searchField.placeHolder')}
			accessibilityHint={txt('searchField.hint')}
			{...props}
			style={[styles.input, props.inputStyle]}
		/>

		{props.value !== '' && (
			<CustomPressable
				onPress={() => props.onChangeText('')}
				accessibilityRole="button"
				accessibilityLabel={txt('searchField.clearLabel')}
				accessibilityHint={txt('searchField.clearHint')}
			>
				<NormalIcon color={Colors.grey} size={Fontsizes.m} name="times" />
			</CustomPressable>
		)}
	</View>
)

const styles = StyleSheet.create({
	container: {
		backgroundColor: Colors.white,
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 12,
		paddingVertical: 10,
		flex: 1,
		gap: 6,

		...CornerStyles.borderRadiusS,
		...ShadowStyles.shadowInputs,
	},

	input: {
		flex: 1,
		...TypographyStyles.text,
	},
})
