import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import { NotificationSwitch } from '../../switches/StyledSwitches'
import { CustomText } from '../../text/StyledText'
import { useSelector } from 'react-redux'
import { updateUserPreferences } from '../../../services/api/User'
import { useNavigation } from '@react-navigation/core'
import Card from '../../Card'

export default function GroupNotifications() {
  const preferences = useSelector((state) => state.preferences)
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({ title: txt('settings.groups') })
  }, [])

  function handlePostMailSwitch(value) {
    updateUserPreferences({ post_mail_notification: value })
  }

  function handlePostPushSwitch(value) {
    updateUserPreferences({ post_push_notification: value })
  }

  function handleCommentMailSwitch(value) {
    updateUserPreferences({ comment_mail_notification: value })
  }

  function handleCommentPushSwitch(value) {
    updateUserPreferences({ comment_push_notification: value })
  }

  function handleLikePushSwitch(value) {
    updateUserPreferences({ like_push_notification: value })
  }

  return (
    <Card style={styles.container}>
      <View style={[styles.notificationGroup, styles.borderBottom]}>
        <CustomText font="largeBold">{txt('settings.email')}</CustomText>
        <NotificationSwitch
          title={txt('settings.newPost')}
          value={preferences.post_mail_notification}
          handleChange={handlePostMailSwitch}
        />
        <NotificationSwitch
          title={txt('settings.newComment')}
          value={preferences.comment_mail_notification}
          handleChange={handleCommentMailSwitch}
        />
      </View>
      <View style={styles.notificationGroup}>
        <CustomText font="largeBold">{txt('settings.push')}</CustomText>
        <NotificationSwitch
          title={txt('settings.newPost')}
          value={preferences.post_push_notification}
          handleChange={handlePostPushSwitch}
        />
        <NotificationSwitch
          title={txt('settings.newComment')}
          value={preferences.comment_push_notification}
          handleChange={handleCommentPushSwitch}
        />
        <NotificationSwitch
          title={txt('settings.newLike')}
          value={preferences.like_push_notification}
          handleChange={handleLikePushSwitch}
        />
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  borderBottom: {
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: 1,
    paddingBottom: 12,
  },
  container: {
    alignSelf: 'center',
    marginTop: 12,
    maxWidth: Layout.maxFeedWidth,
    paddingHorizontal: 12,
    paddingTop: 12,
    width: '100%',
  },
  notificationGroup: {
    marginBottom: 12,
  },
})
