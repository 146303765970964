import React, { useEffect, useState } from 'react'
import { View, StyleSheet, FlatList, Keyboard, Image } from 'react-native'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import PostItemSmall from './PostItemSmall'
import { getPostsByUserId } from '../../../services/api/Posts'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import { ActivityIndicator } from 'react-native'
import { useSelector } from 'react-redux'
import NormalIcon, { BoblbergIcon } from '../../icons/NormalIcon'
import ChristmasInfoOverview from '../../../christmasFriends/components/ChristmasInfoOverview'
import { InputPrimary } from '../../text/StyledTextinputs'
import { editConversationTitle } from '../../../services/api/Inbox'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import ReportContent from '../../callToActions/reportContent'
import { useNavigation } from '@react-navigation/core'
import { isConnected } from '../../../services/api/ConnectionApi'
import { getUserImage } from '../../../services/api/User'
import CustomPressable from "../../pressables/CustomPressable"

export default function InfoOverview(props) {
  const conversationId = parseInt(props.route.params.id, 10)
  const [users, setUsers] = useState([])
  const [postsCollected, setPostsCollected] = useState(false)
  const [title, setTitle] = useState(null)
  const [successUpdate, setSuccessUpdate] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const conversations = useSelector((state) => state.conversations)
  const ownUser = useSelector((state) => state.user)
  const navigation = useNavigation()

  useEffect(() => {
    const conversation = conversations.find((c) => c.conversation_id === conversationId)
    setUsers(conversation?.otherUsers)
    setTitle(conversation?.title)
  }, [])

  useEffect(() => {
    async function getAllPosts() {
      const users = conversations.find((c) => c.conversation_id === conversationId)?.otherUsers ?? []

      const usersWithPostsPromises = users.map(async (user) => {
        const posts = await getPostsByUserId(user.id)
        return { id: user.id, username: user.username, posts }
      })

      const usersWithPosts = await Promise.all(usersWithPostsPromises)
      setUsers(usersWithPosts)
      setPostsCollected(true)
    }

    if (!postsCollected && conversations.length > 0) {
      getAllPosts()
    }
  }, [users])

  const handleUpdateTitle = async () => {
    const res = await editConversationTitle(title, conversationId)
    if (res) {
      Keyboard.dismiss()
      setSuccessUpdate(true)
      setDisabled(true)
    }
  }

  const renderItem = ({ item }) => {
    return (
      <PostItemSmall
        item={item}
        onPressAction={() => props.navigateToPost(item.id)}
        backgroundColor={props.backgroundColor}
      />
    )
  }

  const handleChangeText = (text) => {
    setDisabled(false)
    setSuccessUpdate(false)
    setTitle(text)
  }

  function RenderUser({ user }) {
    const [isInConnection, setIsInConnection] = useState(false)
    const [image, setImage] = useState(null)
    useEffect(() => {
      isConnected(user.id).then((connected) => {
        setIsInConnection(connected)
        if (connected) getUserImage(user.id).then((img) => {
          if (img.filename) setImage({
            filename: img.filename,
            base64: img.base64,
          })
        })
      })
      return () => setImage(null)
    }, [])

    return (
      <CustomPressable
        disabled={!isInConnection}
        style={[styles.members, { justifyContent: 'space-between' }]}
        onPress={() => props.navigateToProfile(user.id)}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {image ? (
            <Image accessibilityIgnoresInvertColors style={styles.image} source={image && { uri: image.base64 }} />
          ) : (
            <NormalIcon name="user" size={16} style={{ paddingHorizontal: 4.5 }} stroke={'fas'} />
          )}
          <CustomText font={'bold'} style={{ marginLeft: 6 }}>
            {user.username}
          </CustomText>
        </View>
        {isInConnection && (
          <NormalIcon name="chevron-right" size={16} stroke="fas" />)}
      </CustomPressable>
    )
  }

  return (
    <View style={styles.container}>
      {/* conversation members */}
      <View accessible style={styles.margin30}>
        <View style={styles.icon}>
          <NormalIcon name="users" size={20} color={Colors.tintColor} style={styles.spacing} />
          <CustomText style={styles.textContainer} font={'small'}>
            {txt('conversations.info.members').toUpperCase()}
          </CustomText>
        </View>
        <View style={styles.members}>
          <NormalIcon
            name="user"
            size={16}
            style={{ paddingHorizontal: 4.5 }}
            stroke={'fas'}
            color={Colors.blackTransparent54}
          />
          <CustomText style={{ marginLeft: 6 }}>
            {ownUser.username}
          </CustomText>
        </View>
        {users?.map((user) => (
          <RenderUser key={user.id} user={user} />
        ))}
      </View>

      <View>
        <View style={styles.icon}>
          <NormalIcon name="comment-dots" size={20} color={Colors.tintColor} style={styles.spacing} />
          <CustomText font={'small'}>{txt('conversations.info.title').toUpperCase()}</CustomText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <InputPrimary
            value={title}
            style={{ marginRight: 12, flex: 1 }}
            onChangeText={handleChangeText}
          />
          <ButtonPrimary
            title={txt('conversations.info.save')}
            onPress={handleUpdateTitle}
            success={successUpdate}
            disabled={disabled || title?.length < 2}
            style={{ marginTop: 6, backgroundColor: successUpdate ? Colors.green : Colors.tintColor }}
          />
        </View>
      </View>

      {/* Momber's christimas posts */}
      {props.route.params.christmasPostId && (
        <ChristmasInfoOverview
          navigateToPost={props.navigateToChristmasPost}
          christmasPostId={props.route.params.christmasPostId}
        />
      )}

      {/* Member's posts */}
      <View>
        {!postsCollected ? (
          <ActivityIndicator />
        ) : (
          <View>
            {users?.map((user) => {
              return (
                <View key={user.id} style={styles.postContainer}>
                  <View style={styles.icon}>
                    <BoblbergIcon name="bubbles" size={20} color={Colors.tintColor} style={styles.spacing} />
                    <CustomText font={'small'} style={styles.textContainer}>
                      {txt('conversations.info.posts').toUpperCase() + user.username.toUpperCase()}
                    </CustomText>
                  </View>
                  {user.posts?.length > 0 ? (
                    <FlatList data={user.posts} renderItem={renderItem} keyboardDismissMode="on-drag" />
                  ) : (
                    <CustomText style={{ color: Colors.inactive }}>
                      {user.username + txt('conversations.info.noPosts')}
                    </CustomText>
                  )}
                </View>
              )
            })}
          </View>
        )}
      </View>
      <ReportContent reportingTypeId={1} contentId={conversationId} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
    paddingLeft: 12,
    paddingRight: 12,
    width: '100%',
  },
  icon: { alignItems: 'center', flexDirection: 'row', marginRight: 6 },
  margin30: { marginBottom: 24 },
  members: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 12,
  },
  postContainer: { marginBottom: 24 },
  spacing: { marginRight: 6 },
  textContainer: { marginBottom: 12, marginTop: 12 },
  image: { width: 25, height: 25, borderRadius: 12.5 },
})
