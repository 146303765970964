import React from 'react'
import { View, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'

export default class Morten extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.morten.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/morten.jpeg',
              }}
              accessibilityIgnoresInvertColors
            />

            <CustomText font="bold">{txt('blog.morten.author')}</CustomText>
          </View>
          <View>
            <CustomText>{txt('blog.morten.text1')}</CustomText>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
