import { Dimensions } from 'react-native'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 1000,
  small: width < 700,
  maxFeedWidth: 800,
  headerHeight: height > 800 ? 64 : 48,
  logoWidth: 150,
}
