import React from 'react'
import { TextInput, View, StyleSheet } from 'react-native'

import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import { ShadowStyles, TypographyStyles } from '../../styles'

import NormalIcon from '../../components/icons/NormalIcon'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../pressables/CustomPressable"

export const SearchBar = (props) => (
  <View style={styles.container}>
    <NormalIcon color={Colors.grey} size={Fontsizes.m} name="search" />

    <TextInput
      style={styles.input}
      value={props.value}
      onChangeText={props.onChange}
      placeholderTextColor={Colors.inactive}
      placeholder={txt('searchField.placeHolder')}
      accessibilityLabel={txt('searchField.placeHolder')}
      accessibilityHint={txt('searchField.hint')}
    />

    {props.value !== '' && (
      <CustomPressable
        onPress={props.onClear}
        accessibilityLabel={txt('searchField.clearLabel')}
        accessibilityHint={txt('searchField.clearHint')}
      >
        <NormalIcon color={Colors.grey} size={Fontsizes.m} name="times" />
      </CustomPressable>
    )}
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 10,
    alignItems: 'center',
    flexDirection: 'row',
    ...ShadowStyles.shadowInputs,
    marginTop: 6,
  },

  input: {
    flex: 1,
    marginLeft: 6,
    ...TypographyStyles.text,
  },
})
