import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { setOrigin, setShowMakeInfluence } from '../../../redux/actions'
import Fontsizes from '../../../constants/Fontsizes'
import Colors from '../../../constants/Colors'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../text/StyledText'
import { CustomInputWithValidationNoShadow } from '../../text/StyledTextinputs'
import { ModalSelectorCustomNoShadow } from '../../modalSelectors/ModalSelector'
import RegexPatterns from '../../../constants/RegexPatterns'
import NormalIcon from '../../icons/NormalIcon'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import { InvalidInputNoticeCentered, InvalidInputNoticeSimple } from '../../notices/InvalidInputNotices'
import { signup, login } from '../../../services/api/User'
import { CornerStyles, TypographyStyles } from '../../../styles'
import { URLOpener } from '../../../utils/URLOpener'

import FacebookPixel from '../../Integrations/FacebookPixel'
import FacebookPixelSignup from '../../Integrations/FacebookPixelSignup'
import { getInvitedUser } from '../../../services/api/User'
import { Picker } from '@react-native-picker/picker'
import store from '../../../redux/store'
import { joinUniverseById } from '../../../services/api/Universe'
import { getCountryMunicipalities } from '../../../services/api/Municipalities'
import { getRegion, getLanguage, countries } from '../../../services/localization'
import CustomPressable from "../../pressables/CustomPressable"

class SignupField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      birthYearList: [],
      municipality: '',
      municipalityId: 0,
      token: '',
      name: '',
      birthYear: props.defaultBirthYear ?? '',
      email: '',
      password: '',
      checkBoxChecked: false,
      passwordHidden: true,
      isUsernameValid: true,
      isEmailValid: true,
      isMunicipalityValid: true,
      isBirthYearValid: true,
      isPasswordValid: true,
      signUpError: false,
      signUpResponse: '',
      municipalities: [],
      region: getRegion(),
      missingFields: {
        municipalityId: false,
        username: false,
        birthYear: false,
        email: false,
        password: false,
      },
    }
  }

  async componentDidMount() {
    this.getUserInfoViaToken()
    this.setDefaultMunicipality()
    this.setBirthdayYearList()
    this.setMunicipalities()
  }

  componentDidUpdate() {
    const universe = this.props.route.params?.universe?.toLowerCase()
    let { originScreen, originStack, originRootStack, originParams } = this.props
    if (originScreen || originStack || originRootStack) {
      const payload = { rootStack: originRootStack || 'app' }
      if (originScreen) payload.screen = originScreen
      if (originStack) payload.stack = originStack
      if (originParams) payload.params = originParams
      if (universe) payload.universe = universe
      store.dispatch(setOrigin(payload))
    }
    if (this.state.municipalityId === 35 && !universe) {
      // only if municipality = Herlev and universe is undefined
      store.dispatch(setOrigin({ rootStack: 'universe', universe: 'herlev' }))
    }
  }

  async setMunicipalities() {
    const region = getRegion()
    const country = countries.find((country) => country.region === region)
    const municipalities = await getCountryMunicipalities(country?.name)
    this.setState({
      region,
      municipalities: [{ id: 0, name: txt('signupField.municipalityPlaceholder') }, ...municipalities],
    })
  }

  async getUserInfoViaToken() {
    // TODO: find alternative for mobile
    if (Platform.OS === 'web') {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('token')
      if (token) {
        const result = await getInvitedUser(token)
        if (result) {
          const { username, email } = result
          this.setState({
            name: username,
            email,
            token,
          })
        }
      }
    }
  }

  setDefaultMunicipality() {
    let isValid = false
    const defaultMunicipality = this.props.selectedMunicipality
    if (defaultMunicipality) {
      const selectedItem = this.state.municipalities.find((e) => {
        return e.name === defaultMunicipality
      })
      isValid = selectedItem?.id !== 0 ? true : false
      this.setState({
        municipality: defaultMunicipality,
        municipalityId: selectedItem?.id,
        isMunicipalityValid: isValid,
      })
    }
  }

  setBirthdayYearList() {
    let yearStart = 1900
    let yearEnd = new Date().getFullYear() - 15
    let years = Array(yearEnd - yearStart + 1)
      .fill()
      .map(() => yearStart++)
    years.reverse()
    years.unshift(txt('signupField.birthYearPlaceHolder'))
    this.setState({ birthYearList: years })
  }

  signup = async () => {
    this.setState({ signUpError: false, signUpResponse: '', loading: true })
    const { name, email, password, birthYear, municipalityId, checkBoxChecked, token } = this.state
    if (!this.formIsValid() || !checkBoxChecked) {
      this.setMissingFields()
      this.setState({ signUpError: true, password: '', loading: false })
      return
    }

    const region = this.state?.region
    const country = countries.find((country) => country.region === region)
    const language = await getLanguage()

    const result = await signup({
      name,
      email,
      password,
      birthYear,
      municipalityId,
      language: language,
      token,
      origin: this.props.originScreen || 'Boblberg',
      country: country?.name ?? 'Denmark',
    })

    if (result.error) {
      const errorText =
        result.error === 'EMAIL_ALREADY_TAKEN' ? txt('signupField.invalidEmail') : txt('signupField.signUpError')
      this.setState({
        signUpResponse: errorText,
        signUpError: true,
        loading: false,
      })
      return
    }

    //Fires CompleteRegistration Pixel - Should work...
    const showSignUpPixel = Platform.OS === 'web'
    this.setState({ showSignUpPixel, loading: false })
    this.props.setShowMakeInfluence(true)
    if (Platform.OS === 'web') {
      window.plausible('Signup', {
        props: { origin: this.props.originScreen || 'Home' },
      })
    }
    await login(email, password)
    if (this.props.onSignupSuccess) return this.props.onSignupSuccess()
    else {
      //add all users from Herlev municipality to herlevbobler
      if (municipalityId === 35) await joinUniverseById(9)
    }
  }

  formIsValid = () => {
    let everythingValid = false
    if (
      this.state.isBirthYearValid &&
      this.state.isUsernameValid &&
      this.state.isEmailValid &&
      this.state.isMunicipalityValid &&
      this.state.isPasswordValid
    )
      everythingValid = true

    let empty = false
    if (
      this.state.birthYear.length === 0 ||
      this.state.name.length === 0 ||
      this.state.email.length === 0 ||
      this.state.municipalityId === 0 ||
      this.state.password.length === 0
    )
      empty = true

    return !empty && everythingValid
  }

  setMissingFields() {
    // true = missing correct data, false = data is correct
    const missingFields = {
      municipality: !(this.state.municipalityId >= 1 && this.state.municipalityId <= 98),
      username: checkForMissingValue(this.state.name, RegexPatterns.username),
      birthYear: !(this.state.birthYear >= 1900 && this.state.birthYear <= new Date().getFullYear() - 15),
      email: checkForMissingValue(this.state.email, RegexPatterns.email),
      password: checkForMissingValue(this.state.password, RegexPatterns.password),
      checkBoxChecked: !this.state.checkBoxChecked,
    }

    // for later if we want to show errors under each info after failure
    // this.setState({
    //   isMunicipalityValid: !missingFields.municipality,
    //   isUsernameValid: !missingFields.username,
    //   isBirthYearValid: !missingFields.birthYear,
    //   isEmailValid: !missingFields.email,
    //   isPasswordValid: !missingFields.password
    // })

    // check if value is incorrect and therefor missing
    function checkForMissingValue(value, pattern) {
      if (!pattern) return true
      let isValid = false
      // string pattern, one validation rule
      if (typeof pattern === 'string') {
        const condition = new RegExp(pattern, 'g')
        isValid = condition.test(value)
      }
      // array patterns, multiple validation rules
      if (typeof pattern === 'object') {
        const conditions = pattern.map((rule) => new RegExp(rule, 'g'))
        isValid = conditions.map((condition) => condition.test(value))
      }

      // return boolean indicating if value is missing
      if (typeof isValid === 'boolean' && isValid) {
        return false
      } else if (typeof isValid === 'object' && !isValid.includes(false)) {
        return false
      } else {
        return true
      }
    }
    this.setState({ signUpResponse: '' })
    this.setState({ missingFields })
  }

  showMissingFields() {
    const missingFields = this.state.missingFields

    if (this.state.signUpResponse !== '') {
      return this.state.signUpResponse
    }

    // removes fields that are filled out
    const removeFalsy = (obj) => {
      let newObj = {}
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
          newObj[prop] = obj[prop]
        }
      })
      return newObj
    }

    // translate and create list of missing fields
    var str = ''
    Object.keys(removeFalsy(missingFields)).forEach((element) => {
      if (element === 'checkBoxChecked') str += '\n* ' + txt('signupField.InvalidcheckBoxTermsAndPrivacy')
      else str += '\n* ' + txt(`profile.${element}`)
    })
    return str.length === 0 ? txt('signupField.signUpError') : txt('signupField.invalidSignUpInfo') + str
  }

  // Picker used if os === web, else modalselectorcustom
  _pickDropdownFromPlatformForMunicipalities() {
    if (Platform.OS === 'web') {
      var isValid = false
      return (
        <View>
          <Picker
            accessibilityLabel={txt('signupField.municipalityPlaceholder')}
            accessibilityHint={txt('signupField.municipalityHint')}
            selectedValue={this.defaultMunicipality ? this.defaultMunicipality : this.state.municipality}
            onValueChange={(value, index) => {
              const selectedItem = this.state.municipalities.find((e) => {
                return e.name === value
              })
              isValid = selectedItem.id !== 0 ? true : false
              this.setState({
                municipality: value,
                municipalityId: selectedItem.id,
                isMunicipalityValid: isValid,
              })
            }}
            style={styles.picker}
          >
            {this.state.municipalities.map((item) => {
              return <Picker.Item label={item.name} value={item.name} key={item.id} />
            })}
          </Picker>
          {!this.state.isMunicipalityValid && !isValid ? (
            <InvalidInputNoticeCentered>{txt('invalidNotice.invalidMunicipality')}</InvalidInputNoticeCentered>
          ) : null}
        </View>
      )
    } else {
      return (
        <View style={styles.marginBottom}>
          <ModalSelectorCustomNoShadow
            data={this.state.municipalities}
            keyExtractor={(data) => data.id}
            labelExtractor={(data) => data.name}
            key={(data) => data.id}
            initValue={this.defaultMunicipality ? this.defaultMunicipality : txt('signupField.municipalityPlaceholder')}
            style={{ shadowColor: Colors.transparent }}
            onChange={(value) => {
              isValid = value.id !== 0 && typeof value.id === 'number' ? true : false
              this.setState({
                municipality: value.name,
                municipalityId: value.id,
                isMunicipalityValid: isValid,
              })
            }}
          />
          {!this.state.isMunicipalityValid && !isValid ? (
            <InvalidInputNoticeCentered>{txt('invalidNotice.invalidMunicipality')}</InvalidInputNoticeCentered>
          ) : null}
        </View>
      )
    }
  }

  // Picker used if os === web, else modalselectorcustom
  _pickDropdownFromPlatformForBirthYears() {
    if (Platform.OS === 'web') {
      var isValid = false
      return (
        <View>
          <Picker
            selectedValue={this.state.birthYear}
            onValueChange={(option, index) => {
              isValid = index !== 0 ? true : false
              this.setState({
                birthYear: option,
                isBirthYearValid: isValid,
              })
            }}
            accessibilityLabel={txt('signupField.birthYearPlaceHolder')}
            accessibilityHint={txt('signupField.birthYearHint')}
            style={styles.picker}
          >
            {this.state.birthYearList.map((item) => {
              return <Picker.Item label={item.toString()} value={item} key={item} />
            })}
          </Picker>
          {!this.state.isBirthYearValid && !isValid ? (
            <InvalidInputNoticeCentered>{txt('invalidNotice.invalidBirthYear')}</InvalidInputNoticeCentered>
          ) : null}
        </View>
      )
    } else {
      return (
        <View style={styles.marginBottom}>
          <ModalSelectorCustomNoShadow
            data={this.state.birthYearList}
            keyExtractor={(data) => data}
            labelExtractor={(data) => data}
            key={(data) => data.id}
            initValue={txt('signupField.birthYearPlaceHolder')}
            onChange={(value) => {
              isValid = value.toString().length === 4 && typeof value === 'number' ? true : false
              this.setState({
                birthYear: value,
                isBirthYearValid: isValid,
              })
            }}
          />
          {!this.state.isBirthYearValid && !isValid ? (
            <InvalidInputNoticeCentered>{txt('invalidNotice.invalidBirthYear')}</InvalidInputNoticeCentered>
          ) : null}
        </View>
      )
    }
  }

  keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  navigateToLogin() {
    if (this.props.originStack === 'ChristmasTypeSelection') {
      this.props.navigation.navigate('ChristmasLoginScreen')
    } else {
      this.props.navigation.navigate('Login')
    }
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <FacebookPixel />
        {this.state.showSignUpPixel ? <FacebookPixelSignup /> : null}

        <View style={styles.container}>
          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <CustomText font="bold" style={styles.title}>
                {txt('frontPage.signupText')}
              </CustomText>

              {Platform.OS === 'web' && (
                <Picker
                  onValueChange={(region) => location.assign('https://app.boblberg.' + region)}
                  style={styles.regionPicker}
                  value={this.state?.region}
                >
                  <Picker.Item label="DK" value="dk" />
                  <Picker.Item label="SE" value="se" />
                  <Picker.Item label="DE" value="de" />
                </Picker>
              )}
            </View>

            <View style={styles.marginTop}>{this._pickDropdownFromPlatformForMunicipalities()}</View>
            <View style={styles.marginTop}>
              <CustomInputWithValidationNoShadow
                autoComplete="name"
                accessibilityLabel={txt('signupField.namePlaceholder')}
                accessibilityHint={txt('signupField.nameHint')}
                placeholder={txt('signupField.namePlaceholder')}
                inputStyle={styles.text}
                pattern={RegexPatterns.username}
                invalidMessage={txt('invalidNotice.invalidUsername')}
                isInputValid={this.state.isUsernameValid}
                onValidation={(isValid) => {
                  this.setState({ isUsernameValid: isValid })
                }}
                onChangeText={(name) => {
                  this.setState({ name })
                }}
                value={this.state.name}
              />
            </View>
            <View style={styles.marginTop}>{this._pickDropdownFromPlatformForBirthYears()}</View>
            <View style={styles.marginTop}>
              <CustomInputWithValidationNoShadow
                inputMode="email"
                placeholder={txt('signupField.emailPlaceHolder')}
                inputStyle={styles.text}
                accessibilityLabel={txt('signupField.emailPlaceHolder')}
                accessibilityHint={txt('signupField.emailHint')}
                autoCompleteType="off"
                autoCapitalize="none"
                pattern={RegexPatterns.email}
                invalidMessage={txt('invalidNotice.invalidEmail')}
                isInputValid={this.state.isEmailValid}
                onValidation={(isValid) => {
                  this.setState({ isEmailValid: isValid })
                }}
                onChangeText={(email) => {
                  this.setState({ email })
                }}
                value={this.state.email}
              />
            </View>
            <View style={styles.marginTop}>
              <CustomInputWithValidationNoShadow
                value={this.state.password}
                placeholder={txt('signupField.passwordPlaceHolder')}
                accessibilityLabel={txt('signupField.passwordPlaceHolder')}
                accessibilityHint={txt('signupField.passwordHint')}
                autoCompleteType="off"
                secureTextEntry={this.state.passwordHidden}
                pattern={RegexPatterns.password}
                invalidMessage={txt('invalidNotice.invalidPassword')}
                isInputValid={this.state.isPasswordValid}
                onValidation={(isValid) => {
                  this.setState({ isPasswordValid: isValid })
                }}
                onChangeText={(password) => {
                  this.setState({ password })
                }}
              >
                <CustomPressable
                  hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
                  style={styles.icon}
                  onPress={() => this.setState({ passwordHidden: !this.state.passwordHidden })}
                  accessibilityRole="button"
                  accessibilityLabel={
                    this.state.passwordHidden ? txt('loginField.passwordHide') : txt('loginField.passwordShow')
                  }
                  accessibilityHint={txt('loginField.passwordShow')}
                  accessibilityLiveRegion="polite"
                >
                  <NormalIcon
                    name={this.state.passwordHidden ? "eye-slash" : "eye"}
                    size={Fontsizes.l}
                    color={Colors.text}
                    stroke="fas"
                  />
                </CustomPressable>
              </CustomInputWithValidationNoShadow>
            </View>
          </View>
          <View //terms and privacy policy
            style={styles.termsContainer}
          >
            <CustomPressable onPress={() => this.setState({ checkBoxChecked: !this.state.checkBoxChecked })}>
              <NormalIcon
                name={this.state.checkBoxChecked ? "check-square" : "square"}
                color={Colors.tintColor}
                style={styles.marginRight10}
                accessibilityRole="checkbox"
                accessibilityLabel={
                  this.state.checkBoxChecked
                    ? txt('signupField.termsAndPrivacyPolicyUncheck')
                    : txt('signupField.termsAndPrivacyPolicyCheck')
                }
                accessibilityChecked={this.state.checkBoxChecked}
                accessibilityHint={txt('signupField.termsAndPrivacyPolicyCheck')}
                accessibilityLiveRegion="polite"
              />
            </CustomPressable>
            <View style={styles.termsTextContainer}>
              <CustomText style={styles.white}>
                {txt('signupField.termsAndPrivacyPolicy1')}
                <CustomText
                  font="bold"
                  style={styles.white}
                  onPress={() => {
                    this.props.navigation.navigate('Conditions')
                  }}
                >
                  {txt('signupField.termsAndPrivacyPolicy2')}
                </CustomText>
                {txt('signupField.termsAndPrivacyPolicy3')}
                <CustomText
                  font="bold"
                  style={styles.white}
                  onPress={() => {
                    URLOpener(`https://filer.boblberg.dk/Files/Privacy_Policy/boblberg_privacy_policy_${this.state.region}.pdf`)
                  }}
                >
                  {txt('signupField.termsAndPrivacyPolicy4')}
                </CustomText>
                {txt('signupField.termsAndPrivacyPolicy5')}
              </CustomText>
            </View>
          </View>

          <View style={styles.signUpButton}>
            <ButtonPrimary
              title={txt('signupField.signupBtn')}
              onPress={this.signup}
              font="smallBold"
              loading={this.state.loading}
            />
            {this.state.signUpError === true ? (
              <InvalidInputNoticeSimple>{this.showMissingFields()}</InvalidInputNoticeSimple>
            ) : null}
          </View>
          <View style={styles.alreadyUser}>
            <CustomPressable
              accessibilityRole="button"
              onPress={() => this.navigateToLogin()}
              accessibilityLabel={txt('signupField.alreadyAUser') + txt('signupField.loginHere')}
            >
              <CustomText accessible={false} style={styles.white}>
                {txt('signupField.alreadyAUser')}
              </CustomText>
              <View style={styles.alignSelfCenter}>
                <CustomText font="bold" accessible={false} style={styles.white}>
                  {txt('signupField.loginHere')}
                </CustomText>
              </View>
            </CustomPressable>
          </View>
        </View>
        {this.keyboardSpacerShow()}
      </View>
    )
  }
}
export default connect(null, { setShowMakeInfluence })(SignupField)

const styles = StyleSheet.create({
  alignSelfCenter: { alignSelf: 'center' },
  alreadyUser: {
    alignSelf: 'center',
    marginTop: 18,
  },
  container: {
    backgroundColor: Colors.black,
    margin: 10,
    marginTop: 10,
    padding: 18,
    paddingVertical: 24,
    ...CornerStyles.borderRadiusS,
  },
  icon: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    width: 40,
    ...CornerStyles.borderRadiusS,
  },
  marginRight10: { marginRight: 10 },
  marginTop: { marginTop: 10 },
  picker: {
    ...CornerStyles.borderRadiusS,
    height: 40,
    paddingLeft: 5,
    ...TypographyStyles.text,
    backgroundColor: Colors.white,
    borderColor: Colors.transparent,
    color: Colors.text,
  },
  regionPicker: {
    width: 48,
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: 18,
    color: Colors.white,
  },
  signUpButton: {
    alignItems: 'center',
    marginTop: 18,
  },
  termsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  termsTextContainer: { flexGrow: 1, flex: 1 },
  text: { ...TypographyStyles.text },
  title: {
    color: Colors.white,
    marginBottom: 10,
    textAlign: 'center',
  },
  white: { color: Colors.white },
  widthHeight: {
    alignSelf: 'center',
    flex: 1,
    maxWidth: 500,
    width: '100%',
  },
})
