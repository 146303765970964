import React, { useEffect, useState } from 'react'
import { View, Platform, StyleSheet, Image } from 'react-native'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import SignupField from '../../components/views/Auth/SignupField'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import { SpacingStyles } from '../../styles'
import MainContainer from '../../components/MainContainer/MainContainer'
import { joinUniverseById } from '../../services/api/Universe'
import {
  getUniverseConfig,
  getUniverseId,
  isUniverse,
} from '../../universe/universeController'

export default function SignupUniverseScreen(props) {
  const [universeName, setUniverseName] = useState()

  useEffect(() => {
    const mount = async () => {
      const uni = props.route.params?.universe?.toLowerCase()
      const isValidUniverse = isUniverse(uni)
      if (isValidUniverse) setUniverseName(uni)
      else props.navigation.popToTop()

      const universe = getUniverseConfig(uni)
      if (universe) {
        props.navigation.setOptions({
          headerTitle: () => {
            if (universe.logo && universe.logoLabel) {
              return (
                <Image
                  style={styles.logo}
                  resizeMode="contain"
                  source={universe.logo}
                  accessible
                  accessibilityLabel={universe.logoLabel}
                  accessibilityIgnoresInvertColors
                  accessibilityRole="image"
                />
              )
            } else {
              return <CustomText>{universe.headerTitle}</CustomText>
            }
          },
        })
      }
    }
    mount()
  }, [])

  const handleSignupSuccess = async () => {
    const universeId = getUniverseId(universeName.toLowerCase())
    await joinUniverseById(universeId)
  }

  const keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  if (!universeName) return <View />
  return (
    <MainContainer>
      <View style={styles.widthHeight}>
        <View style={styles.textContainer}>
          <CustomText font="bold" style={styles.title}>
            {txt(`universe.${universeName}.signup.title`)}
          </CustomText>
          <CustomText style={styles.subtitle}>
            {txt(`universe.${universeName}.signup.subtitle`)}
          </CustomText>
        </View>

        <SignupField
          navigation={props.navigation}
          originRootStack={'universe'}
          route={props.route}
          onSignupSuccess={handleSignupSuccess}
          selectedMunicipality={
            universeName.charAt(0).toUpperCase() + universeName.slice(1)
          }
        />
      </View>
      {keyboardSpacerShow()}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  logo: { height: 32, width: 200 },
  subtitle: { marginBottom: 15, textAlign: 'center' },
  textContainer: { marginLeft: 10, marginRight: 10 },
  title: { textAlign: 'center' },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
})
