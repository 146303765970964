import { StyleSheet, Text, View } from 'react-native'
import Colors from '../../constants/Colors'
import React from 'react'

export function NotificationBadge({number, style}) {
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.number}>
        {number}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 18,
    height: 18,
    paddingHorizontal: 4,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.badgeColor,
  },
  number: {
    fontSize: 12,
    color: Colors.white,
  }
})