import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { deleteBooking } from '../../../services/api/snakSammen'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import moment from 'moment'
import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import { formatDate } from "./utils/helperFunctions"
import Layout from "../../../constants/Layout"

export default function OwnNotBookedSessions(props) {
  //sets locale to danish time
  moment.locale([txt('videoChat.locale')])

  const [dialogVisibleNotBooked, setDialogVisibleNotBooked] = useState(false)
  const [currentNotBookedItem, setCurrentNotBookedItem] = useState('')

  return (
    <View style={styles.container}>
      {props.ownNotBookedSessions.length === 0 ? (
        <View>
          <CustomText font="gotham-xlarge" style={styles.titleBold}>
            {txt('videoChat.ownNotBookedSessions')}
          </CustomText>
          <CustomText font="gotham" style={styles.noSession}>{txt('videoChat.noOwnNotBookedSessions')}</CustomText>
        </View>
      ) : (
        <CustomText font="gotham-xlarge" style={styles.titleBold}>
          {txt('videoChat.ownNotBookedSessions')}
        </CustomText>
      )}

      {props.ownNotBookedSessions.map((item, key) => {
        const formattedDate = formatDate(item.start_date, item.end_date)
        return (
          <View key={key} style={styles.card}>
            <View style={{ flex: 2 }}>
              <CustomText
                style={Layout.small && {textAlign: 'center'}}
                font="gotham-bold-small"
              >
                {formattedDate}
              </CustomText>
            </View>
            <ButtonPrimary
              title={txt('videoChat.cancel')}
              onPress={() => {
                setCurrentNotBookedItem(item)
                setDialogVisibleNotBooked(true)
              }}
              style={styles.button}
              titleStyle={styles.buttonTitle}
            />
          </View>
        )
      })}

      <CustomDialog visible={dialogVisibleNotBooked} onDismiss={() => setDialogVisibleNotBooked(false)}>
        <ContentContainer>
          <CustomText font="gotham">{txt('videoChat.deleteDialogText')}</CustomText>
          <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 24 }}>
            <ButtonPrimary
              onPress={() => setDialogVisibleNotBooked(false)}
              style={styles.button}
              titleStyle={styles.buttonTitle}
            >
              {txt('videoChat.dialogNo')}
            </ButtonPrimary>
            <ButtonPrimary
              onPress={async () => {
                await deleteBooking(currentNotBookedItem.id)
                await props.reloadAll()
                setDialogVisibleNotBooked(false)
              }}
              style={styles.button}
              titleStyle={styles.buttonTitle}
            >
              {txt('videoChat.dialogYes')}
            </ButtonPrimary>
          </View>
        </ContentContainer>
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
  },
  card: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    flexDirection: Layout.small ? 'column' : 'row',
    alignItems: 'center',
    marginTop: 10,
    paddingVertical: Layout.small ? 18 : 12,
    paddingHorizontal: Layout.small ? 24 : 18,
  },
  titleBold: {
    marginTop: 24,
    marginBottom: 6,
  },
  button: {
    borderRadius: 10,
    backgroundColor: Colors.redCross.background,
    paddingVertical: 13,
    paddingHorizontal: 32,
    marginLeft: 12,
    minWidth: 115,
    marginTop: Layout.small ? 18 : 0,
  },
  buttonTitle: {
    color: Colors.redCross.text,
    fontSize: 14,
    fontFamily: 'gotham-bold'
  },
})
