import React from 'react'
import { View, Text, StyleSheet, Image, Dimensions } from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import PageTitle from '../../../tags/PageTitle'
import Layout from '../../../../constants/Layout'
import { ProjectStyles } from './ProjectStyles'

const { width } = Dimensions.get('window')

export default class CHS extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={ProjectStyles.widthHeight}>
        <PageTitle title={TxtProjects.chs.title[TxtProjects.lang]} />

        <View style={ProjectStyles.container}>
          <Text
            style={ProjectStyles.blogTitle}
            accessibilityRole="header"
            aria-level={1}
          >
            {TxtProjects.chs.title[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.chs.text1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.chs.text2[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.chs.text3[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.chs.projectPeriod[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.chs.period[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.chs.involved[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.chs.partner1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.chs.partner2[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.chs.partner3[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageFK}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/frederiksberg-kommune.png',
            }}
          />
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageHK}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/hvidovre-kommune.png',
            }}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  imageFK: {
    height: Layout.isSmallDevice ? width * 0.25 : 100,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width : 400,
  },
  imageHK: {
    height: Layout.isSmallDevice ? width * 0.33 : 150,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.9 : 400,
  },
})
