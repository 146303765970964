import React, { useEffect } from 'react'
import MainContainer from '../../components/MainContainer/MainContainer'
import { Image, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import { universes } from '../../universe/universeConfig'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import { useSelector } from 'react-redux'
import { shadowInputs } from '../../styles/shadows'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function NeighborIntroScreen(props) {
  const userToken = useSelector((state) => state.userToken)

  const neighborhoodIds = [2, 3, 4, 5, 6, 7]
  const neighborhoods = universes.filter((universe) =>
    neighborhoodIds.includes(universe.id)
  )

  const neighborhoodOnPress = (universe) => {
    if (userToken) {
      props.navigation.navigate('universe', {
        screen: 'UniverseBoblStack',
        params: { universe },
      })
    } else {
      props.navigation.navigate('IntroUniverse', { universe })
    }
  }

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Image
          style={styles.headerLogo}
          resizeMode="contain"
          source={{
            uri: 'https://filer.boblberg.dk/Assets/Img/universe/nabobobler_logo.png',
          }}
          accessibilityLabel={universes[8]?.logoLabel}
          accessibilityIgnoresInvertColors
          accessibilityRole="image"
          accessible
        />
      ),
    })
  }, [props.navigation])

  const neighborhoodItem = (neighborhood) => (
    <CustomPressable
      key={neighborhood.id}
      style={styles.item}
      accessibilityRole="button"
      accessibilityLabel={neighborhood.name.label}
      onPress={() => neighborhoodOnPress(neighborhood.name)}
    >
      <Image
        resizeMode="contain"
        source={{ uri: neighborhood.logo }}
        style={neighborhood.id === 3 ? styles.sabLogo : styles.logo}
        accessibilityIgnoresInvertColors={true}
      />
      <CustomText accessible={false} font="largeBold" style={styles.sabText}>
        {neighborhood.id === 3 && txt('universe.neighborIntro.valby')}
      </CustomText>
    </CustomPressable>
  )

  return (
    <MainContainer>
      <CustomText style={styles.title} font="largeBold">
        {txt('universe.neighborIntro.heading')}
      </CustomText>
      <CustomText style={styles.subtitle}>
        {txt('universe.neighborIntro.subheading')}
      </CustomText>
      <View style={styles.itemsContainer}>
        {neighborhoods.map(neighborhoodItem)}
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  headerLogo: { height: 32, width: 200 },
  item: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 20,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 12,
    marginTop: 12,
    minWidth: 250,
    padding: 18,
    paddingHorizontal: 24,
    ...shadowInputs,
  },
  itemsContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 1200,
    padding: 24,
    paddingHorizontal: 12,
  },
  logo: { alignSelf: 'center', height: 60, width: 200 },
  sabLogo: {
    alignSelf: 'center',
    height: 60,
    width: 100,
  },
  sabText: {
    fontSize: 32,
    color: '#2b72ba',
    paddingLeft: 12,
  },
  subtitle: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 12,
    marginTop: 24,
    textAlign: 'center',
  },
})
