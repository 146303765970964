import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import { CustomText } from '../../../components/text/StyledText'
import { ButtonPrimary } from '../../../components/buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import { URLOpener } from '../../../utils/URLOpener'
import { trackPlausibleEvent } from '../../../services/api/plausible'

export default function RodeKors() {
	const handleButtonClick = (url, btnIndex) => {
		trackPlausibleEvent('HerlevOfferClick', { origin: `RodeKors-btn${btnIndex}` }, 'herlev/HerlevOffers')
		URLOpener(url)
	}

	return (
		<View style={styles.partnerInfoContainer}>
			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/rode-kors.png' }}
				resizeMode="contain"
				style={[styles.image, styles.partnerInfoHeader]}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
				accessibilityLabel={txt('universe.herlev.rodeKors')}
			/>
			<CustomText font="largeBold" style={styles.partnerInfoTitle}>
				{txt('universe.herlev.partnerInfo.rodeKors.title')}
			</CustomText>
			<CustomText>{txt('universe.herlev.partnerInfo.rodeKors.body')}</CustomText>
			<ButtonPrimary
				title={txt('universe.herlev.partnerInfo.rodeKors.btnText1')}
				onPress={() => handleButtonClick('https://boblberg.dk/snaksammen', 1)}
				style={{ marginTop: 12 }}
			/>

			<CustomText font="largeBold">{txt('universe.herlev.partnerInfo.rodeKors.title2')}</CustomText>
			<CustomText>{txt('universe.herlev.partnerInfo.rodeKors.body2')}</CustomText>
			<ButtonPrimary
				title={txt('universe.herlev.partnerInfo.rodeKors.btnText2')}
				onPress={() => handleButtonClick('https://www.rodekors.dk/parat', 2)}
				style={{ marginTop: 12 }}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	image: {
		alignSelf: 'center',
		height: 60,
		marginVertical: 6,
		maxWidth: 300,
		minWidth: 200,
	},
	partnerInfoContainer: {
		alignSelf: 'center',
		flex: 1,
		maxWidth: 600,
		width: '100%',
	},
	partnerInfoHeader: {
		marginBottom: 24,
	},
	partnerInfoTitle: { marginBottom: 12, marginTop: 12 },
})
