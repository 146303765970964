// List of postal codes and their associated municipalityId
// { postalCode: municipalityId }

const PostalCodes = {
  1000: 50,
  1050: 50,
  1051: 50,
  1052: 50,
  1053: 50,
  1054: 50,
  1055: 50,
  1056: 50,
  1057: 50,
  1058: 50,
  1059: 50,
  1060: 50,
  1061: 50,
  1062: 50,
  1063: 50,
  1064: 50,
  1065: 50,
  1066: 50,
  1067: 50,
  1068: 50,
  1069: 50,
  1070: 50,
  1071: 50,
  1072: 50,
  1073: 50,
  1074: 50,
  1092: 50,
  1093: 50,
  1095: 50,
  1098: 50,
  1100: 50,
  1101: 50,
  1102: 50,
  1103: 50,
  1104: 50,
  1105: 50,
  1106: 50,
  1107: 50,
  1110: 50,
  1111: 50,
  1112: 50,
  1113: 50,
  1114: 50,
  1115: 50,
  1116: 50,
  1117: 50,
  1118: 50,
  1119: 50,
  1120: 50,
  1121: 50,
  1122: 50,
  1123: 50,
  1124: 50,
  1125: 50,
  1126: 50,
  1127: 50,
  1128: 50,
  1129: 50,
  1130: 50,
  1131: 50,
  1140: 50,
  1147: 50,
  1148: 50,
  1150: 50,
  1151: 50,
  1152: 50,
  1153: 50,
  1154: 50,
  1155: 50,
  1156: 50,
  1157: 50,
  1158: 50,
  1159: 50,
  1160: 50,
  1161: 50,
  1162: 50,
  1164: 50,
  1165: 50,
  1166: 50,
  1167: 50,
  1168: 50,
  1169: 50,
  1170: 50,
  1171: 50,
  1172: 50,
  1173: 50,
  1174: 50,
  1175: 50,
  1200: 50,
  1201: 50,
  1202: 50,
  1203: 50,
  1204: 50,
  1205: 50,
  1206: 50,
  1207: 50,
  1208: 50,
  1209: 50,
  1210: 50,
  1211: 50,
  1213: 50,
  1214: 50,
  1215: 50,
  1216: 50,
  1217: 50,
  1218: 50,
  1219: 50,
  1220: 50,
  1221: 50,
  1240: 50,
  1250: 50,
  1251: 50,
  1252: 50,
  1253: 50,
  1254: 50,
  1255: 50,
  1256: 50,
  1257: 50,
  1259: 50,
  1260: 50,
  1261: 50,
  1263: 50,
  1264: 50,
  1265: 50,
  1266: 50,
  1267: 50,
  1268: 50,
  1270: 50,
  1271: 50,
  1291: 50,
  1300: 50,
  1301: 50,
  1302: 50,
  1303: 50,
  1304: 50,
  1306: 50,
  1307: 50,
  1308: 50,
  1309: 50,
  1310: 50,
  1311: 50,
  1312: 50,
  1313: 50,
  1314: 50,
  1315: 50,
  1316: 50,
  1317: 50,
  1318: 50,
  1319: 50,
  1320: 50,
  1321: 50,
  1322: 50,
  1323: 50,
  1324: 50,
  1325: 50,
  1326: 50,
  1327: 50,
  1328: 50,
  1329: 50,
  1350: 50,
  1352: 50,
  1353: 50,
  1354: 50,
  1355: 50,
  1356: 50,
  1357: 50,
  1358: 50,
  1359: 50,
  1360: 50,
  1361: 50,
  1362: 50,
  1363: 50,
  1364: 50,
  1365: 50,
  1366: 50,
  1367: 50,
  1368: 50,
  1369: 50,
  1370: 50,
  1371: 50,
  1400: 50,
  1401: 50,
  1402: 50,
  1403: 50,
  1406: 50,
  1407: 50,
  1408: 50,
  1409: 50,
  1410: 50,
  1411: 50,
  1412: 50,
  1413: 50,
  1414: 50,
  1415: 50,
  1416: 50,
  1417: 50,
  1418: 50,
  1419: 50,
  1420: 50,
  1421: 50,
  1422: 50,
  1423: 50,
  1424: 50,
  1425: 50,
  1426: 50,
  1427: 50,
  1428: 50,
  1429: 50,
  1430: 50,
  1431: 50,
  1432: 50,
  1433: 50,
  1434: 50,
  1435: 50,
  1436: 50,
  1437: 50,
  1438: 50,
  1439: 50,
  1440: 50,
  1441: 50,
  1448: 50,
  1450: 50,
  1451: 50,
  1452: 50,
  1453: 50,
  1454: 50,
  1455: 50,
  1456: 50,
  1457: 50,
  1458: 50,
  1459: 50,
  1460: 50,
  1462: 50,
  1463: 50,
  1464: 50,
  1466: 50,
  1467: 50,
  1468: 50,
  1470: 50,
  1471: 50,
  1472: 50,
  1500: 50,
  1532: 88,
  1533: 88,
  1550: 50,
  1551: 50,
  1552: 50,
  1553: 50,
  1554: 50,
  1555: 50,
  1556: 50,
  1557: 50,
  1558: 50,
  1559: 50,
  1560: 50,
  1561: 50,
  1562: 50,
  1563: 50,
  1564: 50,
  1566: 50,
  1567: 50,
  1568: 50,
  1569: 50,
  1570: 50,
  1571: 50,
  1572: 50,
  1573: 50,
  1574: 50,
  1575: 50,
  1576: 50,
  1577: 50,
  1592: 50,
  1599: 50,
  1600: 50,
  1601: 50,
  1602: 50,
  1603: 50,
  1604: 50,
  1606: 50,
  1607: 50,
  1608: 50,
  1609: 50,
  1610: 50,
  1611: 50,
  1612: 50,
  1613: 50,
  1614: 50,
  1615: 50,
  1616: 50,
  1617: 50,
  1618: 50,
  1619: 50,
  1620: 50,
  1621: 50,
  1622: 50,
  1623: 50,
  1624: 50,
  1630: 50,
  1631: 50,
  1632: 50,
  1633: 50,
  1634: 50,
  1635: 50,
  1650: 50,
  1651: 50,
  1652: 50,
  1653: 50,
  1654: 50,
  1655: 50,
  1656: 50,
  1657: 50,
  1658: 50,
  1659: 50,
  1660: 50,
  1661: 50,
  1662: 50,
  1663: 50,
  1664: 50,
  1665: 50,
  1666: 50,
  1667: 50,
  1668: 50,
  1669: 50,
  1670: 50,
  1671: 50,
  1672: 50,
  1673: 50,
  1674: 50,
  1675: 50,
  1676: 50,
  1677: 50,
  1699: 50,
  1700: 50,
  1701: 50,
  1702: 50,
  1703: 50,
  1704: 50,
  1705: 50,
  1706: 50,
  1707: 50,
  1708: 50,
  1709: 50,
  1710: 50,
  1711: 50,
  1712: 50,
  1714: 50,
  1715: 50,
  1716: 50,
  1717: 50,
  1718: 50,
  1719: 50,
  1720: 50,
  1721: 50,
  1722: 50,
  1723: 50,
  1724: 50,
  1725: 50,
  1726: 50,
  1727: 50,
  1728: 50,
  1729: 50,
  1730: 50,
  1731: 50,
  1732: 50,
  1733: 50,
  1734: 50,
  1735: 50,
  1736: 50,
  1737: 50,
  1738: 50,
  1739: 50,
  1749: 50,
  1750: 50,
  1751: 50,
  1752: 50,
  1753: 50,
  1754: 50,
  1755: 50,
  1756: 50,
  1757: 50,
  1758: 50,
  1759: 50,
  1760: 50,
  1761: 50,
  1762: 50,
  1763: 50,
  1764: 50,
  1765: 50,
  1766: 50,
  1770: 50,
  1771: 50,
  1772: 50,
  1773: 50,
  1774: 50,
  1775: 50,
  1777: 50,
  1780: 50,
  1785: 50,
  1786: 50,
  1787: 50,
  1790: 50,
  1799: 50,
  1800: 21,
  1801: 21,
  1802: 21,
  1803: 21,
  1804: 21,
  1805: 21,
  1806: 21,
  1807: 21,
  1808: 21,
  1809: 21,
  1810: 21,
  1811: 21,
  1812: 21,
  1813: 21,
  1814: 21,
  1815: 21,
  1816: 21,
  1817: 21,
  1818: 21,
  1819: 21,
  1820: 21,
  1822: 21,
  1823: 21,
  1824: 21,
  1825: 21,
  1826: 21,
  1827: 21,
  1828: 21,
  1829: 21,
  1850: 21,
  1851: 21,
  1852: 21,
  1853: 21,
  1854: 21,
  1855: 21,
  1856: 21,
  1857: 21,
  1860: 21,
  1861: 21,
  1862: 21,
  1863: 21,
  1864: 21,
  1865: 21,
  1866: 21,
  1867: 21,
  1868: 21,
  1870: 21,
  1871: 21,
  1872: 21,
  1873: 21,
  1874: 21,
  1875: 21,
  1876: 21,
  1877: 21,
  1878: 21,
  1879: 21,
  1900: 21,
  1901: 21,
  1902: 21,
  1903: 21,
  1904: 21,
  1905: 21,
  1906: 21,
  1908: 21,
  1909: 21,
  1910: 21,
  1911: 21,
  1912: 21,
  1913: 21,
  1914: 21,
  1915: 21,
  1916: 21,
  1917: 21,
  1920: 21,
  1921: 21,
  1922: 21,
  1923: 21,
  1924: 21,
  1925: 21,
  1926: 21,
  1927: 21,
  1928: 21,
  1950: 21,
  1951: 21,
  1952: 21,
  1953: 21,
  1954: 21,
  1955: 21,
  1956: 21,
  1957: 21,
  1958: 21,
  1959: 21,
  1960: 21,
  1961: 21,
  1962: 21,
  1963: 21,
  1964: 21,
  1965: 21,
  1966: 21,
  1967: 21,
  1970: 21,
  1971: 21,
  1972: 21,
  1973: 21,
  1974: 21,
  2000: 21,
  2100: 50,
  2150: 50,
  2200: 50,
  2300: 50,
  2400: 50,
  2450: 50,
  2500: 50,
  2600: 27,
  2605: 10,
  2610: 73,
  2620: 5,
  2625: 91,
  2630: 39,
  2635: 46,
  2640: 39,
  2650: 44,
  2660: 10,
  2665: 91,
  2670: 28,
  2680: 81,
  2690: 28,
  2700: 50,
  2720: 50,
  2730: 35,
  2740: 8,
  2750: 8,
  2760: 8,
  2765: 13,
  2770: 88,
  2791: 12,
  2800: 58,
  2820: 25,
  2830: 58,
  2840: 75,
  2850: 75,
  2860: 26,
  2870: 25,
  2880: 26,
  2900: 25,
  2920: 25,
  2930: 25,
  2942: 75,
  2950: 75,
  2960: 43,
  2970: 43,
  2980: 19,
  2990: 19,
  3000: 34,
  3050: 19,
  3060: 19,
  3070: 34,
  3080: 34,
  3100: 34,
  3120: 29,
  3140: 34,
  3150: 34,
  3200: 29,
  3210: 29,
  3220: 29,
  3230: 29,
  3250: 29,
  3300: 32,
  3310: 32,
  3320: 37,
  3330: 37,
  3360: 32,
  3370: 32,
  3390: 32,
  3400: 37,
  3450: 6,
  3460: 75,
  3480: 19,
  3490: 34,
  3500: 24,
  3520: 24,
  3540: 6,
  3550: 23,
  3600: 23,
  3630: 23,
  3650: 13,
  3660: 13,
  3670: 13,
  3700: 98,
  3720: 98,
  3730: 98,
  3740: 98,
  3751: 98,
  3760: 98,
  3770: 98,
  3782: 98,
  3790: 98,
  4000: 74,
  4030: 28,
  4040: 74,
  4050: 23,
  4060: 55,
  4070: 55,
  4100: 72,
  4130: 74,
  4140: 51,
  4160: 18,
  4171: 62,
  4173: 83,
  4174: 72,
  4180: 83,
  4190: 80,
  4200: 80,
  4220: 80,
  4230: 80,
  4241: 80,
  4242: 80,
  4243: 80,
  4250: 62,
  4261: 80,
  4262: 62,
  4270: 48,
  4281: 48,
  4291: 83,
  4293: 83,
  4295: 83,
  4296: 83,
  4300: 40,
  4320: 55,
  4330: 55,
  4340: 40,
  4350: 40,
  4360: 40,
  4370: 40,
  4390: 40,
  4400: 48,
  4420: 40,
  4440: 40,
  4450: 40,
  4460: 48,
  4470: 48,
  4480: 48,
  4490: 48,
  4500: 68,
  4520: 40,
  4532: 40,
  4534: 68,
  4540: 68,
  4550: 68,
  4560: 68,
  4571: 68,
  4572: 68,
  4573: 68,
  4581: 68,
  4583: 68,
  4591: 48,
  4592: 48,
  4593: 48,
  4600: 51,
  4621: 74,
  4622: 74,
  4623: 51,
  4632: 51,
  4640: 18,
  4652: 84,
  4653: 18,
  4654: 18,
  4660: 84,
  4671: 84,
  4672: 84,
  4673: 84,
  4681: 51,
  4682: 51,
  4683: 18,
  4684: 62,
  4690: 18,
  4700: 62,
  4720: 97,
  4733: 62,
  4735: 97,
  4736: 62,
  4750: 97,
  4760: 97,
  4771: 97,
  4772: 97,
  4773: 97,
  4780: 97,
  4791: 97,
  4792: 97,
  4793: 97,
  4800: 30,
  4840: 30,
  4850: 30,
  4862: 30,
  4863: 30,
  4871: 30,
  4872: 30,
  4873: 30,
  4874: 30,
  4880: 30,
  4891: 30,
  4892: 30,
  4894: 30,
  4895: 57,
  4900: 57,
  4912: 57,
  4913: 57,
  4920: 57,
  4930: 57,
  4941: 57,
  4943: 57,
  4944: 57,
  4951: 57,
  4952: 57,
  4953: 57,
  4960: 57,
  4970: 57,
  4983: 57,
  4990: 30,
  5000: 67,
  5200: 67,
  5210: 67,
  5220: 67,
  5230: 67,
  5240: 67,
  5250: 67,
  5260: 67,
  5270: 67,
  5290: 49,
  5300: 49,
  5320: 67,
  5330: 49,
  5350: 49,
  5370: 49,
  5380: 49,
  5390: 49,
  5400: 64,
  5450: 64,
  5462: 64,
  5463: 60,
  5464: 60,
  5466: 60,
  5471: 64,
  5474: 64,
  5485: 64,
  5491: 67,
  5492: 7,
  5500: 60,
  5540: 65,
  5550: 49,
  5560: 7,
  5580: 60,
  5591: 60,
  5592: 60,
  5600: 15,
  5610: 7,
  5620: 7,
  5631: 7,
  5642: 15,
  5672: 15,
  5683: 7,
  5690: 7,
  5700: 86,
  5750: 15,
  5762: 86,
  5771: 86,
  5772: 15,
  5792: 15,
  5800: 65,
  5853: 15,
  5854: 15,
  5856: 15,
  5863: 15,
  5871: 65,
  5874: 86,
  5881: 86,
  5882: 86,
  5883: 86,
  5884: 86,
  5892: 86,
  5900: 54,
  5932: 54,
  5935: 54,
  5953: 54,
  5960: 4,
  5970: 4,
  5985: 4,
  6000: 52,
  6040: 94,
  6051: 52,
  6052: 52,
  6064: 52,
  6070: 31,
  6091: 52,
  6092: 52,
  6093: 52,
  6094: 52,
  6100: 31,
  6200: 1,
  6230: 1,
  6240: 90,
  6261: 90,
  6270: 90,
  6280: 90,
  6300: 82,
  6310: 82,
  6320: 82,
  6330: 1,
  6340: 1,
  6360: 1,
  6372: 1,
  6392: 1,
  6400: 82,
  6430: 82,
  6440: 82,
  6470: 82,
  6500: 31,
  6510: 31,
  6520: 90,
  6534: 90,
  6535: 90,
  6541: 31,
  6560: 31,
  6580: 52,
  6600: 93,
  6621: 93,
  6622: 93,
  6623: 9,
  6630: 93,
  6640: 52,
  6650: 93,
  6660: 93,
  6670: 93,
  6682: 93,
  6683: 93,
  6690: 14,
  6700: 14,
  6705: 14,
  6710: 14,
  6715: 14,
  6720: 16,
  6731: 14,
  6740: 14,
  6752: 93,
  6753: 92,
  6760: 14,
  6771: 14,
  6780: 90,
  6792: 90,
  6800: 92,
  6818: 92,
  6823: 92,
  6830: 92,
  6840: 92,
  6851: 92,
  6852: 92,
  6853: 92,
  6854: 92,
  6855: 92,
  6857: 92,
  6862: 92,
  6870: 92,
  6880: 71,
  6893: 71,
  6900: 71,
  6920: 71,
  6933: 36,
  6940: 71,
  6950: 71,
  6960: 71,
  6971: 71,
  6973: 71,
  6980: 71,
  6990: 41,
  7000: 20,
  7007: 20,
  7080: 94,
  7100: 94,
  7120: 94,
  7130: 33,
  7140: 33,
  7150: 33,
  7160: 33,
  7171: 33,
  7173: 94,
  7182: 94,
  7183: 94,
  7184: 94,
  7190: 9,
  7200: 9,
  7250: 9,
  7260: 9,
  7270: 36,
  7280: 36,
  7300: 94,
  7321: 94,
  7323: 94,
  7330: 45,
  7361: 45,
  7362: 45,
  7400: 36,
  7430: 45,
  7441: 45,
  7442: 45,
  7451: 36,
  7470: 96,
  7480: 36,
  7490: 36,
  7500: 41,
  7540: 36,
  7550: 36,
  7560: 85,
  7570: 41,
  7600: 85,
  7620: 56,
  7650: 56,
  7660: 56,
  7673: 56,
  7680: 56,
  7700: 89,
  7730: 89,
  7741: 89,
  7742: 89,
  7752: 89,
  7755: 89,
  7760: 89,
  7770: 89,
  7790: 85,
  7800: 79,
  7830: 41,
  7840: 79,
  7850: 96,
  7860: 79,
  7870: 79,
  7884: 79,
  7900: 61,
  7950: 61,
  7960: 61,
  7970: 61,
  7980: 61,
  7990: 61,
  8000: 3,
  8200: 3,
  8210: 3,
  8220: 3,
  8230: 3,
  8240: 3,
  8245: 3,
  8250: 3,
  8260: 3,
  8270: 3,
  8300: 66,
  8305: 76,
  8310: 3,
  8320: 3,
  8330: 3,
  8340: 66,
  8350: 66,
  8355: 3,
  8361: 3,
  8362: 78,
  8370: 17,
  8380: 3,
  8381: 3,
  8382: 17,
  8400: 87,
  8410: 87,
  8420: 87,
  8444: 87,
  8450: 17,
  8462: 3,
  8464: 78,
  8471: 3,
  8472: 17,
  8500: 63,
  8520: 3,
  8530: 3,
  8541: 3,
  8543: 87,
  8544: 87,
  8550: 87,
  8560: 87,
  8570: 63,
  8581: 87,
  8585: 63,
  8586: 63,
  8592: 63,
  8600: 77,
  8620: 77,
  8632: 77,
  8641: 77,
  8643: 77,
  8653: 77,
  8654: 77,
  8660: 78,
  8670: 78,
  8680: 78,
  8700: 42,
  8721: 33,
  8722: 33,
  8723: 33,
  8732: 42,
  8740: 42,
  8751: 42,
  8752: 42,
  8762: 33,
  8763: 33,
  8765: 45,
  8766: 45,
  8781: 33,
  8783: 33,
  8800: 96,
  8830: 96,
  8831: 96,
  8832: 96,
  8840: 96,
  8850: 96,
  8860: 17,
  8870: 69,
  8881: 17,
  8882: 77,
  8883: 77,
  8900: 69,
  8920: 69,
  8930: 69,
  8940: 69,
  8950: 63,
  8960: 69,
  8961: 63,
  8963: 63,
  8970: 69,
  8981: 69,
  8983: 69,
  8990: 69,
  9000: 2,
  9200: 2,
  9210: 2,
  9220: 2,
  9230: 2,
  9240: 2,
  9260: 2,
  9270: 2,
  9280: 2,
  9293: 2,
  9300: 22,
  9310: 2,
  9320: 11,
  9330: 11,
  9340: 11,
  9352: 22,
  9362: 2,
  9370: 2,
  9380: 2,
  9381: 2,
  9382: 11,
  9400: 2,
  9430: 2,
  9440: 47,
  9460: 47,
  9480: 38,
  9490: 47,
  9492: 47,
  9493: 47,
  9500: 59,
  9510: 59,
  9520: 70,
  9530: 70,
  9541: 70,
  9550: 59,
  9560: 59,
  9574: 70,
  9575: 70,
  9600: 95,
  9610: 70,
  9620: 95,
  9631: 95,
  9632: 96,
  9640: 95,
  9670: 95,
  9681: 95,
  9690: 47,
  9700: 11,
  9740: 11,
  9750: 22,
  9760: 38,
  9800: 38,
  9830: 38,
  9850: 38,
  9870: 38,
  9881: 38,
  9900: 22,
  9940: 53,
  9970: 22,
  9981: 22,
  9982: 22,
  9990: 22,
}

export default PostalCodes
