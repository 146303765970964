import React from 'react'
import {
  View,
  StyleSheet,
  Image,
  Dimensions,
} from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import { CustomText } from '../../../text/StyledText'
import { ProjectStyles } from './ProjectStyles'
import PageTitle from '../../../tags/PageTitle'

const { width } = Dimensions.get('window')

export default function OEUG() {
  return (
    <View style={ProjectStyles.widthHeight}>
      <PageTitle title={TxtProjects.OEUG.title[TxtProjects.lang]} />
      <View style={ProjectStyles.container}>
        <CustomText
          style={ProjectStyles.blogTitle}
          accessibilityRole="header"
          aria-level={1}
        >
          {TxtProjects.OEUG.title[TxtProjects.lang]}
        </CustomText>
        <CustomText
          style={ProjectStyles.blogText}
        >
          {TxtProjects.OEUG.text1[TxtProjects.lang]}
        </CustomText>
        <View style={styles.imageContainer}>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.image}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/OEUG_Bobler.png',
            }}
            resizeMode="cover"
          />
        </View>
        <CustomText
          style={ProjectStyles.blogText}
        >
          {TxtProjects.OEUG.text2[TxtProjects.lang]}
        </CustomText>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    height: undefined,
    width: undefined,
  },
  imageContainer: {
    alignSelf: 'center',
    height: width * 0.9,
    marginBottom: 24,
    marginTop: 12,
    width: width * 0.9,
    maxWidth: 502,
    maxHeight: 500,
  },
})
