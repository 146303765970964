import React, { useEffect, useState } from 'react'
import { CustomText } from '../../text/StyledText'
import { FlatList, StyleSheet } from 'react-native'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import CustomDialog from '../../dialogs/CustomDialog'
import NormalIcon from '../../icons/NormalIcon'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../pressables/CustomPressable"

export default function ChooseFacultyItem({ visible, close, faculties, onPress, required, defaultFaculties }) {
  const [selectedFaculties, setSelectedFaculties] = useState(new Set())

  useEffect(() => {
    if (defaultFaculties === undefined) return
    setSelectedFaculties(defaultFaculties)
  }, [defaultFaculties])

  function selectFaculty(faculty) {
    const updatedList = new Set(selectedFaculties)
    if (updatedList.has(faculty.id)) updatedList.delete(faculty.id)
    else updatedList.add(faculty.id)
    setSelectedFaculties(updatedList)
  }

  function renderFaculty({ item }) {
    return (
      <CustomPressable
        onPress={() => selectFaculty(item)}
        key={item.id}
        style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 6 }}
      >
        <CustomText numberOfLines={1}>{item.name}</CustomText>
        <NormalIcon
          name={selectedFaculties.has(item.id) ? "check-circle" : "circle"}
          size={20}
          style={styles.selectIcon}
          stroke={selectedFaculties.has(item.id) ? 'fas' : 'fal'}
        />
      </CustomPressable>
    )
  }

  return (
    <CustomDialog visible={visible} onDismiss={close} style={styles.dialog}>
      <CustomText font={'largeBold'} style={styles.header}>
        {txt('unions.create.faculty.dialogTitle')}
      </CustomText>
      <CustomText style={styles.header}>{txt('unions.create.faculty.select')}</CustomText>
      <FlatList data={faculties} style={styles.list} renderItem={renderFaculty} />
      <ButtonPrimary
        title={txt('unions.create.faculty.dialogBtn')}
        onPress={() => onPress(selectedFaculties)}
        disabled={required ? selectedFaculties.size < 1 : false}
        style={styles.submitButton}
        icon="check"
        stroke={'far'}
      />
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    padding: 12,
    paddingTop: 18,
    minWidth: 300,
  },
  header: {
    marginBottom: 12,
    textAlign: 'center',
  },
  list: {
    flexGrow: 0,
  },
  submitButton: {
    marginTop: 12,
    width: '100%',
  },
})
