import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import { URLOpener } from '../../../utils/URLOpener'
import Colors from '../../../constants/Colors'
import { trackPlausibleEvent } from '../../../services/api/plausible'

export default function HerlevBibliotek() {
	const handleClick = (url, linkIndex) => {
		trackPlausibleEvent('HerlevOfferClick', { origin: `HerlevKommune-link${linkIndex}` }, 'herlev/HerlevOffers')
		URLOpener(url)
	}

	return (
		<View style={styles.partnerInfoContainer}>
			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/herlev-kommune-logo.png' }}
				resizeMode="contain"
				style={[styles.image, styles.partnerInfoHeader]}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
				accessibilityLabel={txt('universe.herlev.partnerInfo.herlevbibliotek.name')}
			/>
			<CustomText font="largeBold" style={styles.partnerInfoTitle}>
				{txt('universe.herlev.partnerInfo.herlevkommune.title')}
			</CustomText>
			<CustomText>{txt('universe.herlev.partnerInfo.herlevkommune.intro')}</CustomText>

			<CustomText font="bold" style={styles.header}>
				{txt('universe.herlev.partnerInfo.herlevkommune.header1')}
			</CustomText>
			<CustomText style={styles.link} onPress={() => handleClick('https://goherlev.dk/', 1)}>
				{txt('universe.herlev.partnerInfo.herlevkommune.linkText1')}
			</CustomText>

			<CustomText font="bold" style={styles.header}>
				{txt('universe.herlev.partnerInfo.herlevkommune.header2')}
			</CustomText>
			<CustomText
				style={styles.link}
				onPress={() => handleClick('https://herlev.dk/borger/kultur-idraet-og-fritid/kultur/kulturens-steder-i-herlev', 2)}
			>
				{txt('universe.herlev.partnerInfo.herlevkommune.linkText2')}
			</CustomText>

			<CustomText font="bold" style={styles.header}>
				{txt('universe.herlev.partnerInfo.herlevkommune.header3')}
			</CustomText>
			<CustomText style={styles.link} onPress={() => handleClick('https://www.ungher.dk/', 3)}>
				{txt('universe.herlev.partnerInfo.herlevkommune.linkText3')}
			</CustomText>

			<CustomText font="bold" style={styles.header}>
				{txt('universe.herlev.partnerInfo.herlevkommune.header4')}
			</CustomText>
			<CustomText style={styles.link} onPress={() => handleClick('https://www.gammelgaard.dk/', 4)}>
				{txt('universe.herlev.partnerInfo.herlevkommune.linkText4')}
			</CustomText>

			<CustomText font="bold" style={styles.header}>
				{txt('universe.herlev.partnerInfo.herlevkommune.header5')}
			</CustomText>
			<CustomText
				style={styles.link}
				onPress={() => handleClick('https://herlev.dk/borger/kultur-idraet-og-fritid/kultur-og-oplevelser/herlev-festuge', 5)}
			>
				{txt('universe.herlev.partnerInfo.herlevkommune.linkText5')}
			</CustomText>

			<CustomText font="bold" style={styles.header}>
				{txt('universe.herlev.partnerInfo.herlevkommune.header6')}
			</CustomText>
			<CustomText style={styles.link} onPress={() => handleClick('http://sommeriherlev.dk', 6)}>
				{txt('universe.herlev.partnerInfo.herlevkommune.linkText6')}
			</CustomText>
		</View>
	)
}

const styles = StyleSheet.create({
	image: {
		alignSelf: 'center',
		height: 80,
		marginVertical: 6,
		maxWidth: 300,
		minWidth: 200,
	},
	partnerInfoContainer: {
		alignSelf: 'center',
		flex: 1,
		maxWidth: 600,
		width: '100%',
	},
	partnerInfoHeader: {
		marginBottom: 24,
	},
	partnerInfoTitle: { marginBottom: 12, marginTop: 12 },
	header: { marginTop: 12 },
	link: { color: Colors.blueAzure },
})
