import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { txt } from '../../../locales/i18n'
import { Platform, StyleSheet, View } from 'react-native'
import { updateUserPreferences } from '../../../services/api/User'
import * as Updates from 'expo-updates'
import { CustomText } from '../../text/StyledText'
import { LanguageChangedDialog } from '../Profile/ProfileDialog'
import { NormalSnackbar } from '../../snackbars/Snackbar'
import Colors from '../../../constants/Colors'
import Fontsizes from '../../../constants/Fontsizes'
import Layout from '../../../constants/Layout'
import { TypographyStyles } from '../../../styles'
import { LanguagePicker } from '../../formElements/LanguagePicker'
import UniversePicker from './UniversePicker'
import NormalIcon from '../../icons/NormalIcon'
import StartScreenPicker from './StartScreenPicker'
import { LogoutDialog } from '../About/MenuDialog/MenuDialogs'
import Version from '../../tags/Version'
import Card from '../../Card'
import ScreeningConsentDialog from '../../dialogs/ScreeningConsentDialog'
import { SproglandJuniorConsentDialog } from '../../dialogs/SproglandJuniorConsentDialog'
import { getJuniorConsent } from '../../../services/api/LearnDanish'
import CustomPressable from "../../pressables/CustomPressable"

export default function Settings(props) {
  const [modalVisible, setModalVisible] = useState(false)
  const preferences = useSelector((state) => state.preferences)
  const universes = useSelector((state) => state.user.universes)

  const [hasLanguageChanged, setHasLanguagedChanged] = useState(false)
  const [settingsWasSaved, setSettingsWasSaved] = useState(false)
  const [settingsFailedToSave, setSettingsFailedToSave] = useState(false)
  const [universeList, setUniverseList] = useState([])
  const [consentDialog, setConsentDialog] = useState(false)
  const [showSproglandJuniorConsentDialog, setShowSproglandJuniorConsentDialog] = useState(false)

  const country = useSelector((state) => state.user?.country)
  const fullAccess = country === 'Denmark'

	const startScreens = [{ label: txt('home.title'), value: 'Bobls' }]
	if (country === 'Denmark') {
		startScreens.push(
			{ label: txt('groups.title'), value: 'Groups' },
			{ label: txt('learnDanish.title'), value: 'Sprogland' }
		)
	}

  useEffect(() => {
    setUniverseList([
      { id: 0, name: 'boblberg', title: 'Boblberg' },
      { id: 11, name: 'christmasFriends', title: 'Julevenner' },
      ...universes,
    ])
    if (country === 'Sweden') {
      setUniverseList([
        { id: 0, name: 'boblberg', title: 'Boblberg' },
        ...universes,
      ])
    }
  }, [])

  const changeStartScreen = async (screen) => {
    updateUserPreferences({
      start_screen: screen === startScreens[0].value ? null : screen,
    })
  }

  const changeUniverse = (id) => {
    const prefs = { default_universe: id || null }
    if (id !== 0) prefs.start_screen = null
    updateUserPreferences(prefs)
  }

  const confirmChangeLanguage = () => {
    setHasLanguagedChanged(false)
    if (Platform.OS !== 'web') Updates.reloadAsync()
    else window.location.reload()
  }

	function handleInitValue() {
		if (Platform.OS === 'web') return preferences.start_screen || startScreens[0].value
		const screen = startScreens.find((screen) => screen.value === preferences.start_screen)
		return screen ? screen.label : startScreens[0].label
	}

  return (
    <View style={{ marginHorizontal: 12 }}>
      <View>
        <Card style={styles.container}>
          <CustomText font="largeBold" style={{ marginTop: 6 }}>
            {txt('settings.preferences')}
          </CustomText>

          <View style={[styles.item, styles.borderBottom, { paddingBottom: 0 }]}>
            <CustomText>{txt('settings.language')}</CustomText>
            <CustomText font="xsmall" style={styles.description}>
              {txt('settings.languageHint')}
            </CustomText>

            <LanguagePicker style={{marginLeft: -8}} showSelectedLabel />
          </View>

          {country !== 'Germany' && (
            <>
              <View style={[styles.item, styles.borderBottom]}>
                <CustomText>{txt('settings.startscreen')}</CustomText>

                <CustomText font="xsmall" style={styles.description}>
                  {txt('settings.startscreenHint')}
                </CustomText>

                <UniversePicker
                  style={styles.picker}
                  universeList={universeList}
                  universeId={preferences.default_universe || 0}
                  setUniverseId={changeUniverse}
                  universes={universes}
                />
              </View>

              {preferences.default_universe === null && (
                <View style={styles.item}>
                  <CustomText>{txt('settings.changeStartscreen')}</CustomText>

                  <CustomText font="xsmall" style={styles.description}>
                    {txt('settings.changeStartScreenHint')}
                  </CustomText>

                  <StartScreenPicker
                    style={styles.picker}
                    initValue={handleInitValue()}
                    data={startScreens}
                    onChange={changeStartScreen}
                  />
                </View>
              )}
            </>
          )}
        </Card>

        {/** Notifications */}
        <Card style={styles.container}>
          <CustomText font="largeBold" style={{ marginTop: 6 }}>
            {txt('settings.notifications')}
          </CustomText>

          <View style={[styles.item, styles.borderBottom]}>
            <CustomPressable onPress={() => props.navigateToNotificationSettings('messages')} style={styles.flexRow}>
              <CustomText>{txt('settings.messages')}</CustomText>
              <NormalIcon color={Colors.text} name="chevron-right" size={Fontsizes.s} />
            </CustomPressable>
          </View>

          {fullAccess && (
            <>
              <View style={[styles.item, styles.borderBottom]}>
                <CustomPressable onPress={() => props.navigateToNotificationSettings('groups')} style={styles.flexRow}>
                  <CustomText>{txt('settings.groups')}</CustomText>
                  <NormalIcon color={Colors.text} name="chevron-right" size={Fontsizes.s} />
                </CustomPressable>
              </View>

              <View style={styles.item}>
                <CustomPressable
                  onPress={() => props.navigateToNotificationSettings('videoConversations')}
                  style={styles.flexRow}
                >
                  <CustomText>{txt('settings.talkTogether') + ' & ' + txt('settings.learnDanish')}</CustomText>
                  <NormalIcon color={Colors.text} name="chevron-right" size={Fontsizes.s} />
                </CustomPressable>
              </View>
            </>
          )}
        </Card>

        {/** Consents */}
        <Card style={styles.container}>
          <CustomText font="largeBold" style={{ marginTop: 6 }}>
            {txt('conversations.consents')}
          </CustomText>

          <View style={[styles.item, styles.borderBottom]}>
            <CustomPressable onPress={() => setConsentDialog(true)} style={styles.flexRow}>
              <CustomText>{txt('conversations.updateScreeningConsent')}</CustomText>
              <NormalIcon color={Colors.text} name="chevron-right" size={Fontsizes.s} />
            </CustomPressable>
          </View>

          {fullAccess && (
            <View style={styles.item}>
              <CustomPressable onPress={() => setShowSproglandJuniorConsentDialog(true)} style={styles.flexRow}>
                <CustomText>{txt('learnDanish.junior.UpdateConsent')}</CustomText>
                <NormalIcon color={Colors.text} name="chevron-right" size={Fontsizes.s} />
              </CustomPressable>
            </View>
          )}
        </Card>
      </View>

      <Card accessibilityRole="button" onPress={() => setModalVisible(true)} style={styles.logoutBtn}>
        <NormalIcon name="sign-out" color={Colors.text} size={20} style={{ marginRight: 12 }} />
        <CustomText>{txt('logout.logOut')}</CustomText>
      </Card>

      <Version style={{ marginTop: 16 }} />

      <LogoutDialog closeDialog={() => setModalVisible(false)} visible={modalVisible} />

      <LanguageChangedDialog visible={hasLanguageChanged} onPress={confirmChangeLanguage} />

      <NormalSnackbar visible={settingsWasSaved} onDismiss={() => setSettingsWasSaved(false)}>
        {txt('settings.wasSaved')}
      </NormalSnackbar>

      <NormalSnackbar visible={settingsFailedToSave} onDismiss={() => setSettingsFailedToSave(false)}>
        {txt('settings.failedToSave')}
      </NormalSnackbar>
      <ScreeningConsentDialog visible={consentDialog} handleHideDialog={() => setConsentDialog(false)} />

      <SproglandJuniorConsentDialog
        visible={showSproglandJuniorConsentDialog}
        setVisible={setShowSproglandJuniorConsentDialog}
        onCancel={() => setShowSproglandJuniorConsentDialog(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  borderBottom: {
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: 1,
  },
  container: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: Fontsizes.cornerRounding,
    marginTop: 12,
    maxWidth: Layout.maxFeedWidth,
    paddingHorizontal: 12,
    paddingTop: 12,
    width: '100%',
  },
  description: {
    marginTop: 4,
  },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  item: {
    paddingVertical: 12,
    width: '100%',
  },
  logoutBtn: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: 12,
    padding: 16,
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
  },
  picker: {
    height: 25,
    ...TypographyStyles.text,
    backgroundColor: Colors.white,
    borderColor: Colors.transparent,
    color: Colors.text,
    marginLeft: -5,
  },
})
