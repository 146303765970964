import React from 'react'
import { Picker } from '@react-native-picker/picker'
import { ModalSelectorCustomProfile } from './ModalSelector'
import { Platform, StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'
import { ShadowStyles, TypographyStyles } from '../../styles'
import Layout from "../../constants/Layout"

export function SelectList(props) {
	if (Platform.OS === 'web') {
		return (
			<Picker
				accessibilityLabel={props.accessibilityLabel}
				accessibilityHint={props.accessibilityHint}
				selectedValue={props.selectedValue}
				onValueChange={props.onValueChange}
				style={[styles.picker, props.style]}
				enabled={props.enabled}
			>
				{props.children}
			</Picker>
		)
	} else {
		return (
			<ModalSelectorCustomProfile
				data={props.list}
				keyExtractor={(data) => (props.specialID ? data.id?.toString() : data)}
				labelExtractor={(data) => (props.specialID ? data.name : data)}
				initValue={props.specialID ? props.initValue.name : props.initValue}
				onChange={props.onValueChange}
				disabled={props.enabled === false}
				style={[styles.picker, props.style]}
			/>
		)
	}
}

export const SelectModal = (props) => {
	return (
		<ModalSelectorCustomProfile
			data={props.list}
			keyExtractor={(data) => (props.specialID ? data.id?.toString() : data)}
			labelExtractor={(data) => (props.specialID ? data.name : data)}
			initValue={props.specialID ? props.initValue.name : props.initValue}
			onChange={props.onValueChange}
			disabled={props.enabled === false}
			style={[styles.picker, styles.modalPicker, props.style]}
			initValueTextStyle={styles.text}
			selectStyle={{borderWidth: 1, borderColor: Colors.redCross.border}}
			optionTextStyle={styles.text}
			optionContainerStyle={styles.optionContainer}
			cancelStyle={styles.optionContainer}
			cancelTextStyle={styles.text}
		/>
	)
}

const styles = StyleSheet.create({
	picker: {
		backgroundColor: Colors.white,
		marginLeft: Platform.OS === 'web' ? -5 : -9,
		...TypographyStyles.text,
		borderColor: Colors.transparent,
		color: Colors.text,
	},
	modalPicker: {
		fontFamily: 'gotham-book',
		borderWidth: 1,
		borderColor: Colors.redCross.border,
		...ShadowStyles.unsetShadows,
		backgroundColor: Colors.white,
		borderRadius: 6,
	},
	text: {
		fontFamily: 'gotham-book',
		fontSize: 14,
		color: Colors.redCross.text,
	},
	optionContainer: {
		borderRadius: 6,
		borderWidth: 1,
		borderColor: Colors.redCross.border,
		backgroundColor: Colors.white,
		maxHeight: 500,
		maxWidth: Layout.maxFeedWidth,
		width: '100%',
		alignSelf: 'center',
	},
})
