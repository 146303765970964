import { apiGet } from './ApiRequest'
import * as Localization from '../localization'

export const getAdvertisements = async (query = {}) => {
  const region = Localization.getRegion()
  const country = Localization.countries.find((country) => country.region === region)

  const queryString = Object.entries(query).reduce((queryString, [key, value]) => {
    return queryString + '&' + key + '=' + value
  }, 'region=' + country.name ?? 'Denmark')

  const advertisements = await apiGet('advertisements?' + queryString)
  return advertisements ?? []
}
