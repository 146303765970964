// Libraries
import React, { useState, useEffect } from 'react'

// Api
import { createActivity as createActivityApi } from '../../../services/api/Activities'
import { getUniverseConfig, getUniverseId } from '../../../universe/universeController'

// Components
import Activities from '../../../components/views/Activities/Activities'
import { txt } from '../../../locales/i18n'

export default function ActivitiesUniverseScreen(props) {
  const [universe, setUniverse] = useState(null)
  const [universeObject, setUniverseObject] = useState(null)

  useEffect(() => {
    let universeName = props.route.params?.universe?.toLowerCase()

    const universeId = getUniverseId(universeName)
    setUniverse({ id: universeId, name: universeName })
    setUniverseObject(getUniverseConfig(universeName))

  }, [props.navigation, props.route.params])

  const navigateToActivity = (activityId, date) => {
    props.navigation.push('Activity', {
      id: activityId,
      date,
      universe: universe.name,
    })
  }

  const navigateToMyActivities = () => {
    props.navigation.replace('Activities', {
      chip: txt('activities.chipParams.mine'),
      universe: universe.name,
    })
  }

  const createActivity = async (data) => {
    return await createActivityApi({
      ...data,
      universeId: universe.id,
    })
  }

  return (
    <Activities
      createActivity={createActivity}
      navigateToActivity={navigateToActivity}
      navigateToMyActivities={navigateToMyActivities}
      backgroundColor={universeObject?.backgroundColor}
      isUniverse
    />
  )
}
