import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import Layout from '../../../constants/Layout'
import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import { CornerStyles } from '../../../styles'
import { Divider } from 'react-native-paper'
import NormalIcon from '../../icons/NormalIcon'
import { txt } from '../../../locales/i18n'
import moment from 'moment'
import Logo from '../../../assets/images/icon.png'
import Card from '../../Card'

export default class ActivityListItem extends React.Component {
  constructor(props) {
    super(props)

    //sets locale to danish time
    moment.locale([txt('activities.locale')])
  }

  shouldComponentUpdate() {
    return false
  }

  //decides how to render the date
  formatDate(dateStart) {
    if (this.props.item.recurring) {
      return [txt('activities.repeatingActivity')]
    } else {
      return moment(dateStart).format('llll')
    }
  }

  render() {
    return (
      <Card
        style={styles.cardContainer}
        onPress={this.props.onPressAction}
        accessible
        accessibilityRole="button"
        accessibilityLabel={this.props.item.title}
        accessibilityHint={txt('activities.feed.activityHint')}
      >
        <View style={styles.textContainer}>
          <View>
            <ImageHighlight
              borderColor={Colors.black}
              source={this.props.item.img_filename ? { uri: Paths.activityUrlBase + this.props.item.img_filename } : Logo}
            />
          </View>
          <View style={styles.activityTextContainer}>
            <CustomText font="smallBold" numberOfLines={1}>
              {this.props.item.title}
            </CustomText>

            <View>
              <CustomText
                font="small"
                numberOfLines={Layout.isSmallDevice ? 5 : 6}
              >
                {this.props.item.body}
              </CustomText>
            </View>
          </View>
        </View>
        <Divider />
        <View style={styles.cardBottomContainer}>
          <View style={styles.textContainer}>
            <NormalIcon
              accessibilityLabel={txt('activities.feed.calendarIcon')}
              name="calendar-alt"
            />
            <View style={styles.dateContainer}>
              <CustomText font="smallBold" numberOfLines={1}>
                {this.formatDate(this.props.item.date_start)}
              </CustomText>
            </View>
          </View>
          {Platform.OS === 'web' && !Layout.isSmallDevice ? (
            <View style={styles.textContainer}>
              {this.props.item.postal_code !== null && (
                <CustomText>{this.props.item.postal_code + ' '}</CustomText>
              )}
              {this.props.item.city !== '' &&
              this.props.item.city !== null && (
                <CustomText>{this.props.item.city}</CustomText>
              )}
            </View>
          ) : (
            <View style={styles.textContainer}>
              {this.props.item.city !== '' &&
              this.props.item.city !== null && (
                <CustomText>{this.props.item.city}</CustomText>
              )}
            </View>
          )}
        </View>
      </Card>
    )
  }
}

const styles = StyleSheet.create({
  activityTextContainer: { flexGrow: 1, flex: 1, marginLeft: 10 },
  cardBottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  cardContainer: {
    ...CornerStyles.borderRadiusS,
    backgroundColor: Colors.white,
    marginHorizontal: 12,
    marginVertical: 6,
    minHeight: 170,
  },
  dateContainer: { flexGrow: 1, marginLeft: 10 },
  textContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    margin: 10,
  },
})
