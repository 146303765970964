import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { ImageHighlight } from '../../images/ImageHighlight'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import { SpacingStyles, TypographyStyles } from '../../../styles'
import MainContainer from '../../MainContainer/MainContainer'
import CustomPressable from "../../pressables/CustomPressable"

const projectsData = [
  {
    name: 'analyse-af-mental-mistrivsel',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/analyse-af-mental-mistrivsel.jpg',
    title: 'Analyse af mental mistrivsel blandt brugerne på Boblberg',
  },
  {
    name: 'OEUG',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/Project_OEUG.jpg',
    title: 'Økonomer Uden Grænser',
  },
  {
    name: 'Demantec2',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/Project_Demantec.jpg',
    title: 'Demantec 2.0',
  },
  {
    name: 'SBB',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/Project_SBB.jpg',
    title: 'Støttende Borger-Bobler (SBB)',
  },
  {
    name: 'PHD',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/Project_PHD.jpg',
    title: 'Ph.d.-projekt - Sociale fællesskabers betydning for mental sundhed',
  },
  {
    name: 'CHS',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/Project_CHS.jpg',
    title: 'Forskningsprojekt – Seniorers brug af Boblberg',
  },
  {
    name: 'OPILDL',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/Project_OPI-LDL.jpg',
    title: 'OPI LDL - Det demensvenlige samfund',
  },
  {
    name: 'OPIFAKTO',
    uri: 'https://filer.boblberg.dk/Assets/Img/projects/aktivitetsmodul2.jpg',
    title: 'OPI FAKTO',
  },
]

class ProjectBox extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <CustomPressable onPress={() => this.props.pressF(this.props.name)}>
        <View style={styles.projectContainer}>
          <PageTitle title={TxtTMD.projects.title[TxtTMD.lang]} />
          <View>
            <ImageHighlight source={{ uri: this.props.uri }} />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.textBold}>{this.props.title}</Text>
          </View>
        </View>
      </CustomPressable>
    )
  }
}

export default class ProjectsMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  navigateToPost = (project) => {
    this.props.navigation.push('Projects', {
      id: project,
    })
  }

  render() {
    return (
      <MainContainer>
        <View style={styles.widthHeight}>
          <View>
            {projectsData.map((data, i) => {
              return (
                <ProjectBox
                  uri={projectsData[i].uri}
                  pressF={this.navigateToPost}
                  key={i}
                  name={data.name}
                  title={data.title}
                />
              )
            })}
          </View>
        </View>
      </MainContainer>
    )
  }
}

const styles = StyleSheet.create({
  projectContainer: {
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-between',
    marginBottom: 25,
    marginLeft: 10,
    marginRight: 10,
  },
  textBold: { flexWrap: 'wrap', paddingLeft: 10, ...TypographyStyles.textBold },
  textContainer: {
    alignSelf: 'center',
    flex: 1,
    maxHeight: 100,
    overflow: 'hidden',
  },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
