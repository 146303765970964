import React from 'react'
import { Image, StyleSheet } from 'react-native'
import CustomDialog, { ContentContainer } from './CustomDialog'
import { CustomText } from '../text/StyledText'
import { ButtonPrimary } from '../buttons/StyledButtons'
import store from '../../redux/store'
import { resetGlobalDialogAction } from '../../redux/actions'
import { useSelector } from 'react-redux'
import * as RootNavigation from '../../navigation/RootNavigation'
import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'

export const promptTypes = {
  CHRISTMAS: 'CHRISTMAS',
  NEW_YEAR: 'NEW_YEAR',
  NEW_YEAR_SE: 'NEW_YEAR_SE',
  POST: 'POST',
  UNIVERSE: 'UNIVERSE',
  UNIVERSE_POST: 'UNIVERSE_POST'
}

export default function GlobalDialog() {
  const { type, text, title, visible, data } = useSelector(state => state.globalDialog)

  let icon
  let stroke = 'fas'
  let titleVisible = true
  let buttonTitle = txt('global.ok')
  let buttonColor = Colors.blue
  let handlePress = dismiss
  let boblbergIcon = false

  if (type === promptTypes.CHRISTMAS) {
    // icon = "hat-santa"
    titleVisible = true
    buttonColor = Colors.green
    buttonTitle = txt('global.readMore')
    handlePress = () => {
      RootNavigation.navigate('christmasFriends')
      dismiss()
    }
  }
  if (type === promptTypes.NEW_YEAR) {
    // icon = "glass-cheers"
    titleVisible = true
    buttonColor = Colors.newYearPrimary
    buttonTitle = txt('global.readMore')
    handlePress = () => {
      RootNavigation.navigate('universe', { universe: 'nytaarsvenner' })
      dismiss()
    }
  }
  if (type === promptTypes.NEW_YEAR_SE) {
    // icon = "glass-cheers"
    titleVisible = true
    buttonColor = Colors.newYearPrimary
    buttonTitle = txt('global.readMore')
    handlePress = () => {
      RootNavigation.navigate('universe', { universe: 'nyarsvanner' })
      dismiss()
    }
  }
  if (type === promptTypes.POST) {
    icon = "bobl"
    boblbergIcon = true
    buttonTitle = txt('global.goToBobl')
    handlePress = () => {
      RootNavigation.navigate('HomeStack', {
        screen: 'Post',
        initial: false,
        params: { id: Number(data?.postID) },
      })
      dismiss()
    }
  }
  if (type === promptTypes.UNIVERSE) {
    icon = "chevron-right"
    stroke = 'fal'
    buttonTitle = txt('global.goToUniverse')
    handlePress = () => {
      RootNavigation.navigate('universe', { universe: data?.universe })
      dismiss()
    }
  }
  if (type === promptTypes.UNIVERSE_POST) {
    icon = "chevron-right"
    stroke = 'fal'
    buttonTitle = txt('global.goToUniverse')
    handlePress = () => {
      RootNavigation.navigate('universe', {
        screen: 'UniverseBoblStack',
        params: {
          screen: 'Post',
          params: {
            universe: data?.universe,
            id: data?.postID
          },
        },
      })
      dismiss()
    }
  }


  function dismiss() {
    store.dispatch(resetGlobalDialogAction())
  }

  return (
    <CustomDialog visible={Object.values(promptTypes).includes(type) && visible} onDismiss={dismiss}>
      <ContentContainer>
        {data?.img && <Image source={{ uri: data.img}} style={styles.img} resizeMode="contain" accessibilityIgnoresInvertColors/>}
        {titleVisible && <CustomText style={styles.text} font="largeBold">{title}</CustomText>}
        <CustomText style={styles.text}>
          {text}
        </CustomText>
        <ButtonPrimary style={{...styles.button, backgroundColor: buttonColor}} title={buttonTitle} onPress={handlePress} iconRight={icon} stroke={stroke} boblbergIcon={boblbergIcon}/>
      </ContentContainer>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  button: {
    marginTop: 24,
    width: '100%',
    backgroundColor: Colors.green,
  },
  text: {
    textAlign: 'center',
    marginTop: 12,
  },
  img: {
    height: 200,
    width: '100%',
    alignSelf: 'center',
    borderRadius: 6
  }
})
