import { apiDelete, apiGet, apiPost } from './ApiRequest'

export async function createConnection(connectionId) {
  let res = await apiPost({}, `connections/${connectionId}`)
  if (!res.ok) return
  return res.json()
}

export async function getConnections() {
  const res = await apiGet(`connections`)
  if (!res) return
  return res.data
}

export async function getConnection(connectionId) {
  const res = await apiGet(`connections/${connectionId}`)
  if (!res) return
  return res.data
}

export async function isConnected(connectionId) {
  const res = await apiGet(`connections/connection/${connectionId}`)
  if (!res) return
  return res.data
}

export async function removeConnection(connectionId) {
  const res = await apiDelete({}, `connections/${connectionId}`)
  if (!res.ok) return
  return res.json()
}