import { CustomText } from '../../text/StyledText'
import { ContentContainer, ScrollableDialog } from '../../dialogs/CustomDialog'
import React, { useState } from 'react'
import { CustomSwitch } from '../../switches/StyledSwitches'
import { StyleSheet, View } from 'react-native'
import { createConnection, getConnection, removeConnection } from '../../../services/api/ConnectionApi'
import { txt } from '../../../locales/i18n'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import Colors from '../../../constants/Colors'


export function RenderUser({ user }) {
  const [connection, setConnection] = useState(false)
  getConnection(user.id).then(setConnection)

  async function handleShareProfile(connectionId) {
    if (connection) {
      const connectionRemoved = await removeConnection(connectionId)
      if (connectionRemoved) setConnection(false)
    } else {
      const connectionCreated = await createConnection(connectionId)
      if (connectionCreated) setConnection(true)
    }
  }

  return (
    <View style={styles.container}>
      <CustomText>{user.username}</CustomText>
      <CustomSwitch
        value={connection}
        onValueChange={async () => await handleShareProfile(user.id)}
        label={connection ? txt('connections.connectionOnLabel') : txt('connections.connectionOffLabel')}
      />
    </View>
  )
}

export default function ConnectionDialog({ visible, users, onDismiss }) {
  return (
    <ScrollableDialog visible={visible}>
      <ContentContainer>
        <CustomText
          style={{ fontSize: 24, fontFamily: 'raleway-semibold' }}>{txt('connections.title')}</CustomText>
        <CustomText style={{ marginTop: 12, marginBottom: 24 }}>{txt('connections.allowDialog')}</CustomText>
        {users.map((user, index) => (
          <RenderUser user={user} key={index.toString()} />
        ))}
      </ContentContainer>
      <View style={{ paddingBottom: 24 }}>
        <ButtonPrimary
          onPress={onDismiss}
          style={{ backgroundColor: Colors.blueAzure }}
          title={txt('connections.close')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </ScrollableDialog>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 3,
    marginHorizontal: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
