import React from 'react'
import MainContainer from '../../components/MainContainer/MainContainer'
import { StyleSheet, View, TouchableOpacity, Linking } from 'react-native'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import Layout from '../../constants/Layout'
import Fontsizes from '../../constants/Fontsizes'
import Colors from '../../constants/Colors'

export default function ContactChristmasScreen() {
	return (
		<MainContainer>
			<View style={styles.container}>
				<CustomText
					style={{ fontFamily: 'oswald-bold', fontSize: Fontsizes.xl }}
					aria-level={1}
				>
					{txt('christmasFriends.contact.header')}
				</CustomText>
				<CustomText>{txt('christmasFriends.contact.intro')}</CustomText>

				<CustomText font="bold" style={styles.header} aria-level={2}>
					{txt('christmasFriends.contact.contactInfoHeader')}
				</CustomText>
				<CustomText>
					{txt('christmasFriends.contact.emailLabel')}
					<TouchableOpacity
						onPress={() => Linking.openURL(`mailto:${txt('christmasFriends.contact.email')}`)}
					>
						<CustomText style={styles.link}>
							{txt('christmasFriends.contact.email')}
						</CustomText>
					</TouchableOpacity>
				</CustomText>
				<CustomText>
					{txt('christmasFriends.contact.phoneLabel')}
					<TouchableOpacity onPress={() => Linking.openURL(`tel:${+4535259200}`)}>
						<CustomText style={styles.link}>
							{txt('christmasFriends.contact.phone')}
						</CustomText>
					</TouchableOpacity>
				</CustomText>

				<CustomText font="bold" style={styles.header} aria-level={2}>
					{txt('christmasFriends.contact.openingHoursHeader')}
				</CustomText>
				<CustomText>{txt('christmasFriends.contact.openingHours')}</CustomText>

				<CustomText font="bold" style={styles.header} aria-level={2}>
					{txt('christmasFriends.contact.rkMatchHeader')}
				</CustomText>
				<CustomText>{txt('christmasFriends.contact.rkMatch')}</CustomText>

				<CustomText font="bold" style={styles.header} aria-level={2}>
					{txt('christmasFriends.contact.contactFromRKHeader')}
				</CustomText>
				<CustomText>{txt('christmasFriends.contact.contactFromRK')}</CustomText>
				<CustomText style={styles.numbers}>
					{txt('christmasFriends.contact.rkNumbers')}
				</CustomText>
			</View>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	container: {
		alignSelf: 'center',
		maxWidth: Layout.maxFeedWidth,
		paddingBottom: 20,
		paddingHorizontal: 20,
		width: '100%',
	},
	header: { marginTop: 24 },
	link: { color: Colors.blueAzure },
	numbers: {
		lineHeight: Fontsizes.xl,
		marginTop: 12,
	},
})
