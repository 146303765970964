// [Danish, English]
export default {
  // Current language, TODO: make into function and get from AsyncStorage/DB

  lang: 0,

  demantec2: {
    title: ['Demantec 2.0'],
    text1: [
      'Pårørende til mennesker med demens har en lang række opgaver og belastninger, som de skal håndtere. Kan nye teknologier aflaste dem i hverdagen og forbedre deres livskvalitet? Dette spørgsmål gennemgår det praksisorienterede forskningsprojekt Demantec i den nye finansieringsperiode fra marts 2019 til august 2020.',
    ],
    text2: [
      'Boblberg bliver en del af Interreg-projektet Demantec – Projektets første del har været i gang siden 2016 og det viste sig, at det vigtigste ved anvendelse af tekniske hjælpemidler er at sætte mennesket i centrum. Netop dette skal Boblberg hjælpe projektet med så der tages udgangspunkt i behov og krav, og teknologierne skal tilpasses efter disse – ikke omvendt.',
    ],
    text3: ['Projektets fokus'],
    text4: [
      'Demantec har sat fokus på at forbedre livskvaliteten for pårørende, der bor sammen med et familiemedlem der har fået en demensdiagnose. Her vil det være den pårørendes og dennes situation det drejer sig om, fordi de løfter en vigtig opgave i det daglige. Dette indebærer mange belastninger og en øget risiko for fysiske og psykiske sygdomme som f.eks. stress og depression. Dette skal opnås ved anvendelse af innovative teknologier. Demantec er et praksisorienteret forskningsprojekt, der kombinerer den tekniske ekspertise hos Boblberg og Life-Partners med forskningskompetencer fra det tyske Universitet i Flensburg og den danske Professionshøjskole Absalon.',
    ],
    text5: ['De pårørendes behov undersøges, og teknologier afprøves'],
    text6: [
      'I starten af projektet gennemføres en undersøgelse og præsentation af, hvordan pårørende tager sig af deres demensramte partner og hvordan det er organiseret. I denne forbindelse er der fokus på de pårørendes rolle i Tyskland og Danmark. Desuden udarbejdes der en oversigt over, hvilke teknologiske hjælpemidler til pårørende som allerede står til rådighed. Forskningsaktiviteterne vil i særlig grad fokusere på behovene hos pårørende til mennesker med demens. Hvad har de brug for? Hvordan kan teknologien hjælpe? I den praktiske del af projektet anvendes systemet Boblberg.dk til at skabe pusterum i dagligdagen og støttende grupper til andre pårørende. I denne forbindelse forventes det at der gennemføres workshops med brugerne i Assens Kommune. På grundlag af tilbagemeldinger fra brugerne kan teknologierne i sidste ende videreudvikles, så de passer endnu bedre til behovene. Indflydelsen på livskvaliteten samt fordele og ulemper ved de anvendte systemer undersøges.',
    ],
    readMore: ['Læs mere her: '],
    demantecLink: ['demantec.eu'],
    involved: ['Involverede parter'],
    partner1: ['Boblberg'],
    partner2: ['Life-Partners.com'],
    partner3: ['Professionshøjskolen Absalon'],
    partner4: ['Hochschule Flensburg – University of Applied Sciences'],
    projectPeriod: ['Projektperiode'],
    period: ['01.03.2019 - 31.08.2020'],
    supporter: ['Støttegiver'],
    supporterText: [
      'Demantec er finansieret af Interreg Deutschland-Danmark med midler fra Den Europæiske Fond for Regionaludvikling.',
    ],
    readMoreSupporter: ['Læs mere om Interreg Deutschland-Danmark på:'],
    supporterLink: ['www.interreg5a.eu'],
  },

  sbb: {
    title: ['Støttende Borger-Bobler (SBB)'],
    text1: [
      'Virksomheden Boblberg skal i projektet teste og videreudvikle digitale fortrolige støtte- og kommunikationsgrupper kaldet Støttende Borger-Bobler. Dette sker via sikre fællesskabsgrupper i samarbejde med fagpersoner tilknyttet et kommunalt psykiatri-tilbud og en sygehusenhed med patienter i psykiatrisk og somatisk behandling.',
    ],
    text2: [
      'Tjenesteydelsen Støttende Borger-Bobler er et simpelt digitalt værktøj. Tjenesten er uden reklamer, den har kun én enkelt funktion, som er at oprette fortrolige støtte- og kommunikationsgrupper, med fokus på beskyttelse af brugerne og sikker opbevaring af persondata. Målet er at bidrage til at inkludere borgere/patienter i sociale ligeværdige gruppefællesskaber, hvilket er vigtigt for menneskers trivsel, livskvalitet og mentale sundhed, ikke mindst for borgere, som netop har afsluttet et patientforløb.',
    ],
    text3: [
      'Såfremt partnerkredsen gennem testprojektet bliver bekræftet i deres tese om, at løsningen kan bidrage til at støtte borgere/patienter via støtte- og kommunikationsgrupper, forventer virksomheden at omsætte det til en supplerende forretning med omsætnings- og jobvækst til følge.',
    ],
    involved: ['Involverede parter'],
    partner1: ['Boblberg v/ Shark Inc ApS'],
    partner2: ['Vejle Kommune, Velfærdsforvaltningen'],
    partner3: ['Colab/Recovery & Rehab / psykiatrien i Region Syddanmark'],
    partner4: ['Esbjerg Kommune, Videnscenter for demens'],
    partner5: ['SDU - Institut for Entreprenørskab og Relationsledelse'],
    projectPeriod: ['Projektperiode'],
    period: ['01.11.2018 - 31.08.2019'],
    supporter: ['Støttegiver'],
    supporterText: [
      'Boblberg har gennem Syddansk OPI-pulje modtaget tilskud til OPI-projektet ”Støttende Borger-Bobler” fra Den Europæiske Regionalfond og Syddansk Vækstforum.',
    ],
  },

  phd: {
    title: [
      'Ph.d.-projekt - Sociale fællesskabers betydning for mental sundhed',
    ],
    text1: [
      'Boblberg vil i den kommende tid samarbejde med sociolog og ph.d. studerende, Lærke Mai Bonde Andersen. Lærke er ansat ved institut for Medicin og Sundhedsteknologi på Aalborg Universitet, hvor hun forsker i folkesundhed med særlig fokus på sociale fællesskabers betydning for mental sundhed.',
    ],
    text2: [
      'I Lærkes ph.d.-projekt vil hun undersøge hvordan, for hvem og under hvilke betingelser online markedspladser for sociale kontakter, som eksempelvis Boblberg.dk, kan medvirke til at skabe meningsfulde sociale fællesskaber karakteriseret ved oplevelsen af socialt tilhørsforhold og mental sundhed.',
    ],
    text3: [
      'Målet med ph.d. projektet er at styrke udviklingen af initiativer til fremme af meningsfulde sociale fællesskaber med positiv betydning for mental sundhed.',
    ],
    projectPeriod: ['Projektperiode'],
    period: ['01.12.2018 - 31.08.2020'],
    involved: ['Involverede parter'],
    partner1: ['Boblberg'],
    partner2: [
      'Ph.d. studerende, Lærke Mai Bonde Andersen, Cand. Scient. Soc., Institut for Medicin og Sundhedsteknologi. Aalborg Universitet.',
    ],
    partner3: ['Aalborg Kommune, Center for Mental Sundhed.'],
  },

  chs: {
    title: ['Forskningsprojekt – Seniorers brug af Boblberg'],
    text1: [
      'Boblberg samarbejder i øjeblikket med antropolog og videnskabelig assistent, Simone Anna Felding. Simone er videnskabelig assistent på Institut for Folkesundhedsvidenskab på Københavns Universitet, hvor hun forsker i sundhedsantropologi særligt med fokus på ældre i Center for Sund Aldring.',
    ],
    text2: [
      'I projektet undersøger Simone seniorers brug af Boblberg i Hvidovre og Frederiksberg, de to kommuners organisering omkring Boblberg, samt Boblbergs egen organisering. Formålet med projektet er at skabe en bedre forståelse for den ældre gruppe af Boblberg-brugere og samspillet mellem Boblberg, kommuner og de ældre brugere.',
    ],
    text3: [
      'Projektet er fundet af EU Regionalfonds projektet ’Copenhagen Healthtech Solutions (CHS)’.',
    ],
    projectPeriod: ['Projektperiode'],
    period: ['01.10.2018 - 31.05.2019'],
    involved: ['Involverede parter'],
    partner1: ['Boblberg'],
    partner2: [
      'Videnskabelig Assistent, Simone Anna Felding, cand.scient.anth Afdeling for Sundhedstjenesteforskning, Institut for Folkesundhedsvidenskab, Københavns Universitet.',
    ],
    partner3: ['Hvidovre og Frederiksberg kommune'],
  },

  opildl: {
    title: ['OPI LDL - Det demensvenlige samfund'],
    text1: ['Digitalt værktøj til demensramte og deres pårørende'],
    text2: [
      'OPI-samarbejdet skal teste og udvikle en løsning til mennesker med demens, deres pårørende og øvrige netværk. Projektet er adresseret på tværs af demens-, velfærdsteknologi-, ældreområdet samt byplanlægning i de tre kommuner; Haderslev, Billund og Kolding Kommune. OPI = Offentlig-Privat Innovation',
    ],
    text3: [
      'Et centralt spørgsmål i projektet: Hvordan kan vi forebygge, at de nære pårørende til demensramte bliver overbebyrdet og stressede i hverdagen?',
    ],
    text4: [
      'Den løsning, som det planlagte test- og udviklingsforløb, er centreret omkring, beror på en konceptidé som:',
    ],
    text5: ['Målet er en raffineret prototype.'],
    text6: [
      'Det samarbejdende netværkskonsortium består af virksomhederne Tele Call (telecall.dk) og ARBEJDSMILJØEksperten (ameksperten.dk) og Boblberg. Virksomhederne bidrager med hver deres kompetencer og knowhow ind i netværkskonsortiet for at opfylde de krav og behov, de faglige ankerpersoner fra Haderslev, Billund og Kolding kommune foreløbigt har identificeret som væsentlige i forhold til udfordringerne ved Det Demensvenlige Samfund.',
    ],
    point1: ['Tager udgangspunkt i pårørendes behov for støtte og hjælp'],
    point2: [
      'Hjælper pårørende til at fastholde relationer til omverdenen, der giver livskvalitet',
    ],
    point3: [
      'Understøtter tidlig indsats overfor pårørende så fysisk og psykisk nedslidning undgås',
    ],
    projectPeriod: ['Projektperiode'],
    period: ['2018 - 2019'],
    moreInfo: ['Yderligere information om projektet:'],
    ldlLink: ['livingdesignlab.dk'],
    projectResults: ['Projektets resultater:'],
    projectResponsible: ['Projektansvarlig hos Boblberg'],
  },

  opifakto: {
    title: ['OPI FAKTO'],
    title1: ['Formål med projektet: '],
    text1: [
      'Formålet med dette projekt er først og fremmest, at det skal være nemmere og lettere tilgængeligt for medarbejdere og borgere at finde aktuelle aktiviteter i kommunen via denne online aktivitetsoversigt på Boblberg. Odense Kommune ønsker et sundt, aktivt og selvstændigt liv for deres borgere. Odenses frivillige sociale foreningsliv, motions- og rådgivningstilbud m.m. er med til at sikre dette. Derfor er Odense Kommune gået sammen med Boblberg om at videreudvikle på prototypen for en forenings- og aktivitetsoversigt (FAKTO). ' +
        '\nForskning' +
        '\u00B9' +
        ' viser at man ved deltagelse i fællesskaber og aktiviteter kan bekæmpe ensomhed, fysisk inaktivitet og mental mistrivsel. Men mange borgere og medarbejdere i kommunen har svært ved at finde mulighederne i de lokale aktiviteter, foreninger og kommunale tilbud og en oversigt hvor de tilgængelige aktivitetsrum som f.eks. hjertestier er. ' +
        'Sociale foreninger har mange gode tiltag og tilbud, men har svært ved at være synlige overfor kommunens medarbejdere og borgere, hvorfor aktiviteterne bør samles ét sted. Når aktiviteterne samles i en digital aktivitetsoversigt, skabes der større overblik over alle aktiviteter og foreningstilbud, og dette til både gavn for borgere, medarbejdere og foreningerne selv. Man vil ikke længere være nødsaget til at ringe eller søge på de enkelte foreningers hjemmesider for at blive informeret om deres aktiviteter. ' +
        'Formålet med denne digitale aktivitetsoversigt er ligeledes, at aktiviteterne i kommunen altid vil være opdateret, så borgere og medarbejdere ikke bliver forvirrede og fejlinformeret om aktiviteterne. Det vil være muligt at opdatere i aktiviteterne undervejs, hvis der sker nogle ændringer, fx i tidspunkter, lokationer eller hvis de bliver forældede eller aflyste, så de ikke længere er relevante. Derved bliver borgerne og medarbejdere altid kun mødt af aktuelle tilbud, som passer til den enkeltes behov, ønsker og egne ressourcer. ' +
        'Ydermere er formålet at mindske den stigende ensomhed og dårlige fysiske og mentale sundhed blandt borgerne. Ved hjælp af FAKTO bliver det nemmere for borgere og kommunale medarbejdere at finde relevante foreningstilbud, til styrkelse af et øget socialt liv og bedre fysisk og mental sundhed.  ',
    ],
    title2: ['Produktet og dets funktion:'],
    text2: [
      'Boblberg ønsker at tage den nuværende prototype på en forenings- og aktivitetsoversigt og videreudvikle, teste og tilpasse produktet, så det giver et samlet digitalt overblik over aktiviteter der passer til behovene i kommunerne og for borgerne. Dette med udgangspunktet i en høj grad af brugervenlighed. ' +
        'I aktivitetsoversigten har borgere og medarbejdere i kommunen mulighed for at få et overblik over en lang række forskellige aktiviteter, som kommunen og dens sociale foreninger har oprettet. ' +
        'Som bruger af tjenesten navigerer man rundt blandt aktiviteterne, og gøres på denne måde opmærksom på de gældende tilbud i kommunen. ' +
        'Man kan også søge efter specifikke ord som: Sclerose, KOL, genoptræning eller foreninger som Kræftens Bekæmpelse eller lign. Når man som borger eller medarbejder finder interesse for en bestemt aktivitet, trykker man på den for at få flere informationer om fx tidspunkt, sted, tilmelding mm. ' +
        'Aktivitetsoversigten kan i høj grad hjælpe på borgernes fysiske og mentale sundhed samt deres oplevede velfærd og livskvalitet, hvorfor det er vigtigt med en let tilgængelig samlet oversigt over de sociale aktiviteter i kommunen. ',
    ],
    imagetext: ['Aktivitetsmodulet.'],
    projectPeriod: ['Projektperiode'],
    period: ['1. oktober 2020 - 30. august 2021'],
    supportTitle: ['Støttergiver'],
    support: [
      'Projektet er finansieret af Den Europæiske Fond for Regionaludvikling i regi af Welfare Tech.',
    ],
    partnerInfo: ['Involverede parter'],
    partners: [
      'Boblberg, Røde Kors, Odense Kommune og SDU (Institut for Entreprenørskab og Relationsledelse).',
    ],
    referencesTitle: ['Referancer'],
    references: [
      '1. http://www.abcmentalsundhed.dk/media/1144/abc-brochure-om-at-vaere-aktiv.pdf',
    ],
    projectResponsible: ['Projektansvarlig hos Boblberg'],
  },
  OEUG: {
    title: ['Økonomer Uden Grænser'],
    text1: [
      'Økonomer Uden Grænser har – på baggrund af en treårig prognose – udarbejdet en SROI-analyse, der værdisætter effekten af Boblbergs platform inden for områderne øget selvtillid, mindre ensomhed samt øget fysisk aktivitet. Undersøgelsen viser bl.a. at brugerne især (62 pct.) opsøger Boblberg på grund af ensomhed eller for at finde en ven, og at brugerne i høj grad (67 pct.) ender med at mødes fysisk med mennesker, de har mødt på Boblberg. Af brugerne, der søger motionsvenner, lykkes det langt størstedelen (74 pct.) at finde en at motionere med på Boblberg',
    ],
    text2: [
      'Resultaterne viser derudover bl.a. at 40 pct. føler sig mindre ensomme efter at have oprettet en ‘boble’ på Boblberg og 33 pct. oplever højere selvværd efter at have mødtes med andre brugere af Boblberg.\nI løbet af tre år genererer Boblberg en samfundsøkonomisk værdi på 14,7 mio. kr. Omkostningerne, der generer denne værdi, beløber sig til 2,6 mio. kr., og nettogevinsten er således godt 12 mio. kr. Dette giver en social cost benefit ratio (SCBR) på 5,6, hvilket betyder, at for hver krone investeret i Boblberg, får samfundet 5,6 kroner igen. Det svarer til et årligt afkast – det såkaldte social return on investment (SROI) – på 37 pct.\n\nDette betyder, at investeringen i Boblbergs indsats, er en særdeles fornuftig investering set fra et socialt og samfundsmæssigt perspektiv.',
    ],
    shortInfo: [
      'Økonomer Uden Grænser har – på baggrund af en treårig prognose – udarbejdet en SROI-analyse, der værdisætter effekten af Boblbergs platform inden for områderne øget selvtillid, mindre ensomhed samt øget fysisk aktivitet.',
    ],
    shortInfo2: [
      'Resultaterne viser at for hver krone investeret i Boblberg, får samfundet 5,6 kroner igen. Dette betyder, at investeringen i Boblbergs indsats, er en særdeles fornuftig investering set fra et socialt og samfundsmæssigt perspektiv.',
    ],
    readMoreBtn: ['Læs om flere projekter'],
  },
}
