import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'
import { URLOpener } from '../../../../utils/URLOpener'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')
export default class Anna extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.anna.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/blog/anna.jpg' }}
              accessibilityIgnoresInvertColors
            />
            <CustomText font="bold">{txt('blog.anna.author')}</CustomText>
          </View>
          <CustomText>{txt('blog.anna.text1')}</CustomText>
          <Image
            source={{ uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-anna1.jpg' }}
            resizeMode="contain"
            style={styles.imageBig}
            accessibilityIgnoresInvertColors
          />
          <CustomText font="bold" style={styles.marginBottom}>
            {txt('blog.anna.headline1')}
          </CustomText>
          <View>
            <CustomText>{txt('blog.anna.text2')}</CustomText>
            <CustomText>{txt('blog.anna.text22')}</CustomText>
            <CustomPressable
              onPress={() => URLOpener('https://politiken.dk/forbrugogliv/sundhedogmotion/art6374965/Hver-femte-ung-er-ensom-is%C3%A6r-kvinder-f%C3%B8ler-sig-udenfor')}
              style={styles.marginTop}
            >
              <CustomText font="small">
                {txt('blog.anna.footNote1')}
              </CustomText>
            </CustomPressable>
            <View>
              <CustomText>{txt('blog.anna.text23')}</CustomText>
              <CustomPressable
                onPress={() => URLOpener('https://www.information.dk/indland/2016/07/psykisk-saarbare-unge-staar-ingenmandsland')}
                style={styles.marginTop}
              >
                <CustomText font="small">
                  {txt('blog.anna.footNote2')}
                </CustomText>
              </CustomPressable>
            </View>

            <View>
              <CustomText>{txt('blog.anna.text24')}</CustomText>
            </View>
          </View>
          <CustomText font="bold" style={styles.marginBottom}>
            {txt('blog.anna.headline2')}
          </CustomText>
          <View>
            <CustomText>{txt('blog.anna.text3')}</CustomText>
          </View>

          <Image
            source={{ uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-anna2.jpg' }}
            resizeMode="contain"
            style={styles.imageBig}
            accessibilityIgnoresInvertColors
          />
          <CustomText font="bold" style={styles.marginBottom}>
            {txt('blog.anna.headline3')}
          </CustomText>
          <CustomText>{txt('blog.anna.text4')}</CustomText>

          <Image
            source={{ uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-anna3.jpg' }}
            resizeMode="contain"
            style={styles.imageBig}
            accessibilityIgnoresInvertColors
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  imageBig: { alignSelf: 'center', height: 300, width: width * 0.9 },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
