import Colors from '../../constants/Colors'
import { Dimensions, Image, StyleSheet } from 'react-native'
import NormalIcon from '../../components/icons/NormalIcon'
import Fontsizes from '../../constants/Fontsizes'
import React from 'react'
import CustomPressable from "../../components/pressables/CustomPressable"

const logoNoCross = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-logo-i-samarbejde-med-no-cross.png'
const logo = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-logo-i-samarbejde-med.png'
const juniorLogo = 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-junior-logo.png'

const { width } = Dimensions.get('window')
const small = width < 800

export const sproglandHeaderBackImage = (navigation) => (
  <CustomPressable style={{ padding: 12 }} onPress={navigation.goBack}>
    <NormalIcon
      color={Colors.redCrossRed}
      name="chevron-left"
      size={Fontsizes.m}
      stroke="fas"
      style={{ paddingLeft: 6, paddingRight: 12 }}
    />
  </CustomPressable>
)

export const sproglandHeaderTitle = (cross) => (
  <Image
    style={small && cross ? styles.logo : small && !cross ? styles.logoNoCross : !small && cross ? styles.logoBig : styles.logoBigNoCross}
    resizeMode="contain"
    source={{ uri: cross ? logo : logoNoCross }}
    accessibilityIgnoresInvertColors
  />
)

export const sproglandJuniorHeaderTitle = () => (
  <Image
    style={small ? styles.juniorLogo : styles.juniorLogoBig}
    resizeMode="contain"
    source={{ uri: juniorLogo }}
    accessibilityIgnoresInvertColors
  />
)

export const sproglandHeaderStyle = {
  backgroundColor: Colors.redCrossBackground,
  elevation: 0,
  shadowOpacity: 0,
  borderBottomWidth: 0,
  height: small ? 140 : 120,
}

const styles = StyleSheet.create({
  logo: {
    height: 70,
    width: 200,
  },
  logoNoCross: {
    height: 40,
    width: 200,
  },
  logoBig: {
    height: 80,
    width: 200,
  },
  logoBigNoCross: {
    height: 50,
    width: 200,
  },
  juniorLogo: {
    height: 70,
    width: 225,
  },
  juniorLogoBig: {
    height: 80,
    width: 250,
  },
})