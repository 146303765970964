import { CustomText } from '../components/text/StyledText'
import Colors from '../constants/Colors'
import React from 'react'
import { URLOpener } from './URLOpener'
import { Linking } from 'react-native'
import RegexPatterns from '../constants/RegexPatterns'

export function addLinkToText(text) {
  const emailPattern = RegexPatterns.email[1].substring(1, RegexPatterns.email[1].length - 1)
  const linkPattern = '([H,h]ttps?:|[W,w]ww\\.)\\S*'
  const regexPattern = `(${emailPattern})|(${linkPattern})`
  const regex = new RegExp(regexPattern, 'g')
  let match
  const links = []

  while ((match = regex.exec(text)) !== null) {
    links.push(match[0])
  }

  let textWithLinks = [text]
  for (let i = 0; i < links.length; i++) {
    const textIndex = i * 2
    const currentText = textWithLinks[textIndex]
    const linkStartIndex = currentText.indexOf(links[i])
    const linkEndIndex = linkStartIndex + links[i].length

    const clickableLink = (
      <CustomText key={i} style={{ color: Colors.tintColor }} onPress={() => openUrl(links[i])}>
        {links[i]}
      </CustomText>
    )
    const start = currentText.slice(0, linkStartIndex)
    const end = currentText.slice(linkEndIndex)
    textWithLinks = [...textWithLinks.slice(0, textIndex), start, clickableLink, end]
  }
  return textWithLinks
}

const openUrl = (url) => {
  if (url.includes('@')) {
    return Linking.openURL(`mailto:${url}`)
  }
  if (!url.startsWith('http')) {
    url = 'http://' + url
  }
  URLOpener(url)
}