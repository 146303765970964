import Paths from '../../constants/Paths'
import { Platform } from 'react-native'
import { getRegion } from '../localization'

export default async function trackPlausiblePageView(newRoute) {
	const newR = newRoute.getCurrentRoute()
	let url = 'app/'
	let id = ''
	let universe = ''
	let chip = ''
	let route = newR.name + '/'

	if (newR.params) {
		Object.keys(newR.params).forEach((param) => {
			if (param === 'id') {
				id = newR.params.id + '/'
			} else if (param === 'universe' && newR.params.universe?.length > 0) {
				universe = newR.params.universe + '/'
			} else if (param === 'chip' && newR.params.chip.length > 0) {
				chip = newR.params.chip + '/'
			}
		})
	}
	if (route === 'EditPost/' || route === 'Conversation/') {
		url += universe + route + chip
	} else {
		url += universe + route + chip + id
	}

	if (Platform.OS !== 'web' && !__DEV__) {
		url = url.slice(0, -1)
		const region = getRegion()
		const data = {
			name: 'pageview',
			url: Paths.getBaseUrl() + url,
			domain: `app.boblberg.${region},boblberg.samlet`,
		}
		try {
			await fetch('https://plausible.io/api/event', {
				method: 'POST',
				body: JSON.stringify(data),
			})
		} catch (error) {
			if (__DEV__) console.log(error)
		}
	}
}

/**
 * Tracks an event in Plausible analytics.
 *
 * @param {string} eventName - The name of the event to track.
 * @param {Object} props - Additional properties to send with the event.
 * @param {string} url - The URL or path where the event occurred. This will be appended to the base URL for non-web platforms.
 */
export async function trackPlausibleEvent(eventName, props, url) {
	if (Platform.OS === 'web') {
		window.plausible(eventName, { props: props })
	}
	if (Platform.OS !== 'web') {
		const region = getRegion()
		const data = {
			name: eventName,
			url: Paths.getBaseUrl() + 'app/' + url,
			props: props,
			domain: `app.boblberg.${region},boblberg.samlet`,
		}
		try {
			await fetch('https://plausible.io/api/event', {
				method: 'POST',
				body: JSON.stringify(data),
			})
		} catch (error) {
			if (__DEV__) console.log(error)
		}
	}
}
