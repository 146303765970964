import React, { useEffect, useState } from "react"
import { StyleSheet, View } from "react-native"

import { txt } from "../../../../locales/i18n"

import CustomPressable from "../../../pressables/CustomPressable"
import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import { CustomText } from "../../../text/StyledText"

import NormalIcon from "../../../icons/NormalIcon"
import Colors from "../../../../constants/Colors"

import { getSessionCounters } from "../../../../services/api/snakSammen"

export default function UserDetailsDialog({ visible, onDismiss, currentItem }) {
	const [currentSnakSammenCounter, setCurrentSnakSammenCounter] = useState(0)
	const [currentSproglandCounter, setCurrentSproglandCounter] = useState(0)

	useEffect(() => {
		if (!currentItem || currentItem === '') return
		const id = currentItem.user_id ?? currentItem.bookerId ?? currentItem.userId
		getSessionCounters(id).then((res) => {
			setCurrentSnakSammenCounter(res.snakSammenCount)
			setCurrentSproglandCounter(res.sproglandCount)
		})
	}, [currentItem])

	return (
		<CustomDialog visible={visible} onDismiss={onDismiss}>
			<CustomPressable onPress={onDismiss} style={styles.closeIcon}>
				<NormalIcon name="times" size={20} color={Colors.darkGrey}/>
			</CustomPressable>
			<ContentContainer style={{ margin: 36 }}>
				<CustomText font="gotham-bold" style={{ marginBottom: 12 }}>{currentItem?.username}</CustomText>
				<View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12, flexWrap: 'wrap'}}>
					<CustomText font="gotham-bold-small">{currentSnakSammenCounter}</CustomText>
					<CustomText font="gotham-small">
						{currentSnakSammenCounter === 1 ? txt('videoChat.futureSession') : txt('videoChat.futureSessions')}
					</CustomText>
					<CustomText font="gotham-bold-small">{'SnakSammen'}</CustomText>
				</View>
				<View style={{flexDirection: 'row', alignItems: 'center', marginTop: 12, flexWrap: 'wrap'}}>
					<CustomText font="gotham-bold-small">{currentSproglandCounter}</CustomText>
					<CustomText font="gotham-small">
						{currentSproglandCounter === 1 ? txt('videoChat.futureSession') : txt('videoChat.futureSessions')}
					</CustomText>
					<CustomText font="gotham-bold-small">{'Sprogland'}</CustomText>
				</View>
			</ContentContainer>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	closeIcon: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	},
})