import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'

const { width } = Dimensions.get('window')

export default class Conny extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.conny.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/conny.jpg',
              }}
              accessibilityIgnoresInvertColors
            />

            <CustomText font="bold">{txt('blog.conny.author')}</CustomText>
          </View>
          <View>
            <CustomText>{txt('blog.conny.text1')}</CustomText>
            <Image
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/blog-conny.jpg',
              }}
              resizeMode="contain"
              style={styles.imageBig}
              accessibilityIgnoresInvertColors
            />
            <CustomText>{txt('blog.conny.text2')}</CustomText>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  imageBig: { alignSelf: 'center', height: 300, width: width * 0.9 },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
