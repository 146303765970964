import React from 'react'

import { CmsView } from '../../components/CmsView'
import * as Localization from '../../services/localization'

const paths = {
	dk: '/hvad-er-boblberg',
	se: '/vad-ar-boblberg',
	de: '/was-ist-boblberg',
}

const region = Localization.getRegion()

export const WhatIsBoblbergScreen = () => {
	const uri = paths[region] ?? paths.dk
	return <CmsView uri={uri} />
}
