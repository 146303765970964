import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'

import OptionsList from '../../components/lists/OptionsList'
import { txt } from '../../locales/i18n'
import MainContainer from '../../components/MainContainer/MainContainer'
import Version from '../../components/tags/Version'
import layout from '../../constants/Layout'
import { getRegion } from '../../services/localization'
import { URLOpener } from '../../utils/URLOpener'
import Paths from "../../constants/Paths"

export default function StackMenuAuthScreen({ navigation }) {
  const region = getRegion()

  const handleOpenWhatIsBoblberg = async () => {
    const url = Paths.getBaseUrl()
    const page = region === 'de' ? url + '/was-ist-boblberg' : region === 'se' ? url + '/vad-ar-boblberg' : url + '/hvad-er-boblberg'
    await URLOpener(page)
  }

  const data = [
    {
      body: txt('stackMenu.whatIs'),
      onPress: handleOpenWhatIsBoblberg,
      icon: { name: "question-circle" },
      hide: false,
    },
    {
      body: txt('christmasFriends.title'),
      onPress: () => URLOpener('https://boblberg.dk/julevenner'),
      icon: { name: "hat-santa" },
      hide: region !== 'dk',
    },
    {
      body: txt('stackMenu.nytaarsvenner'),
      onPress: () => URLOpener('https://boblberg.dk/nytaarsvenner'),
      icon: { name: "glass-cheers" },
      hide: region !== 'dk',
    },
    {
      body: txt('videoChat.title'),
      onPress: () => URLOpener('https://boblberg.dk/snaksammen'),
      icon: { name: "comments" },
      hide: region !== 'dk',
    },
    {
      body: txt('learnDanish.title'),
      onPress: () => navigation.navigate('LearnDanish'),
      icon: { name: "globe-europe" },
      hide: region !== 'dk',
    },
    {
      body: txt('activities.title'),
      onPress: () => navigation.navigate('Activities'),
      icon: { name: "calendar-day" },
      hide: region !== 'dk',
    },
    {
      body: txt('unions.title'),
      onPress: () => navigation.navigate('Unions'),
      icon: { name: "users-class" },
      hide: region !== 'dk',
    },
    {
      body: txt('events.title'),
      onPress: () => navigation.navigate('Events'),
      icon: { name: "calendar-star" },
      hide: region !== 'dk',
    },
    {
      body: txt('groups.title'),
      onPress: () => URLOpener('https://boblberg.dk/grupper'),
      icon: { name: "users" },
      hide: region !== 'dk',
    },
    {
      body: txt('stackMenu.stories'),
      onPress: () => navigation.navigate('Stories'),
      icon: { name: "book-open" },
      hide: region !== 'dk',
    },
    {
      body: txt('about.title'),
      onPress: () => navigation.navigate('About'),
      icon: { name: "bobl", boblbergIcon: true },
      hide: region !== 'dk',
    },
    {
      body: txt('contact.title'),
      onPress: () => navigation.navigate('Contact'),
      icon: { name: "info-circle" },
      hide: false,
    },
  ]

  if (Platform.OS === 'web') {
    data.push({
      body: txt('cookieLabel'),
      onPress: () => showCookieBanner(),
      icon: { name: "cookie-bite" },
      hide: false,
    })
  }

  return (
    <MainContainer>
      <View style={styles.container}>
        <OptionsList data={data.filter((options) => options.hide === false)} />
        <Version />
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: layout.maxFeedWidth,
  },
})
