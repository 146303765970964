import { apiDelete, apiGet, apiPost, apiPut } from './ApiRequest'

export async function createJuniorProfile({name, birthday}) {
  const body = { name, birthday }
  let res = await apiPost(body, 'juniors')
  return res.json()
}

export async function getJuniorProfiles() {
  const res = await apiGet(`juniors`)
  return res
}

export async function updateJuniorProfile({name, birthday, id}) {
  const body = { name, birthday }
  const res = await apiPut(body, `juniors/${id}`)
  const junior = await  res.json()
  return res.ok ? junior : null
}

export async function deleteJuniorProfile(id) {
  const res = await apiDelete({},`juniors/${id}`)
  if (res.ok) return true
  else return res.json()
}