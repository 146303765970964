import React from 'react'
import { View, Text, Image, StyleSheet, Dimensions } from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import { URLOpener } from '../../../../utils/URLOpener'
import PageTitle from '../../../tags/PageTitle'
import { ProjectStyles } from './ProjectStyles'
import Layout from '../../../../constants/Layout'

const { width } = Dimensions.get('window')
export default class Demantec2 extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={ProjectStyles.widthHeight}>
        <PageTitle title={TxtProjects.demantec2.title[TxtProjects.lang]} />

        <View style={ProjectStyles.container}>
          <Text
            style={ProjectStyles.blogTitle}
            accessibilityRole="header"
            aria-level={1}
          >
            {TxtProjects.demantec2.title[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageIR}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/interreg_logo.png',
            }}
          />

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.text1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.demantec2.text2[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.text3[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.demantec2.text4[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.text5[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogTextBottom}>
            {TxtProjects.demantec2.text6[TxtProjects.lang]}
          </Text>
          <Text
            style={ProjectStyles.linkBottom}
            onPress={() => URLOpener('http://demantec.eu/da/')}
          >
            {TxtProjects.demantec2.readMore[TxtProjects.lang]}
            {TxtProjects.demantec2.demantecLink[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageDPP}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Demantec_Projectpartner_DK.jpg',
            }}
          />

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.involved[TxtProjects.lang]}
          </Text>
          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.partner1[TxtProjects.lang]}
          </Text>
          <Text
            style={ProjectStyles.link}
            onPress={() => URLOpener('https://da.life-partners.com/')}
          >
            {TxtProjects.demantec2.partner2[TxtProjects.lang]}
          </Text>
          <Text
            style={ProjectStyles.link}
            onPress={() => URLOpener('https://phabsalon.dk/')}
          >
            {TxtProjects.demantec2.partner3[TxtProjects.lang]}
          </Text>
          <Text
            style={ProjectStyles.linkBottom}
            onPress={() => URLOpener('https://hs-flensburg.de/en')}
          >
            {TxtProjects.demantec2.partner4[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.projectPeriod[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.demantec2.period[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.demantec2.supporter[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.demantec2.supporterText[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogTextBottom}>
            {TxtProjects.demantec2.readMoreSupporter[TxtProjects.lang]}
          </Text>
          <Text
            style={ProjectStyles.linkBottom}
            onPress={() => URLOpener('https://www.interreg5a.eu/')}
          >
            {TxtProjects.demantec2.supporterLink[TxtProjects.lang]}
          </Text>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  imageDPP: {
    alignSelf: Layout.isSmallDevice ? 'center' : null,
    height: Layout.isSmallDevice ? width * 0.5 : 400,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.9 : 700,
  },
  imageIR: {
    height: Layout.isSmallDevice ? width * 0.2 : 100,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.9 : 550,
  },
})
