import React from 'react'
import StoryFull from '../../components/views/Stories/StoryFull'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useRoute } from "@react-navigation/core"
import { getUniverseConfig } from "../../universe/universeController"

export default function StoryScreen(props) {

  const route = useRoute()
  const { universe } = route.params

  const universeObject = getUniverseConfig(universe?.toLowerCase())

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <StoryFull navigation={props.navigation} id={props.route.params.id} />
    </MainContainer>
  )
}
 