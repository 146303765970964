import React from 'react'
import EditUnion from '../../components/views/Union/EditUnion'
import MainContainer from '../../components/MainContainer/MainContainer'
import {
  deleteUnion,
  getFullUnion,
  updateUnion,
} from '../../services/api/Unions'
import {
  deleteUnion as deleteUnionApi,
  getFullUnion as getFullUnionApi,
  updateUnion as updateUnionApi,
} from '../../services/api/Unions'

export default class EditUnionScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  async getFullUnion(id) {
    return await getFullUnionApi(id)
  }

  async deleteUnion(unionId) {
    return await deleteUnionApi(unionId)
  }

  async updateUnion(data) {
    return await updateUnionApi(data)
  }

  render() {
    return (
      <MainContainer>
        <EditUnion
          getFullUnion={getFullUnion}
          deleteUnion={deleteUnion}
          updateUnion={updateUnion}
          navigation={this.props.navigation}
          id={this.props.route.params.id}
        />
      </MainContainer>
    )
  }
}
