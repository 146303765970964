import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { Map } from '../../maps/map'
import UnionListItem from './UnionListItem'
import { UnionLocationModal } from './UnionLocationModal'
import MunicipalityFilter from '../../shared/MunicipalityFilter'

import PageTitle from '../../../components/tags/PageTitle'
import SearchList, { ListSeparator } from '../../../components/lists/SearchList'
import NormalIcon from '../../icons/NormalIcon'

import Colors from '../../../constants/Colors'
import TxtTMD from '../../../constants/TxtTMD'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import { dropShadow } from '../../../styles/shadows'

import { useRoute } from '@react-navigation/core'
import CustomPressable from "../../pressables/CustomPressable"

export default function UnionFeed(props) {
  const [location, setLocation] = useState(null)
  const [showMap, setShowMap] = useState(false)
  const [municipalityFocus, setMunicipalityFocus] = useState(false)

  const municipalityRef = useRef(null)
  const route = useRoute()

  useEffect(() => {
    municipalityRef.current = props.municipality
  }, [props.municipality])

  const renderItem = ({ item }, index) => {
    if (item.isSeparator) {
      return <ListSeparator key={index} text={txt('listSeparators.unions')} />
    }

    return <UnionListItem item={item} onPressAction={() => props.navigateToUnion(item.id)} />
  }

  const toggleMap = () => {
    setLocation(null)
    setShowMap((prev) => !prev)
  }

  const getListVisibility = () => {
    const isLargeDevice = Layout.isSmallDevice === false
    const hideMap = showMap === false
    return isLargeDevice || hideMap
  }

  const getMapVisibility = () => {
    const isLargeDevice = Layout.isSmallDevice === false
    return isLargeDevice || showMap
  }

  const renderFilterBelow = () => {
    if (props?.filterBelow) return props.filterBelow()

    const isUniverse = Boolean(route.params?.universe)
    if (isUniverse) return null

    return (
      <View style={styles.municipalityFilter}>
        <MunicipalityFilter
          onChange={props.setMunicipality}
          municipalityId={props.municipality}
          focus={municipalityFocus}
          setFocus={setMunicipalityFocus}
          description={txt('unions.feed.municipalityFilterDescription')}
        />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <PageTitle title={TxtTMD.unions.title[TxtTMD.lang]} />

      <View style={[styles.listContainer, { display: getListVisibility() ? 'flex' : 'none' }]}>
        <SearchList
          renderItem={renderItem}
          getData={props.searchUnions}
          renderChips={props.renderChips}
          filterBelow={renderFilterBelow}
          backgroundColor={props.backgroundColor}
          triggerUpdate={() => municipalityRef.current !== props.municipality}
          center={true}
        />
      </View>

      {getMapVisibility() && (
        <View style={styles.mapContainer}>
          <Map locations={props.locations} onMarkerPress={setLocation} onMapPress={() => setLocation(null)} />
          {location && <UnionLocationModal location={location} />}
        </View>
      )}

      {Layout.isSmallDevice && (
        <CustomPressable style={[styles.mapButton, { bottom: location === null ? 16 : 160 }]} onPress={toggleMap}>
          <NormalIcon size={30} name={showMap ? "list" : "map"} color={Colors.white} />
        </CustomPressable>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: Layout.isSmallDevice ? 0 : 12,
  },

  listContainer: {
    flex: 1,
  },

  mapContainer: {
    flex: 1,
    marginBottom: 12,
    maxWidth: 800,
  },

  mapButton: {
    alignItems: 'center',
    backgroundColor: Colors.blue,
    borderRadius: 100,
    height: 64,
    justifyContent: 'center',
    position: 'absolute',
    right: 16,
    width: 64,
    ...dropShadow,
  },

  municipalityFilter: {
    alignSelf: 'center',
    marginVertical: 5,
  },
})
