import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { Platform, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { ButtonPrimary } from '../buttons/StyledButtons'
import CustomPressable from '../pressables/CustomPressable'
import {
	declineCourse, endCourse, getCourse,
	getCourses,
	getDeclinedCourses,
	getUserAnalysis,
	getUserCourse, startCourse
} from "../../services/api/Courses"
import * as Linking from "expo-linking"
import { addToWaitlist } from "../../services/api/snakSammen"
import { CustomSlider } from "../formElements/Slider"
import * as RootNavigation from "../../navigation/RootNavigation"
import store from "../../redux/store"
import { txt } from "../../locales/i18n"
import { addFeature } from "../../services/api/User"

export default function OPIDialog() {
	const userToken = useSelector((state) => state.userToken)
	const [startDialogVisible, setStartDialogVisible] = useState(false)
	const [endDialogVisible, setEndDialogVisible] = useState(false)
	const [userAnalysis, setUserAnalysis] = useState(null)
	const [userCourse, setUserCourse] = useState(null)
	const [wellbyScoreStart, setWellbyScoreStart] = useState(0)
	const [wellbyScoreEnd, setWellbyScoreEnd] = useState(0)

	const [currentIndex, setCurrentIndex] = useState(0)

	useEffect(() => {
		if (userToken) {
			const dialogTimeout = setTimeout(openStartDialog, 30000)
			return () => clearTimeout(dialogTimeout)
		}
	}, [userToken])

	useEffect(() => {
		if (userAnalysis) {
			getCourseFromUserAnalysis(userAnalysis).then((course) => {
				setUserCourse(course)
			})
		}
	}, [userAnalysis])

	const openStartDialog = async () => {
		// Return if user is not in Denmark
		const user = store.getState().user
		if (user?.country !== 'Denmark') return

		// Return if user does not have an analysis
		const analysis = await getUserAnalysis()
		if (analysis === null) return
		const analysisResult = JSON.parse(analysis.result)
		setUserAnalysis(analysisResult)

		// If user is already in course then check if user has ended course
		const userCourse = await getUserCourse()
		if (userCourse !== null) return openEndDialog(userCourse)

		const declinedData = await getDeclinedCourses()

		// Return if user has declined to start course more than 4 times
		const declinedStart = declinedData?.filter((item) => item?.declined_stage === 'start')
		if (declinedStart?.length >= 4) return

		const [declinedLatest] = declinedData

		// Return if user has declined within the last 7 days
		if (declinedLatest) {
			const latestDeclined = new Date(declinedLatest.declined)
			const checkDate = new Date(latestDeclined).setDate(latestDeclined.getDate() + 7)
			if (checkDate >= new Date()) return
		}

		setStartDialogVisible(true)
	}

	const openEndDialog = async (userCourse) => {
		// Return if user has already ended course
		if (userCourse?.wellby_ended !== null) return

		const declinedData = await getDeclinedCourses()

		// Return if user has declined to end course more than 4 times
		const declinedEnd = declinedData?.filter((item) => item?.declined_stage === 'end')
		if (declinedEnd?.length >= 4) return

		// Return if user has started course more than duration_days ago
		const startDate = new Date(userCourse?.wellby_started)
		const course = await getCourse(userCourse?.course_id)
		const checkDate = new Date(startDate)?.setDate(startDate.getDate() + course?.duration_days)
		if (checkDate >= new Date()) return

		setEndDialogVisible(true)
	}

	async function getCourseFromUserAnalysis(analysis) {
		const { nlp, search } = analysis
		const courseList = await getCourses()

		// Stress check
		if (search.stress === true && nlp.mentallyVulnerable === true) {
			return courseList?.find((course) => course?.id === 3)
		}

		// Anxiety check
		if (search.anxiety === true && nlp.mentallyVulnerable === true) {
			return courseList?.find((course) => course?.id === 2)
		}

		// Loneliness check
		if (nlp.loneliness === true) {
			return courseList?.find((course) => course?.id === 1)
		}

		return courseList[0]
	}

	const AcceptOffer = () => {
		const data = [
			{
				id: 1,
				value: 'loneliness',
				title: txt('partnerships.opiAims.loneliness.start.title'),
				description: txt('partnerships.opiAims.loneliness.start.text'),
				buttonTitle: txt('partnerships.opiAims.loneliness.start.action'),
				buttonAction: () => setCurrentIndex((currentIndex) => currentIndex + 1),
			},
			{
				id: 2,
				value: 'anxiety',
				title: txt('partnerships.opiAims.anxiety.start.title'),
				description: txt('partnerships.opiAims.anxiety.start.text'),
				buttonTitle: txt('partnerships.opiAims.anxiety.start.action'),
				buttonAction: () => setCurrentIndex((currentIndex) => currentIndex + 1),
			},
			{
				id: 3,
				value: 'stress',
				title: txt('partnerships.opiAims.stress.start.title'),
				description: txt('partnerships.opiAims.stress.start.text'),
				buttonTitle: txt('partnerships.opiAims.stress.start.action'),
				buttonAction: () => setCurrentIndex((currentIndex) => currentIndex + 1),
			},
		]

		// get data object that matches userCourse.id
		const dataToShow = data?.find((item) => item.id === userCourse?.id)

		return (
			<View style={{ paddingVertical: 12 }}>
				<CustomText font="bold">{dataToShow?.title}</CustomText>
				<CustomText font="small" style={{ marginVertical: 12 }}>{dataToShow?.description}</CustomText>
				<ButtonPrimary
					style={styles.button}
					titleStyle={styles.buttonTitle}
					onPress={dataToShow?.buttonAction}
				>
					{dataToShow?.buttonTitle}
				</ButtonPrimary>
			</View>
		)
	}

	const Wellby = ({ title, description, question, buttonTitle, userOption, setUserOption, action, snapToPrev = false }) => {
		return (
			<View style={{ paddingVertical: 12 }}>
				<CustomText font="bold" style={{ paddingTop: 0 }}>{title}</CustomText>
				<CustomText font="small" style={{ paddingTop: 12 }}>{description}</CustomText>
				<CustomText font="small" style={{ paddingTop: 18 }}>{question}</CustomText>

				<View style={styles.rangeTextContainer}>
					<CustomText font="xsmall" style={{ textAlign: 'center', color: Colors.darkGrey }}>
						{txt('partnerships.opiAims.wellby.lowRange')}
					</CustomText>
					<CustomText font="xsmall" style={{ textAlign: 'center', color: Colors.darkGrey }}>
						{txt('partnerships.opiAims.wellby.highRange')}
					</CustomText>
				</View>

				<CustomSlider
					option={userOption}
					minValue={0}
					maxValue={10}
					step={1}
					setOption={(value) => setUserOption(value)}
				/>

				<View style={styles.rangeActionContainer}>
					{snapToPrev && (
						<ButtonPrimary
							style={[styles.button, styles.secondary]}
							titleStyle={[styles.buttonTitle, styles.titleSecondary]}
							onPress={() => setCurrentIndex((currentIndex) => currentIndex - 1)}
						>
							{txt('partnerships.opiAims.wellby.back')}
						</ButtonPrimary>
					)}
					<ButtonPrimary
						style={styles.button}
						titleStyle={styles.buttonTitle}
						onPress={action} disabled={userOption === null}
					>
						{buttonTitle}
					</ButtonPrimary>
				</View>
			</View>
		)
	}

	const Confirmation = () => {
		const data = [
			{
				id: 1,
				value: 'loneliness',
				title: txt('partnerships.opiAims.loneliness.confirmation.title'),
				description: txt('partnerships.opiAims.loneliness.confirmation.text'),
				buttonTitle: txt('partnerships.opiAims.loneliness.confirmation.action'),
				buttonAction: () => setStartDialogVisible(false),
				icon: 'check',
			},
			{
				id: 2,
				value: 'anxiety',
				title: txt('partnerships.opiAims.anxiety.confirmation.title'),
				description: txt('partnerships.opiAims.anxiety.confirmation.text'),
				buttonTitle: txt('partnerships.opiAims.anxiety.confirmation.action'),
				buttonAction: async () => {
					// add feature 1 to user
					const addedSuccessfully = await addFeature(1)
					if (addedSuccessfully === false) return

					RootNavigation.navigate('HomeStack', {
						screen: 'Home',
						initial: true,
						params: { search: 'Angst' },
					})
					setStartDialogVisible(false)
				},
				icon: 'check',
			},
			{
				id: 3,
				value: 'stress',
				title: txt('partnerships.opiAims.stress.confirmation.title'),
				description: txt('partnerships.opiAims.stress.confirmation.text'),
				buttonTitle: txt('partnerships.opiAims.stress.confirmation.action'),
				buttonAction: async () => {
					Platform.OS === 'web' ? window.open('https://krifa.dk/medlemsfordele/stressfri-40', '_blank') :
					await Linking.openURL('https://krifa.dk/medlemsfordele/stressfri-40')
					setStartDialogVisible(false)
				},
				icon: 'check',
			},
		]

		// get data object that matches userCourse.id
		const dataToShow = data?.find((item) => item.id === userCourse?.id)

		return (
			<View style={{ paddingBottom: 12 }}>
				<NormalIcon
					name={dataToShow?.icon}
					color={Colors.greenIcon}
					size={16}
					stroke="fas"
					style={styles.icon}
				/>
				<CustomText font="bold">{dataToShow?.title}</CustomText>
				<CustomText font="small" style={{ marginVertical: 12 }}>{dataToShow?.description}</CustomText>
				<ButtonPrimary
					style={styles.button}
					titleStyle={styles.buttonTitle}
					onPress={dataToShow?.buttonAction}
				>
					{dataToShow?.buttonTitle}
				</ButtonPrimary>
			</View>
		)
	}

	const pages = [
		{
			id: 1,
			item: () => <AcceptOffer/>
		},
		{
			id: 2,
			item: () => (
				<View>
					<Wellby
						title={txt('partnerships.opiAims.wellby.start.title')}
						description={txt('partnerships.opiAims.wellby.start.text')}
						question={txt('partnerships.opiAims.wellby.start.question')}
						buttonTitle={txt('partnerships.opiAims.wellby.start.action')}
						userOption={wellbyScoreStart}
						setUserOption={setWellbyScoreStart}
						action={handleStartCourse}
						snapToPrev={true}
					/>
				</View>
			)
		},
		{
			id: 3,
			item: () => <Confirmation/>
		}
	]

	const renderPages = (page) => {
		return (
			<View key={page?.id} style={styles.itemContainer}>
				{page?.item()}
			</View>
		)
	}

	async function handleStartCourse() {
		const data = { courseId: userCourse?.id, wellbyScoreStart }
		const res = await startCourse(data)

		if (userCourse?.id === 1) {
			// Add user to SnakSammen waitlist
			await addToWaitlist()
		}

		if (res?.userId) {
			setCurrentIndex((currentIndex) => currentIndex + 1)
		}
	}

	async function handleEndCourse() {
		const data = { courseId: userCourse?.id, wellbyScoreEnd }
		const res = await endCourse(data)
		if (res?.userId) setEndDialogVisible(false)
	}

	async function handleCloseStartDialog() {
		await declineCourse(userCourse?.id, 'start')
		setStartDialogVisible(false)
	}

	async function handleCloseEndDialog() {
		await declineCourse(userCourse?.id, 'end')
		setEndDialogVisible(false)
	}

	return (
		<>
			<CustomDialog visible={startDialogVisible} style={styles.dialog}>
				<CustomPressable
					style={styles.closeIcon}
					accessibilityRole="button"
					onPress={currentIndex === pages.length - 1 ? () => setStartDialogVisible(false) : handleCloseStartDialog}
				>
					<NormalIcon name="times" color={Colors.inactive}/>
				</CustomPressable>
				{pages?.length > 0 && (
					<View>
						<View style={{ alignItems: 'center', alignSelf: 'center' }}>
							<View style={styles.contentContainer}>
								{renderPages(pages[currentIndex])}
							</View>
						</View>
					</View>
				)}
			</CustomDialog>

			<CustomDialog visible={endDialogVisible} style={styles.dialog}>
				<CustomPressable style={styles.closeIcon} accessibilityRole="button" onPress={handleCloseEndDialog}>
					<NormalIcon name="times" color={Colors.inactive} />
				</CustomPressable>
				<View style={styles.itemContainer}>
					<View style={{ paddingHorizontal: 12 }}>
						<Wellby
							title={txt('partnerships.opiAims.wellby.end.title')}
							description={txt('partnerships.opiAims.wellby.end.text')}
							question={txt('partnerships.opiAims.wellby.end.question')}
							buttonTitle={txt('partnerships.opiAims.wellby.end.action')}
							userOption={wellbyScoreEnd}
							setUserOption={setWellbyScoreEnd}
							action={handleEndCourse}
						/>
					</View>
				</View>
			</CustomDialog>
		</>
	)
}

const styles = StyleSheet.create({
	dialog: {
		backgroundColor: Colors.white,
		borderRadius: 10,
	},
	closeIcon: {
		alignSelf: 'flex-end',
		position: 'absolute',
		paddingLeft: 24,
		zIndex: 1,
		top: 12,
		right: 12,
	},
	itemContainer: {
		alignItems: 'center',
		marginHorizontal: 12,
		marginTop: 24,
	},
	rangeTextContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 24,
	},
	rangeActionContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		width: '100%',
		marginTop: 12,
	},
	button: {
		backgroundColor: Colors.blueAzure,
		borderRadius: 10,
		marginVertical: 12,
	},
	secondary: {
		backgroundColor: Colors.greyLightLight,
	},
	buttonTitle: {
		paddingHorizontal: 24,
		paddingVertical: 3,
		fontSize: 16,
	},
	titleSecondary: {
		color: Colors.inactive,
	},
	contentContainer: {
		width: '100%',
		paddingHorizontal: 12,
	},
	icon: {
		alignSelf: 'center',
		backgroundColor: Colors.greenExtraLight,
		borderRadius: 60,
		padding: 12,
		marginBottom: 18,
	}
})
