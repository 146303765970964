import { apiGet } from './ApiRequest'

export async function getStories({ search, skip, amount }) {
  const stories = await apiGet(
    `stories?search=${search}&skip=${skip}&amount=${amount}`
  )
  return stories.stories
}

export async function getStory(id) {
  const story = await apiGet(`stories/${id}`)
  return story.story
}
