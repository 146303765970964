import React, { useEffect, useState } from 'react'
import { Image, View, StyleSheet, Dimensions } from 'react-native'
import Colors from '../../../constants/Colors'
import { bookSession, getBookedSessions } from '../../../services/api/LearnDanish'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import { Portal } from 'react-native-paper'
import MainContainer from '../../MainContainer/MainContainer'
import SproglandUserView from '../Sprogland/SproglandUserView'
import { useNavigation } from '@react-navigation/core'
import {
  sproglandHeaderBackImage,
  sproglandHeaderStyle,
  sproglandHeaderTitle,
} from '../../../screens/LearnDanish/SproglandOptions'

const { width } = Dimensions.get('window')
const small = width < 800

export default function SproglandOversigt() {
  const [bookedSessions, setBookedSessions] = useState([])
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({
      headerBackTitle: small && ' ',
      headerBackTitleStyle: { color: Colors.text, fontFamily: 'raleway-regular' },
      headerBackImage: () => sproglandHeaderBackImage(navigation),
      headerTitle: () => sproglandHeaderTitle(true),
      headerStyle: sproglandHeaderStyle,
    })
  }, [])

  const book = (sessionId) => {
    return bookSession(sessionId)
  }

  const loadBookedSessions = async () => {
    const bookedSessions = await getBookedSessions()
    setBookedSessions(bookedSessions)
  }

  return (
    <Portal.Host>
      <MainContainer backgroundColor={Colors.redCrossBackground}>
        <PageTitle title={TxtTMD.learnDanish.title[TxtTMD.lang]} />
        <View style={styles.widthHeight}>
          <Image
            style={styles.bannerImage}
            resizeMode={'contain'}
            accessibilityIgnoresInvertColors
            source={{ uri: 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland-illustration.png' }}
            accessible
            accessibilityRole="image"
          />
          <View style={styles.sessionContainer}>
            <SproglandUserView
              getBookedSessions={loadBookedSessions}
              bookedSessions={bookedSessions}
              bookSession={book}
            />
          </View>
        </View>
      </MainContainer>
    </Portal.Host>
  )
}

const styles = StyleSheet.create({
  sessionContainer: { alignSelf: 'stretch', marginBottom: 20 },
  widthHeight: {
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  bannerImage: {
    alignSelf: 'center',
    width: width,
    height: 125,
    marginVertical: 24,
  },
})
