import React from 'react'
import { View, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import { CustomText } from '../../text/StyledText'
import { ImageHighlight } from '../../images/ImageHighlight'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../pressables/CustomPressable"

export default function PostItem({ item, onPressAction, rightElement, universe = null, isFaded = false }) {

    return (
      <CustomPressable
        accessible
        accessibilityRole="button"
        accessibilityLabel={item.title}
        accessibilityHint={txt('home.postHint')}
        activeOpacity={0.8}
        onPress={onPressAction}
      >
        <View style={[styles.container, isFaded && { opacity: 0.4 }]}>
          <View>
            <ImageHighlight
              source={{ uri: Paths.imageUrlBase + item.img_filename }}
              borderColor={universe ? universe?.boblHighlightColor ?? Colors.lightBlue : Colors.lightBlue}
            />
          </View>
          <View style={styles.postContainer}>
            <CustomText font="smallBold" style={styles.title} numberOfLines={2}>
              {item.title}
            </CustomText>
            <CustomText
              font={'small'}
              numberOfLines={3}
              style={styles.body}
            >
              {item.body.replace('\n\n','\n')}
            </CustomText>
          </View>
          {/* rightElement is used for myPosts */}
          {rightElement ?? null}
        </View>
      </CustomPressable>
    )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginHorizontal: 4,
  },
  postContainer: {
    alignSelf: 'center',
    flex: 1,
    overflow: 'hidden',
    paddingLeft: 12,
  },
  body: {
    flexWrap: 'nowrap',
    width: '100%',
    color: Colors.darkGrey,
    lineHeight: 20,
  },
  title: {
    flexWrap: 'wrap',
    lineHeight: 20,
  },
})
