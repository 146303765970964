import { Platform, StyleSheet } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import { txt } from '../../../locales/i18n'
import { ModalSelectorCustomProfile } from '../../modalSelectors/ModalSelector'
import React, { useRef } from 'react'
import Colors from '../../../constants/Colors'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import CustomPressable from "../../pressables/CustomPressable"

export default function UniversePicker({universeList, universeId, setUniverseId, ...props}) {
  const pickerRef = useRef()
  const getUniverseTitle = (id) => {
    return universeList.find((uni) => uni.id === id)?.title
  }

  if (Platform.OS === 'web') {
    return (
      <Picker
        accessibilityLabel={txt('settings.changeStartscreen')}
        selectedValue={universeId}
        onValueChange={(id) => setUniverseId(parseInt(id))}
        style={props.style}
      >
        {universeList.map((item) => {
          return (
            <Picker.Item label={item.title} value={item.id} key={item.id} />
          )
        })}
      </Picker>
    )
  } else {
    return (
      <CustomPressable style={styles.container} onPress={pickerRef.current?.open}>
        <ModalSelectorCustomProfile
          ref={pickerRef}
          style={{ marginLeft: -10, flex: 1 }}
          touchableStyle={{ paddingVertical: 3 }}
          data={universeList}
          keyExtractor={(data) => data.id?.toString()}
          labelExtractor={(data) => data.title}
          initValue={getUniverseTitle(universeId)}
          onChange={(data) => setUniverseId(data.id)}
        />
        <NormalIcon
          style={{ color: Colors.text, paddingVertical: 4 }}
          name="chevron-down"
          size={Fontsizes.m}
        />
      </CustomPressable>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
