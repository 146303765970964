import { CustomText } from '../../text/StyledText'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../dialogs/CustomDialog'
import React from 'react'
import { txt } from '../../../locales/i18n'

const ConfirmLeaveDialog = (props) => (
  <CustomDialog visible={props.visible}>
    <ContentContainer>
      <CustomText>{txt('conversations.Leave.body')}</CustomText>
    </ContentContainer>
    <ActionButton onPress={props.confirm} danger>
      {txt('conversations.Leave.confirm')}
    </ActionButton>
    <ActionButton onPress={props.cancel}>
      {txt('conversations.Leave.cancel')}
    </ActionButton>
  </CustomDialog>
)

export default ConfirmLeaveDialog
