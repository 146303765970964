import React, { useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../../components/text/StyledText'
import { useRoute } from '@react-navigation/core'
import { getUniverseConfig } from '../../../universe/universeController'
import MainContainer from '../../../components/MainContainer/MainContainer'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function BSFIntro(props) {
  const route = useRoute()
  let universeName = route.params?.universe?.toLowerCase()
  const universeObject = getUniverseConfig(universeName)

  useEffect(() => {
    props.navigation.setOptions({
      title: universeObject?.pageTitle,
      headerTitle: '',
      headerBackTitle: txt('universe.neighborIntro.headerBackTitle'),
    })
  }, [])

  const handleGetStartedClick = () => {
    props.joinUniverse(universeName)
  }

  return (
    <MainContainer backgroundColor={Colors.background}>
      <View style={styles.container}>
        <View style={styles.marginLeftRight}>
          <CustomText font="largeBold" style={styles.title}>
            {txt('universe.kab.title')}
          </CustomText>
          <CustomText style={styles.subtitle}>
            {txt('universe.kab.subtitle') + txt('universe.kab.bsf')}
          </CustomText>

          <CustomText
            font="bold"
            style={[styles.textAlign, styles.marginBottom6]}
          >
            {txt('universe.kab.header')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.kab.example1')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.kab.example2')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.kab.example3')}
          </CustomText>
          <CustomText style={styles.textAlign}>
            {txt('universe.kab.example4')}
          </CustomText>

          <CustomPressable
            onPress={handleGetStartedClick}
            style={styles.btn}
            accessible
            accessibilityRole="button"
            accessibilityLabel={txt('universe.aauucn.signupBtn')}
          >
            <CustomText font="largeBold" style={styles.btnText}>
              {txt('universe.aauucn.signupBtn')}
            </CustomText>
          </CustomPressable>

          <CustomText style={[styles.textAlign, styles.marginBottom30]}>
            {txt('universe.kab.endText')}
          </CustomText>

          <View style={styles.box}>
            <Image
              source={
                'https://filer.boblberg.dk/assets/img/universe/bsf_logo.png'
              }
              resizeMode="contain"
              style={styles.image}
              accessibilityIgnoresInvertColors
              accessible
              accessibilityRole="image"
              accessibilityLabel={txt('universe.bsf.headerTitle')}
            />
          </View>
        </View>
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  box: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 30,
    maxWidth: 400,
    width: '100%',
  },
  btn: {
    alignSelf: 'center',
    backgroundColor: Colors.blue,
    borderRadius: 10,
    color: Colors.white,
    marginVertical: 20,
    padding: 12,
  },
  btnText: {
    color: Colors.white,
  },
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
  },
  image: {
    alignSelf: 'center',
    height: 80,
    marginBottom: 5,
    width: 250,
  },
  marginBottom30: { marginBottom: 30 },
  marginBottom6: { marginBottom: 6 },
  marginLeftRight: { marginHorizontal: 12 },
  subtitle: { marginBottom: 12, textAlign: 'center' },
  textAlign: { textAlign: 'center' },
  title: { marginBottom: 12, marginTop: 12, textAlign: 'center' },
})
