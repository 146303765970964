import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import React from 'react'

const GroupDialog = ({ visible, title, description, accept, acceptText, cancel }) => (
  <CustomDialog visible={visible}>
    <ContentContainer>
      <CustomText font="bold">
        {title}
      </CustomText>
      {description && (<CustomText>{description}</CustomText>)}
    </ContentContainer>
    <ActionButton
      onPress={accept}
      hitSlop={{ top: 20, bottom: 20, left: 30, right: 30 }}
      style={{ padding: 20 }}
    >
      {acceptText || txt('groups.members.yes')}
    </ActionButton>
    <ActionButton
      onPress={cancel}
      hitSlop={{ top: 20, bottom: 20, left: 30, right: 30 }}
      style={{ padding: 20 }}
    >
      {txt('groups.members.cancel')}
    </ActionButton>
  </CustomDialog>
)

export default GroupDialog