import Fontsizes from '../constants/Fontsizes'
import Colors from '../constants/Colors'

// Normal
export const text = {
  fontFamily: 'raleway-regular',
  fontSize: Fontsizes.m,
}

export const textBold = {
  fontFamily: 'raleway-semibold',
  fontSize: Fontsizes.m,
}

export const textItalic = {
  fontFamily: 'raleway-italic',
  fontSize: Fontsizes.m,
}

// XSmall
export const textXSmall = {
  fontFamily: 'raleway-regular',
  fontSize: Fontsizes.xs,
}

export const textXSmallBold = {
  fontFamily: 'raleway-semibold',
  fontSize: Fontsizes.xs,
}

export const textXSmallItalic = {
  fontFamily: 'raleway-italic',
  fontSize: Fontsizes.xs,
}

// Small
export const textSmall = {
  fontFamily: 'raleway-regular',
  fontSize: Fontsizes.s,
}

export const textSmallBold = {
  fontFamily: 'raleway-semibold',
  fontSize: Fontsizes.s,
}

export const textSmallBoldHeavy = {
  fontFamily: 'raleway-bold',
  fontSize: Fontsizes.s,
}

export const textSmallItalic = {
  fontFamily: 'raleway-italic',
  fontSize: Fontsizes.s,
}

// Large
export const textLarge = {
  fontFamily: 'raleway-regular',
  fontSize: Fontsizes.l,
}

export const textLargeBold = {
  fontFamily: 'raleway-semibold',
  fontSize: Fontsizes.l,
}

export const textLargeItalic = {
  fontFamily: 'raleway-italic',
  fontSize: Fontsizes.l,
}

// X Large
export const textXLarge = {
  fontFamily: 'raleway-regular',
  fontSize: Fontsizes.xl,
}

export const textXLargeBold = {
  fontFamily: 'raleway-semibold',
  fontSize: Fontsizes.xl,
}

export const textXLargeItalic = {
  fontFamily: 'raleway-italic',
  fontSize: Fontsizes.xl,
}

// Title - Let's remove 'titles' at some point, since it's the same as 'large'
export const title = {
  fontFamily: 'raleway-regular',
  fontSize: Fontsizes.l,
}

export const titleBold = {
  fontFamily: 'raleway-semibold',
  fontSize: Fontsizes.l,
}

// For large amounts of text, like a blog.
export const blogTitle = {
  fontSize: 25,
  fontFamily: 'raleway-bold',
  color: Colors.text,
}
export const blogHeader = {
  fontSize: 20,
  fontFamily: 'raleway-bold',
  color: Colors.text,
}
export const blogSubtitle = {
  fontSize: 18,
  fontFamily: 'raleway-semibold',
  color: Colors.text,
}
export const blogText = {
  fontSize: 16,
  lineHeight: 22,
  fontFamily: 'raleway-regular',
  color: Colors.text,
}

//Heading styles (used in Julevenner)
export const heading1 = {
  fontFamily: 'oswald-semibold',
  fontSize: Fontsizes.xxl,
  paddingBottom: Fontsizes.xxl * 0.25,
}

export const heading2 = {
  fontFamily: 'oswald-semibold',
  fontSize: Fontsizes.xl,
  paddingBottom: Fontsizes.xl * 0.25,
}

export const heading3 = {
  fontFamily: 'oswald-semibold',
  fontSize: Fontsizes.l,
  paddingBottom: Fontsizes.l * 0.25,
}

export const heading4 = {
  fontFamily: 'oswald-bold',
  fontSize: Fontsizes.m,
  paddingBottom: Fontsizes.m * 0.25,
}
