import React from 'react'
import InboxScreen from '../../../../screens/Conversation/InboxScreen'
import { useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'
import Colors from '../../../../constants/Colors'

export default function Messages() {
  const groups = useSelector(state => state.groups)
  return (
    <InboxScreen navigation={useNavigation()} groups={groups} color={Colors.redCrossBackground} />
  )
}