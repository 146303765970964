import { paymentClick, getHasFeatureOne } from '../services/api/User'
import { Platform } from 'react-native'
import Cookies from 'js-cookie'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'

import Paths from '../constants/Paths'
import store from '../redux/store'

import { jotaiStore, deepLinkingStatusAtom } from '../atoms'
import { getRegion } from '../services/localization'
import * as TrackingService from '../services/api/Tracking'

export const handlePaymentClick = async ({ origin, post = null, profile = false }) => {
	if (profile === false) paymentClick()
	const url = new URL(Paths.getBaseUrl() + 'abonnement/oversigt')

	const userId = store.getState().user?.id
	const hasFeatureOne = await getHasFeatureOne(userId)

	if (hasFeatureOne === false) {
		const tracking = await TrackingService.createSubscriptionTracking(origin)
		if (tracking.id) url.searchParams.append('tid', tracking.id)
		if (tracking.trackingSpan) url.searchParams.append('tspan', tracking.trackingSpan)
	}

	if (Platform.OS === 'web') {
		if (post) {
			const config = { expires: 1 }
			if (!__DEV__) config.domain = 'boblberg.' + getRegion()
			Cookies.set('subscription-bobl', post?.id, config)
		}

		return window.open(url, '_self')
	}

	jotaiStore.set(deepLinkingStatusAtom, 'pending')

	const appDeeplink = AuthSession.makeRedirectUri()
	url.searchParams.append('appDeeplink', appDeeplink)
	await Linking.openURL(url.href)
}
