import React, { useEffect, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import * as Linking from 'expo-linking'

import { Map } from '../../maps/map'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { CornerStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'

const getCafeLocations = () => [
	{
		lat: 56.7239828600836, 
		lng: 10.13485626898360,
		details: {
			department: 'Hadsund',
			address: 'Hadsund skole, Stadionvej 5, 9560 Hadsund',
			audience: txt('learnDanish.cafe.audienceTypes.children'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.71877312238853,
		lng: 10.117750053332717,
		details: {
			department: 'Hadsund',
			address: 'Mariagerfjord Frivilligcenter, Kirkegade 4, 9560 Hadsund',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.95353738648944,
		lng: 8.685675455188836,
		details: {
			department: 'Thisted',
			address: 'Kongensgade 10, 7700 Thisted',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 57.32127006315328,
		lng: 10.511834646252893,
		details: {
			department: 'Sæby',
			address: 'Sæby Skole',
			audience: txt('learnDanish.cafe.audienceTypes.students at Saeby School'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 57.588827934222394,
		lng: 9.965265679102211,
		details: {
			department: 'Hirtshals',
			address: 'Hirtshals Skole',
			audience: txt('learnDanish.cafe.audienceTypes.students at Hirtshals School'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 57.26944594519252,
		lng: 9.94894259141664,
		details: {
			department: 'Brønderslev',
			address: 'Aktivitetscenter Grønningen / Bien, Bredgade 55, 9700 Brønderslev',
			audience: txt('learnDanish.cafe.audienceTypes.adult ukrainians'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.09037401149546,
		lng: 8.247055853300655,
		details: {
			department: 'Ringkøbing',
			address: 'Østergade 22, 6950 Ringkøbing',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.46139359922162,
		lng: 10.028851370503231,
		details: {
			department: 'Randers',
			address: 'Sprogcenter Randers, Vestergade 34, 8900 Randers C',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.3744110220317,
		lng: 9.658061010984843,
		details: {
			department: 'Bjerringbro',
			address: 'Byens Hus, Banegårdspladsen, 8850 Bjerringbro',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.452617392827584,
		lng: 9.408335055163231,
		details: {
			department: 'Viborg',
			address: 'De Frivilliges Hus, Ll. Sankt Hans Gade 7, 8800 Viborg',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.142131476388435,
		lng: 9.153291655147475,
		details: {
			department: 'Ikast',
			address: 'Ikast Bibliotek, Grønnegade 25, 7430 Ikast',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.413994859100534,
		lng: 10.889036055161258,
		details: {
			department: 'Nørre-Djurs',
			address: 'Østerbrogade 24, 8500 Grenaa',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.26343415133499,
		lng: 10.061290897483898,
		details: {
			department: 'Hinnerup',
			address: 'Jernbanegade 1, 1. sal, 8382 Hinnerup',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},
	
	{
		lat: 56.24930071254426,
		lng: 9.86584522663215,
		details: {
			department: 'Hammel',
			address: 'Søndervangsvej 3, 8450 Hammel',
			audience: txt('learnDanish.cafe.audienceTypes.children'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 56.165805656803215, 
		lng: 10.13280629983559,
		details: {
			department: 'Aarhus',
			address: 'Edwin Rahrs Vej 32P, 8220 Brabrand',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.97089852934103,
		lng: 10.15392755329458,
		details: {
			department: 'Odder',
			address: 'Vita Park 61-65, I spisestuen, 8300 Odder',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.223441043324215,
		lng: 10.126601583935953,
		details: {
			department: 'Haarby',
			address: 'Algade 16, 5683 Haarby',
			audience: txt('learnDanish.cafe.audienceTypes.children and women'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.45965120150974,
		lng: 9.875865326278083,
		details: {
			department: 'Nørre Aaby',
			address: 'Østergade 16, 5580 Nørre Åby',
			audience: txt('learnDanish.cafe.audienceTypes.children'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.045514491905735, 
		lng: 9.416282497738562,
		details: {
			department: 'Aabenraa',
			address: 'NygadeHuset, Nygade 23a, 6200 Aabenraa',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 54.91243181239918,
		lng: 9.79280006858089,
		details: {
			department: 'Sønderborg',
			address: 'Perlegade 50, 6400 Sønderborg',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.05302875784226,
		lng: 9.74538616636997,
		details: {
			department: 'Sønderborg',
			address: '6430 Nordborg - For adresse kontakt Røde Kors',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.76464931280692,
		lng: 8.918599041632955,
		details: {
			department: 'Grindsted',
			address: 'Nymarksvej 10, 7200 Grindsted',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.59888451595822,
		lng: 8.806603392591711,
		details: {
			department: 'Helle',
			address: 'Kærbjervej 7, 6753 Agerbæk',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.27455662202527,
		lng: 9.903384595589765,
		details: {
			department: 'Assens',
			address: 'Peter Willemoesskolen Skelvej 29, 5610 Assens',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 54.93649458963163,
		lng: 10.719108410912387,
		details: {
			department: 'Langeland',
			address: 'Fredensvej 1, 5900 Rudkøbing',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.70437499801564,
		lng: 9.53026083978576,
		details: {
			department: 'Vejle',
			address: 'Damhaven 3A, 7100 Vejle',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.40128530982247,
		lng: 10.385301739770567,
		details: {
			department: 'Odense',
			address: 'Røde Kors Netværkshuset, Østre Stationsvej 33, 5000 Odense C',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.41321439789164,
		lng: 10.438576510936247,
		details: {
			department: 'Odense',
			address: 'Bøgeparken 78, 5240 Odense NØ',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.34714627338616, 
		lng: 10.181120197752971,
		details: {
			department: 'Vissenbjerg',
			address: 'Tallerupvej 22, 5690 Tommerup',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.677923559932026,
		lng: 11.089005941628546,
		details: {
			department: 'Kalundborg',
			address: 'Banegårdspladsen 2, 4400 Kalundborg',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.40572626730304,
		lng: 11.352583268605636,
		details: {
			department: 'Slagelse',
			address: 'Jernbanegade 7, 4200 Slagelse',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.65532924842255,
		lng: 12.050009855122786,
		details: {
			department: 'Roskilde',
			address: 'Villa d’Este, Bistrup Alle 49, 4000 Roskilde',
			audience: txt('learnDanish.cafe.audienceTypes.children at Sankt Hans'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 54.77501379150119,
		lng: 11.4980487109043,
		details: {
			department: 'Midtlolland',
			address: 'Suhrsgade 4, 4930 Maribo',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.31271219387127,
		lng: 12.389573522582431,
		details: {
			department: 'Stevns',
			address: 'Kirketorvet 2, 4660 Store Heddinge',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 54.8318208257182,
		lng: 11.14173035508154,
		details: {
			department: 'Vestlolland',
			address: 'Øster Boulevard 1, 4900 Nakskov',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.01108144807274,
		lng: 11.90581597141818,
		details: {
			department: 'Vordingborg',
			address: 'Algade 43, 4760 Vordingborg',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.255828390586835,
		lng: 12.118233655102753,
		details: {
			department: 'Faxe',
			address: 'Rønnedevej 4, 4640 Faxe',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.120510202598595,
		lng: 12.03859686859129,
		details: {
			department: 'Præstø',
			address: 'Priesesstræde 7, 4720 Præstø',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.21142047844547,
		lng: 11.766127509081912,
		details: {
			department: 'Næstved-Fuglebjerg',
			address: 'Parkvej 101, 4700 Næstved',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.17911532016813,
		lng: 11.975332066750079,
		details: {
			department: 'Næstved-Fuglebjerg',
			address: 'Korskildevej 2A, 4733 Tappernøje',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 54.77000285366457,
		lng: 11.869997999252874,
		details: {
			department: 'Nykøbing Falster/Sydfalster',
			address: 'Kongensgade 43, 4800 Nykøbing Falster',
			audience: txt('learnDanish.cafe.audienceTypes.children'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.72969440095002,
		lng: 12.359547812796324,
		details: {
			department: 'Ballerup',
			address: 'Banegårdspladsen 1, 2750 Ballerup',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.67005033482582,
		lng: 12.450712824444546,
		details: {
			department: 'Rødovre',
			address: 'Cafébiblioteket, Nørrekær 8, 2610 Rødovre',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.6139879401707,
		lng: 12.355971953276581,
		details: {
			department: 'Ishøj-Vallensbæk',
			address: 'Ishøj Store Torv, 2. sal lok. 23, 2635 Ishøj',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.456391912568456,
		lng: 12.179696366764018,
		details: {
			department: 'Køge',
			address: 'Vestergade 3C, 4600 Køge',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.48039732252041,
		lng: 12.17644441278378,
		details: {
			department: 'Køge',
			address: 'CID, Ølbycenter 53, 4600 Køge',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.868780251033165,
		lng: 12.346821041638204,
		details: {
			department: 'Allerød',
			address: 'Gl Lyngevej 18 A C, 3450 Lillerød',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.816167961233525,
		lng: 12.378602239791402,
		details: {
			department: 'Furesø',
			address: 'Paltholmterrasserne 1, 3520 Farum',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.9738465336253,
		lng: 12.018139083973741,
		details: {
			department: 'Halsnæs',
			address: 'Valseværksstræde 5, 3300 Frederiksværk',
			audience: txt('learnDanish.cafe.audienceTypes.children and adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.72547309919318,
		lng: 12.435558726606866,
		details: {
			department: 'Herlev',
			address: 'Herlev Bibliotek, Herlev Bygade 70, 2730 Herlev',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},

	{
		lat: 55.82728458773198,
		lng: 12.531455057392776,
		details: {
			department: 'Søllerød',
			address: 'Egebækvej 80, 2850 Nærum',
			audience: txt('learnDanish.cafe.audienceTypes.adults'),
			contact: 'integration@rodekors.dk',
		},
	},
]

export const CafeMap = () => {
	const [location, setLocation] = useState(null)
	const [locations, setLocations] = useState([])

	useEffect(() => {
		const locations = getCafeLocations().map((location, index) => ({ ...location, id: index }))
		const timeout = setTimeout(() => setLocations(locations), 100)
		return () => clearTimeout(timeout)
	}, [])

	return (
		<View>
			<CustomText font="bold" style={styles.title}>
				{txt('learnDanish.cafe.title')}
			</CustomText>
			<View style={styles.container}>
				<Map locations={locations} onMarkerPress={setLocation} onMapPress={() => setLocation(null)} noCluster />

				{location && (
					<View style={styles.location}>
						<CustomText font="bold">
							{txt('learnDanish.cafe.department')}: {location?.details?.department}
						</CustomText>
						<CustomText>{location?.details?.address}</CustomText>
						<CustomText>
							{txt('learnDanish.cafe.audience')}: {location?.details?.audience}
						</CustomText>

						{location?.details?.contact && (
							<Pressable
								onPress={() => Linking.openURL('mailto:' + location?.details?.contact)}
								accessibilityRole="link"
							>
								<CustomText style={styles.contact}>{location?.details?.contact}</CustomText>
							</Pressable>
						)}
					</View>
				)}
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: 10,
		minHeight: 420,
	},

	title: {
		marginTop: 12,
		textAlign: 'center',
	},

	location: {
		position: 'absolute',
		backgroundColor: Colors.white,
		padding: 16,
		margin: 16,
		bottom: 0,
		left: 0,
		right: 0,
		...CornerStyles.borderRadiusS,
	},

	contact: {
		color: Colors.blueAzure,
	},
})
