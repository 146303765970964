import { apiGet } from './ApiRequest'

export async function searchEvents({ search, sorting, plusMember = false }) {
  const urlQueryParams = {}

  if (search !== '') urlQueryParams.search = search
  if (sorting !== null) urlQueryParams.sorting = sorting
  if (plusMember === true) urlQueryParams.plusMember = true

  const urlQueryEntries = Object.entries(urlQueryParams)
  const urlQuery = urlQueryEntries.map(([key, value]) => key + '=' + value).join('&')

  const events = await apiGet('events?' + urlQuery)
  return events ?? []
}

export async function getEvent(eventId) {
  const event = await apiGet('events/' + eventId)
  return event ?? null
}
