import React, { useState } from 'react'
import { View } from 'react-native'
import { CustomText } from '../text/StyledText'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { StyleSheet } from 'react-native'
import { Image } from 'react-native'
import CustomPressable from "../pressables/CustomPressable"

export function CheckBox(props) {
  return (
    <CustomPressable
      style={[styles.container, props.style]}
      accessible
      accessibilityRole="checkbox"
      accessibilityLabel={props.text}
      onPress={props.onPress}
    >
      <NormalIcon
        name={props.checked ? "check-square" : "square"}
        color={Colors.tintColor}
        style={styles.iconMargin}
      />
      <CustomText style={props.textStyle}>{props.text}</CustomText>
    </CustomPressable>
  )
}

export function RadioButton(props) {
  return (
    <CustomPressable style={styles.container} onPress={props.onPress} accessibilityRole={'radio'}>
      <NormalIcon
        name="circle"
        stroke={props.checked ? "fas" : "fal"}
        color={props.color || Colors.tintColor}
        style={[styles.iconMargin, props.style]}
      />
      <CustomText>{props.text}</CustomText>
    </CustomPressable>
  )
}

export function RadioButtonOutlined(props) {
  return (
    <CustomPressable
      accessibilityRole={'radio'}
      onPress={props.onPress}
      style={[{ flexDirection: 'row', alignItems: 'center' }, props.style]}
      disabled={props.disabled || false}
      accessibilityLabel={props.label}
    >
      <View
        style={[
          styles.radioContainer,
          {
            borderWidth: props.border || 3,
            borderColor: props.disabled ? Colors.grey : props.color || Colors.black,
          },
        ]}>
        <NormalIcon
          name="circle"
          stroke="fas"
          size={props.size || 26}
          color={props.checked ? props.disabled ? Colors.grey : props.color || Colors.tintColor : Colors.transparent}
        />
      </View>
      <CustomText
        style={{ color: props.disabled ? Colors.grey : Colors.text, paddingLeft: 6 }}>{props.text}</CustomText>
    </CustomPressable>
  )
}

export function RadioButtons({ data, onSelect }) {
  const [userOption, setUserOption] = useState(null)
  const selectHandler = (item) => {
    onSelect(item)
    setUserOption(item.label)
  }
  return (
    <View>
      {data.map((item) => {
        return (
          <CustomPressable
            key={item.value}
            onPress={() => selectHandler(item)}
            style={{ flexDirection: 'row', alignItems: 'center', marginTop: 6 }}>
            <NormalIcon
              name="circle"
              stroke={item.label === userOption ? "fas" : "fal"}
              color={Colors.tintColor}
              style={styles.iconMargin}
            />
            <CustomText> {item.label}</CustomText>
          </CustomPressable>
        )
      })}
    </View>
  )
}

export function TouchableHoverImage(props) {
  return (
    <CustomPressable
      style={
        props.hover && !props.checked
          ? [
            styles.moreBtnBg,
            {
              backgroundColor: Colors.background
                ? Colors.background
                : Colors.white,
            },
          ]
          : props.checked && [styles.checked]
      }
      onPress={props.onPress}
      onMouseEnter={props.handleMouseEnter}
      onMouseLeave={props.handleMouseLeave}
      accessibilityRole="button"
      accessibilityLabel={props.accLabel}
    >
      <Image
        accessibilityIgnoresInvertColors
        resizeMode={'contain'}
        style={styles.image}
        {...props}
      />
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  checked: { backgroundColor: Colors.darkerSkyBlue, borderRadius: 10 },
  container: { alignItems: 'center', flexDirection: 'row' },
  iconMargin: { marginRight: 10 },
  image: { alignSelf: 'center', height: 75, margin: 16, width: 75 },
  moreBtnBg: { borderRadius: 10 },
  radioContainer: {
    padding: 3,
    borderColor: Colors.transparent,
    borderRadius: 30,
  },
})
