import { Linking, Platform, StyleSheet, View } from 'react-native'
import CreateSessions from '../CreateSessions'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import OwnBookedSessions from '../OwnBookedSessions'
import OwnNotBookedSessions from '../OwnNotBookedSessions'
import AllSessions from '../AllSessions'
import React, { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import {
  getAllSessions,
  getOwnBooked,
  getOwnNotBooked,
  getSnakSammenProfile,
	getWaitlist
} from '../../../../services/api/snakSammen'
import Colors from '../../../../constants/Colors'
import { SpacingStyles } from '../../../../styles'
import { useSelector } from 'react-redux'
import { ProfileDialog } from "../dialogs/ProfileDialog"
import WaitlistUsers from "../WaitlistUsers"
import Cookies from "js-cookie"
import AsyncStorage from "@react-native-async-storage/async-storage"

export default function MySessions() {
  const userInfo = useSelector((state) => state.user)

  const navigation = useNavigation()
  const [sessions, setSessions] = useState(null)
  const [ownBookedSessions, setOwnBookedSessions] = useState([])
  const [ownNotBookedSessions, setOwnNotBookedSessions] = useState([])
  const [waitlist, setWaitlist] = useState([])
  const [noDescriptionDialogVisible, setNoDescriptionDialogVisible] = useState(false)

  const ownUser = useSelector((state) => state.user)

  const getOwnBookedSessions = async () => {
    const data = await getOwnBooked()
    const sessions = data.map(function (val, index) {
      return {
        key: index,
        id: val.id,
        start_date: val.start_date,
        end_date: val.end_date,
        room_key: val.room_key,
        vonage_session_id: val.vonage_session_id,
        username: val.username,
        bookerId: val.booker_id,
        firstSession: Boolean(val.firstSession),
      }
    })
    setOwnBookedSessions(sessions)
  }

  useEffect(() => {
    openDialog()
  }, [])

  const openDialog = async () => {
    const snakSammenProfile = await getSnakSammenProfile()
    if (snakSammenProfile?.description?.length > 0) return

    let snakSammenProfileFlag
    if (Platform.OS === 'web') snakSammenProfileFlag = Cookies.get('hideSnakSammenProfileDialog')
    else snakSammenProfileFlag = await AsyncStorage.getItem('hideSnakSammenProfileDialog')

    const snakSammenProfileFlagDate = new Date(snakSammenProfileFlag)
    const laterSnakSammenProfileFlagDate = new Date(snakSammenProfileFlagDate)?.setDate(snakSammenProfileFlagDate.getDate() + 30)

    if (laterSnakSammenProfileFlagDate >= Date.now()) return

    setNoDescriptionDialogVisible(true)
  }

  async function handleDismissDialog() {
    if (Platform.OS !== 'web') {
      await AsyncStorage.setItem('hideSnakSammenProfileDialog', new Date().toISOString())
    } else {
      Cookies.set('hideSnakSammenProfileDialog', new Date().toISOString())
    }
    setNoDescriptionDialogVisible(false)
  }

  const getOwnNotBookedSessions = async () => {
    const data = await getOwnNotBooked()
    const res = data.map(function (val, index) {
      return {
        key: index,
        id: val.id,
        start_date: val.start_date,
        end_date: val.end_date,
        username: val.username,
      }
    })
    setOwnNotBookedSessions(res)
  }

  async function loadWaitlist() {
    const waitlistResult = await getWaitlist()
    setWaitlist(waitlistResult?.filter((user) => user.userId !== ownUser.id))
  }

  const navigateToConversation = (conversationId) => {
    navigation.navigate('Conversation', {
      id: conversationId,
    })
  }

  const navigateToNewConversation = (userId, sessionId) => {
    navigation.navigate('Conversation', {
      id: 'new',
      receiverId: userId,
      vsSessionId: sessionId,
    })
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await reloadAll()
    }, 60000)
    return () => clearInterval(interval)
  })

  const loadAllSessions = async () => getAllSessions().then(setSessions)

  useEffect(() => {
    loadAllSessions()
    getOwnBookedSessions()
    getOwnNotBookedSessions()
    loadWaitlist()
  }, [])

  async function reloadAll() {
    await loadAllSessions()
    await getOwnBookedSessions()
    await getOwnNotBookedSessions()
    await loadWaitlist()
  }

  return (
    <View style={styles.container}>
      <CreateSessions reloadAll={reloadAll} />
      <View style={styles.separator} />
      <OwnBookedSessions
        reloadAll={reloadAll}
        ownBookedSessions={ownBookedSessions}
        navigateToConversation={navigateToConversation}
        navigateToNewConversation={navigateToNewConversation}
      />
      <OwnNotBookedSessions reloadAll={reloadAll} ownNotBookedSessions={ownNotBookedSessions}/>
      <WaitlistUsers
        waitlist={waitlist}
        reloadAll={reloadAll}
        navigateToNewConversation={navigateToNewConversation}
        navigateToConversation={navigateToConversation}
      />
      <CustomText font="gotham-small" style={styles.support}>
        {txt('learnDanish.supportPhone')}
        <CustomText font="gotham-small" style={styles.phoneNumber} onPress={() => Linking.openURL('tel:81740740')}>
          {txt('learnDanish.supportPhoneNumber')}
        </CustomText>
      </CustomText>
      <View style={styles.separator} />
      <AllSessions sessions={sessions} service="snaksammen" />

      <ProfileDialog
        visible={noDescriptionDialogVisible}
        onDismiss={handleDismissDialog}
        phoneNumber={userInfo?.phone}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 1000,
    alignSelf: 'center',
    marginVertical: 12,
    backgroundColor: Colors.redCross.background,
  },
  support: {
    margin: 12,
  },
  phoneNumber: {
    color: Colors.tintColor,
  },
  separator: {
    marginBottom: 10,
    marginTop: 12,
    ...SpacingStyles.marginRightLeft10,
    opacity: 0.3,
    zIndex: -1,
  },
})
