import React from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import InfoOverview from '../../../components/views/Conversations/InfoOverview'

export default function ConversationInfoChristmasScreen(props) {

  const goToPost = (postId) => {
    props.navigation.navigate('app', {
      screen: 'HomeStack',
      initial: false,
      params: {
        screen: 'Post',
        params: { id: postId },
      },
    })
  }

  function goToChristmasPost(id) {
    props.navigation.navigate('ChristmasPost', { id })
  }

  function navigateToProfile(userId) {
    props.navigation.navigate('ProfileView', {
      id: userId,
    })
  }

  return (
    <MainContainer>
      <InfoOverview
        navigateToPost={goToPost}
        route={props.route}
        showChristmasFriend={true}
        navigateToChristmasPost={goToChristmasPost}
        navigateToProfile={navigateToProfile}
      />
    </MainContainer>
  )
}
