import React, { useEffect, useState, useRef } from "react";
import { View, Image, StyleSheet } from 'react-native'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Paths from '../../../constants/Paths'
import OptionsDialog from '../../dialogs/OptionsDialog'
import { Portal } from 'react-native-paper'
import { leaveConversation as leaveConversationApi } from '../../../services/api/Inbox'
import ConfirmLeaveDialog from './ConfirmLeaveDialog'
import store from '../../../redux/store'
import { removeConversation } from '../../../redux/actions'
import { NotificationBadge } from '../../icons/NotificationBadge'
import Fontsizes from '../../../constants/Fontsizes'
import OptionButton from '../../buttons/OptionButton'
import ImgHighlightChristmas from '../../../christmasFriends/components/ImgHighlightChristmas'
import CustomPressable from "../../pressables/CustomPressable"

export default function ConversationListItem(props) {
  const [showOptions, setShowOptions] = useState(false)
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false)
  const options = useRef([])

  useEffect(() => {
    setOptions()
  }, [props])

  function setOptions() {
    options.current = [
      {
        label: txt('conversations.options.see'),
        icon: "info-circle",
        action: () => {
          props.goToInformation()
          toggleShowOptions()
        },
      },
      {
        label: txt('conversations.options.leave'),
        icon: "sign-out",
        action: handleLeavePress,
        type: 'danger',
      },
    ]

    if (props.post_id || props.christmas_post_id) {
      options.current.unshift({
        label: txt('conversations.options.goto'),
        icon: "chevron-right",
        action: () => {
          props.post_id ? props.goToPost() : props.goToChristmasPost(props.christmas_post_id)
          toggleShowOptions()
        },
      })
    }
    if (props.group_id) {
      options.current.unshift({
        label: txt('conversations.options.gotoGroup'),
        icon: "chevron-right",
        action: () => {
          props.goToGroup(props.group_id)
          toggleShowOptions()
        },
      })
    }
  }

  const toggleShowOptions = () => {
    setShowOptions((prev) => !prev)
  }

  const leaveConversation = async () => {
    await leaveConversationApi(props.conversation_id)
    toggleShowLeaveConfirm()
    store.dispatch(removeConversation(props.conversation_id))
  }

  const toggleShowLeaveConfirm = () => {
    setShowLeaveConfirm((prev) => !prev)
  }

  const handleLeavePress = async () => {
    toggleShowOptions()
    toggleShowLeaveConfirm()
  }

  const announcementCheck = () => {
    const latestMsg = props.messages[0]
    if (latestMsg.user_id === props.user.id) {
      return txt(`conversations.ownAnnouncement.${latestMsg.announcement_id}`)
    } else {
      return `${latestMsg.username} ${txt(`conversations.announcement.${latestMsg.announcement_id}`)}`
    }
  }

    let imgSource
    if (props.post_id) {
      imgSource = { uri: Paths.imageUrlBase + props.image }
    } else if (props.group_id) {
      imgSource = { uri: Paths.groupUrlBase + props.image }
    } else if (props.sl_session_id) {
      imgSource = { uri: 'https://filer.boblberg.dk/Assets/Img/sprogland/sprogland_logo.jpg' }
    } else if (props.vs_session_id) {
      imgSource = { uri: 'https://filer.boblberg.dk/Assets/Img/snaksammen/snaksammen_logo.jpg' }
    } else {
      imgSource = require('../../../assets/images/bobl.png')
    }

    return (
      <CustomPressable
        accessibilityRole="button"
        accessibilityLabel={txt('conversations.inboxOverviewTitle') + props.title}
        accessibilityHint={txt('conversations.inboxOverviewTitle') + props.title}
        activeOpacity={0.8}
        onPress={props.onPressAction}
        style={styles.container}
      >
        <View>
          {props.christmas_post_id ? (
            <ImgHighlightChristmas type={props.type} imgFilename={props.imgFilename} size={'medium'}/>
          ) : (
            <Image source={imgSource} style={styles.image} accessibilityIgnoresInvertColors />
          )}
          {props.unread_messages > 0 && (
            <NotificationBadge number={props.unread_messages} style={styles.badge} />
          )}
        </View>

        <View style={styles.textContainer}>
          <CustomText font="smallBold" style={styles.textBold} numberOfLines={1}>
            {props.title}
          </CustomText>
          <CustomText font="small" numberOfLines={1} style={styles.text}>
            {props.messages?.[0]?.announcement_id ? announcementCheck() : props.body}
          </CustomText>
        </View>
        <OptionButton
          style={{ marginLeft: 6 }}
          onPress={toggleShowOptions}
          accessibilityRole="button"
          accessibilityLabel={txt('options.optionButton')}
          size={Fontsizes.l}
          stroke="fas"
        />

        <Portal>
          <OptionsDialog visible={showOptions} onDismiss={toggleShowOptions} options={options.current} />
          <ConfirmLeaveDialog
            visible={showLeaveConfirm}
            confirm={leaveConversation}
            cancel={toggleShowLeaveConfirm}
          />
        </Portal>
      </CustomPressable>
    )
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    right: -2,
    top: -2,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  image: {
    borderRadius: 40,
    height: 66,
    width: 66,
  },
  text: {
    color: Colors.inactive,
    width: '100%',
    lineHeight: 24,
  },
  textBold: {
    color: Colors.text,
  },
  textContainer: {
    marginLeft: 12,
    alignSelf: 'center',
    flex: 1,
    maxHeight: 100,
    overflow: 'hidden',
  },
})
