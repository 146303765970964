import React from 'react'
import { StyleSheet, ActivityIndicator } from 'react-native'
import Colors from '../../constants/Colors'

export default function LoadingSpinner({ style, ...props }) {
  return (
    <ActivityIndicator
      {...props}
      style={[styles.spinner, style]}
      color={Colors.tintColor}
    />
  )
}

const styles = StyleSheet.create({
  spinner: { marginBottom: 24, marginTop: 12 },
})
