import React, { useRef } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import { ModalSelectorCustomProfile } from '../../modalSelectors/ModalSelector'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import Fontsizes from '../../../constants/Fontsizes'
import CustomPressable from "../../pressables/CustomPressable"

export default function StartScreenPicker({
  onChange,
  data,
  initValue,
  accessibilityLabel,
  ...props
}) {
  const pickerRef = useRef()

  if (Platform.OS === 'web') {
    return (
      <Picker
        accessibilityLabel={accessibilityLabel}
        selectedValue={initValue}
        onValueChange={onChange}
        style={props.style}
      >
        {data.map((item, index) => (
          <Picker.Item label={item.label} value={item.value} key={index} />
        ))}
      </Picker>
    )
  } else {
    return (
      <CustomPressable style={styles.container}  onPress={pickerRef.current?.open}>
        <ModalSelectorCustomProfile
          ref={pickerRef}
          style={{ marginLeft: -10, flex: 1 }}
          touchableStyle={{ paddingVertical: 3 }}
          data={data}
          labelExtractor={(data) => data.label}
          keyExtractor={(data) => data.label}
          initValue={initValue}
          onChange={(data) => onChange(data.value)}
        />
        <NormalIcon
          style={{ color: Colors.text, paddingVertical: 4 }}
          name="chevron-down"
          size={Fontsizes.m}
        />
      </CustomPressable>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
