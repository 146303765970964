import React from 'react'
import Conversation from '../../../components/views/Conversations/Conversation'
import { createConversation } from '../../../services/api/Inbox'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'

export default function ConversationUniverseScreen(props) {
  const createNewConversation = async () => {
    const { boblId, groupId, postId, receiverId } = props.route.params
    return createConversation({
      boblId: parseInt(boblId),
      groupId: parseInt(groupId),
      postId: parseInt(postId),
      receiverId: parseInt(receiverId),
    })
  }

  const universe = props.route.params.universe?.toLowerCase()
  const universeObject = getUniverseConfig(universe)

  function navigateToInfo() {
    props.navigation.navigate('ConversationInfo', {
      id: props.route.params.id,
      universe,
    })
  }

  function navigateToProfile(userId) {
    props.navigation.navigate('ProfileView', {
      id: userId,
      universe,
    })
  }

  return (
    <MainContainer noScroll backgroundColor={universeObject?.backgroundColor}>
      <Conversation
        createNewConversation={createNewConversation}
        route={props.route}
        navigation={props.navigation}
        navigateToInfo={navigateToInfo}
        navigateToProfile={navigateToProfile}
      />
    </MainContainer>
  )
}
