import { Platform } from 'react-native'
import * as Localization from 'expo-localization'
import AsyncStorage from '@react-native-async-storage/async-storage'

import store from '../redux/store'

export const countries = [
  { name: 'Denmark', language: 'da', region: 'dk' },
  { name: 'Sweden', language: 'sv', region: 'se' },
  { name: 'Germany', language: 'de', region: 'de' },
  { name: 'Finland', language: 'fi' },
  { name: 'England', language: 'en' },
  { name: 'Ukraine', language: 'uk' },
  { name: 'Arabic', language: 'ar' },
]

////////// REGION //////////

function getDomainRegion() {
  const host = window.location.host

  const regionPattern = /boblberg\.(?<region>\w{2})\/?/
  const regionPatternResult = regionPattern.exec(host)

  const region = regionPatternResult?.groups?.region
  return region ?? null
}

function getUserRegion() {
  const user = store.getState()?.user ?? null
  if (user === null) return null

  const country = countries.find((country) => country?.name === user?.country)
  return country?.region ?? null
}

function getSystemRegion() {
  if (Platform.OS !== 'web') {
    const systemRegion = Localization?.region ?? null
    if (systemRegion === null) return null

    const country = countries.find((country) => {
      return country?.region === systemRegion?.toLowerCase()
    })

    return country?.region ?? null
  }

  const [locale = null] = Localization?.getLocales?.() ?? []
  const systemRegion = locale?.regionCode ?? null

  if (systemRegion === null) return null

  const country = countries.find((country) => {
    return country?.region === systemRegion?.toLowerCase()
  })

  return country?.region ?? null
}

export function getRegion() {
  if (Platform.OS === 'web') {
    const domainRegion = getDomainRegion()
    if (domainRegion !== null) return domainRegion
  }

  const userRegion = getUserRegion()
  if (userRegion !== null) return userRegion

  const systemRegion = getSystemRegion()
  if (systemRegion !== null) return systemRegion

  return 'dk' // fallback
}

////////// LANGUAGE //////////

function getUserLanguage() {
  const user = store.getState()?.user ?? null
  return user?.language ?? null
}

function getSystemLanguage() {
  if (Platform.OS !== 'web') {
    const [locale] = Localization.getLocales()
    const systemLanguage = locale?.languageCode ?? null

    const country = countries.find((country) => country.language === systemLanguage)
    return country?.language ?? null
  }

  const [locale = null] = Localization?.getLocales?.() ?? []
  const systemLanguage = locale?.languageCode ?? null

  if (systemLanguage === null) return null

  const country = countries.find((country) => country.language === systemLanguage)
  return country?.language ?? null
}

export function getDomainLanguage() {
  const domainRegion = getDomainRegion()
  if (domainRegion === null) return null

  const country = countries.find((country) => country.region === domainRegion)
  return country?.language ?? null
}

export async function getLanguage() {
  const userLanguage = getUserLanguage()
  if (userLanguage !== null) return userLanguage

  const storedLanguage = await AsyncStorage.getItem('language')
  if (storedLanguage !== null) return storedLanguage

  const systemLanguage = getSystemLanguage()
  if (systemLanguage !== null) return systemLanguage

  if (Platform.OS === 'web') {
    const domainLanguage = getDomainLanguage()
    if (domainLanguage !== null) return domainLanguage
  }

  return 'da' // fallback
}
