import React, { useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import { CustomText } from '../../../components/text/StyledText'
import { getUniverseConfig } from '../../../universe/universeController'
import { useRoute } from '@react-navigation/core'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { LanguagePicker } from '../../../components/formElements/LanguagePicker'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function AauucnIntro(props) {
  const route = useRoute()
  const universeName = route.params?.universe?.toLowerCase()
  const universeObject = getUniverseConfig(universeName)

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={{ uri: universeObject?.logo }}
          accessibilityLabel={universeObject?.logoLabel}
          accessibilityIgnoresInvertColors
          accessibilityRole="image"
          accessible
        />
      ),
      title: universeObject?.pageTitle,
      headerRight: () => <LanguagePicker />,
    })
  }, [])

  const handleGetStartedClick = () => {
    props.joinUniverse(universeName)
  }

  return (
    <MainContainer backgroundColor={Colors.background}>
      <View style={styles.container}>
        <View style={styles.marginLeftRight}>
          <CustomText font="largeBold" style={styles.title}>
            {txt('universe.aauucn.title')}
          </CustomText>
          <CustomText style={styles.subtitle}>{txt('universe.aauucn.subtitle')}</CustomText>

          <CustomText font="bold" style={[styles.textAlign, styles.marginBottom6]}>
            {txt('universe.aauucn.header')}
          </CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>{txt('universe.aauucn.example1')}</CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>{txt('universe.aauucn.example2')}</CustomText>
          <CustomText style={[styles.textAlign, styles.marginBottom6]}>{txt('universe.aauucn.example3')}</CustomText>
          <CustomText style={styles.textAlign}>{txt('universe.aauucn.example4')}</CustomText>

          <CustomPressable
            onPress={handleGetStartedClick}
            style={styles.btn}
            accessible
            accessibilityRole="button"
            accessibilityLabel={txt('universe.aauucn.signupBtn')}
          >
            <CustomText font="largeBold" style={styles.btnText}>
              {txt('universe.aauucn.signupBtn')}
            </CustomText>
          </CustomPressable>
          <CustomText style={[styles.textAlign, styles.marginBottom30]}>{txt('universe.aauucn.text1')}</CustomText>

          <View style={styles.box}>
            <Image
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/universe/aau_logo.png',
              }}
              resizeMode="contain"
              style={styles.image}
              accessibilityIgnoresInvertColors
              accessible
              accessibilityRole="image"
              accessibilityLabel={txt('universe.aauucn.aau')}
            />
            <Image
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/universe/ucn_logo.png',
              }}
              resizeMode="contain"
              style={styles.image}
              accessibilityIgnoresInvertColors
              accessible
              accessibilityRole="image"
              accessibilityLabel={txt('universe.aauucn.ucn')}
            />
          </View>
        </View>
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  box: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 30,
    maxWidth: 400,
    width: '100%',
  },
  btn: {
    alignSelf: 'center',
    backgroundColor: Colors.blue,
    borderRadius: 10,
    marginVertical: 20,
    padding: 12,
  },
  btnText: {
    color: Colors.white,
  },
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
  },
  image: {
    alignSelf: 'center',
    height: 80,
    marginBottom: 5,
    width: 80,
  },
  logo: { height: 32, width: 200 },
  marginBottom30: { marginBottom: 30 },
  marginBottom6: { marginBottom: 6 },
  marginLeftRight: { marginHorizontal: 12 },
  subtitle: { marginBottom: 12, textAlign: 'center' },
  textAlign: { textAlign: 'center' },
  title: { marginBottom: 12, marginTop: 12, textAlign: 'center' },
})
