import React, { useEffect, useState } from 'react'
import { selectUsersFromConversations } from '../../../redux/selectors'
import NormalIcon from '../../icons/NormalIcon'
import { FlatList, StyleSheet, ScrollView, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import CustomDialog from '../../dialogs/CustomDialog'
import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { useNavigation } from '@react-navigation/core'
import { txt } from '../../../locales/i18n'
import { useRoute } from '@react-navigation/native'
import CreateGroupConversationItem from './CreateGroupConversationItem'
import { LogBox } from 'react-native'
import CustomPressable from "../../pressables/CustomPressable"

export default function ConversationCreate(props) {
  const navigation = useNavigation()
  const route = useRoute()
  const users = useSelector(selectUsersFromConversations)
  const [showUserList, setShowUserList] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState(new Set())
  const [error, setError] = useState(false)

  useEffect(() => {
    // Because we use wrap a FlatList with a ScrollView
    LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
  })

  function selectUser(user) {
    setError(false)
    const updatedList = new Set(selectedUsers)
    if (updatedList.has(user.id)) {
      updatedList.delete(user.id)
    } else {
      updatedList.add(user.id)
    }
    setSelectedUsers(updatedList)
  }

  async function goToConversation() {
    const universe = route.params?.universe
    close()
    navigation.navigate('Conversation', {
      id: 'new',
      receiverIds: selectedUsers,
      universe: universe || null,
    })
  }

  function close() {
    setShowUserList(false)
    setSelectedUsers(new Set())
    setError(false)
  }

  function renderUser({ item }) {
    return (
      <CustomPressable
        onPress={() => selectUser(item)}
        key={item.id}
        style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 6 }}>
        <CustomText numberOfLines={1}>
          {item.username}
        </CustomText>
        <NormalIcon
          name={selectedUsers.has(item.id) ? "check-circle" : "circle"}
          size={20}
          style={styles.selectIcon}
          stroke={selectedUsers.has(item.id) ? 'fas' : 'fal'}
        />
      </CustomPressable>
    )
  }

  const handleOnPress = () => {
    if (Platform.OS === 'web') {
      window.plausible('createGroupConversation', {
        props: { source: props.card ? 'card' : 'icon' },
      })
    }
    setShowUserList(true)
  }

  return (
    <>
      {props.card ? (
        <CreateGroupConversationItem
          onPress={handleOnPress}
        />
      ) : (
        <CustomPressable onPress={handleOnPress} style={styles.button}>
          <NormalIcon name="edit" size={22} stroke="far" />
        </CustomPressable>
      )}
      <CustomDialog visible={showUserList} onDismiss={close} style={styles.dialog}>
        <CustomText font={'largeBold'} style={styles.header}>
          {txt('conversations.create.title')}
        </CustomText>
        {/* The ScrollView needs to be there for the FlatList to behave correctly */}
        {Platform.OS === 'web' ? (
          <ScrollView>
            <FlatList data={users} style={styles.list} renderItem={renderUser} />
          </ScrollView>
        ) : (
          <FlatList data={users} style={styles.list} renderItem={renderUser} />
        )}
        <ButtonPrimary
          title={txt('conversations.create.submit')}
          onPress={goToConversation}
          error={error}
          disabled={selectedUsers.size < 1}
          style={styles.submitButton}
          icon="edit"
          stroke={'far'}
        />
      </CustomDialog>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    padding: 6,
    marginHorizontal: 6,
  },
  dialog: {
    padding: 12,
    paddingTop: 18,
    minWidth: 300,
  },
  header: {
    marginBottom: 12,
    textAlign: 'center',
  },
  list: {
    flexGrow: 0,
  },
  selectIcon: {
    marginLeft: 24,
  },
  submitButton: {
    marginTop: 12,
    width: '100%',
  },
})
