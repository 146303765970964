import React from 'react'
import AboutUs from '../../components/views/About/AboutUs'
import MainContainer from '../../components/MainContainer/MainContainer'
export default class AboutUsScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <MainContainer>
        <AboutUs navigation={this.props.navigation} />
      </MainContainer>
    )
  }
}
