import React from 'react'
import { Dimensions } from 'react-native'
import { ImageBackground } from 'react-native'
import { View, StyleSheet } from 'react-native'
import MainContainer from '../../components/MainContainer/MainContainer'
import { CustomText } from '../../components/text/StyledText'
import { LoginForm } from '../../components/views/Auth/LoginForm'
import { txt } from '../../locales/i18n'

export default function LoginChristmasScreen() {
	const { width } = Dimensions.get('window')
	const ratio = width / 144.5 // original width of image (for image background)

	return (
		<MainContainer>
			<ImageBackground
				style={{
					height: 54.29 * ratio, //original height of image
					width: '100%',
				}}
				resizeMode="cover"
				accessibilityIgnoresInvertColors
				source={{ uri: 'https://filer.boblberg.dk/Assets/Img/julevenner/julevenner-banner.png' }}
				accessible
				accessibilityRole="image"
			>
				<View style={{ marginTop: '20%', alignItems: 'center' }}>
					<View accessible style={styles.textContainer}>
						<CustomText font="large" style={styles.title}>
							{txt('signupField.welcome')}
						</CustomText>
						<CustomText style={styles.text}>{txt('christmasFriends.login.instructions')}</CustomText>
					</View>

					<LoginForm />
				</View>
			</ImageBackground>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	text: { textAlign: 'center' },
	textContainer: { alignItems: 'center', marginBottom: 24, marginTop: 12 },
	title: { marginBottom: 10 },
})
