import { Platform } from 'react-native'

import { universes } from './universeConfig'
import { initialUrlAtom, jotaiStore } from '../atoms'

export function isUniverse(name) {
	return universes.some((universe) => universe.name === name?.toLowerCase())
}

export function getUniverseId(name) {
  const universe = universes.find(
    (universe) => universe.name === name?.toLowerCase()
  )
  return universe?.id
}

export function extractUniverseFromWeb() {
	if (Platform.OS !== 'web') return null

	const pathSegments = window.location.href.split('/')
	const universeIndex = pathSegments.indexOf('u') + 1

	if (universeIndex > 0 && pathSegments[universeIndex]) {
		return pathSegments[universeIndex].toLowerCase()
	}

	return null
}

export function getUniverseConfig(name) {
	const target = name || extractUniverseFromWeb()

	const universe = universes.find((universe) => {
		return universe.name === target?.toLowerCase()
	})

	return universe
}

export function getUniverseConfigById(id) {
  const universe = universes.find((universe) => universe.id === id)
  return universe
}

const getNameFromRoutePattern = (values, pattern) => {
	const json = JSON.stringify(values)
	const matches = json.match(pattern)

	const universeName = matches?.groups?.universeName ?? null
	return universeName?.toLowerCase() ?? null
}

const getNameFromInitialUrl = () => {
	const initialUrl = jotaiStore.get(initialUrlAtom)
	if (initialUrl === null) return null

	const universeNamePattern = /u\/(?<universeName>\w+)/
	const matches = initialUrl.match(universeNamePattern)

	const universeName = matches?.groups?.universeName ?? null
	return universeName ?? null
}

export const getName = (route) => {
	const routeUniversePattern = /"universe":"(?<universeName>\w+)/
	const universeNameRouteUniverse = getNameFromRoutePattern(route, routeUniversePattern)
	if (universeNameRouteUniverse !== null) return universeNameRouteUniverse

	const routePathPattern = /"path":"u\/(?<universeName>\w+)/
	const universeNameRoutePath = getNameFromRoutePattern(route, routePathPattern)
	if (universeNameRoutePath !== null) return universeNameRoutePath

	if (Platform.OS === 'web') {
		const url = window.location.pathname
		const universeNamePattern = /u\/(?<universeName>\w+)/
		const matches = url.match(universeNamePattern)

		const universeName = matches?.groups?.universeName ?? null
		if (universeName !== null) return universeName.toLowerCase()
	}

	const universeNameInitialUrl = getNameFromInitialUrl()
	if (universeNameInitialUrl !== null) return universeNameInitialUrl

	return null
}

export function getConfig(name) {
	const universe = universes.find((universe) => {
		return universe.name.toLowerCase() === name?.toLowerCase()
	})

	return universe ?? null
}