import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../components/dialogs/CustomDialog'
import { TouchableHoverImage } from '../../components/formElements/formElements'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import { isUniversitySet, setUniversity } from '../../services/api/Universe'
import store from '../../redux/store'

export function UniversityDialog() {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [aauChecked, setAauChecked] = useState(false)
  const [ucnChecked, setUcnChecked] = useState(false)
  const [hoverAau, setHoverAau] = useState(false)
  const [hoverUcn, setHoverUcn] = useState(false)

  useEffect(() => {
    const getIsUniversitySet = async () => {
      const universes = store.getState().user.universes
      const universeObject = universes.find((uni) => uni.id === 1)
      const universitySet = await isUniversitySet(universeObject.id)
      if (!universitySet) setDialogVisible(true)
    }

    getIsUniversitySet()
  }, [])

  const handleSelectUniversity = async () => {
    const university = aauChecked ? 'aau' : 'ucn'
    const universitySet = await setUniversityName(university)
    if (universitySet) setDialogVisible(false)
  }

  const handleMouseEnter = (university) => {
    university === 'aau' ? setHoverAau(true) : setHoverUcn(true)
  }

  const handleMouseLeave = (university) => {
    university === 'aau' ? setHoverAau(false) : setHoverUcn(false)
  }

  const setUniversityName = async (university) => {
    return await setUniversity(university)
  }

  return (
    <CustomDialog visible={dialogVisible}>
      <ContentContainer>
        <CustomText style={styles.dialogText}>
          {txt('universe.aauucn.dialogText')}
        </CustomText>
        <View style={styles.row}>
          <View style={styles.margin12}>
            <TouchableHoverImage
              onPress={() =>
                aauChecked
                  ? setAauChecked(false) + setUcnChecked(true)
                  : setAauChecked(true) + setUcnChecked(false)
              }
              onMouseEnter={() => handleMouseEnter('aau')}
              onMouseLeave={() => handleMouseLeave('aau')}
              hover={hoverAau}
              checked={aauChecked}
              accLabel={txt('universe.aauucn.aau')}
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/universe/aau_logo.png',
              }}
            />
          </View>
          <View style={styles.margin12}>
            <TouchableHoverImage
              onPress={() =>
                ucnChecked
                  ? setUcnChecked(false) + setAauChecked(true)
                  : setUcnChecked(true) + setAauChecked(false)
              }
              onMouseEnter={() => handleMouseEnter('ucn')}
              onMouseLeave={() => handleMouseLeave('ucn')}
              hover={hoverUcn}
              checked={ucnChecked}
              accLabel={txt('universe.aauucn.ucn')}
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/universe/ucn_logo.png',
              }}
            />
          </View>
        </View>
      </ContentContainer>
      <ActionButton
        // centerAlign
        onPress={aauChecked || ucnChecked ? handleSelectUniversity : null}
      >
        {txt('dialog.ok')}
      </ActionButton>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  dialogText: { alignSelf: 'center', marginBottom: 12 },
  margin12: { margin: 12 },
  row: { flexDirection: 'row' },
})
