import React, { useState } from 'react'
import { editMessage } from '../../../../services/api/Groups'
import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { Keyboard, View } from 'react-native'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import { CustomGroupInput } from '../../../text/StyledTextinputs'

export default function EditPostDialog({ groupId, post, updatePost, visible, dismiss }) {
  const [text, setText] = useState(post.body)

  async function saveChanges() {
    const wasEdited = await editMessage(groupId, post.id, text)
    if (wasEdited && wasEdited.status_code >= 200 && wasEdited.status_code <= 299) {
      updatePost(text)
    } else {
      updatePost(null)
    }
  }
  return (
    <CustomDialog onDismiss={Keyboard.dismiss} style={{ width: '90%' }} visible={visible}>
      <ContentContainer>
        <View style={{ marginBottom: 12 }}>
          {!post.parent_message_id ? (
            <CustomText>{txt('groups.bulletin.editParentMessageText')}</CustomText>
          ) : (
            <CustomText>{txt('groups.bulletin.editChildMessageText')}</CustomText>
          )}
        </View>
        <View>
          <CustomGroupInput
            accessibilityLabel={txt('groups.bulletin.accessibilityLabelEdit')}
            accessibilityHint={txt('groups.bulletin.accessibilityHintEdit')}
            value={text}
            // Save text in state
            onChangeText={(value) => setText(value)}
            height={120}
            noShadow
          />
        </View>
      </ContentContainer>
      <ActionButton onPress={saveChanges}>
        {txt('groups.bulletin.saveChanges')}
      </ActionButton>
      <ActionButton onPress={dismiss}>
        {txt('groups.members.cancel')}
      </ActionButton>
    </CustomDialog>
  )
}