import React from 'react'
import { connect } from 'react-redux'
import { StyleSheet } from 'react-native'
import { NormalSnackbar } from './Snackbar'
import layout from '../../constants/Layout'
import { setAnnouncement } from '../../redux/actions'

class AppAnnouncement extends React.Component {
  render() {
    return (
      <NormalSnackbar
        style={styles.container}
        duration={6000}
        visible={this.props.announcement}
        onDismiss={() => this.props.setAnnouncement('')}
        action={{
          label: 'OK',
          onPress: () => this.props.setAnnouncement(''),
        }}
      >
        {this.props.announcement}
      </NormalSnackbar>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginBottom: 60,
    maxWidth: layout.maxFeedWidth,
  },
})

const mapStateToProps = (state) => {
  return {
    announcement: state.announcement,
  }
}

export default connect(mapStateToProps, { setAnnouncement })(AppAnnouncement)
