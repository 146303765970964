import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { CustomText } from '../../../components/text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import NormalIcon from '../../../components/icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import store from '../../../redux/store'
import { setChristmasMatchDialogVisible } from '../../../redux/actions'
import { CarouselSingle } from '../../../components/carousels/CarouselPrimary'
import Layout from '../../../constants/Layout'
import { ScrollableDialog } from '../../../components/dialogs/CustomDialog'

const smallWeb = Platform.OS === 'web' && Layout.isSmallDevice

export default function AfterMatchDialog() {
  const myPost = useSelector((state) => state.christmas.post)
  const matchDialogVisible = useSelector(
    (state) => state.christmas.matchDialogVisible
  )

  let items
  if (myPost?.type === 'host') {
    items = [
      {
        title: txt('christmasFriends.afterMatch.introTitle'),
        body: txt('christmasFriends.afterMatch.hostIntro'),
        icon: "hat-santa",
        color: Colors.red,
      },
      {
        title: txt('christmasFriends.afterMatch.hostContactTitle'),
        body: txt('christmasFriends.afterMatch.hostContact'),
        icon: "phone-alt",
        color: Colors.blue,
      },
      {
        title: txt('christmasFriends.afterMatch.hostHelpTitle'),
        body: txt('christmasFriends.afterMatch.hostHelp'),
        icon: "bags-shopping",
        color: Colors.green,
      },
    ]
  } else {
    items = [
      {
        title: txt('christmasFriends.afterMatch.introTitle'),
        body: txt('christmasFriends.afterMatch.guestIntro'),
        icon: "hat-santa",
        color: Colors.red,
      },
      {
        title: txt('christmasFriends.afterMatch.guestHelpTitle'),
        body: txt('christmasFriends.afterMatch.guestHelp'),
        icon: "bags-shopping",
        color: Colors.blue,
      },
      {
        title: txt('christmasFriends.afterMatch.guestPresentTitle'),
        body: txt('christmasFriends.afterMatch.guestPresent'),
        icon: "gifts",
        color: Colors.green,
      },
    ]
  }

  function close() {
    store.dispatch(setChristmasMatchDialogVisible(false))
  }

  function renderItems(item) {
    return (
      <View style={styles.itemContainer}>
        <NormalIcon
          name={item.icon}
          color={item.color}
          size={100}
          style={{ marginTop: 12, marginBottom: 40 }}
          stroke="fad"
        />
        <View style={styles.textContainer}>
          <CustomText
            style={{
              fontFamily: 'oswald-bold',
              fontSize: smallWeb ? Fontsizes.l : Fontsizes.xl,
            }}
          >
            {item.title}
          </CustomText>
          <CustomText
            font={smallWeb ? 'small' : null}
            style={{ marginTop: 12 }}
          >
            {item.body}
          </CustomText>
        </View>
      </View>
    )
  }

  return (
    <ScrollableDialog
      visible={matchDialogVisible === true}
      onDismiss={close}
    >
      <CarouselSingle
        data={items}
        renderItem={({ item }) => renderItems(item)}
        pagination
        dismiss={close}
      />
    </ScrollableDialog>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    alignItems: 'center',
  },
  textContainer: {
    paddingHorizontal: 12,
  },
})
