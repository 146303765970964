import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'

import MainContainer from '../../components/MainContainer/MainContainer'
import { CustomText } from '../../components/text/StyledText'
import NormalIcon from '../../components/icons/NormalIcon'
import { dropShadow } from '../../styles/shadows'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'
import Heading from '../../components/text/Heading'

import Fontsizes from '../../constants/Fontsizes'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import { txt } from '../../locales/i18n'

export default function AfterSignupChristmasScreen() {
  const rkMatch = useSelector((state) => state.christmas?.post.rkMatch)
  const ownPost = useSelector((state) => state.christmas?.post)
  const navigation = useNavigation()

  function navigateToEditPost(){
    navigation.navigate('MyPostStack', {
      screen: 'ChristmasPostEdit',
      params: { id: ownPost.id, type: ownPost.type },
    })
  }
  
  const RenderItem = ({ title, body, reverse, icon, color }) => {
    const direction = reverse ? 'direction' : 'reverseDirection'
    return (
      <View style={[styles.itemContainer, styles[direction]]}>
        <View style={[styles.iconContainer, Layout.isSmallDevice ? {} : { flex: 1 }]}>
          <NormalIcon
            name={icon}
            color={color}
            size={Layout.isSmallDevice ? 150 : 200}
            style={{ marginVertical: 24 }}
            stroke="fad"
          />
        </View>
        <View style={Layout.isSmallDevice ? {} : { flex: 1 }}>
          <Heading level={1}>
            {title}
          </Heading>
          <CustomText>{body}</CustomText>
        </View>
      </View>
    )
  }

  return (
    <MainContainer>
      {rkMatch ? (
        <View style={styles.container}>
          <View style={styles.rkInfoContainer}>
            <RenderItem
              title={txt('christmasFriends.afterSignup.createdPostTitle')}
              body={txt('christmasFriends.afterSignup.rkCreatedPost')}
              icon="wreath"
              color={Colors.green}
            />
            <View style={styles.itemContainer}>
              <View style={[styles.rkSmallInfo, Layout.isSmallDevice ? {} : { flex: 1 }]}>
                <Heading level={4} style={{color: Colors.darkGrey, textAlign: 'center'}}>
                  {txt('christmasFriends.afterSignup.rkChangeChoiceTitle')}
                </Heading>
                <CustomText style={{ color: Colors.darkGrey, textAlign: 'center', paddingButton: 24 }} font={'xsmall'}>{txt('christmasFriends.afterSignup.rkChangeChoiceP')}</CustomText>
              </View>
              <ButtonPrimary titleStyle={{fontSize: Fontsizes.xs}} style={{backgroundColor: Colors.green}} onPress={() => navigateToEditPost()}>{txt('christmasFriends.afterSignup.editBtn')}</ButtonPrimary>
            </View>

          </View>
        </View>
      ) : (
        <View style={styles.container}>
          <RenderItem
            title={txt('christmasFriends.afterSignup.createdPostTitle')}
            body={txt('christmasFriends.afterSignup.createdPost')}
            icon="hat-santa"
            color={Colors.red}
          />

          <RenderItem
            title={txt('christmasFriends.afterSignup.sendMessageTitle')}
            body={txt('christmasFriends.afterSignup.sendMessage')}
            icon="paper-plane"
            color={Colors.blue}
            reverse
          />

          <RenderItem
            title={txt('christmasFriends.afterSignup.favoriteTitle')}
            body={txt('christmasFriends.afterSignup.favorite')}
            icon="star"
            color={Colors.julevenner.yellow}
          />

          <RenderItem
            title={txt('christmasFriends.afterSignup.editTitle')}
            body={txt('christmasFriends.afterSignup.edit')}
            icon="edit"
            color={Colors.grey}
            reverse
          />

          <RenderItem
            title={txt('christmasFriends.afterSignup.matchTitle')}
            body={txt('christmasFriends.afterSignup.match')}
            icon="tree-christmas"
            color={Colors.green}
          />
        </View>
      )}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: Layout.maxFeedWidth,
    alignSelf: 'center',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  iconContainer: {
    alignItems: 'center'
  },
  itemContainer: {
    alignItems: 'center',
    marginVertical: 24,
    maxWidth: '100%',
  },
  direction: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row'
  },
  reverseDirection: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row-reverse'
  },
  rkInfoContainer: {
    ...dropShadow,
    // alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 12,
    flex: 1,
    maxWidth: Layout.maxFeedWidth,
    marginTop: 12,
    marginBottom: 18,
    padding: 24,
  },
  rkSmallInfo: {
    alignItems: 'center',
    paddingBottom: 12,
    width: Layout.isSmallDevice ? '100%' : '75%'
  }
})
