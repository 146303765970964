import React, { useState } from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { CornerStyles } from '../../../styles'
import CustomPressable from "../../pressables/CustomPressable"

export default function PrintButton({ generatePDF }) {
  const [isRendering, setIsRendering] = useState(false)

  const handleOnPress = async () => {
    setIsRendering(true)
    await generatePDF()
    setIsRendering(false)
  }

  return (
    <View style={styles.buttonContainer}>
      <CustomPressable style={styles.button} onPress={handleOnPress}>
        {isRendering ? (
          <ActivityIndicator size="small" color={Colors.white} />
        ) : (
          <CustomText font="bold" style={styles.buttonText}>
            {txt('activities.favorites.print')}
          </CustomText>
        )}
      </CustomPressable>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 12,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.tintColor,
    ...CornerStyles.borderRadiusS,
    width: 100,
    height: 36,
  },
  buttonText: {
    color: Colors.white,
  },
})
