import { Keyboard, ScrollView, StyleSheet, TextInput, View } from "react-native"
import CustomDialog, { ContentContainer } from "./CustomDialog"
import CustomPressable from "../pressables/CustomPressable"
import NormalIcon from "../icons/NormalIcon"
import { CustomText } from "../text/StyledText"
import { txt } from "../../locales/i18n"
import { ButtonPrimary } from "../buttons/StyledButtons"
import React, { useEffect, useRef, useState } from "react"
import { getCountriesList, setUserCountry } from "../../services/api/LearnDanish"
import Colors from "../../constants/Colors"
import { TypographyStyles } from "../../styles"

export const SproglandCountryDialog = ({ countryDialogVisible, setCountryDialogVisible }) => {
	const [countries, setCountries] = useState([])
	const [suggestions, setSuggestions] = useState([])
	const [selectedCountry, setSelectedCountry] = useState('')
	const [selectedCountryCode, setSelectedCountryCode] = useState('')

	const timeout = useRef(null)

	useEffect(() => {
		getCountriesList().then(setCountries)
	}, []);

	function getCountrySuggestions(value) {
		if (value === '') return countries
		return countries.filter((country) => {
			return country?.countryName?.toLowerCase().includes(value.toLowerCase()) || country?.countryCode?.toLowerCase().includes(value.toLowerCase())
		})
	}

	async function handleSelectedCountryChange(value) {
		clearTimeout(timeout.current)
		setSelectedCountry(value)
		timeout.current = setTimeout(async () => {
			const suggestionList = getCountrySuggestions(value)
			setSuggestions(suggestionList)
		}, 800)
	}

	async function handleSuggestionClick(suggestion) {
		setSelectedCountry(suggestion.countryName)
		setSelectedCountryCode(suggestion.countryCode)
		setSuggestions([])
		Keyboard.dismiss()
	}

	async function handleClearSuggestion() {
		setSelectedCountry('')
		setSelectedCountryCode('')
		setSuggestions(countries)
	}

	function renderSuggestions() {
		return (
			<ScrollView style={styles.suggestionsContainer} keyboardShouldPersistTaps="handled">
				{suggestions?.map((suggestion, index) => {
					const borderWidth = index !== suggestions.length - 1 ? 1 : 0
					return (
						<CustomPressable
							style={[styles.suggestion, { borderBottomWidth: borderWidth }]}
							key={index.toString()}
							onPress={() => handleSuggestionClick(suggestion)}
						>
							<CustomText>{suggestion?.countryName}</CustomText>
							<CustomText style={{ color: Colors.blueAzure }} font="smallBold">{suggestion?.countryCode}</CustomText>
						</CustomPressable>
					)
				})}
			</ScrollView>
		)
	}

	async function handleSubmitCountry() {
		await setUserCountry(selectedCountryCode)
		setCountryDialogVisible(false)
	}

	return (
		<CustomDialog style={{ width: '90%' }} visible={countryDialogVisible} onDismiss={() => Keyboard.dismiss()}>
			<CustomPressable onPress={() => setCountryDialogVisible(false)} style={styles.icon}>
				<NormalIcon name="times" color={Colors.darkGrey} size={16} />
			</CustomPressable>

			<ContentContainer noScroll>
				<CustomText font="largeBold" style={styles.title}>
					{txt('learnDanish.user.countryDialog.title')}
				</CustomText>

				<View style={styles.inputContainer}>
					<NormalIcon name="search" color={Colors.textLight} size={14} style={{ paddingLeft: 10 }}/>
					<TextInput
						style={styles.input}
						placeholderTextColor={Colors.textLight}
						spellCheck={true}
						accessibilityLabel={txt('learnDanish.user.countryDialog.placeholder')}
						placeholder={txt('learnDanish.user.countryDialog.placeholder')}
						value={selectedCountry}
						onChangeText={(item) => handleSelectedCountryChange(item)}
						onFocus={() => {
							if (selectedCountry === '') setSuggestions(countries)
						}}
					/>
					{selectedCountry !== '' && (
						<CustomPressable onPress={handleClearSuggestion}>
							<NormalIcon
								name="times-circle"
								stroke="fas"
								color={Colors.blackTransparent}
								size={18}
								style={{ paddingRight: 10 }}
							/>
						</CustomPressable>
					)}
				</View>
				{suggestions?.length > 0 && renderSuggestions()}
			</ContentContainer>

			<ButtonPrimary disabled={selectedCountryCode.length === 0} onPress={handleSubmitCountry} style={styles.button}>
				{txt('learnDanish.user.countryDialog.submit')}
			</ButtonPrimary>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	title: {
		paddingVertical: 12,
		marginVertical: 18,
		textAlign: 'center',
	},
	inputContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderWidth: 1,
		borderColor: Colors.blackTransparent,
		borderRadius: 6,
	},
	input: {
		...TypographyStyles.text,
		padding: 10,
		flex: 1,
	},
	suggestion: {
		borderBottomColor: Colors.blackTransparent,
		paddingVertical: 12,
		paddingHorizontal: 6,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	suggestionsContainer: {
		width: '100%',
		maxHeight: 150,
	},
	icon: {
		position: 'absolute',
		right: 16,
		top: 14,
		padding: 6,
		backgroundColor: Colors.greyLightLight,
		borderRadius: 8,
		zIndex: 10,
	},
	button: {
		marginBottom: 24,
		borderRadius: 6,
		backgroundColor: Colors.blueAzure,
		paddingHorizontal: 24,
	}
})