import { Platform } from 'react-native'
import { Linking } from 'react-native'

export function openMap(address, postalCode) {
  address = `${address}, ${postalCode ? postalCode : ''}`
  const url = Platform.select({
    ios: `http://maps.apple.com/?q=${address}`,
    android: `geo:0,0?q=${address}`,
    web: `https://www.google.dk/maps/place/${address}`,
  })

  if (Platform.OS === 'web') window.open(url, '_blank')
  else Linking.openURL(url)
}
