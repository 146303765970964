import React from 'react'
import { View, Linking, StyleSheet, Image, Dimensions } from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import Colors from '../../../../constants/Colors'
import { ImageHighlight } from '../../../images/ImageHighlight'
import Fontsizes from '../../../../constants/Fontsizes'
import NormalIcon from '../../../icons/NormalIcon'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import { URLOpener } from '../../../../utils/URLOpener'
import PageTitle from '../../../tags/PageTitle'
import Layout from '../../../../constants/Layout'
import { ProjectStyles } from './ProjectStyles'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

export default class OPILDL extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={ProjectStyles.widthHeight}>
        <PageTitle title={TxtProjects.opildl.title[TxtProjects.lang]} />

        <View style={ProjectStyles.container}>
          <CustomText
            style={ProjectStyles.blogTitle}
            accessibilityRole="header"
            aria-level={1}
          >
            {TxtProjects.opildl.title[TxtProjects.lang]}
          </CustomText>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageEU}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/eu_logo_OPI_FAKTO.png',
            }}
          />
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.text1[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.text2[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.text3[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.text4[TxtProjects.lang]}
          </CustomText>

          <View style={ProjectStyles.containerRow}>
            <View>
              <CustomText style={ProjectStyles.blogText}>
                {'\u25CF '}
              </CustomText>
            </View>
            <View style={ProjectStyles.flex}>
              <CustomText style={ProjectStyles.bullit}>
                {TxtProjects.opildl.point1[TxtProjects.lang]}
              </CustomText>
            </View>
          </View>

          <View style={ProjectStyles.containerRow}>
            <View>
              <CustomText style={ProjectStyles.blogText}>
                {'\u25CF '}
              </CustomText>
            </View>
            <View style={ProjectStyles.flex}>
              <CustomText style={ProjectStyles.bullit}>
                {TxtProjects.opildl.point2[TxtProjects.lang]}
              </CustomText>
            </View>
          </View>

          <View style={ProjectStyles.containerRow}>
            <View>
              <CustomText style={ProjectStyles.blogText}>
                {'\u25CF '}
              </CustomText>
            </View>
            <View style={ProjectStyles.flex}>
              <CustomText style={ProjectStyles.bullitLast}>
                {TxtProjects.opildl.point3[TxtProjects.lang]}
              </CustomText>
            </View>
          </View>

          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.text5[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.text6[TxtProjects.lang]}
          </CustomText>

          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opildl.projectPeriod[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opildl.period[TxtProjects.lang]}
          </CustomText>

          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opildl.moreInfo[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.link}
            onPress={() =>
              URLOpener(
                'http://livingdesignlab.dk/4-innovationssamarbejder/demens/'
              )
            }
          >
            {TxtProjects.opildl.ldlLink[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opildl.projectResults[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.link}
            onPress={() =>
              URLOpener(
                'http://livingdesignlab.dk/4-innovationssamarbejder/demens/'
              )
            }
          >
            {TxtProjects.opildl.ldlLink[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opildl.projectResponsible[TxtProjects.lang]}
          </CustomText>
          {/* Anders */}
          <View style={ProjectStyles.employeeContainer}>
            <View>
              <ImageHighlight
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/employees/Anders.jpg',
                }}
              />
            </View>
            <View style={ProjectStyles.employeeText}>
              <CustomText font="bold">{txt('aboutUs.andersName')}</CustomText>

              <CustomPressable onPress={() => Linking.openURL('mailto:as@boblberg.dk')}>
                <View style={ProjectStyles.containerRow}>
                  <NormalIcon
                    accessibilityLabel={txt('aboutUs.emailIcon')}
                    name="envelope"
                    size={Fontsizes.l}
                    color={Colors.text}
                  />
                  <CustomText>{txt('aboutUs.andersMail')}</CustomText>
                </View>
              </CustomPressable>

              <CustomPressable onPress={() => Linking.openURL(`tel:${+4522250520}`)}>
                <View style={ProjectStyles.containerRow}>
                  <NormalIcon
                    accessibilityLabel={txt('aboutUs.phoneIcon')}
                    name="phone-alt"
                    size={Fontsizes.l}
                    color={Colors.text}
                  />
                  <CustomText>{txt('aboutUs.andersPhone')}</CustomText>
                </View>
              </CustomPressable>
            </View>
          </View>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageBK}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Logo_Billund.png',
            }}
          />
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageHK}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Logo_Haderslev.png',
            }}
          />
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageKK}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Logo_Kolding.png',
            }}
          />
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageSDU}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Logo_SDU.png',
            }}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  imageBK: {
    height: Layout.isSmallDevice ? width * 0.2 : 80,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.51 : 202,
  },
  imageEU: {
    height: Layout.isSmallDevice ? width * 0.25 : 85,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.75 : 250,
  },
  imageHK: {
    height: Layout.isSmallDevice ? width * 0.2 : 60,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.75 : 230,
  },
  imageKK: {
    height: Layout.isSmallDevice ? width * 0.2 : 85,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.5 : 210,
  },
  imageSDU: {
    height: Layout.isSmallDevice ? width * 0.2 : 60,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.75 : 220,
  },
})
