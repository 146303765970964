import React from 'react'
import { Text } from 'react-native'
import { TypographyStyles } from '../../styles'
import Colors from '../../constants/Colors'
import { Platform } from 'react-native'

export default function Heading({level, style, text, children}){
  return(
    <Text
      accessible
      accessibilityRole={'header'}
      aria-level={level}
      style={[{ color: Colors.text }, TypographyStyles['heading' + level], { userSelect: 'text' }, style]}
    >
      {text || children}
    </Text>
  )
}