import React from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import CreateActivity from '../../../components/views/Activities/CreateActivity'
import { txt } from '../../../locales/i18n'

export default function EditActivityUniverseScreen(props) {
  let universe = props.route.params?.universe?.toLowerCase()
  const universeObject = getUniverseConfig(
    props.route.params.universe?.toLowerCase()
  )

  function navigateToMyActivities() {
    props.navigation.navigate('UniverseActivityStack', {
      screen: 'Activities',
      initial: false,
      params: {
        chip: txt('activities.chipParams.mine'),
        universe: universe,
      },
    })
  }

  return (
    <MainContainer noScroll backgroundColor={universeObject?.backgroundColor}>
      <CreateActivity
        id={props.route.params.id}
        universeId={universeObject.id}
        date={props.route.params.date}
        navigateToMyActivities={navigateToMyActivities}
      />
    </MainContainer>
  )
}
