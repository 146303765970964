import { ActivityIndicator, Image, Linking, StyleSheet, View } from 'react-native'
import CreateSessions from '../CreateSessions'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import OwnBookedSessions from '../OwnBookedSessions'
import OwnNotBookedSessions from '../OwnNotBookedSessions'
import AllSessions from './AllSessions'
import React, { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import { getAllSessions, getOwnBooked, getOwnNotBooked } from '../../../../services/api/LearnDanish'
import Layout from '../../../../constants/Layout'
import Colors from '../../../../constants/Colors'
import { ShadowStyles, SpacingStyles, TypographyStyles } from '../../../../styles'
import PhoneInputForm from '../../../formElements/PhoneInputForm'
import { useSelector } from 'react-redux'
import NormalIcon from '../../../icons/NormalIcon'
import CustomPressable from "../../../pressables/CustomPressable"

export default function MySessions() {
  const navigation = useNavigation()
  const [sessions, setSessions] = useState(null)
  const [ownBookedSessions, setOwnBookedSessions] = useState([])
  const [ownNotBookedSessions, setOwnNotBookedSessions] = useState([])
  const [reloading, setReloading] = useState(false)
  const phoneNumber = useSelector((state) => state.user.phone)

  const getOwnBookedSessions = async () => {
    const data = await getOwnBooked()
    const res = data.map(function(val, index) {
      return {
        key: index,
        id: val.id,
        start_date: val.start_date,
        end_date: val.end_date,
        username: val.username,
        bookerId: val.booker_id,
        room_key: val.room_key,
        junior: val.junior,
        juniorName: val.juniorName,
        juniorAge: val.juniorAge,
        categories: val.categories,
        vonage_session_id: val.vonage_session_id,
        firstSession: Boolean(val.firstSession),
      }
    })
    setOwnBookedSessions(res)
  }

  const getOwnNotBookedSessions = async () => {
    const data = await getOwnNotBooked()
    const res = data.map(function(val, index) {
      return {
        key: index,
        id: val.id,
        start_date: val.start_date,
        end_date: val.end_date,
        username: val.username,
        junior: val.junior,
        categories: val.categories,
      }
    })
    setOwnNotBookedSessions(res)
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await reloadAll()
    }, 60000)
    return () => clearInterval(interval)
  })

  const loadAllSessions = async () => {
    getAllSessions().then(setSessions)
  }

  const navigateToConversation = (conversationId) => {
    navigation.navigate('Conversation', {
      id: conversationId,
    })
  }

  const navigateToNewConversation = (userId, sessionId) => {
    navigation.navigate('Conversation', {
      id: 'new',
      receiverId: userId,
      slSessionId: sessionId,
    })
  }

  useEffect(() => {
    loadAllSessions()
    getOwnNotBookedSessions()
    getOwnBookedSessions()
  }, [])

  const reloadAll = async () => {
    setReloading(true)
    await loadAllSessions()
    await getOwnBookedSessions()
    await getOwnNotBookedSessions()
    setTimeout(() => {
      setReloading(false)
    }, 400)
  }

  return (
    <View style={styles.container}>
      <View style={!Layout.isSmallDevice && styles.layout}>
        <View style={!Layout.isSmallDevice && styles.element}>
          <View>
            {(phoneNumber === 0 || !phoneNumber) && (
              <>
                <View style={{ maxWidth: 400, alignSelf: 'center' }}>
                  <PhoneInputForm buttonTitleStyle={{ ...TypographyStyles.textSmall }} />
                </View>
                <View style={styles.separator} />
              </>
            )}
            <View style={!Layout.isSmallDevice && { marginBottom: 20 }}>
              <CreateSessions reloadAll={reloadAll} />
            </View>
          </View>
          <CustomPressable onPress={reloadAll} style={styles.reload}>
            <CustomText style={{ marginRight: 6 }}>{txt('learnDanish.update')}</CustomText>
            {reloading ? <ActivityIndicator /> :
              <NormalIcon name="redo-alt" style={{ margin: 2 }} color={Colors.text} size={16} />}
          </CustomPressable>
          <View style={!Layout.isSmallDevice && { flexDirection: 'row', justifyContent: 'space-evenly', flex: 4 }}>
            <View style={!Layout.isSmallDevice && { flex: 2 }}>
              <OwnBookedSessions
                reloadAll={reloadAll}
                ownBookedSessions={ownBookedSessions}
                getOwnBookedSessions={getOwnBookedSessions}
                navigateToConversation={navigateToConversation}
                navigateToNewConversation={navigateToNewConversation}
              />
            </View>
            <View style={!Layout.isSmallDevice && { flex: 2 }}>
              <OwnNotBookedSessions
                reloadAll={reloadAll}
                ownNotBookedSessions={ownNotBookedSessions}
                getOwnNotBookedSessions={getOwnNotBookedSessions}
              />
            </View>
          </View>
          <View style={styles.separator} />
        </View>
      </View>
      <View style={!Layout.isSmallDevice && styles.layout}>
        <View style={!Layout.isSmallDevice && styles.element}>
          <View style={!Layout.isSmallDevice && { flexDirection: 'row', justifyContent: 'space-evenly', flex: 4 }}>
            <View style={!Layout.isSmallDevice && { flex: 2 }}>
              <AllSessions sessions={sessions} />
            </View>
            <View style={!Layout.isSmallDevice && { flex: 2 }}>
              <View style={{ margin: 10 }}>
                <CustomText font="largeBold" style={{ textAlign: 'center' }}>
                  {txt('learnDanish.volunteer.help')}
                </CustomText>
                <View style={styles.item}>
                  <CustomText font="small" style={{ marginTop: 6 }}>
                    {txt('learnDanish.supportPhone')}
                  </CustomText>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 12 }}>
                    <Image
                      style={{ width: 75, height: 75, borderRadius: 40 }}
                      resizeMode={'contain'}
                      accessibilityIgnoresInvertColors
                      source={{ uri: 'https://filer.boblberg.dk/Assets/Img/employees/Rosa.jpg' }}
                      accessible
                      accessibilityRole="image"
                    />
                    <View style={{ paddingLeft: 12 }}>
                      <CustomText font="bold">Rosa</CustomText>
                      <CustomPressable
                        style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 6 }}
                        onPress={() => Linking.openURL('mailto:support@sprogland.dk')}
                      >
                        <NormalIcon name="envelope" size={18} stroke="fal" />
                        <CustomText font="small" style={{ paddingLeft: 6 }}>support@sprogland.dk</CustomText>
                      </CustomPressable>
                      <CustomPressable style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 6 }}
                                        onPress={() => Linking.openURL('tel:81740740')}>
                        <NormalIcon name="phone-alt" size={18} stroke="fal" />
                        <CustomText font="small"
                                    style={{ paddingLeft: 6 }}>{txt('learnDanish.supportPhoneNumber')}</CustomText>
                      </CustomPressable>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center',
    marginVertical: 12,
  },
  layout: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  element: {
    width: '100%',
    minWidth: 500,
    maxWidth: 900,
  },
  separator: {
    marginBottom: 10,
    marginTop: 20,
    ...SpacingStyles.marginRightLeft10,
    opacity: 0.3,
    zIndex: -1,
  },
  reload: {
    alignSelf: 'flex-end',
    marginRight: 24,
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    backgroundColor: Colors.white,
    borderRadius: 2,
    marginTop: 10,
    padding: 10,
    ...ShadowStyles.liftedShadow,
  },
})
