import React from 'react'
import Line from '../../components/views/About/Blog/Line'
import Zuzan from '../../components/views/About/Blog/Zuzan'
import Morten from '../../components/views/About/Blog/Morten'
import Thomas from '../../components/views/About/Blog/Thomas'
import Conny from '../../components/views/About/Blog/Conny'
import Emma from '../../components/views/About/Blog/Emma'
import Rikke from '../../components/views/About/Blog/Rikke'
import Nicolaj1 from '../../components/views/About/Blog/Nicolaj-1'
import Nicolaj2 from '../../components/views/About/Blog/Nicolaj-2'
import Finn from '../../components/views/About/Blog/Finn'
import Anna from '../../components/views/About/Blog/Anna'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class BlogScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  checkSwitch = (param) => {
    switch (param) {
      case 'Line':
        return this.Line()
      case 'Zuzan':
        return this.Zuzan()
      case 'Morten':
        return this.Morten()
      case 'Thomas':
        return this.Thomas()
      case 'Conny':
        return this.Conny()
      case 'Emma':
        return this.Emma()
      case 'Rikke':
        return this.Rikke()
      case 'Nicolaj1':
        return this.Nicolaj1()
      case 'Nicolaj2':
        return this.Nicolaj2()
      case 'Finn':
        return this.Finn()
      case 'Anna':
        return this.Anna()
      default:
        if (__DEV__) console.log('NUMBER NOT FOUND')
    }
  }

  Line = () => {
    return <Line />
  }
  Zuzan = () => {
    return <Zuzan />
  }
  Morten = () => {
    return <Morten />
  }
  Thomas = () => {
    return <Thomas />
  }
  Conny = () => {
    return <Conny />
  }
  Emma = () => {
    return <Emma />
  }
  Rikke = () => {
    return <Rikke />
  }
  Nicolaj1 = () => {
    return <Nicolaj1 />
  }
  Nicolaj2 = () => {
    return <Nicolaj2 />
  }
  Finn = () => {
    return <Finn />
  }
  Anna = () => {
    return <Anna />
  }

  render() {
    return (
      <MainContainer>
        {this.checkSwitch(this.props.route.params.id)}
      </MainContainer>
    )
  }
}
