import React from 'react'
import InboxOverview from '../../components/views/Conversations/InboxOverview'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useSelector } from 'react-redux'
import { useNavigation } from "@react-navigation/core"

export default function InboxScreen(props) {
  const groups = useSelector(state => state.groups)
  const navigation = useNavigation()
  const navigateToConversation = (conversationId) => {
    navigation.push('Conversation', {
      id: conversationId,
    })
  }

  const goToPost = (postId) => {
    navigation.navigate('HomeStack', {
      screen: 'Post',
      initial: false,
      params: { id: postId },
    })
  }

  const goToInformation = (conversationId) => {
    navigation.navigate('ConversationInfo', {
      id: conversationId,
    })
  }

  const goToChristmasPost = (id) => {
    navigation.navigate('christmasFriends', {
      screen: 'PostFeedStack',
      initial: false,
      params: {
        screen: 'ChristmasPost',
        params: { id },
      },
    })
  }

  const goToGroup = (id) => {
    navigation.navigate('app', {
      screen: groups?.length > 0 ? 'GroupsStack' : 'MenuStack',
      initial: false,
      params: {
        screen: 'Group',
        params: { id },
      },
    })
  }

  return (
    <MainContainer noScroll backgroundColor={props.color}>
      <PageTitle title={TxtTMD.inbox.title[TxtTMD.lang]} />
      <InboxOverview
        navigateToConversation={navigateToConversation}
        goToPost={goToPost}
        goToChristmasPost={goToChristmasPost}
        goToGroup={goToGroup}
        goToInformation={goToInformation}
      />
    </MainContainer>
  )
}

// export default function(props) {
//   const groups = useSelector(state => state.groups)
//   return <InboxScreen {...props} groups={groups}/>
// }
