import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import * as WebBrowser from 'expo-web-browser'

import { CustomText } from '../../text/StyledText'
import Colors from '../../../constants/Colors'
import NormalIcon from '../../icons/NormalIcon'
import { ScrollableDialog, ActionButton, ContentContainer } from '../../dialogs/CustomDialog'

import FontSizes from '../../../constants/Fontsizes'
import { txt } from '../../../locales/i18n'
import { getTempFileLink } from '../../../services/api/Groups'
import CustomPressable from "../../pressables/CustomPressable"

export function GroupPostFileLink({ id, name, groupId }) {
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  async function openFile() {
    const fileLink = await getTempFileLink(groupId, id)

    if (fileLink === null) {
      return setShowErrorDialog(true)
    }

    await WebBrowser.openBrowserAsync(fileLink)
  }

  return (
    <>
      <CustomPressable
        onPress={openFile}
        style={styles.container}
        accessibilityRole="button"
        accessibilityLabel={txt('groups.bulletin.downloadDocument')}
        accessibilityHint={txt('groups.bulletin.downloadDocumentHint')}
      >
        <NormalIcon name="paperclip" color={Colors.text} size={FontSizes.m} style={styles.icon} />

        <CustomText font="bold" style={styles.text} numberOfLines={1}>
          {name}
        </CustomText>
      </CustomPressable>

      <ScrollableDialog visible={showErrorDialog}>
        <ContentContainer>
          <CustomText font="bold">{txt('groups.fileLink.errorHeader')}</CustomText>

          <CustomText>{txt('groups.fileLink.errorBody')}</CustomText>
        </ContentContainer>

        <ActionButton onPress={() => setShowErrorDialog(false)}>{txt('global.ok')}</ActionButton>
      </ScrollableDialog>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 6,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },

  icon: {
    marginRight: 6,
  },

  text: {
    color: Colors.blue,
  },
})
