import * as Sentry from '@sentry/browser'
import Constants from 'expo-constants'

Sentry.init({
	dsn: 'https://4bed262282834f0195a49b3f3c62b6dd@o438798.ingest.sentry.io/5404170',
	release: Constants.expoConfig.version,
	ignoreErrors: [/^3000ms timeout exceeded$/, /Non-Error promise rejection/],
})

export const wrap = (app) => app
