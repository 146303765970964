import React, { useState, useEffect, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import DatePicker from '../dates/DatePicker'
import { CustomText } from '../text/StyledText'
import { ButtonPrimary, ButtonSecondary } from '../buttons/StyledButtons'
import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import moment from 'moment'
import LoadingSpinner from '../activityIndicators/LoadingSpinner'
import NormalIcon from '../icons/NormalIcon'
import { ShadowStyles } from '../../styles'
import CustomDialog, { ContentContainer } from '../dialogs/CustomDialog'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../pressables/CustomPressable"

export default function RecurringDateForm(props) {
  const [dates, setDates] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [maximumDate, setMaximumDate] = useState()
  const [errorMsg, setErrorMsg] = useState('')
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)
  const [showCreatedDates, setShowCreatedDates] = useState(props.editing)
  const [loading, setLoading] = useState(true)
  const selectedDate = useRef()

  const [days, setDays] = useState({
    mon: { value: 1, selected: false },
    tue: { value: 2, selected: false },
    wed: { value: 3, selected: false },
    thu: { value: 4, selected: false },
    fri: { value: 5, selected: false },
    sat: { value: 6, selected: false },
    sun: { value: 0, selected: false },
  })

  useEffect(() => {
    const date = new Date()
    date.setYear(date.getFullYear() + 6)
    setMaximumDate(date)
    moment.locale([txt('activities.locale')])
    setStartDate(props.date.start)
    setStartTime(roundUpTime(props.date.start))
    setEndTime(roundUpTime(props.date.end))
    setEndDate(new Date(props.dates[props.dates.length - 1].end))
    if (props.editing) {
      setDates(props.dates)
      setSelectedDays()
    } else {
      setDates([])
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (props.isVisible && dates?.length > 0) props.setDates(dates)
  }, [props.isVisible, dates])

  function setSelectedDays() {
    const weekdays = props.dates.map((date) => new Date(date.start).getDay())
    const selectedDays = Object.entries(days).reduce((acc, [key, _]) => {
      acc[key].selected = weekdays.includes(_.value)
      return acc
    }, days)
    setDays(selectedDays)
  }

  const roundUpTime = (date) => {
    if (props.editing) return date
    const coeff = 1000 * 60 * 5
    const roundedDate = Math.ceil(date / coeff) * coeff
    return new Date(roundedDate)
  }

  const handleStartDateChange = (date) => {
    if (isSameDay(date)) {
      setStartDate(new Date())
      setStartTime(roundUpTime(new Date()))
      setEndTime(roundUpTime(new Date()))
    } else {
      const newDate = new Date(date)
      newDate.setHours(0, 0)
      setStartDate(newDate)
    }
  }

  const handleStartTimeChange = (date) => {
    setStartTime(date)
    if (endTime < date) {
      setEndTime(date)
    }
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  const handleEndTimeChange = (date) => {
    setEndTime(date)
  }

  const isSameDay = (date) => {
    const newDate = new Date()
    return (
      newDate.getDate() === new Date(date).getDate() &&
      newDate.getMonth() === new Date(date).getMonth() &&
      newDate.getFullYear() === new Date(date).getFullYear()
    )
  }

  const updateDay = (key) => {
    setDays((previous) => {
      const previousDays = { ...previous }
      previousDays[key].selected = !previousDays[key].selected
      return previousDays
    })
  }

  async function validateInputs() {
    const hasSelectedDays = Object.entries(days).some(([_, day]) => day.selected)
    if (startTime.getHours() === endTime.getHours() && startTime.getMinutes() === endTime.getMinutes()) {
      setErrorMsg(txt('activities.create.recurringForm.timesAreEqual'))
      return false
    }
    if (!hasSelectedDays) {
      setErrorMsg(txt('activities.create.recurringForm.noDaysSelected'))
      return false
    }
    setErrorMsg('')
    return true
  }

  async function createDates() {
    const createdDates = []
    const isValid = await validateInputs()
    if (!isValid) return

    const selectedDays = Object.values(days).reduce((acc, target) => {
      if (target.selected) acc.push(target.value)
      return acc
    }, [])

    for (const target = new Date(startDate); target <= endDate; target.setDate(target.getDate() + 1)) {
      if (selectedDays.includes(target.getDay())) {
        const start = new Date(target)
        start.setHours(startTime.getHours(), startTime.getMinutes(), 0)

        const end = new Date(target)
        end.setHours(endTime.getHours(), endTime.getMinutes(), 0)
        createdDates.push({ start, end })
      }
    }
    if (createdDates.length === 0) {
      setErrorMsg(txt('activities.create.recurringForm.noDatesCreated'))
      return
    }
    setErrorMsg('')

    props.setDates(createdDates)
    setDates(createdDates)
  }

  function removeDate() {
    const filteredDates = dates.filter((date, i) => i !== selectedDate.current)
    if (filteredDates.length === 0) {
      props.setDates([{ start: new Date(), end: new Date() }])
    } else {
      props.setDates(filteredDates)
    }
    setDates(filteredDates)
    setShowRemoveDialog(false)
  }

  function renderDaySelector() {
    return Object.entries(days).map(([key, value]) => (
      <CustomPressable
        style={[styles.selectorDay, { backgroundColor: value.selected ? Colors.blue : Colors.grey }]}
        key={key}
        onPress={() => updateDay(key)}
      >
        <CustomText style={{ color: Colors.white }} font={value.selected ? 'smallBold' : 'small'}>
          {txt(`activities.create.recurringForm.weekdays.${key}`)}
        </CustomText>
      </CustomPressable>
    ))
  }

  function getDateString(date) {
    return `${formatDate(date.start, false)} - ${formatDate(date.end, true)}`
  }

  function formatDate(date, isEndDate) {
    if (isEndDate) return moment(new Date(date)).format('LT')
    return moment(new Date(date)).format('llll')
  }

  function renderDates() {
    return dates.map((date, index) => {
      const dateItem = getDateString(date)
      return (
        <View key={index} style={styles.dateListItem}>
          <CustomText style={{ flex: 1, paddingHorizontal: 8 }}>{dateItem}</CustomText>
          <CustomPressable style={{ padding: 8 }} onPress={() => toggleShowRemoveDialog(index)}>
            <NormalIcon name="times" color={Colors.textLight} stroke="fal" size={20} />
          </CustomPressable>
        </View>
      )
    })
  }

  function toggleShowRemoveDialog(index) {
    selectedDate.current = index
    setShowRemoveDialog((prev) => !prev)
  }

  function toggleShowCreatedDates() {
    setShowCreatedDates((prev) => !prev)
  }

  function getRecurringText() {
    let text = txt('activities.create.recurringForm.recurring')
    let selectedDays = 0
    let index = 0
    Object.entries(days).forEach(([key, value]) => {
      if (value.selected) {
        selectedDays += 1
        if (index > 0) text += ','
        text += ` ${txt(`activities.create.recurringForm.weekdays.${key.toString()}`)}`
        index += 1
      }
    })
    if (selectedDays === 0) return txt('activities.create.recurringForm.selectDays')
    const lastIndex = text.lastIndexOf(', ')
    if (selectedDays >= 2) {
      return text.substr(0, lastIndex) + ` ${txt('activities.create.recurringForm.and')}` + text.substr(lastIndex + 1)
    }
    return text
  }

  function getDatesString() {
    if (showCreatedDates) {
      if (dates.length === 1) return txt('activities.create.hideDate')
      return txt('activities.create.hideDates', { dates: dates.length })
    } else {
      if (dates.length === 1) return txt('activities.create.showDate')
      return txt('activities.create.showDates', { dates: dates.length })
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <View>
      <View
        style={{
          flexDirection: Layout.isSmallDevice ? 'column' : 'row',
        }}
      >
        <View style={[styles.elementContainer, { flex: Layout.isSmallDevice ? 1 : 2 }]}>
          <CustomText style={styles.title} font="bold">
            {txt('activities.create.startDate')}
          </CustomText>
          <DatePicker
            date={startDate}
            minDate={new Date()}
            maxDate={maximumDate}
            handleDateChange={handleStartDateChange}
            dateFormat="eee d MMM yyyy"
          />
        </View>
        <View style={[styles.elementContainer, { flex: 1 }]}>
          <CustomText style={styles.title} font="bold">
            {txt('activities.create.recurringForm.startTime')}
          </CustomText>
          <DatePicker
            date={startTime}
            minTime={startDate}
            showTimeSelectOnly
            minuteInterval={15}
            dateFormat="H:mm"
            handleDateChange={handleStartTimeChange}
          />
        </View>

        <View style={[styles.elementContainer, { flex: 1 }]}>
          <CustomText style={styles.title} font="bold">
            {txt('activities.create.recurringForm.endTime')}
          </CustomText>
          <DatePicker
            date={endTime}
            minTime={startTime}
            showTimeSelectOnly
            minuteInterval={15}
            dateFormat="H:mm"
            handleDateChange={handleEndTimeChange}
          />
        </View>
      </View>

      <View style={styles.elementContainer}>
        <CustomText style={styles.title} font="bold">
          {getRecurringText()}
        </CustomText>
        <View style={styles.daySelector}>{renderDaySelector()}</View>
      </View>

      <View style={styles.elementContainer}>
        <CustomText style={styles.title} font="bold">
          {txt('activities.create.endDate')}
        </CustomText>
        <DatePicker
          date={endDate}
          minTime={0}
          minDate={startDate}
          maxDate={maximumDate}
          handleDateChange={handleEndDateChange}
          dateFormat="eee d MMM yyyy"
        />
      </View>

      {!!errorMsg && (
        <View style={styles.errorMsg}>
          <CustomText style={{ color: Colors.red }}>{errorMsg}</CustomText>
        </View>
      )}

      <View style={styles.elementContainer}>
        <ButtonPrimary title={txt('activities.create.recurringForm.createDates')} onPress={createDates} />
      </View>
      {dates.length > 0 && (
        <CustomPressable style={styles.showDatesBtn} onPress={toggleShowCreatedDates}>
          <CustomText style={{ marginRight: 12 }}>{getDatesString()}</CustomText>
          <NormalIcon name={showCreatedDates ? 'chevron-up' : 'chevron-down'} size={Fontsizes.l} />
        </CustomPressable>
      )}
      {showCreatedDates && <View style={{ marginHorizontal: 12, marginBottom: 12 }}>{renderDates()}</View>}
      <CustomDialog onDismiss={toggleShowRemoveDialog} visible={showRemoveDialog}>
        <ContentContainer>
          <CustomText>{txt('activities.create.recurringForm.removeDialog')}</CustomText>
          <ButtonPrimary
            style={styles.removeDateBtn}
            title={txt('activities.create.recurringForm.removeDate')}
            onPress={removeDate}
          />
          <ButtonSecondary
            style={styles.cancelRemoveBtn}
            title={txt('activities.edit.cancel')}
            onPress={toggleShowRemoveDialog}
          />
        </ContentContainer>
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  dateListItem: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
    backgroundColor: Colors.white,
    borderRadius: 12,
    ...ShadowStyles.shadowInputs,
  },
  daySelector: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  selectorDay: {
    alignItems: 'center',
    borderRadius: 12,
    padding: 8,
    paddingHorizontal: 18,
    margin: 6,
  },
  elementContainer: {
    marginHorizontal: 10,
    marginTop: 12,
  },
  errorMsg: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
  },
  title: {
    marginBottom: 6,
  },
  removeDateBtn: {
    backgroundColor: Colors.redLight,
    width: '100%',
    marginTop: 24,
  },
  cancelRemoveBtn: { width: '100%', marginTop: 12 },
  showDatesBtn: {
    flexDirection: 'row',
    marginVertical: 12,
    marginHorizontal: 12,
  },
})
