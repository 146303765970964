import React, { useState } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { CustomText } from '../../../components/text/StyledText'
import { txt } from '../../../locales/i18n'

// Offer components
import BroenHerlev from '../../../components/views/Universe/BroenHerlev'
import Area9 from '../../../components/views/Universe/Area9'
import RodeKorsHerlev from '../../../components/views/Universe/RodeKorsHerlev'
import RodeKors from '../../../components/views/Universe/RodeKors'
import WeCare from '../../../components/views/Universe/WeCare'
import LaerAtTackle from '../../../components/views/Universe/LaerAtTackle'
import Frivilligcenter from '../../../components/views/Universe/Frivilligcenter'
import HerlevBibliotek from '../../../components/views/Universe/HerlevBibliotek'
import HerlevKommune from '../../../components/views/Universe/HerlevKommune'
import { ContentContainer, ScrollableDialog } from '../../../components/dialogs/CustomDialog'
import NormalIcon from '../../../components/icons/NormalIcon'
import CustomPressable from '../../../components/pressables/CustomPressable'
import { trackPlausibleEvent } from '../../../services/api/plausible'

// import { HerlevDialog } from '../HerlevDialog'

export default function HerlevOffersScreen() {
	const [modalVisible, setModalVisible] = useState(false)
	const [modalContent, setModalContent] = useState('')
	const navigation = useNavigation()

	const offers = [
		{
			name: 'boblberg',
			handlePress: () => navigation.navigate('UniverseBoblStack', { screen: 'Bobls' }),
			img: 'https://filer.boblberg.dk/assets/img/universe/boblberg.png',
			label: txt('universe.herlev.partnerInfo.boblberg.name'),
			text: txt('universe.herlev.partnerInfo.boblberg.offerBox'),
		},
		{
			name: 'wecare',
			handlePress: () => handlePress('wecare', 'WeCare'),
			img: 'https://filer.boblberg.dk/assets/img/universe/we-care.png',
			label: txt('universe.herlev.partnerInfo.weCare.name'),
			text: txt('universe.herlev.partnerInfo.weCare.offerBox'),
		},
		{
			name: 'area9',
			handlePress: () => handlePress('area9', 'Area9'),
			img: 'https://filer.boblberg.dk/assets/img/universe/area9.png',
			label: txt('universe.herlev.partnerInfo.area9.name'),
			text: txt('universe.herlev.partnerInfo.area9.offerBox'),
		},
		{
			name: 'rodekorsherlev',
			handlePress: () => handlePress('rodekorsherlev', 'RodeKorsHerlev'),
			img: 'https://filer.boblberg.dk/assets/img/universe/rode-kors-herlev.png',
			label: txt('universe.herlev.partnerInfo.rodeKorsHerlev.name'),
			text: txt('universe.herlev.partnerInfo.rodeKorsHerlev.offerBox'),
		},
		{
			name: 'rodekors',
			handlePress: () => handlePress('rodekors', 'RodeKors'),
			img: 'https://filer.boblberg.dk/assets/img/universe/rode-kors.png',
			label: txt('universe.herlev.partnerInfo.rodeKors.name'),
			text: txt('universe.herlev.partnerInfo.rodeKors.offerBox'),
		},
		{
			name: 'broen',
			handlePress: () => handlePress('broen', 'Broen'),
			img: 'https://filer.boblberg.dk/assets/img/universe/broen-herlev.png',
			label: txt('universe.herlev.partnerInfo.broenHerlev.name'),
			text: txt('universe.herlev.partnerInfo.broenHerlev.offerBox'),
		},
		{
			name: 'laerattackle',
			handlePress: () => handlePress('laerattackle', 'LaerAtTackle'),
			img: 'https://filer.boblberg.dk/assets/img/universe/laer-at-tackle.png',
			label: txt('universe.herlev.partnerInfo.laerAtTackle.name'),
			text: txt('universe.herlev.partnerInfo.laerAtTackle.offerBox'),
		},
		{
			name: 'frivilligcenter',
			handlePress: () => handlePress('frivilligcenter', 'Frivilligcenter'),
			img: 'https://filer.boblberg.dk/assets/img/universe/frivilligcenter-herlev.png',
			label: txt('universe.herlev.partnerInfo.frivilligcenter.name'),
			text: txt('universe.herlev.partnerInfo.frivilligcenter.offerBox'),
		},
		{
			name: 'herlevbibliotek',
			handlePress: () => handlePress('herlevbibliotek', 'HerlevBibliotek'),
			img: 'https://filer.boblberg.dk/assets/img/universe/herlev-bibliotek.png',
			label: txt('universe.herlev.partnerInfo.herlevbibliotek.name'),
			text: txt('universe.herlev.partnerInfo.herlevbibliotek.offerBox'),
		},
		{
			name: 'herlevkommune',
			handlePress: () => handlePress('herlevkommune', 'HerlevKommune'),
			img: 'https://filer.boblberg.dk/assets/img/universe/herlev-kommune-logo.png',
			label: txt('universe.herlev.partnerInfo.herlevkommune.name'),
			text: txt('universe.herlev.partnerInfo.herlevkommune.offerBox'),
		},
	]

	const handlePress = (name, offer) => {
		trackPlausibleEvent('HerlevOfferClick', { origin: offer }, 'herlev/HerlevOffers')
		setModalContent(name)
		setModalVisible(!modalVisible)
	}

	const handleOffer = () => {
		switch (modalContent) {
			case 'broen':
				return <BroenHerlev setModalVisible={setModalVisible} />
			case 'area9':
				return <Area9 />
			case 'rodekorsherlev':
				return <RodeKorsHerlev setModalVisible={setModalVisible} />
			case 'rodekors':
				return <RodeKors />
			case 'wecare':
				return <WeCare />
			case 'laerattackle':
				return <LaerAtTackle />
			case 'frivilligcenter':
				return <Frivilligcenter />
			case 'herlevbibliotek':
				return <HerlevBibliotek />
			case 'herlevkommune':
				return <HerlevKommune />
		}
	}

	// const renderHerlevDialog = () => {
	//   return <HerlevDialog />
	// }

	return (
		<MainContainer backgroundColor={Colors.background}>
			{/* {renderHerlevDialog()} */}
			<View style={styles.container}>
				<CustomText style={{ textAlign: 'center', marginBottom: 12 }} font="bold">
					{txt('universe.herlev.offersTitle')}
				</CustomText>
				<View style={styles.flexContainer}>
					{offers.map((offer) => {
						return (
							<CustomPressable
								key={offer.name}
								onPress={() => offer.handlePress()}
								style={styles.offerContainer}
								accessibilityRole="button"
							>
								<CustomText font="bold" style={styles.offerText}>
									{offer.text}
								</CustomText>
								<Image
									source={{ uri: offer.img }}
									resizeMode="contain"
									style={styles.offerImage}
									accessible
									accessibilityRole="image"
									accessibilityLabel={offer.label}
									accessibilityIgnoresInvertColors
								/>
							</CustomPressable>
						)
					})}
				</View>

				<View style={styles.quotesContainer}>
					<View style={styles.quoteContainer}>
						<Image
							source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/erik-nielsen.jpg' }}
							resizeMode="contain"
							style={styles.quoteImage}
							accessible
							accessibilityRole="image"
							accessibilityLabel={txt('universe.herlev.quotes.quote1Name')}
							accessibilityIgnoresInvertColors
						/>
						<View style={styles.quoteText}>
							<CustomText style={styles.quote}>{txt('universe.herlev.quotes.quote1')}</CustomText>
							<CustomText font={'smallBold'}>
								{txt('universe.herlev.quotes.quote1Name')}
								<CustomText font={'small'} style={{ color: Colors.inactive, fontWeight: 'normal' }}>
									{txt('universe.herlev.quotes.quote1Title')}
								</CustomText>
							</CustomText>
						</View>
					</View>

					<View style={styles.quoteContainer}>
						<Image
							source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/thomas-gyldal-petersen.jpg' }}
							resizeMode="contain"
							style={styles.quoteImage}
							accessible
							accessibilityRole="image"
							accessibilityLabel={txt('universe.herlev.quotes.quote2Name')}
							accessibilityIgnoresInvertColors
						/>
						<View style={styles.quoteText}>
							<CustomText style={styles.quote}>{txt('universe.herlev.quotes.quote2')}</CustomText>
							<CustomText font={'smallBold'}>
								{txt('universe.herlev.quotes.quote2Name')}
								<CustomText font={'small'} style={{ color: Colors.inactive, fontWeight: 'normal' }}>
									{txt('universe.herlev.quotes.quote2Title')}
								</CustomText>
							</CustomText>
						</View>
					</View>
				</View>

				<ScrollableDialog visible={modalVisible} onDismiss={() => setModalVisible(false)}>
					<ContentContainer style={{ marginVertical: 24, marginLeft: 24, marginRight: 6, paddingRight: 18 }}>
						<CustomPressable
							style={{ alignSelf: 'flex-end', position: 'absolute', paddingLeft: 24, zIndex: 1 }}
							accessibilityRole="button"
							onPress={() => setModalVisible(false)}
						>
							<NormalIcon name="times" color={Colors.inactive} />
						</CustomPressable>
						{handleOffer()}
					</ContentContainer>
				</ScrollableDialog>
			</View>
		</MainContainer>
	)
}

const styles = StyleSheet.create({
	container: {
		alignSelf: 'center',
		alignItems: 'center',
		marginHorizontal: 12,
		maxWidth: Layout.isSmallDevice ? '100%' : Layout.maxFeedWidth,
	},
	flexContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		maxWidth: Layout.maxFeedWidth,
	},
	offerContainer: {
		margin: Layout.isSmallDevice ? 6 : 10,
		minHeight: 140,
		width: Layout.isSmallDevice ? 156 : 200,
		backgroundColor: Colors.white,
		padding: Layout.isSmallDevice ? 6 : 12,
		borderRadius: 6,
	},
	offerImage: {
		alignSelf: 'center',
		height: Layout.isSmallDevice ? 50 : 50,
		maxWidth: 100,
		minWidth: 100,
	},
	offerText: {
		height: 60,
		marginBottom: 12,
		textAlign: 'center',
	},
	quotesContainer: {
		alignItems: 'center',
		marginVertical: 48,
		maxWidth: Layout.isSmallDevice ? '100%' : 636,
	},
	quoteContainer: {
		flexDirection: Layout.isSmallDevice ? 'column' : 'row',
		alignItems: 'center',
		backgroundColor: Colors.white,
		padding: 12,
		borderRadius: 6,
		marginBottom: 24,
	},
	quoteImage: {
		width: 75,
		height: 75,
		borderRadius: 150,
	},
	quoteText: {
		flex: Layout.isSmallDevice ? null : 1,
		marginLeft: Layout.isSmallDevice ? 0 : 24,
		marginTop: Layout.isSmallDevice ? 12 : 0,
	},
	quote: {
		marginBottom: 20,
	},
})
