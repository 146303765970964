import React, { useEffect, useState } from 'react'
import NormalIcon from '../icons/NormalIcon'
import Colors from '../../constants/Colors'
import { FlatList, StyleSheet, View } from 'react-native'
import { getMunicipalityList } from '../../services/api/Municipalities'
import { txt } from '../../locales/i18n'

import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import CustomPressable from "../pressables/CustomPressable"

export default function MunicipalityFilter({ onChange, municipalityId, focus, setFocus, feature, ...props }) {
  const [municipalities, setMunicipalities] = useState([])

  useEffect(() => {
    getMunicipalityList({ feature, countryId: 1 }).then((data) => {
      const municipalities = data.map(({ id, name }) => ({ value: String(id), name }))
      const municipality = municipalities.find(({ value }) => value === municipalityId)

      if (municipality === undefined) {
        setFocus(true)
        onChange('all')
      }

      municipalities.unshift({ value: 'all', name: txt('activities.allMunicipalities') })
      setMunicipalities(municipalities)
    })
  }, [])

  function handleSelect(value) {
    onChange(value)
    setFocus(false)
  }

  function getMunicipalityName(value) {
    return municipalities.find((item) => item.value === value)?.name
  }

  return (
    <View style={styles.container}>
      <CustomPressable onPress={() => setFocus(true)} style={styles.button}>
        <NormalIcon name="map-marker-alt" color={Colors.text} size={15} style={{ marginRight: 4 }} />
        <CustomText>{getMunicipalityName(municipalityId)}</CustomText>
      </CustomPressable>

      <CustomDialog onDismiss={() => setFocus(false)} visible={focus} style={{ padding: 24 }}>
        <NormalIcon name="map-marker-alt" size={60} style={{ alignSelf: 'center' }} />
        <CustomText style={{ textAlign: 'center', marginVertical: 24 }}>{props.description}</CustomText>

        <FlatList
          data={municipalities}
          keyExtractor={(item) => item.value}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          initialNumToRender={20}
          style={{}}
          renderItem={({ item }) => (
            <ListItem item={item} selected={item.value === municipalityId} onSelect={handleSelect} />
          )}
        />
      </CustomDialog>
    </View>
  )
}

function ListItem({ item, onSelect, selected }) {
  const backgroundColor = selected ? Colors.greyLightLight : Colors.transparent
  return (
    <CustomPressable style={[styles.selectItem, { backgroundColor }]} onPress={() => onSelect(item.value)}>
      {selected && <NormalIcon name="check" size={17} color={Colors.text} style={styles.checkIcon} />}
      <CustomText>{item.name}</CustomText>
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectItem: {
    paddingVertical: 6,
    paddingHorizontal: 30,
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.greyLightLight,
  },
  checkIcon: {
    position: 'absolute',
    left: 8,
    top: 7,
  },
})
