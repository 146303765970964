import React from 'react'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../../dialogs/CustomDialog'

const OnlyAdminInGroup = (props) => (
  <CustomDialog visible={props.visible}>
    <ContentContainer>
      <CustomText font="bold">{txt('groups.members.onlyAdminTitle')}</CustomText>
      <CustomText style={{ marginTop: 12 }}>{txt('groups.members.onlyAdminText')}</CustomText>
    </ContentContainer>
    <ActionButton onPress={props.cancel}>
      {txt('groups.members.ok')}
    </ActionButton>
  </CustomDialog>
)

export default OnlyAdminInGroup
