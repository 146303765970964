export const badWords = [
  'sex',
  'sexpartner',
  'kæreste',
  'kaereste',
  'knep',
  'kneppe',
  'bollven',
  'bolleven',
  'idiot',
  'spasser',
  'date',
  'knalde',
  'pik',
  'fisse',
  'blowjob',
  'fvb',
  'fwb',
  'fetish',
  'bolle',
  'liderlig',
  'tantra',
  'yoni',
  'nøgen',
  'elskerinde',
  'sxs',
  'svans',
  'numse',
  'lort',
  'dating',
]

export const unacceptedConversationText = [
  'du er lækker',
  'du ser sød ud',
  'hej smukke',
  'hej søde',
  'jeg søger en kæreste',
  'sex',
]
