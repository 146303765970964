import React from 'react'
import CustomDialog, { ActionButton, ContentContainer } from '../../../components/dialogs/CustomDialog'
import { CustomText } from '../../../components/text/StyledText'
import { ButtonPrimary, ButtonSecondary } from '../../../components/buttons/StyledButtons'
import { StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'

export default function MatchQuestionDialog({ visible, confirm, dismiss, username }) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
          <CustomText style={styles.text}>
            {txt(`christmasFriends.match.question${username ? '' : 'General'}`,{username})}
          </CustomText>
        <ButtonPrimary
          style={[styles.button, {marginTop: 24}]}
          title={txt(`christmasFriends.match.confirm${username ? '' : 'General'}`)}
          onPress={confirm}
        />
        <ButtonSecondary
          style={styles.button}
          title={txt(`christmasFriends.match.dismiss${username ? '' : 'General'}`)}
          onPress={dismiss}
        />
      </ContentContainer>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'center'
  },
  button: {
    marginTop: 12,
    width: '100%',
  }
})