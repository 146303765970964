import React from 'react'
import Colors from '../../constants/Colors'
import ModalSelector from 'react-native-modal-selector'
import { txt } from '../../locales/i18n'
import { StyleSheet } from 'react-native'
import { ShadowStyles, TypographyStyles } from '../../styles'
import Layout from '../../constants/Layout'

export function ModalSelectorCustom(props) {
  return (
    <ModalSelector
      {...props}
      backdropPressToClose={true}
      animationType="fade"
      cancelText={txt('signupField.close')}
      style={[styles.modalCustom, styles.modalShadow]}
      initValueTextStyle={[styles.text, styles.alignLeft]}
      selectStyle={{ borderColor: Colors.transparent }}
      selectTextStyle={[styles.text, styles.alignLeft]}
      optionTextStyle={[styles.selectColor, styles.text]}
      optionContainerStyle={styles.optionContainer}
      cancelStyle={styles.optionContainer}
      cancelTextStyle={[styles.selectText, styles.text]}
    />
  )
}

export const ModalSelectorCustomNoShadow = React.forwardRef((props, ref) => {
  return (
    <ModalSelector
      {...props}
      ref={ref}
      backdropPressToClose={true}
      animationType="fade"
      cancelText={txt('signupField.close')}
      style={[styles.modalCustom, props.style]}
      initValueTextStyle={[styles.text, styles.alignLeft]}
      selectStyle={{ borderColor: Colors.transparent }}
      selectTextStyle={[styles.text, styles.alignLeft]}
      optionTextStyle={[styles.selectColor, styles.text]}
      optionContainerStyle={styles.optionContainer}
      cancelStyle={styles.optionContainer}
      cancelTextStyle={[styles.selectText, styles.text]}
    />
  )
})

export const ModalSelectorCustomProfile = React.forwardRef((props, ref) => {
  return (
    <ModalSelector
      ref={ref}
      {...props}
      backdropPressToClose={true}
      animationType="fade"
      cancelText={txt('signupField.close')}
      childrenContainerStyle={styles.childrenContainer}
      style={[styles.modalProfile, props.style]}
      initValueTextStyle={[styles.text, styles.alignLeft, styles.selectColor]}
      selectStyle={styles.border}
      optionTextStyle={[styles.text, styles.alignLeft, styles.selectColor]}
      optionContainerStyle={styles.optionContainer}
      cancelStyle={styles.optionContainer}
      cancelTextStyle={[styles.selectText, styles.text]}
    />
  )
})

export function ModalSelectorCustomVideoChat(props) {
  return (
    <ModalSelector
      backdropPressToClose={true}
      animationType="fade"
      cancelText={txt('signupField.close')}
      style={[styles.modalCustom, !props.noShadow && styles.modalShadow, { paddingVertical: 2 }, props.style]}
      initValueTextStyle={[styles.textSmall, styles.selectColor, styles.alignLeft]}
      selectStyle={{ borderColor: Colors.transparent }}
      selectTextStyle={[styles.textSmall, styles.selectColor]}
      optionTextStyle={[styles.textSmall, styles.selectColor, props.alignLeft && styles.alignLeft]}
      optionContainerStyle={styles.optionContainer}
      cancelStyle={styles.optionContainer}
      cancelTextStyle={styles.textSmall}
      {...props}
    />
  )
}

export function ModalSelectorCustomSprogland(props) {
  return (
    <ModalSelector
      backdropPressToClose={true}
      animationType="fade"
      cancelText={txt('signupField.close')}
      style={[styles.modalCustom, !props.noShadow && styles.modalShadow, { paddingVertical: 2 }]}
      initValueTextStyle={[styles.textSmall, styles.selectColor, styles.alignLeft]}
      selectStyle={{ borderColor: Colors.transparent }}
      selectTextStyle={[styles.textSmall, styles.selectColor]}
      optionTextStyle={[styles.textSmall, styles.selectColor, props.alignLeft && styles.alignLeft]}
      optionContainerStyle={styles.optionContainer}
      cancelStyle={styles.optionContainer}
      cancelTextStyle={styles.textSmall}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  alignLeft: { textAlign: 'left' },
  border: { borderColor: Colors.transparent },
  childrenContainer: { margin: -5, marginLeft: 0 },
  modalCustom: {
    backgroundColor: Colors.white,
    borderRadius: 10,
  },
  modalProfile: {
    backgroundColor: Colors.white,
  },
  modalShadow: { ...ShadowStyles.shadowInputs },
  optionContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white,
    maxHeight: 500,
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
    alignSelf: 'center',
  },
  selectColor: { color: Colors.text },
  text: { ...TypographyStyles.text, color: Colors.text },
  textSmall: { ...TypographyStyles.textSmall, color: Colors.text },
})
