import { Actions } from './constants'

export function setOwnPost(payload) {
  return { type: Actions.SET_OWN_POST, payload }
}

export function updateOwnPost(payload) {
  return { type: Actions.UPDATE_OWN_POST, payload }
}

export function setAnnouncement(payload) {
  return { type: Actions.SET_ANNOUNCEMENT, payload }
}

export function setDataResponsible(payload) {
  return { type: Actions.SET_DATA_RESPONSIBLE, payload }
}

export function setOrigin(payload) {
  return { type: Actions.SET_ORIGIN, payload }
}

export function setUser(payload) {
  return { type: Actions.SET_USER, payload }
}

export function setUserToken(payload) {
  return { type: Actions.SET_USER_TOKEN, payload }
}

export function setDeviceId(payload) {
  return { type: Actions.SET_DEVICE_ID, payload }
}

export function setConversations(payload) {
  return { type: Actions.SET_CONVERSATIONS, payload }
}

export function setShowMakeInfluence(payload) {
  return { type: Actions.SET_SHOW_MAKEINFLUENCE, payload }
}

export function addConversation(conversation) {
  return { type: Actions.ADD_CONVERSATION, conversation }
}

export function removeConversation(payload) {
  return { type: Actions.REMOVE_CONVERSATION, payload }
}

export function updateConversation(conversation) {
  return { type: Actions.UPDATE_CONVERSATION, conversation }
}

export function addConversationMessage(conversationID, message) {
  return { type: Actions.ADD_CONVERSATION_MESSAGE, conversationID, message }
}

export function deleteConversationMessage(conversationID, messageID) {
  return {
    type: Actions.DELETE_CONVERSATION_MESSAGE,
    conversationID,
    messageID,
  }
}

export function setUserUniverses(universes) {
  return { type: Actions.SET_USER_UNIVERSES, universes }
}

export function setUserPreferences(preferences) {
  return { type: Actions.SET_USER_PREFERENCES, preferences }
}

export function updateUserPreferences(preferences) {
  return { type: Actions.UPDATE_USER_PREFERENCES, preferences }
}

export function setGroups(groups) {
  return { type: Actions.SET_GROUPS, groups }
}

export function updateGroup(group) {
  return { type: Actions.UPDATE_GROUP, group }
}

export function leaveGroup(groupId) {
  return { type: Actions.LEAVE_GROUP, groupId }
}

export function setChristmasPost(post) {
  return { type: Actions.SET_CHRISTMAS_POST, post }
}

export function setChristmasDialogVisible(dialogVisible) {
  return { type: Actions.SET_CHRISTMAS_DIALOG_VISIBLE, dialogVisible }
}

export function setChristmasMatchDialogVisible(matchDialogVisible) {
  return {
    type: Actions.SET_CHRISTMAS_MATCH_DIALOG_VISIBLE,
    matchDialogVisible,
  }
}

export function updateChristmasPostAction(post) {
  return { type: Actions.UPDATE_CHRISTMAS_POST, post }
}

export function deleteChristmasPostAction(payload) {
  return { type: Actions.DELETE_CHRISTMAS_POST, payload }
}

export function resetStateAction() {
  return { type: Actions.RESET_STATE }
}

export function setGlobalDialogAction(globalDialog) {
  return { type: Actions.SET_GLOBAL_DIALOG, globalDialog }
}

export function setMunicipalities(municipalities) {
	return { type: Actions.SET_MUNICIPALITIES, municipalities }
}

export function resetGlobalDialogAction() {
  return {
    type: Actions.SET_GLOBAL_DIALOG,
    globalDialog: {
      visible: false,
      type: null,
      text: '',
      title: '',
      data: {}
    }
  }
}
