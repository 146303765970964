import moment from "moment/moment"
import { txt } from "../../../../locales/i18n"

export function formatDate(startDate, endDate) {
	const mStart = moment(startDate)
	const mEnd = moment(endDate)
	const today = moment().startOf('day');
	const tomorrow = moment().add(1, 'days').startOf('day');

	if (mStart.isSame(today, 'day')) {
		return `${txt('videoChat.volunteer.create.dateToday') + mStart.format('HH.mm')} - ${mEnd.format('HH:mm')}`;
	} else if (mStart.isSame(tomorrow, 'day')) {
		return `${txt('videoChat.volunteer.create.dateTomorrow') + mStart.format('HH:mm')} - ${mEnd.format('HH:mm')}`;
	} else {
		const str = `${mStart.format(txt('videoChat.volunteer.create.time'))} - ${mEnd.format('HH:mm')}`
		return `${str[0].toUpperCase()}${str.slice(1)}`
	}
}
