import React from 'react'
import { View, StyleSheet } from 'react-native'
import { txt } from '../../locales/i18n'
import OptionsList from '../../components/lists/OptionsList'
import TxtTMD from '../../constants/TxtTMD'
import PageTitle from '../../components/tags/PageTitle'
import MainContainer from '../../components/MainContainer/MainContainer'
import layout from '../../constants/Layout'
import { URLOpener } from '../../utils/URLOpener'

export default class AboutScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      data: [
        {
          body: txt('partners.title'),
          onPress: () => {
            this.props.navigation.navigate('PartnersMenu')
          },
          icon: {
            name: "handshake-alt",
          },
        },
        {
          body: txt('subjects.title'),
          onPress: () => URLOpener('https://boblberg.dk/inspiration'),
          icon: {
            name: "list",
          },
        },
        {
          body: txt('press.title'),
          onPress: () => {
            this.props.navigation.navigate('Press')
          },
          icon: {
            name: "newspaper",
          },
        },
        {
          body: txt('employment.title'),
          onPress: () => URLOpener('https://boblberg.dk/job'),
          icon: {
            name: "suitcase",
          },
        },
        {
          body: txt('blog.title'),
          onPress: () => {
            this.props.navigation.navigate('BlogsMenu')
          },
          icon: {
            name: "edit",
          },
        },
        {
          body: txt('projects.title'),
          onPress: () => {
            this.props.navigation.navigate('ProjectsMenu')
          },
          icon: {
            name: "clipboard-check",
          },
        },
        {
          body: txt('arrangements.title'),
          onPress: () => {
            this.props.navigation.navigate('Arrangements')
          },
          icon: {
            name: "calendar-day",
          },
        },
        {
          body: txt('faellesskabsprisen.title'),
          onPress: () => {
            this.props.navigation.navigate('Faellesskabsprisen')
          },
          icon: {
            name: "award",
          },
        },
        {
          body: txt('MunicipalityInfo.title'),
          onPress: () => {
            this.props.navigation.navigate('MunicipalityInfo')
          },
          icon: {
            name: "info-circle",
          },
        },
        {
          body: txt('aboutUs.title'),
          onPress: () => {
            this.props.navigation.navigate('AboutUs')
          },
          icon: {
            name: "bobl",
            boblbergIcon: true,
          },
        },
      ],
    }
  }

  render() {
    return (
      <MainContainer>
        <PageTitle title={TxtTMD.about.title[TxtTMD.lang]} />
        <View style={styles.container}>
          <OptionsList data={this.state.data} />
        </View>
      </MainContainer>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: layout.maxFeedWidth,
  },
})
