import { txt } from '../locales/i18n'

export function getSproglandCategories() {
  const sproglandCategories = [
    { value: 1, name: txt('learnDanish.categories.1') },
    { value: 2, name: txt('learnDanish.categories.2') },
  ]

  return sproglandCategories
}
