import React from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import Colors from '../../constants/Colors'

export default function MainContainer({
  children,
  noScroll,
  backgroundColor = Colors.background,
}) {
  if (noScroll) {
    return (
      <View
        accessibilityRole="main"
        contentContainerStyle={styles.contentContainer}
        style={[styles.container, { backgroundColor }]}
      >
        {children}
      </View>
    )
  }
  return (
    <ScrollView
      accessibilityRole="main"
      contentContainerStyle={styles.contentContainer}
      style={[styles.container, { backgroundColor }]}
    >
      {children}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainer: { flexGrow: 1 },
})
