import React, { useState } from 'react'
import { Image, Platform, StyleSheet, View } from 'react-native'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import ImageSlider from './ImageSlider'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupPostImages({ images }) {
  const [startIndex, setStartIndex] = useState(undefined)

  return (
    <View style={styles.imageContainer}>
      {images.map((image, index) => (
        <CustomPressable
          key={index.toString()}
          style={styles.postImage}
          accessibilityLabel={txt('groups.bulletin.imageLabel')}
          accessibilityHint={txt('groups.bulletin.imageHint')}
          onPress={() => setStartIndex(index)}
        >
          <Image
            style={[
              Platform.OS !== 'web' && { height: 135 },
              images.length === 1 ? styles.singleImage : styles.postImage,
            ]}
            source={{ uri: image.base64 }}
            accessibilityIgnoresInvertColors
          />
        </CustomPressable>
      ))}
      <ImageSlider
        visible={startIndex !== undefined}
        dismiss={() => setStartIndex(undefined)}
        images={images}
        startIndex={startIndex}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  postImage: {
    flexBasis: Layout.isSmallDevice ? 135 : 200,
    flexGrow: 1,
    flexShrink: 1,
    margin: 1,
  },
  singleImage: {
    alignSelf: 'center',
    height: 400,
    resizeMode: 'cover',
    width: '100%',
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: 12,
    width: '100%',
  },
})