import CustomDialog, { ContentContainer } from '../../components/dialogs/CustomDialog'
import { View, StyleSheet } from 'react-native'
import NormalIcon from '../../components/icons/NormalIcon'
import Colors from '../../constants/Colors'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'
import React from 'react'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function MatchDialog({ visible, close, title, description }) {
	return (
		<CustomDialog visible={visible} onDismiss={close}>
			<CustomPressable
				style={styles.closeIcon}
				accessibilityRole="button"
				onPress={close}
			>
				<NormalIcon name="times" size={20} color={Colors.inactive} />
			</CustomPressable>

			<ContentContainer>
				<CustomText style={styles.title}>
					{title}
				</CustomText>
				<CustomText>
					{description}
				</CustomText>
			</ContentContainer>
			<View style={{ paddingBottom: 16 }}>
				<ButtonPrimary
					onPress={close}
					style={{ backgroundColor: Colors.blueAzure }}
					title={txt('global.close')}
					titleStyle={{ paddingHorizontal: 12 }}
				/>
			</View>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	closeIcon: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		alignSelf: 'flex-end',
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 1,
	},
	title: {
		fontSize: 20,
		fontFamily: 'raleway-semibold',
		marginRight: 30,
		marginBottom: 12,
	}
})
