import React from 'react'
import { View, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { URLOpener } from '../../../../utils/URLOpener'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'
import Colors from '../../../../constants/Colors'
import CustomPressable from "../../../pressables/CustomPressable"

export default class Zuzan extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>

        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.zuzan.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/Zuzan.png',
              }}
              style={styles.image}
              accessibilityIgnoresInvertColors
            />

            <CustomText font="bold">{txt('blog.zuzan.author')}</CustomText>
          </View>
          <View>
            <CustomText>{txt('blog.zuzan.text1')}</CustomText>
          </View>
          <View style={styles.marginBottom}>
            <CustomPressable
              onPress={() => URLOpener('https://mentality.dk/blogs/')}
              style={styles.marginTop}
            >
              <CustomText font="small">
                {txt('blog.zuzan.references1Title')}

                <CustomText style={styles.linkText}>
                  {txt('blog.zuzan.references1')}
                </CustomText>
              </CustomText>
            </CustomPressable>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  image: { alignSelf: 'center', height: 200 },
  linkText: { color: Colors.tintColor },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  marginTop: { marginTop: 10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
