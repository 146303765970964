import React, { useEffect } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { CustomChip } from '../../components/chips/Chip'
import Colors from '../../constants/Colors'
import PageTitle from '../../components/tags/PageTitle'
import { useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/core'
import MainContainer from '../../components/MainContainer/MainContainer'
import MySessions from '../../components/views/LearnDanish/volunteer/MySessions'
import PreviousSessions from '../../components/views/LearnDanish/volunteer/PreviousSessions'
import Materials from '../../components/views/LearnDanish/volunteer/Materials'
import { txt } from '../../locales/i18n'
import { getVolunteerAccess } from '../../services/api/volunteerApi'
import Messages from '../../components/views/LearnDanish/volunteer/Messages'
import ConversationCreate from '../../components/views/Conversations/ConversationCreate'
import { sproglandHeaderBackImage, sproglandHeaderStyle, sproglandHeaderTitle } from './SproglandOptions'

const { width } = Dimensions.get('window')
const small = width < 800

export default function VolunteerScreen() {
  const route = useRoute()
  const navigation = useNavigation()
  const isSmallDevice = Dimensions.get('window').width < 420

  let chip = route.params?.chip

  const mine = txt('learnDanish.volunteer.chipMine')
  const previous = txt('learnDanish.volunteer.chipPrevious')
  const materials = txt('learnDanish.volunteer.chipMaterials')
  const messages = txt('learnDanish.volunteer.chipMessages')

  useEffect(() => {
    navigation.setOptions({
      headerBackTitle: small && ' ',
      headerBackTitleStyle: { color: Colors.text, fontFamily: 'raleway-regular' },
      headerBackImage: () => sproglandHeaderBackImage(navigation),
      headerTitle: () => sproglandHeaderTitle(true),
      headerStyle: sproglandHeaderStyle,
    })

    getVolunteerAccess('sprogland').then((volunteer) => {
      if (!volunteer.isVolunteer) navigation.navigate('LearnDanish')
    })
    let chip = route.params?.chip
    if (Number(chip)) {
      navigation.navigate('SproglandVolunteer', {
        id: chip,
      })
    } else if (!chip) {
      chip = mine
      setChipParam(chip)
    }
  }, [])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        if (chip === messages) return <ConversationCreate />
        else return null
      },
    })
  }, [chip])

  const setChipParam = (chip) => navigation.setParams({ chip })

  function renderChips() {
    return (
      <View style={styles.chipContainer}>
        {/* Create + own bookings */}
        <View style={styles.chip}>
          <CustomChip
            icon="clock"
            iconStroke="fal"
            selected={route.params?.chip === mine}
            onPress={() => setChipParam(mine)}
            accessibilityLabel={txt('learnDanish.volunteer.mine')}
            text={mine.charAt(0).toUpperCase() + mine.slice(1)}
          />
        </View>

        {/* Previous bookings */}
        <View style={styles.chip}>
          <CustomChip
            icon="history"
            iconStroke="fal"
            selected={route.params?.chip === previous}
            onPress={() => setChipParam(previous)}
            accessibilityLabel={txt('learnDanish.volunteer.previous')}
            text={previous.charAt(0).toUpperCase() + previous.slice(1)}
          />
        </View>

        {/* Materials */}
        <View style={styles.chip}>
          <CustomChip
            icon="paperclip"
            iconStroke="fal"
            selected={route.params?.chip === materials}
            onPress={() => setChipParam(materials)}
            accessibilityLabel={txt('learnDanish.volunteer.materials.title')}
            text={!isSmallDevice && materials.charAt(0).toUpperCase() + materials.slice(1)}
          />
        </View>

        {/* Inbox */}
        <View style={styles.chip}>
          <CustomChip
            icon="comment-lines"
            iconStroke="fal"
            selected={route.params?.chip === messages}
            onPress={() => setChipParam(messages)}
            accessibilityLabel={txt('learnDanish.volunteer.messages')}
            text={!isSmallDevice && messages.charAt(0).toUpperCase() + messages.slice(1)}
          />
        </View>
      </View>
    )
  }

  switch (chip) {
    case previous:
      return (
        <MainContainer backgroundColor={Colors.redCrossBackground}>
          <PageTitle title={txt('learnDanish.volunteer.previous')} />
          {renderChips()}
          <PreviousSessions />
        </MainContainer>
      )
    case materials:
      return (
        <MainContainer backgroundColor={Colors.redCrossBackground}>
          <PageTitle title={txt('learnDanish.volunteer.materials.title')} />
          {renderChips()}
          <Materials />
        </MainContainer>
      )
    case messages:
      return (
        <MainContainer noScroll backgroundColor={Colors.redCrossBackground}>
          <PageTitle title={txt('learnDanish.volunteer.messages')} />
          {renderChips()}
          <Messages />
        </MainContainer>
      )
    case mine:
    default:
      return (
        <MainContainer backgroundColor={Colors.redCrossBackground}>
          <PageTitle title={txt('learnDanish.volunteer.mine')} />
          {renderChips()}
          <MySessions />
        </MainContainer>
      )
  }
}

const styles = StyleSheet.create({
  chip: {
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
  },
  chipContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
})

