import Paths from '../../constants/Paths'
import store from '../../redux/store'


//https://facebook.github.io/react-native/docs/network
export async function apiPost(jsonData, url) {
  try {
    return await fetch(Paths.apiBaseUrl + url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + store.getState().userToken,
        deviceId: store.getState().deviceId,
      },
      body: JSON.stringify(jsonData),
    })
  } catch (error) {
    if (__DEV__) console.error(error)
  }
}

export async function apiPostImage(jsonData, url) {
  try {
    return await fetch(Paths.apiBaseUrl + url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + store.getState().userToken,
        deviceId: store.getState().deviceId,
      },
      body: jsonData,
    })
  } catch (error) {
    if (__DEV__) console.error(error)
  }
}

export async function apiPut(jsonData, url) {
  try {
    return await fetch(Paths.apiBaseUrl + url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + store.getState().userToken,
        deviceId: store.getState().deviceId,
      },
      body: JSON.stringify(jsonData),
    })
  } catch (error) {
    if (__DEV__) console.error(error)
  }
}

export async function apiGet(url) {
  try {
    return await fetch(Paths.apiBaseUrl + url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (store.getState().userToken ?? ''),
        deviceId: store.getState().deviceId,
      },
    }).then((res) => res.json())
  } catch (error) {
    if (__DEV__) console.error(error)
  }
}

export async function apiGetFile(url) {
  const urlFull = Paths.apiBaseUrl + url
  const token = store.getState().userToken

  const options = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token ?? ''}`,
      deviceId: store.getState().deviceId,
    },
  }
  try {
    const response = await fetch(urlFull, options)
    const blob = await response.blob()
    return blob
  } catch (error) {
    if (__DEV__) console.log(error)
  }
}

export async function apiDelete(jsonData, url) {
  try {
    return await fetch(Paths.apiBaseUrl + url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + store.getState().userToken,
        deviceId: store.getState().deviceId,
      },
      body: JSON.stringify(jsonData),
    })
  } catch (error) {
    if (__DEV__) console.error(error)
  }
}
