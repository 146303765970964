import React from 'react'
import { View, StyleSheet, Linking } from 'react-native'
import { ContentContainer, ScrollableDialog } from '../../dialogs/CustomDialog'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import Colors from '../../../constants/Colors'
import CustomPressable from "../../pressables/CustomPressable"

export default function BehaviorDialog({ visible, close }) {
  return (
      <ScrollableDialog visible={visible}>
        <ContentContainer>
          <CustomText style={{ fontSize: 20, fontFamily: 'raleway-semibold', marginVertical: 12 }}>
            {txt('learnDanish.help.label')}
          </CustomText>
          <CustomText>
            {txt('learnDanish.help.description1')}
            <CustomPressable onPress={() => Linking.openURL('mailto:nyren@rodekors.dk')}>
              <CustomText style={styles.linkText}>{txt('learnDanish.help.supportMail')}</CustomText>
            </CustomPressable>
            <CustomPressable onPress={() => Linking.openURL(`tel:${+4524912771}`)}>
              <CustomText style={styles.linkText}>{'/ \u200B24\u00A091\u00A027\u00A071'}</CustomText>
            </CustomPressable>
            {txt('learnDanish.help.description2')}
          </CustomText>
        </ContentContainer>
        <View style={{ paddingBottom: 16 }}>
          <ButtonPrimary
            onPress={close}
            style={{ backgroundColor: Colors.blueAzure }}
            title={txt('global.close')}
            titleStyle={{ paddingHorizontal: 12 }}
          />
        </View>
      </ScrollableDialog>
    // </View>
  )
}

const styles = StyleSheet.create({
  linkText: {
    color: Colors.tintColor,
  },
})
