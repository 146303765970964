import React, { useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView, FlatList } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { SpacingStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import {
  getGroupMembers,
  leaveGroupByID,
  removeGroupMember,
  getJoinRequests,
  getBannedMembers,
  unbanGroupMember,
  approveJoinRequest,
  sendInvite,
  makeUserAdmin,
  getInvitedMembers,
  deleteInvite,
} from '../../../services/api/Groups'
import CustomDialog, { ActionButton, ContentContainer } from '../../dialogs/CustomDialog'
import { CustomInputs } from '../../text/StyledTextinputs'
import RegexPatterns from '../../../constants/RegexPatterns'
import { InvalidInputNoticeCentered } from '../../notices/InvalidInputNotices'
import LeaveGroupDialog from './dialogs/LeaveGroupDialog'
import GroupMemberItem from './GroupMemberItem'
import store from '../../../redux/store'
import OnlyAdminInGroup from './dialogs/OnlyAdminInGroup'
import Colors from '../../../constants/Colors'
import GroupDialog from './dialogs/GroupDialog'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupMembers(props) {
  const [groupMembersState, setGroupMembersState] = useState({
    visibleOnlyAdmin: false,
    visibleLeaveGroup: false,
    visibleRemoveMember: false,
    visibleAddMember: false,
    visibleRemoveMemberConfirmation: false,
    visibleUnbanUser: false,
    visibleMakeAdmin: false,
    visibleDeleteInvite: false,
    ownUserRank: props.group.own_user_rank,
    userToBeRemoved: null,
    userToBeAdmin: null,
    removeId: null,
    userToDecline: null,
    userToUnban: null,
    userInviteToDelete: null,
    expanded: false,
    addMemberName: '',
    addMemberEmail: '',
    isAddMemberEmailValid: true,
    visibleDeclineRequest: false,
  })
  const [memberList, setMemberList] = useState([])
  const [joinRequests, setJoinRequests] = useState([])
  const [invitedList, setInvitedList] = useState([])
  const [bannedUsers, setBannedUsers] = useState([])
  const [bannedUsersFetched, setBannedUsersFetched] = useState(false)

  function updateState(update) {
    setGroupMembersState((prev) => ({ ...prev, ...update }))
  }

  useEffect(() => {
    getGroupMembers(props.group.id).then(setMemberList)
    if (props.group.own_user_rank === 1) {
      getJoinRequests(props.group.id).then(setJoinRequests)
      getInvitedMembers(props.group.id).then(setInvitedList)
      getBannedMembers(props.group.id).then((res) => {
        setBannedUsers(res)
        setBannedUsersFetched(true)
      })
    }
  }, [])

  const leaveGroup = async () => {
    const didLeaveGroup = await leaveGroupByID(props.group.id)
    if (didLeaveGroup.status_msg === 'User left group.') {
      props.navigateToGroups()
    }
  }

  async function togglePanel() {
    updateState({ expanded: !groupMembersState.expanded })
    if (bannedUsersFetched === false) {
      getBannedMembers(props.group.id).then((res) => {
        setBannedUsers(res)
        setBannedUsersFetched(true)
      })
    }
  }

  const handleLeaveGroup = () => {
    const oneAdmin = memberList.filter(m => m.rank_id === 1).length === 1
    const isAdmin = memberList.filter(m => m.user_id === store.getState().user.id && m.rank_id === 1).length === 1
    if (oneAdmin && isAdmin) updateState({ visibleOnlyAdmin: true })
    else updateState({ visibleLeaveGroup: true })
  }

  const showDialogMakeAdmin = (user) => {
    updateState({ visibleMakeAdmin: true, userToBeAdmin: user })
  }

  const showDialogRemoveMember = (user) => {
    updateState({ visibleRemoveMember: true, userToBeRemoved: user })
  }

  const hideDialogAddMember = () => {
    updateState({
      visibleAddMember: false,
      addMemberEmail: '',
      addMemberName: '',
      isAddMemberEmailValid: true,
    })
  }

  // kick = 0, ban = 1
  const showDialogRemoveMemberConfirmation = (removeId) => {
    updateState({
      visibleRemoveMemberConfirmation: true,
      removeId,
      visibleRemoveMember: false,
    })
  }

  const hideDialogRemoveMemberConfirmation = () => {
    updateState({
      visibleRemoveMemberConfirmation: false,
      removeId: null,
      userToBeRemoved: null,
    })
  }

  const handleRemoveMember = async () => {
    if (!groupMembersState.userToBeRemoved) return
    const wasRemoved = await removeGroupMember(
      props.group.id,
      groupMembersState.userToBeRemoved.user_id,
      groupMembersState.removeId,
    )
    if (wasRemoved?.status_code >= 200 && wasRemoved?.status_code <= 299) {
      getGroupMembers(props.group.id).then(setMemberList)
      getBannedMembers(props.group.id).then((res) => {
        setBannedUsers(res)
        setBannedUsersFetched(true)
      })
      hideDialogRemoveMemberConfirmation()
    }
  }

  const handleDeleteInvite = async () => {
    if (!groupMembersState.userInviteToDelete) return
    const wasDeleted = await deleteInvite(props.group.id, groupMembersState.userInviteToDelete.email)
    if (wasDeleted.status === 200) {
      getInvitedMembers(props.group.id).then(setInvitedList)
    }
    updateState({ visibleDeleteInvite: false, userInviteToDelete: null })
  }

  const handleDeclineJoinRequest = async () => {
    if (!groupMembersState.userToDecline) return
    const wasDeclined = await removeGroupMember(
      props.group.id, groupMembersState.userToDecline.user_id, 0,
    )
    if (wasDeclined?.status_code >= 200 && wasDeclined?.status_code <= 299) {
      getJoinRequests(props.group.id).then(setJoinRequests)
    }
    updateState({ visibleDeclineRequest: false, userToDecline: null })
  }

  const handleUnbanUser = async () => {
    if (!groupMembersState.userToUnban) return
    const wasUnbanned = await unbanGroupMember(props.group.id, groupMembersState.userToUnban.user_id)
    if (wasUnbanned?.status_msg === 'User was unbanned') {
      getBannedMembers(props.group.id).then((res) => {
        setBannedUsers(res)
        setBannedUsersFetched(true)
      })
    }
    updateState({ visibleUnbanUser: false })
  }

  const handleMakeAdmin = async () => {
    const didBecomeAdmin = await makeUserAdmin(props.group.id, groupMembersState.userToBeAdmin.user_id)
    if (didBecomeAdmin && didBecomeAdmin.status_msg === 'User was made admin') {
      getGroupMembers(props.group.id).then(setMemberList)
    }
    updateState({ visibleMakeAdmin: false, userToBeAdmin: null })
  }

  const showDialogDeleteInvite = (item) => {
    updateState({ visibleDeleteInvite: true, userInviteToDelete: item })
  }

  const showDialogDeclineRequest = (item) => {
    updateState({ visibleDeclineRequest: true, userToDecline: item })
  }

  const showDialogUnbanUser = (item) => {
    updateState({ visibleUnbanUser: true, userToUnban: item })
  }

  const handleApproveJoinRequest = async (item) => {
    if (!item) return
    const didJoin = await approveJoinRequest(props.group.id, item.user_id)
    if (didJoin.status_code >= 200 && didJoin.status_code <= 299) {
      getJoinRequests(props.group.id).then(setJoinRequests)
      getGroupMembers(props.group.id).then(setMemberList)
    }
  }

  const handleSendInvite = async () => {
    const conditions = RegexPatterns.email.map((rule) => new RegExp(rule, 'g'))
    const isEmailValid = conditions.map((condition) => condition.test(groupMembersState.addMemberEmail))
    if (isEmailValid.includes(false)) {
      updateState({ isAddMemberEmailValid: false })
    } else {
      updateState({ isAddMemberEmailValid: true })
      // send invite
      const res = await sendInvite(props.group.id, groupMembersState.addMemberEmail.trim(), groupMembersState.addMemberName)
      if (res?.wasInvited) {
        getInvitedMembers(props.group.id).then(setInvitedList)
      }
      hideDialogAddMember()
    }
  }

  const renderMembers = ({ item }) => (
    <GroupMemberItem
      item={item}
      group={props.group}
      ownUserRank={groupMembersState.ownUserRank}
      makeAdmin={showDialogMakeAdmin}
      leaveGroup={handleLeaveGroup}
      removeMember={showDialogRemoveMember}
      approveRequest={handleApproveJoinRequest}
      declineRequest={showDialogDeclineRequest}
      unbanUser={showDialogUnbanUser}
      deleteInvitation={showDialogDeleteInvite}
      navigation={props.navigation}
      navigateToConversation={props.navigateToConversation}
      navigateToNewConversation={props.navigateToNewConversation}
      navigateToGroups={props.navigateToGroups}
    />
  )

  const renderJoinRequests = ({ item }) => (
    <GroupMemberItem
      item={item}
      group={props.group}
      ownUserRank={groupMembersState.ownUserRank}
      makeAdmin={showDialogMakeAdmin}
      removeMember={showDialogRemoveMember}
      approveRequest={handleApproveJoinRequest}
      declineRequest={showDialogDeclineRequest}
      unbanUser={showDialogUnbanUser}
      deleteInvitation={showDialogDeleteInvite}
      navigation={props.navigation}
      navigateToConversation={props.navigateToConversation}
      navigateToNewConversation={props.navigateToNewConversation}
    />
  )

  const renderInvitedList = ({ item }) => (
    <GroupMemberItem
      item={item}
      group={props.group}
      ownUserRank={groupMembersState.ownUserRank}
      makeAdmin={showDialogMakeAdmin}
      removeMember={showDialogRemoveMember}
      approveRequest={handleApproveJoinRequest}
      declineRequest={showDialogDeclineRequest}
      unbanUser={showDialogUnbanUser}
      deleteInvitation={showDialogDeleteInvite}
      navigation={props.navigation}
    />
  )

  const renderBannedMembers = ({ item }) => (
    <GroupMemberItem
      item={item}
      group={props.group}
      ownUserRank={groupMembersState.ownUserRank}
      makeAdmin={showDialogMakeAdmin}
      removeMember={showDialogRemoveMember}
      approveRequest={handleApproveJoinRequest}
      declineRequest={showDialogDeclineRequest}
      unbanUser={showDialogUnbanUser}
      deleteInvitation={showDialogDeleteInvite}
      navigation={props.navigation}
    />
  )

  return (
    <ScrollView style={props.style}>
      <View>
        {groupMembersState.ownUserRank === 1 && (
          <View style={[styles.marginBottom, styles.marginTop, styles.flexRow]}>
            <ButtonPrimary
              onPress={() => updateState({ visibleAddMember: true })}
              style={{ backgroundColor: props.group.theme?.primary || Colors.tintColor }}
              titleStyle={{ color: props.group.theme?.buttonText || Colors.white }}
              title={txt('groups.members.addMember')}
            />
          </View>
        )}
        <View style={styles.container}>


          {(groupMembersState.ownUserRank === 1 || groupMembersState.ownUserRank === 0) && (
            <View>
              {/** Join requests */}
              {joinRequests?.length !== 0 && (
                <View style={styles.marginBottom}>
                  <View style={styles.titleContainer}>
                    <CustomText font="large">{txt('groups.members.requests')}</CustomText>
                  </View>
                  <View style={styles.memberContainer}>
                    <FlatList
                      data={joinRequests}
                      renderItem={renderJoinRequests}
                      keyExtractor={(item) => item.user_id?.toString()}
                    />
                  </View>
                </View>
              )}

              {/** Invitations */}
              {invitedList.length !== 0 && (
                <View style={styles.marginBottom}>
                  <View style={styles.titleContainer}>
                    <CustomText font="large">{txt('groups.members.invitations')}</CustomText>
                  </View>
                  <View style={styles.memberContainer}>
                    <FlatList
                      data={invitedList}
                      renderItem={renderInvitedList}
                      keyExtractor={(item) => item.email}
                    />
                  </View>
                </View>
              )}
            </View>
          )}

          {/** Members */}
          <View style={styles.marginBottom}>
            <View style={styles.titleContainer}>
              <CustomText font="large">{txt('groups.members.members')}</CustomText>
            </View>
            <View style={styles.memberContainer}>
              <FlatList
                data={memberList}
                renderItem={renderMembers}
                keyExtractor={(item) => item.user_id?.toString()}
              />
            </View>
          </View>

          {(groupMembersState.ownUserRank === 1 || groupMembersState.ownUserRank === 0) && (
            <View>
              {/** Banned users */}
              {bannedUsers.length !== 0 && (
                <View style={styles.marginBottom}>
                  <CustomPressable
                    onPress={togglePanel}
                    accessibilityLabel={
                      groupMembersState.expanded
                        ? txt('groups.members.accessibilityLabelBannedUsersHide')
                        : txt('groups.members.accessibilityLabelBannedUsers')
                    }
                  >
                    <View style={[styles.titleContainer, styles.row]}>
                      <CustomText font="large">{txt('groups.members.bannedUsers')}</CustomText>
                      <NormalIcon name={groupMembersState.expanded ? "chevron-up" : "chevron-down"} />
                    </View>
                  </CustomPressable>
                  {groupMembersState.expanded ? (
                    <View style={styles.memberContainer}>
                      <FlatList
                        data={bannedUsers}
                        renderItem={renderBannedMembers}
                        keyExtractor={(item) => item.user_id?.toString()}
                      />
                    </View>
                  ) : null}
                </View>
              )}
            </View>
          )}
        </View>

        {/* Dialog for leaving group */}
        <LeaveGroupDialog
          visible={groupMembersState.visibleLeaveGroup}
          cancel={() => updateState({ visibleLeaveGroup: false })}
          accept={leaveGroup}
        />

        {/* Dialog for trying to leave when only admin left */}
        <OnlyAdminInGroup
          visible={groupMembersState.visibleOnlyAdmin}
          cancel={() => updateState({ visibleOnlyAdmin: false })}
        />

        {/* Dialog for making group member admin */}
        <GroupDialog
          visible={groupMembersState.visibleMakeAdmin}
          title={txt('groups.members.makeMemberAdminText') + groupMembersState.userToBeAdmin?.username + '?'}
          accept={handleMakeAdmin}
          cancel={() => updateState({ visibleMakeAdmin: false, userToBeAdmin: null })}
        />

        {/* Dialog for removing group member */}
        <CustomDialog visible={groupMembersState.visibleRemoveMember}>
          <ContentContainer>
            <CustomText font="bold">
              {txt('groups.members.removeMemberText')}
              {groupMembersState.userToBeRemoved ? groupMembersState.userToBeRemoved.username + '?' : '?'}
            </CustomText>
            <CustomText>{txt('groups.members.removeMemberDescription')}</CustomText>
          </ContentContainer>
          <ActionButton onPress={() => showDialogRemoveMemberConfirmation(0)}>
            {txt('groups.members.kick')}
          </ActionButton>
          <ActionButton onPress={() => showDialogRemoveMemberConfirmation(1)}>
            {txt('groups.members.ban')}
          </ActionButton>
          <ActionButton onPress={() => updateState({ visibleRemoveMember: false })}>
            {txt('groups.members.cancel')}
          </ActionButton>
        </CustomDialog>

        {/* Dialog for removing group member confirmation */}
        <GroupDialog
          visible={groupMembersState.visibleRemoveMemberConfirmation}
          title={(groupMembersState.removeId === 0 // kick = 0, ban = 1
            ? txt('groups.members.removeMemberConfirmKick')
            : txt('groups.members.removeMemberConfirmBan'))
          + groupMembersState.userToBeRemoved?.username + '?'}
          accept={handleRemoveMember}
          cancel={hideDialogRemoveMemberConfirmation}
        />

        {/* Dialog for adding group member */}
        <CustomDialog visible={groupMembersState.visibleAddMember}>
          <ContentContainer>
            <View style={styles.input}>
              <View style={styles.dialogTextContainer}>
                <CustomText font="bold">{txt('groups.members.addMemberText')}</CustomText>
              </View>

              <View style={styles.marginBottom}>
                <CustomInputs
                  accessibilityLabel={txt('groups.members.addMemberName')}
                  accessibilityHint={txt('groups.members.addMemberNamePlaceholder')}
                  placeholder={txt('groups.members.addMemberName')}
                  value={groupMembersState.addMemberName}
                  onChangeText={(value) => updateState({ addMemberName: value })}
                />
              </View>
              <View style={styles.marginBottom}>
                <CustomInputs
                  accessibilityLabel={txt('groups.members.addMemberEmail')}
                  accessibilityHint={txt('groups.members.addMemberEmailPlaceholder')}
                  placeholder={txt('groups.members.addMemberEmail')}
                  value={groupMembersState.addMemberEmail}
                  onChangeText={(value) => updateState({ addMemberEmail: value })}
                />
                {/* Invalid email prompt */}
                {!groupMembersState.isAddMemberEmailValid && (
                  <InvalidInputNoticeCentered>{txt('invalidNotice.invalidEmail')}</InvalidInputNoticeCentered>
                )}
              </View>
            </View>
          </ContentContainer>
          <ActionButton onPress={handleSendInvite}>{txt('groups.members.addMemberText')}</ActionButton>
          <ActionButton onPress={hideDialogAddMember}>{txt('groups.close')}</ActionButton>
        </CustomDialog>

        {/* Dialog for deleting invites */}
        <GroupDialog
          visible={groupMembersState.visibleDeleteInvite}
          title={txt('groups.members.deleteInviteText') + groupMembersState.userInviteToDelete?.username + '?'}
          description={txt('groups.members.deleteInviteDescription')}
          accept={handleDeleteInvite}
          cancel={() => updateState({ visibleDeleteInvite: false, userInviteToDelete: null })}
        />

        {/* Dialog for declining join request */}
        <GroupDialog
          visible={groupMembersState.visibleDeclineRequest}
          title={txt('groups.members.declineRequestText') + groupMembersState.userToDecline?.username + '?'}
          description={txt('groups.members.declineRequestDescription')}
          accept={handleDeclineJoinRequest}
          cancel={() => updateState({ visibleDeclineRequest: false, userToDecline: null })}
        />

        {/* Dialog for unbanning user */}
        <GroupDialog
          visible={groupMembersState.visibleUnbanUser}
          title={txt('groups.members.unbanUserText') + groupMembersState.userToUnban?.username + '?'}
          description={txt('groups.members.unbanUserDescription')}
          accept={handleUnbanUser}
          cancel={() => updateState({ visibleUnbanUser: false })}
        />

      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    ...SpacingStyles.widthAndHeight,
  },
  dialogTextContainer: { alignSelf: 'center', marginBottom: 10 },
  flexRow: { flexDirection: 'row', justifyContent: 'space-evenly' },
  input: { minWidth: 300 },
  marginBottom: { marginBottom: 15 },
  marginTop: { marginTop: 10 },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: { ...SpacingStyles.marginRightLeft10, marginBottom: 10 },
})
