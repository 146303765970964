import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { CustomText } from '../../../components/text/StyledText'
import Layout from '../../../constants/Layout'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function PostTypeScreenChristmas(props) {
  function handleTypeSelection(type) {
    props.navigation.navigate('ChristmasCreate', { type })
  }

  return (
    <MainContainer>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <CustomText style={styles.headerText1}>
            {txt('christmasFriends.postType.title')}
          </CustomText>
          <CustomText
            font={Layout.isSmallDevice && 'small'}
            style={styles.text}
          >
            {txt('christmasFriends.postType.description')}
          </CustomText>
        </View>
        <View style={styles.typeContainer}>
          <CustomPressable
            style={{ marginTop: Layout.isSmallDevice ? 12 : 20 }}
            onPress={() => handleTypeSelection('guest')}
          >
            <Image
              style={
                Layout.isSmallDevice ? styles.signImage : styles.signImageWeb
              }
              resizeMode={'contain'}
              accessibilityIgnoresInvertColors
              source={require('../../../assets/images/Julevenner/Julekrans_guest.png')}
              accessible
              accessibilityRole="image"
              accessibilityLabel={'Rund julekrans'}
            />
          </CustomPressable>
          <CustomPressable onPress={() => handleTypeSelection('host')}>
            <Image
              style={
                Layout.isSmallDevice ? styles.signImage : styles.signImageWeb
              }
              resizeMode={'contain'}
              accessibilityIgnoresInvertColors
              source={require('../../../assets/images/Julevenner/Julehus_host.png')}
              accessible
              accessibilityRole="image"
              accessibilityLabel={'Julekrans formet som et hus'}
            />
          </CustomPressable>
        </View>
      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    maxWidth: Layout.isSmallDevice ? '100%' : '80%',
  },
  headerText1: {
    fontFamily: 'oswald-bold',
    fontSize: 30,
    lineHeight: 36,
    marginTop: 24,
    textAlign: 'center',
  },
  signImage: { alignSelf: 'center', height: 130, marginTop: 24, width: 130 },
  signImageWeb: { alignSelf: 'center', height: 250, marginTop: 24, width: 250 },
  text: { marginVertical: 24, textAlign: 'center' },
  textContainer: { marginHorizontal: 12 },
  typeContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
})
