import { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/core'

import * as UniverseController from '../universe/universeController'

export const useUniverseConfig = () => {
	const route = useRoute()

	const [config, setConfig] = useState(() => {
		const universeName = UniverseController.getName(route)
		const universeConfig = UniverseController.getConfig(universeName)
		return universeConfig ?? null
	})

	useEffect(() => {
		const universeName = UniverseController.getName(route)
		const universeConfig = UniverseController.getConfig(universeName) ?? null
		setConfig(universeConfig)
	}, [route])

	return config
}
