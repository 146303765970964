import React, { useEffect, useState, useRef } from 'react'
import GroupsIntroScreen from '../../Groups/GroupsIntro'
import GroupsOverviewScreen from '../../Groups/GroupsOverviewScreen'
import { useSelector } from 'react-redux'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { searchGroups } from '../../../services/api/Groups'
import { getUniverseConfig } from '../../../universe/universeController'
import { Modules } from '../../../constants/Modules'

export default function GroupsUniverseScreen(props) {
  const userToken = useSelector((state) => state.userToken)
  const universes = useSelector((state) => state.user.universes)
  const [universe, setUniverse] = useState(null)
  const universeObject = getUniverseConfig(props.route?.params?.universe)
  const universeGroupCount = useRef(0)

  useEffect(() => {
    const universeName = props.route.params?.universe?.toLowerCase()
    const universe = universes.find((uni) => uni.name === universeName)
    setUniverse(universe)
    if (universe && !universe.modules?.includes(Modules.GROUPS)) {
      props.navigation.popToTop()
    }
  }, [props, universes])

  const navigateToGroup = (groupId) => {
    props.navigation.navigate('Group', {
      id: groupId,
      universe: universe.name,
    })
  }

	const getGroups = async (options) => {
		if (options.skip === 0) universeGroupCount.current = 0
		const groups = []

		if (universeGroupCount.current === options.skip) {
			const universeGroups = await searchGroups({ ...options, universeId: universeObject.id })
			universeGroupCount.current += universeGroups.length
			groups.push(...universeGroups)
		}

		if (groups.length < options.amount) {
			options.skip = Math.max(0, options.skip - universeGroupCount.current)
			const boblbergGroups = await searchGroups(options)
			groups.push(...boblbergGroups)
		}

		return groups
	}

  return (
    <MainContainer noScroll backgroundColor={universeObject?.backgroundColor}>
      {userToken ? (
        <GroupsOverviewScreen
          getGroups={getGroups}
          navigation={props.navigation}
          navigateToGroup={navigateToGroup}
          universeId={universeObject?.id}
        />
      ) : (
        <GroupsIntroScreen navigation={props.navigation} />
      )}
    </MainContainer>
  )
}
