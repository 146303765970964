import * as ImageManipulator from 'expo-image-manipulator'
import { apiPostImage } from './ApiRequest'

export const UploadImage = async (image, rotation = 0, imgType = '') => {

  try {
    // Compress image
    var manipResult = await ImageManipulator.manipulateAsync(
      image.uri,
      [{ rotate: rotation * 90 }],
      {
        format: ImageManipulator.SaveFormat.JPEG,
        base64: true,
      }
    )

    const desiredSize = 1000 // Width and height since the aspect ratio is 1:1

    // Calculate how much to scale down image
    var scale = 1
    while (true) {
      if (
        manipResult.width / scale < desiredSize &&
        manipResult.height / scale < desiredSize
      ) {
        break

        // Prevent infinite loops || edge case if manipresult was null
      } else if (!manipResult.width || !manipResult.height) {
        break
      }

      scale++
    }

    // Resize with previously gotten width and height from previous manipResult
    var manipResult = await ImageManipulator.manipulateAsync(
      manipResult.uri,
      [
        {
          resize: {
            width: manipResult.width / scale,
            height: manipResult.height / scale,
          },
        },
      ],
      {
        format: ImageManipulator.SaveFormat.JPEG,
        base64: true,
        compress: 0.8,
      }
    )

    // Get image extension
    // Find word inbetween data:image/ and ;base64
    var fileExtension = manipResult.uri
      .substring(0, 50)
      .split(';base64')[0]
      .split('data:image/')[1]

    if (!fileExtension) {
      fileExtension = manipResult.uri
        .substring(manipResult.uri.length - 100, manipResult.uri.length)
        .split('.')
        .pop()
    }

    if (!fileExtension) return

    var uriHeader = 'data:image/' + fileExtension + ';base64,'

    // Add uri header if not present
    if (manipResult.base64.substring(0, uriHeader.length) === uriHeader) {
      uriHeader = ''
    }

    const uriOutput = uriHeader + manipResult.base64

    // Desktop formatting is different, so data:image/ is written twice, replace
    // Send and get api imgid response
    return apiPostImage(
      createFormData(uriOutput, {}),
      `images/upload${imgType}`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // Check if img_id is returned by api, then the image was uploaded sucessfully
        if (!responseJson.data.img_id) {
          console.error('Image upload failed.')
          return
        }
        return responseJson.data.img_id
      })
  } catch (err) {
    if (__DEV__) console.log(err)
  }
}

const createFormData = (image, body) => {
  const data = new FormData()
  data.append('file', image)
  Object.keys(body).forEach((key) => {
    data.append(key, body[key])
  })

  return data
}
