import React, { useState, useEffect } from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import Union from '../../../components/views/Union/Union'
import {
  getUniverseConfig,
  getUniverseId,
} from '../../../universe/universeController'

export default function UnionUniverseScreen(props) {
  const [universe, setUniverse] = useState(null)

  useEffect(() => {
    let universeName = props.route.params?.universe?.toLowerCase()

    const universeId = getUniverseId(universeName)
    setUniverse({ id: universeId, name: universeName })
  }, [props.navigation, props.route.params])

  const navigateToUnion = (unionId) => {
    props.navigation.push('EditUnion', {
      id: unionId,
      universe: universe.name,
    })
  }

  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <Union
        navigation={props.navigation}
        route={props.route}
        navigateToUnion={navigateToUnion}
        id={props.route.params.id}
      />
    </MainContainer>
  )
}
