import { Camera } from 'expo-camera'
import * as MediaLibrary from 'expo-media-library'
import { Linking, Platform } from 'react-native'
import Constants from 'expo-constants'
import * as IntentLauncher from 'expo-intent-launcher'

// Article about Android vs IOS permission: https://medium.com/toprakio/react-native-how-to-open-app-settings-page-d30d918a7f55
export async function getMediaPermissions(type) {
  if (type === 'camera') {
    let permission = await Camera.getCameraPermissionsAsync()
    if (!permission.granted) {
      if (!permission.canAskAgain) {
        if (Platform.OS === 'ios') {
          await Linking.openURL('app-settings:')
        } else {
          const pkg = __DEV__ ? 'host.exp.exponent' : Constants.expoConfig.android.package
          const activityAction = IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS
          await IntentLauncher.startActivityAsync(activityAction, { data: 'package:' + pkg })
        }
      } else {
        permission = await Camera.requestCameraPermissionsAsync()
      }
    }
    return permission.granted
  } else if (type === 'library') {
    let permission = await MediaLibrary.getPermissionsAsync()
    if (!permission.granted) {
      if (!permission.canAskAgain) {
        if (Platform.OS === 'ios') {
          await Linking.openURL('app-settings:')
        } else {
          const pkg = __DEV__ ? 'host.exp.exponent' : Constants.expoConfig.android.package
          const activityAction = IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS
          await IntentLauncher.startActivityAsync(activityAction, { data: 'package:' + pkg })
        }
      } else {
        permission = await MediaLibrary.requestPermissionsAsync()
      }
    }
    return permission.granted
  }
}

export const cameraPermission = async () => {
  const permission = await Camera.getCameraPermissionsAsync()
  if (permission.granted) return permission

  if (permission.canAskAgain) {
    return await Camera.requestCameraPermissionsAsync()
  }

  if (Platform.OS === 'ios') {
    await Linking.openURL('app-settings:')
  }

  if (Platform.OS === 'android') {
    const pkg = __DEV__ ? 'host.exp.exponent' : Constants.expoConfig.android.package
    const activityAction = IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS
    await IntentLauncher.startActivityAsync(activityAction, { data: 'package:' + pkg })
  }

  return await Camera.getCameraPermissionsAsync()
}

export const getLibraryPermission = async () => {
  const permission = await MediaLibrary.getPermissionsAsync()
  if (permission.granted) return permission

  if (permission.canAskAgain) {
    return await MediaLibrary.requestPermissionsAsync()
  }

  if (Platform.OS === 'ios') {
    await Linking.openURL('app-settings:')
  }

  if (Platform.OS === 'android') {
    const pkg = __DEV__ ? 'host.exp.exponent' : Constants.expoConfig.android.package
    const activityAction = IntentLauncher.ActivityAction.APPLICATION_DETAILS_SETTINGS
    await IntentLauncher.startActivityAsync(activityAction, { data: 'package:' + pkg })
  }

  return await MediaLibrary.requestPermissionsAsync()
}
