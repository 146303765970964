import { apiPost, apiGet, apiDelete, apiPut } from './ApiRequest'

export async function searchUnions({ search, skip, amount, universeId, faculties, municipality }) {
  const urlQueryParams = {}

  if (search !== undefined) urlQueryParams.search = search
  if (amount !== undefined) urlQueryParams.amount = amount
  if (skip !== undefined) urlQueryParams.skip = skip
  if (universeId !== undefined) urlQueryParams.universeId = universeId
  if (faculties !== undefined && faculties?.length !== 0) urlQueryParams.faculties = faculties.join(',')
  if (municipality && municipality !== 'all') urlQueryParams.municipalityId = municipality

  const urlQuery = Object.entries(urlQueryParams).map(([key, value]) => {
    return key + '=' + value
  })

  const unions = await apiGet('unions/search?' + urlQuery.join('&'))
  return unions ?? { unions: [], locations: [] }
}

export async function getFullUnion(unionId) {
  const res = await apiGet(`unions/${unionId}`)
  return res.union
}

export async function createUnion(data) {
  const res = await apiPost(data, 'unions/')
  const resJson = await res.json()
  return resJson.union
}

export async function updateUnion(data) {
  const res = await apiPut(data, `unions/${data.id}`)
  const resJson = await res.json()
  return resJson.union
}

export async function getOwnUnions() {
  const res = await apiGet(`unions/own`)
  return res.unions
}

export async function deleteUnion(unionId) {
  const res = await apiDelete({}, `unions/${unionId}`)
  const resJson = await res.json()
  return resJson.data
}

export async function getHasAccessToUnions() {
  const res = await apiGet('unions/hasAccessToUnions')
  return res.data
}

export async function setUnionFavorite(unionId, favorite) {
  let res
  if (favorite) {
    res = await apiDelete({}, `unions/${unionId}/favorite`)
  } else {
    res = await apiPost({}, `unions/${unionId}/favorite`)
  }
  const result = await res.json()
  return result.union
}

export async function getFavoriteUnions() {
  const res = await apiGet(`unions/favorites`)
  return res.unions
}

export async function getFacilties() {
  return await apiGet('unions/faculties')
}
