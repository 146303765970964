import { Image, StyleSheet, View } from 'react-native'
import Paths from '../../constants/Paths'
import React from 'react'

// size = ['small'|'large']

export default function ImgHighlightChristmas({ type, imgFilename, size = ''}) {
  let borderSource
  if (type === 'host') borderSource = require('../../assets/images/Julevenner/Julehus_sign.png')
  else if (type === 'guest') borderSource = require('../../assets/images/Julevenner/Julekrans_sign.png')
  else borderSource = require('../../assets/images/Julevenner/Julekrans.png')

  const imgBaseUrl = type === 'group' ? Paths.groupUrlBase : Paths.imageUrlBaseJV
  
  const imgSource = imgFilename
    ? { uri: imgBaseUrl + imgFilename }
    : require('../../assets/images/Julevenner/Julevenner_logo.png')

  if (type === 'group') type = 'guest'

  return (
    <View style={[styles.imageContainer, styles[`imageContainer${size}`]]}>
      <Image
        source={borderSource}
        style={[styles.imageBorder, styles[`imageBorder${size}`]]}
      />
      <Image
        source={imgSource}
        style={styles[`${type}Image${size}`]}
        accessibilityIgnoresInvertColors
      />
    </View>
  )
}

const styles = StyleSheet.create({
  imageContainer: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainerlarge: {
    width: 200,
    height: 200,
  },
  imageContainersmall: {
    width: 33,
    height: 33,
  },
  imageContainermedium: {
    width: 66,
    height: 66,
  },
  hostImage: {
    marginTop: 3,
    width: 67,
    height: 67,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  hostImagelarge: {
    marginTop: 6,
    width: 134,
    height: 134,
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
  },
  hostImagesmall: {
    marginTop: 1,
    width: 22,
    height: 22,
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  hostImagemedium: {
    marginTop: 2,
    width: 44,
    height: 44,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  guestImage: {
    width: 75,
    height: 75,
    borderRadius: 35,
  },
  guestImagelarge: {
    width: 150,
    height: 150,
    borderRadius: 70,
  },
  guestImagesmall: {
    width: 25,
    height: 25,
    borderRadius: 12,
  },
  guestImagemedium: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  imageBorder: {
    zIndex: 1,
    position: 'absolute',
    width: 100,
    height: 100,
  },
  imageBorderlarge: {
    width: 200,
    height: 200,
  },
  imageBordersmall: {
    width: 33,
    height: 33,
  },
  imageBordermedium: {
    width: 66,
    height: 66,
  }
})
