import React from 'react'
import { View, Dimensions, Platform, StyleSheet } from 'react-native'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import Layout from '../../../../constants/Layout'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import { CornerStyles, SpacingStyles, BorderStyles } from '../../../../styles'
import { ImageHighlightPost } from '../../../images/ImageHighlight'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

const fairs = [
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/messer/billede1.jpg',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/messer/billede2.jpg',
  },
  {
    uri: 'https://filer.boblberg.dk/Assets/Img/events/messer/billede3.jpg',
  },
]

export default class Messer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.title,
      expanded: false,
      activeIndex: 0,
    }
  }

  renderImage({ item }) {
    return (
      <View style={styles.imageContainer}>
        <ImageHighlightPost
          source={{ uri: item.uri }}
          style={styles.image}
          accessibilityIgnoresInvertColors
        />
      </View>
    )
  }

  togglePanel(e) {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    return (
      <View style={styles.widthAndHeight}>
        <View style={styles.marginBottom}>
          <View style={styles.marginLeftRight}>
            <View style={styles.marginBottom}>
              <CustomText font="large" style={styles.textCenter}>
                {txt('fairs.title')}
              </CustomText>
            </View>
            <CustomText style={styles.textCenter}>
              {txt('fairs.startText')}
            </CustomText>
          </View>
        </View>

        <View style={styles.center}>
          <View style={styles.row}>
            <Carousel
              layout={'default'}
              ref={(ref) => {
                if (!this.state.pagRef) {
                  this.setState({ pagRef: ref })
                }
              }}
              data={fairs}
              sliderWidth={Platform.OS === 'web' ? 400 : width}
              itemWidth={Platform.OS === 'web' ? 400 : width}
              renderItem={this.renderImage}
              onSnapToItem={(index) => this.setState({ activeIndex: index })}
              loop
              autoplay
              enableSnap={true}
            />
          </View>
          <Pagination
            dotsLength={fairs.length}
            activeDotIndex={this.state.activeIndex}
            carouselRef={this.state.pagRef}
            tappableDots={!!this.state.pagRef}
            dotStyle={styles.pagination}
            dotContainerStyle={styles.dotContainer}
          />
        </View>

        <View style={styles.marginLeftRight}>
          <CustomPressable onPress={this.togglePanel.bind(this)}>
            <CustomText
              font="bold"
              style={[styles.textCenter, styles.marginBottom]}
            >
              {txt('fairs.seeList')}
            </CustomText>
          </CustomPressable>

          <View style={styles.marginBottom}>
            {this.state.expanded ? (
              <CustomText style={styles.textCenter}>
                {txt('fairs.list')}
              </CustomText>
            ) : null}
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { justifyContent: 'center' },
  dotContainer: { padding: 5 },
  image: {
    height: Layout.isSmallDevice ? 200 : 300,
    width: Layout.isSmallDevice ? 200 : 300,
    ...CornerStyles.roundImg,
    ...BorderStyles.blueBorder,
  },
  imageContainer: {
    height: Layout.isSmallDevice ? 250 : 400,
    width: Layout.isSmallDevice ? width : 400,
    ...CornerStyles.borderRadiusM,
    alignItems: 'center',
  },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  pagination: { borderRadius: 10, height: 12, width: 12 },
  row: { flexDirection: 'row' },
  textCenter: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  widthAndHeight: { ...SpacingStyles.widthAndHeight },
})
