// libraries
import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image, Linking, Dimensions } from 'react-native'
import { useSelector } from 'react-redux'

// api
import { getSamplePosts } from '../../services/api/ChristmasFriends'

// components
import { CustomText } from '../../components/text/StyledText'
import { ButtonPrimary, ButtonSecondary } from '../../components/buttons/StyledButtons'
import MainContainer from '../../components/MainContainer/MainContainer'
import CarouselPrimary from '../../components/carousels/CarouselPrimary'
import ImgHighlightChristmas from '../components/ImgHighlightChristmas'
import Heading from '../../components/text/Heading'
import Case from '../../components/cases/Case'
import NormalIcon from '../../components/icons/NormalIcon'

import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../../components/pressables/CustomPressable"

const { width } = Dimensions.get('window')
const ratio = width / 144.5 // original width of image (for image background)

export default function IntroChristmasScreen(props) {
  const userToken = useSelector((state) => state.userToken)
  const [christmasPosts, setChristmasPosts] = useState([])

  useEffect(() => {
    async function getChristmasPosts() {
      const posts = await getSamplePosts()
      return posts.posts
    }

    getChristmasPosts().then(setChristmasPosts)
  }, [])

  const renderChristmasPosts = (post) => {
    return (
      <View key={post.id} style={styles.postContainer}>
        <ImgHighlightChristmas
          size="large"
          type={post.type}
          imgFilename={post.imgFilename}
        />
        <CustomText
          font="smallBold"
          style={{ marginTop: 6 }}
          accessibilityLabel={'header'}
          aria-level={3}
        >
          {post.title}
        </CustomText>
        <CustomText style={{ marginTop: 6 }} numberOfLines={2} font="small">
          {post.body}
        </CustomText>
      </View>
    )
  }

  function RenderGuestHost(item) {
    return (
      <View
        style={[
          item.guest ? styles.guest : styles.host,
          styles.guestHostContainer,
        ]}
      >
        <Image
          style={Layout.isSmallDevice ? styles.signImage : styles.signImageWeb}
          resizeMode={'contain'}
          accessibilityIgnoresInvertColors
          source={item.image}
          accessible
          accessibilityRole="image"
          accessibilityLabel={item.label}
        />
        <View style={Layout.isSmallDevice ? '' : styles.guestHostTextContainer}>
          <Heading style={styles.headerLevel2} level={2}>
            {item.header}
          </Heading>
          <CustomText font={Layout.isSmallDevice ? 'small' : ''} style={styles.text}>
            {item.body}
          </CustomText>
          <ButtonPrimary
            title={
              item.guest
                ? txt('christmasFriends.intro.becomeGuest')
                : txt('christmasFriends.intro.becomeHost')
            }
            style={{ marginBottom: 24, backgroundColor: '#117325' }}
            titleStyle={styles.btnText}
            font="bold"
            onPress={handleSignup}
          />
        </View>
      </View>
    )
  }

  function handleSignup() {
    if (!userToken) props.navigation.navigate('SignupChristmas')
    else props.navigation.navigate('ChristmasTypeSelection')
  }

  function goBack() {
    props.navigation.goBack()
  }

  return (
    <MainContainer>
      <Image
        style={styles.backgroundImage}
        source={{ uri: 'https://filer.boblberg.dk/Assets/Img/julevenner/julevenner-banner.png' }}
        accessibilityIgnoresInvertColors
        accessibilityRole="image"
        accessibilityLabel={''}
      />
      <View style={{ paddingTop: (54.29 * ratio) / 2 }}>
        <View style={{ alignSelf: 'center', marginTop: 24 }}>
          {props.navigation.canGoBack() && (
            <CustomPressable onPress={goBack} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <NormalIcon
                name="chevron-left"
                color={Colors.blue}
                size={Fontsizes.m}
                stroke="fas"
              />
              <CustomText style={{ marginLeft: 6, color: Colors.blue, fontFamily: 'raleway-regular' }}>
                {txt('headerActions.backLabel')}
              </CustomText>
            </CustomPressable>
          )}
          <Heading style={styles.header} level={1}>{txt('christmasFriends.intro.welcome')}</Heading>
        </View>

        <View style={[styles.container, styles.introductionContainer]}>
          <Image
            style={Layout.isSmallDevice ? styles.image : styles.imageWeb}
            resizeMode={'contain'}
            accessibilityIgnoresInvertColors
            source={require('../../assets/images/Julevenner/Julevenner_logo_bg.png')}
            accessible
            accessibilityRole="image"
            accessibilityLabel={''}
          />
          <View style={{ maxWidth: Layout.isSmallDevice ? '100%' : '50%' }}>
            <Heading style={styles.headerLevel2} level={2}>
              {txt('christmasFriends.intro.introTitle')}
            </Heading>
            <CustomText font={Layout.isSmallDevice && 'small'} style={styles.text}>
              {txt('christmasFriends.intro.introText')}
            </CustomText>
            <ButtonPrimary
              title={txt('christmasFriends.intro.signupButton')}
              style={{ width: '100%', backgroundColor: '#117325' }}
              titleStyle={styles.btnText}
              font="bold"
              onPress={handleSignup}
            />
          </View>
        </View>

        {christmasPosts.length > 0 && (
          <View>
            <CarouselPrimary data={christmasPosts} renderData={({ item }) => renderChristmasPosts(item)} />
            <ButtonPrimary
              title={txt('christmasFriends.intro.createPost')}
              style={{ maxWidth: '80%', backgroundColor: '#117325' }}
              titleStyle={styles.btnText}
              font="bold"
              onPress={handleSignup}
            />
          </View>
        )}

        <View style={styles.casesContainer}>
          <View style={styles.container}>
            <Heading style={[styles.headerLevel2, styles.textCenter]} level={2}>
              {txt('christmasFriends.intro.cases.caseTitle')}
            </Heading>
            <Case
              img={{ uri: 'https://filer.boblberg.dk/Assets/Img/julevenner/julevenner-soeren.jpg' }}
              title={txt('christmasFriends.intro.cases.case1Title')}
              body={txt('christmasFriends.intro.cases.case1Body')}
              name={txt('christmasFriends.intro.cases.case1Name')}
              direction="right"
            />
            <Case
              img={{ uri: 'https://filer.boblberg.dk/Assets/Img/julevenner/julevenner-christina.jpg' }}
              title={txt('christmasFriends.intro.cases.case2Title')}
              body={txt('christmasFriends.intro.cases.case2Body')}
              name={txt('christmasFriends.intro.cases.case2Name')}
              direction="left"
            />
            {!Layout.isSmallDevice && (
              <Case
                img={{ uri: 'https://filer.boblberg.dk/Assets/Img/julevenner/julevenner-gitte.jpg' }}
                title={txt('christmasFriends.intro.cases.case3Title')}
                body={txt('christmasFriends.intro.cases.case3Body')}
                name={txt('christmasFriends.intro.cases.case3Name')}
                direction="right"
              />
			)}
            <ButtonSecondary
              title={txt('christmasFriends.intro.becomeChristmasFriend')}
              style={{ marginVertical: 24, borderColor: '#117325' }}
              titleStyle={[styles.btnText, { color: '#117325' }]}
              font="bold"
              onPress={handleSignup}
            />
          </View>
        </View>

        <View style={styles.container}>
          <RenderGuestHost
            guest
            header={txt('christmasFriends.intro.whatIsGuest')}
            image={require('../../assets/images/Julevenner/Julekrans_guest.png')}
            body={txt('christmasFriends.intro.guestDescription')}
            label={'Krans af gran'}
          />

          <RenderGuestHost
            header={txt('christmasFriends.intro.whatIsHost')}
            image={require('../../assets/images/Julevenner/Julehus_host.png')}
            body={txt('christmasFriends.intro.hostDescription')}
            label={'Hus af gran'}
          />
        </View>
		
				<View style={styles.whiteContainer}>
					<View style={styles.container}>
						<Heading style={styles.headerLevel2}>
							{txt('christmasFriends.intro.becomePartOf')}
						</Heading>
						<CustomText font={Layout.isSmallDevice ? 'small' : ''} style={styles.text}>
							{txt('christmasFriends.intro.becomePartOfBody')}  
						</CustomText>
						<ButtonSecondary
							title={txt('christmasFriends.intro.becomePartOfButton')}
							style={{ marginVertical: 12, borderColor: '#117325' }}
							titleStyle={[styles.btnText, { color: '#117325' }]}
							font="bold"
							onPress={handleSignup}
						/>
					</View>
				</View>

				<View style={styles.helpContainer}>
					<View style={styles.container}>
						<Heading style={styles.headerLevel2} level={2}>
							{txt('christmasFriends.contact.header')}
						</Heading>
						<CustomText font={Layout.isSmallDevice ? 'small' : ''} style={{ marginTop: 12 }}>
							{txt('christmasFriends.contact.intro')}
						</CustomText>

						<CustomText font='bold' style={styles.headerLevel3}>
							{txt('christmasFriends.contact.contactInfoHeader')}
						</CustomText>
						<CustomText font={Layout.isSmallDevice ? 'small' : ''}>
							{txt('christmasFriends.contact.emailLabel')}
							<CustomPressable
								onPress={() => Linking.openURL(`mailto:${txt('christmasFriends.contact.email')}`)}
							>
								<CustomText style={styles.link}>
									{txt('christmasFriends.contact.email')}
								</CustomText>
							</CustomPressable>
						</CustomText>
						<CustomText>
							{txt('christmasFriends.contact.phoneLabel')}
							<CustomPressable onPress={() => Linking.openURL(`tel:${+4535259200}`)}>
								<CustomText style={styles.link}>
									{txt('christmasFriends.contact.phone')}
								</CustomText>
							</CustomPressable>
						</CustomText>

						<CustomText font="bold" style={styles.headerLevel3}>
							{txt('christmasFriends.contact.openingHoursHeader')}
						</CustomText>
						<CustomText>{txt('christmasFriends.contact.openingHours')}</CustomText>
								
						<CustomText font="bold" style={styles.headerLevel3}>
							{txt('christmasFriends.contact.rkMatchHeader')}
						</CustomText>
						<CustomText>{txt('christmasFriends.contact.rkMatch')}</CustomText>
					</View>
				</View>

      </View>
    </MainContainer>
  )
}

const styles = StyleSheet.create({
	backgroundImage: {
		height: 54.29 * ratio, //original height of image
		left: 0,
		position: 'absolute',
		top: 0,
		width: width,
	},
	btnText: { textAlign: 'center' },
	casesContainer: {
		alignSelf: 'center',
		backgroundColor: Colors.white,
		flex: 1,
		marginTop: 48,
		width: '100%',
	},
	container: {
		alignSelf: 'center',
		justifyContent: 'space-between',
		marginHorizontal: 36,
		maxWidth: Layout.isSmallDevice ? '100%' : 900,
	},
	guest: { flexDirection: Layout.isSmallDevice ? 'column' : 'row' },
	guestHostContainer: { marginVertical: 24, gap: Layout.isSmallDevice ? 24 : 60 },
	guestHostTextContainer: { flex: 11 },
	header: {
		fontFamily: 'oswald-bold',
		fontSize: Layout.isSmallDevice ? 50 : 70,
		marginBottom: 24,
		marginTop: 6,
		textAlign: 'center',
		color: '#117325'
	},
	headerLevel2: {
		fontFamily: 'oswald-bold',
		fontSize: 30,
		lineHeight: 36,
		marginTop: 24,
		color: '#117325'
	},
	headerLevel3: {
		color: '#117325',
		marginTop: 12
	},
	helpContainer: {
		// backgroundColor: Colors.white,
		paddingVertical: 48,
	},
	whiteContainer: {
		backgroundColor: Colors.white,
		paddingVertical: 48,
	},
	host: { flexDirection: Layout.isSmallDevice ? 'column' : 'row-reverse' },
	image: { alignSelf: 'center', height: width * 0.3, width: width * 0.3 },
	imageWeb: { alignSelf: 'center', height: width * 0.12, width: width * 0.12 },
	introductionContainer: {
		alignSelf: 'center',
		flexDirection: Layout.isSmallDevice ? 'column' : 'row-reverse',
		marginVertical: 50,
	},
	postContainer: {
		alignItems: 'center',
		marginHorizontal: Layout.isSmallDevice ? 12 : 36,
		marginVertical: 24,
	},
	signImage: { alignSelf: 'center', height: 150, marginTop: 24, width: 150 },
	signImageWeb: { alignSelf: 'center', height: 200, marginTop: 24, width: 200 },
	text: { marginVertical: 24, lineHeight: 20 },
	textCenter: { textAlign: 'center' },
	link: { color: Colors.blueAzure },
})
