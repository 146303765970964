import React from 'react'
import Conditions from '../../components/views/Conversations/Conditions'
import MainContainer from '../../components/MainContainer/MainContainer'
export default class ConditionsScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <MainContainer>
        <Conditions navigation={this.props.navigation} />
      </MainContainer>
    )
  }
}
