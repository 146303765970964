import React from 'react'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { RoundBtn } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'

export default function ({ navigateToBobls, navigateToFrontpage, signedIn }) {
  const handleActionPress = () => {
    if (signedIn) {
      navigateToBobls()
    } else {
      navigateToFrontpage()
    }
  }

  return (
    <View style={styles.container}>
      <CustomText style={styles.textIllustration}>{':-('}</CustomText>
      <CustomText font={'large'} style={styles.text}>
        {txt('postNotFound.body')}
      </CustomText>
      <RoundBtn
        title={
          signedIn
            ? txt('postNotFound.goToBobls')
            : txt('postNotFound.goToHome')
        }
        onPress={handleActionPress}
        style={styles.button}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    padding: 24,
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    maxWidth: 400,
    marginBottom: 60,
  },
  textIllustration: {
    fontSize: 60,
    marginBottom: 24,
  },
  text: {
    textAlign: 'center',
  },
  button: {
    marginTop: 36,
  },
})
