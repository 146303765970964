import React from 'react'
import { View, Image, Linking, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import { SpacingStyles } from '../../../styles'
import MainContainer from '../../MainContainer/MainContainer'
import CustomPressable from "../../pressables/CustomPressable"

const partnersData = [
	{
		name: 'ABCMentalSundhed',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/ABCMentalSundhed.png',
	},
	{
		name: 'AdipositasForeningen',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/AdipositasForeningen.png',
	},
	{
		name: 'Arbejdermuseet',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Arbejdermuseet.png',
	},
	{
		name: 'BørneBuddies',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/BørneBuddies.png',
	},
	{
		name: 'CHC',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/CHC.png',
	},
	{
		name: 'Copa',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Copa.png',
	},
	{
		name: 'DaghøjskolenForSeniorer',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/DaghøjskolenForSeniorer.png',
	},
	{
		name: 'DLSC',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/dlsc.png',
		},
	{
		name: 'DrugRebels',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/DrugRebels.png',
	},
	{
		name: 'FamilieKlub',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/FamilieKlub.png',
	},
	{
		name: 'FrederiksbergMuseerne',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/FrederiksbergMuseerne.png',
	},
	{
		name: 'Fremdrift',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Fremdrift.png',
	},
	{
		name: 'GirlTalk',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/GirlTalk.png',
	},
	{
		name: 'GivingTuesday',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/GivingTuesday.png',
	},
	{
		name: 'HivDanmark',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/HivDanmark.png',
	},
	{
		name: 'Hjerteforeningen',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Hjerteforeningen.png',
	},
	{
		name: 'Idan',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Idan.png',
	},
	{
		name: 'LandsforeningenLEV',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/LandsforeningenLEV.png',
	},
	{
		name: 'LærForLivet',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/LærForLivet.png',
	},
	{
		name: 'Misbrugsportalen',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Misbrugsportalen.png',
	},
	{
		name: 'Modensomhed',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Modensomhed.png',
	},
	{
		name: 'NSCC',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/NSCC.png',
	},
	{
		name: 'SindUngdom',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/SindUngdom.png',
	},
	{
		name: 'SOMS',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/SOMS.png',
	},
	{
		name: 'Startlinjen',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Startlinjen.png',
	},
	{
		name: 'SUMH',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/SUMH.png',
	},
	{
		name: 'Veteran',
		uri: 'https://filer.boblberg.dk/Assets/Img/partners/Veteran.png',
	},
]

class Panel extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<View style={{ padding: 15 }}>
				<CustomPressable
					accessibilityLabel={this.props.name}
					accessibilityHint={txt('partners.partnersHint') + this.props.name}
					onPress={() => this.props.pressF(this.props.name)}
				>
					<Image
					source={{ uri: this.props.uri }}
					resizeMode="contain"
					style={styles.partnerImage}
					accessibilityIgnoresInvertColors
					/>
				</CustomPressable>
			</View>
		)
	}
}

export default class PartnersMenu extends React.Component {
	constructor(props) {
		super(props)
	}

	navigateToPost = (partner) => {
		if (['SOMS', 'DaghøjskolenForSeniorer'].includes(partner)) return
		this.props.navigation.push('Partners', { id: partner })
	}

	render() {
		return (
			<MainContainer>
				<PageTitle title={TxtTMD.partners.title[TxtTMD.lang]} />

				<View style={styles.widthHeight}>
					<View style={{ margin: 10 }}>
						<CustomText>{txt('partners.introduction')}</CustomText>
						<Image
							style={styles.logo}
							source={require('../../../assets/images/icon.png')}
							accessibilityIgnoresInvertColors
							accessible
							accessibilityRole="image"
							accessibilityLabel={txt('partners.logo')}
						/>
						<CustomText>{txt('partners.contact')}
							<CustomText
								style={{marginTop: 10, color: Colors.blue }}
								titleStyle={styles.linkText}
								onPress={() => Linking.openURL('mailto:support@boblberg.dk')}
							>
								{txt('partners.infoMail')}
							</CustomText>
						</CustomText>
						<CustomText accessibilityRole="link" href="#">
							{txt('partners.readMore')}
							<CustomText
								style={styles.linkText}
								onPress={() => this.props.navigation.navigate('MunicipalityInfo')}
							>
								{txt('partners.clickHere')}
							</CustomText>
						</CustomText>
						<CustomText>{txt('partners.currentPartners')}</CustomText>
					</View>
					<View style={styles.partnerContainer}>
						{partnersData.map((image, i) => {
							return (
								<Panel
									uri={partnersData[i].uri}
									pressF={this.navigateToPost}
									key={i}
									name={image.name}
								/>
							)
						})}
					</View>
				</View>
			</MainContainer>
		)
	}
}

const styles = StyleSheet.create({
	linkText: { color: Colors.tintColor },
	logo: { alignSelf: 'center', height: 150, width: 150 },
	partnerContainer: {
		alignItems: 'center',
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		paddingBottom: 10,
	},
	partnerImage: { height: 120, width: 150 },
	widthHeight: { ...SpacingStyles.widthAndHeight },
})
