import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import { RoundSquareBtn } from '../../components/buttons/StyledButtons'
import PageTitle from '../../components/tags/PageTitle'
import TxtTMD from '../../constants/TxtTMD'
import Layout from '../../constants/Layout'
import { ScrollView } from 'react-native'
import store from '../../redux/store'

export default class GroupsIntroScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  async componentDidMount() {
    const userToken = store.getState().userToken
    this.setState({ userToken })
  }

  handleGetStartedClick = () => {
    if (this.state.userToken) {
      this.props.navigation.navigate('Groups')
    } else {
      this.props.navigation.navigate('GroupsSignup')
    }
  }

  render() {
    return (
      <ScrollView style={styles.container}>
        <PageTitle title={TxtTMD.groups.title[TxtTMD.lang]} />
        <View style={styles.marginLeftRight}>
          <CustomText style={styles.text} text={txt('groups.intro.text')} />
          <View style={styles.btnMargin}>
            <RoundSquareBtn
              title={txt('groups.intro.signUpBtn')}
              onPress={this.handleGetStartedClick}
            />
          </View>

          <CustomText
            font="bold"
            style={styles.listHeader}
            text={txt('groups.intro.listHeader')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem1')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem2')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem3')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem4')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem5')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem6')}
          />
          <CustomText
            style={styles.listItem}
            text={txt('groups.intro.listItem7')}
          />

          <CustomText
            font="bold"
            style={styles.boxTitle}
            text={txt('groups.intro.boxTitle')}
          />

          <View style={styles.boxContainer}>
            <View style={styles.box}>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/groups/groups-intro-board.png',
                }}
                resizeMode="contain"
                style={styles.image}
                accessibilityIgnoresInvertColors
              />
              <CustomText
                font="bold"
                style={styles.boxHeader}
                text={txt('groups.intro.boxHeader2')}
              />
              <CustomText text={txt('groups.intro.box2')} />
            </View>

            <View style={styles.box}>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/groups/groups-intro-members.png',
                }}
                resizeMode="contain"
                style={styles.image}
                accessibilityIgnoresInvertColors
              />
              <CustomText
                font="bold"
                style={styles.boxHeader}
                text={txt('groups.intro.boxHeader1')}
              />
              <CustomText text={txt('groups.intro.box1')} />
            </View>

            <View style={styles.box}>
              <Image
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/groups/groups-intro-chat.png',
                }}
                resizeMode="contain"
                style={styles.image}
                accessibilityIgnoresInvertColors
              />
              <CustomText
                font="bold"
                style={styles.boxHeader}
                text={txt('groups.intro.boxHeader3')}
              />
              <CustomText text={txt('groups.intro.box3')} />
            </View>
          </View>
        </View>
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  box: {
    alignSelf: 'center',
    marginBottom: 30,
    maxWidth: 400,
    textAlign: 'center',
    width: '100%',
  },
  boxContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  boxHeader: {
    color: Colors.text,
    marginBottom: 5,
    textAlign: 'center',
  },
  boxTitle: {
    color: Colors.text,
    marginTop: 20,
    textAlign: 'center',
  },
  btnMargin: {
    alignSelf: 'center',
    marginVertical: 20,
    maxWidth: 400,
  },
  container: {
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
  },
  image: {
    alignSelf: 'center',
    height: 80,
    marginBottom: 5,
    width: 80,
  },
  listHeader: {
    color: Colors.text,
    marginBottom: 10,
    textAlign: 'center',
  },
  listItem: {
    color: Colors.text,
    marginBottom: 5,
    textAlign: 'center',
  },
  marginLeftRight: { marginLeft: 10, marginRight: 10 },
  text: {
    color: Colors.text,
    marginBottom: 10,
    textAlign: 'center',
  },
})
