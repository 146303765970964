import React from 'react'
import { View, StyleSheet, Image, Platform } from 'react-native'
import { CustomText } from '../../text/StyledText'
import PageTitle from '../../tags/PageTitle'
import { formatForMetaTitle } from '../../text/TextFormatter'
import { ShadowStyles, SpacingStyles } from '../../../styles'
import { SignInCardCall } from '../../callToActions/signInCardCall'
import { useSelector } from 'react-redux'
import { getStory } from '../../../services/api/Stories'
import { useState, useEffect } from 'react'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import Paths from '../../../constants/Paths'
import { URLOpener } from '../../../utils/URLOpener'
import Colors from "../../../constants/Colors"

function styleQuotes(text) {
  return text.split('\n').map((subText, index) => {
    const type =
      subText[0] === '”' || subText[0] === '“' || subText[0] === '"'
        ? 'qoute'
        : subText[0] === '-' || subText[0] === '–'
        ? 'author'
        : 'text'

    if (!subText.length) return

    return (
      <CustomText style={styles[type]} key={index}>
        {subText}
      </CustomText>
    )
  })
}

export default function StoryFull(props) {
  const [story, setStory] = useState(null)

  const userToken = useSelector((state) => state.userToken)
  const navigateToFrontpage = () => {
    return Platform.OS === 'web'
      ? props.navigation.navigate('Frontpage')
      : URLOpener('https://boblberg.dk')
  }
  function navigateToStories() {
    props.navigation.navigate('Stories')
  }

  useEffect(() => {
    getStory(props.id).then(setStory)
  }, [props.id])

  if (!story) return <LoadingSpinner />

  return (
    <View>
      <PageTitle title={formatForMetaTitle(story.title)} />

      <View style={styles.widthHeight}>
        <View style={styles.marginLeftRight}>
          <Image
            source={{ uri: Paths.storyUrlBase + story?.filename }}
            resizeMode="cover"
            style={styles.storyImage}
            accessibilityIgnoresInvertColors
          />

          <CustomText font="largeBold" style={styles.title}>
            {story?.title}
          </CustomText>

          {styleQuotes(story?.body)}
          {!userToken && <SignInCardCall onPress={navigateToFrontpage} />}

          {userToken && (
            <ButtonPrimary
              onPress={navigateToStories}
              title={txt('story.readmoreBtn')}
              style={{paddingVertical: 10}}
              icon="chevron-left"
              stroke="fal"
            />
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  author: {
    marginBottom: 24,
    marginTop: 12,
    textAlign: 'center',
  },
  marginLeftRight: {
    marginHorizontal: 12,
    ...ShadowStyles.cardShadow,
    borderRadius: 6,
    backgroundColor: Colors.white,
    padding: 24,
    paddingTop: 12,
  },
  qoute: {
    fontFamily: 'raleway-italic',
    lineHeight: 25,
    marginTop: 12,
    textAlign: 'center',
    maxWidth: 500,
    alignSelf: 'center',
  },
  storyImage: {
    alignSelf: 'center',
    borderRadius: 113,
    height: 225,
    marginBottom: 10,
    marginTop: 10,
    width: 225,
    // ...BorderStyles.blueBorder,
  },
  text: {
    lineHeight: 25,
    marginBottom: 30,
    marginTop: 10,
    textAlign: 'left',
  },
  title: {
    marginBottom: 5,
  },
  widthHeight: {
    ...SpacingStyles.widthAndHeight,
    marginTop: 12,
    marginBottom: 12,
  },
})
