import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { Platform, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { ButtonPrimary } from '../buttons/StyledButtons'
import { txt } from '../../locales/i18n'
import { updateUser } from '../../services/api/User'
import AsyncStorage from '@react-native-async-storage/async-storage'
import CustomPressable from "../pressables/CustomPressable"

export default function ChooseGenderDialog() {
  const userToken = useSelector((state) => state.userToken)
  const gender_id = useSelector((state) => state.user?.gender_id)
  const [visible, setVisible] = useState(false)
  const [gender, setGender] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (userToken) {
      setTimeout(openDialog, 3000)
    }
  }, [userToken])

  const openDialog = async () => {
    let chooseGenderFlag
    if (Platform.OS === 'web') chooseGenderFlag = Cookies.get('hideGenderDialog')
    else chooseGenderFlag = await AsyncStorage.getItem('hideGenderDialog')
    const chooseGenderFlagDate = new Date(chooseGenderFlag)
    const laterChooseGenderFlagDate = new Date(chooseGenderFlagDate)?.setDate(new Date().getDate() + 30)
    if (userToken !== null && gender_id === 0 && (!chooseGenderFlag || new Date() > laterChooseGenderFlagDate)) {
      setVisible(true)
    }
  }

  async function handleChooseGender() {
    setLoading(true)
    const success = await updateUser({ gender_id: gender })
    if (success) setVisible(false)
    setLoading(false)
  }

  async function handleCloseDialog() {
    if (Platform.OS !== 'web') await AsyncStorage.setItem('hideGenderDialog', JSON.stringify(new Date()))
    else Cookies.set('hideGenderDialog', JSON.stringify(new Date()))
    setVisible(false)
  }

  function Gender({ checked, onPress, text }) {
    return (
      <CustomPressable
        style={[styles.gender, checked && styles.selected]}
        accessibilityRole={'radio'}
        onPress={onPress}
      >
        <View style={{ borderWidth: 2, borderColor: Colors.blackTransparent2, borderRadius: 60, padding: 3 }}>
          <NormalIcon
            name="circle"
            stroke="fas"
            size={12}
            color={checked ? Colors.blueAzure : Colors.transparent}
          />
        </View>
        <CustomText font="bold" style={{ paddingLeft: 12 }}>{text}</CustomText>
      </CustomPressable>
    )
  }

  return (
    <CustomDialog visible={visible}>
      <View style={{ margin: 24 }}>
        <CustomPressable
          style={{ alignSelf: 'flex-end', position: 'absolute', paddingLeft: 24, zIndex: 1 }}
          accessibilityRole="button"
          onPress={handleCloseDialog}
        >
          <NormalIcon name="times" color={Colors.inactive} />
        </CustomPressable>
        <CustomText
          style={{ fontSize: 30, fontFamily: 'raleway-semibold' }}>{txt('overlay.chooseGender.title')}</CustomText>
        <CustomText style={{ marginTop: 12 }}>
          {txt('overlay.chooseGender.description')}
        </CustomText>
        <CustomText
          style={{ marginVertical: 12 }}
          font="smallItalic"
        >
          {txt('overlay.chooseGender.changeLater')}
        </CustomText>
        <Gender
          checked={gender === 1}
          onPress={() => setGender(1)}
          color={Colors.tintColor}
          text={txt('overlay.chooseGender.male')}
        />
        <Gender
          checked={gender === 2}
          onPress={() => setGender(2)}
          color={Colors.tintColor}
          text={txt('overlay.chooseGender.female')}
        />
        <Gender
          checked={gender === 3}
          onPress={() => setGender(3)}
          color={Colors.tintColor}
          text={txt('overlay.chooseGender.other')}
        />
      </View>
      <View style={{ paddingBottom: 24 }}>
        <ButtonPrimary
          disabled={gender === null || loading}
          onPress={handleChooseGender}
          style={{ backgroundColor: Colors.blueAzure }}
          title={txt('overlay.chooseGender.button')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  gender: {
    backgroundColor: Colors.white,
    padding: 12,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.transparent,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: Colors.greyLightLight,
  },
})