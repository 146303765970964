import React from 'react'
import { getUniverseConfig } from '../../../universe/universeController'
import Notifications from '../../../components/views/Settings/Notifications'

export default function NotificationsUniverseScreen(props) {
  const universeObject = getUniverseConfig(
    props.route.params?.universe?.toLowerCase()
  )
   return <Notifications type={props.route.params.id} universe={universeObject} />
}
