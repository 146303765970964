import React from 'react'
import { View, StyleSheet, Image, Dimensions } from 'react-native'
import { CustomText } from '../../../text/StyledText'
import { ProjectStyles } from './ProjectStyles'
import PageTitle from '../../../tags/PageTitle'
import { URLOpener } from '../../../../utils/URLOpener'
import Colors from '../../../../constants/Colors'

const { width } = Dimensions.get('window')
const small = width < 800

export default function AnalyseAfMentalMistrivsel() {
	return (
		<View style={ProjectStyles.widthHeight}>
			<PageTitle title={'Analyse af mental mistrivsel blandt brugerne på Boblberg'} />
			<View style={ProjectStyles.container}>
				<CustomText
					style={ProjectStyles.blogTitle}
					accessibilityRole="header"
					aria-level={1}
				>
					{'Analyse af mental mistrivsel blandt brugerne på Boblberg'}
				</CustomText>
				<CustomText style={ProjectStyles.blogText}>
					{'Med støtte fra TrygFonden har Analyse & Tal undersøgt brugernes mentale sundhed på Boblberg, en netværksplatform skabt til at bekæmpe ensomhed og fremme mental sundhed.'}
				</CustomText>
				<CustomText style={ProjectStyles.blogText}>
					{'Rapporten tager udgangspunkt i næsten 2 millioner bobler og beskeder som ved hjælp af en AI sprogmodel er blevet undersøgt for selvoplyst mentale udfordringer. Som grafikken nedenfor illustrerer, konkluderer rapporten, at 40% af brugerne oplever mental mistrivsel, mens 17% er uden for beskæftigelse, og at 11% lider af fysiske helbredsproblemer. Når man ser nærmere på de 40% af brugerne, der oplever mental mistrivsel, viser rapporten desuden, at 37% af disse føler sig ensomme, 11% er psykisk sårbare, og at 8% føler sig både ensomme og psykisk sårbare.'}
				</CustomText>
				<Image
					accessibilityIgnoresInvertColors
					style={styles.image}
					source={{ uri: 'https://filer.boblberg.dk/Assets/Img/projects/trygfonden-projekt.jpg' }}
					resizeMode="contain"
				/>
				<CustomText style={ProjectStyles.blogText}>
					{'Rapporten understreger tydeligt, at Boblberg spiller en afgørende rolle ved at facilitere direkte borger-til-borger-kontakt blandt dem, der af forskellige årsager har udfordringer med at engagere sig i traditionelle fællesskaber. Dette gør platformen til et unikt alternativ og supplement til andre lokale organisationer.'}
				</CustomText>
				<CustomText style={ProjectStyles.blogText}>
					{'Herunder finder du rapporten, der detaljeret beskriver resultaterne af analysen. Desuden er der en optagelse tilgængelig fra den konference, der blev afholdt ifm. lanceringen af analysen, hvor resultaterne blev præsenteret og drøftet i et panel bestående af ungdomsforsker Niels Ulrik Sørensen fra Aalborg Universitet, Morten Sodemann professor og overlæge ved Syddansk Universitet samt Geske Fischer-Hansen direktør i Ungdommens Røde Kors.'}
				</CustomText>
				<CustomText style={ProjectStyles.blogText}>
					{'Boblberg, som har haft over en halv million oprettede profiler siden lanceringen i 2016, muliggør nye og trygge relationer gennem opslag ("bobler") og private beskeder. Dette har resulteret i næsten 2 millioner udvekslede beskeder i 2023, hvilket viser et stort behov for at etablere nye relationer. Mange brugere står uden for gængse fællesskaber pga. arbejdsløshed eller helbredsproblemer, og Boblberg fungerer netop som en tryg indgang til sociale netværk, der forbedrer deres mentale trivsel.'}
				</CustomText>

				<CustomText style={ProjectStyles.blogText}>
					{'Læs den fulde rapport '}
					<CustomText
						style={ProjectStyles.link}
						accessibilityRole="link"
						onPress={() => URLOpener('https://filer.boblberg.dk/Files/Documents/Analyse-af-mental-mistrivsel-blandt-brugerne-paa-Boblberg.pdf')}
					>
						{'her'}
					</CustomText>
				</CustomText>
				<CustomText style={ProjectStyles.blogText}>
					{'Se konferencen '}
					<CustomText
						style={styles.link}
						accessibilityRole="link"
						onPress={() => URLOpener('https://filer.boblberg.dk/video/konference/online-konference-analyse-af-mental-mistrivsel-blandt-brugerne-paa-boblberg.mp4')}
					>
						{'her'}
					</CustomText>
				</CustomText>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	image: {
		alignSelf: 'center',
		width: '100%',
		height: small ? 200 : 480,
		marginTop: 12,
		marginBottom: 24,
	},
	link: {
		fontSize: 16,
		color: Colors.tintColor,
		textDecorationLine: 'underline',
	},
})
