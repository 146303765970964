import React from 'react'
import MainContainer from '../../components/MainContainer/MainContainer'
import { CustomText } from '../../components/text/StyledText'
import { StyleSheet, View } from 'react-native'
import Layout from '../../constants/Layout'
import { txt } from '../../locales/i18n'
import { useSelector } from 'react-redux'
import NormalIcon from '../../components/icons/NormalIcon'
import Colors from '../../constants/Colors'
import Heading from '../../components/text/Heading'

export default function AfterMatchChristmasScreen() {
  const type = useSelector((state) => state.christmas?.post.type)
  const host = type === 'host'
  const RenderItem = ({ title, body, reverse, icon, color }) => {
    const direction = reverse ? 'direction' : 'reverseDirection'
    return (
      <View style={[styles.itemContainer, styles[direction]]}>
        <View style={[styles.iconContainer, Layout.isSmallDevice ? {} : { flex: 1 }]}>
          <NormalIcon
            name={icon}
            color={color}
            size={Layout.isSmallDevice ? 150 : 200}
            style={{ marginVertical: 24 }}
            stroke="fad"
          />
        </View>
        <View style={Layout.isSmallDevice ? {} : { flex: 1 }}>
          <Heading level={2}>
            {title}
          </Heading>
          <CustomText>{body}</CustomText>
        </View>
      </View>
    )
  }

  return (
    <MainContainer>
      {host ? (
        <View style={styles.container}>
          <RenderItem
            title={txt('christmasFriends.afterMatch.introTitle')}
            body={txt('christmasFriends.afterMatch.hostIntro')}
            icon="hat-santa"
            color={Colors.red}
          />
          <RenderItem
            title={txt('christmasFriends.afterMatch.hostContactTitle')}
            body={txt('christmasFriends.afterMatch.hostContact')}
            icon="phone-alt"
            color={Colors.blue}
            reverse
          />
          <RenderItem
            title={txt('christmasFriends.afterMatch.hostHelpTitle')}
            body={txt('christmasFriends.afterMatch.hostHelp')}
            icon="bags-shopping"
            color={Colors.green}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <RenderItem
            title={txt('christmasFriends.afterMatch.introTitle')}
            body={txt('christmasFriends.afterMatch.guestIntro')}
            icon="hat-santa"
            color={Colors.red}
          />

          <RenderItem
            title={txt('christmasFriends.afterMatch.guestHelpTitle')}
            body={txt('christmasFriends.afterMatch.guestHelp')}
            icon="bags-shopping"
            color={Colors.blue}
            reverse
          />

          <RenderItem
            title={txt('christmasFriends.afterMatch.guestPresentTitle')}
            body={txt('christmasFriends.afterMatch.guestPresent')}
            icon="gifts"
            color={Colors.green}
          />
        </View>
      )}
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    maxWidth: Layout.maxFeedWidth,
    alignSelf: 'center',
    alignItems: 'center',
    width: Layout.isSmallDevice ? '90%' : '100%',
  },
  iconContainer: {
    alignItems: 'center'
  },
  itemContainer: {
    alignItems: 'center',
    marginVertical: 24,
    maxWidth: '100%',
  },
  direction: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row'
  },
  reverseDirection: {
    flexDirection: Layout.isSmallDevice ? 'column' : 'row-reverse'
  },
})
