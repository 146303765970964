import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import { URLOpener } from '../../../utils/URLOpener'
import { trackPlausibleEvent } from '../../../services/api/plausible'

export default function LaerAtTackle() {
	const handleClick = (url, linkIndex) => {
		trackPlausibleEvent('HerlevOfferClick', { origin: `LaerAtTackle-link${linkIndex}` }, 'herlev/HerlevOffers')
		URLOpener(url)
	}

	return (
		<View style={styles.partnerInfoContainer}>
			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/laer-at-tackle.png' }}
				resizeMode="contain"
				style={[styles.image, styles.partnerInfoHeader]}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
				accessibilityLabel={txt('universe.herlev.laerAtTackle')}
			/>
			<CustomText font="largeBold" style={styles.partnerInfoTitle}>
				{txt('universe.herlev.partnerInfo.laerAtTackle.title')}
			</CustomText>
			<CustomText style={{ marginBottom: 24 }}>{txt('universe.herlev.partnerInfo.laerAtTackle.body1')}</CustomText>
			<CustomText>{txt('universe.herlev.partnerInfo.laerAtTackle.body2')}</CustomText>
			<CustomText
				style={[styles.link, { marginBottom: 24 }]}
				onPress={() => handleClick('mailto:heidi.kappel@herlev.dk', 1)}
			>
				{'heidi.kappel@herlev.dk'}
				<CustomText>{' / '}</CustomText>
				<CustomText style={styles.link} onPress={() => handleClick('tel:+4544526265', 2)}>
					{'+45 4452 6265'}
				</CustomText>
			</CustomText>
			<CustomText>
				{txt('universe.herlev.partnerInfo.laerAtTackle.readMore')}
				<CustomText style={styles.link} onPress={() => handleClick('https://www.laerattackle.dk/boblberg', 3)}>
					{txt('universe.herlev.partnerInfo.laerAtTackle.linkText')}
				</CustomText>
			</CustomText>

			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/laer-at-tackle-2.jpg' }}
				resizeMode="contain"
				style={styles.imageBottom}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	image: {
		alignSelf: 'center',
		height: 60,
		marginVertical: 6,
		maxWidth: 300,
		minWidth: 200,
	},
	imageBottom: {
		alignSelf: 'center',
		height: 250,
		width: 250,
	},
	link: {
		color: Colors.blueAzure,
	},
	partnerInfoContainer: {
		alignSelf: 'center',
		flex: 1,
		maxWidth: 600,
		width: '100%',
	},
	partnerInfoHeader: {
		marginBottom: 24,
	},
	partnerInfoTitle: {
		marginBottom: 12,
		marginTop: 12,
	},
})
