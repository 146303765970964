import React from 'react'
import Activity from '../../../components/views/Activities/Activity'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'

export default function ActivityUniverseScreen(props) {
  let universe = props.route.params?.universe?.toLowerCase()

  function navigateToEditActivity(activityId) {
    props.navigation.navigate('UniverseActivityStack', {
      screen: 'EditActivity',
      initial: false,
      params: {
        id: activityId,
        universe: universe,
      },
    })
  }

  const universeObject = getUniverseConfig(
    props.route.params.universe?.toLowerCase()
  )

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <Activity
        navigation={props.navigation}
        id={props.route.params.id}
        date={props.route.params.date}
        navigateToEditActivity={navigateToEditActivity}
        route={props.route}
      />
    </MainContainer>
  )
}
