import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FlatList, Image, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import PageTitle from '../../components/tags/PageTitle'
import { CustomText } from '../../components/text/StyledText'
import { SearchBar } from '../../components/events/SearchBar'
import { EventListItem } from '../../components/events/EventListItem'
import MainContainer from '../../components/MainContainer/MainContainer'
import LoadingSpinner from '../../components/activityIndicators/LoadingSpinner'
import CustomDialog, { ContentContainer, ScrollableDialog } from '../../components/dialogs/CustomDialog'
import { ButtonPrimary } from '../../components/buttons/StyledButtons'

import NormalIcon from '../../components/icons/NormalIcon'

import { txt } from '../../locales/i18n'
import TxtTMD from '../../constants/TxtTMD'
import Layout from '../../constants/Layout'
import Colors from '../../constants/Colors'
import * as EventService from '../../services/api/Events'
import CustomPressable from "../../components/pressables/CustomPressable"
import { URLOpener } from "../../utils/URLOpener"
import Paths from "../../constants/Paths"
import { useNavigation, useRoute } from "@react-navigation/core"
import { getUniverseConfig, getUniverseId } from "../../universe/universeController"
import { getSubscription } from "../../services/api/User"
import { ShadowStyles } from "../../styles"

export const EventFeedScreen = () => {
  const user = useSelector(({ user }) => user)
  const navigation = useNavigation()
  const userToken = useSelector((state) => state.userToken)
  moment.locale(user?.language ?? 'da')

  const [events, setEvents] = useState([])
  const [plusMemberEvents, setPlusMemberEvents] = useState([])
  const [combinedEvents, setCombinedEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [sorting, setSorting] = useState('date')
  const [search, setSearch] = useState('')
  const [showSortDialog, setShowSortDialog] = useState(false)
  const [activeSubscription, setActiveSubscription] = useState(false)
  const [showPlusMemberInfoDialog, setShowPlusMemberInfoDialog] = useState(false)
  const searchTimeout = useRef(null)

  const [universe, setUniverse] = useState(null)
  const [universeObject, setUniverseObject] = useState(null)
  const route = useRoute()

  useEffect(() => {
    getSubscription().then((sub) => {
      setActiveSubscription(sub?.state === 'active' || sub?.state === 'cancelled')
    })

    let universeName = route.params?.universe?.toLowerCase()
    if (!universeName) return

    const universeId = getUniverseId(universeName)
    setUniverse({ id: universeId, name: universeName })
    setUniverseObject(getUniverseConfig(universeName))

  }, [navigation, route.params])

  const getEvents = useCallback(async () => {
    setIsLoading(true)

    const events = await EventService.searchEvents({ search, sorting })
    setEvents(events)

    const plusMemberEvents = await EventService.searchEvents({ search, sorting, plusMember: true })
    setPlusMemberEvents(plusMemberEvents)

    setIsLoading(false)
  }, [search, sorting, setIsLoading, setEvents, setPlusMemberEvents])

  useEffect(() => {
    if (searchTimeout.current !== null) {
      clearTimeout(searchTimeout.current)
    }

    searchTimeout.current = setTimeout(getEvents, 350)
  }, [search, sorting])

  const weave = (arr1, arr2) => {
    const weaved = [...arr1]
    const steps = 3

    for (let index = 0; index < arr2.length; index++) {
      const element = arr2[index]
      const position = (index + 1) * steps - 1
      weaved.splice(position, 0, element)
    }

    return weaved
  }

  useEffect(() => {
    if (events && plusMemberEvents) {
      const combined = weave(events, plusMemberEvents)
      setCombinedEvents(combined)
    }
  }, [events, plusMemberEvents])

  const navigateToEvent = async (eventId) => {
    if (userToken) {
      const params = { eventId }
      if (universe?.name) params.universe = universe.name
      navigation.navigate('Event', params)
    }
    else await URLOpener(Paths.getBaseUrl() + `events/${eventId}`)
  }

  const renderEvent = ({ item: event }) => {
    return <EventListItem key={event.id} event={event} navigateToEvent={() => navigateToEvent(event.id)} />
  }

  const renderSearchBar = () => (
    <View style={styles.searchBarContainer}>
      <SearchBar value={search} onChange={setSearch} onClear={() => setSearch('')} />

      <CustomPressable
        accessibilityRole="button"
        accessibilityHint={txt('events.sortHint')}
        accessibilityLabel={txt('events.sortLabel')}
        onPress={() => setShowSortDialog(true)}
        style={styles.searchBarSortButton}
      >
        <NormalIcon stroke="fad" name="sort-down" color={Colors.blueAzure} />
      </CustomPressable>
    </View>
  )

  const renderListFooter = () => {
    if (isLoading) {
      return (
        <View style={styles.footerContainer}>
          <LoadingSpinner />
        </View>
      )
    }

    if (events.length === 0) return null

    return (
      <View style={styles.footerContainer}>
        <CustomText style={styles.textAlignCenter}>{txt('events.listFooter')}</CustomText>
      </View>
    )
  }

  const renderListEmpty = () => (
    <View style={styles.footerContainer}>
      <CustomText style={styles.textAlignCenter}>{txt('events.listEmpty')}</CustomText>
    </View>
  )

  return (
    <>
      <PageTitle title={TxtTMD.events.title[TxtTMD.lang]} />
      <MainContainer backgroundColor={universeObject?.backgroundColor}>
        <View style={styles.container}>
          {renderSearchBar()}

          {activeSubscription && (
            <CustomPressable onPress={() => setShowPlusMemberInfoDialog(true)} style={styles.plusMemberBanner}>
              <CustomText font="small">{txt('events.plusMemberCard1')}</CustomText>
              <CustomText font="smallBold" style={{color: Colors.orange}}>{txt('events.plusMemberCard2')}</CustomText>
              <CustomText font="small">{txt('events.plusMemberCard3')}</CustomText>
            </CustomPressable>
          )}

          <FlatList
            data={activeSubscription ? combinedEvents : events}
            onRefresh={getEvents}
            refreshing={isLoading}
            renderItem={renderEvent}
            style={styles.eventList}
            ListFooterComponent={renderListFooter}
            ListEmptyComponent={isLoading === false && renderListEmpty}
            numColumns={Layout.small ? 1 : 2}
          />
        </View>
      </MainContainer>

      <CustomDialog visible={showSortDialog} onDismiss={() => setShowSortDialog(false)} style={styles.sortDialog}>
        <View style={styles.sortDialogContent}>
          <CustomText font="largeBold">{txt('events.sortDialog.title')}</CustomText>

          <View style={styles.sortDialogSelectionsContainer}>
            <CustomPressable
              accessibilityRole="button"
              disabled={sorting === 'date'}
              onPress={() => setSorting('date')}
              style={styles.sortDialogSelectionContainer}
            >
              <NormalIcon stroke="fad" name="calendar-day" color={Colors.blueAzure} />
              <CustomText style={styles.sortDialogSelectionText}>{txt('events.sortDialog.date')}</CustomText>

              <NormalIcon
                name="circle"
                stroke={sorting === 'date' ? 'fad' : 'far'}
                color={sorting === 'date' ? Colors.blueAzure : Colors.greyLight}
              />
            </CustomPressable>

            <CustomPressable
              accessibilityRole="button"
              disabled={sorting === 'distance'}
              onPress={() => setSorting('distance')}
              style={styles.sortDialogSelectionContainer}
            >
              <NormalIcon stroke="fad" name="map-marker-alt" color={Colors.blueAzure} />
              <CustomText style={styles.sortDialogSelectionText}>{txt('events.sortDialog.distance')}</CustomText>

              <NormalIcon
                name="circle"
                stroke={sorting === 'distance' ? 'fad' : 'far'}
                color={sorting === 'distance' ? Colors.blueAzure : Colors.greyLight}
              />
            </CustomPressable>
          </View>

          <ButtonPrimary
            style={styles.sortDialogButton}
            title={txt('events.sortDialog.select')}
            titleStyle={styles.sortDialogButtonTitle}
            onPress={() => setShowSortDialog(false)}
          />
        </View>
      </CustomDialog>

      <ScrollableDialog
        style={{ borderRadius: 10 }}
        visible={showPlusMemberInfoDialog}
        onDismiss={() => setShowPlusMemberInfoDialog(false)}
      >
        <ContentContainer>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 12 }}>
            <Image
              accessibilityIgnoresInvertColors
              source={{ uri: 'https://filer.oplevalt.dk/oplev-alt-logo_2.png' }}
              style={{ aspectRatio: 1, width: 50 }}
              resizeMode="contain"
            />
            <CustomText font="xlargeBold">{'OplevAlt'}</CustomText>
          </View>
          <CustomText style={{ marginBottom: 0 }}>
            {txt('events.plusMemberInfoDialog')}
          </CustomText>
        </ContentContainer>
        <ButtonPrimary
          style={{ backgroundColor: Colors.blueAzure, borderRadius: 6, minWidth: 125, marginBottom: 24 }}
          title={'Luk'}
          titleStyle={styles.sortDialogButtonTitle}
          onPress={() => setShowPlusMemberInfoDialog(false)}
        />
      </ScrollableDialog>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignSelf: 'center',
    maxWidth: Layout.maxFeedWidth,
  },

  searchBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
  },

  searchBarSortButton: {
    padding: 6,
  },

  eventList: {
    flex: 1,
  },

  textAlignCenter: {
    textAlign: 'center',
  },

  footerContainer: {
    padding: 12,
    margin: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },

  sortDialog: {
    maxWidth: 280,
    width: '100%',
  },

  sortDialogContent: {
    margin: 24,
  },

  sortDialogSelectionsContainer: {
    marginVertical: 12,
  },

  sortDialogSelectionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
  },

  sortDialogSelectionText: {
    marginLeft: 6,
    marginRight: 'auto',
  },

  sortDialogButton: {
    backgroundColor: Colors.blueAzure,
  },

  sortDialogButtonTitle: {
    paddingHorizontal: 12,
  },
  plusMemberBanner: {
    backgroundColor: Colors.white,
    margin: 12,
    padding: 12,
    borderRadius: 6,
    ...ShadowStyles.cardShadow,
    borderLeftWidth: 6,
    borderLeftColor: Colors.orange,
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})
