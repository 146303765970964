import CustomDialog, { ContentContainer } from '../../../dialogs/CustomDialog'
import { View } from 'react-native'
import NormalIcon from '../../../icons/NormalIcon'
import Colors from '../../../../constants/Colors'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import { ButtonPrimary } from '../../../buttons/StyledButtons'
import React from 'react'
import CustomPressable from "../../../pressables/CustomPressable"

export function CancelWithMessageDialog({ visible, onDismiss, sendMessage }) {
  return (
    <CustomDialog
      visible={visible}
      onDismiss={onDismiss}
    >
      <ContentContainer>
        <CustomPressable
          style={{ alignSelf: 'flex-end', position: 'absolute', paddingLeft: 24, zIndex: 1 }}
          accessibilityRole="button"
          onPress={onDismiss}
        >
          <NormalIcon name="times" color={Colors.inactive} />
        </CustomPressable>
        <CustomText font="largeBold">{txt(`videoChat.cancelMessageTitle`)}</CustomText>
        <CustomText style={{ paddingTop: 12 }}>
          {txt(`videoChat.cancelMessageText`)}
        </CustomText>
      </ContentContainer>
      <View style={{ padding: 12 }}>
        <ButtonPrimary
          title={txt(`videoChat.cancelMessageSend`)}
          style={{ backgroundColor: Colors.blueAzure }}
          onPress={sendMessage}
        />
      </View>
    </CustomDialog>
  )
}

export function CannotCancelDialog({ visible, onDismiss, sendMessage }) {
  return (
    <CustomDialog
      visible={visible}
      onDismiss={onDismiss}
    >
      <ContentContainer>
        <CustomPressable
          style={{ alignSelf: 'flex-end', position: 'absolute', paddingLeft: 24, zIndex: 1 }}
          accessibilityRole="button"
          onPress={onDismiss}
        >
          <NormalIcon name="times" color={Colors.inactive} />
        </CustomPressable>
        <CustomText font="largeBold">{txt(`videoChat.cannotCancelTitle`)}</CustomText>
        <CustomText style={{ paddingTop: 12 }}>{txt(`videoChat.cannotCancelText`)}</CustomText>
      </ContentContainer>
      <View style={{ padding: 12 }}>
        <ButtonPrimary
          title={txt(`videoChat.cancelMessageSend`)}
          style={{ backgroundColor: Colors.blueAzure }}
          onPress={sendMessage}
        />
      </View>
    </CustomDialog>
  )
}