import { StyleSheet } from 'react-native'
import { SpacingStyles, TypographyStyles } from '../../../../styles'
import Layout from '../../../../constants/Layout'
import Colors from '../../../../constants/Colors'

export const ProjectStyles = StyleSheet.create({
  blogHeader: { ...TypographyStyles.blogHeader, marginBottom: 12, marginTop: 12 },
  blogText: { ...TypographyStyles.blogText, marginBottom: 12 },
  blogTextBottom: { ...TypographyStyles.blogText },
  blogTitle: {
    ...TypographyStyles.blogTitle,
    marginBottom: 36,
    textAlign: 'center',
    marginTop: Layout.isSmallDevice ? 18 : 0,
  },
  bullit: { ...TypographyStyles.blogText, marginBottom: 8 },
  bullitLast: { ...TypographyStyles.blogText, marginBottom: 15 },
  container: {
    backgroundColor: Colors.white,
    ...SpacingStyles.marginRightLeft10,
    marginBottom: 20,
    borderRadius: 12,
    padding: Layout.isSmallDevice ? 18 : 36,
  },
  containerRow: { flex: 1, flexDirection: 'row' },
  employeeContainer: { alignItems: 'center', flexDirection: 'row', margin: 10 },
  employeeText: { flexGrow: 1, flex: 1, marginLeft: 10 },
  flex: { flex: 1 },
  link: {
    ...TypographyStyles.blogText,
    color: Colors.tintColor,
    marginBottom: 8,
    textDecorationLine: 'underline',
  },
  linkBottom: {
    ...TypographyStyles.blogText,
    color: Colors.tintColor,
    marginBottom: 15,
  },
  imageText: { ...TypographyStyles.textItalic, marginBottom: 15 },
  partners: { ...TypographyStyles.blogText, marginBottom: 8 },
  partnersLast: { ...TypographyStyles.blogText, marginBottom: 15 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})