import React from 'react'
import MainContainer from '../../components/MainContainer/MainContainer'
import InfoOverview from '../../components/views/Conversations/InfoOverview'
export default class ConversationInfoScreen extends React.Component {
  constructor(props) {
    super(props)
  }
  navigateToPost = (postId) => {
    this.props.navigation.navigate('Post', {
      id: postId,
    })
  }

  navigateToChristmasPost = (postId) => {
    this.props.navigation.navigate('christmasFriends', {
      screen: 'ChristmasConversationStack',
      initial: false,
      params: {
        screen: 'ChristmasPost',
        params: { id: postId },
      },
    })
  }

  navigateToProfile = (userId) => {
    this.props.navigation.navigate('ProfileView', {
      id: userId,
    })
  }

  render() {
    return (
      <MainContainer>
        <InfoOverview
          navigateToPost={this.navigateToPost}
          navigateToChristmasPost={this.navigateToChristmasPost}
          route={this.props.route}
          navigateToProfile={this.navigateToProfile}
        />
      </MainContainer>
    )
  }
}
