import React from 'react'
import Biblioteker from '../../components/views/About/EventsViews/Biblioteker'
import Byfester from '../../components/views/About/EventsViews/Byfester'
import Festivaler from '../../components/views/About/EventsViews/Festivaler'
import Messer from '../../components/views/About/EventsViews/Messer'
import Motion from '../../components/views/About/EventsViews/Motion'
import Sundhedscentre from '../../components/views/About/EventsViews/Sundhedscentre'
import Gymnasier from '../../components/views/About/EventsViews/Gymnasier'
import Efterskoler from '../../components/views/About/EventsViews/Efterskoler'
import Hoejskoler from '../../components/views/About/EventsViews/Hoejskoler'
import Sprogskoler from '../../components/views/About/EventsViews/Sprogskoler'
import Storcentre from '../../components/views/About/EventsViews/Storcentre'
import Tilflyttere from '../../components/views/About/EventsViews/Tilflyttere'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class ArrangementScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  checkSwitch = (param) => {
    switch (param) {
      case 'Biblioteker':
        return this.Biblioteker()

      case 'Byfester':
        return this.Byfester()

      case 'Festivaler':
        return this.Festivaler()

      case 'Messer':
        return this.Messer()

      case 'Motion':
        return this.Motion()

      case 'Sundhedscentre':
        return this.Sundhedscentre()

      case 'Gymnasier':
        return this.Gymnasier()

      case 'Efterskoler':
        return this.Efterskoler()

      case 'Højskoler':
        return this.Hoejskoler()

      case 'Sprogskoler':
        return this.Sprogskoler()

      case 'Storcentre':
        return this.Storcentre()

      case 'Tilflyttere':
        return this.Tilflyttere()

      default:
        if (__DEV__) console.log('NUMBER NOT FOUND')
    }
  }

  Biblioteker = () => {
    return <Biblioteker />
  }

  Byfester = () => {
    return <Byfester />
  }

  Festivaler = () => {
    return <Festivaler />
  }

  Messer = () => {
    return <Messer />
  }

  Motion = () => {
    return <Motion />
  }

  Sundhedscentre = () => {
    return <Sundhedscentre />
  }

  Gymnasier = () => {
    return <Gymnasier />
  }

  Efterskoler = () => {
    return <Efterskoler />
  }

  Hoejskoler = () => {
    return <Hoejskoler />
  }

  Sprogskoler = () => {
    return <Sprogskoler />
  }

  Storcentre = () => {
    return <Storcentre />
  }

  Tilflyttere = () => {
    return <Tilflyttere />
  }

  render() {
    return (
      <MainContainer>
        {this.checkSwitch(this.props.route.params.id)}
      </MainContainer>
    )
  }
}
