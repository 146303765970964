import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet, Modal, View, AppState, Image } from 'react-native'
import * as Updates from 'expo-updates'

import { ButtonPrimary } from '../buttons/StyledButtons'
import { CustomText } from '../text/StyledText'

import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'

const checkForUpdates = async () => {
	try {
		const update = await Updates.checkForUpdateAsync()
		if (update.isAvailable) await Updates.fetchUpdateAsync()
	} catch (error) {
		if (__DEV__) console.error(error)
	}
}

export const UpdateDialog = () => {
	const { isUpdatePending } = Updates.useUpdates()
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		const subscription = AppState.addEventListener('change', (appState) => {
			if (mounted && appState === 'active') checkForUpdates()
		})

		setMounted(true)
		return () => subscription?.remove()
	}, [mounted])

	if (Platform.OS === 'web') return null

	return (
		<Modal visible={isUpdatePending}>
			<View style={styles.container}>
				<View style={styles.contentContainer}>
					<Image
						accessibilityIgnoresInvertColors
						style={styles.backgroundImage}
						source={{ uri: 'https://filer.boblberg.dk/Assets/Img/graphics/bubbles-graphics-1.png' }}
					/>
					<View style={styles.textContainer}>
						<CustomText font="largeBold" style={styles.textCenter}>{txt('overlay.update.text')}</CustomText>
						<CustomText font="small" style={[styles.textCenter, styles.textLight]}>
							{txt('overlay.update.subText')}
						</CustomText>
					</View>

					<ButtonPrimary
						title={txt('overlay.update.button')}
						titleStyle={styles.buttonTitle}
						style={styles.button}
						onPress={() => Updates.reloadAsync()}
					/>
				</View>
			</View>
		</Modal>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.redCrossBackground,
	},

	contentContainer: {
		gap: 100,
	},

	textContainer: {
		gap: 6
	},

	textCenter: {
		textAlign: 'center',
	},

	textLight: {
		color: Colors.textLight,
	},

	button: {
		minWidth: 250,
		backgroundColor: Colors.blueAzure,
		paddingVertical: 13,
		paddingHorizontal: 24,
		borderRadius: 10,
	},

	buttonTitle: {
		paddingHorizontal: 12,
	},

	backgroundImage: {
		height: 150,
		width: 150,
		objectFit: 'contain',
		alignSelf: 'center'
	},
})
