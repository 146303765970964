// libraries
import React, { useRef, useState } from 'react'
import { View, StyleSheet, Image, Platform, FlatList } from 'react-native'
import * as ImageManipulator from 'expo-image-manipulator'
import * as FileSystem from 'expo-file-system'

// components
import { CustomGroupInput } from '../../text/StyledTextinputs'
import { txt } from '../../../locales/i18n'
import { ButtonPrimary, RotateLeftBtn, RotateRightBtn } from '../../buttons/StyledButtons'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import CustomDialog, { ActionButton, ContentContainer } from '../../dialogs/CustomDialog'
import { CustomText } from '../../text/StyledText'
import Fontsizes from '../../../constants/Fontsizes'
import ImagePickerPrivate from '../../ImagePicker/ImagePickerPrivate'
import * as DocumentPicker from 'expo-document-picker'
import { maximumDocumentSize, acceptedMimeTypes } from '../../../constants/FilesConfig'
import { CameraModal } from '../../camera-modal/camera-modal'

// api
import { scaleImages } from '../../../utils/ImageHelper'
import { createMessage } from '../../../services/api/Groups'
import { getScreeningConsent } from '../../../services/api/Inbox'
import ScreeningConsentDialog from '../../dialogs/ScreeningConsentDialog'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupInput(props) {
  const [text, setText] = useState('')
  const [height, setHeight] = useState(null)
  const [images, setImages] = useState([])
  const [documents, setDocuments] = useState([])
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')
  const [showScreeningDialog, setShowScreeningDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const cameraRef = useRef(null)

  const sendMessage = async () => {
    setIsLoading(true)
    let scaledImages = null
    if (images.length > 0) {
      scaledImages = await scaleImages(images) || null
      if (scaledImages === null) {
        setUploadErrorMessage(txt('groups.bulletin.uploadFailed'))
        return setIsLoading(false)
      }
    }

    const consentGiven = await getScreeningConsent()
    if (consentGiven === 0) {
      setShowScreeningDialog(true)
      return setIsLoading(false)
    }

    const messageData = {
      groupId: props.group.id,
      body: text,
      images: scaledImages,
      documents,
    }

    const message = await createMessage(messageData)
    setIsLoading(false)

    if (message === undefined) {
      return setUploadErrorMessage(txt('groups.bulletin.uploadFailed'))
    }

    if (message?.errCode) {
      return setUploadErrorMessage(txt('groups.bulletin.uploadFailed'))
    }

    setText('')
    setImages([])
    setDocuments([])
    props.onMessageCreated(message)
  }

  const addImages = (images) => {
    if (images) {
      setImages((prevState) => [...images, ...prevState])
    }
  }

  const removeImage = async (imgIndex) => {
    const filteredImages = images.filter((img, index) => {
      if (index !== imgIndex) {
        return img
      }
    })
    setImages(filteredImages)
  }

  const rotateImage = async (direction, index) => {
    if (!images) return

    const manipResult = await ImageManipulator.manipulateAsync(images[index].uri, [{ rotate: direction * 90 }], {
      format: ImageManipulator.SaveFormat.JPEG,
    })

    const copyOfImages = images.slice()
    copyOfImages[index] = manipResult
    setImages(copyOfImages)
  }

  const handleSetText = (text) => {
    if (text === '') {
      setHeight(null)
    }
    setText(text)
  }

  const renderImageItem = ({ item, index }, removeImage) => {
    return (
      <View key={index} style={styles.pickedImageContainer}>
        {renderImagePreview(item)}
        <View style={styles.rotateBtnContainer}>
          <RotateLeftBtn
            onPress={() => {
              // Web goes opposite direction
              rotateImage(Platform.OS === 'web' ? 1 : -1, index)
            }}
            style={{ paddingHorizontal: 12 }}
          />
          <CustomPressable style={{ paddingHorizontal: 12 }} onPress={() => removeImage(index)}>
            <NormalIcon name="times-circle" size={28} color={Colors.red} />
          </CustomPressable>
          <RotateRightBtn
            onPress={() => {
              rotateImage(Platform.OS === 'web' ? -1 : 1, index)
            }}
            style={{ paddingHorizontal: 12 }}
          />
        </View>
      </View>
    )
  }

  const renderImagePreview = (item) => {
    return <Image source={{ uri: item.uri }} style={styles.images} accessibilityIgnoresInvertColors />
  }

  const removeDocument = (index) => {
    setDocuments((previous) => previous.filter((e, i) => i !== index))
  }

  const renderDocuments = () => {
    return documents.map((document, index) => (
      <View key={String(index)} style={styles.document}>
        <CustomText style={styles.documentLink} numberOfLines={1}>
          {document.name}
        </CustomText>

        {/* remove button */}
        <CustomPressable
          onPress={() => removeDocument(index)}
          accessibilityRole="button"
          accessibilityLabel={txt('groups.input.removeDocument')}
          accessibilityHint={txt('groups.input.removeDocumentHint')}
        >
          <NormalIcon name="times" size={28} color={Colors.red} />
        </CustomPressable>
      </View>
    ))
  }

  const pickDocument = async () => {
    const { assets, canceled } = await DocumentPicker.getDocumentAsync({})
    if (canceled) return // cancelled by user

    const [file] = assets
    const { name, size, uri } = file

    let base64

    if (Platform.OS === 'web') {
      const regex = /;base64,(?<data>.+)$/g.exec(uri)
      base64 = regex.groups.data
    } else {
      base64 = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' })
    }

    // validate file format
    const { extension } = /.(?<extension>\w+)$/g.exec(name).groups
    const documentType = acceptedMimeTypes.find((e) => {
      return e.extension === extension.toLowerCase()
    })

    if (!documentType) {
      const errorMessage = txt('groups.bulletin.unsupportedDocument')
      return setUploadErrorMessage(errorMessage)
    }

    // validate size
    if (size > maximumDocumentSize) {
      const errorMessage = txt('groups.bulletin.documentToLarge')
      const supportedSize = `${maximumDocumentSize * 10 ** -6} megabytes`
      return setUploadErrorMessage(`${errorMessage} ${supportedSize}`)
    }

    const document = { base64, name, size, ...documentType }
    setDocuments((previous) => [document, ...previous])
  }

  return (
    <View onLayout={props.onLayout} style={styles.margin10}>
      <View style={styles.postContainer}>
        <View style={styles.inputContainer}>
          <CustomGroupInput
            accessibilityLabel={txt('groups.bulletin.writeMessage')}
            accessibilityHint={txt('groups.bulletin.writeMessageHint')}
            placeholder={txt('groups.bulletin.writeMessage')}
            onChangeText={handleSetText}
            onContentSizeChange={(e) => setHeight(e.nativeEvent.contentSize.height)}
            height={height}
            value={text}
            //style={{ backgroundColor: props.group.theme?.secondary || Colors.white }}
          />
        </View>

        <View style={styles.inputOptions}>
          <ButtonPrimary
            onPress={pickDocument}
            style={{ backgroundColor: props.group?.theme?.primary || Colors.tintColor, marginRight: 10 }}
            titleStyle={{ color: props.group?.theme?.buttonText || Colors.white }}
            color={props.group.theme?.buttonText || Colors.white}
            icon="paperclip"
            stroke={'fal'}
            label={txt('groups.input.uploadDocument')}
          />

          <ImagePickerPrivate
            color={props.group.theme?.buttonText || Colors.white}
            backgroundColor={props?.group.theme?.primary || Colors.tintColor}
            buttonText={props.group?.theme?.buttonText || Colors.white}
            addImages={addImages}
            multiple
          />

          <ButtonPrimary
            icon="camera"
            onPress={() => cameraRef.current?.open?.()}
            style={{ backgroundColor: props.group?.theme?.primary || Colors.tintColor, marginLeft: 10 }}
            titleStyle={{ color: props.group?.theme?.buttonText || Colors.white }}
            color={props.group.theme?.buttonText || Colors.white}
            accessibilityLabel={txt('groups.input.openCamera')}
          />

            <ButtonPrimary
              style={{ backgroundColor: props.group?.theme?.primary || Colors.tintColor, marginLeft: 'auto' }}
              titleStyle={{ color: props.group?.theme?.buttonText || Colors.white }}
              disabled={(!text && !images.length && !documents.length) || isLoading}
              title={txt('groups.bulletin.createButton')}
              loading={isLoading}
              onPress={sendMessage}
              color={props.group.theme?.primary}
              buttonText={props.group.theme?.buttonText}
            />
        </View>
      </View>

      {images.length !== 0 && (
        <View style={styles.imageContainer}>
          <FlatList
            horizontal={true}
            data={images}
            extraData={images}
            renderItem={(item) => renderImageItem(item, removeImage)}
          />
        </View>
      )}

      {/* document container */}
      {documents.length !== 0 && <View style={styles.documentContainer}>{renderDocuments()}</View>}

      <CameraModal
        ref={cameraRef}
        onConfirm={(image) => setImages((images) => [image, ...images])}
      />

      <CustomDialog
        visible={uploadErrorMessage !== ''}
        onDismiss={() => setUploadErrorMessage('')}
      >
        <ContentContainer>
          <CustomText>{uploadErrorMessage}</CustomText>
        </ContentContainer>
        <ActionButton onPress={() => setUploadErrorMessage('')}>
          {txt('groups.bulletin.ok')}
        </ActionButton>
      </CustomDialog>

      <ScreeningConsentDialog visible={showScreeningDialog} handleHideDialog={() => setShowScreeningDialog(false)} />
    </View>
  )
}

const styles = StyleSheet.create({
  document: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  documentContainer: {
    margin: 10,
  },
  documentLink: {
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  images: {
    alignItems: 'center',
    borderRadius: Fontsizes.cornerRounding,
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    width: 200,
  },
  inputContainer: {
    flex: 1,
    flexGrow: 5,
    maxHeight: 300,
    minHeight: 100,
    width: '100%',
  },
  inputOptions: {
    marginVertical: 12,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  margin10: { margin: 10 },
  pickedImageContainer: {
    marginTop: 10,
    paddingRight: 10,
  },
  postContainer: {
    width: '100%',
  },
  rotateBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 5,
    marginTop: 5,
  },
})
