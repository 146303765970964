import CustomPressable from "../../../pressables/CustomPressable"
import NormalIcon from "../../../icons/NormalIcon"
import Colors from "../../../../constants/Colors"
import CustomDialog, { ContentContainer } from "../../../dialogs/CustomDialog"
import { CustomText } from "../../../text/StyledText"
import { txt } from "../../../../locales/i18n"
import { StyleSheet, View } from "react-native"
import { SnakSammenInput } from "../volunteer/VolunteerProfile"
import { ButtonSecondary } from "../../../buttons/StyledButtons"
import React, { useState } from "react"
import { useNavigation } from "@react-navigation/core"
import { updateUser } from "../../../../services/api/User"

export function ProfileDialog({ visible, onDismiss, phoneNumber }) {
	const navigation = useNavigation()

	const [phone, setPhone] = useState(phoneNumber ?? '')
	const [phoneError, setPhoneError] = useState(false)
	const [phoneErrorMessage, setPhoneErrorMessage] = useState('')
	const [phoneSuccess, setPhoneSuccess] = useState(false)

	const [loading, setLoading] = useState(false)
	const [failedToSave, setFailedToSave] = useState(false)

	async function handleSavePhone() {
		setLoading(true)
		if (phone.length < 8 && phone.length !== 0) {
			setPhoneError(true)
			setPhoneErrorMessage(txt('videoChat.volunteer.profile.phoneErrorMessage'))
		} else {
			setPhoneSuccess(true)
			const updated = await updateUser({ phone })
			if (!updated) setFailedToSave(true)
			onDismiss()
		}
		setLoading(false)
	}

	return (
		<CustomDialog visible={visible} onDismiss={onDismiss} style={styles.dialog}>

			<CustomPressable onPress={onDismiss} style={styles.iconContainer}>
				<NormalIcon name="times" size={20} color={Colors.text} />
			</CustomPressable>

			<ContentContainer>

				<CustomText font="gotham-bold" style={{ marginBottom: 12 }}>{txt('videoChat.volunteer.profile.dialog.title')}</CustomText>
				<CustomText font="gotham-small" style={styles.body}>{txt('videoChat.volunteer.profile.dialog.body')}</CustomText>

				<CustomPressable onPress={() => navigation.setParams({ chip: txt('videoChat.volunteer.chipProfile') })}>
					<CustomText font="gotham-bold-small" style={styles.link}>{txt('videoChat.volunteer.profile.dialog.link')}</CustomText>
				</CustomPressable>

				{(!phoneNumber || phoneNumber === '' || phoneNumber?.length === 0 || phoneNumber === 0) && (
					<View style={{ marginTop: 12 }}>
						<CustomText font="gotham-bold" style={styles.body}>{txt('videoChat.volunteer.profile.dialog.phoneTitle')}</CustomText>
						<CustomText font="gotham-small" style={styles.body}>{txt('videoChat.volunteer.profile.dialog.phoneBody')}</CustomText>

						<View style={{ marginTop: 12 }}>
							<SnakSammenInput
								value={phone}
								placeholder={txt('videoChat.volunteer.profile.phonePlaceholder')}
								onChange={(text) => setPhone(text)}
								error={phoneError}
								errorMessage={phoneErrorMessage}
								success={phoneSuccess}
							/>
						</View>

						{failedToSave && (
							<CustomText font="gotham-bold-small" style={styles.errorText}>
								{txt('videoChat.volunteer.profile.failedToSave')}
							</CustomText>
						)}

						<View style={styles.buttonContainer}>
							<ButtonSecondary
								title={txt('videoChat.volunteer.profile.dialog.noThanks')}
								onPress={onDismiss}
								style={styles.button}
								titleStyle={styles.buttonTitle}
							/>
							<ButtonSecondary
								title={txt('videoChat.volunteer.profile.dialog.save')}
								onPress={handleSavePhone}
								loading={loading}
								style={styles.button}
								titleStyle={styles.buttonTitle}
							/>
						</View>
					</View>
				)}

			</ContentContainer>

		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	body: {
		lineHeight: 24,
	},
	link: {
		textDecorationLine: 'underline',
		marginTop: 24,
		marginBottom: 12,
	},
	buttonContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: 12,
		borderColor: Colors.greyLight,
		borderRadius: 10,
		borderWidth: 1,
		marginLeft: 24,
		paddingHorizontal: 32,
		paddingVertical: 12,
	},
	buttonTitle: {
		color: Colors.text,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},
	dialog: {
		borderRadius: 6,
		maxWidth: 500,
	},
	iconContainer: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 12,
		padding: 8,
		position: 'absolute',
		top: 12,
		right: 12,
		zIndex: 10
	}
})