import React, { useEffect } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import store from '../../redux/store'

import { joinUniverseById } from '../../services/api/Universe'
import { getOwnUser } from '../../services/api/User'

import MainContainer from '../../components/MainContainer/MainContainer'
import { CustomText } from '../../components/text/StyledText'
import { txt } from '../../locales/i18n'
import Layout from '../../constants/Layout'
import Colors from '../../constants/Colors'
import { cardShadow } from '../../styles/shadows'
import CustomPressable from "../../components/pressables/CustomPressable"

export default function StudieboblerSelectionScreen(props) {
  const userToken = useSelector((state) => state.userToken)

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: () => (
        <Image
          style={styles.headerLogo}
          resizeMode="contain"
          source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/studiebobler_logo.png' }}
          accessibilityLabel={txt('universe.studiebobler.logoLabel')}
          accessibilityIgnoresInvertColors
          accessibilityRole="image"
          accessible
        />
      ),
    })

    // Navigate to universe, if user is already part of 'aauucn' or 'au'
    const universes = store.getState().user.universes
    const universeIds = [1, 12]
    const universe = universes.find(uni => universeIds.includes(uni.id))
    if (universe) {
      props.navigation.replace('universe', {
        screen: 'UniverseBoblStack',
        params: {
          screen: 'Universe',
          params: {
            universe: universe.name,
          },
        },
      })
    }
  }, [props.navigation])

  async function joinUniverse(id) {
    if (userToken) {
      await joinUniverseById(id)
      await getOwnUser()

      let universe
      if (id === 1) { universe = 'aauucn' } else if (id === 12) { universe = 'au' }

      props.navigation.replace('universe', {
        screen: 'UniverseBoblStack',
        params: {
          screen: 'Universe',
          params: {
            universe: universe,
          },
        },
      })
    }
  }

  return (
    <MainContainer>

      <View style={styles.container}>

        <CustomText>{txt('universe.studiebobler.choose')}</CustomText>

        <View style={styles.boxContainer}>
          <CustomPressable
            style={styles.box}
            accessibilityRole="button"
            accessibilityLabel={txt('universe.studiebobler.aauucnBtn')}
            onPress={() => joinUniverse(1)}
          >
            <Image
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/studiebobler/aau-ucn-logo.png' }}
              resizeMode="contain"
              style={styles.image}
              accessibilityIgnoresInvertColors
              accessible
              accessibilityRole="image"
              accessibilityLabel={txt('universe.studiebobler.aauucnLogoLabel')}
            />
            <CustomText font="bold">{txt('universe.studiebobler.aauucn')}</CustomText>
          </CustomPressable>

          <CustomPressable
            style={styles.box}
            accessibilityRole="button"
            accessibilityLabel={txt('universe.studiebobler.auBtn')}
            onPress={() => joinUniverse(12)}
          >
            <Image
              source={{ uri: 'https://filer.boblberg.dk/Assets/Img/universe/studiebobler/au-sr-logo.png' }}
              resizeMode="contain"
              style={styles.image}
              accessibilityIgnoresInvertColors
              accessible
              accessibilityRole="image"
              accessibilityLabel={txt('universe.studiebobler.auLogoLabel')}
            />
            <CustomText font="bold">{txt('universe.studiebobler.au')}</CustomText>
          </CustomPressable>
        </View>
      </View>
      
    </MainContainer>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: Layout.maxFeedWidth,
    width: '100%',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
  },
  box: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 250,
    height: 150,
    backgroundColor: Colors.white,
    borderRadius: 12,
    margin: 24,
    padding: 16,
    ...cardShadow,
  },
  headerLogo: { height: 32, width: 200 },
  image: {
    alignSelf: 'center',
    maxHeight: 50,
    height: '100%',
    width: '100%',
    marginBottom: 24,
  },
})
