import React from 'react'
import CreateActivity from '../../components/views/Activities/CreateActivity'
import MainContainer from '../../components/MainContainer/MainContainer'
import { txt } from '../../locales/i18n'

export default function EditActivityScreen(props) {
  function navigateToMyActivities() {
    props.navigation.navigate('Activities', {
      chip: txt('activities.chipParams.mine'),
    })
  }

  return (
    <MainContainer noScroll>
      <CreateActivity
        id={props.route.params.id}
        date={props.route.params.date}
        navigateToMyActivities={navigateToMyActivities}
      />
    </MainContainer>
  )
}
