import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import { NotificationSwitch } from '../../switches/StyledSwitches'
import { CustomText } from '../../text/StyledText'
import { useSelector } from 'react-redux'
import { updateUserPreferences } from '../../../services/api/User'
import { useNavigation } from '@react-navigation/core'
import Card from '../../Card'

export default function VideoConversationsNotifications() {
  const preferences = useSelector((state) => state.preferences)
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({ title: txt('settings.notifications') })
  }, [])

  function handleSwitch(type, value) {
    updateUserPreferences({ [type]: value })
  }

  return (
    <Card style={styles.container}>
      <View style={[styles.notificationGroup, styles.borderBottom]}>
        <CustomText font="largeBold">{txt('settings.learnDanish')}</CustomText>
        <CustomText font="xsmall" style={styles.description}>{txt('settings.videoConversationDescription')}</CustomText>
        <NotificationSwitch
          title={txt('settings.notificationType.push')}
          icon="bell"
          value={preferences.sprogland_push_notification}
          handleChange={(value) => handleSwitch('sprogland_push_notification', value)}
        />
        <NotificationSwitch
          title={txt('settings.notificationType.sms')}
          icon="comments"
          value={preferences.sprogland_sms_notification}
          handleChange={(value) => handleSwitch('sprogland_sms_notification', value)}
        />
        <NotificationSwitch
          title={txt('settings.notificationType.email')}
          icon="envelope"
          value={preferences.sprogland_email_notification}
          handleChange={(value) => handleSwitch('sprogland_email_notification', value)}
        />
      </View>
      <View style={styles.notificationGroup}>
        <CustomText font="largeBold">{txt('settings.talkTogether')}</CustomText>
        <CustomText font="xsmall" style={styles.description}>{txt('settings.videoConversationDescription')}</CustomText>
        <NotificationSwitch
          title={txt('settings.notificationType.push')}
          icon="bell"
          value={preferences.snaksammen_push_notification}
          handleChange={(value) => handleSwitch('snaksammen_push_notification', value)}
        />
        <NotificationSwitch
          title={txt('settings.notificationType.sms')}
          icon="comments"
          value={preferences.snaksammen_sms_notification}
          handleChange={(value) => handleSwitch('snaksammen_sms_notification', value)}
        />
        <NotificationSwitch
          title={txt('settings.notificationType.email')}
          icon="envelope"
          value={preferences.snaksammen_email_notification}
          handleChange={(value) => handleSwitch('snaksammen_email_notification', value)}
        />
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  borderBottom: {
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: 1,
    paddingBottom: 12,
  },
  container: {
    alignSelf: 'center',
    marginTop: 12,
    maxWidth: Layout.maxFeedWidth,
    paddingHorizontal: 12,
    paddingTop: 12,
    width: '100%',
  },
  description: {
    marginBottom: 12,
    marginTop: 4,
  },
  notificationGroup: {
    marginBottom: 12,
  },
})
