import React from 'react'
import GroupsIntroScreen from './GroupsIntro'
import GroupsOverviewScreen from './GroupsOverviewScreen'
import { searchGroups } from '../../services/api/Groups'
import { useSelector } from 'react-redux'
import MainContainer from '../../components/MainContainer/MainContainer'

export default function GroupsScreen(props) {
  const userToken = useSelector((state) => state.userToken)

  const getGroups = async ({search, skip, amount}) => {
    return searchGroups({search, skip, amount})
  }

  const navigateToGroup = (groupId) => {
    props.navigation.navigate( 'GroupsStack', {
      screen: 'Group',
      initial: false,
      params: { id: groupId },
    })
  }

  return (
    <MainContainer noScroll={!!userToken}>
      {userToken ? (
        <GroupsOverviewScreen
          getGroups={getGroups}
          navigation={props.navigation}
          navigateToGroup={navigateToGroup}
        />
      ) : (
        <GroupsIntroScreen navigation={props.navigation} />
      )}
    </MainContainer>
  )
}
