import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { CustomText } from '../../text/StyledText'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import SurveyPromptDialog, { platforms, shouldShowSurveyPrompt } from '../../dialogs/SurveyPromptDialog'

import { getConversationId } from '../../../services/api/Inbox'
import { cancelBooking, getVolunteerProfile } from '../../../services/api/snakSammen'
import startVideoSession from '../../../utils/startVideoSession'
import { surveyIds } from '../../../services/api/Surveys'
import { txt } from '../../../locales/i18n'

import Layout from '../../../constants/Layout'
import Colors from '../../../constants/Colors'
import { CancelWithMessageDialog, CannotCancelDialog } from './dialogs/CancelDialogs'
import CustomPressable from "../../pressables/CustomPressable"
import CustomDialog, { ContentContainer } from "../../dialogs/CustomDialog"
import NormalIcon from "../../icons/NormalIcon"
import { formatDate } from "./utils/helperFunctions"

export function BookedSessions(props) {
  const [showSurveyPrompt, setShowSurveyPrompt] = useState(false)
  const [cannotCancelDialogVisible, setCannotCancelDialogVisible] = useState(false)
  const [cancelMessageDialogVisible, setCancelMessageDialogVisible] = useState(false)
  const [volunteerProfile, setVolunteerProfile] = useState(null)
  const [volunteerProfileVisible, setVolunteerProfileVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigation = useNavigation()

  async function handlePressVolunteer(userId, username) {
    if (userId) {
      const profileRes = await getVolunteerProfile(userId)
      setVolunteerProfile({ description: profileRes?.description, username })
      setVolunteerProfileVisible(true)
    }
  }

  async function handleSendMessage(receiverId, vsSessionId) {
    if (receiverId === undefined || vsSessionId === undefined) return
    const conversationId = await getConversationId({ vsSessionId, receiverId })
    const params = { id: conversationId ?? 'new' }

    if (conversationId === null) {
      params.receiverId = receiverId
      params.vsSessionId = vsSessionId
    }

    const options = { screen: 'Conversation', initial: false, params }
    navigation.navigate('InboxStack', options)
  }

  async function handleStartSession(roomKey, vonageSessionId) {
    await startVideoSession(roomKey, vonageSessionId, 'snaksammen')
    // const showSurveyPrompt = await shouldShowSurveyPrompt(surveyIds.snaksammenUser)
    // setShowSurveyPrompt(showSurveyPrompt)
  }

  async function handleCancelBooking(session) {
    setLoading(true)
    const now = new Date()
    const startDate = new Date(session.start_date)
    const tenMinutesBeforeStartDate = new Date(new Date(session.start_date).setMinutes(startDate.getMinutes() - 10))

    if (now >= startDate && now >= tenMinutesBeforeStartDate) {
      setCannotCancelDialogVisible(true)
      setLoading(false)
    } else if (now < startDate && now > tenMinutesBeforeStartDate) {
      setCancelMessageDialogVisible(true)
    } else {
      await cancelBooking(session.id)
      props.handleRefresh()
      setLoading(false)
    }
  }

  async function handleCancel(session) {
    setLoading(true)
    await cancelBooking(session.id)
    props.handleRefresh()
    setCancelMessageDialogVisible(false)
    setLoading(false)
  }

  async function handleSendMessageAndCancel(session) {
    await handleCancel(session)
    await handleSendMessage(session.volunteer_id, session.id)
  }

  async function handleCancelMessage(session) {
    setCannotCancelDialogVisible(false)
    await handleSendMessage(session.volunteer_id, session.id)
  }

  return (
    <View style={styles.container}>
      <View>
        <CustomText font="gotham-xlarge">{txt('videoChat.bookedSessions')}</CustomText>
        {props.bookedSessions.length === 0 && (
          <CustomText font="gotham-small" style={styles.noSession}>{txt('videoChat.noBookedSessions')}</CustomText>
        )}
      </View>

      {props.bookedSessions.map((session) => {
        const formattedDate = formatDate(session.start_date, session.end_date)
        return (
          <View key={session.id} style={styles.card}>
            <View style={{ flex: 2, justifyContent: 'center' }}>
              <CustomText font="gotham-bold-small">{formattedDate}</CustomText>

              <CustomPressable onPress={() => handlePressVolunteer(session.volunteer_id, session.username)}>
                <CustomText font="gotham-small" style={{textDecorationLine: 'underline'}}>
                  <CustomText font="gotham-small">{txt('videoChat.volunteer.title') + ': '}</CustomText>
                  {session.username}
                </CustomText>
              </CustomPressable>
            </View>

            <View style={styles.buttonsContainer}>
              <View style={styles.buttonStartContainer}>
                <ButtonPrimary
                  title={txt('videoChat.start')}
                  onPress={() => handleStartSession(session.room_key, session.vonage_session_id)}
                  style={[styles.button, {backgroundColor: Colors.redCross.base}]}
                  titleStyle={[styles.buttonTitle, {color: Colors.white}]}
                />
              </View>

              <View style={styles.buttonStartContainer}>
                <ButtonPrimary
                  title={Layout.small ? txt('videoChat.write') : txt('videoChat.messageSend')}
                  onPress={() => handleSendMessage(session.volunteer_id, session.id)}
                  style={[styles.button, !Layout.small && { paddingHorizontal: 12 }]}
                  titleStyle={styles.buttonTitle}
                />
              </View>

              <View style={styles.buttonStartContainer}>
                <ButtonPrimary
                  title={txt('videoChat.cancel')}
                  onPress={() => handleCancelBooking(session)}
                  style={styles.button}
                  titleStyle={styles.buttonTitle}
                  loading={loading}
                />
              </View>
            </View>
            <CancelWithMessageDialog
              visible={cancelMessageDialogVisible}
              onDismiss={async () => await handleCancel(session)}
              sendMessage={() => handleSendMessageAndCancel(session)}
            />
            <CannotCancelDialog
              visible={cannotCancelDialogVisible}
              onDismiss={() => setCannotCancelDialogVisible(false)}
              sendMessage={() => handleCancelMessage(session)}
            />
          </View>
        )
      })}
      <SurveyPromptDialog
        visible={showSurveyPrompt}
        surveyId={surveyIds.snaksammenUser}
        platform={platforms.SnakSammen}
        surveyLink={'https://www.survey-xact.dk/LinkCollector?key=JLPMT31WUK36'}
        onDismiss={() => setShowSurveyPrompt(false)}
      />

      <CustomDialog visible={volunteerProfileVisible} onDismiss={() => setVolunteerProfileVisible(false)}>
        <CustomPressable onPress={() => setVolunteerProfileVisible(false)} style={styles.iconContainer}>
          <NormalIcon name="times" size={20} color={Colors.text} />
        </CustomPressable>

        <ContentContainer>
          <View style={{ marginTop: 18 }}>
            <CustomText font="gotham-bold">{volunteerProfile?.username}</CustomText>
            {volunteerProfile?.description ? (
              <CustomText font="gotham" style={{ marginTop: 18 }}>{volunteerProfile?.description}</CustomText>
            ) : (
              <CustomText font="gotham" style={{ marginTop: 18 }}>{txt('videoChat.user.noVolunteerProfile')}</CustomText>
            )}
          </View>
        </ContentContainer>
      </CustomDialog>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonStartContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingRight: 2,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: Layout.small ? 12 : 0
  },
  container: {
    margin: 10,
  },
  card: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    flexDirection: Layout.small ? 'column' : 'row',
    alignItems: Layout.small ? 'flex-start' : 'center',
    marginTop: 10,
    paddingVertical: Layout.small ? 18 : 12,
    paddingHorizontal: Layout.small ? 24 : 18,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
  },
  button: {
    borderRadius: 10,
    backgroundColor: Colors.redCross.background,
    paddingVertical: 13,
    paddingHorizontal: Layout.small ? 24 : 32,
    marginLeft: 12,
    minWidth: Layout.small ? 75 : 115,
  },
  buttonTitle: {
    color: Colors.redCross.text,
    fontSize: 14,
    fontFamily: 'gotham-bold'
  },

  iconContainer: {
    backgroundColor: Colors.greyLightLight,
    borderRadius: 12,
    padding: 8,
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 10
  }
})
