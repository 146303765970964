exports.formatForSeoBody = function (txt) {
  return txt
    .replace(/  +/g, ' ')
    .trim()
    .replace('\n', '')
    .replace('\t', '')
    .slice(0, 160)
}

exports.formatForMetaTitle = function (txt) {
  return txt
    .replace(/  +/g, ' ')
    .trim()
    .replace('\n', '')
    .replace('\t', '')
    .slice(0, 64)
}
