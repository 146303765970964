import React from 'react'
import { Dimensions, Platform, StyleSheet, View } from 'react-native'
import Slider from '@react-native-community/slider'

import { CustomText } from '../../../components/text/StyledText'
import { FilterButton } from '../../../components/chips/FilterButton'
import NormalIcon from '../../../components/icons/NormalIcon'

import Colors from '../../../constants/Colors'
import Layout from '../../../constants/Layout'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function FeedFilterIcons({
  updateFilter,
  showDistanceDiag,
  setShowDistanceDiag,
  filter,
  setFilter,
  distance,
  setDistance,
}) {
  const isSmallDevice = Layout.isSmallDevice

  const Icon = ({ iconName, type, selected, buttonName, isSmallScreen }) => {
    return (
      <View style={styles.iconStyle}>
        <FilterButton
          buttonSelectedStyle={styles.buttonSelected}
          buttonUnselectedStyle={styles.buttonUnselected}
          onPress={() => updateFilter(type)}
          iconName={iconName}
          text={isSmallScreen ? undefined : buttonName}
          selected={selected}
          activeColor={Colors.greenChristmas}
        />
      </View>
    )
  }

  function toggleShowDistanceDiag() {
    setShowDistanceDiag(!showDistanceDiag)
  }

  function resetDistance() {
    setFilter({ distance: false })
    setDistance(500)
    toggleShowDistanceDiag()
  }

  function handleDistanceChange(distance) {
    setDistance(distance)
    setFilter({ ...filter, distance: distance !== 0 })
  }

  return (
    <View>
      <View style={styles.iconContainer}>
        {/* host */}
        <Icon
          iconName="home-lg-alt"
          type={'host'}
          buttonName={txt('christmasFriends.feed.host')}
          menuItemValue={2}
          isSmallScreen={Dimensions.get('window').width < 375}
          selected={filter.host}
        />
        {/* guest */}
        <Icon
          iconName="wreath"
          type={'guest'}
          buttonName={txt('christmasFriends.feed.guest')}
          menuItemValue={1}
          isSmallScreen={Dimensions.get('window').width < 375}
          selected={filter.guest}
        />
        {/* favorite */}
        <Icon
          iconName="star"
          type={'favorite'}
          buttonName={txt('christmasFriends.feed.favorite')}
          menuItemValue={3}
          isSmallScreen={isSmallDevice}
          selected={filter.favorite}
        />
        {/* distance */}
        <View style={styles.distanceButton}>
          <CustomPressable
            onPress={toggleShowDistanceDiag}
            accessibilityRole="button"
            style={styles.distance}
          >
            {distance && (
              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  alignItems: 'center',
                  marginBottom: 6,
                }}
              >
                <NormalIcon
                  name="arrows-alt-h"
                  size={20}
                  color={Colors.greenChristmas}
                />
                <CustomText
                  style={styles.distanceText}
                  font={'bold'}
                  numberOfLines={1}
                >
                  {distance + ' km'}
                </CustomText>
              </View>
            )}
          </CustomPressable>
        </View>
      </View>

      {(Platform.OS === 'web' || showDistanceDiag) && (
        <View style={[styles.distanceInputContainer, { display: showDistanceDiag ? 'flex': 'none' }]}>
          <View>
            <View style={styles.sliderLabels}>
              <CustomText style={styles.distanceText}>10</CustomText>
              <CustomText style={styles.distanceText} font='bold'>{distance} km</CustomText>
              <CustomText style={styles.distanceText}>500</CustomText>
            </View>

            <View style={styles.sliderContainer}>
              <Slider
                step={10}
                minimumValue={10}
                maximumValue={500}
                value={distance}
                onSlidingComplete={handleDistanceChange}
                minimumTrackTintColor={Colors.greenChristmas}
                thumbTintColor={Colors.greenChristmas}
                maximumTrackTintColor="#000000"
                style={{ flex: 4, marginVertical: 6 }}
                tapToSeek={true}
              />

              <CustomPressable onPress={resetDistance} style={styles.sliderResetButton}>
                <CustomText font='smallBold'>
                  {txt('christmasFriends.feed.reset')}
                </CustomText>
              </CustomPressable>
            </View>

            <CustomText font="small" style={styles.sliderDescription}>
              {txt('christmasFriends.feed.distanceCalculated')}
            </CustomText>
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonSelected: {
    borderColor: Colors.greenChristmas,
  },
  buttonUnselected: {
    borderColor: Colors.filterBackground,
    borderWidth: 2,
  },
  distance: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Layout.isSmallDevice ? 74 : 140,
  },
  distanceButton: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  distanceText: {
    color: Colors.greenChristmas,
    paddingHorizontal: 6,
    textAlign: 'center',
  },
  distanceInputContainer: {
    marginBottom: 6,
  },
  iconContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    marginTop: 6,
  },
  iconStyle: {
    marginLeft: 3,
    marginRight: 3,
  },
  sliderResetButton: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 6,
    flex: 1,
  },
  sliderLabels: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    paddingLeft: 8,
  },
  sliderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 6,
  },
  sliderDescription: {
    paddingHorizontal: 6,
    textAlign: 'center'
  }
})
