import React from 'react'
import Settings from '../../components/views/Settings/Settings'
import MainContainer from '../../components/MainContainer/MainContainer'
import NotificationSettingsTypes from '../../constants/NotificationSettingsTypes'

export default function SettingsScreen(props) {
  function navigateToNotificationSettings(type) {
    const id = NotificationSettingsTypes.indexOf(type)
    props.navigation.navigate('Notifications', {
      id,
    })
  }

  return (
    <MainContainer>
        <Settings navigateToNotificationSettings={navigateToNotificationSettings} />
    </MainContainer>
  )
}
