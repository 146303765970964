import React from 'react'
import Press from '../../components/views/About/Press'
import MainContainer from '../../components/MainContainer/MainContainer'
export default function PressScreen(props) {
  return (
    <MainContainer noScroll>
      <Press navigation={props.navigation} />
    </MainContainer>
  )
}
