import React from 'react'
import { Platform, View } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import LoginScreen from '../../screens/Auth/LoginScreen'
import ResetPasswordScreen from '../../screens/Auth/ResetPasswordScreen'
import StackMenuAuthScreen from '../../screens/StackMenu/StackMenuAuthScreen'
import SnakSammenSignupScreen from '../../screens/SnakSammen/SnakSammenSignup'
import GroupsScreen from '../../screens/Groups/GroupsScreen'
import GroupsSignupScreen from '../../screens/Groups/GroupsSignup'
import ActivitiesSignupScreen from '../../screens/Activities/ActivitiesSignupScreen'
import SignupUniverseScreen from '../../screens/Universe/SignupUniverse'
import SignupChristmasScreen from '../../christmasFriends/screens/SignupChristmasScreen'
import UnionScreen from '../../screens/Unions/UnionScreen'
import { UnionFeedScreen } from '../../screens/Unions/UnionFeedScreen'
import PostScreen from '../../screens/Post/PostScreen'
import IntroChristmasScreen from '../../christmasFriends/screens/IntroChristmasScreen'
import LoginChristmasScreen from '../../christmasFriends/screens/LoginChristmasScreen'
import LearnDanishSignupScreen from '../../screens/LearnDanish/LearnDanishSignup'

import { txt } from '../../locales/i18n'
import Colors from '../../constants/Colors'
import { stackConfig } from '../navigationConfig'
import sharedStack from './sharedStack'

import NormalIcon from '../../components/icons/NormalIcon'
import ActivitiesFeedScreen from '../../screens/Activities/ActivitiesFeedScreen'
import ActivityScreen from '../../screens/Activities/ActivityScreen'
import { CustomText } from '../../components/text/StyledText'
import { LanguagePicker } from '../../components/formElements/LanguagePicker'
import { EventFeedScreen } from "../../screens/Events/EventFeedScreen"
import CustomPressable from "../../components/pressables/CustomPressable"

const Stack = createStackNavigator()

export default function AuthStack() {
  return (
    <Stack.Navigator screenOptions={{ ...stackConfig({}), headerMode: 'float' }}>
      {/*{Platform.OS === 'web' && (*/}
      {/*  <Stack.Screen*/}
      {/*    name="Frontpage"*/}
      {/*    component={FrontpageScreen}*/}
      {/*    options={({ navigation }) => ({*/}
      {/*      title: txt('frontPage.title'),*/}
      {/*      headerRight: () => headerOptions(navigation, false),*/}
      {/*      headerLeft,*/}
      {/*    })}*/}
      {/*  />*/}
      {/*)}*/}

      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={({ navigation }) => ({
          headerRight: () => headerOptions(navigation),
        })}
      />

	{Platform.OS === 'web' && (
		<>
			<Stack.Screen
				name="ResetPassword"
				component={ResetPasswordScreen}
				options={({ navigation }) => ({
				headerRight: () => headerOptions(navigation),
				title: txt('passwordField.titleReset'),
				})}
			/>
			<Stack.Screen
				name="Post"
				component={PostScreen}
				options={({ navigation }) => ({
				headerRight: () => headerOptions(navigation, false),
				title: '',
				})}
			/>
			<Stack.Screen
				name="Menu"
				component={StackMenuAuthScreen}
				options={{ title: txt('stackMenu.title') }}
			/>
			<Stack.Screen
				name="SnakSammenSignup"
				component={SnakSammenSignupScreen}
				options={{ title: txt('videoChat.title') }}
			/>
			<Stack.Screen
				name="LearnDanishSignup"
				component={LearnDanishSignupScreen}
				options={{
				title: txt('learnDanish.title'),
				headerBackTitle: txt('learnDanish.title'),
				}}
			/>
			<Stack.Screen
				name="Groups"
				component={GroupsScreen}
				options={{ title: txt('groups.title'), headerRight }}
			/>
			<Stack.Screen
				name="GroupsSignup"
				component={GroupsSignupScreen}
				options={{ title: txt('groups.title'), headerRight }}
			/>
			<Stack.Screen
				name="Unions"
				component={UnionFeedScreen}
				options={{ title: txt('unions.title'), headerRight }}
			/>
			<Stack.Screen
				name="Union"
				component={UnionScreen}
				options={{ title: txt('unions.title'), headerRight }}
			/>
			<Stack.Screen
				name="Activities"
				component={ActivitiesFeedScreen}
				options={{ title: txt('activities.title') }}
			/>
			<Stack.Screen
				name="Activity"
				component={ActivityScreen}
				options={{ title: txt('activities.activity') }}
			/>
			<Stack.Screen
				name="ActivitiesSignup"
				component={ActivitiesSignupScreen}
				options={{ title: txt('activities.title'), headerRight }}
			/>
			<Stack.Screen
				name="Events"
				component={EventFeedScreen}
				options={({ navigation }) => ({
				headerRight: () => headerOptions(navigation, false),
				title: txt('events.title'),
				})}
			/>
			<Stack.Screen
				name="SignupUniverse"
				component={SignupUniverseScreen}
				options={{ headerBackTitle: txt(`universe.backTitle`) }}
			/>
			<Stack.Screen
				name="SignupChristmas"
				component={SignupChristmasScreen}
				options={{ headerBackTitle: txt(`christmasFriends.title`) }}
			/>
			<Stack.Screen
				name="ChristmasIntro"
				component={IntroChristmasScreen}
				options={{
				headerShown: false,
				title: txt(`christmasFriends.browserTitle`),
				}}
			/>
			<Stack.Screen
				name="ChristmasLoginScreen"
				component={LoginChristmasScreen}
				options={{ headerShown: false }}
			/>
			{sharedStack()}
		</>
	)}
    </Stack.Navigator>
  )
}


const headerRight = () => <LanguagePicker />
const headerLeft = () => <LanguagePicker />

const headerOptions = (navigation, lang = true) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    {lang ? <LanguagePicker /> : (
      <CustomText accessibilityRole="button" onPress={() => navigation.navigate('Login')} style={{ marginRight: 12 }}>
        {txt('loginField.loginButtonText')}
      </CustomText>
    )}
      {Platform.OS === 'web' && (
          <CustomPressable
            style={{ justifyContent: 'center', marginHorizontal: 12 }}
            onPress={() => navigation.navigate('Menu')}
            accessibilityLabel={txt('mainTabNavigator.menuName')}
            accessibilityRole="button"
          >
            <NormalIcon
              name="bars"
              size={28}
              color={Colors.blue}
            />
          </CustomPressable>
      )}
  </View>
)
