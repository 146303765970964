import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import Layout from '../../constants/Layout'
import NormalIcon from '../icons/NormalIcon'
import { Dimensions } from 'react-native'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import { txt } from '../../locales/i18n'
import { ButtonPrimary, ButtonSecondary } from '../buttons/StyledButtons'
import { CustomText } from '../text/StyledText'
import CustomPressable from "../pressables/CustomPressable"

const { width } = Dimensions.get('window')
const carouselWidth = width > 900 ? 900 : width

export default function CarouselPrimary(props) {
  let carouselRef

  return (
    <View style={styles.carouselContainer}>
      <CustomPressable onPress={() => carouselRef.snapToPrev()}>
        <NormalIcon name="chevron-left" color={Colors.text} />
      </CustomPressable>
      <Carousel
        ref={(ref) => {
          carouselRef = ref
        }}
        data={props.data}
        sliderWidth={Layout.isSmallDevice ? carouselWidth - 56 : carouselWidth}
        itemWidth={
          Layout.isSmallDevice ? carouselWidth - 56 : carouselWidth / 3
        }
        renderItem={props.renderData}
        loop
        inactiveSlideOpacity={1} //makes web view show both items equally big
        inactiveSlideScale={1} //makes web view show both items equally big
      />
      <CustomPressable onPress={() => carouselRef.snapToNext()}>
        <NormalIcon name="chevron-right" color={Colors.text} />
      </CustomPressable>
    </View>
  )
}

export function CarouselSingle(props) {
  let carouselRef
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <View>
      <View style={{ alignItems: 'center', alignSelf: 'center' }}>
        <View style={{ width: '100%', paddingHorizontal: 12 }}>
          <Carousel
            ref={(ref) => carouselRef = ref}
            data={props.data}
            renderItem={props.renderItem}
            sliderWidth={width <= 330 ? 280 : 330}
            itemWidth={width <= 330 ? 280 : 330}
            onSnapToItem={(index) => setCurrentIndex(index)}
            loop={props.loop || false}
            scrollEnabled={true}
            useScrollView={true}
          />
        </View>
        {props.pagination && (
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <CustomPressable
              onPress={() => carouselRef.snapToPrev()}
              hitSlop={{ left: 100 }}
              style={{ paddingRight: 36 }}
            >
              <NormalIcon
                name="chevron-left"
                color={currentIndex !== 0 ? Colors.text : Colors.transparent}
              />
            </CustomPressable>
            <Pagination
              carouselRef={carouselRef}
              dotsLength={props.data.length}
              activeDotIndex={currentIndex}
            />
            <CustomPressable
              onPress={() => carouselRef.snapToNext()}
              hitSlop={{ right: 100 }}
              style={{ paddingLeft: 36 }}
            >
              <NormalIcon
                name="chevron-right"
                color={currentIndex !== props.data.length - 1 ? Colors.text : Colors.transparent}
              />
            </CustomPressable>
          </View>
        )}
      </View>
      <View>
        {currentIndex === props.data.length - 1 && (
          <ButtonPrimary
            style={{ marginBottom: 12, backgroundColor: Colors.blueAzure }}
            title={txt('christmasFriends.afterMatch.dialogButton')}
            font="bold"
            onPress={props.dismiss}
          />
        )}
      </View>
    </View>
  )
}

export function CarouselSurvey({ data, renderItem, prev, next, pagination, submitTitle, submit }) {
  let carouselRef
  const [currentIndex, setCurrentIndex] = useState(0)
  const surveyWidth = width > Layout.maxFeedWidth ? 500 : width

  return (
    <View style={{ alignItems: 'center', alignSelf: 'center' }}>
      {pagination && (
        <CustomText font="largeBold" style={{ marginTop: 12 }}>
          {`${txt('learnDanish.survey.question')} ${currentIndex + 1}/${data.length}`}
        </CustomText>
      )}
      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
        <Carousel
          ref={(ref) => {
            carouselRef = ref
          }}
          data={data}
          renderItem={renderItem}
          sliderWidth={surveyWidth - 74}
          itemWidth={surveyWidth - 74}
          onSnapToItem={(index) => setCurrentIndex(index)}
          loop={false}
          scrollEnabled={true}
          useScrollView={true}
        />
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 12 }}>
        {currentIndex !== 0 && (
          <ButtonSecondary
            title={prev}
            style={{ margin: 6 }}
            icon="chevron-left"
            onPress={() => carouselRef.snapToPrev()}
            disabled={currentIndex === 0}
          />
        )}
        {currentIndex !== data.length - 1 ? (
          <ButtonSecondary
            title={next}
            style={{ margin: 6 }}
            iconRight="chevron-right"
            onPress={() => carouselRef.snapToNext()}
          />
        ) : (
          <ButtonPrimary
            title={submitTitle}
            style={{ margin: 6 }}
            onPress={submit}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  carouselContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
})
