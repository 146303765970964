import React from 'react'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import CustomDialog, {
  ActionButton,
  ContentContainer,
} from '../../../dialogs/CustomDialog'

const LeaveGroupDialog = (props) => (
  <CustomDialog visible={props.visible}>
    <ContentContainer>
      <CustomText>{txt('groups.members.leaveGroupText')}</CustomText>
    </ContentContainer>
    <ActionButton onPress={props.accept}>
      {txt('groups.members.yes')}
    </ActionButton>
    <ActionButton onPress={props.cancel}>
      {txt('groups.members.no')}
    </ActionButton>
  </CustomDialog>
)

export default LeaveGroupDialog
