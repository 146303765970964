import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useEffect, useState } from 'react'
import { getGroupByID } from '../../../services/api/Groups'
import GroupInfo from './GroupInfo'
import GroupBulletin from './GroupBulletin'
import GroupMembers from './GroupMembers'
import { setAnnouncement, setDataResponsible } from '../../../redux/actions'
import store from '../../../redux/store'
import GroupSettings from './GroupSettings'
import { CustomChip } from '../../chips/Chip'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import Colors from '../../../constants/Colors'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import { useNavigation, useRoute } from '@react-navigation/native'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import { blogHeader } from '../../../styles/typography'
import CustomPressable from "../../pressables/CustomPressable"

export function Group(props) {
  const [loading, setLoading] = useState(true)
  const [group, setGroup] = useState(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState(1)
  const route = useRoute()
  const navigation = useNavigation()

  useEffect(() => {
    if (group) {
      navigation.setOptions({
        headerStyle: {
          backgroundColor: group.theme?.background || Colors.background,
          elevation: 0,
          shadowOpacity: 0,
          borderBottomWidth: 0,
        },
        headerTitle: group.name,
        headerTitleStyle: { maxWidth: 300, ...blogHeader },
        headerBackTitleVisible: false,
        headerBackImage: () => (
          <CustomPressable style={{ padding: 12 }} onPress={navigation.goBack}>
            <NormalIcon
              color={Colors.blue}
              name="chevron-left"
              size={Fontsizes.m}
              stroke="fas"
              style={{ paddingLeft: 6, paddingRight: 20 }}
            />
          </CustomPressable>
        ),
      })
    }
  }, [group])

  useEffect(() => {
    getGroupByID(route.params?.id).then(setGroupData)
  }, [route.params?.id])

  useEffect(() => {
    const { refresh, id } = route.params
    if (refresh) {
      setLoading(true)
      getGroupByID(id).then(setGroupData)
      navigation.setParams({ id, refresh: false })
    }
  }, [route.params?.refresh])

  function setTheme(theme) {
    if (!group) return
    setGroup((prev) => ({ ...prev, theme }))
  }

  function showDataResponsibility(dataResponsible) {
    if (dataResponsible) {
      const boblbergText = txt('groups.boblbergResponsibility')
      const groupOwnerText =
        txt('groups.groupOwnerResponsibility') + dataResponsible
      store.dispatch(setDataResponsible(dataResponsible))
      store.dispatch(setAnnouncement(groupOwnerText))
      navigation.addListener('blur', async () => {
        store.dispatch(setAnnouncement(boblbergText))
        store.dispatch(setDataResponsible(''))
      })
      navigation.addListener('focus', async () => {
        store.dispatch(setDataResponsible(dataResponsible))
        store.dispatch(setAnnouncement(groupOwnerText))
      })
    }
  }

  function setGroupData(group) {
    // Redirect if unauthorized
    if (!group || group?.status_code >= 400 || group?.status_code <= 499) return props.navigateToGroups()
    showDataResponsibility(group.data_responsible)
    setGroup(group)
    setLoading(false)
  }

  if (loading) return <LoadingSpinner />

  return (
    <View style={{ flex: 1, backgroundColor: group.theme?.background || Colors.background }}>
      {/* Info */}
      <View style={styles.chipContainer}>
        <View style={styles.chip}>
          <CustomChip
            icon="info-circle"
            selected={selectedMenuItem === 0}
            onPress={() => setSelectedMenuItem(0)}
            accessibility={txt('groups.menu.infoTitle')}
            text={txt('groups.menu.infoTitle')}
          />
        </View>

        {/* Bulletins */}
        <View style={styles.chip}>
          <CustomChip
            icon="calendar-day"
            selected={selectedMenuItem === 1}
            onPress={() => setSelectedMenuItem(1)}
            accessibilityLabel={txt('groups.menu.bulletinTitle')}
            text={txt('groups.menu.bulletinTitle')}
          />
        </View>

        {/* Members */}
        <View style={styles.chip}>
          <CustomChip
            icon="users"
            selected={selectedMenuItem === 2}
            onPress={() => setSelectedMenuItem(2)}
            accessibilityLabel={txt('groups.menu.membersTitle')}
            text={!Layout.isSmallDevice && txt('groups.menu.membersTitle')}
          />
        </View>

        {/* Settings */}
        {group.own_user_rank === 1 && (
          <View style={styles.chip}>
            <CustomChip
              icon="cog"
              selected={selectedMenuItem === 3}
              onPress={() => setSelectedMenuItem(3)}
              accessibilityLabel={txt('groups.menu.settingsTitle')}
              text={!Layout.isSmallDevice && txt('groups.menu.settingsTitle')}
            />
          </View>
        )}
      </View>
      <GroupInfo style={selectedMenuItem === 0 ? {} : { display: 'none' }} group={group} />
      <GroupBulletin
        style={selectedMenuItem === 1 ? {} : { display: 'none' }}
        group={group}
        navigateToConversation={props.navigateToConversation}
        navigateToNewConversation={props.navigateToNewConversation}
      />
      <GroupMembers
        style={selectedMenuItem === 2 ? {} : { display: 'none' }}
        group={group}
        navigateToConversation={props.navigateToConversation}
        navigateToNewConversation={props.navigateToNewConversation}
        navigateToGroups={props.navigateToGroups}
        navigation={navigation}
      />
      <GroupSettings
        style={selectedMenuItem === 3 ? {} : { display: 'none' }}
        group={group}
        setGroup={setGroup}
        setTheme={setTheme}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  chip: {
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
  },
  chipContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
})
