import Layout from '../constants/Layout'

export const marginRightLeft10 = {
  marginLeft: 10,
  marginRight: 10,
}

export const paddingRightLeft10 = {
  paddingLeft: 10,
  paddingRight: 10,
}

export const widthAndHeight = {
  width: '100%',
  maxWidth: Layout.maxFeedWidth,
  alignSelf: 'center',
}
