import React from 'react'
import ChangePassword from '../../components/views/Password/ChangePassword'
import PageTitle from '../../components/tags/PageTitle'
import TxtTMD from '../../constants/TxtTMD'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class ChangePasswordScreen extends React.Component {
  render() {
    return (
      <MainContainer>
        <PageTitle title={TxtTMD.resetPassword.title[TxtTMD.lang]} />
        <ChangePassword
          navigation={this.props.navigation}
          route={this.props.route}
        />
      </MainContainer>
    )
  }
}
