import React from 'react'
import InboxOverview from '../../../components/views/Conversations/InboxOverview'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../../components/tags/PageTitle'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { getUniverseConfig } from '../../../universe/universeController'
import { useSelector } from 'react-redux'

export default function InboxUniverseScreen(props) {
  const universe = props.route.params?.universe?.toLowerCase()
  const groups = useSelector(state => state.groups)

  const navigateToConversation = (conversationId) => {
    props.navigation.push('Conversation', {
      id: conversationId,
      universe,
    })
  }

  const goToPost = (postId) => {
    props.navigation.navigate('Post', {
      id: postId,
      universe,
    })
  }

  const goToInformation = (conversationId) => {
    props.navigation.navigate('ConversationInfo', {
      id: conversationId,
      universe,
    })
  }

  const goToChristmasPost = (id) => {
    props.navigation.navigate('christmasFriends', {
      screen: 'PostFeedStack',
      initial: false,
      params: {
        screen: 'ChristmasPost',
        params: { id },
      },
    })
  }

  const goToGroup = (id) => {
    props.navigation.navigate('app', {
      screen: groups?.length > 0 ? 'GroupsStack' : 'MenuStack',
      initial: false,
      params: {
        screen: 'Group',
        params: { id },
      },
    })
  }

  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <PageTitle title={TxtTMD.inbox.title[TxtTMD.lang]} />
      <InboxOverview
        navigateToConversation={navigateToConversation}
        goToPost={goToPost}
        goToChristmasPost={goToChristmasPost}
        goToInformation={goToInformation}
        goToGroup={goToGroup}
      />
    </MainContainer>
  )
}
