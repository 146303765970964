import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StyleSheet, Animated, View } from 'react-native'
import Colors from '../../constants/Colors'
import { ShadowStyles } from '../../styles'
import { CustomText } from '../text/StyledText'
import { txt } from '../../locales/i18n'
import { Platform } from 'react-native'
import { URLOpener } from '../../utils/URLOpener'
import Cookies from 'js-cookie'
import { Image } from 'react-native'
import NormalIcon from '../icons/NormalIcon'
import { getLanguage } from '../../services/localization'
import { browserName } from "react-device-detect"
import { dropShadow } from "../../styles/shadows"
import CustomPressable from "../pressables/CustomPressable"

export default function DownloadAppBanner() {
  const userToken = useSelector((state) => state.userToken)
  const overlayCookie = Cookies.get('doNotShowDownloadAppBanner')
  const animated = new Animated.Value(-250)
  const isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  const isAndroid = /Android/i.test(navigator.userAgent)

  useEffect(() => {
    openOverlay()
  }, [userToken])

  const openOverlay = () => {
    const isNotBrowser = Platform.OS !== 'web'
    if (isNotBrowser) return

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    if (isMobile === false) return

    // safari mobile uses [Apple Smart Banner] metatag
    const isSafari = browserName?.toLowerCase()?.includes('safari') ?? false
    if (isSafari) return

    if (userToken === null || overlayCookie) return

    const animation = Animated.timing(animated, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: true,
    })

    animation.start()
  }

  const closeOverlay = () => {
    Animated.timing(animated, {
      toValue: -250,
      duration: 1000,
      useNativeDriver: true,
    }).start()
    Cookies.set('doNotShowDownloadAppBanner', 1, { expires: 7 })
  }

  const openApp = async () => {
    const language = await getLanguage()

    const iosLink ='https://apps.apple.com/dk/app/boblberg-find-f%C3%A6llesskaber/id1530812655?l=' + language
    const androidLink = 'https://play.google.com/store/apps/details?id=dk.boblberg.Boblberg'

    if (isIos) URLOpener(iosLink)
    else if (isAndroid) URLOpener(androidLink)
    closeOverlay()
  }

  return (
    <Animated.View
      accessibilityElementsHidden={true} importantForAccessibility="no" accessibilityHidden={true}
      style={[{ transform: [{ translateY: animated }] }, styles.container]}
    >
      <View style={styles.content}>
        <CustomPressable
          style={styles.touchableArea}
          onPress={openApp}
          accessibilityRole="link"
          href="#"
        >
          <View style={styles.iconContainer}>
            <Image
              source={require('../../assets/images/bobl.png')}
              style={{ height: 35, width: 35 }}
              resizeMode="contain"
            />
          </View>
          <View style={{ flex: 1 }}>
            <CustomText
              font="smallBold"
              style={styles.text}
              accessibilityLabel={txt(
                `overlay.downloadApp.${
                  isIos ? 'downloadIos' : 'downloadAndroid'
                }`
              )}
            >
              {txt(
                `overlay.downloadApp.${
                  isIos ? 'downloadIos' : 'downloadAndroid'
                }`
              )}
            </CustomText>
          </View>
        </CustomPressable>
        <CustomPressable onPress={closeOverlay}>
          <NormalIcon
            name="times"
            color={Colors.text}
            style={{ paddingHorizontal: 9, paddingVertical: 9 }}
            size={24}
            accessibilityRole="button"
            accessibilityLabel={txt('signupField.close')}
          />
        </CustomPressable>
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    position: 'absolute',
    width: '100%',
    ...ShadowStyles.dropShadow,
    shadowOpacity: 0.2,
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  iconContainer: {
    alignItems: 'center',
    borderColor: Colors.greyLightLight,
    borderRadius: 10,
    borderWidth: 0.5,
    height: 45,
    justifyContent: 'center',
    marginRight: 12,
    ...dropShadow,
    width: 45,
  },
  text: {
    fontSize: 14,
    lineHeight: 18,
  },
  touchableArea: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
})
