import CustomDialog, { ActionButton, ContentContainer } from '../../../dialogs/CustomDialog'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import React from 'react'

export default function DeletePostDialog({ visible, deletePost, cancel }) {
  return (
    <CustomDialog visible={visible}>
      <ContentContainer>
        <CustomText>{txt('post.deletePostAlert')}</CustomText>
      </ContentContainer>
      <ActionButton onPress={deletePost}>
        {txt('post.deletePostYes')}
      </ActionButton>
      <ActionButton onPress={cancel}>
        {txt('post.deletePostNo')}
      </ActionButton>
    </CustomDialog>
  )
}
