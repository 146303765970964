import { setUserUniverses } from '../../redux/actions'
import store from '../../redux/store'
import { apiGet, apiPost, apiPut } from './ApiRequest'
import { updateUserPreferences } from './User'

export async function joinUniverseById(universeId) {
  try {
    let result = await apiPost({}, `universe/join/${universeId}`)
    result = await result.json()
    store.dispatch(setUserUniverses([result.universe]))
    if (![10, 14].includes(universeId)) {
      await updateUserPreferences({ default_universe: universeId })
    }
    return true
  } catch {
    return false
  }
}

export async function updateUniverseProfile(universeId, profile) {
  const body = {
    division: profile.division,
    subDivision: profile.subDivision || null,
  }
  let res = await apiPut(body, `universe/${universeId}/profiles`)
  return res.ok ? res.json() : null
}

export async function getUniverseProfile(universeId) {
  return apiGet(`universe/${universeId}/profiles`)
}

// TODO: delete below and use function above instead
export async function isUniversitySet(universeId) {
  const res = await apiGet(`universe/university/${universeId}`)
  if (!res) return
  return res.universityIsSet
}

export async function setUniversity(university) {
  let res = await apiPost({}, `universe/${university}`)
  if (!res) return
  return res
}
