import React from 'react'
import { View, Text, StyleSheet, Image, Dimensions } from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import PageTitle from '../../../tags/PageTitle'
import Layout from '../../../../constants/Layout'
import { ProjectStyles } from './ProjectStyles'

const { width } = Dimensions.get('window')

export default class PHD extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={ProjectStyles.widthHeight}>
        <PageTitle title={TxtProjects.phd.title[TxtProjects.lang]} />

        <View style={ProjectStyles.container}>
          <Text
            style={ProjectStyles.blogTitle}
            accessibilityRole="header"
            aria-level={1}
          >
            {TxtProjects.phd.title[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageAU}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/AAU_LOGO.png',
            }}
          />
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.phd.text1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.phd.text2[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.phd.text3[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.phd.projectPeriod[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.blogText}>
            {TxtProjects.phd.period[TxtProjects.lang]}
          </Text>

          <Text
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.phd.involved[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.phd.partner1[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.phd.partner2[TxtProjects.lang]}
          </Text>
          <Text style={ProjectStyles.partners}>
            {TxtProjects.phd.partner3[TxtProjects.lang]}
          </Text>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageAK}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/Aalborg_Kommune_logo.png',
            }}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  imageAK: {
    height: Layout.isSmallDevice ? width * 0.15 : 75,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.45 : 250,
  },
  imageAU: {
    height: Layout.isSmallDevice ? width * 0.25 : 125,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.45 : 225,
  },
})
