import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { InputPrimary } from '../../text/StyledTextinputs'
import DatePicker from '../../dates/DatePicker'
import { ButtonPrimary, ButtonSecondary } from '../../buttons/StyledButtons'
import CustomDialog from '../../dialogs/CustomDialog'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { getJuniorConsent } from '../../../services/api/LearnDanish'
import { SproglandJuniorConsentDialog } from '../../../components/dialogs/SproglandJuniorConsentDialog'

export default function JuniorCreateDialog({
  visible,
  onDismiss,
  createJuniorProfile,
  updateJuniorProfile,
  deleteJuniorProfile,
  juniorEdit,
}) {
  const [name, setName] = useState('')
  const [birthday, setBirthday] = useState(null)
  const [creating, setCreating] = useState(false)
  const [successCreate, setSuccessCreate] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deletingSuccess, setDeletingSuccess] = useState(false)
  const [deletingError, setDeletingError] = useState('')
  const [showConsentDialog, setShowConsentDialog] = useState(false)

  useEffect(() => {
    setName(juniorEdit?.name || '')
    setBirthday(juniorEdit ? new Date(juniorEdit.birthday) : null)
  }, [juniorEdit])

  async function handleSubmit() {
	const consentGiven = await getJuniorConsent()
	setShowConsentDialog(!consentGiven)
    if (!consentGiven) return

    setCreating(true)
    if (juniorEdit) await updateJuniorProfile({ name, birthday, id: juniorEdit.id })
    else await createJuniorProfile({ name, birthday })
    setCreating(false)
    setSuccessCreate(true)
    setTimeout(resetAndClose, 700)
  }

  async function handleDelete() {
    if (confirmDelete) {
      setDeleting(true)
      const res = await deleteJuniorProfile(juniorEdit.id)
      setDeleting(false)
      if (res.error) {
        setDeletingError(res.error)
      } else {
        setDeletingSuccess(true)
        setTimeout(resetAndClose, 700)
      }
    } else {
      setConfirmDelete(true)
    }
  }

  function resetAndClose() {
    onDismiss()
    setName('')
    setBirthday(null)
    setSuccessCreate(false)
    setDeletingSuccess(false)
    setConfirmDelete(false)
    setDeletingError('')
  }

  return (
    <CustomDialog visible={visible} onDismiss={resetAndClose}>
      <View style={styles.juniorCreateContainer}>
        {!confirmDelete && (
          <>
            <InputPrimary
              style={{ marginTop: 0 }}
              value={name}
              placeholder={txt('learnDanish.junior.namePlaceholder')}
              onChangeText={setName}
            />
            <View style={styles.dateContainer}>
              <DatePicker
                date={birthday}
                handleDateChange={setBirthday}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="d. MMMM yyyy"
                maxDate={new Date()}
                minDate={new Date((new Date().setFullYear(new Date().getFullYear() - 18)))}
                placeholder={txt('learnDanish.junior.birthdayPlaceholder')}
              />
            </View>
            <ButtonPrimary
              title={txt(`learnDanish.junior.${juniorEdit ? 'update' : 'createPlaceholder'}`)}
              style={styles.button}
              icon={juniorEdit ? "user" : "user-plus"}
              onPress={handleSubmit}
              success={successCreate}
              loading={creating}
              disabled={birthday === null || name?.length < 2}
            />
          </>
        )}
        {juniorEdit && confirmDelete && (
          <CustomText style={{ marginBottom: 12 }}>
            {txt('learnDanish.junior.deleteConfirm')}
            <CustomText font="bold">{' ' + juniorEdit.name}</CustomText>
          </CustomText>
        )}
        {juniorEdit && (
          <>
            {!!deletingError && (
              <CustomText style={{ color: Colors.danger, marginTop: 6 }}>{txt(`apiError.${deletingError}`)}</CustomText>
            )}
            <ButtonPrimary
              title={txt('learnDanish.junior.delete')}
              style={styles.buttonDelete}
              icon="trash"
              onPress={handleDelete}
              loading={deleting}
              success={deletingSuccess}
              error={!!deletingError}
            />
            <ButtonSecondary
              title={txt('learnDanish.junior.cancel')}
              style={styles.buttonCancel}
              onPress={resetAndClose}
            />
          </>
        )}
      </View>

      <SproglandJuniorConsentDialog
        visible={showConsentDialog}
        setVisible={setShowConsentDialog}
        onCancel={() => setShowConsentDialog(false)}
        acceptOnly
      />
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  juniorCreateContainer: {
    minWidth: 300,
    padding: 24,
  },
  dateContainer: {
    zIndex: 1,
    marginTop: 12,
    height: 40,
  },
  button: {
    marginTop: 24,
    width: '100%',
  },
  buttonDelete: {
    marginTop: 12,
    width: '100%',
    backgroundColor: Colors.danger,
  },
  buttonCancel: {
    marginTop: 12,
    width: '100%',
  },
})
