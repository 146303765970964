import React, { useState } from 'react'
import { Dimensions, StyleSheet, View } from "react-native"
import { CustomText } from "../../text/StyledText"
import Colors from "../../../constants/Colors"
import moment from "moment"
import { txt } from "../../../locales/i18n"
import { ButtonPrimary } from "../../buttons/StyledButtons"
import OptionsDialog from "../../dialogs/OptionsDialog"
import CreateSessionDialog from "./dialogs/CreateSessionDialog"
import { getConversationId } from "../../../services/api/Inbox"
import Layout from "../../../constants/Layout"
import UserDetailsDialog from "./dialogs/UserDetailsDialog"

export default function WaitlistUsers({ waitlist, reloadAll, navigateToConversation, navigateToNewConversation }) {

	//sets locale to danish time
	moment.locale([txt('videoChat.locale')])

	const [showOptions, setShowOptions] = useState(false)
	const [inviteDialogVisible, setInviteDialogVisible] = useState(false)
	const [currentUser, setCurrentUser] = useState(null)
	const [userSessionsDialogVisible, setUserSessionsDialogVisible] = useState(false)

	const isSmallDevice = Dimensions.get('window').width < 420

	const options = [
		{
			label: txt('videoChat.messageSend'),
			action: async () => {
				await handleSendMessage()
			},
		},
		{
			label: txt('videoChat.participantInfo'),
			action: () => handleUserDetailsPress(),
		},
	]

	function handleUserDetailsPress() {
		setShowOptions(false)
		setUserSessionsDialogVisible(true)
	}

	async function handleSendMessage() {
		// No session id is available for invitation; using 1 as identifier for invitation scenarios.
		const sessionId = 1

		const conversationId = await getConversationId({ vsSessionId: sessionId, receiverId: currentUser.userId })

		if (conversationId) navigateToConversation(conversationId)
		else navigateToNewConversation(currentUser.userId, sessionId)

		setShowOptions(false)
	}

	const handleInvite = (user) => {
		setCurrentUser(user)
		setInviteDialogVisible(true)
	}

	const handleMore = (user) => {
		setCurrentUser(user)
		setShowOptions(true)
	}

	const WaitlistUser = ({ user }) => {

		if (isSmallDevice) return (
			<View style={[styles.card, styles.smallLayout]}>
				<View>
					<CustomText font="gotham-bold-small">{user.username}</CustomText>
					<CustomText font="gotham-small">
						{txt('videoChat.volunteer.waitlist.waitlistedSince') + moment(user.created).format('dddd DD.MM.YY')}
					</CustomText>
				</View>
				<View style={[styles.buttonContainer, { flexDirection: 'row', alignSelf: 'center', marginTop: 12 }]}>
					<View style={styles.buttonStartContainer}>
						<ButtonPrimary
							title={txt('videoChat.volunteer.waitlist.invite')}
							style={styles.button}
							titleStyle={styles.buttonTitle}
							onPress={() => handleInvite(user)}
						/>
					</View>
					<View style={styles.buttonStartContainer}>
						<ButtonPrimary
							title={txt('videoChat.more')}
							style={styles.button}
							titleStyle={styles.buttonTitle}
							onPress={() => handleMore(user)}
						/>
					</View>
				</View>
			</View>
		)
		return (
			<View style={[styles.card, styles.largeLayout]}>
				<CustomText font="gotham-bold-small" style={{ flex: 2 }}>{user.username}</CustomText>
				<CustomText font="gotham-small" style={{ flex: 2 }}>
					{txt('videoChat.volunteer.waitlist.waitlistedSince') + moment(user.created).format('dddd DD.MM.YY')}
				</CustomText>
				<View style={[styles.buttonContainer, { flex: 2 }]}>
					<ButtonPrimary
						title={txt('videoChat.volunteer.waitlist.invite')}
						style={styles.button}
						titleStyle={styles.buttonTitle}
						onPress={() => handleInvite(user)}
					/>
					<ButtonPrimary
						title={txt('videoChat.more')}
						style={styles.button}
						titleStyle={styles.buttonTitle}
						onPress={() => handleMore(user)}
					/>
				</View>
			</View>
		)
	}

	return (
		<View style={styles.container}>
			<CustomText font="gotham-xlarge" style={styles.titleBold}>
				{txt('videoChat.volunteer.waitlist.participants')}
			</CustomText>
			{waitlist?.length > 0 ? (
				waitlist?.map((user, key) => (
					<WaitlistUser key={key} user={user} />
				))
			) : (
				<CustomText font="gotham-small" style={styles.noWaitlist}>
					{txt('videoChat.volunteer.waitlist.noParticipants')}
				</CustomText>
			)}
			<OptionsDialog
				visible={showOptions}
				onDismiss={() => setShowOptions(false)}
				options={options}
			/>

			<CreateSessionDialog
				visible={inviteDialogVisible}
				onDismiss={() => setInviteDialogVisible(false)}
				reloadAll={reloadAll}
				user={currentUser}
				waitlist={true}
			/>

			<UserDetailsDialog
				visible={userSessionsDialogVisible}
				onDismiss={() => setUserSessionsDialogVisible(false)}
				currentItem={currentUser}
			/>

		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		margin: 10,
	},
	titleBold: {
		marginTop: 24,
		marginBottom: 6,
	},
	card: {
		backgroundColor: Colors.white,
		borderRadius: 10,
		marginTop: 10,
		paddingVertical: Layout.small ? 18 : 12,
		paddingHorizontal: Layout.small ? 24 : 18,
	},
	smallLayout: {
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	largeLayout: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	buttonContainer: {
		flexDirection: Layout.small ? 'column' : 'row',
		justifyContent: 'flex-end',
	},
	button: {
		borderRadius: 10,
		backgroundColor: Colors.redCross.background,
		paddingVertical: 13,
		paddingHorizontal: 32,
		marginLeft: 12,
		minWidth: 115,
	},
	buttonTitle: {
		color: Colors.redCross.text,
		fontSize: 14,
		fontFamily: 'gotham-bold'
	},

	noWaitlist: {
		color: Colors.inactive,
		marginTop: 10,
		marginBottom: 24,
	},

	buttonStartContainer: {
		flex: 1,
		justifyContent: 'center',
		paddingRight: 2,
	},
})