import React from 'react'
import { View, StyleSheet, FlatList } from 'react-native'
import GroupItem from './GroupItem'
import Colors from '../../../constants/Colors'
import { SpacingStyles } from '../../../styles'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'

export default function GroupsJoined(props) {
  if (props.groups?.length > 0) {
    return (
      <View style={styles.container}>
        <FlatList
          data={props.groups}
          renderItem={({ item }) => <GroupItem group={item} navigateToGroup={props.navigateToGroup} />}
          keyExtractor={(item) => item.id.toString()}
        />
      </View>
    )
  } else
    return (
      <View style={styles.container}>
        <CustomText style={styles.text} font="regularbold">
          {txt('groups.noMyGroups')}
        </CustomText>
      </View>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...SpacingStyles.widthAndHeight,
  },
  text: {
    color: Colors.inactive,
    paddingTop: 20,
    textAlign: 'center',
  },
})
