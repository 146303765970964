import React from 'react'
import { ProgressBar } from 'react-native-paper'
import Colors, { getColorFromPercent } from '../../constants/Colors'
import { StyleSheet } from 'react-native'
import { CornerStyles, ShadowStyles } from '../../styles'

export function Progress({progress, style}) {
  return (
    <ProgressBar
      progress={progress}
      style={[styles.progress, style]}
      color={getColorFromPercent(progress * 100)}/>
  )
}

const styles = StyleSheet.create({
  progress: {
    backgroundColor: Colors.white,
    ...CornerStyles.borderRadiusM,
    ...ShadowStyles.shadowInputs,
    height: 10,
  },
})
