import React from 'react'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import moment from 'moment'
import { formatDate } from "./utils/helperFunctions"
import Layout from "../../../constants/Layout"

export default function AllSessions(props) {
  //sets locale to danish time
  moment.locale([txt('videoChat.locale')])

  return (
    <View style={styles.container}>
      {props.sessions?.length === 0 ? (
        <View>
          <CustomText font="gotham-xlarge" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
            {txt('videoChat.allSessions')}
          </CustomText>
          <CustomText style={styles.noSession} font="gotham-small">
            {txt('videoChat.noAllSessions')}
          </CustomText>
        </View>
      ) : (
        <View>
          <CustomText font="gotham-xlarge" style={styles.titleBold} accessibilityRole="header" aria-level={2}>
            {txt('videoChat.allSessions')}
          </CustomText>
          <CustomText font="gotham-small" style={styles.allSessionsInfo}>{txt('videoChat.allSessionsInfo')}</CustomText>
          {props.sessions?.map((item, key) => {
            const formattedDate = formatDate(item.start_date, item.end_date)
            return (
              <View key={key} style={item.booked === 1 ? styles.cardBooked : styles.card}>
                <View style={{ flex: 2 }}>
                  <CustomText font="gotham-bold-small">{formattedDate}</CustomText>
                  <CustomText font="gotham-small">
                    {txt('videoChat.volunteer.title') + ': ' + item.username}
                  </CustomText>
                </View>
              </View>
            )
          })}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  noSession: {
    color: Colors.inactive,
    marginTop: 10,
  },
  card: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    flexDirection: 'row',
    marginTop: 10,
    paddingVertical: Layout.isSmallDevice ? 18 : 12,
    paddingHorizontal: Layout.isSmallDevice ? 24 : 18,
  },
  cardBooked: {
    backgroundColor: '#efffed',
    borderRadius: 10,
    flexDirection: 'row',
    marginTop: 10,
    paddingVertical: Layout.isSmallDevice ? 18 : 12,
    paddingHorizontal: Layout.isSmallDevice ? 24 : 18,
  },
  titleBold: {
    marginTop: 24,
    marginBottom: 6,
  },
  allSessionsInfo: {
    color: Colors.inactive,
  }
})
