import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavigationContainer } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import MainTabNavigator from './MainTabNavigator'
import AuthStack from './navigationStacks/AuthStack'
import { getAppConfig, authConfig } from './navigationConfig'
import UniverseStack from './UniverseStack'
import { createStackNavigator } from '@react-navigation/stack'
import * as RootNavigation from './RootNavigation'
import { registerPushNotifications } from '../services/notifications'
import { useRedirect } from './useRedirect'
import ChristmasFriendsStack from './ChristmasFriendsStack'
import trackPlausiblePageView from '../services/api/plausible'
import { txt } from '../locales/i18n'
import StudieboblerStack from './StudieboblerStack'
import associatedDomains from '../constants/associated-domains.json'
import { DeepLinkSessionDialog } from "../components/overlay/DeepLinkSession"

const AppContainer = () => {
  useRedirect()
  useEffect(() => {
    registerPushNotifications()
  }, [])

  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="app" component={MainTabNavigator} options={{ title: 'Boblberg' }} />
      <Stack.Screen name="universe" component={UniverseStack} options={{ title: txt('universe.title') }} />
      <Stack.Screen name="christmasFriends" component={ChristmasFriendsStack} options={{ title: 'Julevenner' }} />
      <Stack.Screen name="studiebobler" component={StudieboblerStack} options={{ title: 'Studiebobler' }} />
    </Stack.Navigator>
  )
}

export default function Navigator() {
  const userToken = useSelector((state) => state.userToken)
  const preferences = useSelector((state) => state.preferences)
  const groups = useSelector((state) => state.groups)
  const prefix = Linking.createURL('/')

  const linking = {
    prefixes: [prefix, ...associatedDomains.map((associatedDomain) => 'https://' + associatedDomain)],
    config: userToken ? getAppConfig() : authConfig,
  }

  return (
    <NavigationContainer
      linking={linking}
      ref={RootNavigation.navigationRef}
      onStateChange={() => trackPlausiblePageView(RootNavigation.navigationRef.current)}
    >
      <DeepLinkSessionDialog />
      {userToken && preferences && groups ? <AppContainer /> : <AuthStack />}
    </NavigationContainer>
  )
}
