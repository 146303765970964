import React from 'react'
import NormalIcon from '../icons/NormalIcon'
import Colors from '../../constants/Colors'
import { txt } from '../../locales/i18n'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../pressables/CustomPressable"

const FavoriteIndicator = (props) => (
  <CustomPressable /* to make a post favorite */
    hitSlop={{ bottom: 100, right: 100, left: 100, top: 100 }}
    onPress={() => props.toggleFavorite()}
    accessibilityRole={'button'}
    accessibilityLabel={props.isFavorite ? txt('fullPost.removeFavorite') : txt('fullPost.favorite')}
    accessibilityLiveRegion={'polite'}
    accessibilityHint={txt('fullPost.favoriteHint')}
  >
    <NormalIcon
      name="star"
      stroke={props.isFavorite ? "fas" : "fal"}
      color={Colors.tintColor}
      size={props.size || Fontsizes.xxl}
    />
  </CustomPressable>
)

export default FavoriteIndicator
