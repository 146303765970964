import React from 'react'
import { View, StyleSheet } from 'react-native'
import { txt } from '../../../../locales/i18n'
import { CustomText } from '../../../text/StyledText'
import { SpacingStyles } from '../../../../styles'
import { URLOpener } from '../../../../utils/URLOpener'
import { ImageHighlightMedium } from '../../../images/ImageHighlight'
import Colors from '../../../../constants/Colors'
import CustomPressable from "../../../pressables/CustomPressable"

export default class Finn extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={styles.widthHeight}>
        <View style={[styles.marginLeftRight, styles.marginBottom]}>
          <View style={styles.titleContainer}>
            <CustomText font="large" style={styles.center}>
              {txt('blog.finn.title')}
            </CustomText>
          </View>
          <View style={styles.marginBottom}>
            <ImageHighlightMedium
              source={{
                uri: 'https://filer.boblberg.dk/Assets/Img/blog/finn.jpg',
              }}
              accessibilityIgnoresInvertColors
            />
            <CustomText font="bold">{txt('blog.finn.author')}</CustomText>
          </View>
          <View>
            <CustomText>{txt('blog.finn.text1')}</CustomText>
          </View>
          <View>
            <CustomText>
              <CustomText font="bold">
                {txt('blog.finn.highlight1')}
              </CustomText>
              <CustomText font="bold">
                {txt('blog.finn.highlight2')}
              </CustomText>
              <CustomText>{txt('blog.finn.text3')}</CustomText>
            </CustomText>
          </View>
          <View>
            <CustomText font="large">
              {txt('blog.finn.headline')}
            </CustomText>
            <View style={styles.marginBottom}>
              <CustomPressable
                onPress={() => URLOpener('https://pubmed.ncbi.nlm.nih.gov/23993280/')}
                style={styles.marginTop}
              >
                <CustomText font="bold">
                  {txt('blog.finn.referanceHead1')}
                </CustomText>
                <CustomText>
                  {txt('blog.finn.referance1')}

                  <CustomText style={styles.linkText}>
                    {txt('blog.finn.referanceLink1')}
                  </CustomText>
                </CustomText>
              </CustomPressable>
            </View>
            <View style={styles.marginBottom}>
              <CustomPressable
                onPress={() => URLOpener('https://www.cabiweb.dk/media/1634/hvidbog-mentalt-helbred.pdf')}
                style={styles.marginTop}
              >
                <CustomText font="bold">
                  {txt('blog.finn.referanceHead2')}
                </CustomText>
                <CustomText>
                  {txt('blog.finn.referance2')}

                  <CustomText style={styles.linkText}>
                    {txt('blog.finn.referanceLink2')}
                  </CustomText>
                </CustomText>
              </CustomPressable>
            </View>
            <View style={styles.marginBottom}>
              <CustomPressable
                onPress={() => URLOpener('https://pubmed.ncbi.nlm.nih.gov/25678606/')}
                style={styles.marginTop}
              >
                <CustomText font="bold">
                  {txt('blog.finn.referanceHead3')}
                </CustomText>
                <CustomText>
                  {txt('blog.finn.referance3')}

                  <CustomText style={styles.linkText}>
                    {txt('blog.finn.referanceLink3')}
                  </CustomText>
                </CustomText>
              </CustomPressable>
            </View>
            <View style={styles.marginBottom}>
              <CustomPressable
                onPress={() => URLOpener('https://www.altinget.dk/sundhed/artikel/groenbaek-mental-sundhed-kraever-nyt-fokus')}
                style={styles.marginTop}
              >
                <CustomText font="bold">
                  {txt('blog.finn.referanceHead4')}
                </CustomText>
                <CustomText>
                  {txt('blog.finn.referance4')}

                  <CustomText style={styles.linkText}>
                    {txt('blog.finn.referanceLink4')}
                  </CustomText>
                </CustomText>
              </CustomPressable>
            </View>
            <View style={styles.marginBottom}>
              <CustomText font="bold">
                {txt('blog.finn.referanceHead5')}
              </CustomText>
              <CustomText>{txt('blog.finn.referance5')}</CustomText>
            </View>
            <View style={styles.marginBottom}>
              <CustomText font="bold">
                {txt('blog.finn.referanceHead6')}
              </CustomText>
              <CustomText>{txt('blog.finn.referance6')}</CustomText>
            </View>
            <View style={styles.marginBottom}>
              <CustomText font="bold">
                {txt('blog.finn.referanceHead7')}
              </CustomText>
              <CustomText>{txt('blog.finn.referance7')}</CustomText>
            </View>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  linkText: { color: Colors.tintColor },
  marginBottom: { marginBottom: 10 },
  marginLeftRight: { ...SpacingStyles.marginRightLeft10 },
  marginTop: { marginTop: 10 },
  titleContainer: { alignItems: 'center', marginBottom: 10 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
