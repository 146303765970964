import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import da from 'date-fns/locale/da'
registerLocale('da', da)
import 'react-datepicker/dist/react-datepicker.css'
import Colors from '../../constants/Colors'
import { TypographyStyles, ShadowStyles } from '../../styles'
import { CustomText } from '../text/StyledText'
import CustomDialog from '../dialogs/CustomDialog'
import moment from 'moment'
import { ButtonPrimary } from '../buttons/StyledButtons'
import { txt } from '../../locales/i18n'
import CustomPressable from "../pressables/CustomPressable"

export default function WebDatePicker({
  date,
  handleDateChange,
  minTime,
  maxTime,
  minDate,
  maxDate,
  minuteInterval,
  CustomInput,
  dateFormat,
  popperPlacement,
  showTimeSelectOnly,
  placeholder,
  ...props
}) {
  const [showModal, setShowModal] = useState(false)
  const POPPER_HEIGHT = 245

  const DefaultInput = ({ value, onClick, isButton }) => {
    useEffect(() => {
      if (!isButton) {
        onClick()
      }
    }, [])

    return (
      <CustomPressable accessibilityRole="button" onPress={onClick} style={[styles.pickerStyle, props.style]}>
        <CustomText font="gotham-small" style={[styles.text, { color: date ? Colors.text : Colors.textPlaceholder }]}>
          {value || placeholder}
        </CustomText>
        {props.children}
      </CustomPressable>
    )
  }

  const WrappedCustomInput = ({ value, onClick, isButton }) => {
    useEffect(() => {
      if (!isButton) {
        onClick()
      }
    }, [])

    if (!isButton) {
      return DefaultInput({ value, onClick })
    }

    return CustomInput({ value, onClick })
  }

  const selectedDate = useMemo(() => {
    if (!date) return new Date()
    if (date < minDate) return minDate
    return date
  }, [date, minDate])

  const getMinTime = () => {
    const dateNow = moment()
    const dateSelected = date ? moment(date) : null
    const timeMinimum = minTime ? moment(minTime) : null

    const isToday = dateSelected?.format('l') === dateNow.format('l')
    const beforeMinimumTime = dateNow.format('LTS') < timeMinimum?.format('LTS')

    if (isToday && beforeMinimumTime) return timeMinimum.toDate()
    if (isToday && beforeMinimumTime === false) return dateNow.toDate()
    if (timeMinimum && isToday === false) return timeMinimum.toDate()

    return moment().startOf('day').toDate()
  }

  const formatDate = (date) => {
    if (showTimeSelectOnly) return moment(date).format('LT')
    if (minuteInterval) return moment(date).format('lll')
    return moment(date).format('ll')
  }

  return (
    <>
      <CustomDialog
        style={{ backgroundColor: Colors.transparent }}
        visible={showModal}
        onDismiss={() => setShowModal(false)}
      >
        <View style={{ marginTop: POPPER_HEIGHT }}>
          <ReactDatePicker
            selected={selectedDate}
            onChange={(date) => handleDateChange(date)}
            showTimeSelect={!!minuteInterval}
            timeIntervals={minuteInterval}
            timeCaption="Tid"
            minTime={getMinTime()}
            maxTime={maxTime ? new Date(maxTime) : moment().endOf('day').toDate()}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={dateFormat || 'dd/MM/yyyy HH:mm'}
            timeFormat={'HH:mm'}
            locale="da"
            customInput={CustomInput ? <WrappedCustomInput /> : <DefaultInput />}
            popperPlacement={popperPlacement || 'top'}
            showTimeSelectOnly={showTimeSelectOnly}
            placeholderText={'Date'}
            {...props}
          />
        </View>
        <ButtonPrimary
          style={[{ marginTop: 12 }, props.buttonStyle]}
          titleStyle={props.buttonTitleStyle}
          title={showTimeSelectOnly ? txt('activities.create.chooseTime') : txt('activities.create.chooseDate')}
          onPress={() => setShowModal(false)}
        />
      </CustomDialog>
      {CustomInput
        ? WrappedCustomInput({ value: date ? formatDate(date) : null, onClick: () => setShowModal(true), isButton: true })
        : DefaultInput({ value: date ? formatDate(date) : null, onClick: () => setShowModal(true), isButton: true })}
    </>
  )
}

const styles = StyleSheet.create({
  pickerStyle: {
    justifyContent: 'center',
    ...ShadowStyles.shadowInputs,
    backgroundColor: Colors.white,
    borderRadius: 10,
    height: 40,
  },
  text: {
    paddingLeft: 10,
    paddingRight: 10,
    color: Colors.redCross.text
  },
})
