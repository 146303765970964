import React from 'react'
import Colors from '../../constants/Colors'
import { CustomText } from '../text/StyledText'
import { StyleSheet } from 'react-native'
import { TypographyStyles } from '../../styles'
import { txt } from '../../locales/i18n'
import NormalIcon from '../icons/NormalIcon'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../pressables/CustomPressable"

export function FilterButton({
  selected,
  text,
  iconName,
  onPress,
  buttonSelectedStyle,
  buttonUnselectedStyle,
  activeColor,
}) {
  return (
    <CustomPressable
      accessibilityRole="button"
      accessibilityLabel={selected ? txt('chip.selected') + text : text}
      textStyle={selected ? styles.textSelected : styles.textUnselected}
      style={[
        styles.filterButton,
        selected
          ? buttonSelectedStyle
            ? buttonSelectedStyle
            : styles.buttonSelected
          : buttonUnselectedStyle
          ? buttonUnselectedStyle
          : styles.buttonUnselected,
      ]}
      onPress={onPress}
    >
      <NormalIcon
        name={iconName}
        size={Fontsizes.l}
        color={selected ? activeColor || Colors.blue : Colors.inactive}
        stroke={selected ? "fas" : "fal"}
      />
      {text && (
        <CustomText style={styles.textStyle} font="bold">
          {text}
        </CustomText>
      )}
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  buttonSelected: {
    borderColor: Colors.blue,
  },
  buttonUnselected: {
    borderColor: Colors.filterBackground,
  },
  filterButton: {
    alignItems: 'center',
    backgroundColor: Colors.filterBackground,
    borderRadius: 23,
    borderWidth: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 9,
    paddingVertical: 3,
  },
  textSelected: { ...TypographyStyles.textSmallBold, color: Colors.text },
  textStyle: {
    marginLeft: 6,
  },
  textUnselected: { ...TypographyStyles.textSmall, color: Colors.text },
})
