import Colors from '../../constants/Colors'
import { CustomText } from '../text/StyledText'
import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import { NotificationBadge } from '../icons/NotificationBadge'
import NormalIcon, { BoblbergIcon } from '../icons/NormalIcon'
import Fontsizes from '../../constants/Fontsizes'
import CustomPressable from "../pressables/CustomPressable"

export default function ListItem(props) {
  const [focus, setfocus] = useState(false)

  function insertIcon() {
    if (props.item.icon) {
      if (props.item.icon.boblbergIcon) {
        return (
          <BoblbergIcon
            name={props.item.icon.name}
            size={Fontsizes.l}
            color={Colors.text}
            stroke={focus ? "fas" : "fal"}
          />
        )
      } else {
        return (
          <NormalIcon
            name={props.item.icon.name}
            size={Fontsizes.l}
            color={Colors.text}
            stroke={focus ? "fas" : "fal"}
          />
        )
      }
    }
  }
  return (
    <>
      <CustomPressable
        style={styles.item}
        accessibilityRole={'button'}
        onPress={props.item.onPress ?? null}
        onMouseEnter={() => setfocus(true)}
        onMouseLeave={() => setfocus(false)}
      >
        <View style={styles.iconTextContainer}>
          <View style={styles.iconContainer}>{insertIcon()}</View>
          {props.item.title && (
            <CustomText font="large">{props.item.title}</CustomText>
          )}
          <CustomText>{props.item.body}</CustomText>
        </View>
        {!!props.badge && <NotificationBadge number={props.badge} />}
      </CustomPressable>
      {!props.last && <View style={styles.horizontalLine} />}
    </>
  )
}

const styles = StyleSheet.create({
  horizontalLine: {
    backgroundColor: Colors.blackTransparent,
    height: 1,
  },
  iconContainer: {
    marginRight: 20,
  },
  iconTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
})
