import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCamera as faCameraLight,
  faStar as faStarLight,
  faUser as faUserLight,
  faUserPlus as faUserPlusLight,
  faCommentAltLines,
  faPlusCircle as faPlusCircleLight,
  faBars as faBarsLight,
  faCalendarDay as faCalendarDayLight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faMap as faMapLight,
  faImages as faImagesLight,
  faCalendarAlt,
  faUsersClass as faUsersClassLight,
  faClock as faClockLight,
  faUsers as faUsersLight,
  faCity as faCityLight,
  faBuilding as faBuildingLight,
  faTicketAlt as faTicketAltLight,
  faGlobe as faGlobeLight,
  faList as faListLight,
  faSquare as faSquareLight,
  faCheckSquare as faCheckSquareLight,
  faPlusSquare as faPlusSquareLight,
  faEdit as faEditLight,
  faPaperPlane as faPaperPlaneLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faChevronDown as faChevronDownLight,
  faChevronUp as faChevronUpLight,
  faArrowCircleDown,
  faArrowCircleUp,
  faTrash as faTrashLight,
  faEllipsisV as faEllipsisVLight,
  faEllipsisH as faEllipsisHLight,
  faInfoCircle as faInfoCircleLight,
  faNewspaper as faNewspaperLight,
  faTvRetro as faTvRetroLight,
  faRadioAlt as faRadioAltLight,
  faEnvelope as faEnvelopeLight,
  faPhoneAlt as faPhoneAltLight,
  faUserHeadset,
  faCog as faCogLight,
  faUsersCog as faUsersCogLight,
  faPaperclip,
  faUserCheck as faUserCheckLight,
  faCheck as faCheckLight,
  faCheckCircle as faCheckCircleLight,
  faLockOpenAlt as faLockOpenAltLight,
  faCircle as faCircleLight,
  faTimesCircle as faTimesCircleLight,
  faUndo as faUndoLight,
  faRedo as faRedoLight,
  faCompass,
  faCommentLines as faCommentLinesLight,
  faComments as faCommentsLight,
  faCookieBite as faCookieBiteLight,
  faBookOpen as faBookOpenLight,
  faHandshakeAlt as faHandshakeAltLight,
  faSuitcase as faSuitcaseLight,
  faClipboardCheck as faClipboardCheckLight,
  faSignOut,
  faGlobeEurope as faGlobeEuropeLight,
  faUserShield as faUserShieldLight,
  faFileCheck as faFileCheckLight,
  faAward as faAwardLight,
  faTimes as faTimesLight,
  faListUl,
  faMap,
  faVideo,
  faHatSanta as faHatSantaLight,
  faArrowsAltH as faArrowsAltHLight,
  faHomeLgAlt as faHomeLgAltLight,
  faWreath as faWreathLight,
  faPlus as faPlusLight,
  faMinus as faMinusLight,
  faCandyCane as faCandyCaneLight,
  faPen as faPenLight,
  faBell as faBellLight,
  faQuestionCircle as faQuestionCircleLight,
  faTrashAlt as faTrashAltLight,
  faGlassCheers as faGlassCheers,
  faVirus as faVirusLight,
  faThumbtack as faThumbtackLight,
  faRedoAlt as faRedoAltLight,
  faGripVertical as faGripVerticalLight,
  faCommentDots as faCommentDotsLight,
  faFlagAlt as faFlagAltLight,
  faHistory as faHistoryLight,
  faArrowToBottom as faArrowToBottomLight,
  faLink as faLinkLight,
  faSync as faSyncLight,
  faFilter as faFilterLight,
  faGraduationCap as faGraduationCapLight,
  faSave as faSaveLight,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faBook as faBookLight,
  faSignInAlt as faSignInAltLight,
  faShield as faShieldLight,
  faCopy as faCopyLight,
  faCalendarStar as faCalendarStarLight,
  faSearch as faSearchLight,
  faUsersMedical as faUsersMedicalLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faHome as faHomeLight,
  faExclamationCircle as faExclamationCircleLight,
} from '@fortawesome/pro-light-svg-icons'

import {
  faCamera as faCameraSolid,
  faStar as faStarSolid,
  faUser as faUserSolid,
  faUserPlus as faUserPlusSolid,
  faPlusCircle as faPlusCircleSolid,
  faBars as faBarsSolid,
  faCalendarDay as faCalendarDaySolid,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faMap as faMapSolid,
  faImages as faImagesSolid,
  faClock as faClockSolid,
  faUsers as faUsersSolid,
  faCity as faCitySolid,
  faBuilding as faBuildingSolid,
  faTicketAlt as faTicketAltSolid,
  faGlobe as faGlobeSolid,
  faList as faListSolid,
  faSquare as faSquareSolid,
  faCheckSquare as faCheckSquareSolid,
  faEdit as faEditSolid,
  faPlusSquare as faPlusSquareSolid,
  faPaperPlane as faPaperPlaneSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faQuestion,
  faEyeSlash,
  faEye,
  faTrash as faTrashSolid,
  faEllipsisV as faEllipsisVSolid,
  faEllipsisH as faEllipsisHSolid,
  faInfoCircle as faInfoCircleSolid,
  faNewspaper as faNewspaperSolid,
  faEnvelope as faEnvelopeSolid,
  faPhoneAlt as faPhoneAltSolid,
  faCog as faCogSolid,
  faUsersCog as faUsersCogSolid,
  faUserCheck as faUserCheckSolid,
  faCheck as faCheckSolid,
  faTimesCircle as faTimesCircleSolid,
  faUndo as faUndoSolid,
  faRedo as faRedoSolid,
  faComments as faCommentsSolid,
  faCookieBite as faCookieBiteSolid,
  faBookOpen as faBookOpenSolid,
  faSuitcase as faSuitcaseSolid,
  faClipboardCheck as faClipboardCheckSolid,
  faGlobeEurope as faGlobeEuropeSolid,
  faUserShield as faUserShieldSolid,
  faAward as faAwardSolid,
  faArrowsAltH as faArrowAltHSolid,
  faPen as faPenSolid,
  faBell as faBellSolid,
  faBellSlash as faBellSlashSolid,
  faGlassCheers as faGlassCheersSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faThumbtack as faThumbtackSolid,
  faRedoAlt as faRedoAltSolid,
  faGripVertical as faGripVerticalSolid,
  faHistory as faHistorySolid,
  faFilter as faFilterSolid,
  faGraduationCap as faGraduationCapSolid,
  faArrowLeft as faArrowLeftSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
} from '@fortawesome/free-solid-svg-icons'

import {
  faHandshakeAlt as faHandshakeAltSolid,
  faFileCheck as faFileCheckSolid,
  faUsersClass as faUsersClassSolid,
  faHatSanta as faHatSantaSolid,
  faCommentLines as faCommentLinesSolid,
  faHomeLgAlt as faHomeLgAltSolid,
  faWreath as faWreathSolid,
  faCandyCane as faCandyCaneSolid,
  faTvRetro as faTvRetroSolid,
  faRadioAlt as faRadioAltSolid,
  faTimes as faTimesSolid,
  faVirus as faVirusSolid,
  faCheckCircle as faCheckCircleSolid,
  faCircle as faCircleSolid,
  faFlagAlt as faFlagAltSolid,
  faSave as faSaveSolid,
  faCopy as faCopySolid,
  faCalendarStar as faCalendarStarSolid,
  faUsersMedical as faUsersMedicalSolid,
  faHome as faHomeSolid,
  faExclamationCircle as faExclamationCircleSolid,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faGifts as faGiftsDuo,
  faHatSanta as faHatSantaDuo,
  faStar as faStarDuo,
  faPaperPlane as faPaperPlaneDuo,
  faWreath as faWreathDuo,
  faEdit as faEditDuo,
  faBagsShopping as faBagsShoppingDuo,
  faPhoneAlt as faPhoneAltDuo,
  faTreeChristmas as faTreeChristmasDuo,
  faTimesCircle as faTimesCircleDuo,
  faUserHeadset as faUserHeadsetDuo,
  faUser as faUserDuo,
  faChild as faChildDuo,
  faSignInAlt as faSignInAltDuo,
  faCheck as faCheckDuo,
  faFileAlt as faFileAltDuo,
  faVideo as faVideoDuo,
  faCamera as faCameraDuo,
  faImage as faImageDuo,
  faArrowToBottom as faArrowToBottomDuo,
  faMapMarkerAlt as faMapMarkerAltDuo,
  faCalendarDay as faCalendarDayDuo,
  faTicket as faTicketDuo,
  faUsers as faUsersDuo,
  faSortDown as faSortDownDuo,
  faCircle as faCircleDuo,
  faCommentLines as faCommentLinesDuo,
  faPlusCircle as faPlusCircleDuo,
  faUsersClass as faUsersClassDuo,
  faCalendarStar as faCalendarStarDuo,
  faBars as faBarsDuo,
  faGripVertical as faGripVerticalDuo,
  faHome as faHomeDuo,
  faExclamationCircle as faExclamationCircleDuo,
} from '@fortawesome/pro-duotone-svg-icons'

import { faUser as faUserRegular, faEdit as faEditRegular } from '@fortawesome/free-regular-svg-icons'

import {
  faUserPlus as faUserPlusRegular,
  faCheck as faCheckRegular,
  faPen as faPenRegular,
  faTimes as faTimesRegular,
  faEllipsisV as faEllipsisVRegular,
  faEllipsisH as faEllipsisHRegular,
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faRedoAlt as faRedoAltRegular,
  faCommentLines as faCommentLinesRegular,
  faFilter as faFilterRegular,
  faArrowRight as faArrowRightRegular,
  faCircle as faCircleRegular
} from '@fortawesome/pro-regular-svg-icons'

export default function AddIconLibrary() {
  library.add(
    faCameraLight,
    faCameraSolid,
    faStarLight,
    faStarSolid,
    faUserLight,
    faUserSolid,
    faUserRegular,
    faUserPlusRegular,
    faUserPlusSolid,
    faUserPlusLight,
    faCommentAltLines,
    faPlusCircleLight,
    faPlusCircleSolid,
    faBarsLight,
    faBarsSolid,
    faCalendarDayLight,
    faCalendarDaySolid,
    faMapMarkerAltLight,
    faMapMarkerAltSolid,
    faMapLight,
    faMapSolid,
    faImagesLight,
    faImagesSolid,
    faCalendarAlt,
    faUsersClassLight,
    faUsersClassSolid,
    faClockLight,
    faClockSolid,
    faUsersLight,
    faUsersSolid,
    faCityLight,
    faCitySolid,
    faBuildingLight,
    faBuildingSolid,
    faTicketAltLight,
    faTicketAltSolid,
    faGlobeLight,
    faGlobeSolid,
    faListLight,
    faListSolid,
    faSquareLight,
    faSquareSolid,
    faCheckSquareLight,
    faCheckSquareSolid,
    faCheckCircleLight,
    faPlusSquareLight,
    faPlusSquareSolid,
    faEditLight,
    faEditSolid,
    faEditRegular,
    faPaperPlaneLight,
    faPaperPlaneSolid,
    faChevronLeftLight,
    faChevronLeftRegular,
    faChevronLeftSolid,
    faChevronRightLight,
    faChevronRightRegular,
    faChevronRightSolid,
    faChevronDownLight,
    faChevronDownSolid,
    faChevronUpLight,
    faChevronUpSolid,
    faArrowCircleDown,
    faArrowCircleUp,
    faQuestion,
    faEyeSlash,
    faEyeSlashLight,
    faEye,
    faEyeLight,
    faTrashLight,
    faTrashSolid,
    faEllipsisVLight,
    faEllipsisVSolid,
    faEllipsisVRegular,
    faEllipsisHLight,
    faEllipsisHSolid,
    faEllipsisHRegular,
    faInfoCircleLight,
    faInfoCircleSolid,
    faNewspaperLight,
    faNewspaperSolid,
    faTvRetroLight,
    faTvRetroSolid,
    faRadioAltLight,
    faRadioAltSolid,
    faEnvelopeLight,
    faEnvelopeSolid,
    faPhoneAltLight,
    faPhoneAltSolid,
    faUserHeadset,
    faCogLight,
    faCogSolid,
    faUsersCogLight,
    faUsersCogSolid,
    faPaperclip,
    faUserCheckLight,
    faUserCheckSolid,
    faCheckLight,
    faCheckSolid,
    faCheckRegular,
    faLockOpenAltLight,
    faCircleLight,
    faCircleSolid,
    faTimesCircleLight,
    faTimesCircleSolid,
    faTimesCircleDuo,
    faUndoLight,
    faUndoSolid,
    faRedoLight,
    faRedoSolid,
    faCompass,
    faCommentLinesLight,
    faCommentLinesSolid,
    faCommentLinesRegular,
    faCommentsLight,
    faCommentsSolid,
    faCookieBiteLight,
    faCookieBiteSolid,
    faBookOpenLight,
    faBookOpenSolid,
    faHandshakeAltLight,
    faHandshakeAltSolid,
    faSuitcaseLight,
    faSuitcaseSolid,
    faClipboardCheckLight,
    faClipboardCheckSolid,
    faSignOut,
    faGlobeEuropeLight,
    faGlobeEuropeSolid,
    faUserShieldLight,
    faUserShieldSolid,
    faFileCheckLight,
    faFileCheckSolid,
    faAwardLight,
    faAwardSolid,
    faTimesLight,
    faTimesSolid,
    faTimesRegular,
    faListUl,
    faMap,
    faVideo,
    faHatSantaLight,
    faHatSantaSolid,
    faPlusLight,
    faMinusLight,
    faArrowsAltHLight,
    faArrowAltHSolid,
    faHomeLgAltLight,
    faHomeLgAltSolid,
    faWreathLight,
    faWreathSolid,
    faCandyCaneSolid,
    faCandyCaneLight,
    faGiftsDuo,
    faHatSantaDuo,
    faStarDuo,
    faPaperPlaneDuo,
    faWreathDuo,
    faEditDuo,
    faBagsShoppingDuo,
    faPhoneAltDuo,
    faTreeChristmasDuo,
    faBellLight,
    faBellSolid,
    faBellSlashSolid,
    faTrashAltLight,
    faGlassCheers,
    faGlassCheersSolid,
    faQuestionCircleLight,
    faQuestionCircleSolid,
    faTreeChristmasDuo,
    faPenLight,
    faPenSolid,
    faPenRegular,
    faVirusLight,
    faVirusSolid,
    faThumbtackLight,
    faThumbtackSolid,
    faCheckCircleSolid,
    faCircleSolid,
    faRedoAltLight,
    faRedoAltRegular,
    faRedoAltSolid,
    faGripVerticalLight,
    faGripVerticalSolid,
    faGripVerticalDuo,
    faCommentDotsLight,
    faFlagAltSolid,
    faFlagAltLight,
    faHistoryLight,
    faHistorySolid,
    faArrowToBottomLight,
    faLinkLight,
    faSyncLight,
    faFilterLight,
    faFilterSolid,
    faFilterRegular,
    faGraduationCapLight,
    faGraduationCapSolid,
    faSaveLight,
    faSaveSolid,
    faBookLight,
    faUserHeadsetDuo,
    faUserDuo,
    faChildDuo,
    faBookLight,
    faArrowRightRegular,
    faSignInAltLight,
    faSignInAltDuo,
    faShieldLight,
    faCopyLight,
    faCopySolid,
    faCheckDuo,
    faFileAltDuo,
    faVideoDuo,
    faCameraDuo,
    faImageDuo,
    faArrowLeftSolid,
    faArrowToBottomDuo,
    faCalendarStarLight,
    faCalendarStarSolid,
    faMapMarkerAltDuo,
    faCalendarDayDuo,
    faSearchLight,
    faTicketDuo,
    faUsersDuo,
    faSortDownDuo,
    faCircleDuo,
    faCircleRegular,
    faCommentLinesDuo,
    faPlusCircleDuo,
    faUsersClassDuo,
    faCalendarStarDuo,
    faBarsDuo,
    faUsersMedicalLight,
    faUsersMedicalSolid,
    faExclamationTriangleLight,
    faExclamationTriangleSolid,
    faHomeLight,
    faHomeSolid,
    faHomeDuo,
    faExclamationCircleLight,
    faExclamationCircleSolid,
    faExclamationCircleDuo,
  )
}
