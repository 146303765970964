import React from 'react'
import Colors from '../../constants/Colors'
import { StyleSheet } from 'react-native'
import { TypographyStyles } from '../../styles'
import { txt } from '../../locales/i18n'
import CustomPressable from "../pressables/CustomPressable"
import { CustomText } from "../text/StyledText"
import NormalIcon, { BoblbergIcon } from "../icons/NormalIcon"
import Fontsizes from "../../constants/Fontsizes"

export function CustomChip({ selected, onPress, accessibilityLabel, text, icon, ...props}) {
  return (
    <CustomPressable
      {...props}
      onPress={onPress}
      // style={[styles.chip, selected ? styles.chipSelected : styles.chipUnselected]}
      style={[
        styles.chip,
        props.backgroundColor && !selected ? { backgroundColor: props.backgroundColor }
          : selected ? styles.chipSelected : styles.chipUnselected
      ]}
      accessibilityLabel={selected ? txt('chip.selected') + accessibilityLabel : accessibilityLabel}
      // mode="flat"
      // selectedColor={props.selected && props.textColor ? props.textColor : props.selected && !props.textColor ? Colors.text : null}
      // accessibilityLabel={
      //   props.selected
      //     ? txt('chip.selected') + props.accessibilityLabel
      //     : props.accessibilityLabel
      // }
      // textStyle={[styles.text, props.textColor && {color: props.textColor}]}
    >
      {icon === "bobl" ? <BoblIcon/> : (
        <NormalIcon
          name={icon ?? "users"}
          size={props.iconSize ?? Fontsizes.s}
          color={props.iconColor ?? Colors.inactive}
          stroke={props.iconStroke ?? "fas"}
        />
      )}
      {text && <CustomText style={styles.text}>{text}</CustomText>}
    </CustomPressable>
  )
}

const BoblIcon = () => (
  <BoblbergIcon
    accessibilityLabel={txt('fullPost.usernameIcon')}
    name="bobl"
    size={Fontsizes.s}
    color={Colors.inactive}
    stroke="fas"
  />
)

const styles = StyleSheet.create({
  chip: {
    justifyContent: 'center',
    borderWidth: 0,
    padding: 8,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  chipSelected: {
    backgroundColor: Colors.chipSelected
  },
  chipUnselected: {
    backgroundColor: Colors.chipUnselected
  },
  text: {
    ...TypographyStyles.textSmall,
    color: Colors.text,
    paddingLeft: 6
  },
  // chip: {
  //   justifyContent: 'center',
  //   borderWidth: 0
  // },
  // chipSelected: {
  //   backgroundColor: Colors.chipSelected,
  //   ...ShadowStyles.shadowInputs
  // },
  // chipUnselected: {
  //   backgroundColor: Colors.chipUnselected
  // },
  // text: {
  //   ...TypographyStyles.textSmall,
  //   color: Colors.text
  // },
})
