import React from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import CustomDialog, { ContentContainer } from '../../../components/dialogs/CustomDialog'
import { CustomText } from '../../../components/text/StyledText'
import { selectChristmasPostsFromConversations } from '../../../redux/selectors'
import ImgHighlightChristmas from '../ImgHighlightChristmas'
import { txt } from '../../../locales/i18n'
import CustomPressable from "../../../components/pressables/CustomPressable"

export default function MatchChooseDialog({ visible, confirm, dismiss }) {
  const posts = useSelector(selectChristmasPostsFromConversations)

  function renderPostItem({ item }) {
    return (
      <CustomPressable style={styles.itemContainer} onPress={() => confirm(item.id)}>
        <ImgHighlightChristmas imgFilename={item.imgFilename} type={item.type} size='small'/>
        <CustomText font='small' style={styles.itemText}>{item.title}</CustomText>
      </CustomPressable>
    )
  }

  return (
    <CustomDialog visible={visible} onDismiss={dismiss}>
      <ContentContainer>
        <CustomText style={styles.title}>
          {txt('christmasFriends.match.choose.title')}
        </CustomText>
        <FlatList data={posts} renderItem={renderPostItem}/>
      </ContentContainer>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    marginBottom: 12,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
  },
  itemText: {
    marginLeft: 12,
  }
})