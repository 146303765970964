import Colors from '../constants/Colors'
import React from 'react'
import Fontsizes from '../constants/Fontsizes'
import { txt } from '../locales/i18n'
import NormalIcon, { BoblbergIcon } from '../components/icons/NormalIcon'
import { Dimensions, TouchableOpacity } from 'react-native'
import * as RootNavigation from './RootNavigation'
import { CustomText } from '../components/text/StyledText'
import store from '../redux/store'
import { getExtraTabs } from './MenuConfig'

export function RightElement(props) {
	const { width } = Dimensions.get('window')
	const country = store.getState()?.user?.country ?? null

	const now = new Date()
	const currentYear = new Date().getFullYear()
	const nextYear = new Date().getFullYear() + 1
	const firstOfNovember = new Date(currentYear, 10, 1)
	const firstOfDecember = new Date(currentYear, 11, 1)
	const afterChristmas = new Date(currentYear, 11, 25)
	const afterNewYear = new Date(nextYear, 0, 1, 6)
	const renderChristmas = country === 'Denmark' && (now > firstOfNovember && now < afterChristmas) && props.type !== 'Boblberg'
	const renderNewYear = country === 'Denmark' && (now > afterChristmas && now < afterNewYear) && props.type !== 'Boblberg'
	const renderNewYearSe = country === 'Sweden' && (now > firstOfDecember && now < afterNewYear) && props.type !== 'Boblberg'

	if (country === 'Germany') return null
	if (country !== 'Denmark' && renderChristmas) return null

	const buttonText = () => {
		if (renderChristmas) return txt('christmasFriends.title')
		if (renderNewYear) return txt('stackMenu.nytaarsvenner')
		if (renderNewYearSe) return txt('stackMenu.nytaarsvenner')
		if (props.type === 'Boblberg') return 'Boblberg'
		return null
	}

	const buttonIcon = () => {
		if (renderChristmas) {
			return (
				<NormalIcon
					name="hat-santa"
					color={Colors.redChristmas}
					size={Fontsizes.xl}
					stroke="fas"
				/>
			)
		}
		if (renderNewYear) {
			return (
				<NormalIcon
					name="glass-cheers"
					color={Colors.blue}
					size={Fontsizes.xl}
					stroke="fal"
				/>
			)
		}
		if (renderNewYearSe) {
			return (
			<NormalIcon
				name="glass-cheers"
				color={Colors.blue}
				size={Fontsizes.xl}
				stroke="fal"
			/>
			)
		}
		if (props.type === 'Boblberg') {
			return (
				<BoblbergIcon name="bobl" color={Colors.blue} size={Fontsizes.xl} />
			)
		}
		return null
	}

	if (renderChristmas || renderNewYear || renderNewYearSe || props.type === 'Boblberg') return (
		<TouchableOpacity
			onPress={() => {
				if (renderChristmas) return RootNavigation.navigate('christmasFriends')
				if (renderNewYear) return RootNavigation.navigate('universe', { universe: 'nytaarsvenner' })
				if (renderNewYearSe) return RootNavigation.navigate('universe', { universe: 'nyarsvanner' })
				return RootNavigation.navigate('app')
			}}
			style={{ padding: 12, flexDirection: 'row', alignItems: 'center' }}
			accessibilityRole='button'
		>
			{buttonIcon()}

			{width > 800 && (
				<CustomText style={{ marginLeft: 6, color: Colors.blue }}>
					{buttonText()}
				</CustomText>
			)}
		</TouchableOpacity>
	)
}

export const stackConfig = ({ universeConfig, rightElement }) => {
  return {
    headerStyle: {
      backgroundColor: universeConfig?.backgroundColor || Colors.background,
      borderBottomColor: 'transparent',
      elevation: 0, // remove shadow on Android
      shadowOpacity: 0,
    },
    headerTitleStyle: {
      color: Colors.text,
      fontFamily: 'raleway-bold',
      fontSize: Fontsizes.l,
    },
    headerTitleAlign: 'center',
    headerBackImage: () => (
      <NormalIcon
        name="chevron-left"
        color={Colors.blue}
        size={22}
        style={{ paddingHorizontal: 6 }}
        stroke="far"
      />
    ),
    headerRight: () => {
      // Dont show rightElement in 'nytaarsvenner' universe
      if (universeConfig?.name !== 'nytaarsvenner' && universeConfig?.name !== 'nyarsvanner') return <RightElement type={rightElement} />
    },
    headerBackTitleVisible: true,
    headerTruncatedBackTitle: txt('mainTabNavigator.backTitle'),
    headerBackAccessibilityLabel: txt('mainTabNavigator.backTitle'),
    headerBackTitleStyle: {
      color: Colors.tintColor,
      fontFamily: 'raleway-regular',
    },
    animationEnabled: true,
  }
}

const sharedScreens = {
  About: 'om-boblberg',
  Contact: 'kontakt',
  Stories: 'historier',
  Story: 'historier/:id',
  Conditions: 'betingelser',
  BlogsMenu: 'blogs',
  Blogs: 'blogs/:id',
  ProjectsMenu: 'projekter',
  Projects: 'projekter/:id',
  MunicipalityInfo: 'kommuneinfo',
  Press: 'presse',
  PartnersMenu: 'partnere',
  Partners: 'partnere/:id',
  AboutUs: 'om-os',
  Arrangements: 'arrangementer',
  Arrangement: 'arrangementer/:id',
  Faellesskabsprisen: 'faellesskabsprisen',
  LearnDanish: 'sprogland',
  SnakSammen: 'snaksammen',
  NeighborIntro: 'nabobobler',
  ChangePassword: 'nyt-password/:token',
}

const getAppScreens = () => {
  const features = store.getState()?.user?.features
  const joinedGroups = store.getState()?.groups
  const extraTabs = getExtraTabs(features ?? [], joinedGroups?.length ?? 0)

  const screens = {
    HomeStack: {
      initialRouteName: 'Home',
      screens: {
        Home: 'bobler/:chip?',
        Post: 'bobl/:id',
        PostWithTitle: 'bobl/:id/:postTitle',
        EditPost: 'mine-bobler/rediger/:id',
      },
    },
    InboxStack: {
      initialRouteName: 'Inbox',
      screens: {
        Inbox: 'beskeder',
        Conversation: 'beskeder/:id',
        ConversationInfo: 'beskeder/:id/info',
        ProfileView: 'beskeder/profil/:id',
      },
    },
    CreatePostStack: {
      screens: {
        CreatePost: 'opret-bobl',
      },
    },
    GroupsStack: {
      screens: {},
    },
    ActivityStack: {
      initialRouteName: 'Activities',
      screens: {},
    },
    UnionStack: {
      initialRouteName: 'Unions',
      screens: {},
    },

    EventStack: {
      initialRouteName: 'Events',
      screens: {},
    },

    MenuStack: {
      initialRouteName: 'Menu',
      screens: {
        Menu: 'menu',
        Profile: 'profil',
        ProfileView: 'se-profil',
        SnakSammenOversigt: 'snaksammen-oversigt',
        VolunteerSignup: 'snaksammen-bliv-frivillig',
        SproglandOversigt: 'sprogland-oversigt',
        SproglandJunior: 'sprogland/junior',
        SproglandVolunteer: 'sprogland/frivillig/:chip',
        Conversation: 'sprogland/frivillig/beskeder/:id',
        ConversationInfo: 'sprogland/frivillig/beskeder/:id/info',
        SnakSammenVolunteer: 'snaksammen/frivillig/:chip',
        Settings: 'indstillinger',
        Notifications: 'notifikationer',
        WhatIsBoblberg: 'hvad-er-boblberg',
        ...sharedScreens,
      },
    },
  }
  const activityScreens = {
    Activities: 'aktiviteter/:chip?',
    Activity: 'aktivitet/:id',
    EditActivity: 'aktivitet/rediger/:id',
  }
  const unionScreens = {
    Unions: 'foreninger/:chip?',
    Union: 'forening/:id',
    EditUnion: 'forening/rediger/:id',
  }
  const groupScreens = {
    Groups: 'grupper/:chip?',
    Group: 'grupper/:id',
  }

  const eventScreens = {
    Events: 'oplev',
    Event: 'oplev/:eventId',
  }

  if (!features || !joinedGroups) return screens

  if (extraTabs.includes('groups')) {
    screens.GroupsStack.screens = groupScreens
  }

  if (extraTabs.includes('activities')) {
    screens.ActivityStack.screens = activityScreens
  }

  if (extraTabs.includes('events')) {
    screens.EventStack.screens = eventScreens
  }

  if (extraTabs.includes('unions')) {
    screens.UnionStack.screens = unionScreens
  }

  if (extraTabs.includes('groups') === false) {
    screens.MenuStack.screens = { ...screens.MenuStack.screens, ...groupScreens }
  }

  if (extraTabs.includes('activities') === false) {
    screens.MenuStack.screens = { ...screens.MenuStack.screens, ...activityScreens }
  }

  if (extraTabs.includes('events') === false) {
    screens.MenuStack.screens = { ...screens.MenuStack.screens, ...eventScreens }
  }

  if (extraTabs.includes('unions') === false) {
    screens.MenuStack.screens = { ...screens.MenuStack.screens, ...unionScreens }
  }

  return screens
}

const universeScreens = {
  IntroUniverse: 'u/:universe',
  UniverseBoblStack: {
    initialRouteName: 'Bobls',
    screens: {
      Bobls: 'u/:universe/bobler/:chip?',
      Post: 'u/:universe/bobl/:id',
      EditPost: 'u/:universe/mine-bobler/rediger/:id',
      HerlevOffers: 'u/:universe/tilbud',
    },
  },
  UniverseConversationStack: {
    initialRouteName: 'Inbox',
    screens: {
      Inbox: 'u/:universe/beskeder',
      Conversation: 'u/:universe/beskeder/:id',
      ConversationInfo: 'u/:universe/beskeder/:id/info',
      ProfileView: 'u/:universe/beskeder/profil/:id',
    },
  },
  UniverseCreatePostStack: {
    screens: {
      UniverseCreatePost: 'u/:universe/opret-bobl',
    },
  },
	UniverseGroupsStack: {
		initialRouteName: 'GroupsUniverse',
		screens: {
			Groups: 'u/:universe/grupper/:chip?',
			Group: 'u/:universe/grupper/:id',
		}
	},
  UniverseActivityStack: {
    initialRouteName: 'ActivitiesUniverse',
    screens: {
      Activities: 'u/:universe/aktiviteter/:chip?',
      Activity: 'u/:universe/aktivitet/:id',
      EditActivity: 'u/:universe/aktiviteter/rediger/:id',
    },
  },
	UniverseEventStack: {
		initialRouteName: 'EventsUniverse',
		screens: {
			Events: 'u/:universe/oplev',
			Event: 'u/:universe/oplev/:eventId',
		},
	},
  UniverseUnionStack: {
    initialRouteName: 'UnionsUniverse',
    screens: {
      Unions: 'u/:universe/foreninger/:chip?',
      Union: 'u/:universe/forening/:id',
      EditUnion: 'u/:universe/foreninger/rediger/:id',
    },
  },
	UniverseOfferStack: {
		screens: {
			KrifaOffers: 'u/:universe/krifa-tilbud',
		},
	},
  UniverseMenuStack: {
    initialRouteName: 'Menu',
    screens: {
      Menu: 'u/:universe/menu',
      Profile: 'u/:universe/profil',
      ProfileView: 'u/:universe/se-profil',
      Settings: 'u/:universe/indstillinger',
      Notifications: 'u/:universe/notifikationer',
      Groups: 'u/:universe/menu/grupper/:chip?',
      Group: 'u/:universe/menu/grupper/:id',
      HerlevOffers: 'u/:universe/tilbud-til-dig',
      CovidScreenNewYear: 'u/:universe/covid19',
			Stories: 'u/:universe/historier',
			Story: 'u/:universe/historier/:id',
			Events: 'u/:universe/u/oplev',
			Event: 'u/:universe/u/oplev/:eventId',
    },
  },
}

const christmasFriendsScreens = {
  ChristmasIntro: 'julevenner',
  ChristmasTypeSelection: 'julevenner/type',
  ChristmasCreate: 'julevenner/opret',
  PostFeedStack: {
    initialRouteName: 'ChristmasFeed',
    screens: {
      ChristmasFeed: 'julevenner/bobler',
      ChristmasPost: 'julevenner/bobler/:id',
    },
  },
  MyPostStack: {
    initialRouteName: 'MyPostsChristmas',
    screens: {
      MyPostsChristmas: 'julevenner/mine',
      ChristmasPost: 'julevenner/mine/:id',
      ChristmasPostEdit: 'julevenner/mine/:id/rediger',
    },
  },
  ChristmasMenuStack: {
    initialRouteName: 'Menu',
    screens: {
      Menu: 'julevenner/menu',
      ChristmasFAQ: 'julevenner/faq',
      AfterSignupChristmas: 'julevenner/efter-opret',
      AfterMatchChristmas: 'julevenner/efter-match',
      Contact: 'julevenner/kontakt',
      Profile: 'julevenner/profil',
      ProfileView: 'julevenner/se-profil',
      Settings: 'julevenner/indstillinger',
      Groups: 'julevenner/grupper/:chip?',
      Group: 'julevenner/grupper/:id',
    },
  },
  ChristmasConversationStack: {
    initialRouteName: 'Inbox',
    screens: {
      Inbox: 'julevenner/beskeder',
      Conversation: 'julevenner/beskeder/:id',
      ConversationInfo: 'julevenner/beskeder/:id/info',
      ProfileView: 'julevenner/beskeder/profil/:id',
    },
  },
}

const studieboblerScreens = {
  StudieboblerSelection: 'studiebobler',
}

export const getAppConfig = () => {
  return {
    screens: {
      app: { screens: getAppScreens() },
      universe: { screens: universeScreens },
      christmasFriends: { screens: christmasFriendsScreens },
      studiebobler: { screens: studieboblerScreens },
    },
  }
}

export const authConfig = {
  initialRouteName: 'Frontpage',
  screens: {
    Frontpage: '',
    Login: 'login',
    ResetPassword: 'reset-password',
    Menu: 'menu',
    SnakSammenSignup: 'snaksammen-opret',
    LearnDanishSignup: 'sprogland-opret',
    Groups: 'grupper',
    GroupsSignup: 'grupper/opret',
    Activities: 'aktiviteter/:chip?',
    Activity: 'aktivitet/:id',
    ActivitiesSignup: 'aktiviteter-opret',
    Events: 'oplev',
    IntroUniverse: 'u/:universe',
    SignupUniverse: 'u/:universe/opret',
    SignupChristmas: 'julevenner/opret',
    Post: 'bobl/:id',
    NeighborIntro: 'nabobobler',
    Unions: 'foreninger',
    Union: 'forening/:id',
    ChristmasIntro: 'julevenner',
    ChristmasLoginScreen: 'julevenner/login',
    ...sharedScreens,
  },
}
