import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { useNavigation } from '@react-navigation/core'

import { getConversationId } from '../../../services/api/Inbox'

import { CustomText } from '../../../components/text/StyledText'
import { ButtonPrimary } from '../../../components/buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import { trackPlausibleEvent } from '../../../services/api/plausible'

export default function BroenHerlev(props) {
	const navigation = useNavigation()

	const handleContactBroen = async () => {
		trackPlausibleEvent('HerlevOfferClick', { origin: 'Broen-btn' }, 'herlev/HerlevOffers')
		const receiverId = 407669
		const conversationId = await getConversationId({ receiverId })
		if (conversationId) {
			navigation.navigate('UniverseConversationStack', {
				screen: 'Conversation',
				initial: false,
				params: {
					id: conversationId,
					universe: 'herlev',
				},
			})
		} else {
			navigation.navigate('UniverseConversationStack', {
				screen: 'Conversation',
				initial: false,
				params: {
					id: 'new',
					receiverId,
					universe: 'herlev',
				},
			})
		}
	}

	return (
		<View style={styles.partnerInfoContainer}>
			<Image
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/broen-herlev.png' }}
				resizeMode="contain"
				style={[styles.image, styles.partnerInfoHeader]}
				accessibilityIgnoresInvertColors
				accessible
				accessibilityRole="image"
				accessibilityLabel={txt('universe.herlev.broenHerlev')}
			/>
			<CustomText>{txt('universe.herlev.partnerInfo.broenHerlev.body')}</CustomText>
			<Image
				accessibilityIgnoresInvertColors
				source={{ uri: 'https://filer.boblberg.dk/assets/img/universe/broen-herlev-lars.jpg' }}
				style={{ height: 125, width: 125, borderRadius: 65, alignSelf: 'center', marginTop: 12 }}
			/>
			<ButtonPrimary
				title={txt('universe.herlev.partnerInfo.broenHerlev.contact')}
				onPress={() => {
					props.setModalVisible(false)
					handleContactBroen()
				}}
				style={{ marginTop: 12 }}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	image: {
		alignSelf: 'center',
		height: 60,
		marginVertical: 6,
		maxWidth: 300,
		minWidth: 200,
	},
	partnerInfoContainer: {
		alignSelf: 'center',
		flex: 1,
		maxWidth: 600,
		width: '100%',
	},
	partnerInfoHeader: {
		marginBottom: 24,
	},
})
