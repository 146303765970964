import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './styles.css'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../constants/Colors'
import { CornerStyles, ShadowStyles } from '../../../styles'
import ua from 'react-phone-number-input/locale/ua'
import fi from 'react-phone-number-input/locale/fi'
import fr from 'react-phone-number-input/locale/fr'
import sv from 'react-phone-number-input/locale/sv'
import da from './daCountries.json'
import { useSelector } from 'react-redux'

const langToLabels = {
  'da': da,
  'uk': ua,
  'fr': fr,
  'fi': fi,
  'se': sv,
}

export const PhoneNumberInput = forwardRef(({phoneNumber, setPhoneNumber, callCode, setCallCode, style, defaultCountryCode = 'DK', ...props}, ref) => {
  const [value, setValue] = useState(`+${callCode||45}${phoneNumber}`)
  const language = useSelector(state => state.user.language)
  const [labels, setLabels] = useState(undefined)

  useEffect(() => {
    const l = langToLabels[language] || undefined
    setLabels(l)
  }, [language])

  useEffect(() => {
    if (callCode === null) {
      setCallCode(45)
    }
  }, [])

  function isValid() {
    return isValidPhoneNumber(value) || phoneNumber.length === 0
  }

  useImperativeHandle(ref, () => ({ isValid }))

  function handleChange(val) {
    if (!val) {
      setPhoneNumber('')
      setCallCode('')
      return
    }
    const passedNumber = parsePhoneNumber(val)
    setValue(val)
    if (passedNumber) {
      setPhoneNumber(passedNumber.nationalNumber)
      setCallCode(passedNumber.countryCallingCode)
    }
  }

  return (
    <View style={[styles.container, style]}>
      <PhoneInput
        defaultCountry={defaultCountryCode}
        value={value}
        onChange={handleChange}
        labels={labels}
        {...props}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
    minHeight: 40,
    ...CornerStyles.borderRadiusS,
    ...ShadowStyles.shadowInputs,
  }
})
