import React from 'react'
import { View, Linking, StyleSheet, Image, Dimensions } from 'react-native'
import TxtProjects from '../../../../constants/TxtProjects'
import Colors from '../../../../constants/Colors'
import { ImageHighlight } from '../../../images/ImageHighlight'
import Fontsizes from '../../../../constants/Fontsizes'
import NormalIcon from '../../../icons/NormalIcon'
import { CustomText } from '../../../text/StyledText'
import { txt } from '../../../../locales/i18n'
import { URLOpener } from '../../../../utils/URLOpener'
import PageTitle from '../../../tags/PageTitle'
import Layout from '../../../../constants/Layout'
import { ProjectStyles } from './ProjectStyles'
import CustomPressable from "../../../pressables/CustomPressable"

const { width } = Dimensions.get('window')

export default class OPILDL extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={ProjectStyles.widthHeight}>
        <PageTitle title={TxtProjects.opildl.title[TxtProjects.lang]} />

        <View style={ProjectStyles.container}>
          <CustomText
            style={ProjectStyles.blogTitle}
            accessibilityRole="header"
            aria-level={1}
          >
            {TxtProjects.opifakto.title[TxtProjects.lang]}
          </CustomText>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.imageEU}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/eu_logo_OPI_FAKTO.png',
            }}
          />
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opifakto.title1[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opifakto.text1[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opifakto.title2[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opifakto.text2[TxtProjects.lang]}
          </CustomText>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.image}
            source={{
              uri: 'https://filer.boblberg.dk/Assets/Img/projects/IMG_OPI_Fakto_Phone.png',
            }}
          />
          <CustomText style={ProjectStyles.imageText}>
            {TxtProjects.opifakto.imagetext}
          </CustomText>

          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opifakto.projectPeriod[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opifakto.period[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opifakto.partnerInfo[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opifakto.partners[TxtProjects.lang]}
          </CustomText>

          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opifakto.supportTitle[TxtProjects.lang]}
          </CustomText>
          <CustomText style={ProjectStyles.blogText}>
            {TxtProjects.opifakto.support[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opifakto.referencesTitle[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.link}
            onPress={() =>
              URLOpener(
                'http://www.abcmentalsundhed.dk/media/1144/abc-brochure-om-at-vaere-aktiv.pdf'
              )
            }
          >
            {TxtProjects.opifakto.references[TxtProjects.lang]}
          </CustomText>
          <CustomText
            style={ProjectStyles.blogHeader}
            accessibilityRole="header"
            aria-level={2}
          >
            {TxtProjects.opildl.projectResponsible[TxtProjects.lang]}
          </CustomText>
          {/* Anders */}
          <View style={ProjectStyles.employeeContainer}>
            <View>
              <ImageHighlight
                source={{
                  uri: 'https://filer.boblberg.dk/Assets/Img/employees/Anders.jpg',
                }}
              />
            </View>
            <View style={ProjectStyles.employeeText}>
              <CustomText font="bold">{txt('aboutUs.andersName')}</CustomText>

              <CustomPressable onPress={() => Linking.openURL('mailto:as@boblberg.dk')}>
                <View style={ProjectStyles.containerRow}>
                  <NormalIcon
                    accessibilityLabel={txt('aboutUs.emailIcon')}
                    name="envelope"
                    size={Fontsizes.l}
                    color={Colors.text}
                  />
                  <CustomText>{txt('aboutUs.andersMail')}</CustomText>
                </View>
              </CustomPressable>

              <CustomPressable onPress={() => Linking.openURL(`tel:${+4522250520}`)}>
                <View style={ProjectStyles.containerRow}>
                  <NormalIcon
                    accessibilityLabel={txt('aboutUs.phoneIcon')}
                    name="phone-alt"
                    size={Fontsizes.l}
                    color={Colors.text}
                  />
                  <CustomText>{txt('aboutUs.andersPhone')}</CustomText>
                </View>
              </CustomPressable>
            </View>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  image: {
    height: Layout.isSmallDevice ? width * 1.65 : 750,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.9 : 400,
  },
  imageEU: {
    height: Layout.isSmallDevice ? width * 0.25 : 85,
    marginBottom: 8,
    width: Layout.isSmallDevice ? width * 0.75 : 250,
  },
})
