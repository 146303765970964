import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import { txt } from '../../locales/i18n'
import { CustomText } from '../../components/text/StyledText'
import SignupField from '../../components/views/Auth/SignupField'
import KeyboardSpacer from 'react-native-keyboard-spacer'
import { TypographyStyles, SpacingStyles } from '../../styles'
import MainContainer from '../../components/MainContainer/MainContainer'

export default class GroupsSignupScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0,
      activeIndexMunicipalities: 0,
    }
  }

  keyboardSpacerShow = () => {
    if (Platform.OS === 'ios') {
      return <KeyboardSpacer />
    }
  }

  render() {
    return (
      <MainContainer>
        <View style={styles.widthHeight}>
          <View style={styles.textContainer}>
            <CustomText style={styles.textBold}>
              {txt('groups.signup.title')}
            </CustomText>
            <CustomText style={styles.text}>
              {txt('groups.signup.subtitle')}
            </CustomText>
          </View>

          <SignupField
            navigation={this.props.navigation}
            originScreen={'Groups'}
            originStack={'MenuStack'}
            originRootStack={'app'}
            route={this.props.route}
          />
        </View>
        {this.keyboardSpacerShow()}
      </MainContainer>
    )
  }
}

const styles = StyleSheet.create({
  text: { ...TypographyStyles.text, marginBottom: 15, textAlign: 'center' },
  textBold: { ...TypographyStyles.textBold, textAlign: 'center' },
  textContainer: { marginLeft: 10, marginRight: 10 },
  widthHeight: { flex: 1, ...SpacingStyles.widthAndHeight },
})
