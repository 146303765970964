import React, { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import * as AuthSession from 'expo-auth-session'
import * as Linking from 'expo-linking'

import { CustomText } from '../../text/StyledText'
import CustomPressable from '../../pressables/CustomPressable'

import NormalIcon from '../../icons/NormalIcon'

import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import { txt } from '../../../locales/i18n'

import * as Localization from '../../../services/localization'
import { storeAccessToken } from '../../../services/authActions'
import { setUserToken } from '../../../redux/actions'
import store from '../../../redux/store'
import Fontsizes from '../../../constants/Fontsizes'

export const PasswordChanger = () => {
	// idle | pending | success | error
	const [authState, setAuthState] = useState('idle')

	useEffect(() => {
		const urlSubscriber = Linking.addEventListener('url', authLinkHandler)
		return () => urlSubscriber.remove()
	}, [])

	useEffect(() => {
		if (authState !== 'success') return

		const successTimeout = setTimeout(() => setAuthState('idle'), 4000)
		return () => clearTimeout(successTimeout)
	}, [authState])

	const authLinkHandler = async ({ url }) => {
		const { queryParams = null } = Linking.parse(url)
		if (queryParams === null) return

		const { event = null, status = null, token = null } = queryParams
		if (event !== 'password' || status === null) return

		if (status === 'cancel') return setAuthState('idle')
		if (status === 'success' && token) return updateToken(token)

		return setAuthState('error')
	}

	const passwordNativeHandler = async () => {
		setAuthState('pending')

		const queries = []
		const url = Paths.getBaseUrl() + 'auth/update'

		const appDeeplink = AuthSession.makeRedirectUri()
		queries.push('appDeeplink=' + encodeURIComponent(appDeeplink))

		const lang = await Localization.getLanguage()
		if (lang) queries.push('lang=' + lang)

		await Linking.openURL(url + '?' + queries.join('&'))
	}

	const updateToken = async (token) => {
		setAuthState('success')
		store.dispatch(setUserToken(token))
		storeAccessToken(token)
	}

	return (
		<>
			<CustomPressable onPress={passwordNativeHandler} style={styles.container}>
				<CustomText style={styles.buttonText}>{txt('profile.changePassword')}</CustomText>
				{authState === 'pending' && <ActivityIndicator color={Colors.blueAzure} />}
				{authState === 'success' && <NormalIcon name="check" size={18} color={Colors.greenBtn} />}
			</CustomPressable>

			{authState === 'error' && <CustomText style={styles.errorText}>{txt('changePassword.error')}</CustomText>}
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
	},

	buttonText: {
		flex: 1,
	},

	errorText: {
		color: Colors.errorBackground,
		fontSize: Fontsizes.s,
	},
})
