import { Features } from '../constants/Features'
import Layout from '../constants/Layout'

const minWidth = 375
const extraTabCount = Layout.window.width >= minWidth ? 2 : 1

export const getExtraTabs = (features = [], joinedGroupCount = 0) => {
	const extraTabs = []

	if (joinedGroupCount !== 0) extraTabs.push('groups')

	if (extraTabCount > extraTabs.length && features.includes(Features.SEARCH_ACTIVITIES)) {
		extraTabs.push('activities')
	}

	if (extraTabCount > extraTabs.length) extraTabs.push('events')
	if (extraTabCount > extraTabs.length) extraTabs.push('unions')

	return extraTabs
}