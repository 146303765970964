import React from 'react'
import { View, StyleSheet } from 'react-native'
import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import { ImageHighlightMedium } from '../../images/ImageHighlight'
import TxtTMD from '../../../constants/TxtTMD'
import PageTitle from '../../tags/PageTitle'
import { SpacingStyles } from '../../../styles'
import MainContainer from '../../MainContainer/MainContainer'
import CustomPressable from "../../pressables/CustomPressable"

const images = [
  {
    name: 'Biblioteker',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/bibliotek.jpg',
  },
  {
    name: 'Byfester',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/byfester.jpg',
  },
  {
    name: 'Festivaler',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/festival.jpg',
  },
  {
    name: 'Messer',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/messer.jpg',
  },
  {
    name: 'Motion',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/motion.jpg',
  },
  {
    name: 'Sundhedscentre',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/sundhedscentre.jpg',
  },
  {
    name: 'Gymnasier',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/gymnasium.jpg',
  },
  {
    name: 'Efterskoler',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/efterskole.jpg',
  },
  {
    name: 'Højskoler',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/hoejskole.jpg',
  },
  {
    name: 'Sprogskoler',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/sprogskole.jpg',
  },
  {
    name: 'Storcentre',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/storcentre.jpg',
  },
  {
    name: 'Tilflyttere',
    uri: 'https://filer.boblberg.dk/Assets/Img/events/tilflyttere.jpg',
  },
]

class Panel extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View>
        <PageTitle title={TxtTMD.arrangements.title[TxtTMD.lang]} />

        <CustomPressable
          style={styles.padding5}
          onPress={() => this.props.pressF(this.props.name)}
        >
          <CustomText style={styles.center}>{this.props.name}</CustomText>
          <View style={styles.margin10}>
            <ImageHighlightMedium source={{ uri: this.props.uri }} />
          </View>
        </CustomPressable>
      </View>
    )
  }
}

export default class Arrangements extends React.Component {
  constructor(props) {
    super(props)
  }

  navigateToPost = (subject) => {
    this.props.navigation.push('Arrangement', {
      id: subject,
    })
  }

  render() {
    return (
      <MainContainer>
        <View style={styles.widthHeight}>
          <View style={styles.textContainer}>
            <CustomText>{txt('arrangements.startText')}</CustomText>
          </View>
          <View style={styles.eventContainer}>
            {images.map((image, i) => {
              return (
                <Panel
                  uri={images[i].uri}
                  pressF={this.navigateToPost}
                  key={i}
                  name={image.name}
                />
              )
            })}
          </View>
        </View>
      </MainContainer>
    )
  }
}

const styles = StyleSheet.create({
  center: { textAlign: 'center' },
  eventContainer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  margin10: { margin: 10 },
  padding5: { padding: 5 },
  textContainer: { alignItems: 'center', margin: 20 },
  widthHeight: { ...SpacingStyles.widthAndHeight },
})
