import React, { useEffect, useState } from 'react'
import MainContainer from '../../../components/MainContainer/MainContainer'
import InfoOverview from '../../../components/views/Conversations/InfoOverview'
import { getUniverseConfig } from '../../../universe/universeController'

export default function ConversationInfoUniverseScreen(props) {
  const [universe, setUniverse] = useState('')

  useEffect(() => {
    const universeName = props.route.params?.universe?.toLowerCase()
    setUniverse(universeName)
  }, [])

  const navigateToPost = (postId) => {
    props.navigation.navigate('Post', {
      id: postId,
      universe,
    })
  }

  const universeObject = getUniverseConfig(props.route.params.universe?.toLowerCase())

  const navigateToProfile = (userId) => {
    props.navigation.navigate('ProfileView', {
      id: userId,
      universe,
    })
  }

  return (
    <MainContainer backgroundColor={universeObject?.backgroundColor}>
      <InfoOverview
        navigateToPost={navigateToPost}
        route={props.route}
        backgroundColor={universeObject?.conversationPostsItemBackground}
        navigateToProfile={navigateToProfile}
      />
    </MainContainer>
  )
}
