import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

export function useKeyboardHeight() {
  const [keyboardHeight, setKeyboardHeight] = useState(0)
  let keyboardDidShow, keyboardDidHide

  function onKeyboardDidShow(e) {
    setKeyboardHeight(e.endCoordinates.height)
  }

  function onKeyboardDidHide() {
    setKeyboardHeight(0)
  }

  useEffect(() => {
    keyboardDidShow = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow)
    keyboardDidHide = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide)
    return () => {
      keyboardDidShow.remove()
      keyboardDidHide.remove()
    }
  }, [])

  return keyboardHeight
}