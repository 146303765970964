import React, { useRef } from 'react'
import { View, Platform } from 'react-native'
import { useRoute } from '@react-navigation/core'
import KeyboardSpacer from 'react-native-keyboard-spacer'

import GroupItem from './GroupItem'
import SearchList from '../../lists/SearchList'
import { getUniverseConfig } from '../../../universe/universeController'

export default function GroupsAll(props) {
	const route = useRoute()
	const universeObjectRef = useRef(getUniverseConfig(route?.params?.universe))

	const renderGroupItem = ({ item }) => (
		<GroupItem group={item} navigateToGroup={props.navigateToGroup} />
	)
	
	return (
		<View style={{ flex: 1 }}>
			<SearchList
				renderItem={renderGroupItem}
				getData={props.getGroups}
				center={true}
				renderChips={props.renderChips}
				backgroundColor={universeObjectRef.current?.backgroundColor}
			/>
			{Platform.OS === 'ios' && <KeyboardSpacer />}
		</View>
	)
}
