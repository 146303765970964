import { CustomText } from '../../text/StyledText'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { txt } from '../../../locales/i18n'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { getUsername } from '../../../services/api/User'
import CustomPressable from "../../pressables/CustomPressable"

export default function ConversationHeader({ newConversation, conversation, navigateToInfo }) {
  const title = useSelector((state) => state.conversations.filter((c) => c.conversation_id === conversation?.conversation_id)[0]?.title)
  const [receiver, setReceiver] = useState(null)
  const route = useRoute()

  useEffect(() => {
    if (route.params?.receiverId) getUsername(route.params?.receiverId).then(setReceiver)
  }, [route.params])

  if (newConversation) {
    return (
      <CustomText numberOfLines={1} font="largeBold" style={{ textAlign: 'center' }}>
        {receiver?.username ?? txt('conversation.newTitle')}
      </CustomText>
    )
  }

  return (
    <CustomPressable
      style={styles.headerContainer}
      accessibilityRole={'button'}
      accessibilityLabel={txt('conversation.info')}
      onPress={navigateToInfo}
    >
      <CustomText numberOfLines={1} style={{ fontFamily: 'raleway-bold' }}>
        {title}
      </CustomText>
    </CustomPressable>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
