import React from 'react'
import { Image, View, TouchableOpacity, StyleSheet } from 'react-native'

import { CustomText } from '../../text/StyledText'
import { txt } from '../../../locales/i18n'
import Colors from '../../../constants/Colors'
import { ShadowStyles } from "../../../styles"

const newyearUniverses = ['nytaarsvenner', 'nyarsvanner']

export const CreatePostCard = (props) => (
  <TouchableOpacity
    accessible
    accessibilityRole="button"
    accessibilityLabel={txt('frontPage.card3Title')}
    accessibilityHint={txt('home.postHint')}
    onPress={() => props.navigateToCreatePost('card')}
    style={[styles.container, props.universe && {...ShadowStyles.shadowInputs}]}
    activeOpacity={0.8}
  >
    {newyearUniverses.includes(props.universe?.name) ? (
      <Image style={styles.image} accessibilityIgnoresInvertColors source={{uri:'https://filer.boblberg.dk/assets/img/nytaarsvenner/gold-bobl.png'}} />
      ) : (
      <Image style={styles.image} accessibilityIgnoresInvertColors source={require('../../../assets/images/bobl.png')} />
    )}

    <View style={styles.textContainer}>
      <CustomText style={[styles.title, props.universe && {color: props.universe?.boblHighlightColor ?? null}]} font="bold" numberOfLines={2}>
        {props.title ?? txt('frontPage.card3Title')}
      </CustomText>

      <CustomText font="small" numberOfLines={3}>
        {props.text ?? txt('home.createBoblText')}
      </CustomText>
    </View>
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 4,
    marginVertical: 8,
    padding: 8,
    borderRadius: 12,
    backgroundColor: Colors.white,
    flexDirection: 'row',
    alignItems: 'center',
  },

  textContainer: {
    marginLeft: 12,
    flex: 1,
  },

  title: {
    marginBottom: 6,
    color: Colors.blueAzure,
  },

  image: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
})
