import React, { useEffect, useState } from 'react'
import { TouchableOpacity, StyleSheet, View } from 'react-native'
import NormalIcon from '../../icons/NormalIcon'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import CustomDialog, { ContentContainer } from '../../dialogs/CustomDialog'
import CategorySelectList from '../../formElements/CategorySelectList'
import { borderRadiusS } from '../../../styles/corners'
import { ButtonPrimary } from '../../buttons/StyledButtons'
import { txt } from '../../../locales/i18n'
import Slider from '../../formElements/Slider'
import PostFilterSubscriptionDialog from "../../dialogs/PostFilterSubscriptionDialog"
import { useSelector } from "react-redux"
import { useRoute } from "@react-navigation/native"

export default function PostFilter({
	filterVisible,
	setFilterVisible,
	selectedGenders,
	setSelectedGenders,
	ageLow,
	setAgeLow,
	ageHigh,
	setAgeHigh,
	distance,
	setDistance,
	resetFilters,
	applyFilter,
	numFilters
}) {
  const minAge = 15
  const maxAge = 95
  const genders = [
    {value: 1, name: txt('genders')[1]},
    {value: 2, name: txt('genders')[2]},
    {value: 3, name: txt('genders')[3]},
  ]

  const [ageLowDisplay, setAgeLowDisplay] = useState(ageLow)
  const [ageHighDisplay, setAgeHighDisplay] = useState(ageHigh)
  const [displayDistance, setDisplayDistance] = useState(distance)
  const [subscriptionDialogVisible, setSubscriptionDialogVisible] = useState(false)
  const features = useSelector((state) => state.user?.features)
  const router = useRoute()

  useEffect(() => {
	if (subscriptionDialogVisible === false) return
	handleSetFilterVisible()
  }, [features])

  useEffect(() => {
    const _minAge = ageLow + 10
    if (ageHigh < _minAge) {
      setAgeHighDisplay(_minAge)
      setAgeHigh(_minAge)
    }
  }, [ageHigh])

  useEffect(() => {
    const _maxAge = ageHigh - 10
    if (ageLow > _maxAge) {
      setAgeLowDisplay(_maxAge)
      setAgeLow(_maxAge)
    }
  }, [ageLow])

  function selectGender(selectedGender) {
    if (selectedGenders.includes(selectedGender)) {
      setSelectedGenders(selectedGenders.filter((id) => id !== selectedGender))
      return
    }
    setSelectedGenders([...selectedGenders, selectedGender])
  }

  function applyFilters() {
    setFilterVisible(false)
    applyFilter()
  }

  function handleSetFilterVisible() {
    const universe = router.params?.universe
    const newYearUniverses = ['nytaarsvenner', 'nyarsvanner']

    if (!features.includes(1) && !newYearUniverses.includes(universe)) setSubscriptionDialogVisible(true)
    else setFilterVisible(true)
  }

  return (
    <>
      <TouchableOpacity
        accessibilityRole="button"
        style={styles.filterButton}
        onPress={handleSetFilterVisible}
      >
        <NormalIcon stroke='far' color={Colors.textLight} size={14} name="filter" badge={numFilters}/>
        <CustomText style={{marginLeft: 6}}>{txt('posts.filters.filter')}</CustomText>
      </TouchableOpacity>

      <CustomDialog visible={filterVisible} onDismiss={() => setFilterVisible(false)} style={{width: '95%'}}>
        <ContentContainer>
          <TouchableOpacity style={styles.reset} onPress={resetFilters}>
            <CustomText font={'bold'} style={{color:Colors.blue}}>{txt('global.reset')}</CustomText>
          </TouchableOpacity>

          <CustomText font={'bold'} >{txt('posts.filters.age')}</CustomText>
          <View style={styles.sliderWrapper}>
            <CustomText>{`${txt('posts.filters.min')} `}</CustomText>
            <Slider
              value={ageLow}
              minimumValue={minAge}
              maximumValue={maxAge}
              onValueChange={setAgeLowDisplay}
              onSlidingComplete={setAgeLow}
              step={10}
            />
            <CustomText style={styles.age}>{` ${ageLowDisplay} ${txt('posts.filters.years')}`}</CustomText>
          </View>

          <View style={styles.sliderWrapper}>
            <CustomText>{`${txt('posts.filters.max')} `}</CustomText>
            <Slider
              value={ageHigh}
              minimumValue={minAge}
              maximumValue={maxAge}
              onValueChange={setAgeHighDisplay}
              onSlidingComplete={setAgeHigh}
              step={10}
            />
            <CustomText style={styles.age}>{` ${ageHighDisplay}${ageHighDisplay === maxAge ? '+':''} ${txt('posts.filters.years')}`}</CustomText>
          </View>

          <CustomText font={'bold'} style={styles.label}>{txt('posts.filters.distance')}</CustomText>
          <CustomText font={'small'}>{txt('posts.filters.distanceDescription')}</CustomText>
          <View style={styles.sliderWrapper}>
            <Slider
              value={distance}
              minimumValue={10}
              maximumValue={750}
              step={10}
              onValueChange={setDisplayDistance}
              onSlidingComplete={setDistance}
            />
            <CustomText style={{minWidth: 90, textAlign: 'right'}}>{` ca. ${displayDistance} km`}</CustomText>
          </View>

          <CustomText font={'bold'} style={styles.label}>{txt('posts.filters.gender')}</CustomText>
          <CategorySelectList
            categories={genders}
            selectedCategories={selectedGenders}
            selectCategory={selectGender}
          />

          <ButtonPrimary title={txt('posts.filters.apply')} style={styles.applyButton} onPress={applyFilters}/>
        </ContentContainer>
      </CustomDialog>

      <PostFilterSubscriptionDialog visible={subscriptionDialogVisible} close={() => setSubscriptionDialogVisible(false)} />
    </>
  )
}

const styles = StyleSheet.create({
  filterButton: {
    flexDirection: 'row',
    alignItems: 'center',
    ...borderRadiusS,
    padding: 10,
  },
  label: {
    marginTop: 12,
  },
  applyButton: {
    marginTop: 24,
    width: '100%'
  },
  sliderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  age: {
    minWidth: 45,
    textAlign: 'right'
  },
  reset: {
    alignSelf: 'flex-end',
    paddingVertical: 6
  }
})
