import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import Colors from '../../../constants/Colors'
import Paths from '../../../constants/Paths'
import { CustomText } from '../../text/StyledText'
import { ShadowStyles } from '../../../styles'
import CustomPressable from "../../pressables/CustomPressable"
import Svg, { Path } from "react-native-svg"

export default function Story({ onPressAction, item }) {

	return (
		<CustomPressable
			onPress={onPressAction}
			style={styles.container}
			activeOpacity={0.75}
		>
			<View style={styles.storyContainer}>

				<View>
					<Image
						source={{ uri: Paths.storyUrlBase + item.filename }}
						style={styles.storyImage}
						accessibilityIgnoresInvertColors
					/>

					<View style={styles.svgContainer}>
						<Svg viewBox="0 0 125 125" fill={Colors.white}>
							<Path d="M125,0.1v125H79.8c26.1-17.6,43.2-47.4,43.2-81.2c0-15.8-3.7-30.6-10.3-43.8H125z"/>
						</Svg>
					</View>
				</View>

				<View style={styles.textContainer}>
					<CustomText
						font="smallBold"
						numberOfLines={3}
					>
						{item.title}
					</CustomText>
					<CustomText
						font="small"
						style={styles.introText}
						numberOfLines={3}
					>
						{item.teaser}
					</CustomText>
				</View>
			</View>
		</CustomPressable>
	)
}


const styles = StyleSheet.create({
	container: {
		backgroundColor: Colors.white,
		...ShadowStyles.cardShadow,
		borderRadius: 6,
		margin: 10,
	},
	storyContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	storyImage: {
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
		height: 125,
		width: 125,
	},
	svgContainer: {
		height: 125,
		width: 125,
		position: 'absolute',
		right: -1
	},
	textContainer: {
		flex: 1,
		paddingHorizontal: 12
	},
	introText: {
		paddingTop: 6,
		fontSize: 14,
		color: Colors.textLight,
	},
})
